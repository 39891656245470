import { Divider, Flex, IconFont, pointer, Popover, Text } from '@byecode/ui'
import type { BaseBreakPointProtocol } from '@lighthouse/core'
import { ApplicationPreviewEnum, MAIN_BREAK_POINT } from '@lighthouse/shared'
import { isIosPlatform } from '@lighthouse/tools'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { useBreakPointContext } from '@/contexts/BreakPointContext'
import { usePreviewType } from '@/hooks/useApplication'

import { useSetExtendsKeys } from '../DesignSetting/hooks/useMouseEventDistance'

interface InheritLabelProps {
    label: string
    name: string
}

const SCxMenu = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
`

const SCxPoint = styled(Flex)`
    height: 32px;
    align-items: center;
    ${pointer}
`

const SCxMenuItem = styled.div`
    padding: 8px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    font-style: normal;
    font-weight: 400;
    font-family: 'PingFang SC';
    ${pointer}

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxDescription = styled.div`
    padding: 4px 16px;
    margin-top: 8px;
`

export const InheritLabel: React.FC<InheritLabelProps> = ({ label, name }) => {
    const previewType = usePreviewType()
    const { onSyncOne, onSyncAll } = useBreakPointContext()
    const { control } = useFormContext<BaseBreakPointProtocol>()
    const { handleRemoveBreakKey, handleClearBreakKey } = useSetExtendsKeys()
    const textRef = useRef<HTMLDivElement>(null)
    const [open, setOpen] = useState(false)
    const [childrenOpen, setChildrenOpen] = useState(false)
    const isMac = isIosPlatform()
    const breakKeys = useWatch({ name: 'breakPoint.breakKeys', control })
    // const keyBoardKey = isMac ? 'Option' : 'Alt'

    const breakKey = useMemo(() => {
        const list = name.split('.')
        return list.includes('breakPoint') ? list.slice(1).join('.') : name
    }, [name])

    const isBreak = useMemo(() => {
        return breakKeys?.includes(breakKey) && previewType !== MAIN_BREAK_POINT
    }, [breakKey, breakKeys, previewType])

    const handleReset = useCallback(() => {
        handleRemoveBreakKey(breakKey)
        setOpen(false)
    }, [breakKey, handleRemoveBreakKey])

    const handleAllReset = useCallback(() => {
        handleClearBreakKey()
        setOpen(false)
    }, [handleClearBreakKey])

    const breakColor = useMemo(() => {
        if (isBreak) {
            return 'var(--color-main)'
        }
    }, [isBreak])

    const handleSync = useCallback(() => {
        // breakKey
        onSyncOne?.(breakKey, previewType)
        setOpen(false)
        setChildrenOpen(false)
    }, [breakKey, onSyncOne, previewType])

    const handleAllSync = useCallback(() => {
        onSyncAll?.(previewType)
        setOpen(false)
    }, [onSyncAll, previewType])

    useEffect(() => {
        if (!textRef.current || !isBreak) {
            return
        }
        textRef.current.addEventListener('mousedown', event => {
            // 判断 Alt 键是否按下
            if (isMac && event.altKey && event.button === 0) {
                event.stopPropagation()
                handleRemoveBreakKey(breakKey)
                setOpen(false)
                return
            }
            if (event.altKey && event.button === 0) {
                event.stopPropagation()
                // 0 是鼠标左键
                handleRemoveBreakKey(breakKey)
                setOpen(false)
            }
        })
    }, [breakKey, handleRemoveBreakKey, isBreak, isMac, name])

    return (
        <Popover width={235} opened={open} withinPortal onChange={setOpen} disabled={!isBreak} position="bottom">
            <Popover.Target>
                <SCxPoint>
                    <Text ref={textRef} color={breakColor}>
                        {label}
                    </Text>
                </SCxPoint>
            </Popover.Target>
            <Popover.Dropdown>
                <SCxMenu>
                    <SCxMenuItem onClick={handleReset}>
                        <Flex alignItems="center" gap={8}>
                            <IconFont type="UndoA" size={16} fill="var(--color-gray-400)" />
                            <Text color="var(--color-black)">重置</Text>
                        </Flex>
                        {/* <Flex alignItems="center" gap={4}>
                            <Text color="var(--color-gray-500)">{keyBoardKey}</Text>
                            <Text color="var(--color-gray-500)">+</Text>
                            <Text color="var(--color-gray-500)">左键</Text>
                        </Flex> */}
                    </SCxMenuItem>
                    <SCxMenuItem onClick={handleAllReset}>
                        <Flex alignItems="center" gap={8}>
                            <IconFont type="ResetAll" size={16} fill="var(--color-gray-400)" />
                            <Text color="var(--color-black)">全部重置</Text>
                        </Flex>
                    </SCxMenuItem>
                    <SCxDescription>
                        <Text color="var(--color-gray-500)" size={12}>
                            重置后该属性将恢复对主断点的继承
                        </Text>
                    </SCxDescription>
                    {previewType !== ApplicationPreviewEnum.desktop && (
                        <>
                            <Divider my={8} />
                            <Popover
                                width={235}
                                trigger="hover"
                                opened={childrenOpen}
                                onChange={setChildrenOpen}
                                position="left"
                            >
                                <Popover.Target>
                                    <SCxMenuItem>
                                        <Flex alignItems="center" gap={8}>
                                            <IconFont type="ArrowsLeftRight" size={16} fill="var(--color-gray-400)" />
                                            <Text color="var(--color-black)">将更改同步到主断点</Text>
                                        </Flex>
                                        <IconFont type="ArrowRightSmall" fill="var(--color-gray-400)" />
                                    </SCxMenuItem>
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <SCxMenu>
                                        <SCxMenuItem onClick={handleSync}>
                                            <Flex alignItems="center" gap={8}>
                                                <IconFont type="SyncOne" size={16} fill="var(--color-gray-400)" />
                                                <Text color="var(--color-black)">同步此更改</Text>
                                            </Flex>
                                        </SCxMenuItem>
                                        <SCxMenuItem onClick={handleAllSync}>
                                            <Flex alignItems="center" gap={8}>
                                                <IconFont type="SyncAll" size={16} fill="var(--color-gray-400)" />
                                                <Text color="var(--color-black)">同步全部更改</Text>
                                            </Flex>
                                        </SCxMenuItem>
                                    </SCxMenu>
                                </Popover.Dropdown>
                            </Popover>
                        </>
                    )}
                </SCxMenu>
            </Popover.Dropdown>
        </Popover>
    )
}
