import type { PopoverProps } from '@byecode/ui'
import { Box, Popover } from '@byecode/ui'
import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import type { StyleComponentProps } from '@byecode/ui/theme/types'
import type { Placement } from '@floating-ui/react'
import type { IconStyle, IconTabs } from '@lighthouse/core'
import { type UploadyProps } from '@rpldy/uploady'
import classnames from 'classnames'
import React, { useState } from 'react'

import { IconDropDown } from './IconDropDown'
import { useStyles } from './IconPicker.styles'
import type { BaseChange, IconPickerStylesNames } from './IconPicker.types'
import { IconRender } from './IconRender'

export interface IconPickerProps
    extends StyleComponentProps<IconPickerStylesNames>,
        Omit<React.ComponentPropsWithoutRef<'input'>, 'size' | 'onChange' | 'defaultValue' | 'value'>,
        BaseChange {
    // width?: number
    position?: Placement
    target?: PopoverProps['target']
    zIndex?: number
    trigger?: PopoverProps['trigger']
    trapFocus?: boolean
    disabledPortal?: boolean
    clearable?: boolean
    searchable?: boolean
    size?: number
    color?: string
    targetComponent?: React.ReactNode
    uploadParams?: UploadyProps
    allowIconTypes?: IconTabs[]
    onGetFullUrl?: (url: string) => string
}

export const IconPicker = React.forwardRef<HTMLDivElement, IconPickerProps>(
    (
        {
            value,
            defaultValue,
            onChange,
            disabled,
            position = 'bottom-start',
            trigger,
            // width = 372,
            clearable,
            searchable = true,
            target,
            zIndex,
            trapFocus,
            disabledPortal,
            allowIconTypes,
            styles,
            className,
            targetComponent,
            size,
            color,
            uploadParams,
            onGetFullUrl,
            ...rest
        },
        ref
    ) => {
        const [opened, setOpened] = useState(false)

        const popoverProps = { target, trapFocus, withinPortal: !disabledPortal, disabled, zIndex, trigger, position, width: 392 }

        const [_value, _setValue] = useUncontrolled({ defaultValue, value, onChange })

        const { classes } = useStyles({}, { name: 'IconPicker', styles })

        return (
            <Popover opened={opened} onChange={setOpened} {...popoverProps}>
                <Popover.Target ref={ref}>
                    <Box className={classnames(classes.root, className)} {...rest}>
                        {targetComponent ?? (
                            <IconRender
                                disabled={disabled}
                                size={size}
                                color={_value?.value ? color : 'var(--color-gray-400)'}
                                clearable={Boolean(clearable && _value?.value)}
                                value={_value?.value ? _value : { type: 'icon', value: 'SmileCircleIconLibrary' }}
                                onGetFullUrl={onGetFullUrl}
                                onClear={() => _setValue(undefined)}
                            />
                        )}
                    </Box>
                </Popover.Target>
                <Popover.Dropdown compact>
                    <IconDropDown
                        value={_value}
                        uploadParams={uploadParams}
                        allowIconTypes={allowIconTypes}
                        searchable={searchable}
                        styles={styles}
                        onChange={_setValue}
                        onClose={() => setOpened(false)}
                        onGetFullUrl={onGetFullUrl}
                    />
                </Popover.Dropdown>
            </Popover>
        )
    }
)
