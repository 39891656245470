import type { AnyObject } from '@byecode/ui'
import type { ApplicationSettingLanguageItem } from '@lighthouse/core'
import type { ParseKeys } from 'i18next'
import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useCallback, useContext, useMemo } from 'react'
import type { TransProps } from 'react-i18next'
import { Trans, useTranslation } from 'react-i18next'

import { DEFAULT_LANG } from '../constants/language'
import type { I18TranslationKey } from '../types'
import { getTranslationValue } from '../utils'

export type LanguageContextType = {
    languageList: ApplicationSettingLanguageItem[]
    language?: string
    convertTextByLanguage: (s: I18TranslationKey, v?: AnyObject) => string
}

export const LanguageContext = createContext<LanguageContextType>({
    languageList: [],
    language: DEFAULT_LANG,
    convertTextByLanguage: (s, v) => getTranslationValue(s)
})

type LanguageProviderProps = PropsWithChildren<{
    value: Omit<LanguageContextType, 'convertTextByLanguage'>
}>

export const LanguageProvider: FC<LanguageProviderProps> = ({ children, value: propsValue, ...rest }) => {
    const { t } = useTranslation(undefined, { lng: propsValue.language ?? DEFAULT_LANG })
    const convertTextByLanguage = useCallback(
        (s: I18TranslationKey, v?: AnyObject) => {
            const d = t(s, v)
            return typeof d === 'string' ? d : ''
        },
        [t]
    )
    const value = useMemo(
        () => ({ ...propsValue, language: propsValue.language ?? DEFAULT_LANG, convertTextByLanguage }),
        [convertTextByLanguage, propsValue]
    )
    return (
        <LanguageContext.Provider value={value} {...rest}>
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguageContext = () => useContext(LanguageContext)

export const Translation = (props: TransProps<ParseKeys>) => {
    const { language } = useLanguageContext()
    const { t } = useTranslation(undefined, { lng: language })
    return <Trans t={t} {...props} />
}
