/**
 * 拖拽时滚动容器
 */
export class ScrollContainerControl {
    private readonly container: Element

    constructor(container: Element) {
        this.container = container
    }

    /** 误差偏移量 */
    private readonly offset = 32

    /** 每帧滚动距离 */
    private readonly vector = 8

    private timer: number | null = null

    private readonly scroll = (clientY: number) => {
        const { y: scrollContainerY, height: scrollContainerHeight } = this.container.getBoundingClientRect()
        const bottomPointY = scrollContainerHeight + scrollContainerY

        if (clientY <= scrollContainerY + this.offset) {
            this.container.scrollBy({ top: -this.vector })
        } else if (clientY >= bottomPointY - this.offset) {
            // 触底了
            const isReachBottom = this.container.scrollTop + scrollContainerHeight >= this.container.scrollHeight
            if (isReachBottom) {
                return
            }
            this.container.scrollBy({ top: this.vector })
        } else {
            this.timer = null
            return
        }

        this.timer = requestAnimationFrame(() => this.scroll(clientY))
    }

    start = (clientY: number) => {
        if (this.timer) {
            this.stop()
        }
        this.scroll(clientY)
    }

    stop = () => {
        if (this.timer) {
            cancelAnimationFrame(this.timer)
            this.timer = null
        }
    }
}
