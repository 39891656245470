import { type DateField, type DateValue, type FieldADTValue, DateFieldUnit } from '@lighthouse/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { dateUnitToMode } from '../../constants'
import { getDateConfig } from '../../utils'
import { DataSourceDatePicker } from '../DataSourceDatePicker'

export const SCxDateInput = styled.input<{ width?: string; color?: string }>`
    display: flex;
    flex-wrap: wrap;
    width: ${({ width }) => width || '100%'};
    height: 36px;
    padding: 6px 0 4px 5.5px;
    border: none;
    outline: none;
    color: ${({ color }) => color || 'var(--color-black)'};
    background-color: var(--color-white);
    font-size: var(--font-size-normal);
    border-bottom: 1px solid var(--color-gray-200);
    overflow: hidden;
`
interface DateValueEditorProps {
    recordId: string
    field: DateField
    data?: DateValue
    width?: string
    onClose?: (isActive?: boolean) => void
    onCellChange?: (recordId: string, field: FieldADTValue) => Promise<boolean>
}

export const DateValueEditor: React.FC<DateValueEditorProps> = ({ recordId, field, data, width, onClose, onCellChange }) => {

    const { unit, format } = getDateConfig(field) || {}
    const calendarMode = dateUnitToMode[unit]
    const dateValue = data ? new Date(Number(data)) : undefined
    const handleDateChange = useCallback(
        (date: Date | undefined) => {
            onCellChange?.(recordId, { ...field, type: 'date', value: date ? date.valueOf() : '' })
            onClose?.(false)
        },
        [field, onCellChange, onClose, recordId]
    )

    return (
        <DataSourceDatePicker
            // styles={width ? { width } : {}}
            value={dateValue}
            mode={calendarMode}
            showTime={unit === DateFieldUnit.MINUTE || unit === DateFieldUnit.DAY_MINUTE}
            format={format}
            inputRender={rest => {
                return (
                    <SCxDateInput
                        {...rest}
                        color={rest.hoverValue ? 'var(--color-gray-400)' : undefined}
                        autoFocus
                        placeholder=""
                        width="100%"
                    />
                )
            }}
            onChange={ev => {
                handleDateChange(ev)
            }}
        />
    )
}
