import { ActionIcon } from '@mantine/core'
import styled from 'styled-components'

export const BasicActionIcon: typeof ActionIcon = styled(ActionIcon)`
    height: 20px;
    min-height: 20px;
    color: var(--color-black);
    &:hover {
        background: rgba(56, 56, 56, 0.05) !important;
    }
    &.active {
        background: rgba(56, 56, 56, 0.05) !important;
        &:hover {
            background: rgba(56, 56, 56, 0.1) !important;
        }
    }
`
export const CustomActionIcon: typeof ActionIcon = styled(BasicActionIcon)`
    width: 20px;
    min-width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const DropdownActionButton: typeof ActionIcon = styled(BasicActionIcon)`
    flex: none;
    width: auto;
    min-width: 20px;
    padding: 0 3px;
    gap: 3px;
`
