import { useContainerBlockContext } from '@lighthouse/block'
import type { FloatBlockConfig } from '@lighthouse/core'
import { BlockType, FloatBlockAbstract } from '@lighthouse/core'
import { findBlockById, findNormalOrSyncBlock, findParentBlockByType, useAtomAction, useAtomData, useFloatBoxContext, usePageContainer } from '@lighthouse/shared'
import { findLast, mergeDeepRight } from 'rambda'
import { useCallback, useMemo } from 'react'

import { toggleFloatBoxAtom } from '@/atoms/application/action'
import { pageStackOfFloatBlockAtom, syncComponentsAtom } from '@/atoms/application/state'
import { pageBlocksAtom, pageStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'

import { usePreviewType } from './useApplication'

export function useFloatBlock(blockId: string, scope?: string) {
    const stackId = useCurrentStackIdContext()
    const { pageId } = useCurrentPageContext()
    const { rootPageId } = useRootPageContext()
    const { dragPlugin } = usePageContainer()
    const blocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(syncComponentsAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const previewType = usePreviewType()
    const float = useFloatBoxContext()
    const block = useMemo(() => {
        const floatBlock = findNormalOrSyncBlock({ id: blockId, scope }, blocks, syncComponents)
        return floatBlock?.type === BlockType.floatBox ? floatBlock : undefined
    }, [blockId, blocks, scope, syncComponents])

    const opened = useAtomData(
        pageStackOfFloatBlockAtom,
        useCallback(
            v => {
                return (
                    findLast(d => d.blockId === blockId && stackId === d.stackId && (scope === undefined || scope === d.scope), v)?.open ??
                    false
                )
            },
            [blockId, scope, stackId]
        )
    )
    // console.log("🚀 ~ useFloatBlock ~ opened:", opened, blockId, scope)
    const { run: openFloat } = useAtomAction(toggleFloatBoxAtom)

    const handleOpenChange = useCallback(
        (v: boolean) => {
            openFloat({
                stackId,
                blockId,
                rootPageId,
                scope,
                open: v
            })
            v &&
                setPageStack(draft => {
                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                    if (stack) {
                        stack.state.selectedNodes = [{ id: blockId, scope }]
                        stack.state.asideType = AsideType.BLOCK
                    }
                })
        },
        [blockId, openFloat, rootPageId, scope, setPageStack, stackId]
    )


    const onClose = useCallback(() => {
        openFloat({
            stackId,
            blockId,
            scope,
            rootPageId,
            open: false
        })
    }, [blockId, openFloat, scope, rootPageId, stackId])

    const onOpen = useCallback(() => {
        openFloat({
            stackId,
            blockId,
            scope,
            rootPageId,
            open: true
        })
    }, [blockId, openFloat, scope, rootPageId, stackId])

    return useMemo(
        () => ({
            opened,
            config: block?.config
                ? mergeDeepRight<FloatBlockConfig>(block?.config, {
                      breakPoint: {
                          overlay: {
                              showOn: 'click',
                              // 关闭时机
                              dismiss: 'click'
                          }
                      }
                  })
                : undefined,
            blockId,
            parentFloatId: float?.blockId,
            // disableDismiss: true,
            disabled: !block?.config,
            isOpenToggle: false,
            previewType,
            updateDragNode: () => {
                dragPlugin?.collectNodes()
            },
            onOpenChange: handleOpenChange,
            onClose,
            onOpen
        }),
        [opened, block?.config, blockId, float?.blockId, previewType, handleOpenChange, onClose, onOpen, dragPlugin]
    )
}
