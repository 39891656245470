import { flex, tinyButtons } from '@byecode/ui'
import styled from 'styled-components'

export const Contain = styled.div<{ selected: boolean; disableEvent?: boolean }>`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    width: 100%;
    padding: 15px 14px;
    height: 66px;
    position: relative;
    z-index: 11;
    border: 2px solid ${({ selected }) => (selected ? 'var(--color-purple-500)' : 'transparent')};
    /* border-radius: ${({ selected }) => (selected ? '8px 8px 0 0' : 'none')}; */
    pointer-events: ${({ disableEvent }) => (disableEvent ? 'none' : 'auto')};
`
export const LeftFill = styled.div`
    ${flex}
    align-items: center;
`

export const RightFill = styled.div`
    ${flex}
    align-items: center;
    justify-content: flex-end;
    gap: 6px;
`

export const DrawerContain = styled.div`
    width: 100%;
    padding: 12px 0;
    padding-bottom: 150px;
    height: 100%;
    overflow-y: auto;
    ${tinyButtons}
    background-color: var(--color-white);
`
export const ButtonList = styled.div`
    padding: 0 16px;

    button {
        width: 100%;
    }

    button:not(:first-child) {
        margin-top: 16px;
    }
`
