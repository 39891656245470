/* eslint-disable react/no-array-index-key */
import { useByecodeUIConfig } from '@byecode/ui/providers'
import { dateFormat } from '@byecode/ui/utils/dateformat'
import clsx from 'clsx'
import { addDays, compareAsc, isSameDay, isSameMonth, startOfWeek } from 'date-fns'
import React, { forwardRef, useCallback, useMemo } from 'react'

import type { Selectors, StyleComponentProps } from '../../../theme/types'
import { Box } from '../../Box'
import type { CalendarSettingsProps } from '../Calendar.type'
import { useCalendarDays } from './DayCalendar.hook'
import { useStyles } from './DayCalendar.style'

export type DayCalendarStylesName = Selectors<typeof useStyles>

interface DayCalendarProps
    extends Pick<
            CalendarSettingsProps,
            | 'weekFormat'
            | 'firstDayOfWeek'
            | 'dayRowsCount'
            | 'CellComponent'
            | 'onCellMouseLeave'
            | 'onCellMouseEnter'
            | 'maxDate'
            | 'minDate'
        >,
        StyleComponentProps<DayCalendarStylesName> {
    date?: Date

    onSelect: (day: Date) => void

    disabled?: boolean

    /**
     * @description 当前面板展示的是哪个月份的数据
     * @date 8/30/2023 - 6:51:52 PM
     *
     * @type {Date}
     */
    panelViewDate: Date
}

export const DayCalendar = forwardRef<HTMLDivElement, DayCalendarProps>((props, ref) => {
    const {
        classNames,
        styles,
        unstyled,

        date = new Date(),
        onSelect,
        disabled,
        panelViewDate,
        minDate,
        maxDate,

        // settings props
        firstDayOfWeek = 1,
        weekFormat = 'iiiiii',
        dayRowsCount = 6,
        CellComponent,
        onCellMouseEnter,
        onCellMouseLeave,
        ...rest
    } = props
    const { locale } = useByecodeUIConfig()

    const { classes } = useStyles({}, { name: 'Calendar', classNames, styles, unstyled })

    const headers = useMemo(() => {
        const labels: React.ReactNode[] = []
        for (let i = 0; i < 7; i++) {
            const current = startOfWeek(new Date(), { weekStartsOn: firstDayOfWeek })
            labels.push(dateFormat(addDays(current, i), weekFormat, { locale }))
        }

        return labels
    }, [firstDayOfWeek, locale, weekFormat])

    const rows = useCalendarDays({ calendarDate: panelViewDate, currentDate: date, dayRowsCount, firstDayOfWeek })

    const isDayDisabled = useCallback(
        (day: Date) => {
            if (minDate && maxDate && !isSameDay(minDate, day) && !isSameDay(maxDate, day)) {
                return compareAsc(minDate, day) > 0 || compareAsc(day, maxDate) > 0
            }
            if (minDate && !isSameDay(minDate, day)) {
                return compareAsc(minDate, day) > 0
            }
            if (maxDate && !isSameDay(maxDate, day)) {
                return compareAsc(day, maxDate) > 0
            }
            return false
        },
        [maxDate, minDate]
    )

    return (
        <Box ref={ref} className={clsx(classes.dayBody)} {...rest}>
            <table>
                <colgroup>
                    {Array.from({ length: 7 }).map((_, i) => (
                        <col key={i} width={`${100 / 7}%`} />
                    ))}
                </colgroup>

                <thead>
                    <tr>
                        {headers.map((label, i) => (
                            <th key={i}>{label}</th>
                        ))}
                    </tr>
                </thead>

                <tbody>
                    {rows.map((cells, i) => (
                        <tr key={i}>
                            {cells.map((day, j) => (
                                <td
                                    key={j}
                                    onClick={() => {
                                        if (disabled || isDayDisabled(day)) {
                                            return
                                        }
                                        onSelect(day)
                                    }}
                                    onMouseEnter={e => {
                                        if (disabled || isDayDisabled(day)) {
                                            return
                                        }
                                        onCellMouseEnter?.(day, e.nativeEvent)
                                    }}
                                    onMouseLeave={e => {
                                        if (disabled || isDayDisabled(day)) {
                                            return
                                        }
                                        onCellMouseLeave?.(day, e.nativeEvent)
                                    }}
                                >
                                    {CellComponent ? (
                                        <CellComponent panelView="day" date={day} panelViewDate={panelViewDate} />
                                    ) : (
                                        <>
                                            <Box
                                                data-selected={isSameDay(day, date) || undefined}
                                                data-out-range={!isSameMonth(day, panelViewDate) || undefined}
                                                data-disabled={isDayDisabled(day)}
                                                className={classes.dayCell}
                                            >
                                                {dateFormat(day, 'dd', { locale })}
                                            </Box>
                                            {isSameDay(day, new Date()) && <div className={classes.dayIndicator} />}
                                        </>
                                    )}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </Box>
    )
})
