import { flex, IconFont, pointer, Switch, Text, Toast, Tooltip } from '@byecode/ui'
import type { CollectEvent, EVENT_VARIABLE_TYPE } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable, PrevPageDatasourceForVariable } from '@lighthouse/shared'
import { TagIcon, validateEvent } from '@lighthouse/shared'
import { Collapse } from '@mantine/core'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { CollectionEventConfigure } from './CollectionEventConfigure'
import * as SC from './styles'

const SCxRightFill = styled.div`
    width: 180px;
    /* max-width: 80%; */
    ${flex}
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
`

const SCxErrorInfoIcon = styled(IconFont)`
    ${pointer}
`

interface CollectionEventSettingProps {
    value: CollectEvent
    opened: boolean
    disabled?: boolean
    eventTypes?: EVENT_VARIABLE_TYPE[]
    curr?: CurrPageDatasourceForVariable
    prev?: PrevPageDatasourceForVariable
    onChangeOpen: (opened: boolean) => void
    onChange: (val: CollectEvent) => void
    onDuplicate: (id: string) => void
    onDelete: (id: string) => void
}

export const CollectionEventItem: React.FC<CollectionEventSettingProps> = ({
    value,
    opened,
    disabled,
    eventTypes,
    curr,
    prev,
    onChange,
    onDuplicate,
    onDelete,
    onChangeOpen
}) => {
    const { id, title } = value
    const methods = useForm<CollectEvent>({
        defaultValues: value
    })

    const { control } = methods

    const errorMessage = useMemo(() => !validateEvent(value), [value])

    useEffect(() => {
        const subscribe = methods.watch(values => {
            onChange(values as CollectEvent)
        })

        return () => {
            subscribe.unsubscribe()
        }
    }, [id, methods, onChange])

    const handleEnable = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>, c: (ev: React.ChangeEvent<HTMLInputElement>) => void) => {
            const isChecked = ev.target.checked
            if (errorMessage && isChecked) {
                Toast.error('采集器配置未完善，无法开启')
                return
            }
            c(ev)
        },
        [errorMessage]
    )

    return (
        <FormProvider {...methods}>
            <SC.Container>
                <SC.Header>
                    <Controller
                        name="enable"
                        control={control}
                        render={({ field }) => (
                            <SC.LeftFill>
                                <Switch checked={field.value} onChange={ev => handleEnable(ev, field.onChange)} />
                                <Text size={14}>{title}</Text>
                                {errorMessage && (
                                    <Tooltip title="配置规则未完善，此项不生效" placement="top">
                                        <SCxErrorInfoIcon size={20} type="WarningCircle" fill="var(--color-red-500)" />
                                    </Tooltip>
                                )}
                            </SC.LeftFill>
                        )}
                    />
                    <SCxRightFill style={{ width: 'auto' }}>
                        <Tooltip title="创建副本">
                            <TagIcon
                                icon="Duplicate"
                                size={24}
                                radius={5}
                                hoverBackground="var(--color-gray-100)"
                                enableHover
                                iconColor="var(--color-gray-500)"
                                onClick={() => onDuplicate(id)}
                            />
                        </Tooltip>
                        <Tooltip title="删除">
                            <TagIcon
                                icon="Trash"
                                size={24}
                                radius={5}
                                hoverBackground="var(--color-gray-100)"
                                enableHover
                                iconColor="var(--color-gray-500)"
                                onClick={() => onDelete(id)}
                            />
                        </Tooltip>

                        <IconFont
                            size={16}
                            color="var(--color-gray-500)"
                            type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'}
                            onClick={() => onChangeOpen(!opened)}
                        />
                    </SCxRightFill>
                </SC.Header>
                <Collapse in={opened}>
                    <SC.Content>
                        <CollectionEventConfigure curr={curr} prev={prev} disabled={disabled} eventTypes={eventTypes} />
                    </SC.Content>
                </Collapse>
            </SC.Container>
        </FormProvider>
    )
}
