import type { AnimationControlSetting, ApplicationSettingThemeColor, TransformProtocol, TransitionProtocol } from '@lighthouse/core'
import { AnimationBreakPoint, TransitionTypeEnum } from '@lighthouse/core'
import { getBackgroundStyle, getShadowStyle, mergeBackgroundStyles } from '@lighthouse/shared'
import type { AnimationOptions, Transition } from 'motion/react'

// 获取偏移样式
// export function getTransformStyle(transform: TransformProtocol): React.CSSProperties {
//     const { rotateType, rotate3d, skew = [0, 0], scale, rotate, offset } = transform
//     const { x = 0, y = 0, z = 0 } = rotate3d ?? {}

//     return rotateType === '3d'
//         ? {
//               transform: `translate(${offset[0]}px, ${offset[1]}px) rotateX(${x}deg) rotateY(${y}deg) rotateZ(${z}deg) scale(${scale})  skew(${skew?.[0]}deg, ${skew?.[1]}deg)`
//           }
//         : {
//               transform: `translate(${offset[0]}px, ${offset[1]}px) rotate(${rotate}deg) scale(${scale}) skew(${skew?.[0]}deg, ${skew?.[1]}deg)`
//           }
// }
// 获取偏移样式
export function getAnimateConfig(transform: TransformProtocol) {
    const {
        rotateType,
        rotate3d,
        skew,
        scale,
        rotate,
        offset: [x, y]
    } = transform
    const { x: rotateX, y: rotateY, z: rotateZ } = rotate3d ?? { x: 0, y: 0, z: 0 }
    const [skewX, skewY] = skew ?? [0, 0]

    return rotateType === '3d'
        ? {
              x,
              y,
              rotateX,
              rotateY,
              rotateZ,
              scale,
              skewX,
              skewY
          }
        : {
              x,
              y,
              rotate,
              scale,
              skewX,
              skewY
          }
}

// 获取动画
export function getAnimation(
    params: { appId: string; animation: AnimationControlSetting; palettes: ApplicationSettingThemeColor[] },
    options?: {
        diffAnimate?: Record<string, unknown>
    }
) {
    const { diffAnimate } = options ?? {}
    const { appId, palettes, animation } = params
    const { opacity, rotateType, transition, rotate3d, background, shadow, skew, scale, rotate, offset } = animation

    const newAnimate: Record<string, unknown> = {
        ...mergeBackgroundStyles(getBackgroundStyle(appId, background, palettes)),
        ...getShadowStyle(shadow, palettes),
        ...getAnimateConfig({ rotateType, rotate3d, skew, scale, rotate, offset }),
        opacity
    }
    if (diffAnimate) {
        return Object.fromEntries(
            Object.entries(diffAnimate).reduce<[string, unknown][]>((p, [k, v]) => {
                const config = newAnimate[k]
                if (config) {
                    if (k === 'opacity' || k === 'scale') {
                        return config === v ? p : [...p, [k, config]]
                    }
                    return [...p, [k, config]]
                }
                return p
            }, [])
        )
    }
    return newAnimate
}

// 获取动画style
// export function getAnimationStyles(params: {
//     appId: string
//     animation: AnimationControlSetting
//     palettes: ApplicationSettingThemeColor[]
// }) {
//     const { appId, palettes, animation } = params
//     const { opacity, rotateType, transition, rotate3d, background, shadow, skew, scale, rotate, offset } = animation

//     return {
//         ...getBackgroundStyle(appId, background, palettes),
//         ...getShadowStyle(shadow, palettes),
//         ...getTransformStyle({ rotateType, rotate3d, skew, scale, rotate, offset }),
//         opacity
//     }
// }

export function getLayoutAnimationConfig(params: {
    appId: string
    animation: AnimationControlSetting
    palettes: ApplicationSettingThemeColor[]
}) {
    const { appId, palettes, animation } = params
    const { opacity, rotateType, transition, rotate3d, background, shadow, skew, scale, rotate, offset } = animation

    const newAnimate: Record<string, unknown> = {
        ...mergeBackgroundStyles(getBackgroundStyle(appId, background, palettes)),
        ...getShadowStyle(shadow, palettes),
        ...getAnimateConfig({ rotateType, rotate3d, skew, scale, rotate, offset }),
        opacity
    }

    const defaultAnimate = getDefaultAnimationConfig()

    return Object.fromEntries(
        Object.entries(defaultAnimate).reduce<[string, unknown][]>((p, [k, v]) => {
            const config = newAnimate[k]
            if (config === v) {
                return p
            }
            return [...p, [k, v]]
        }, [])
    )
}

// export function getDefaultAnimationStyles() {
//     return {
//         transform: 'none',
//         opacity: 1
//     }
// }

export function getDefaultAnimationConfig() {
    return {
        x: 0,
        y: 0,
        rotate: 0,
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        scale: 1,
        skewX: 0,
        skewY: 0,
        opacity: 1,
        backgroundColor: undefined,
        background: undefined,
        backgroundImage: undefined,
        boxShadow: undefined
    }
}

export function getBuilderHoverAnimationConfig(
    params: {
        appId: string
        animation: AnimationControlSetting
        palettes: ApplicationSettingThemeColor[]
    },
    options: {
        styles?: React.CSSProperties
    }
) {
    const { styles } = options
    const { animation } = params
    const animate = getAnimation(params)
    const defaultStyle = styles ? mergeBackgroundStyles(styles) : {}

    return {
        x: [0, animate.x, 0],
        y: [0, animate.y, 0],
        rotate: animate.rotate && animation.rotateType === '2d' ? [0, animate.rotate, 0] : 0,
        rotateX: animate.rotateX && animation.rotateType === '3d' ? [0, animate.rotateX, 0] : 0,
        rotateY: animate.rotateY && animation.rotateType === '3d' ? [0, animate.rotateY, 0] : 0,
        rotateZ: animate.rotateZ && animation.rotateType === '3d' ? [0, animate.rotateZ, 0] : 0,
        scale: [1, animate.scale, 1],
        skewX: [0, animate.skewX, 0],
        skewY: [0, animate.skewY, 0],
        opacity: [1, animate.opacity, 1],
        background: animate.background
            ? [defaultStyle.background || 'rgba(0,0,0,0)', animate.background, defaultStyle.background || 'rgba(0,0,0,0)']
            : null,
        boxShadow: animate.boxShadow
            ? [
                  defaultStyle.boxShadow || '0px 0px 0px rgba(0, 0, 0, 0)',
                  animate.boxShadow,
                  defaultStyle.boxShadow || '0px 0px 0px rgba(0, 0, 0, 0)'
              ]
            : null,
        backgroundColor: animate.backgroundColor
            ? [defaultStyle.backgroundColor || 'rgba(0,0,0,0)', animate.backgroundColor, defaultStyle.backgroundColor || 'rgba(0,0,0,0)']
            : null,
        backgroundImage: animate.backgroundImage
            ? [
                  defaultStyle.backgroundImage || 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))',
                  animate.backgroundImage,
                  defaultStyle.backgroundImage || 'linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0))'
              ]
            : null
    }
}

export function getBuilderLayoutAnimationConfig(params: {
    appId: string
    animation: AnimationControlSetting
    palettes: ApplicationSettingThemeColor[]
}) {
    const animate = getAnimation(params)
    return {
        x: [animate.x, 0],
        y: [animate.y, 0],
        rotateX: animate.rotateX ? [animate.rotateX, 0] : 0,
        rotateY: animate.rotateY ? [animate.rotateY, 0] : 0,
        rotateZ: animate.rotateZ ? [animate.rotateZ, 0] : 0,
        scale: [animate.scale, 1],
        skewX: [animate.skewX, 0],
        skewY: [animate.skewY, 0],
        opacity: [animate.opacity, 1]
    }
}

export function getTransition(
    transition: TransitionProtocol,
    options?: { repeat?: number; repeatType: 'loop' | 'reverse' | 'mirror' }
): Transition {
    const { repeat, repeatType } = options ?? {}
    const { type, ease, bezier, delay, basedOn, stiffness, bounce, damping, mass, time } = transition
    if (type === TransitionTypeEnum.ease) {
        return {
            ease: bezier?.split(',').map(Number),
            delay,
            repeat,
            repeatType,
            duration: time,
            visualDuration: time
        }
    }
    if (basedOn === 'time') {
        return {
            type: 'spring',
            duration: time,
            visualDuration: time,
            bounce,
            repeat,
            repeatType,
            delay
        }
    }
    return {
        type: 'spring',
        stiffness,
        damping,
        mass,
        repeat,
        repeatType,
        duration: time,
        visualDuration: time,
        delay
    }
}
export function getParentScrollContainer(element: Element) {
    let parent = element.parentElement

    // 向上遍历DOM直到找到有滚动行为的父元素
    while (parent) {
        const { overflow } = window.getComputedStyle(parent)
        if (overflow.includes('auto') || overflow.includes('scroll')) {
            return parent
        }
        parent = parent.parentElement
    }

    return null // 如果没有找到滚动容器，则返回 null
}

export function getVisibleHeight(element: Element) {
    const container = getParentScrollContainer(element) // 获取父滚动容器
    const elementRect = element.getBoundingClientRect() // 获取元素的位置信息

    let containerRect = { top: 0, bottom: window.innerHeight, height: 0 } // 默认视口信息
    if (container) {
        containerRect = container.getBoundingClientRect() // 获取容器的位置信息
    }

    const elementTop = elementRect.top
    // console.log("🚀 ~ getVisibleHeight ~ elementTop:", elementTop)
    const elementBottom = elementRect.bottom

    const containerTop = containerRect.top
    // console.log("🚀 ~ getVisibleHeight ~ containerTop:", containerTop)
    // console.log('🚀 ~ getVisibleHeight ~ scrollTop:', container?.scrollTop)
    // console.log('🚀 ~ getVisibleHeight ~ diffTop:', elementTop - containerTop + container?.scrollTop)
    const containerBottom = containerRect.bottom
    // 计算元素与容器/视口的交集部分
    const visibleTop = Math.max(elementTop, containerTop) // 可见区域的上边界
    const visibleBottom = Math.min(elementBottom, containerBottom) // 可见区域的下边界

    // 计算可见区域的高度
    return Math.max(0, visibleBottom - visibleTop)
}
