import type { DividerBlockAbstract, DividerStyle } from '@lighthouse/core'
import { DIRECTION, DIVIDER_STYLE } from '@lighthouse/core'
import { getTextColorStyle, useAppThemeContext } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'

const DividerRoot = styled(AnimationDecorators)`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
`

const DividerContent = styled.div``

const getLineDashedSvg = (fill: string, vertical: boolean) =>
    vertical
        ? `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 18" fill="none">
    <g>
        <mask id="mask0_13618_7138" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="1" height="18">
            <path d="M0.999999 18L1 0L0 -4.37114e-08L-7.86805e-07 18L0.999999 18Z" fill="white" />
        </mask>
        <g mask="url(#mask0_13618_7138)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-8.74228e-08 2L0 -4.37114e-08L1 0L1 2L-8.74228e-08 2Z" fill="${fill}" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.74846e-07 14L0 10L1 10L1 14L-1.74846e-07 14Z" fill="${fill}" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-1.74846e-07 8L0 4L1 4L1 8L-1.74846e-07 8Z" fill="${fill}" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-8.74228e-08 18L0 16L1 16L1 18L-8.74228e-08 18Z" fill="${fill}" />
        </g>
    </g>
</svg>
   `
        : `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 1" fill="none">
    <g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M2 1H0V0H2V1Z" fill="${fill}" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M14 1H10V0H14V1Z" fill="${fill}" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8 1H4V0H8V1Z" fill="${fill}" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 1L16 1V0L18 0V1Z" fill="${fill}" />
    </g>
</svg>
`

const getLineSolidSvg = (fill: string, vertical: boolean) =>
    vertical
        ? `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 18" fill="none">
    <g>
        <mask id="mask0_13618_7147" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="0" y="0">
            <path d="M0.999999 18L1 0L0 -4.37114e-08L-7.86805e-07 18L0.999999 18Z" fill="white" />
        </mask>
        <g mask="url(#mask0_13618_7147)">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M-7.86805e-07 18L0 -4.37114e-08L1 8.74229e-08L0.999998 18L-7.86805e-07 18Z" fill="${fill}" />
        </g>
    </g>
</svg>
   `
        : `
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 1" fill="none">
    <g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M18 1L-8.74229e-08 1L0 0L18 1.57361e-06L18 1Z" fill="${fill}" />
    </g>
</svg>
`

const SVG_MAP: Record<DividerStyle, typeof getLineDashedSvg> = {
    [DIVIDER_STYLE.DASHED]: getLineDashedSvg,
    [DIVIDER_STYLE.SOLID]: getLineSolidSvg
}

interface DividerBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: DividerBlockAbstract
}

const DividerBlock: FC<DividerBlockProps> = ({ blockData, ...rest }) => {
    const { breakPoint, fill, visible, direction, size = 1, style: dividerStyle = 'SOLID' } = blockData.config

    const {
        theme: { palettes }
    } = useAppThemeContext()

    const dividerColorStyle = useMemo(() => {
        const colorStyle = getTextColorStyle(fill, palettes)

        return {
            ...colorStyle,
            color: colorStyle.color || '#e4e7ec'
        }
    }, [fill, palettes])

    const style = useMemo<React.CSSProperties>(() => {
        const svgStr = SVG_MAP[dividerStyle]?.(dividerColorStyle.color as string, direction === DIRECTION.vertical)

        return {
            width: direction === DIRECTION.vertical ? size : '100%',
            height: direction === DIRECTION.vertical ? '100%' : size,
            opacity: visible ? 1 : 0,
            backgroundImage: `url('data:image/svg+xml;charset=utf8,${encodeURIComponent(svgStr)}')`,
            backgroundRepeat: direction === DIRECTION.vertical ? 'repeat-y' : 'repeat-x',
            backgroundSize: direction === DIRECTION.vertical ? '100% auto' : 'auto 100%',
            backgroundPosition: direction === DIRECTION.vertical ? 'center top' : 'left center'
        }
    }, [direction, dividerColorStyle.color, dividerStyle, size, visible])

    return (
        <DividerRoot blockId={blockData.id} animation={breakPoint?.animation} {...rest}>
            <DividerContent style={style} />
        </DividerRoot>
    )
}

export default DividerBlock
