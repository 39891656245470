import { Empty } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { FilterBlockAbstract, FilterBlockValue } from '@lighthouse/core'
import { ApplicationPreviewEnum, CenteredWrapper } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'
import { DesktopFilter } from './DesktopFilter'
import { MobileFilter } from './MobileFilter'
import type { FilterItemProps } from './types'

export interface FilterBlockProps
    extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>,
        Pick<FilterItemProps, 'recordId' | 'dataSourceList' | 'onChange' | 'onFetchTextOptions' | 'onFetchCascadeOptions'> {
    blockData: FilterBlockAbstract
    filterData: FilterBlockValue
    previewType: ApplicationPreviewEnum
}

const SCxFilterBlockContainer = styled(AnimationDecorators)`
    width: 100%;
    min-height: 32px;
    padding: var(--block-padding);
    height: 100%;
    /* overflow: hidden; */
    /* padding: 6px 5px 4px 4px; */
`

const EmptyImage = styled.img`
    width: 140px;
    height: 144px;
`

const FilterBlock: React.FC<FilterBlockProps> = ({
    dataSourceList,
    blockData,
    filterData,
    previewType,
    recordId,
    onChange,
    onFetchTextOptions,
    onFetchCascadeOptions,
    ...rest
}) => {
    // const { ref } = useBreakpoint()
    const { id, config } = blockData
    const { breakPoint } = config
    const emptyImageUrl = getAssetUrl('empty', 'empty_filter.png')
    const isMobile = previewType === ApplicationPreviewEnum.mobile
    const { filterItems, mode, labelWidth, showLabel } = config
    const Component = useMemo(() => (isMobile ? MobileFilter : DesktopFilter), [isMobile])

    return (
        <SCxFilterBlockContainer
            blockId={id}
            animation={breakPoint?.animation}
            data-block-type="chartView"
            {...rest}
            style={{ overflow: breakPoint.size.overflow, ...rest.style }}
        >
            {filterItems.length === 0 && (
                <CenteredWrapper style={{ padding: 24 }}>
                    <Empty icon={<EmptyImage src={emptyImageUrl} alt="请添加筛选项" />} description="请添加筛选项" />
                </CenteredWrapper>
            )}
            <Component
                list={filterItems}
                mode={mode}
                showLabel={showLabel}
                labelWidth={labelWidth}
                filterData={filterData}
                dataSourceList={dataSourceList}
                recordId={recordId}
                onFetchTextOptions={onFetchTextOptions}
                onFetchCascadeOptions={onFetchCascadeOptions}
                onChange={onChange}
            />
        </SCxFilterBlockContainer>
    )
}

export default FilterBlock
