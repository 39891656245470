import { Button, Flex, IconFont, Input } from '@byecode/ui'
import type { EdgeValue } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import { isEmpty } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { InheritLabel } from '@/components/InheritLabel'
import { transform2Integer, transform2Number } from '@/utils/number'

import { SizeIcon } from '../SizeSetting/SizeIcon'

interface RadiusSizeProps {
    title: React.ReactNode
    value?: EdgeValue
    enablePadding?: boolean
    breakKeys?: string[]
    onChange: (val: EdgeValue) => void
}

const SCxTitle = styled.div`
    height: 32px;
`

export const RadiusSize: React.FC<RadiusSizeProps> = ({ title, value, enablePadding, breakKeys, onChange }) => {
    const [moveOpen, setMoveOpen] = useImmer<[boolean, boolean, boolean, boolean]>([false, false, false, false])
    const initValue: EdgeValue = useMemo(() => (!value || isEmpty(value) ? [0, 0, 0, 0] : value), [value])
    const [radiusValue, setRadiusValue] = useImmer<EdgeValue>(initValue)
    const [left, top, right, bottom] = radiusValue
    const [open, setOpen] = useState(!!(top !== bottom || right !== left || top !== left))

    useUpdateEffect(() => {
        if (isDeepEqual(initValue, radiusValue)) {
            return
        }
        setRadiusValue(initValue)
    }, [initValue])

    const handleRadiusChange = useCallback(
        (value: string, index: number) => {
            const num = value ? transform2Number(value) : undefined
            setRadiusValue(draft => {
                if (!open) {
                    return [num, num, num, num]
                }
                draft[index] = num
            })
        },
        [open, setRadiusValue]
    )

    // const handleMoveChange = useCallback(
    //     (val: number, index: number) => {
    //         const v: EdgeValue = [...radiusValue]
    //         const num = transform2Integer(val)
    //         v[index] = num
    //         setRadiusValue(v)
    //         if (isDeepEqual(v, initValue)) {
    //             return
    //         }
    //         onChange(v)
    //     },
    //     [initValue, onChange, radiusValue, setRadiusValue]
    // )

    const handleMoveChange = useCallback(
        (val: number, index: number) => {
            let v: EdgeValue = [...radiusValue]
            const num = transform2Integer(val)
            if (open) {
                v[index] = num
            } else {
                v = [num, num, num, num]
            }
            setRadiusValue(v)
            if (isDeepEqual(v, initValue)) {
                return
            }
            onChange(v)
        },
        [initValue, onChange, open, radiusValue, setRadiusValue]
    )

    const handleMoveOpen = useCallback(
        (val: boolean, index: number) => {
            setMoveOpen(draft => {
                draft[index] = val
            })
        },
        [setMoveOpen]
    )

    const handleSubmit = useCallback(
        (val: string, index: number) => {
            const num = transform2Number(Number(val).toFixed(2))
            const v: EdgeValue = [...radiusValue]
            v[index] = num
            setRadiusValue(v)
            if (isDeepEqual(v, initValue)) {
                return
            }
            onChange(v)
        },
        [initValue, onChange, radiusValue, setRadiusValue]
    )

    const handleTrigger = useCallback(() => {
        if (open && (Number(top) !== Number(bottom) || Number(left) !== Number(right) || Number(top) !== Number(left))) {
            const v: EdgeValue = [left, left, left, left]
            if (isDeepEqual(v, initValue)) {
                setOpen(!open)
                return
            }
            onChange(v)
        }
        setOpen(!open)
    }, [bottom, initValue, left, onChange, open, right, top])

    const resizeStyle = useMemo(
        () =>
            moveOpen.map(item => {
                if (!item) {
                    return {}
                }
                return {
                    borderColor: 'var(--color-main)',
                    backgroundColor: 'var(--color-gray-200)'
                }
            }),
        [moveOpen]
    )

    return (
        <>
            <ListItem4ByecodeUi enablePadding={enablePadding} justifyContent="space-between" alignItems="flex-start">
                <SCxTitle>{title}</SCxTitle>

                <Flex gap={8} justifyContent="flex-start">
                    {open ? (
                        <Flex styles={{ root: { width: 140 } }} gap="8px" wrap="wrap">
                            <Flex styles={{ root: { width: '100%' } }} gap="8px">
                                <Input
                                    value={left}
                                    placeholder=""
                                    styles={{
                                        wrapper: resizeStyle[0]
                                    }}
                                    style={{ width: 76 }}
                                    type="number"
                                    prefix={
                                        <SizeIcon
                                            type="RadiusLeftTop"
                                            min={0}
                                            value={left}
                                            onChangeMove={val => handleMoveOpen(val, 0)}
                                            onChange={val => handleMoveChange(val, 0)}
                                        />
                                    }
                                    onFocus={e => {
                                        e.target.select()
                                    }}
                                    onChange={e => {
                                        handleRadiusChange(e.target.value, 0)
                                    }}
                                    onKeyDownCapture={ev => {
                                        if (ev.key === 'Enter') {
                                            ev.currentTarget.blur()
                                        }
                                    }}
                                    onBlur={ev => {
                                        const v = ev.target.value
                                        handleSubmit(v, 0)
                                    }}
                                />
                                <Input
                                    value={top}
                                    placeholder=""
                                    styles={{
                                        wrapper: resizeStyle[1]
                                    }}
                                    style={{ width: 76 }}
                                    type="number"
                                    prefix={
                                        <SizeIcon
                                            type="RadiusRightTop"
                                            min={0}
                                            value={left}
                                            onChangeMove={val => handleMoveOpen(val, 1)}
                                            onChange={val => handleMoveChange(val, 1)}
                                        />
                                    }
                                    onFocus={e => {
                                        e.target.select()
                                    }}
                                    onChange={e => {
                                        handleRadiusChange(e.target.value, 1)
                                    }}
                                    onKeyDownCapture={ev => {
                                        if (ev.key === 'Enter') {
                                            ev.currentTarget.blur()
                                        }
                                    }}
                                    onBlur={ev => {
                                        const v = ev.target.value
                                        handleSubmit(v, 1)
                                    }}
                                />
                            </Flex>
                            <Flex styles={{ root: { width: 140 } }} gap="8px">
                                <Input
                                    value={right}
                                    onChange={e => {
                                        handleRadiusChange(e.target.value, 2)
                                    }}
                                    type="number"
                                    placeholder=""
                                    styles={{
                                        wrapper: resizeStyle[2]
                                    }}
                                    style={{ width: 76 }}
                                    prefix={
                                        <SizeIcon
                                            type="RadiusLeftBottom"
                                            min={0}
                                            value={left}
                                            onChangeMove={val => handleMoveOpen(val, 2)}
                                            onChange={val => handleMoveChange(val, 2)}
                                        />
                                    }
                                    onFocus={e => {
                                        e.target.select()
                                    }}
                                    onKeyDownCapture={ev => {
                                        if (ev.key === 'Enter') {
                                            ev.currentTarget.blur()
                                        }
                                    }}
                                    onBlur={ev => {
                                        const v = ev.target.value
                                        handleSubmit(v, 2)
                                    }}
                                />
                                <Input
                                    value={bottom}
                                    onChange={e => {
                                        handleRadiusChange(e.target.value, 3)
                                    }}
                                    type="number"
                                    placeholder=""
                                    styles={{
                                        wrapper: resizeStyle[3]
                                    }}
                                    style={{ width: 76 }}
                                    prefix={
                                        <SizeIcon
                                            type="RadiusRightBottom"
                                            min={0}
                                            value={left}
                                            onChangeMove={val => handleMoveOpen(val, 3)}
                                            onChange={val => handleMoveChange(val, 3)}
                                        />
                                    }
                                    onFocus={e => {
                                        e.target.select()
                                    }}
                                    onKeyDownCapture={ev => {
                                        if (ev.key === 'Enter') {
                                            ev.currentTarget.blur()
                                        }
                                    }}
                                    onBlur={ev => {
                                        const v = ev.target.value
                                        handleSubmit(v, 3)
                                    }}
                                />
                            </Flex>
                        </Flex>
                    ) : (
                        <Input
                            value={left}
                            placeholder=""
                            styles={{
                                wrapper: resizeStyle[0]
                            }}
                            style={{ width: 140 }}
                            type="number"
                            prefix={
                                <SizeIcon
                                    type="Radius"
                                    min={0}
                                    value={left}
                                    onChangeMove={val => handleMoveOpen(val, 0)}
                                    onChange={val => handleMoveChange(val, 0)}
                                />
                            }
                            onFocus={e => {
                                e.target.select()
                            }}
                            onChange={e => {
                                handleRadiusChange(e.target.value, 0)
                            }}
                            onKeyDownCapture={ev => {
                                if (ev.key === 'Enter') {
                                    ev.currentTarget.blur()
                                }
                            }}
                            onBlur={ev => {
                                const v = ev.target.value
                                handleSubmit(v, 0)
                            }}
                        />
                    )}
                    <Button
                        type="primary"
                        style={{ backgroundColor: 'var(--color-gray-100)' }}
                        icon={<IconFont type={open ? 'ArrowPackUp' : 'ArrowOpen'} size={16} color="var(--color-gray-500)" />}
                        onClick={() => handleTrigger()}
                    />
                </Flex>
            </ListItem4ByecodeUi>
        </>
    )
}
