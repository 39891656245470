import { Select } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { useMemo } from 'react'

import { openPageStackAtom } from '@/atoms/page/action'
import { stackFactory } from '@/atoms/page/utils'
import { usePageList } from '@/hooks/usePage'

type Props = {
    appId: string
    pageId: string
}

export const PageSelector: FC<Props> = ({ appId, pageId }) => {
    const allPageList = usePageList()

    const options = useMemo(() => {
        return allPageList.map(item => ({
            label: item.name || '无标题',
            value: item.id
        }))
    }, [allPageList])

    const { run: openPageStack } = useAtomAction(openPageStackAtom)

    return <Select m={12} options={options} value={pageId} onChange={v => openPageStack(stackFactory({ appId, pageId: v }))} />
}
