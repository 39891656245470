import { flex } from "@lighthouse/bui";
import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 8px;
    border: 1px solid var(--color-gray-200);
`

export const Header = styled.div`
    ${flex}
    padding: 12px 8px;
    justify-content: space-between;
    background-color: var(--color-gray-50);
    gap: 8px;
`

export const LeftFill = styled.div`
    /* width: 126px; */
    ${flex}
    align-items: center;
    overflow: hidden;
    gap: 8px;
    flex: 1;
`

export const RightFill = styled.div`
    width: 180px;
    /* max-width: 80%; */
    ${flex}
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
`

export const Content = styled.div`
    margin: 8px 0;
`

export const Tip = styled.div`
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    margin: 4px 0;
    line-height: 20px;
`
