import type { BlockAbstract, ButtonBlockAbstract, ContainerBlockAbstract, ImageBlockAbstract, TextBlockAbstract } from '@lighthouse/core'
import { ActionItemPattern, BACKGROUND_TYPE, BlockType, DIRECTION, FLEX_ALIGN, SHAPE, TEXT_ALIGNMENT } from '@lighthouse/core'
import { getDefaultAction, TEXT_FONT_NORMAL } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'

import { blockPicture, blockText } from './constant'
import { generateButtonBlock, getInitButton } from './generate/button'
import { getBlockInitConfig } from './help'
import { COL_CONTAINER, ROW_CONTAINER } from './shared'

export function getDefaultCombinationBlocksOrNode(): BlockAbstract | undefined {
    const containerBlock = getBlockInitConfig(COL_CONTAINER) as ContainerBlockAbstract
    if (!containerBlock) {
        return
    }
    const image: ImageBlockAbstract = {
        ...(getBlockInitConfig(blockPicture) as ImageBlockAbstract),
        config: {
            sourceType: 'default',
            isPreview: true,
            swipeSpeed: 2,
            variant: 'default',
            showTitle: true,
            align: 'center',
            fitType: 'cover',
            shape: 'square',
            radius: '16',
            ratio: '7',
            imageClickEvent: 'custom',
            sources: [
                {
                    link: '',
                    size: 441919,
                    title: '',
                    name: '',
                    type: 'image',
                    url: 'https://byecode-libs.oss-cn-shanghai.aliyuncs.com/libs/backend/touxiang.png'
                }
            ],
            action: getDefaultAction(),
            breakPoint: {
                id: 'desktop',
                name: '电脑端',
                size: {
                    width: {
                        size: 140,
                        unit: 'px'
                    },
                    height: {
                        size: 'auto'
                    },
                    overflow: 'hidden'
                },
                position: {
                    type: 'relative'
                },
                layout: {},
                visibility: {
                    visible: 'VISIBLE'
                }
            },
            breakPoints: [
                {
                    id: 'mobile',
                    name: '移动端',
                    breakKeys: ['size.width'],
                    size: {
                        width: {
                            size: 90,
                            unit: 'px'
                        },
                        height: {
                            size: 'auto'
                        }
                    }
                }
            ]
        }
    }
    const stress: TextBlockAbstract = {
        ...(getBlockInitConfig(blockText) as TextBlockAbstract),
        config: {
            content: {
                value: {
                    type: 'doc',
                    content: [
                        {
                            type: 'paragraph',
                            content: [
                                {
                                    text: '强调文本',
                                    type: 'text',
                                    marks: [
                                        {
                                            type: 'bold'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },

            breakPoint: {
                id: 'desktop',
                name: '电脑端',
                visibility: {
                    visible: 'VISIBLE'
                },
                font: {
                    fontFamily: TEXT_FONT_NORMAL,
                    fontSize: 1,
                    letterSpacing: 0,
                    lineHeight: 1
                },
                size: {
                    width: {
                        size: 'fill'
                    },
                    height: {
                        size: 'auto'
                    },
                    overflow: 'hidden'
                },
                position: {
                    type: 'relative'
                },
                design: {
                    color: {
                        color: '--color-app-main',
                        gradient: {
                            angle: 90,
                            height: 50,
                            mode: 'linear',
                            stops: [
                                {
                                    color: '#2D01FF',
                                    percentage: 0
                                },
                                {
                                    color: '#F00000',
                                    percentage: 100
                                }
                            ],
                            width: 50
                        },
                        type: 'color'
                    }
                },
                layout: {}
            },
            breakPoints: [
                {
                    id: 'mobile',
                    name: '移动端'
                }
            ]
        }
    }
    const subTitle: TextBlockAbstract = {
        ...(getBlockInitConfig(blockText) as TextBlockAbstract),
        config: {
            content: {
                value: {
                    type: 'doc',
                    content: [
                        {
                            type: 'paragraph',
                            content: [
                                {
                                    type: 'text',
                                    text: '副标题'
                                }
                            ]
                        }
                    ]
                }
            },
            breakPoint: {
                id: 'desktop',
                name: '电脑端',
                visibility: {
                    visible: 'VISIBLE'
                },
                position: {
                    type: 'relative'
                },
                design: {
                    color: {
                        color: '#999999',
                        gradient: {
                            angle: 90,
                            height: 50,
                            mode: 'linear',
                            stops: [
                                {
                                    color: '#2D01FF',
                                    percentage: 0
                                },
                                {
                                    color: '#F00000',
                                    percentage: 100
                                }
                            ],
                            width: 50
                        },
                        type: 'color'
                    }
                },
                font: {
                    advance: '',
                    fontFamily: TEXT_FONT_NORMAL,
                    lineHeight: 1.5,
                    fontSize: 1,
                    letterSpacing: 0
                },
                size: {
                    width: {
                        size: 'fill'
                    },
                    height: {
                        size: 'auto'
                    },
                    overflow: 'hidden'
                }
            },
            breakPoints: [
                {
                    id: 'mobile',
                    name: '移动端'
                }
            ]
        }
    }

    const title: TextBlockAbstract = {
        ...(getBlockInitConfig(blockText) as TextBlockAbstract),
        config: {
            content: {
                value: {
                    type: 'doc',
                    content: [
                        {
                            type: 'paragraph',
                            content: [
                                {
                                    text: '标题',
                                    type: 'text',
                                    marks: [
                                        {
                                            type: 'bold'
                                        }
                                    ]
                                }
                            ]
                        }
                    ]
                }
            },
            breakPoint: {
                id: 'desktop',
                name: '电脑端',
                font: {
                    advance: '',
                    fontFamily: TEXT_FONT_NORMAL,
                    fontSize: 2,
                    letterSpacing: 0,
                    lineHeight: 1
                },
                size: {
                    width: {
                        size: 'fill'
                    },
                    height: {
                        size: 'auto'
                    },
                    overflow: 'hidden'
                },
                design: {
                    color: {
                        color: '#000',
                        type: 'color'
                    }
                },
                position: {
                    type: 'relative'
                },
                layout: {},
                visibility: {
                    visible: 'VISIBLE'
                }
            },
            breakPoints: [
                {
                    id: 'mobile',
                    name: '移动端'
                }
            ]
        }
    }

    const textContainerViewId = nanoid()
    const textContainer: ContainerBlockAbstract = {
        ...containerBlock,
        id: nanoid(),
        config: {
            viewList: [
                {
                    id: textContainerViewId,
                    name: '面板 01'
                }
            ],
            breakPoint: {
                id: 'desktop',
                name: '电脑端',
                size: {
                    width: {
                        size: 'fill'
                    },
                    height: {
                        size: 'auto'
                    },
                    overflow: 'hidden'
                },
                visibility: {
                    visible: 'VISIBLE'
                },
                design: {},
                position: {
                    type: 'relative'
                },
                layout: {
                    align: {
                        direction: DIRECTION.vertical,
                        alignX: FLEX_ALIGN['flex-start'],
                        alignY: FLEX_ALIGN['center']
                    },
                    gap: 4,
                    padding: [0, 0, 0, 0]
                }
            },
            breakPoints: [
                {
                    id: 'mobile',
                    name: '移动端'
                }
            ]
        },
        children: [
            {
                id: textContainerViewId,
                children: [stress, title, subTitle]
            }
        ]
    }

    const combinationContainerViewId = nanoid()
    const combinationContainer: ContainerBlockAbstract = {
        ...containerBlock,
        config: {
            viewList: [
                {
                    id: combinationContainerViewId,
                    name: '面板 01'
                }
            ],
            breakPoint: {
                id: 'desktop',
                name: '电脑端',
                visibility: {
                    visible: 'VISIBLE'
                },
                size: {
                    width: {
                        size: 'fill'
                    },
                    height: {
                        size: 'auto'
                    },
                    overflow: 'hidden'
                },
                design: {},
                position: {
                    type: 'relative'
                },
                layout: {
                    align: {
                        direction: DIRECTION.horizontal,
                        alignX: FLEX_ALIGN['flex-start'],
                        alignY: FLEX_ALIGN['center']
                    },
                    gap: 8,
                    padding: [8, 8, 8, 8]
                }
            },
            breakPoints: [
                {
                    id: 'mobile',
                    name: '移动端'
                }
            ]
        },
        children: [
            {
                id: combinationContainerViewId,
                children: [image, textContainer]
            }
        ]
    }

    return combinationContainer
}

export const getDefaultHighlightBlocksOrNode = function (): BlockAbstract | undefined {
    const textBlock: TextBlockAbstract = {
        ...(getBlockInitConfig(blockText) as TextBlockAbstract),
        config: {
            content: {
                value: {
                    content: [
                        {
                            content: [
                                {
                                    text: '高亮文本',
                                    type: 'text'
                                }
                            ],
                            type: 'paragraph'
                        }
                    ],
                    type: 'doc'
                }
            },
            breakPoint: {
                id: 'desktop',
                name: '电脑端',
                visibility: {
                    visible: 'VISIBLE'
                },
                font: {
                    advance: '',
                    align: TEXT_ALIGNMENT.left,
                    fontFamily: TEXT_FONT_NORMAL,
                    fontSize: 1,
                    lineHeight: 1.5,
                    letterSpacing: 0
                },
                position: {
                    type: 'relative'
                },
                design: {
                    color: {
                        color: '#0d19fc',
                        gradient: {
                            angle: 90,
                            height: 50,
                            mode: 'linear',
                            stops: [
                                {
                                    color: '#2D01FF',
                                    percentage: 0
                                },
                                {
                                    color: '#F00000',
                                    percentage: 100
                                }
                            ],
                            width: 50
                        },
                        type: 'color'
                    }
                },
                size: {
                    width: {
                        size: 'fill'
                    },
                    height: {
                        size: 'auto'
                    }
                }
            },
            breakPoints: [
                {
                    id: 'mobile',
                    name: '移动端'
                }
            ]
        }
    }

    const textContainer = getBlockInitConfig(ROW_CONTAINER) as ContainerBlockAbstract
    const viewId = nanoid()
    const textContainerBlock: ContainerBlockAbstract = {
        ...textContainer,
        title: '高亮文本',
        config: {
            viewList: [
                {
                    id: viewId,
                    name: '面板 01'
                }
            ],
            breakPoint: {
                id: 'desktop',
                name: '电脑端',
                visibility: {
                    visible: 'VISIBLE'
                },
                size: {
                    width: {
                        size: 'fill'
                    },
                    height: {
                        size: 'auto'
                    },
                    // minHeight: {
                    //     size: 80,
                    //     unit: 'px'
                    // },
                    overflow: 'hidden'
                },
                position: {
                    type: 'relative'
                },
                layout: {
                    align: {
                        direction: DIRECTION.vertical,
                        alignX: FLEX_ALIGN['flex-start'],
                        alignY: FLEX_ALIGN['flex-start']
                    },
                    gap: 8,
                    padding: [12, 12, 12, 12]
                },
                design: {
                    radius: [8, 8, 8, 8],
                    background: {
                        type: BACKGROUND_TYPE.color,
                        color: '#0d19fc1f'
                    }
                }
            },
            breakPoints: [
                {
                    id: 'mobile',
                    name: '移动端'
                }
            ]
        },
        children: [{ id: viewId, children: [textBlock] }]
    }

    return textContainerBlock
}

export const getDefaultIconBtnOrNode = function (): BlockAbstract {
    const blockConfig = generateButtonBlock()
    const buttonBlock: ButtonBlockAbstract = {
        id: nanoid(),
        type: BlockType.button,
        title: '按钮',
        config: {
            ...blockConfig,
            // ...COMMON_BLOCK_CONFIG,
            ...getInitButton(ActionItemPattern.secondary),
            showType: 'icon'
        }
    }

    return buttonBlock
}

export const getDefaultCapsuleBtnOrNode = function (): BlockAbstract {
    const blockConfig = generateButtonBlock()
    return {
        id: nanoid(),
        type: BlockType.button,
        title: '按钮',
        config: {
            ...blockConfig,
            // ...COMMON_BLOCK_CONFIG,
            shape: SHAPE.round
        }
    }
}
