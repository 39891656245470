import { IconFont } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import { useLanguageContext } from '../../../contexts'

export interface UploaderNavigatorProps {
    type?: string
    onClose?: () => void
}

const SCxNavigatorContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
`

const SCxNavigatorTitle = styled.p`
    font-size: 20px;
    font-weight: 500;
`

const SCxNavigatorCloseIcon = styled(IconFont)`
    cursor: pointer;
`

export const UploaderNavigator: React.FC<UploaderNavigatorProps> = ({ type, onClose }) => {
    const {  convertTextByLanguage } = useLanguageContext()
    return (
        <SCxNavigatorContainer>
            <SCxNavigatorTitle>{convertTextByLanguage('localImport')}</SCxNavigatorTitle>
            <SCxNavigatorCloseIcon type="Close" size={16} onClick={onClose} />
        </SCxNavigatorContainer>
    )
}
