import type { ApplicationPreviewEnum } from "@lighthouse/shared"

export function parseValue(value: string) {
    // 使用正则表达式提取数字部分
    const match = /[\d.]+/.exec(value)
    return match ? Number.parseFloat(match[0]) : 0
}

export const getApplicationScale = (previewType: ApplicationPreviewEnum) => {
    if (previewType !== 'mobile') {
        return 1
    }
    const applicationWrapper = document.querySelector('.applicationWrapper')
    if (!applicationWrapper) {
        return 1
    }

    const { height } = applicationWrapper.getBoundingClientRect()
    if (height < 876) {
        return height / 876
    }
    return 1
}
