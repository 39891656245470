import type {
    ApplicationId,
    BaseBreakPointProtocol,
    BlockId,
    EnvironmentId,
    FilterableProtocol,
    PageId,
    RecordId,
    ScopeId,
    SortableProtocol
} from '../../protocols'
import { BlockType } from '../Basic'
import { BaseBlock } from './BaseBlock'

export enum FilterBlockMode {
    'normal' = 'normal',
    'tile' = 'tile'
}

export enum FilterSelectWay {
    'singleSelect' = 'singleSelect',
    'multiSelect' = 'multiSelect'
}

export enum FilterWay {
    'textFilter' = 'textFilter',
    'numberFilter' = 'numberFilter',
    'dateFilter' = 'dateFilter',
    'personFilter' = 'personFilter',
    'selectFilter' = 'selectFilter',
    'cascadeFilter' = 'cascadeFilter',
    'boolFilter' = 'boolFilter'
}

export type NumberRangeArray = [number | undefined, number | undefined]

export type FilterBlockIdentify =
    | `${ApplicationId}-${EnvironmentId}-${PageId}-${RecordId}-${BlockId}`
    | `${ApplicationId}-${EnvironmentId}-${PageId}-${RecordId}-${ScopeId}-${BlockId}`
    | `${ApplicationId}-${EnvironmentId}-${PageId}-${BlockId}`
    | `${ApplicationId}-${EnvironmentId}-${PageId}-${ScopeId}-${BlockId}`

export type FilterBlockItemValue = {
    type: FilterWay
    value: string[]
    customValue?: NumberRangeArray
}

export type FilterBlockValue = Record<string, FilterBlockItemValue>

export type NumberOption = {
    id: string
    label: string
    value: [number | undefined, number | undefined]
}

export enum NumberRangeMode {
    'slider' = 'slider',
    'custom' = 'custom',
    'disable' = 'disable'
}

export type TextFilterConfig = Record<'filterWay', FilterWay.textFilter> &
    FilterableProtocol &
    SortableProtocol & {
        pointer?: string
        fieldPointer?: string
        showFieldPointer?: string
    }

export type NumberFilterConfig = Record<'filterWay', FilterWay.numberFilter> & {
    numberOptions?: NumberOption[]
    numberRangeMode?: NumberRangeMode
    numberRange?: [number | undefined, number | undefined]
    step?: number
}

export type DateFilterConfig = Record<'filterWay', FilterWay.dateFilter> & {
    dateVariables?: string[]
    isDateRange?: boolean
}

export type PersonFilterConfig = Record<'filterWay', FilterWay.personFilter> & FilterableProtocol & SortableProtocol

export type SelectFilterConfig = Record<'filterWay', FilterWay.selectFilter> & {
    pointer?: string
    fieldPointer?: string
    customOptions?: string[]
}

export type CascadeFilterConfig = Record<'filterWay', FilterWay.cascadeFilter> & {
    pointer?: string
    fieldPointer?: string
    parentFieldPointer?: string
    sortFieldPointer?: string
    showFieldPointer?: string
}

export type BoolFilterConfig = Record<'filterWay', FilterWay.boolFilter>

export type InnerTypeFilterConfig =
    | TextFilterConfig
    | NumberFilterConfig
    | DateFilterConfig
    | PersonFilterConfig
    | SelectFilterConfig
    | CascadeFilterConfig
    | BoolFilterConfig

export type FilterBlockItemConfig = InnerTypeFilterConfig & {
    id: string
    title: string
    selectWay: FilterSelectWay
    filterWay: FilterWay
}

export interface FilterBlockConfig extends BaseBreakPointProtocol {
    mode: FilterBlockMode
    filterItems: FilterBlockItemConfig[]
    labelWidth?: number
    showLabel: boolean
}

export abstract class FilterBlockAbstract extends BaseBlock {
    type = BlockType.filter

    abstract override config: FilterBlockConfig
}

// 111

// import type { BlockProtocol, BlockVisibilityProtocol, DTSelectItem, FilterableProtocol, SortableProtocol } from "../../protocols"
// import { BlockType } from "../Basic"

// export enum FilterBlockMode {
//     'normal' = 'normal',
//     'tile' = 'tile'
// }

// export enum FilterSelectWay {
//     'singleSelect' = 'singleSelect',
//     'multiSelect' = 'multiSelect'
// }

// export enum FilterWay {
//     'textFilter' = 'textFilter',
//     'numberFilter' = 'numberFilter',
//     'dateFilter' = 'dateFilter',
//     'personFilter' = 'personFilter',
//     'selectFilter' = 'selectFilter',
//     'cascadeFilter' = 'cascadeFilter',
// }

// export type NumberOption = {
//     label: string
//     value: [number, number]
// }

// export enum NumberRangeMode {
//     'slider' = 'slider',
//     'custom' = 'custom',
//     'disable' = 'disable',
// }

// export type TextFilterConfigure = {
//     textFilterConfigure?: FilterableProtocol & SortableProtocol & {
//         pointer?: string
//         fieldPointer?: string
//     }
// }

// export type NumberFilterConfigure = {
//     numberFilterConfigure?: {
//         numberOptions?: NumberOption[]
//         numberRangeMode?: NumberRangeMode
//         numberRange?: [number | undefined, number | undefined]
//         step?: number
//     }
// }

// export type DateFilterConfigure = {
//     dateFilterConfigure?: {
//         dateVariables?: string[]
//         isDateRange?: boolean
//     }
// }

// export type PersonFilterConfigure = {
//     personFilterConfigure?: FilterableProtocol & SortableProtocol
// }

// export type SelectFilterConfigure = {
//     selectFilterConfigure?: {
//         pointer?: string
//         fieldPointer?: string
//         customOptions?: DTSelectItem[]
//     }
// }

// export type CascadeFilterConfigure = {
//     cascadeFilterConfigure?: {
//         pointer?: string
//         fieldPointer?: string
//         parentFieldPointer?: string
//         sortFieldPointer?: string
//     }
// }

// export type TextFilterConfig = Record<'filterWay', FilterWay.textFilter> & TextFilterConfigure

// export type NumberFilterConfig = Record<'filterWay', FilterWay.numberFilter> & NumberFilterConfigure

// export type DateFilterConfig = Record<'filterWay', FilterWay.dateFilter> & DateFilterConfigure

// export type PersonFilterConfig = Record<'filterWay', FilterWay.personFilter> & PersonFilterConfigure

// export type SelectFilterConfig = Record<'filterWay', FilterWay.selectFilter> & SelectFilterConfigure

// export type CascadeFilterConfig = Record<'filterWay', FilterWay.cascadeFilter> & CascadeFilterConfigure

// export type InnerTypeFilterConfig = TextFilterConfig | NumberFilterConfig | DateFilterConfig | PersonFilterConfig | SelectFilterConfig | CascadeFilterConfig

// export type FilterBlockConfig = BlockVisibilityProtocol & {
//     title: string
//     mode: FilterBlockMode
//     selectWay: FilterSelectWay
//     filterWay: FilterWay
// }

// export abstract class FilterBlockAbstract implements BlockProtocol {
//     abstract id: string

//     type = BlockType.filter

//     abstract title: string

//     abstract config: FilterBlockConfig
// }
