import React, { useCallback, useEffect, useRef, useState } from 'react'

import * as SC from './styles'
import type { NoticePropsType } from './types'

export const Notice: React.FC<NoticePropsType> = ({
    iconColor,
    content = '这是一条消息',
    icon = false,
    linkText,
    noticeKey,
    delayed = 3000,
    onLinkClick,
    onClose,
    style
}) => {
    const [show, setShow] = useState<boolean>(true)

    const timer = useRef<number | null>(null)
    const transitionTimer = useRef<number | null>(null)
    const moveTimer = useRef<number | null>(null)
    // let timer:

    const close = useCallback(() => {
        if (onClose) {
            setShow(false)
            transitionTimer.current = window.setTimeout(() => {
                onClose(noticeKey)
            }, 400)
        }
    }, [noticeKey, onClose])

    const startCloseTimer = useCallback(() => {
        timer.current = window.setTimeout(() => {
            close()
        }, delayed)
    }, [close, delayed])

    const clearCloseTimer = () => {
        timer.current && clearTimeout(timer.current)
        transitionTimer.current && clearTimeout(transitionTimer.current)
        moveTimer.current && clearTimeout(moveTimer.current)
    }

    // const compareLength = useCallback(, [length])

    // useEffect(() => {
    //     if (lengthRef.current > length) {
    //         lengthRef.current = length
    //         setIsMove(true)
    //         moveTimer.current = setTimeout(() => {
    //             setIsMove(false)
    //         }, 300)
    //         return
    //     }
    //     moveTimer.current && clearTimeout(moveTimer.current)
    //     lengthRef.current = length
    //     return
    // }, [keyIndex, length])

    useEffect(() => {
        startCloseTimer()

        return clearCloseTimer
    }, [startCloseTimer])

    return (
        <SC.Notice>
            <SC.NoticeMessage className={show ? 'start' : 'stop'}>
                <SC.NoticeMessageContent style={style}>
                    <SC.NoticeFlex>
                        {icon ? <SC.NoticeIcon type={icon} fill={iconColor} /> : null}
                        <SC.NoticeTxt>{content}</SC.NoticeTxt>
                        {linkText && <SC.NoticeLinkText onClick={onLinkClick}>{linkText}</SC.NoticeLinkText>}
                    </SC.NoticeFlex>
                </SC.NoticeMessageContent>
            </SC.NoticeMessage>
        </SC.Notice>
    )
}
