import type { DataSourceAbstract, FieldInputADTValue, InputValueItem, RecordLikeProtocol } from '@lighthouse/core';
import { RecordAbstract } from '@lighthouse/core'
import { pick } from 'rambda'
import React, { createContext, useContext, useMemo } from 'react'

import type { FieldInputError, FieldInputErrors } from '../components'

type SubFormRecord = {
    id: string
    content: Record<string, InputValueItem>
}
export type SubFormErrors = { recordId: string; blockId: string; error: FieldInputError; columnId: string }[]
export type FormModuleType = {
    defaultValue: {
        inputList: InputValueItem[]
        initInputList: InputValueItem[]
        subFormDataMap: Record<string, SubFormRecord[]>
        initSubFormDataMap: Record<string, SubFormRecord>
    }
    disabled?: boolean
    pointer: string
    dataSource?: DataSourceAbstract
    children?: React.ReactNode
    errors?: FieldInputErrors
    subFormErrors?: SubFormErrors
    record?: RecordLikeProtocol
    type: 'form' | 'field'
    onChange?: (blockId: string, value: FieldInputADTValue) => void
    onCodeChange?: (blockId: string, code: string) => void
    onChangeSubForm?: (v: (v: Record<string, SubFormRecord[]>) => void) => void
    onSubFormInputChange?: (blockId: string, recordId: string, columnId: string, value: FieldInputADTValue) => void
    onSubFormInputBlur?: (blockId: string, recordId: string, columnId: string) => void
}

const FormModuleContext = createContext<FormModuleType>({
    pointer: '',
    defaultValue: {
        inputList: [],
        initInputList: [],
        subFormDataMap: {},
        initSubFormDataMap: {}
    },
    type: 'field'
})

export const FormModuleProvider = ({ children, value }: { value: FormModuleType; children?: React.ReactNode }) => {
    return <FormModuleContext.Provider value={value}> {children}</FormModuleContext.Provider>
}

export const useFormModuleContext = () => {
    return useContext(FormModuleContext)
}

export const useFormModuleSubForm = (id: string) => {
    const { defaultValue, subFormErrors, onChangeSubForm, onSubFormInputBlur, onSubFormInputChange } = useFormModuleContext()
    return useMemo<{
        defaultValue: SubFormRecord[]
        initValue: SubFormRecord
        subFormErrors?: SubFormErrors
        onChangeSubForm: ((v: (v: Record<string, SubFormRecord[]>) => void) => void) | undefined
        onSubFormInputChange?: (blockId: string, recordId: string, columnId: string, value: FieldInputADTValue) => void
        onSubFormInputBlur?: (blockId: string, recordId: string, columnId: string) => void
    }>(
        () => ({
            defaultValue: defaultValue.subFormDataMap[id] ?? [],
            initValue: defaultValue.initSubFormDataMap[id],
            subFormErrors: subFormErrors?.filter(v => v.blockId === id),
            onChangeSubForm,
            onSubFormInputChange,
            onSubFormInputBlur
        }),
        [
            defaultValue.initSubFormDataMap,
            defaultValue.subFormDataMap,
            id,
            onChangeSubForm,
            onSubFormInputBlur,
            onSubFormInputChange,
            subFormErrors
        ]
    )
}
