import styled from 'styled-components'

import { AnimationDecorators } from '../../components'

export const IframeContainer = styled(AnimationDecorators)`
    width: 100%;
    height: 100%;
    padding: var(--block-padding);
    display: flex;
    align-items: center;
    border-radius: inherit;
    overflow: hidden;
`

export const Iframe = styled.iframe`
    width: 100%;
    height: 100%;
`

export const EmptyImg = styled.img`
    width: 140px;
    height: 140px;
    border-radius: 50%;
`
