import { OnOffBlock } from '@lighthouse/block'
import type { OnOffBlockAbstract } from '@lighthouse/core'
import React from 'react'

import { useActionTrigger } from '@/hooks/useActionTrigger'

interface OnOffBlockProps {
    blockData: OnOffBlockAbstract
}

const OnOffBlockController: React.FC<OnOffBlockProps> = ({ blockData }) => {
    const { handleActionTrigger } = useActionTrigger()

    return <OnOffBlock onActionTrigger={handleActionTrigger} blockData={blockData} />
}

export default OnOffBlockController
