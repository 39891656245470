import { Flex, IconFont, Input, Popover } from '@byecode/ui'
import type { BackgroundNoImageProtocol, Border } from '@lighthouse/core'
import { type ContainerBlockConfig, BACKGROUND_TYPE, EDGE_MODE } from '@lighthouse/core'
import { FilledInput, FillPickerPopoverDropdown, ListItem4ByecodeUi } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import produce from 'immer'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useImmer } from 'use-immer'

import { useColorSystemAction } from '@/hooks/useColorSystemAction'
import { transform2Integer } from '@/utils/number'

import { InheritLabel } from '../../InheritLabel'
import { combinePath } from './utils'

// interface BorderFieldProps {
//     prefixPath?: string
//     onSetBreakKey?: (name: string) => void
// }

// export const BorderField: React.FC<BorderFieldProps> = ({ prefixPath, onSetBreakKey }) => {
//     const { control, setValue, getValues } = useFormContext<ContainerBlockConfig>()

//     const borderColor = getValues(combinePath(prefixPath, 'breakPoint.design.border.color'))

//     const colorSystemMethods = useColorSystemAction()

//     const borderName = combinePath(prefixPath, 'breakPoint.design.border')

//     return (
//         <>
//             <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
//                 <InheritLabel label="描边" name="breakPoint.design.border" />
//                 <Controller
//                     control={control}
//                     name={borderName}
//                     render={({ field }) => (
//                         <Popover width={300} position="left-start" withinPortal autoUpdate={false} offsetOptions={168}>
//                             <Popover.Target>
//                                 <FilledInput
//                                     value={field.value}
//                                     onClear={() => {
//                                         field.onChange({ type: BACKGROUND_TYPE.color })
//                                         onSetBreakKey?.(borderName)
//                                     }}
//                                     style={{ width: 180 }}
//                                 />
//                             </Popover.Target>
//                             <FillPickerPopoverDropdown
//                                 title="填充"
//                                 value={field.value}
//                                 onChange={v => {
//                                     const val = {
//                                         ...(v as BackgroundNoImageProtocol),
//                                         mode: field.value?.mode || EDGE_MODE.each,
//                                         each: field.value?.each || [1, 1, 1, 1],
//                                         all: field.value?.all || 1
//                                     }
//                                     if (isDeepEqual(val, field.value)) {
//                                         return
//                                     }
//                                     field.onChange(val)
//                                     onSetBreakKey?.(borderName)
//                                 }}
//                                 enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
//                                 {...colorSystemMethods}
//                             />
//                         </Popover>
//                     )}
//                 />
//             </ListItem4ByecodeUi>
//             {borderColor && (
//                 <>
//                     <ListItem4ByecodeUi justifyContent="flex-end">
//                         <Flex styles={{ root: { width: 180 } }} gap="8px">
//                             <Controller
//                                 control={control}
//                                 name={`${borderName}.each.0`}
//                                 render={({ field }) => (
//                                     <Input
//                                         value={field.value}
//                                         onChange={e => {
//                                             field.onChange(transform2Integer(e.target.value))
//                                             onSetBreakKey?.(borderName)
//                                         }}
//                                         prefix={<IconFont type="LeftStroke" />}
//                                         placeholder=""
//                                         onFocus={e => {
//                                             e.target.select()
//                                         }}
//                                     />
//                                 )}
//                             />
//                             <Controller
//                                 control={control}
//                                 name={`${borderName}.each.1`}
//                                 render={({ field }) => (
//                                     <Input
//                                         value={field.value}
//                                         onChange={e => {
//                                             field.onChange(transform2Integer(e.target.value))
//                                             onSetBreakKey?.(borderName)
//                                         }}
//                                         prefix={<IconFont type="TopStroke" />}
//                                         placeholder=""
//                                         onFocus={e => {
//                                             e.target.select()
//                                         }}
//                                     />
//                                 )}
//                             />
//                         </Flex>
//                     </ListItem4ByecodeUi>
//                     <ListItem4ByecodeUi justifyContent="flex-end">
//                         <Flex styles={{ root: { width: 180 } }} gap="8px">
//                             <Controller
//                                 control={control}
//                                 name={`${borderName}.each.2`}
//                                 render={({ field }) => (
//                                     <Input
//                                         value={field.value}
//                                         onChange={e => {
//                                             field.onChange(transform2Integer(e.target.value))
//                                             onSetBreakKey?.(borderName)
//                                         }}
//                                         prefix={<IconFont type="RightStroke" />}
//                                         placeholder=""
//                                         onFocus={e => {
//                                             e.target.select()
//                                         }}
//                                     />
//                                 )}
//                             />
//                             <Controller
//                                 control={control}
//                                 name={`${borderName}.each.3`}
//                                 render={({ field }) => (
//                                     <Input
//                                         value={field.value}
//                                         onChange={e => {
//                                             field.onChange(transform2Integer(e.target.value))
//                                             onSetBreakKey?.(borderName)
//                                         }}
//                                         prefix={<IconFont type="BottomStroke" />}
//                                         placeholder=""
//                                         onFocus={e => {
//                                             e.target.select()
//                                         }}
//                                     />
//                                 )}
//                             />
//                         </Flex>
//                     </ListItem4ByecodeUi>
//                 </>
//             )}
//         </>
//     )
// }

interface BorderFieldProps {
    label?: React.ReactNode
    value: Border | undefined
    onChange: (val: Border) => void
}

export const BorderField: React.FC<BorderFieldProps> = ({ label, value, onChange }) => {
    const { each, mode, all, color } = value || {}
    const colorSystemMethods = useColorSystemAction()

    return (
        <>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                {label}
                <Popover width={300} position="left-start" withinPortal autoUpdate={false} offsetOptions={168}>
                    <Popover.Target>
                        <FilledInput
                            value={value}
                            onClear={() => {
                                onChange({ type: BACKGROUND_TYPE.color })
                            }}
                            style={{ width: 180 }}
                        />
                    </Popover.Target>
                    <FillPickerPopoverDropdown
                        title="填充"
                        value={value}
                        onChange={v => {
                            const val = {
                                ...(v as BackgroundNoImageProtocol),
                                mode: mode || EDGE_MODE.each,
                                each: each || [1, 1, 1, 1],
                                all: all || 1
                            }
                            if (isDeepEqual(val, value)) {
                                return
                            }
                            onChange(val)
                        }}
                        enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
                        {...colorSystemMethods}
                    />
                </Popover>
            </ListItem4ByecodeUi>
            {color && (
                <>
                    <ListItem4ByecodeUi justifyContent="flex-end">
                        <Flex styles={{ root: { width: 180 } }} gap="8px">
                            <Input
                                value={each?.[0]}
                                onChange={e => {
                                    if(!value){
                                        return
                                    }
                                    const val = transform2Integer(e.target.value)
                                    const newValue = produce(value, draft => {
                                        if(!draft || !draft.each){
                                            return
                                        }
                                        draft.each[0] = val
                                    })
                                    onChange(newValue)
                                }}
                                prefix={<IconFont type="LeftStroke" />}
                                placeholder=""
                                onFocus={e => {
                                    e.target.select()
                                }}
                            />
                            <Input
                                value={each?.[1]}
                                onChange={e => {
                                    if(!value){
                                        return
                                    }
                                    const val = transform2Integer(e.target.value)
                                    const newValue = produce(value, draft => {
                                        if(!draft || !draft.each){
                                            return
                                        }
                                        draft.each[1] = val
                                    })
                                    onChange(newValue)
                                }}
                                prefix={<IconFont type="TopStroke" />}
                                placeholder=""
                                onFocus={e => {
                                    e.target.select()
                                }}
                            />
                        </Flex>
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi justifyContent="flex-end">
                        <Flex styles={{ root: { width: 180 } }} gap="8px">
                            <Input
                                value={each?.[2]}
                                onChange={e => {
                                    if(!value){
                                        return
                                    }
                                    const val = transform2Integer(e.target.value)
                                    const newValue = produce(value, draft => {
                                        if(!draft || !draft.each){
                                            return
                                        }
                                        draft.each[2] = val
                                    })
                                    onChange(newValue)
                                }}
                                prefix={<IconFont type="RightStroke" />}
                                placeholder=""
                                onFocus={e => {
                                    e.target.select()
                                }}
                            />
                            <Input
                                value={each?.[3]}
                                onChange={e => {
                                    if(!value){
                                        return
                                    }
                                    const val = transform2Integer(e.target.value)
                                    const newValue = produce(value, draft => {
                                        if(!draft || !draft.each){
                                            return
                                        }
                                        draft.each[3] = val
                                    })
                                    onChange(newValue)
                                }}
                                prefix={<IconFont type="BottomStroke" />}
                                placeholder=""
                                onFocus={e => {
                                    e.target.select()
                                }}
                            />
                        </Flex>
                    </ListItem4ByecodeUi>
                </>
            )}
        </>
    )
}
