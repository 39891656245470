import { Select } from '@byecode/ui'
import { getFlatAllBlock } from '@lighthouse/block'
import { type BlockAbstract } from '@lighthouse/core'
import type { FlowNode, NodeTypes, WithBlockOption } from '@lighthouse/shared'
import {
    CollapseBox,
    findBlockById,
    getBlockChildren,
    nodeTypeOptions,
    scroll2FlowNode,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { syncComponentsAtom } from '@/atoms/application/state'
import { blockHighlightAtom, lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import type { NodeIdWithScope } from '@/atoms/page/types'

import { ComponentSelector } from '../../Common/ComponentSelector'
import * as SC from '../../styles'

export interface ScrollToActionConfigureProps {
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
    prefixName?: string
}

const generateOptions = (pageBlocks: BlockAbstract[], scope?: string) => {
    const blocks = getFlatAllBlock(pageBlocks)
    return blocks.map(block => {
        return {
            label: block.title,
            value: { id: block.id, scope },
            block,
            level: 0
        }
    }) as WithBlockOption[]
}

const collectBlockAndChildrenWithSync = (
    block: BlockAbstract,
    synchronousId: string,
    syncChildren: BlockAbstract[],
    scope?: string
): WithBlockOption[] => {
    const currentIsSyncInstance = block.id === synchronousId
    const children = currentIsSyncInstance ? syncChildren : getBlockChildren(block) ?? []

    return [
        {
            label: block.title,
            value: { id: block.id, scope },
            block,
            level: 0
        },
        ...children.reduce<WithBlockOption[]>((total, curr) => {
            return [
                ...total,
                ...collectBlockAndChildrenWithSync(
                    curr,
                    synchronousId,
                    syncChildren,
                    scope || (currentIsSyncInstance ? block.id : undefined)
                )
            ]
        }, [])
    ]
}

/**
 * 同步组件子级只能获取同属一个作用域内的元素
 */
export const generateOptionsWithSync = (currentNode: NodeIdWithScope, blocks: BlockAbstract[], syncComponents: BlockAbstract[]) => {
    if (currentNode.scope) {
        const topBlock = findBlockById(currentNode.scope, blocks)
        if (!topBlock || !topBlock.synchronousId) {
            return
        }

        const masterSyncBlock = syncComponents.find(item => item.id === topBlock.synchronousId)
        if (!masterSyncBlock) {
            return
        }

        const children = getBlockChildren(masterSyncBlock)
        if (!children) {
            return
        }

        return generateOptions(children, currentNode.scope)
    }

    const block = findBlockById(currentNode.id, blocks)
    if (!block) {
        return
    }

    // 是同步组件实例
    if (block.synchronousId) {
        const masterSyncBlock = syncComponents.find(item => item.id === block.synchronousId)
        if (!masterSyncBlock) {
            return
        }
        const syncChildren = getBlockChildren(masterSyncBlock)
        if (!syncChildren) {
            return
        }

        return blocks.reduce<WithBlockOption[]>((total, curr) => {
            return [...total, ...collectBlockAndChildrenWithSync(curr, block.id, syncChildren)]
        }, [])
    }

    return generateOptions(blocks)
}

export const ScrollToActionConfigure: React.FC<ScrollToActionConfigureProps> = ({ actionTypeSwitcher, prefixName = 'config' }) => {
    const { control } = useFormContext()
    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)
    const [pageId, selectedNodes] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.state.selectedNodes] as const, [])
    )

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(syncComponentsAtom)

    const options = useMemo(() => {
        const currentNode = selectedNodes?.[0]
        if (!currentNode) {
            return []
        }

        return generateOptionsWithSync(currentNode, pageBlocks, syncComponents) ?? []
    }, [pageBlocks, selectedNodes, syncComponents])

    const handleScroll2FlowNode = useCallback(
        (value?: NodeIdWithScope) => {
            setBlockHighlight(value ? [value.id] : [])
            value && scroll2FlowNode(value.id, value.scope)
        },
        [setBlockHighlight]
    )

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <CollapseBox label="定位">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>目标组件</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.scrollToElementId`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <ComponentSelector
                                            value={field.value}
                                            onChange={value => {
                                                field.onChange(value.id)
                                            }}
                                            options={options}
                                            onHover={handleScroll2FlowNode}
                                        />
                                    )
                                }}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
