import { Modal } from '@byecode/ui'
import { useContainerBlockContext } from '@lighthouse/block'
import type { DataSourceAbstract, Field, FieldADTValue, FilterOption, ViewBlockAbstract } from '@lighthouse/core'
import { DataSourceType, SelectedMode } from '@lighthouse/core'
import type {
    ColumnEvent,
    CreateFieldParams,
    CurrPageDatasourceForVariable,
    InsertDirection,
    PrevPageDatasourceForVariable,
    RowEvent
} from '@lighthouse/shared'
import {
    createFieldNeedFetchData,
    filterBlock,
    generateLinkFilter,
    getFilterBlockItemIdsInFilter,
    getMainTableRecordId,
    getQuickFilterRule,
    getWithScopeId,
    pageStackPubSub,
    resolveFilter,
    SuspendPagination,
    Table,
    updateFieldNeedFetchData,
    useApplicationContext,
    useAtomAction,
    useAtomData,
    useLanguageContext
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import produce from 'immer'
import { getDefaultStore, useAtomValue } from 'jotai'
import { find } from 'rambda'
import React, { useCallback, useId, useMemo, useRef } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { syncComponentsAtom } from '@/atoms/application/state'
import { createViewRecordInDataDrawerAtom } from '@/atoms/blockRecordsDict/action'
import { filterBlockOptionsAtom } from '@/atoms/blockRecordsDict/state'
import {
    addAiFieldStatusAtom,
    createFieldAtom,
    deleteFieldAtom,
    deleteRecordAtom,
    deleteViewRecordAtom,
    updateCellAtom,
    updateFieldAtom
} from '@/atoms/dataSource/action'
import { aiFieldStatusListAtom, recordPoolAtom, viewIndependentDataAtom,viewIndependentDataAtomFamily } from '@/atoms/dataSource/state'
import { blocksAtom, lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { filterBlockIdsCacheAtomFamily, quickFilterCacheAtomFamily } from '@/atoms/storage/state'
import { useCurrentStackIdContext } from '@/contexts/PageContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useViewIndependentData } from '@/hooks/useBlock'
// import { useViewRecords } from '@/hooks/useBlock'
import { useDataSourceList, useRecord } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useUserRecord } from '@/hooks/useUserRecord'
import * as srv from '@/services'
import { uploadManagerInAppParams } from '@/utils/auth'

const SCxContainer = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 14px;
`

const SCxFooter = styled.div`
    position: absolute;
    z-index: 2;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%, -50%);
`

interface ViewDataSourceProps {
    pageId: string
    currentPageData: CurrPageDatasourceForVariable
    prevPageData: PrevPageDatasourceForVariable
    blockData: ViewBlockAbstract
    dataSource: DataSourceAbstract
}

export interface ViewDataSourceState {
    selectedRecords: string[]
    selectedMode?: SelectedMode
}

export const ViewDataSource: React.FC<ViewDataSourceProps> = ({ pageId, currentPageData, prevPageData, blockData, dataSource }) => {
    const store = getDefaultStore()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { id, schema, type, name } = dataSource
    const { id: blockId, config } = blockData
    const scope = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.state.selectedNodes?.[0]?.scope, [])
    )
    const widthScopeId = getWithScopeId(blockId, scope)
    const {
        pagination,
        linkFilterController,
        filter: configFilter
    } = config
    const filterBlocks = useAtomData(
        scope ? syncComponentsAtom : pageBlocksAtom(pageId),
        useCallback(s => filterBlock(s, block => block.type === 'filter'), [])
    )
    const userRecord = useUserRecord()
    const prevRecord = useRecord(appId, envId, prevPageData.datasource?.id ?? '', prevPageData.recordId ?? '')
    const currentRecord = useRecord(appId, envId, currentPageData.datasource?.id ?? '', currentPageData.recordId ?? '')

    const uploadId = useId()
    const isAggregateDataSource = type === DataSourceType.aggregateDataSource
    const filterOptions = useAtomData(filterBlockOptionsAtom)
    const { personOptions } = useApplicationContext()
    const dataSourceList = useDataSourceList(appId, envId)
    const viewIndependentData = useAtomData(viewIndependentDataAtom, useCallback(s => s[widthScopeId] || {}, [widthScopeId]))
    const { recordIds, search, currentPage, rowTotal } = viewIndependentData
    const { filterBlockItemIds } = useMemo(() => getFilterBlockItemIdsInFilter(linkFilterController), [linkFilterController])
    const filterValue = useAtomValue(
        filterBlockIdsCacheAtomFamily({ appId, envId, pageId, recordId: currentPageData.recordId, scope, filterBlockItemIds })
    )
    const { convertTextByLanguage } = useLanguageContext()

    const { run: createField } = useAtomAction(createFieldAtom)
    const { run: updateField } = useAtomAction(updateFieldAtom)
    const { run: deleteField } = useAtomAction(deleteFieldAtom)
    const { run: updateCell } = useAtomAction(updateCellAtom)
    const { run: createViewRecordInDataDrawer } = useAtomAction(createViewRecordInDataDrawerAtom)
    const { run: deleteRecord } = useAtomAction(deleteRecordAtom)
    const { run: deleteViewRecord } = useAtomAction(deleteViewRecordAtom)
    const { run: addAiFieldStatus } = useAtomAction(addAiFieldStatusAtom)
    const selectedModeRef = useRef<SelectedMode>()
    // const { mutation, abortRef } = useHandleAbortPrevious(fetchDataSource)
    const createFieldParamsRef = useRef<CreateFieldParams>({
        sourceId: '',
        direction: '',
        fieldId: ''
    })
    const [state, setState] = useImmer<ViewDataSourceState>({
        selectedRecords: [],
        selectedMode: undefined
    })

    const { selectedRecords, selectedMode } = state

    useUpdateEffect(() => {
        if (selectedMode === 'ALL' && selectedModeRef.current === 'ALL') {
            setState(draft => {
                draft.selectedRecords = recordIds || []
            })
        }
    }, [recordIds])

    const viewDataSource = useMemo(() => {
        return produce(dataSource, draft => {
            draft.records = recordIds || []
            draft.viewOptions.pagination = {
                ...pagination,
                rowTotal: recordIds?.length ?? 0
            }
        })
    }, [dataSource, pagination, recordIds])

    const quickFiltersRule = useMemo(() => {
        if (!blockData) {
            return
        }
        const { config, id } = blockData
        const { pointer, quickFilter } = config
        const dataSource = find(item => item.id === pointer, dataSourceList)
        if (!dataSource) {
            return []
        }
        const quickFiltersCache = store.get(quickFilterCacheAtomFamily({ appId, envId, id }))
        return getQuickFilterRule({ quickFiltersCache, quickFilter, dataSource, personOptions })
    }, [appId, blockData, dataSourceList, envId, personOptions, store])

    const resolvedFilter = useMemo(() => {
        const usedFilterOptions = filterBlockItemIds.reduce<Record<string, FilterOption[]>>((prev, filterItem) => {
            const id = `${filterItem.blockId}-${filterItem.itemId}`
            const options = filterOptions[id]
            if (options) {
                prev[id] = options
            }
            return prev
        }, {})

        return generateLinkFilter({
            filterBlockItemIds,
            filterOptions: usedFilterOptions,
            linkFilterController,
            filterValue,
            filterBlocks
        })
    }, [filterBlockItemIds, filterBlocks, filterOptions, filterValue, linkFilterController])

    const filter = useMemo(() => {
        if (!blockData) {
            return
        }
        const { config } = blockData
        const { filter: configFilter } = config
        if (!configFilter) {
            return
        }
        return resolveFilter({
            filter: configFilter,
            extraParams: {
                clickTriggerNodeParams: {
                    prevRecord
                },
                userRecord,
                dataSourceList,
                pageRecord: currentRecord,
                pageStackFormState: currentPageData.formState
            }
        })
    }, [blockData, currentPageData.formState, currentRecord, dataSourceList, prevRecord, userRecord])

    const handleSelectedRecords = useCallback(
        (selectedRecords: string[]) => {
            setState(draft => {
                draft.selectedRecords = selectedRecords
                draft.selectedMode = SelectedMode.CURRENT_PAGE
            })
            selectedModeRef.current = SelectedMode.CURRENT_PAGE
        },
        [setState]
    )

    const handleSelectModeChange = useCallback(
        (val?: SelectedMode) => {
            if (val === SelectedMode.ALL) {
                setState(draft => {
                    draft.selectedMode = SelectedMode.ALL
                    draft.selectedRecords = viewDataSource.records
                })
                selectedModeRef.current = SelectedMode.ALL
                return
            }
            if (val === SelectedMode.CURRENT_PAGE) {
                setState(draft => {
                    draft.selectedMode = SelectedMode.CURRENT_PAGE
                    draft.selectedRecords = viewDataSource.records
                })
                selectedModeRef.current = SelectedMode.CURRENT_PAGE
                return
            }
            setState(draft => {
                draft.selectedMode = undefined
                draft.selectedRecords = []
            })
            selectedModeRef.current = undefined
        },
        [setState, viewDataSource.records]
    )

    const handleCellChange = useCallback(
        (recordId: string, fieldValue: FieldADTValue) =>
            updateCell({ envId, dsId: id, recordId, fieldId: fieldValue.id, value: fieldValue }),
        [envId, id, updateCell]
    )

    const handleCellUpdate = useCallback(
        (recordId: string, fieldValue: FieldADTValue) =>
            srv.updateCell({
                envId,
                dsId: id,
                id: recordId,
                fieldId: fieldValue.id,
                content: fieldValue
            }),
        [envId, id]
    )

    const handleCreateRecord = useCallback(
        (bId?: string) => {
            const beforeId = bId ? getMainTableRecordId(bId) : undefined
            return createViewRecordInDataDrawer({ viewId: blockId, appId, envId, dsId: id, beforeId })
        },
        [appId, blockId, createViewRecordInDataDrawer, envId, id]
    )

    // const handleDeleteRecord = useCallback(
    //     (recordIds: string[]) => {
    //         return deleteRecord({ appId, dsId: id, recordIds, mode: selectedMode })
    //     },
    //     [appId, deleteRecord, id, selectedMode]
    // )

    const handleDeleteRecord = useCallback(
        async (recordIds: string[]) => {
            const isConfirm = await Modal.confirm({
                title: convertTextByLanguage('sureDelete'),
                content: convertTextByLanguage('deleteRecordWaring', {
                    count: selectedMode === 'ALL' ? rowTotal : recordIds.length
                }),
                cancelText: convertTextByLanguage('cancel'),
                okText: convertTextByLanguage('delete'),
                okStatus: 'error'
            })?.then(res => res)
            if (isConfirm) {
                const isDelete =
                    // eslint-disable-next-line no-negated-condition
                    selectedMode !== 'ALL'
                        ? await deleteRecord({ envId, dsId: id, recordIds, mode: selectedMode })
                        : await deleteViewRecord({
                              envId,
                              viewId: blockId,
                              isSyncComponent: !!scope,
                              dsId: id,
                              recordIds,
                              mode: selectedMode,
                              search,
                              filter,
                              linkFilter: resolvedFilter,
                              quickFilters: quickFiltersRule
                          })
                if (isDelete) {
                    pageStackPubSub.emit(`${id}-UPDATE`)
                }
                return isDelete
            }
            return false
        },
        [
            blockId,
            convertTextByLanguage,
            deleteRecord,
            deleteViewRecord,
            envId,
            filter,
            id,
            rowTotal,
            quickFiltersRule,
            resolvedFilter,
            scope,
            search,
            selectedMode
        ]
    )

    const handleDeleteMultipleRecord = useCallback(async () => {
        const isDelete = await handleDeleteRecord(selectedRecords)
        if (isDelete) {
            setState(draft => {
                draft.selectedRecords = []
                draft.selectedMode = SelectedMode.CURRENT_PAGE
            })
            selectedModeRef.current = SelectedMode.CURRENT_PAGE
        }
    }, [handleDeleteRecord, selectedRecords, setState])

    const handleAiGeneration = useCallback(
        async (recordId: string, fieldId: string) => {
            const statusId = nanoid()
            const isSuccess = await srv.aiGenerate({ dsId: id, recordId, fieldId })
            if (isSuccess) {
                addAiFieldStatus({
                    id: statusId,
                    dataSourceId: id,
                    recordId,
                    fieldId,
                    state: 'STARTED'
                })
            }
            return isSuccess
        },
        [addAiFieldStatus, id]
    )

    const handleCreateField = useCallback(
        async (field: Field, sourceId: string, direction: InsertDirection) => {
            createFieldParamsRef.current = {
                sourceId,
                direction,
                fieldId: field.id
            }
            const innerType = await createField({
                envId,
                dsId: id,
                config: field,
                sourceId,
                direction
            })

            if (id !== field.dsId || createFieldNeedFetchData.has(field.type)) {
                pageStackPubSub.emit(`${id}-UPDATE`)
            }
            return !!innerType
        },
        [createField, envId, id]
    )

    const handleUpdateField = useCallback(
        async (fieldId: string, config: Field) => {
            const isUpdate = await updateField({
                envId,
                dsId: id,
                fieldId,
                config
            })
            const field = schema[fieldId]
            if ((field && field.type !== config.type) || updateFieldNeedFetchData.has(config.type)) {
                pageStackPubSub.emit(`${id}-UPDATE`)
            }
            return !!isUpdate
        },
        [envId, id, schema, updateField]
    )

    const handleDeleteField = useCallback(
        async (field: Field) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: `确认删除列「${field.name ?? '未命名列'}」？`,
                okStatus: 'error'
            })
            if (isConfirm) {
                const isDelete = await deleteField({
                    envId,
                    dsId: id,
                    fieldId: field.id
                })
                return !!isDelete
            }
            return false
        },
        [deleteField, envId, id]
    )

    const handleClose = useCallback(() => {
        setState(draft => {
            draft.selectedMode = undefined
            draft.selectedRecords = []
        })
    }, [setState])

    const uploadOptions = useMemo(
        () => ({
            info: { id: uploadId, label: name, groupId: id },
            options: uploadManagerInAppParams()
        }),
        [id, name, uploadId]
    )

    const videoUploadOptions = useMemo(() => {
        return {
            info: { id: uploadId, label: name, groupId: id },
            options: uploadManagerInAppParams()
        }
    }, [id, name, uploadId])

    useUpdateEffect(() => {
        handleClose()
    }, [currentPage])

    const rowEvent: RowEvent = useMemo(
        () => ({
            onRecordSelect: handleSelectedRecords,
            onCellChange: handleCellChange,
            onCellUpdate: handleCellUpdate,
            // onLoadMoreData: handleLoadDataSource,
            onCreateRecord: handleCreateRecord,
            onAiGeneration: handleAiGeneration
        }),
        [handleCellChange, handleCellUpdate, handleCreateRecord, handleSelectedRecords, handleAiGeneration]
    )

    const columnEvent: ColumnEvent = useMemo(
        () => ({
            onCreateField: handleCreateField,
            onUpdateField: handleUpdateField,
            onDeleteField: handleDeleteField,
            onSelectModeChange: handleSelectModeChange
        }),
        [handleCreateField, handleUpdateField, handleDeleteField, handleSelectModeChange]
    )

    return (
        <>
            <SCxContainer>
                <Table
                    appId={appId}
                    headerFixed
                    // contentLoading={loading}
                    // contentLoading={loading}
                    disableFindUse
                    disableFieldCreatable={isAggregateDataSource}
                    disableSelect={isAggregateDataSource}
                    contentLoading={false}
                    data={viewDataSource}
                    viewIndependentData={viewIndependentData}
                    dataSourceList={dataSourceList}
                    createFieldParams={createFieldParamsRef.current}
                    recordPoolAtom={recordPoolAtom}
                    disableSelectMode
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    selectedRecords={selectedRecords}
                    // disableFieldCreatable
                    // disableAddRecord

                    pageSize={100}
                    columnEvent={columnEvent}
                    rowEvent={rowEvent}
                    uploadOptions={uploadOptions}
                    richTextUploadOptions={uploadManagerInAppParams()}
                    videoUploadOptions={videoUploadOptions}
                    scrollBarOutside={false}
                />
            </SCxContainer>
            <SCxFooter>
                {selectedRecords.length > 0 && (
                    <SuspendPagination
                        selectIds={selectedRecords}
                        total={recordIds?.length}
                        mode={selectedMode}
                        enableDelete
                        onDelete={handleDeleteMultipleRecord}
                        onClose={handleClose}
                    />
                )}
            </SCxFooter>
        </>
    )
}
