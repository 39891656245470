import { useByecodeUIConfig } from '@byecode/ui/providers'
import { dateFormat } from '@byecode/ui/utils/dateformat'
import { eachMonthOfInterval, endOfMonth, endOfYear, format, isSameMonth, startOfYear } from 'date-fns'
import React, { FC, forwardRef, useMemo } from 'react'

import type { Selectors, StyleComponentProps } from '../../../theme/types'
import { Box } from '../../Box'
import { Button } from '../../Button'
import type { CalendarSettingsProps } from '../Calendar.type'
import { useStyles } from './MonthCalendar.style'

export type MonthCalendarStylesName = Selectors<typeof useStyles>

interface MonthCalendarProps
    extends Pick<CalendarSettingsProps, 'CellComponent' | 'onCellMouseEnter' | 'onCellMouseLeave'>,
        StyleComponentProps<MonthCalendarStylesName> {
    disabled?: boolean

    date?: Date
    onSelect: (date: Date) => void

    panelViewDate: Date
}

export const MonthCalendar = forwardRef<HTMLDivElement, MonthCalendarProps>((props, ref) => {
    const {
        classNames,
        styles,
        unstyled,

        disabled,
        date = new Date(),
        onSelect,
        panelViewDate,

        CellComponent,
        onCellMouseEnter,
        onCellMouseLeave,
        ...rest
    } = props

    const { locale } = useByecodeUIConfig()
    const { classes } = useStyles({}, { name: 'Calendar', classNames, styles, unstyled })

    const months = useMemo(() => {
        const ls = eachMonthOfInterval({
            start: startOfYear(panelViewDate),
            end: endOfYear(panelViewDate)
        })

        ls.forEach(d => {
            d.setDate(Math.min(endOfMonth(d).getDate(), panelViewDate.getDate()))
            d.setHours(panelViewDate.getHours(), panelViewDate.getMinutes(), panelViewDate.getSeconds())
        })

        return ls
    }, [panelViewDate])

    return (
        <Box ref={ref} className={classes.monthBody} {...rest}>
            {months.map(month =>
                CellComponent ? (
                    <CellComponent key={month.getTime()} panelView="month" date={month} panelViewDate={panelViewDate} />
                ) : (
                    <Button
                        key={month.getTime()}
                        className={classes.monthCell}
                        size="sm"
                        style={{ color: isSameMonth(date, month) ? undefined : 'var(--color-gray-900)' }}
                        type={isSameMonth(date, month) ? 'primary' : 'text'}
                        onClick={() => {
                            if (disabled) {
                                return
                            }
                            onSelect(month)
                        }}
                        onMouseEnter={e => {
                            if (disabled) {
                                return
                            }
                            onCellMouseEnter?.(month, e.nativeEvent)
                        }}
                        onMouseLeave={e => {
                            if (disabled) {
                                return
                            }
                            onCellMouseLeave?.(month, e.nativeEvent)
                        }}
                    >
                        {dateFormat(month, 'LLLL', { locale })}

                        {isSameMonth(month, new Date()) && <div className={classes.monthIndicator} />}
                    </Button>
                )
            )}
        </Box>
    )
})
