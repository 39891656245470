import type { UserCenterBlockConfig } from '@lighthouse/core'

export const generateUserCenterBlock = (): UserCenterBlockConfig => {
    return {
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            position: {
                type: 'relative'
            },
            size: {
                width: {
                    size: 32
                },
                height: {
                    size: 'auto'
                }
            },
            design: {
                color: {
                    type: 'color',
                    color: '#475467'
                },
                radius: [100, 100, 100, 100],
                background: {
                    type: 'color',
                    color: '#F9FAFB'
                },
                border: {
                    type: 'color',
                    color: '#E4E7EC',
                    mode: 'all',
                    each: [1, 1, 1, 1]
                }
            },
            layout: {}
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
