import React, { useState } from 'react'

// 生成弹性动画曲线点
const generateElasticTimePoints = (time: number, bounce: number, steps = 200) => {
    const points = []
    const k = bounce / 10 // 衰减系数
    const b = bounce // 振动频率

    for (let i = 0; i <= steps; i++) {
        const t = (i / steps) * time // 时间范围 [0, time]
        const y = Math.exp(-k * t) * Math.cos(2 * Math.PI * b * t) // 弹性公式
        points.push({ x: (i / steps) * 600, y: 100 - y * 50 }) // 映射到 SVG 坐标
    }

    return points
}

// React 组件
export const ElasticCurveTimeSVG: React.FC<{ time?: number; bounce: number; width: number; height: number }> = ({
    time = 5,
    bounce,
    width,
    height
}) => {
    // 生成弹性曲线点
    const points = generateElasticTimePoints(time, bounce)

    // 转换点为 SVG path
    const toPath = (points: { x: number; y: number }[]) => points.map((p, i) => `${i === 0 ? 'M' : 'L'}${p.x},${p.y}`).join(' ')

    return (
        <svg width={width} height={height}>
            {/* 曲线 */}
            <path
                d={toPath(points)}
                stroke="blue"
                fill="none"
                strokeWidth="2"
                style={{ strokeLinejoin: 'round', strokeLinecap: 'round' }}
            />
        </svg>
    )
}

// 生成基于物理参数的弹性动画曲线点
const generateElasticPhysicsPoints = (time: number, stiffness: number, damping: number, mass: number, steps = 200) => {
    const points = []
    const gamma = damping / (2 * mass) // 衰减系数
    const omegaD = Math.sqrt(stiffness / mass - gamma * gamma) // 阻尼振荡频率

    // 振幅和初相位设定
    const A = 1
    const phi = 0

    for (let i = 0; i <= steps; i++) {
        const t = (i / steps) * time // 时间范围 [0, time]
        const y = A * Math.exp(-gamma * t) * Math.cos(omegaD * t + phi) // 弹性公式
        points.push({ x: (i / steps) * 600, y: 100 - y * 50 }) // 映射到 SVG 坐标
    }

    return points
}

// React 组件
export const ElasticCurvePhysicsSVG: React.FC<{
    time?: number
    stiffness: number
    damping: number
    mass: number
    width: number
    height: number
}> = ({ time = 5, stiffness, damping, mass, width, height }) => {
    // 生成弹性曲线点
    const points = generateElasticPhysicsPoints(time, stiffness, damping, mass)

    // 转换点为 SVG path
    const toPath = (points: { x: number; y: number }[]) => points.map((p, i) => `${i === 0 ? 'M' : 'L'}${p.x},${p.y}`).join(' ')

    return (
        <svg width={width} height={height} style={{ transform: 'scaleY(-1)' }}>
            {/* 曲线 */}
            <path
                d={toPath(points)}
                stroke="blue"
                fill="none"
                strokeWidth="2"
                style={{ strokeLinejoin: 'round', strokeLinecap: 'round' }}
            />
        </svg>
    )
}
