import type { BlockAbstract, ContainerBlockAbstract, PageAbstract } from '@lighthouse/core'
import type { MakeADT } from 'ts-adt/MakeADT'

import { UndoRedoController } from '../core'

export const BLOCK_UPDATE_ACTION = 'BLOCK_UPDATE_ACTION'
export type BlockUpdateAction = 'BLOCK_UPDATE_ACTION'
export const NODE_UPDATE_ACTION = 'NODE_UPDATE_ACTION'
export type NodeUpdateAction = 'NODE_UPDATE_ACTION'

export const PAGE_CONFIG_UPDATE_ACTION = 'PAGE_SETTINGS_UPDATE_ACTION'
export type PageConfigUpdateAction = 'PAGE_SETTINGS_UPDATE_ACTION'

export type UndoRedoAction = BlockUpdateAction | NodeUpdateAction | PageConfigUpdateAction

export type PageUndoRedoPayload = MakeADT<
    'action',
    {
        [BLOCK_UPDATE_ACTION]: {
            payload: {
                prev: BlockAbstract
                next: BlockAbstract
                blocks?: { prev: BlockAbstract[]; next: BlockAbstract[] }
                syncComponents?: {
                    prev: ContainerBlockAbstract[]
                    next: ContainerBlockAbstract[]
                }
            }
        }
        [NODE_UPDATE_ACTION]: {
            payload: {
                created?: {
                    createdBlocks?: BlockAbstract[]
                    prevBlocks?: BlockAbstract[]
                    nextBlocks?: BlockAbstract[]
                    createdSyncComponents?: BlockAbstract[]
                    prevSyncComponents?: ContainerBlockAbstract[]
                    nextSyncComponents?: ContainerBlockAbstract[]
                }
                removed?: {
                    removedBlocks?: BlockAbstract[]
                    prevBlocks?: BlockAbstract[]
                    nextBlocks?: BlockAbstract[]
                    removedSyncComponents?: BlockAbstract[]
                    prevSyncComponents?: ContainerBlockAbstract[]
                    nextSyncComponents?: ContainerBlockAbstract[]
                }
                prev?: {
                    blocks: undefined | BlockAbstract[]
                    syncComponents: undefined | ContainerBlockAbstract[]
                }
                next?: {
                    blocks: undefined | BlockAbstract[]
                    syncComponents: undefined | ContainerBlockAbstract[]
                }
            }
        }

        [PAGE_CONFIG_UPDATE_ACTION]: {
            payload: {
                prev: PageAbstract
                next: PageAbstract
            }
        }
    }
>

export const pageUndoRedoController = new UndoRedoController<PageUndoRedoPayload>()
