import { Box, Button, Flex, IconFont, Input, Modal, Radio, Toast } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, MouseSensor, TouchSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ContainerBlockConfig, ContainerView } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { useAtomCallback } from 'jotai/utils'
import React, { useRef, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom, pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { BlockSettingMitt } from '@/blockControllers/BlockSettingsController/listener'
import { useSetExtendsKeys } from '@/components/DesignSetting/hooks/useMouseEventDistance'

interface Props {}

export const ViewListSettings = () => {
    const { control, setValue, getValues } = useFormContext<ContainerBlockConfig>()
    const { fields, append, insert, move, remove } = useFieldArray({ control, name: 'viewList', keyName: 'key' })
    const defaultPanelView = getValues('breakPoint.panel.defaultPanel')

    const [activeId, setActiveId] = useState<string>()

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        }),
        useSensor(TouchSensor, {
            activationConstraint: { distance: 8 }
        })
    )
    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = fields.findIndex(item => item.id === over.id)
            const activeIndex = fields.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                move(activeIndex, overIndex)
            }
        }
        setActiveId(undefined)
    }

    const {
        pageId = '',
        stackId = '',
        rootPageId = '',
        state: { selectedNodes = [] } = {},
        blockRuntimeState
    } = useAtomData(lastPageOfStackAtom) ?? {}

    const runtimeStateUniqueId = selectedNodes[0].scope ? `${selectedNodes[0].scope}@${selectedNodes[0].id}` : selectedNodes[0].id

    const countRef = useRef(fields.length)

    const onCreate = () => {
        const id = nanoid()
        const key = (++countRef.current).toString().padStart(2, '0')

        BlockSettingMitt.emit('event', { action: 'createContainerView', newId: id })

        append({ id, name: `面板 ${key}` })
    }

    const onDelete = (index: number) => {
        if (fields.length === 1) {
            Toast.warning('不可删除，至少需要1个面板')
            return
        }

        BlockSettingMitt.emit('event', { action: 'deleteContainerView', index })

        remove(index)
    }

    const onDuplicate = (index: number) => {
        const newId = nanoid()

        BlockSettingMitt.emit('event', { action: 'cloneContainerView', newId, fromIndex: index })

        insert(index + 1, { id: newId, name: `${fields[index].name}-副本` })
    }

    const { handleSetBreakKey } = useSetExtendsKeys()

    const handleViewChange = useAtomCallback<void, string[]>((get, set, view) => {
        handleSetBreakKey('breakPoint.panel.defaultPanel')
        setValue('breakPoint.panel.defaultPanel', view)

        set(pageStackAtom, draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                stack.blockRuntimeState.container = {
                    ...stack.blockRuntimeState.container,
                    [runtimeStateUniqueId]: {
                        ...stack.blockRuntimeState.container?.[runtimeStateUniqueId],
                        currentView: view
                    }
                }
            }
        })
    })

    return (
        <Box py={8}>
            <div style={{ overflow: 'auto' }}>
                <DndContext
                    sensors={sensors}
                    modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragCancel={() => setActiveId(undefined)}
                >
                    <SortableContext items={fields.map(item => item.id)} strategy={verticalListSortingStrategy}>
                        <Radio.Group
                            name="test"
                            value={defaultPanelView || blockRuntimeState?.container?.[runtimeStateUniqueId]?.currentView}
                            onChange={handleViewChange}
                            style={{ flexDirection: 'column' }}
                        >
                            {fields.map((item, index) => (
                                <SortableItem
                                    key={item.id}
                                    disabled={fields.length <= 1}
                                    index={index}
                                    data={item}
                                    onDelete={async i => {
                                        const isConfirm = await Modal.confirm({
                                            title: '确认删除',
                                            content: '删除的面板内容不可恢复'
                                        })
                                        if (!isConfirm) {
                                            return
                                        }
                                        onDelete(i)
                                    }}
                                    onDuplicate={onDuplicate}
                                />
                            ))}
                        </Radio.Group>
                    </SortableContext>
                </DndContext>
            </div>
            <Button radius={100} icon={<IconFont type="Add" />} onClick={onCreate} style={{ marginTop: 12 }}>
                添加
            </Button>
        </Box>
    )
}

const ActionButton = styled(Button)`
    all: unset;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 4px;
    color: var(--color-gray-400);
    &:disabled {
        color: var(--color-gray-300);
    }
    &:not(:disabled):hover {
        cursor: pointer;
        background-color: #26415a0f;
    }
`

interface SortableItemProps {
    index: number
    data: ContainerView
    onDelete: (index: number) => void
    onDuplicate: (index: number) => void
    disabled?: boolean
}
const SortableItem = ({ index, data, onDelete, onDuplicate, disabled }: SortableItemProps) => {
    const { setNodeRef, setActivatorNodeRef, listeners, attributes, transform, transition } = useSortable({
        id: data.id,
        attributes: { role: 'li' }
    })

    const { register } = useFormContext<ContainerBlockConfig>()

    const style: React.CSSProperties = {
        flex: 1,
        transform: CSS.Translate.toString(transform),
        transition
    }

    return (
        <Flex alignItems="center" gap="6px" ref={setNodeRef} style={style}>
            <ActionButton {...listeners} {...attributes} ref={setActivatorNodeRef}>
                <IconFont type="DotsSix" />
            </ActionButton>
            <Radio size="xs" value={data.id} />
            <Input {...register(`viewList.${index}.name`)} size="sm" styles={{ wrapper: { flex: 1 } }} />
            <ActionButton onClick={() => onDuplicate(index)}>
                <IconFont type="Duplicate" />
            </ActionButton>
            <ActionButton disabled={disabled} onClick={() => !disabled && onDelete(index)}>
                <IconFont type="Trash" />
            </ActionButton>
        </Flex>
    )
}
