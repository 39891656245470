import { Empty } from '@byecode/ui'
import type {
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SelectedMode,
    TableColumns,
    TableColumnWidth,
    TableViewConfig,
    ViewBlockAbstract,
    ViewField
} from '@lighthouse/core'
import { VIEW_DESIGN_STYLE } from '@lighthouse/core'
import type { ApplicationPreviewEnum } from '@lighthouse/shared'
import { EmptyNoRecordSvg, getViewColumns, useLanguageContext } from '@lighthouse/shared'
import type { BreakPointSize } from '@lighthouse/tools'
import type { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import React, { memo, useCallback, useMemo, useRef } from 'react'

import * as SC from './styles'
import { Table } from './Table'
import { getDesignStyleMap } from './utils'

interface TableBlockProps {
    dataSource: DataSourceAbstract
    blockData: ViewBlockAbstract
    records?: RecordLikeProtocol[]
    tablePropsCache?: TableColumns
    selectedRecords: string[]
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    blockWidth?: number
    tableColumnCache?: TableColumnWidth
    previewType: ApplicationPreviewEnum
    onSelectModeChange?: (mode?: SelectedMode) => void
    onSelectedRecords: (recordIds: string[]) => void
    onRecordClick?: (recordId: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[], selectedMode?: SelectedMode) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
    onTableColumnWidthChange?: (val: TableColumnWidth) => void
}

/**
 *
 * @param param
 * @returns
 * blockData: {
 *   fields: { id: string; type: string; name: string }[]
 *   records: { id: string; content: Record<number, string> }[]
 * }
 */

const TableBlock: React.FC<TableBlockProps> = ({
    dataSource,
    blockData,
    records,
    tablePropsCache,
    selectedRecords,
    aiFieldStatusListAtom,
    blockWidth,
    tableColumnCache,
    previewType,
    onSelectModeChange,
    onSelectedRecords,
    onRecordClick,
    onRecordDelete,
    onRecordEdit,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle,
    onTableColumnWidthChange
}) => {
    const tableBlockRef = useRef<HTMLDivElement>(null)
    const { schema, appId: dsAppId, viewOptions } = dataSource
    const { tableProps } = viewOptions
    const { id: blockId, title = '无标题', config } = blockData
    const isEmptyImg = blockWidth && blockWidth >= 200
    const {
        pointer,
        canDeleteRecord = false,
        canViewEdit = false,
        canViewRecord,
        canExport,
        canPrint,
        canDisplay,
        viewFieldSettings,
        designStyle = VIEW_DESIGN_STYLE.classic,
        listAvatar,
        isShowListAvatar,
        avatarType,
        hiddenHeader,
        // fieldProps,
        // fieldSetting,
        highLightRules,
        tableRowHeight,
        actions
    } = config as TableViewConfig
    const { convertTextByLanguage } = useLanguageContext()
    const noData = !pointer
    const noRecords = records && records.length === 0
    // const [selectedRecords, setSelectedRecords] = useState<string[]>([])
    // const [selectedMode, setSelectedMode] = useState<SelectedMode>(undefined)
    // const tableColumn = ViewSettingCache.getTableColumnWidthCache(blockId)
    const tableRecordCheckable = !!canDeleteRecord || !!canExport || !!canPrint

    const designConfig = useMemo(
        () => ({
            designStyle,
            listAvatar,
            isShowListAvatar,
            avatarType,
            hiddenHeader
        }),
        [avatarType, designStyle, hiddenHeader, isShowListAvatar, listAvatar]
    )

    const designStyleOption = useMemo(() => getDesignStyleMap(designStyle), [designStyle])
    const { isContentBorder, isContentMargin } = designStyleOption

    // const isClassic = designStyle === 'classic'
    // const isSimple = designStyle === 'simple'
    // block 设置后的tableProps
    const settingColumns = useMemo(
        () =>
            getViewColumns({
                blockId,
                tableProps,
                value: viewFieldSettings,
                schema,
                tableColumn: tableColumnCache
            }),
        [blockId, schema, tableColumnCache, tableProps, viewFieldSettings]
    )

    // 用户字段设置后的tableProps
    const [columns, userColumns] = useMemo(() => {
        if (!tablePropsCache || tablePropsCache.length === 0 || !canDisplay) {
            const list = settingColumns.filter(item => {
                return item.visible
            })
            return [list, list]
        }

        const columnsCache = tablePropsCache.reduce<ViewField[]>((prev, cur) => {
            const field = find(({ fieldId }) => fieldId === cur.id, settingColumns)
            if (field) {
                prev.push({
                    ...field,
                    visible: cur.visible
                })
            }
            return prev
        }, [])
        const columns = settingColumns.reduce<ViewField[]>((prev, cur) => {
            if (!cur.visible) {
                return prev
            }
            const field = find(({ fieldId }) => fieldId === cur.fieldId, prev)
            if (!field) {
                prev.push({
                    ...cur,
                    visible: false
                })
            }
            return prev
        }, columnsCache)

        const userColumns = columns.filter(item => item.visible)
        return [columns, userColumns]
    }, [tablePropsCache, canDisplay, settingColumns])

    const handleRecordOperatorDelete = useCallback(
        async (dsId: string, ids: string[]) => {
            const isDelete = await onRecordDelete?.(dsId, ids)
            const newSelectedRecords = selectedRecords.reduce<string[]>((prev, cur) => {
                if (!ids.includes(cur)) {
                    prev.push(cur)
                }
                return prev
            }, [])
            onSelectedRecords(newSelectedRecords)
            return !!isDelete
        },
        [onRecordDelete, onSelectedRecords, selectedRecords]
    )

    const handleSelect = useCallback(
        (recordIds: string[]) => {
            onSelectedRecords(recordIds)
        },
        [onSelectedRecords]
    )

    return (
        <SC.TableScrollerContent
            id={`content-${blockId}`}
            bordered={isContentBorder}
            margin={!isContentMargin}
            data-content-type="Table"
            styles={{
                scrollbar: {
                    zIndex: 2
                }
            }}
            viewportRef={tableBlockRef}
        >
            <Table
                id={blockId}
                actions={actions}
                checkable={tableRecordCheckable}
                recordOpenable={canViewRecord}
                recordEditOpenable={canViewEdit}
                recordDeleteAble={canDeleteRecord}
                dataSourceInfo={dataSource}
                highLightRules={highLightRules}
                scrollContainerRef={tableBlockRef}
                viewFields={userColumns}
                records={records || []}
                blockWidth={blockWidth}
                // designStyle={designStyle}
                designConfig={designConfig}
                selectedRecords={selectedRecords}
                tableRowHeight={tableRowHeight}
                aiFieldStatusListAtom={aiFieldStatusListAtom}
                previewType={previewType}
                onSelectModeChange={onSelectModeChange}
                onRecordSelect={handleSelect}
                onRecordClick={onRecordClick}
                onRecordEdit={onRecordEdit}
                onRecordDelete={handleRecordOperatorDelete}
                onAiGeneration={onAiGeneration}
                onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                onRenderButtonTitle={onRenderButtonTitle}
                onTableColumnWidthChange={onTableColumnWidthChange}
            />
            {noRecords && (
                <SC.TableNoDataContent width={blockWidth}>
                    <Empty
                        icon={isEmptyImg ? <EmptyNoRecordSvg color="var(--color-app-main)" /> : undefined}
                        description={convertTextByLanguage('noFindData')}
                    />
                </SC.TableNoDataContent>
            )}
        </SC.TableScrollerContent>
    )
}

export default memo(TableBlock)
