import { Empty, IconFont, Input, Text } from '@byecode/ui'
import { getFlatAllBlock } from '@lighthouse/block'
import type { BlockAbstract, BlockRuntimeState, ContainerBlockAbstract, FloatBlockAbstract } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import {
    findAllParentBlocksByType,
    findBlockById,
    findNormalOrSyncBlock,
    findParentBlockByType,
    getCurrentBlockChildren,
    Group4ByecodeUi,
    ListItem4ByecodeUi,
    TagIcon,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { current } from 'immer'
import { clone, findIndex, reduce } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { toggleFloatBoxAtom } from '@/atoms/application/action'
import { pageStackOfFloatBlockAtom, syncComponentsAtom } from '@/atoms/application/state'
import { createBlockAtom, updateRemoteBlockAtom } from '@/atoms/page/action'
import { blocksAtom, lastPageOfStackAtom, pageBlocksAtom, pageStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { generateFloatBlock } from '@/constants/Block/generate/float'
import { useNodeToolbarActions } from '@/hooks/layoutEngine/useNodeToolbarActions'
import { useCurrentAppID } from '@/hooks/useApplication'
import { createSyncBlock, deleteSyncBlock, updateSyncComponents } from '@/services'
import { BLOCK_UPDATE_ACTION, NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

interface FloatingControlProps {
    value: string
    blockId: string
    onChange: (v: string) => void
}

const SCxContainer = styled.div`
    border-radius: 8px;
    margin: 8px;
    border: 1px dashed var(--color-gray-200);
    background-color: var(--color-gray-25);
`

// function findBlockOfViewIdFormContainerBlock(
//     blockId: string,
//     blocks: BlockAbstract[],
//     syncComponents: ContainerBlockAbstract[],
//     blockRuntimeState: BlockRuntimeState
// ) {}

export const FloatingControl: React.FunctionComponent<FloatingControlProps> = ({ value, blockId }) => {
    const stack = useAtomData(lastPageOfStackAtom)

    const { pageId = '', stackId = '', rootPageId = '', blockRuntimeState, state: { selectedNodes = [] } = {} } = stack ?? {}

    const selectNode = selectedNodes?.[0] ?? {}

    const { scope: currentScope, id: selectBlockId } = selectNode

    const appId = useCurrentAppID()
    const blocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(syncComponentsAtom)
    const { run: setSyncBlocks } = useAtomAction(syncComponentsAtom)
    const { run: setBlocks } = useAtomAction(blocksAtom)
    const { run: createBlock } = useAtomAction(createBlockAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const { run: openFloatBox } = useAtomAction(toggleFloatBoxAtom)
    const { run: updateRemoteBlock } = useAtomAction(updateRemoteBlockAtom)

    const [syncContainerBlock, containerBlock, isSyncScope] = useMemo(() => {
        const syncBlock = findNormalOrSyncBlock(selectNode, blocks, syncComponents)
        const block = findBlockById<ContainerBlockAbstract>(selectBlockId, blocks)
        const isSyncScope = syncBlock?.isMasterSynchronous || syncBlock?.isLeafSynchronous

        return [syncBlock, block, isSyncScope]
    }, [blocks, selectBlockId, selectNode, syncComponents])

    const floatBlock = useMemo(() => {
        const block = syncContainerBlock ?? containerBlock
        const floatId = block?.type === BlockType.container ? block.config.floatPointer : undefined
        const container = (containerBlock?.children ?? []).length > 0 ? block : syncContainerBlock
        const children = container
            ? getCurrentBlockChildren(container, {
                  blockRuntimeState,
                  uniqueContainerId: isSyncScope && selectNode.scope ? `${selectNode.scope}@${selectNode.id}` : selectNode.id
              })
            : []
        // const isExitChildren = floatId && children ? !!findBlockById(floatId, children) : false
        // console.log("🚀 ~ floatBlock ~ children:", children)
        if (!floatId) {
            return
        }
        return isSyncScope ? findBlockById(floatId, syncComponents) : findBlockById(floatId, blocks)
    }, [syncContainerBlock, containerBlock, blockRuntimeState, isSyncScope, selectNode.id, syncComponents, blocks])

    const isEmpty = !floatBlock

    const { onRemove } = useNodeToolbarActions({
        stackId,
        rootPageId,
        pageId
    })

    const handleCreateFloatBlock = useCallback(async () => {
        const floatBlock: FloatBlockAbstract = {
            id: `floatBox-${nanoid(12)}`,
            type: 'floatBox',
            title: '弹出窗口',
            config: generateFloatBlock(),
            children: []
        }
        let parentSyncBlockId: string | undefined

        if (isSyncScope) {
            let finallySyncContainerBlock: ContainerBlockAbstract[] | undefined
            setSyncBlocks(syncBlocks => {
                let children: BlockAbstract[] | undefined = []
                const syncBlock = findBlockById<ContainerBlockAbstract>(syncContainerBlock?.id ?? '', syncBlocks)
                if (!syncBlock) {
                    return
                }
                syncBlock.config.floatPointer = floatBlock.id
                if (syncBlock?.isMasterSynchronous) {
                    children =
                        syncBlock && containerBlock
                            ? getCurrentBlockChildren(syncBlock, { blockRuntimeState, uniqueContainerId: containerBlock.id })
                            : undefined
                } else {
                    children = syncBlock
                        ? getCurrentBlockChildren(syncBlock, {
                              blockRuntimeState,
                              uniqueContainerId: `${currentScope}@${syncBlock?.id}`
                          })
                        : undefined
                }
                if (!children) {
                    return
                }
                floatBlock.isLeafSynchronous = true
                children.push(floatBlock)
                finallySyncContainerBlock = clone(syncBlocks)
            })
            if (!finallySyncContainerBlock) {
                return
            }
            await createSyncBlock({
                createBlocks: [floatBlock],
                blocks: finallySyncContainerBlock
            })

            await updateSyncComponents(finallySyncContainerBlock)
            parentSyncBlockId = syncContainerBlock?.isMasterSynchronous ? containerBlock?.id : currentScope
            if (containerBlock && finallySyncContainerBlock) {
                pageUndoRedoController.add({
                    action: NODE_UPDATE_ACTION,
                    payload: {
                        created: {
                            createdSyncComponents: [floatBlock],
                            prevSyncComponents: syncComponents,
                            nextSyncComponents: finallySyncContainerBlock
                        }
                    }
                })
            }
        } else {
            let finallyBlocks: BlockAbstract[] | undefined
            let finallyBlock: BlockAbstract | undefined
            setBlocks(draft => {
                const pageBlocks = draft[pageId]
                if (!pageBlocks) {
                    return
                }
                const parentBlock = findBlockById<ContainerBlockAbstract>(blockId, pageBlocks)
                if (!parentBlock) {
                    return
                }
                parentBlock.config.floatPointer = floatBlock.id
                const children = parentBlock ? getCurrentBlockChildren(parentBlock, { blockRuntimeState }) : pageBlocks
                if (!children) {
                    return
                }
                children.push(floatBlock)
                finallyBlocks = clone(pageBlocks)
                finallyBlock = clone(parentBlock)
            })

            if (!finallyBlocks || !containerBlock || !finallyBlock) {
                return
            }
            await createBlock({
                rootPageId,
                pageId,
                stackId,
                blocks: finallyBlocks,
                createdBlocks: [floatBlock]
            })
            await updateRemoteBlock({
                appId,
                block: finallyBlock,
                origin: containerBlock,
                blocks: finallyBlocks,
                pageId,
                rootPageId,
                stackId
            })
            pageUndoRedoController.add({
                action: NODE_UPDATE_ACTION,
                payload: {
                    created: {
                        createdBlocks: [floatBlock],
                        prevBlocks: blocks,
                        nextBlocks: finallyBlocks
                    }
                }
            })
        }

        openFloatBox({ blockId: floatBlock.id, stackId, rootPageId, open: true, scope: parentSyncBlockId })

        setPageStack(draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                stack.state.selectedNodes = [{ id: floatBlock.id, scope: parentSyncBlockId }]
                stack.state.asideType = AsideType.BLOCK
            }
        })
    }, [
        appId,
        blockId,
        blockRuntimeState,
        blocks,
        containerBlock,
        createBlock,
        currentScope,
        isSyncScope,
        openFloatBox,
        pageId,
        rootPageId,
        setBlocks,
        setPageStack,
        setSyncBlocks,
        stackId,
        syncComponents,
        syncContainerBlock?.id,
        syncContainerBlock?.isMasterSynchronous,
        updateRemoteBlock
    ])

    return (
        <Group4ByecodeUi
            label="浮层"
            styles={{
                collapse: {
                    padding: '0 8px !important'
                }
            }}
            extra={
                !floatBlock && (
                    <TagIcon
                        icon="Add"
                        size={24}
                        enableHover
                        hoverBackground="#26415A0F"
                        color="var(--color-gray-400)"
                        radius={4}
                        onClick={e => {
                            e.stopPropagation()
                            handleCreateFloatBlock()
                        }}
                    />
                )
            }
        >
            {isEmpty ? (
                <SCxContainer>
                    <Empty
                        icon="Copy"
                        styles={{
                            root: {
                                height: 130
                            },
                            wrapper: {
                                gap: 12
                            }
                        }}
                        description="添加浮层可实现弹窗、下拉列表等场景"
                    />
                </SCxContainer>
            ) : (
                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                    <Text>鼠标移入</Text>
                    <Input
                        style={{ width: 180 }}
                        styles={{
                            input: {
                                cursor: 'pointer'
                            }
                        }}
                        value={floatBlock?.title}
                        readOnly
                        prefix={
                            <TagIcon icon="Copy" size={24} iconColor="#fff" background="var(--color-theme-7)" radius={4} color="#fff" />
                        }
                        suffix={
                            <IconFont
                                type="CloseCircle"
                                size={16}
                                onClick={e => {
                                    e.stopPropagation()
                                    if (isSyncScope) {
                                        setSyncBlocks(syncBlocks => {
                                            const syncBlock = findBlockById(syncContainerBlock?.id ?? '', syncBlocks)
                                            if (!syncBlock) {
                                                return
                                            }
                                            let children: BlockAbstract[] | undefined = []
                                            if (syncBlock?.isMasterSynchronous) {
                                                children =
                                                    syncBlock && containerBlock
                                                        ? getCurrentBlockChildren(syncBlock, {
                                                              blockRuntimeState,
                                                              uniqueContainerId: containerBlock.id
                                                          })
                                                        : undefined
                                            } else {
                                                children = syncBlock
                                                    ? getCurrentBlockChildren(syncBlock, {
                                                          blockRuntimeState,
                                                          uniqueContainerId: `${currentScope}@${syncBlock?.id}`
                                                      })
                                                    : undefined
                                            }
                                            const index = (children ?? []).findIndex(v => v.id === value)
                                            if (index !== -1) {
                                                children?.splice(index, 1)
                                            }
                                        })
                                        deleteSyncBlock(value)
                                    } else {
                                        onRemove([{ id: value, scope: currentScope }])
                                    }
                                }}
                            />
                        }
                        onClick={() => {
                            openFloatBox({
                                blockId: floatBlock.id,
                                stackId,
                                rootPageId,
                                open: true,
                                scope: isSyncScope ? currentScope ?? containerBlock?.id : undefined
                            })

                            setPageStack(draft => {
                                const stack = equalPageStack({ rootPageId, stackId })(draft)
                                if (stack) {
                                    stack.state.selectedNodes = [
                                        { id: floatBlock.id, scope: isSyncScope ? currentScope ?? containerBlock?.id : undefined }
                                    ]
                                }
                            })
                        }}
                    />
                </ListItem4ByecodeUi>
            )}
        </Group4ByecodeUi>
    )
}
