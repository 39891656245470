import type { BlockAbstract, ButtonAction, DataSourceAbstract, FilterCommonCondition } from '@lighthouse/core'
import { BlockType, VariableType } from '@lighthouse/core'
import type { CollisionArea} from '@lighthouse/shared';
import {     type FlowLayoutNode,
    fillSyncBlock,
    getBlockChildren,
    getTimestampAboutWeekAfter,
    getWithScopeId,
hasChildrenBlock ,
    isContainerBlock,
    isCustomViewBlock,
    isFloatBoxBlock,
    isFormContainerBlock
} from '@lighthouse/shared'
import { getCachedItem, nanoid, setCachedItem } from '@lighthouse/tools'
import { clone } from 'rambda'

import type { NodeIdWithScope } from '@/atoms/page/types'
import { CacheKey, GroupCacheAtom, QuickFilterCacheAtom, TablePropsCacheAtom } from '@/atoms/storage/types'

/**
 * 根据id和tree删除节点
 * @date 2024/1/8 - 13:42:38
 *
 * @export
 * @param {string} id
 * @returns {(tree: BlockAbstract[]) => BlockAbstract[]}
 */
export function removeBlockById(id: string) {
    const recursion = (tree: BlockAbstract[]): undefined | BlockAbstract[] => {
        for (let i = 0; i < tree.length; i++) {
            const block = tree[i]
            if (block.id === id) {
                return tree.splice(i, 1)
            }

            if (block.type === BlockType.container) {
                for (const view of block.children) {
                    const res = recursion(view.children)
                    if (res) {
                        return res
                    }
                }
            }

            const children = getBlockChildren(block)
            if (children) {
                const res = recursion(children)
                if (res) {
                    return res
                }
            }
        }
    }
    return recursion
}

/**
 * over的是否是active本身或者后代，禁止自己插入到自己或者自己的后代
 * @date 2024/3/26 - 15:01:24
 *
 * @export
 * @param {NodeIdWithScope} activeId
 * @param {CollisionArea} overId
 */
export function isActiveInOver(activeId: NodeIdWithScope, over: CollisionArea, blocks: BlockAbstract[], syncComponents: BlockAbstract[]) {
    if (activeId.id === over.scope) {
        return true
    }

    function findOver(id: string, tree: BlockAbstract[]): boolean {
        for (const node of tree) {
            if (node.id === id) {
                return true
            }

            const filledBlock = fillSyncBlock(node, syncComponents)
            const children = getBlockChildren(filledBlock)
            if (children) {
                const res = findOver(id, children)
                if (res) {
                    return res
                }
            }
        }

        return false
    }

    const recursion = (tree: BlockAbstract[]): boolean => {
        for (const node of tree) {
            const filledBlock = fillSyncBlock(node, syncComponents)

            const children = getBlockChildren(filledBlock)

            if (filledBlock.id === activeId.id) {
                if (children) {
                    const res = findOver(over.id, children)
                    if (res) {
                        return true
                    }
                }
                return false
            }

            if (children) {
                const res = recursion(children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    return recursion(activeId.scope ? syncComponents : blocks)
}

/**
 * 检查是否是自定义视图的后代
 *
 * @export
 * @param {string} target
 * @param {BlockAbstract[]} blocks
 * @returns {boolean}
 */
export function isCustomChildren(targetId: string, blocks: BlockAbstract[]) {
    function findTarget(id: string, tree: BlockAbstract[]) {
        for (const child of tree) {
            if (child.id === id) {
                return true
            }

            const children = getBlockChildren(child)
            if (children) {
                const res = findTarget(id, children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    function find(tree: BlockAbstract[]): boolean {
        for (const child of tree) {
            if (isCustomViewBlock(child) && child.children) {
                const res = findTarget(targetId, child.children)
                if (res) {
                    return true
                }
            }

            const children = getBlockChildren(child)
            if (children) {
                const res = find(children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    return find(blocks)
}

/**
 * 检查是否是表单容器的后代
 *
 * @export
 * @param {string} target
 * @param {BlockAbstract[]} blocks
 * @returns {boolean}
 */
export function isFormContainerChildren(targetId: string, blocks: BlockAbstract[]) {
    function findTarget(id: string, tree: BlockAbstract[]) {
        for (const child of tree) {
            if (child.id === id) {
                return true
            }

            const children = getBlockChildren(child)
            if (children) {
                const res = findTarget(id, children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    function find(tree: BlockAbstract[]): boolean {
        for (const child of tree) {
            if (isFormContainerBlock(child) && child.children) {
                const res = findTarget(targetId, child.children)
                if (res) {
                    return true
                }
            }

            const children = getBlockChildren(child)
            if (children) {
                const res = find(children)
                if (res) {
                    return true
                }
            }
        }

        return false
    }

    return find(blocks)
}

/**
 * 递归检查是否有禁止的node
 *
 * @export
 * @param {FlowLayoutNode} target
 * @param {BlockType[]} notAllowedList
 * @returns {boolean}
 */
export function includeNotAllowedBlock(target: BlockAbstract, notAllowedList: BlockType[]): boolean {
    if (notAllowedList.includes(target.type)) {
        return true
    }

    const children = getBlockChildren(target)

    if (children) {
        for (const child of children) {
            const res = includeNotAllowedBlock(child, notAllowedList)
            if (res) {
                return true
            }
        }
    }
    return false
}

/** 递归复制block，替换id */
export function copyBlock<T extends BlockAbstract>(blocks: T[]): T[] {
    const idMap = new Map<string, string>()

    // 递归收集新旧blockId的映射
    function collectId(oldBlock: BlockAbstract) {
        const newId = `${oldBlock.type}-${nanoid(12)}`
        idMap.set(oldBlock.id, newId)

        if (isContainerBlock(oldBlock)) {
            oldBlock.children.forEach(view => {
                view.children.forEach(collectId)
            })
        }

        if (isFormContainerBlock(oldBlock) || isCustomViewBlock(oldBlock) || isFloatBoxBlock(oldBlock)) {
            oldBlock.children.forEach(collectId)
        }
    }

    blocks.forEach(collectId)

    // 重设动作中绑定的组件id
    function resetActionBindBlockId(action: ButtonAction | undefined) {
        const newActionId = nanoid()
        if(action){
            action.id = newActionId
        }
        if (action?.type === 'control' && action.data.control.controlElementId) {
            const oldId = action.data.control.controlElementId
            if (idMap.has(oldId)) {
                action.data.control.controlElementId = idMap.get(oldId) || oldId
            }
        }

        if (action?.type === 'scrollTo' && action.data.scrollTo.scrollToElementId) {
            const oldId = action.data.scrollTo.scrollToElementId
            if (idMap.has(oldId)) {
                action.data.scrollTo.scrollToElementId = idMap.get(oldId) || oldId
            }
        }
    }
    // BlockType.button, BlockType.card, BlockType.container, BlockType.view, BlockType.icon, BlockType.image, BlockType.tabs
    function recursion(oldBlock: BlockAbstract): BlockAbstract {
        if (oldBlock.type === BlockType.button || oldBlock.type === BlockType.image) {
            resetActionBindBlockId(oldBlock.config.action)
        }

        if (oldBlock.type === BlockType.card) {
            oldBlock.config.list.forEach(item => {
                resetActionBindBlockId(item.action)
            })
        }

        if (oldBlock.type === BlockType.tabs) {
            oldBlock.config.baseList.forEach(item => {
                resetActionBindBlockId(item.action)
            })
        }

        if (oldBlock.type === BlockType.icon || oldBlock.type === BlockType.image) {
            const newActionId = nanoid()
            oldBlock.config.action.id = newActionId
        }

        if (oldBlock.type === BlockType.view) {
            oldBlock.config.actions.toolbar.actionGroup?.group.forEach(item => {
                resetActionBindBlockId(item.action)
            })
            oldBlock.config.actions.recordOperator.actionGroup?.group.forEach(item => {
                resetActionBindBlockId(item.action)
            })
            resetActionBindBlockId(oldBlock.config.actions.recordClicked.action)

            oldBlock.config.linkFilterController?.expression?.conditions?.forEach(item => {
                const newItem = item as FilterCommonCondition
                if (newItem.idVariable?.type === VariableType.FILTER && newItem.idVariable.filterVariable?.blockId) {
                    const oldId = newItem.idVariable.filterVariable.blockId
                    newItem.idVariable.filterVariable.blockId = idMap.get(oldId) || oldId
                }
            })
        }

        if (oldBlock.type === BlockType.chart) {
            oldBlock.config.linkFilterController?.expression?.conditions?.forEach(item => {
                const newItem = item as FilterCommonCondition
                if (newItem.idVariable?.type === VariableType.FILTER && newItem.idVariable.filterVariable?.blockId) {
                    const oldId = newItem.idVariable.filterVariable.blockId
                    newItem.idVariable.filterVariable.blockId = idMap.get(oldId) || oldId
                }
            })
        }

        if (oldBlock.type === BlockType.container) {
            resetActionBindBlockId(oldBlock.config.action)

            if (oldBlock.config.floatPointer) {
                const oldId = oldBlock.config.floatPointer
                if (idMap.has(oldId)) {
                    oldBlock.config.floatPointer = idMap.get(oldId) || oldId
                }
            }

            return {
                ...oldBlock,
                id: idMap.get(oldBlock.id) || oldBlock.id,
                children: oldBlock.children.map(view => {
                    return {
                        ...view,
                        children: view.children.map(recursion)
                    }
                })
            }
        }

        if (isCustomViewBlock(oldBlock) || isFormContainerBlock(oldBlock) || isFloatBoxBlock(oldBlock)) {
            return { ...oldBlock, id: idMap.get(oldBlock.id) || oldBlock.id, children: oldBlock.children.map(recursion) }
        }

        return { ...oldBlock, id: idMap.get(oldBlock.id) || oldBlock.id }
    }

    return blocks.map(block => recursion(clone(block))) as T[]
}

/** 将block转换为一个同步组件 */
export function transformBlock2SyncComponents<T extends BlockAbstract>(block: T, isMaster = true): T {
    function recursion(b: BlockAbstract, _isMaster?: boolean): BlockAbstract {
        // // master的id需要更新一下，子级元素尽可能保留相同的id，
        // // 这样创建出来的同步block实例内的子级id和之前一致。
        // const newId = _isMaster ? `${b.type}-${nanoid(12)}` : b.id

        if (b.type === BlockType.container) {
            return {
                ...b,
                // id: newId,
                isMasterSynchronous: _isMaster,
                isLeafSynchronous: !_isMaster,
                children: b.children.map(view => {
                    return {
                        ...view,
                        children: view.children.map(item => recursion(item))
                    }
                })
            }
        }

        if (hasChildrenBlock(b)) {
            return {
                ...b,
                // id: newId,
                isMasterSynchronous: _isMaster,
                isLeafSynchronous: !_isMaster,
                children: b.children.map(item => recursion(item))
            }
        }

        // if (b.type === BlockType.view && b.config.viewType === 'custom' && b.children) {
        //     return {
        //         ...b,
        //         // id: newId,
        //         isMasterSynchronous: _isMaster,
        //         isLeafSynchronous: !_isMaster,
        //         children: b.children.map(item => recursion(item))
        //     }
        // }

        return { ...b, /* id: newId, */ isMasterSynchronous: _isMaster, isLeafSynchronous: !_isMaster }
    }

    return recursion(block, isMaster) as T
}
