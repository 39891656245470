import { useEffectEvent } from '@byecode/ui'
import type { ElementProps, FloatingContext, ReferenceType, UseHoverProps } from '@floating-ui/react'
import { useFloatingPortalNode, useFloatingTree } from '@floating-ui/react'
import { atom } from 'jotai'
import { atomWithImmer } from 'jotai-immer'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useClickAway, useLatest } from 'react-use'

import { useAtomData } from './useAtomData'

export type popoverListData = {
    id: string
    reference: React.MutableRefObject<ReferenceType | null>
    floating: React.MutableRefObject<ReferenceType | null>
    open: boolean
    children: popoverListData[]
}

export const popoverListAtom = atom<popoverListData[]>([])

export interface UsePopoverTriggerProps {
    enabled?: boolean

    blockId?: string

    enabledEscape?: boolean

    restMs?: number

    delay?: number

    boundary?: HTMLElement | string

    toggle?: boolean

    openEvent: 'hover' | 'click'

    closeEvent: 'hover' | 'click'
}

export const findPopoverTree = (floatTrees: popoverListData[], parentId: string): popoverListData | null => {
    let parentFloatTree: popoverListData | null = null
    function recursion(trees: popoverListData[]) {
        for (const tree of trees) {
            if (tree.id === parentId) {
                parentFloatTree = tree
                return
            }
            if (tree.children.length > 0) {
                recursion(tree.children)
            }
        }
    }
    recursion(floatTrees)
    return parentFloatTree
}

export function flattenTree(nodes: popoverListData[]): popoverListData[] {
    let result: popoverListData[] = []

    nodes.forEach(node => {
        result.push(node) // 添加当前节点
        if (node.children && node.children.length > 0) {
            result = [...result, ...flattenTree(node.children)] // 递归展平子节点
        }
    })

    return result
}

export function usePopoverTrigger(context: FloatingContext, props: UsePopoverTriggerProps) {
    const {
        open,
        onOpenChange,
        dataRef,
        elements: { domReference }
    } = context
    const { enabled = true, enabledEscape = true, toggle, openEvent, boundary, closeEvent, restMs = 0, delay = 100, blockId } = props

    const pointerTypeRef = React.useRef<'mouse' | 'pen' | 'touch'>()
    const restTimeoutRef = React.useRef(-1)
    const blockMouseEnterRef = React.useRef(false)
    const delayRef = useLatest(delay)
    const restTimeoutPendingRef = React.useRef(false)
    const openRef = useLatest(open)
    const popoverList = useAtomData(popoverListAtom)

    // click关闭
    useClickAway(context.refs.floating, e => {
        if (closeEvent !== 'click' || !enabled) {
            return
        }
        const { target } = e
        const boundaryElement = typeof boundary === 'string' ? document.querySelector(`${boundary}`) : boundary
        const childElement = context.refs.floating.current
        if (target instanceof Element) {
            const isInBoundaryElement = boundaryElement?.contains(target)
            if (!isInBoundaryElement) {
                return
            }
            const isInsideChild = childElement && childElement.contains(target)
            const isInPopoverTarget = (context.refs.reference?.current as HTMLDivElement)?.contains(target)
            const childPopoverTree = findPopoverTree(popoverList, blockId ?? '')
            const isInChildrenPopover = flattenTree(childPopoverTree?.children ?? []).some(({ id, reference, floating }) => {
                const popoverDropdown = floating.current as HTMLDivElement
                const popoverTarget = reference.current as HTMLDivElement
                return popoverDropdown?.contains(target) || popoverTarget?.contains(target)
            })
            if (!isInsideChild && !isInPopoverTarget && !isInChildrenPopover) {
                onOpenChange(false)
            }
        }
    })

    let closeTimer: NodeJS.Timeout | null = null

    const handleMouseMove = useCallback(
        (event: MouseEvent) => {
            requestAnimationFrame(() => {
                const { target } = event
                if (target instanceof HTMLElement) {
                    const isDropdownMove = context.refs.floating.current?.contains(target)
                    const isTargetMove = (context.refs.reference.current as HTMLDivElement)?.contains(target)
                    // 在子集的内容移动鼠标
                    const childPopoverTree = findPopoverTree(popoverList, blockId ?? '')

                    const isInChildrenPopover = flattenTree(childPopoverTree?.children ?? []).some(({ id, reference }) => {
                        const popoverDropdown = document.querySelector<HTMLDivElement>(`[data-node-id="${id}"]`)
                        const popoverTarget = reference.current as HTMLDivElement
                        return popoverDropdown?.contains(target) || popoverTarget?.contains(target)
                    })

                    // 判断鼠标是否在 popover 内部
                    const isInside = isInChildrenPopover || isDropdownMove || isTargetMove

                    if (isInside) {
                        // 鼠标进入 popover，清除关闭定时器
                        if (closeTimer) {
                            clearTimeout(closeTimer)
                            closeTimer = null
                        }
                    } else {
                        // 鼠标移出 popover，启动延迟关闭逻辑
                        if (!closeTimer) {
                            closeTimer = setTimeout(() => {
                                onOpenChange(false)
                            }, delayRef.current) // 100 毫秒延迟
                        }
                    }
                }
            })
        },
        [popoverList, onOpenChange, blockId]
    )

    useEffect(() => {
        if (closeEvent !== 'hover' || !enabled) {
            return
        }

        document.addEventListener('mousemove', handleMouseMove)
        return () => {
            document.removeEventListener('mousemove', handleMouseMove)
            if (closeTimer) {
                clearTimeout(closeTimer)
            }
        }
    }, [closeEvent, closeTimer, enabled, handleMouseMove, openRef])

    // hover关闭
    useEffect(() => {
        blockMouseEnterRef.current = open
    }, [open])

    const isClickLikeOpenEvent = useEffectEvent(() => {
        return dataRef.current.openEvent ? ['click', 'mousedown'].includes(dataRef.current.openEvent.type) : false
    })

    const reference: ElementProps['reference'] = React.useMemo(() => {
        function setPointerRef(event: React.PointerEvent) {
            pointerTypeRef.current = event.pointerType
        }
        return {
            onPointerDown: setPointerRef,
            onPointerEnter: setPointerRef,
            onClick(event) {
                if (openEvent !== 'click') {
                    return
                }
                const isInDropdownClick = context.refs.floating.current?.contains(event.target as HTMLElement)

                if (isInDropdownClick) {
                    event.stopPropagation()
                    return
                }
                if (open) {
                   toggle && onOpenChange(false)
                } else {
                    onOpenChange(true)
                }
            },
            onMouseEnter(event) {
                if (openEvent !== 'hover') {
                    return
                }
                blockMouseEnterRef.current = true
            },
            onMouseLeave() {
                if (openEvent !== 'hover') {
                    return
                }
                blockMouseEnterRef.current = false
            },
            onMouseMove(event) {
                if (openEvent !== 'hover') {
                    return
                }
                function handleMouseMove() {
                    if (!openRef.current && blockMouseEnterRef.current) {
                        onOpenChange(true)
                    }
                }
                if (open) {
                    return
                }
                // Ignore insignificant movements to account for tremors.
                if (restTimeoutPendingRef.current && event.movementX ** 2 + event.movementY ** 2 < 2) {
                    return
                }
                clearTimeout(restTimeoutRef.current)

                if (pointerTypeRef.current === 'touch') {
                    handleMouseMove()
                } else {
                    restTimeoutPendingRef.current = true
                    restTimeoutRef.current = window.setTimeout(handleMouseMove, restMs)
                }
            }
        }
    }, [context.refs.floating, onOpenChange, open, openEvent, openRef, restMs])

    const floating: ElementProps['floating'] = React.useMemo(
        () => ({
            //
        }),
        []
    )

    return useMemo(() => (enabled ? { reference, floating } : {}), [enabled, floating, reference])
}
