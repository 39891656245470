import { Flex, IconFont, Input, Popover, SegmentedControl, Slider, Text } from '@byecode/ui'
import type { AnimationControlSetting, AnimationTypeEnum } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import { FilledInput, FillPickerPopoverDropdown, ListItem4ByecodeUi, ShadowController } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import { ANIMATE_OPTIONS, ROTATE_OPTIONS } from '../constants'
import { OffsetInput } from '../offset'
import { SizeInput } from '../SizeInput'
import TransitionPreview from './TransitionPreview'
import { getAnimationSettingRule } from './util'

interface AnimationSettingProps {
    value: AnimationControlSetting
    type: AnimationTypeEnum
    onChange: (v: Partial<AnimationControlSetting>) => void
    onOpen: (v: 'transition' | 'shadow' | 'background') => void
}
const SCxContainer = styled.div`
    /* padding: 0 16px; */
`

const AnimationSetting: React.FunctionComponent<AnimationSettingProps> = ({ value, type, onChange, onOpen }) => {
    const { repeatType, opacity, rotateType, transition, rotate3d, background, shadow, skew, scale, rotate, offset } = value
    console.log("🚀 ~ background:", background)
    const colorSystemMethods = useColorSystemAction()
    const setting = getAnimationSettingRule(type)

    return (
        <SCxContainer>
            {setting.type && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>类型</Text>
                    <div style={{ width: 180 }}>
                        <SegmentedControl
                            fullWidth
                            data={ANIMATE_OPTIONS}
                            value={repeatType}
                            onChange={v => onChange({ repeatType: v as 'loop' | 'mirror' })}
                        />
                    </div>
                </ListItem4ByecodeUi>
            )}
            {setting.opacity && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>不透明度</Text>
                    <Slider
                        value={opacity}
                        onChange={v => onChange?.({ opacity: Number(v.toFixed(1)) })}
                        onChangeEnd={v => onChange?.({ opacity: Number(v.toFixed(1)) })}
                        min={0}
                        max={1}
                        step={0.1}
                        style={{ width: 180 }}
                        inputProps={{
                            autoSelect: true,
                            onFocus: e => {
                                e.target.select()
                            }
                        }}
                    />
                </ListItem4ByecodeUi>
            )}
            {setting.scale && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>大小</Text>
                    <Slider
                        value={scale}
                        onChange={v => onChange?.({ scale: Number(v.toFixed(1)) })}
                        onChangeEnd={v => onChange?.({ scale: Number(v.toFixed(1)) })}
                        min={0}
                        max={10}
                        step={0.1}
                        style={{ width: 180 }}
                        inputProps={{
                            autoSelect: true,
                            onFocus: e => {
                                e.target.select()
                            }
                        }}
                    />
                </ListItem4ByecodeUi>
            )}
            {setting.rotate && (
                <>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>旋转</Text>
                        <div style={{ width: 180 }}>
                            <SegmentedControl
                                fullWidth
                                data={ROTATE_OPTIONS}
                                value={rotateType}
                                onChange={v => onChange({ rotateType: v as '2d' | '3d' })}
                            />
                        </div>
                    </ListItem4ByecodeUi>
                    {rotateType === '2d' && (
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>旋转角度</Text>
                            <SizeInput
                                radius={0}
                                icon="Angle"
                                type="number"
                                min={0}
                                max={360}
                                onFocus={e => e.currentTarget.select()}
                                value={rotate}
                                onChange={v => onChange?.({ rotate: Number(v) })}
                            />
                        </ListItem4ByecodeUi>
                    )}
                    {rotateType === '3d' && (
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>旋转角度</Text>
                            <Flex style={{ width: 180 }} gap={8}>
                                <SizeInput
                                    radius={0}
                                    icon="X"
                                    type="number"
                                    min={0}
                                    max={360}
                                    onFocus={e => e.currentTarget.select()}
                                    value={rotate3d?.x}
                                    placeholder="0"
                                    onChange={v => rotate3d && onChange?.({ rotate3d: { ...rotate3d, x: v } })}
                                />
                                <SizeInput
                                    radius={0}
                                    icon="Y"
                                    type="number"
                                    min={0}
                                    max={360}
                                    onFocus={e => e.currentTarget.select()}
                                    value={rotate3d?.y}
                                    placeholder="0"
                                    onChange={v => rotate3d && onChange?.({ rotate3d: { ...rotate3d, y: v } })}
                                />
                                <SizeInput
                                    radius={0}
                                    icon="Z"
                                    type="number"
                                    min={0}
                                    max={360}
                                    onFocus={e => e.currentTarget.select()}
                                    value={rotate3d?.z}
                                    placeholder="0"
                                    onChange={v => rotate3d && onChange?.({ rotate3d: { ...rotate3d, z: v } })}
                                />
                            </Flex>
                        </ListItem4ByecodeUi>
                    )}
                </>
            )}
            {setting.skew && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>倾斜</Text>
                    <OffsetInput
                        value={skew}
                        onChange={val => {
                            onChange({ skew: val })
                        }}
                    />
                </ListItem4ByecodeUi>
            )}
            {setting.offset && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>偏移</Text>
                    <OffsetInput
                        value={offset}
                        onChange={val => {
                            onChange({ offset: val })
                        }}
                    />
                </ListItem4ByecodeUi>
            )}
            {setting.background && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>背景</Text>
                    <FilledInput
                        value={background}
                        onClear={() => {
                            onChange({ background: { type: BACKGROUND_TYPE.color, color: '' } })
                        }}
                        style={{ width: 180 }}
                        onClick={() => onOpen('background')}
                    />
                </ListItem4ByecodeUi>
            )}
            {setting.shadow && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>阴影</Text>
                    <FilledInput
                        value={{ type: BACKGROUND_TYPE.color, color: shadow?.color }}
                        onClear={() => {
                            onChange({ shadow: { color: '', x: 0, y: 0, blur: 0, diffusion: 0 } })
                        }}
                        style={{ width: 180 }}
                        onClick={() => {
                            if (!shadow?.color) {
                                onChange({ shadow: { color: '#00000040', x: 0, y: 4, blur: 4, diffusion: 4 } })
                            }
                            onOpen('shadow')
                        }}
                    />
                </ListItem4ByecodeUi>
            )}
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text>过渡</Text>
                <TransitionPreview type={transition.type} onClick={() => onOpen('transition')} />
            </ListItem4ByecodeUi>
        </SCxContainer>
    )
}

export default AnimationSetting
