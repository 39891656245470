import { Popover } from '@byecode/ui/components/Popover'
import { Radio } from '@byecode/ui/components/Radio'
import { Slider } from '@byecode/ui/components/Slider'
import type { ApplicationSettingThemeColor, Veins } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { VEINS_OPTIONS } from '../../utils'
import { FilledInput } from '../FilledInput'
import { FillPickerPopoverDropdown } from '../FillPicker'
import { ListItem4ByecodeUi } from '../ListItemMenu'

const StyledVeinsRadioGroup = styled(Radio.Group)`
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 12px;
    margin-bottom: 8px;
`
const StyledVeinsRadio = styled(Radio)`
    gap: 0;
    padding: 0;
    .byecode-radio-input {
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 5px;
        border-color: transparent;
        &:checked {
            border-color: var(--color-main);
        }
    }
    .byecode-radio-icon {
        display: none;
    }
    .byecode-radio-inner {
        padding: 0;
        border-radius: 5px;
        & > * {
            vertical-align: middle;
            color: #000;
        }
    }
`

const hasStrokeWidth = (v: string | undefined) => !!v && !!VEINS_OPTIONS.find(item => item.value === v)?.strokeWidth

interface VeinsControllerProps {
    name: string
    value?: Veins
    onChange?: (value: Veins) => void

    onUpdateColor: (value: ApplicationSettingThemeColor) => void
    onCreateColor: (value: ApplicationSettingThemeColor) => void
}

/** 纹理控制器 */
export const VeinsController = ({ name, value, onChange, onCreateColor, onUpdateColor }: VeinsControllerProps) => {
    const showStrokeWidth = useMemo(() => hasStrokeWidth(value?.value), [value?.value])

    return (
        <>
            <StyledVeinsRadioGroup name={name} value={value?.value} onChange={v => onChange?.({ ...value, value: v })}>
                {VEINS_OPTIONS.map(item => (
                    <StyledVeinsRadio value={item.value} key={item.value}>
                        <img
                            src={`data:image/svg+xml;charset=utf8,${encodeURI(item.thumb)}`}
                            style={{ width: '100%', aspectRatio: 1 }}
                            alt={item.label}
                        />
                    </StyledVeinsRadio>
                ))}
            </StyledVeinsRadioGroup>

            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <div>颜色</div>
                <Popover width={300}>
                    <Popover.Target>
                        <FilledInput
                            value={{ type: BACKGROUND_TYPE.color, color: value?.color }}
                            onClear={() => onChange?.({ ...value, color: '' })}
                            style={{ width: 180 }}
                        />
                    </Popover.Target>
                    {/* <PopoverDropdownWithClose title="填充">
                        <FillPicker
                            value={{ type: BACKGROUND_TYPE.color, color: value?.color }}
                            onChange={v => onChange?.({ ...value, color: v.color })}
                        />
                    </PopoverDropdownWithClose> */}
                    <FillPickerPopoverDropdown
                        title="填充"
                        value={{ type: BACKGROUND_TYPE.color, color: value?.color }}
                        onChange={v => onChange?.({ ...value, color: v.color })}
                        onCreateColor={onCreateColor}
                        onUpdateColor={onUpdateColor}
                    />
                </Popover>
            </ListItem4ByecodeUi>

            {showStrokeWidth && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <div>粗细</div>
                    <Slider
                        style={{ width: 180 }}
                        hideIcon
                        min={1}
                        max={100}
                        value={value?.strokeWidth}
                        onChange={v => onChange?.({ ...value, strokeWidth: v })}
                        inputProps={{
                            onFocus: e => {
                                e.target.select()
                            }
                        }}
                    />
                </ListItem4ByecodeUi>
            )}

            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <div>大小</div>
                <Slider
                    style={{ width: 180 }}
                    hideIcon
                    min={1}
                    max={100}
                    value={value?.size}
                    onChange={v => onChange?.({ ...value, size: v })}
                    inputProps={{
                        onFocus: e => {
                            e.target.select()
                        }
                    }}
                />
            </ListItem4ByecodeUi>
        </>
    )
}
