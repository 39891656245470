import { Loading } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { IconStyle } from '@lighthouse/core'
import { ICON_STYLE } from '@lighthouse/core'
import React, { useLayoutEffect, useState } from 'react'
import { createGlobalState } from 'react-use'

import type { IconListItem, IconListProps } from '../IconPicker.types'
import { IconPickerList } from './IconPickerList'

const fetchIconList = function (type: IconStyle | undefined) {
    return fetch(getAssetUrl('icons', type === 'line' ? 'icon-line.json' : 'icon-db.json')).then(response => response.json())
}

const useGlobalIcon = createGlobalState<IconListItem[]>([])
const useGlobalLineIcon = createGlobalState<IconListItem[]>([])

export const IconList: React.FC<IconListProps> = props => {
    const [data, setData] = useGlobalIcon()
    const [loading, setLoading] = useState(data.length === 0)
    useLayoutEffect(() => {
        if (data.length === 0) {
            fetchIconList(ICON_STYLE.face)
                .then((val: IconListItem[]) => {
                    setData(val)
                    setLoading(false)
                })
                .catch(() => {
                    // eslint-disable-next-line no-console
                    console.log(`fetch icon.json fail`)
                })
        }
    })
    return loading ? <Loading /> : <IconPickerList type={ICON_STYLE.face} data={data} {...props} />
}

export const LineIconList: React.FC<IconListProps> = props => {
    const [data, setData] = useGlobalLineIcon()
    const [loading, setLoading] = useState(data.length === 0)
    useLayoutEffect(() => {
        if (data.length === 0) {
            fetchIconList(ICON_STYLE.line)
                .then((val: IconListItem[]) => {
                    setData(val)
                    setLoading(false)
                })
                .catch(() => {
                    // eslint-disable-next-line no-console
                    console.log(`fetch line-icon.json fail`)
                })
        }
    })
    return loading ? <Loading /> : <IconPickerList type={ICON_STYLE.line} data={data} {...props} />
}
