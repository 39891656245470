import { getAssetUrl } from '@lighthouse/assets'
import { TabsBlock, useCustomViewBlockContext } from '@lighthouse/block'
import type { TabsBlockAbstract, VariableADTvalue } from '@lighthouse/core'
import { ALIGNMENT } from '@lighthouse/core'
import { TEXT_FONT_NORMAL, useActionRunningLoadings, useAppContainerContext, useAtomData } from '@lighthouse/shared'
import equal from 'fast-deep-equal'
import produce from 'immer'
import React, { memo, useCallback, useEffect, useMemo } from 'react'

import { applicationSettingAtom } from '@/atoms/application/state'
import { pageStackAtomFamily } from '@/atoms/page/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource } from '@/hooks/useDataSource'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'
import { useVisibilityFilter } from '@/hooks/useVisibilityFilter'

interface TabsControllerProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
    blockData: TabsBlockAbstract
}

const TabsController = ({ blockData, ...rest }: TabsControllerProps) => {
    const { breakPoint, baseList } = blockData.config
    const { font } = breakPoint
    const { scale } = useAppContainerContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { record: customViewRecord, pointer } = useCustomViewBlockContext()
       const dataSource = useDataSource(appId, envId, pointer)
    const { prev, curr } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const visibilityFilter = useVisibilityFilter({ prev, curr })

    const visibleList = useMemo(
        () =>
            baseList.filter(item =>
                visibilityFilter({
                    visibility: {
                        visibilityFilter: item.visibilityFilter,
                        visible: 'FILTER'
                    },
                    viewRecord: {
                        record: customViewRecord,
                        datasource: dataSource
                    }
                })
            ),
        [baseList, visibilityFilter, customViewRecord, dataSource]
    )


    const currentTab = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(
            s => {
                if (!s) {
                    return
                }

                const activeItem = baseList.find(item => {
                    if (item.action?.type === 'control') {
                        const { controlElementId, controlElementType, controlAction } = item.action.data.control
                        if (controlElementType === 'container' && controlAction?.type === 'switchPanel') {
                            const viewId = controlAction.params?.switchPanelId
                            return s.blockRuntimeState.container?.[controlElementId]?.currentView === viewId
                        }
                    }

                    return false
                })

                return activeItem?.id
            },
            [baseList]
        )
    )

    const { handleActionTrigger } = useActionTrigger()
    const { handleActionTriggerWithLoading } = useActionRunningLoadings()

    const handleTabsChange = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>, id: string) => {
            const action = baseList.find(item => item.id === id)?.action
            if (!action) {
                return
            }
            handleActionTriggerWithLoading({ type: 'click', action, id, trigger: () => handleActionTrigger(action) })
        },
        [baseList, handleActionTriggerWithLoading, handleActionTrigger]
    )

    /** **************************** 文本样式字体 start **************************** */
    const textPresets = useAtomData(
        applicationSettingAtom,
        useCallback(s => s?.theme.textPresetList ?? [], [])
    )

    const mergedBlockData = useMemo(() => {
        const advance = font?.advance
        if (advance) {
            const advanceOption = textPresets.find(item => item.id === advance)
            if (advanceOption) {
                const { fontFamily, fontSize, letterSpacing, lineClamp, lineHeight } = advanceOption
                return produce(blockData, draft => {
                    draft.config.baseList = visibleList
                    draft.config.breakPoint.font = {
                        ...draft.config.breakPoint.font,
                        align: draft.config.breakPoint.font?.align || ALIGNMENT.left,
                        advance,
                        fontFamily,
                        fontSize,
                        letterSpacing,
                        lineClamp,
                        lineHeight
                    }
                })
            }
        }

        return produce(blockData, draft => {
            draft.config.baseList = visibleList
        })
    }, [blockData, font?.advance, textPresets, visibleList])

    useEffect(() => {
        const ff = mergedBlockData.config.breakPoint.font?.fontFamily
        if (!ff || ff === TEXT_FONT_NORMAL) {
            return
        }
        document.fonts.ready
            .then(async ffs => {
                const fontFaceList = [...ffs.values()]
                const isLoaded = fontFaceList.some(item => item.family === ff)
                if (!isLoaded) {
                    const fontUrl = `url(${getAssetUrl('font', `${ff}.woff2`)})`
                    const newFont = new FontFace(ff, fontUrl)
                    await newFont.load()
                    ffs.add(newFont)
                }
            })
            // eslint-disable-next-line no-console
            .catch(console.warn)
    }, [mergedBlockData.config.breakPoint.font?.fontFamily])
    /** **************************** 文本样式字体 end **************************** */

    /** **************************** 解析背景图片需要使用的参数 start **************************** */
    const { recordId: parentRecordId } = prev
    const { recordId  } = curr
    const renderLabel = useVariableValueRender(parentRecordId, recordId)
    const { record } = useCustomViewBlockContext()
    const parseBackgroundVariableImage = useCallback(
        (value: VariableADTvalue | undefined) => renderLabel(value, { useFileUrl: true, viewRecord: record }),
        [record, renderLabel]
    )
    /** **************************** 解析背景图片需要使用的参数 end ****************************** */

    return (
        <TabsBlock
            blockData={mergedBlockData}
            scale={scale}
            value={currentTab}
            onClickTab={handleTabsChange}
            parseVariableImage={parseBackgroundVariableImage}
            {...rest}
        />
    )
}

export default memo(TabsController, equal)
