import { getFlatAllBlock } from '@lighthouse/block'
import type { BlockAbstract, ChartBlockAbstract, ContainerBlockAbstract, DataSourceAbstract, ViewOptions } from '@lighthouse/core'
import { BlockType, ChartType } from '@lighthouse/core'
import {
    ApplicationPreviewEnum,
    chartTypeLevel,
    findBlockById,
    findNormalOrSyncBlock,
    getViewColumns,
    initBlockRuntimeState,
    useAtomData
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { current, original } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { debounce } from 'throttle-debounce'
import type { MakeADTMember } from 'ts-adt/MakeADT'
import { makeMatchI } from 'ts-adt/MakeADT'

import { previewTypeAtom, syncComponentsAtom } from '@/atoms/application/state'
import { createBlockAtom } from '@/atoms/page/action'
import { blockCreatingListAtom, blocksAtom, lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { BlockSettings } from '@/components/BlockSettings'
import { generateCalendarConfig, generateGalleryConfig } from '@/constants/Block/generate/view'
import { getIndicatorOption } from '@/constants/Block/sharedStyle'
import { copyBlock } from '@/hooks/layoutEngine/utils'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import type { BlockUpdateOption } from '@/hooks/useBlock'
import { useBlockActions } from '@/hooks/useBlock'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useDefaultPageList } from '@/hooks/usePage'
import * as srv from '@/services'
import { BLOCK_UPDATE_ACTION, NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import {
    cleanBarConfig,
    cleanCalendarConfig,
    cleanGalleryConfig,
    cleanIndicatorConfig,
    cleanKanbanConfig,
    cleanPieConfig,
    cleanStriationConfig
} from './constants'
import type { BlockSettingActionADT } from './listener'
import { BlockSettingMitt } from './listener'

const getInitChartBlockConfigurator = function (block: ChartBlockAbstract, dataSource: DataSourceAbstract): ChartBlockAbstract {
    const { schema, appId, id: dsId } = dataSource
    const textField = Object.values(schema).find(f => f.type === 'text' || (f.type === 'aggregation' && f.innerType === 'TEXT'))
    const textFieldId = textField?.id || 'ID'
    const numberField = Object.values(schema).find(f => f.type === 'number' || (f.type === 'aggregation' && f.innerType === 'NUMBER'))
    const numberFieldId = numberField?.id
    const calcType = numberField ? 'max' : 'count'
    switch (block.config.chartType) {
        case 'bar':
        case 'striation': {
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: numberFieldId,
                                  calcType,
                                  chartType: 'bar'
                              }
                          ]
                        : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    }
                }
            }
        }
        case 'line': {
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: numberFieldId,
                                  calcType,
                                  chartType: 'line',
                                  showArea: false,
                                  showSymbol: true,
                                  lineType: 'smooth'
                              }
                          ]
                        : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    }
                }
            }
        }
        case 'funnel':
        case 'pie': {
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: numberFieldId,
                                  calcType
                              }
                          ]
                        : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    }
                }
            }
        }
        case 'composite': {
            const secondNumberField = Object.values(schema).find(f => f.type === 'number' && f.id !== numberFieldId)
            const secondCalcType = secondNumberField ? 'sum' : calcType
            return {
                ...block,
                config: {
                    ...block.config,
                    pointer: dsId,
                    appId,
                    dimensions: [{ id: nanoid(), fieldId: textField?.id || 'ID' }],
                    mainAxis: numberFieldId
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: numberFieldId,
                                  calcType,
                                  chartType: 'bar'
                              }
                          ]
                        : [],
                    linkFilterController: {
                        expression: {
                            where: 'AND',
                            conditions: []
                        }
                    },
                    showSecondaryAxis: !!secondNumberField,
                    secondaryAxis: secondNumberField
                        ? [
                              {
                                  id: nanoid(),
                                  fieldId: secondNumberField?.id,
                                  calcType: secondCalcType,
                                  chartType: 'line',
                                  showArea: false,
                                  showSymbol: true,
                                  lineType: 'straight'
                              }
                          ]
                        : []
                }
            }
        }
        case 'indicator': {
            return {
                ...block,
                config: {
                    ...block.config,
                    ...getIndicatorOption(),
                    appId,
                    pointer: dsId,
                    fieldId: numberFieldId,
                    calcType
                }
            }
        }
        default: {
            return block
        }
    }
    // const dimension = dataSource.schema
}

export interface BlockSettingsControllerProps {
    loading?: boolean
    dataSourceID?: string
}

const BlockSettingsController = React.forwardRef<HTMLDivElement, BlockSettingsControllerProps>(
    ({ loading, dataSourceID: parentDsId }, ref) => {
        const appId = useCurrentAppID()
        const envId = useCurrentEnvId()
        const { rootPageId, pageId, stackId, selectedNode } = useAtomData(
            lastPageOfStackAtom,
            useCallback(
                s => ({
                    rootPageId: s?.rootPageId ?? '',
                    pageId: s?.pageId ?? '',
                    stackId: s?.stackId ?? '',
                    selectedNode: s?.state.selectedNodes && s.state.selectedNodes.length === 1 ? s.state.selectedNodes[0] : undefined
                }),
                []
            )
        )

        const syncComponents = useAtomData(syncComponentsAtom)

        const selectedBlock = useAtomData(
            blocksAtom,
            useCallback(
                s => {
                    const blocks = s[pageId]
                    if (!blocks) {
                        return
                    }

                    if (!selectedNode) {
                        return
                    }

                    if (selectedNode.scope) {
                        return findBlockById(selectedNode.id, syncComponents)
                    }

                    const normalBlock = findBlockById(selectedNode.id, blocks)
                    if (normalBlock?.synchronousId) {
                        return findBlockById(normalBlock.synchronousId, syncComponents)
                    }

                    return normalBlock
                },
                [pageId, selectedNode, syncComponents]
            )
        )
        const { onUpdateBlock } = useBlockActions(pageId, stackId)

        const pageDsId = useAtomData(
            pageAtomFamily(pageId),
            useCallback(page => page?.dsId, [])
        )

        const pointer = useMemo(() => {
            if (!selectedBlock) {
                return ''
            }
            switch (selectedBlock.type) {
                case 'view': {
                    return selectedBlock.config.pointer || ''
                }
                case 'chart': {
                    if (selectedBlock.config.chartType === ChartType.indicator) {
                        return ''
                    }
                    return selectedBlock.config.pointer
                }
                case 'fieldGroup':
                case 'field': {
                    // if (selectModule.type === 'formModule') {
                    //     return selectModule?.config?.form.pointer
                    // }
                    return pageDsId ?? ''
                }
                default: {
                    return parentDsId || ''
                }
            }
        }, [selectedBlock, pageDsId, parentDsId])

        const dataSourceList = useDataSourceList(appId, envId)

        const dataSource = useDataSource(appId, envId, pointer)
        // const allDataSource = useDataSourcePool()

        const allPages = useDefaultPageList()

        // const handleFetchAllViews = useCallback(() => srv.getAllViews(), [])

        const debounceAdd = useMemo(
            () =>
                debounce(500, ({ prevBlock, nextBlock }: BlockUpdateOption) => {
                    pageUndoRedoController.add({
                        action: BLOCK_UPDATE_ACTION,
                        payload: {
                            prev: prevBlock,
                            next: nextBlock
                        }
                    })
                }),
            []
        )

        const blockSettingActionRef = useRef<BlockSettingActionADT | null>(null)

        useEffect(() => {
            function handle(payload: BlockSettingActionADT) {
                blockSettingActionRef.current = payload
            }

            BlockSettingMitt.on('event', handle)

            return () => {
                BlockSettingMitt.off('event', handle)
            }
        }, [])

        const handleCreateContainerView = useAtomCallback(
            (get, set, value: BlockAbstract, payload: MakeADTMember<'action', BlockSettingActionADT, 'createContainerView'>) => {
                if (!selectedBlock) {
                    return
                }

                const isSyncComponent = !!selectedBlock.isMasterSynchronous || !!selectedBlock.isLeafSynchronous

                if (isSyncComponent) {
                    let prevSyncComponents: ContainerBlockAbstract[] | undefined
                    let nextSyncComponents: ContainerBlockAbstract[] | undefined
                    set(syncComponentsAtom, draft => {
                        const draftBlock = findBlockById<ContainerBlockAbstract>(selectedBlock.id, draft)
                        if (!draftBlock) {
                            return
                        }

                        Object.entries(value).forEach(([k, v]) => {
                            Reflect.set(draftBlock, k, v)
                        })

                        draftBlock.children.push({ id: payload.newId, children: [] })

                        prevSyncComponents = original(draft)
                        nextSyncComponents = current(draft)

                        set(pageStackAtom, draftStack => {
                            const stack = equalPageStack({ rootPageId, stackId })(draftStack)
                            if (stack) {
                                initBlockRuntimeState(stack, {
                                    blocks: get(pageBlocksAtom(pageId)),
                                    syncComponents: nextSyncComponents || []
                                })
                            }
                        })
                    })

                    if (!nextSyncComponents) {
                        return
                    }

                    pageUndoRedoController.add({
                        action: NODE_UPDATE_ACTION,
                        payload: {
                            prev: {
                                blocks: undefined,
                                syncComponents: prevSyncComponents
                            },
                            next: {
                                blocks: undefined,
                                syncComponents: nextSyncComponents
                            }
                        }
                    })

                    srv.updateSyncComponents(nextSyncComponents)
                } else {
                    let nextBlock: BlockAbstract | undefined
                    let prevBlocks: BlockAbstract[] | undefined
                    let nextBlocks: BlockAbstract[] | undefined

                    set(blocksAtom, draft => {
                        const draftBlocks = draft[pageId]
                        if (!draftBlocks) {
                            return
                        }

                        const draftBlock = findBlockById<ContainerBlockAbstract>(selectedBlock.id, draftBlocks)
                        if (!draftBlock) {
                            return
                        }

                        Object.entries(value).forEach(([k, v]) => {
                            Reflect.set(draftBlock, k, v)
                        })

                        draftBlock.children.push({ id: payload.newId, children: [] })
                        nextBlock = current(draftBlock)
                        prevBlocks = original(draftBlocks)
                        nextBlocks = current(draftBlocks)
                    })

                    if (!nextBlock || !nextBlocks) {
                        return
                    }

                    pageUndoRedoController.add({
                        action: NODE_UPDATE_ACTION,
                        payload: {
                            prev: {
                                blocks: prevBlocks,
                                syncComponents: undefined
                            },
                            next: {
                                blocks: nextBlocks,
                                syncComponents: undefined
                            }
                        }
                    })

                    srv.updateBlock({ updatedBlocks: [nextBlock], allBlocks: nextBlocks, pageId })
                }
            }
        )

        const handleDeleteContainerView = useAtomCallback(
            async (get, set, value: BlockAbstract, payload: MakeADTMember<'action', BlockSettingActionADT, 'deleteContainerView'>) => {
                if (!selectedBlock) {
                    return
                }
                const isSyncComponent = !!selectedBlock.isMasterSynchronous || !!selectedBlock.isLeafSynchronous
                const previewType = get(previewTypeAtom)
                const stack = get(lastPageOfStackAtom)
                const { blockRuntimeState, state: { selectedNodes = [] } = {} } = stack ?? {}
                const runtimeStateUniqueId = selectedNodes[0].scope
                    ? `${selectedNodes[0].scope}@${selectedNodes[0].id}`
                    : selectedNodes[0].id

                if (isSyncComponent) {
                    let removedSyncComponents: BlockAbstract[] = []
                    let prevSyncComponents: ContainerBlockAbstract[] | undefined
                    let nextSyncComponents: ContainerBlockAbstract[] | undefined
                    set(syncComponentsAtom, draft => {
                        const draftBlock = findBlockById<ContainerBlockAbstract>(selectedBlock.id, draft)
                        if (!draftBlock) {
                            return
                        }

                        const deleteId = draftBlock.config.viewList[payload.index].id

                        Object.entries(value).forEach(([k, v]) => {
                            Reflect.set(draftBlock, k, v)
                        })

                        // 如果当前删除面板是当前选中的面板则重新选中第一个面板
                        if (deleteId === blockRuntimeState?.container?.[runtimeStateUniqueId]?.currentView) {
                            const firstView = draftBlock.config.viewList.find(item => item.id !== deleteId)
                            if (firstView) {
                                if (previewType === ApplicationPreviewEnum.desktop) {
                                    if (draftBlock.config.breakPoint.panel?.defaultPanel === deleteId) {
                                        draftBlock.config.breakPoint.panel.defaultPanel = firstView.id
                                    }
                                } else {
                                    const otherBreakPoint = draftBlock.config.breakPoints.find(item => item.id === previewType)
                                    if (otherBreakPoint && otherBreakPoint.panel?.defaultPanel === deleteId) {
                                        otherBreakPoint.panel.defaultPanel = firstView.id
                                    }
                                }
                            }
                        }

                        const childrenIndex = draftBlock.children.findIndex(item => item.id === deleteId)
                        const removed = draftBlock.children.splice(childrenIndex, 1)
                        removedSyncComponents = removed.reduce<BlockAbstract[]>((total, current) => [...total, ...current.children], [])

                        prevSyncComponents = original(draft)
                        nextSyncComponents = current(draft)

                        set(pageStackAtom, draftStack => {
                            const stack = equalPageStack({ rootPageId, stackId })(draftStack)
                            if (stack) {
                                initBlockRuntimeState(stack, {
                                    blocks: get(pageBlocksAtom(pageId)),
                                    syncComponents: nextSyncComponents || []
                                })
                            }
                        })
                    })

                    if (!nextSyncComponents) {
                        return
                    }

                    pageUndoRedoController.add({
                        action: NODE_UPDATE_ACTION,
                        payload: {
                            removed: {
                                removedSyncComponents,
                                prevSyncComponents,
                                nextSyncComponents
                            }
                        }
                    })

                    srv.updateSyncComponents(nextSyncComponents)
                } else {
                    let prevBlockWhenUpdateConfig: BlockAbstract | undefined
                    let nextBlockWhenUpdateConfig: BlockAbstract | undefined
                    let removedBlocks: BlockAbstract[] = []
                    let prevBlocks: BlockAbstract[] | undefined
                    let nextBlocks: BlockAbstract[] | undefined

                    set(blocksAtom, draft => {
                        const draftBlocks = draft[pageId]
                        if (!draftBlocks) {
                            return
                        }

                        const draftBlock = findBlockById<ContainerBlockAbstract>(selectedBlock.id, draftBlocks)
                        if (!draftBlock) {
                            return
                        }

                        const deleteId = draftBlock.config.viewList[payload.index].id

                        Object.entries(value).forEach(([k, v]) => {
                            Reflect.set(draftBlock, k, v)
                        })

                        // 如果当前删除面板是当前选中的面板则重新选中第一个面板
                        if (deleteId === blockRuntimeState?.container?.[runtimeStateUniqueId]?.currentView) {
                            const firstView = draftBlock.config.viewList.find(item => item.id !== deleteId)
                            if (firstView) {
                                if (previewType === ApplicationPreviewEnum.desktop) {
                                    if (draftBlock.config.breakPoint.panel?.defaultPanel === deleteId) {
                                        draftBlock.config.breakPoint.panel.defaultPanel = firstView.id
                                    }
                                } else {
                                    const otherBreakPoint = draftBlock.config.breakPoints.find(item => item.id === previewType)
                                    if (otherBreakPoint && otherBreakPoint.panel?.defaultPanel === deleteId) {
                                        otherBreakPoint.panel.defaultPanel = firstView.id
                                    }
                                }

                                set(pageStackAtom, draft => {
                                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                                    if (stack) {
                                        stack.blockRuntimeState.container = {
                                            ...stack.blockRuntimeState.container,
                                            [runtimeStateUniqueId]: {
                                                ...stack.blockRuntimeState.container?.[runtimeStateUniqueId],
                                                currentView: firstView.id
                                            }
                                        }
                                    }
                                })
                            }
                        }

                        prevBlockWhenUpdateConfig = original(draftBlock)
                        nextBlockWhenUpdateConfig = current(draftBlock)

                        const childrenIndex = draftBlock.children.findIndex(item => item.id === deleteId)
                        const removed = draftBlock.children.splice(childrenIndex, 1)
                        removedBlocks = removed.reduce<BlockAbstract[]>((total, current) => [...total, ...current.children], [])

                        prevBlocks = original(draftBlocks)
                        nextBlocks = current(draftBlocks)
                    })

                    if (!prevBlocks || !nextBlocks || !prevBlockWhenUpdateConfig || !nextBlockWhenUpdateConfig) {
                        return
                    }

                    pageUndoRedoController.add([
                        {
                            action: NODE_UPDATE_ACTION,
                            payload: {
                                removed: {
                                    removedBlocks,
                                    prevBlocks,
                                    nextBlocks
                                }
                            }
                        },
                        {
                            action: BLOCK_UPDATE_ACTION,
                            payload: {
                                prev: prevBlockWhenUpdateConfig,
                                next: nextBlockWhenUpdateConfig,
                                blocks: {
                                    prev: prevBlocks,
                                    next: nextBlocks
                                }
                            }
                        }
                    ])

                    // 先删除block，再更新block面板配置
                    await srv.deleteBlock({ removedBlocks, blocks: nextBlocks, pageId })

                    srv.updateBlock({ pageId, updatedBlocks: [nextBlockWhenUpdateConfig], allBlocks: nextBlocks })
                }
            }
        )

        const handleCloneContainerView = useAtomCallback(
            async (get, set, value: BlockAbstract, payload: MakeADTMember<'action', BlockSettingActionADT, 'cloneContainerView'>) => {
                if (!selectedBlock) {
                    return
                }

                const isSyncComponent = !!selectedBlock.isMasterSynchronous || !!selectedBlock.isLeafSynchronous

                if (isSyncComponent) {
                    let prevSyncComponentWhenUpdateConfig: BlockAbstract | undefined
                    let nextSyncComponentWhenUpdateConfig: BlockAbstract | undefined
                    let prevSyncComponentsWhenUpdateConfig: ContainerBlockAbstract[] | undefined
                    let nextSyncComponentsWhenUpdateConfig: ContainerBlockAbstract[] | undefined

                    let createdSyncComponents: BlockAbstract[] = []
                    let prevSyncComponents: ContainerBlockAbstract[] | undefined
                    let nextSyncComponents: ContainerBlockAbstract[] | undefined
                    set(syncComponentsAtom, draft => {
                        const draftBlock = findBlockById<ContainerBlockAbstract>(selectedBlock.id, draft)
                        if (!draftBlock) {
                            return
                        }

                        Object.entries(value).forEach(([k, v]) => {
                            Reflect.set(draftBlock, k, v)
                        })

                        const fromPanelId = draftBlock.config.viewList[payload.fromIndex].id
                        const beCopyPanel = draftBlock.children.find(item => item.id === fromPanelId)
                        if (!beCopyPanel) {
                            return
                        }
                        createdSyncComponents = copyBlock(beCopyPanel.children)
                        set(blockCreatingListAtom, s => [...s, ...getFlatAllBlock(createdSyncComponents).map(item => item.id)])

                        const newPanelWhenUpdateConfig = { id: payload.newId, children: [] }
                        draftBlock.children.splice(payload.fromIndex + 1, 0, newPanelWhenUpdateConfig)
                        prevSyncComponentWhenUpdateConfig = original(draftBlock)
                        nextSyncComponentWhenUpdateConfig = current(draftBlock)
                        prevSyncComponentsWhenUpdateConfig = original(draft)
                        nextSyncComponentsWhenUpdateConfig = current(draft)

                        const newPanel = { id: payload.newId, children: createdSyncComponents }
                        draftBlock.children.splice(payload.fromIndex + 1, 1, newPanel)

                        prevSyncComponents = original(draft)
                        nextSyncComponents = current(draft)

                        set(pageStackAtom, draftStack => {
                            const stack = equalPageStack({ rootPageId, stackId })(draftStack)
                            if (stack) {
                                initBlockRuntimeState(stack, {
                                    blocks: get(pageBlocksAtom(pageId)),
                                    syncComponents: nextSyncComponents || []
                                })
                            }
                        })
                    })

                    if (
                        !nextSyncComponents ||
                        !prevSyncComponentWhenUpdateConfig ||
                        !nextSyncComponentWhenUpdateConfig ||
                        !prevSyncComponentsWhenUpdateConfig ||
                        !nextSyncComponentsWhenUpdateConfig
                    ) {
                        return
                    }

                    pageUndoRedoController.add([
                        {
                            action: BLOCK_UPDATE_ACTION,
                            payload: {
                                prev: prevSyncComponentWhenUpdateConfig,
                                next: nextSyncComponentWhenUpdateConfig,
                                syncComponents: {
                                    prev: prevSyncComponentsWhenUpdateConfig,
                                    next: nextSyncComponentsWhenUpdateConfig
                                }
                            }
                        },
                        {
                            action: NODE_UPDATE_ACTION,
                            payload: {
                                created: {
                                    createdBlocks: createdSyncComponents,
                                    prevSyncComponents,
                                    nextSyncComponents
                                }
                            }
                        }
                    ])

                    await srv.updateSyncComponents(nextSyncComponentsWhenUpdateConfig)

                    await srv.createSyncBlock({
                        createBlocks: createdSyncComponents,
                        blocks: nextSyncComponents
                    })

                    set(blockCreatingListAtom, s => s.filter(id => !getFlatAllBlock(createdSyncComponents).some(item => item.id === id)))
                } else {
                    let prevBlockWhenUpdateConfig: BlockAbstract | undefined
                    let nextBlockWhenUpdateConfig: BlockAbstract | undefined
                    let prevBlocksWhenUpdateConfig: BlockAbstract[] | undefined
                    let nextBlocksWhenUpdateConfig: BlockAbstract[] | undefined

                    let createdBlocks: BlockAbstract[] = []
                    let prevBlocks: BlockAbstract[] | undefined
                    let nextBlocks: BlockAbstract[] | undefined

                    set(blocksAtom, draft => {
                        const draftBlocks = draft[pageId]
                        if (!draftBlocks) {
                            return
                        }

                        const draftBlock = findBlockById<ContainerBlockAbstract>(selectedBlock.id, draftBlocks)
                        if (!draftBlock) {
                            return
                        }

                        Object.entries(value).forEach(([k, v]) => {
                            Reflect.set(draftBlock, k, v)
                        })

                        const fromPanelId = draftBlock.config.viewList[payload.fromIndex].id
                        const beCopyPanel = draftBlock.children.find(item => item.id === fromPanelId)
                        if (!beCopyPanel) {
                            return
                        }
                        createdBlocks = copyBlock(beCopyPanel.children)

                        set(blockCreatingListAtom, s => [...s, ...getFlatAllBlock(createdBlocks).map(item => item.id)])

                        const newPanelWhenUpdateConfig = { id: payload.newId, children: [] }
                        draftBlock.children.splice(payload.fromIndex + 1, 0, newPanelWhenUpdateConfig)
                        prevBlockWhenUpdateConfig = original(draftBlock)
                        nextBlockWhenUpdateConfig = current(draftBlock)
                        prevBlocksWhenUpdateConfig = original(draftBlocks)
                        nextBlocksWhenUpdateConfig = current(draftBlocks)

                        const newPanel = { id: payload.newId, children: createdBlocks }
                        draftBlock.children.splice(payload.fromIndex + 1, 1, newPanel)

                        prevBlocks = original(draftBlocks)
                        nextBlocks = current(draftBlocks)
                    })

                    if (
                        !nextBlocks ||
                        !prevBlockWhenUpdateConfig ||
                        !nextBlockWhenUpdateConfig ||
                        !prevBlocksWhenUpdateConfig ||
                        !nextBlocksWhenUpdateConfig
                    ) {
                        return
                    }

                    pageUndoRedoController.add([
                        {
                            action: BLOCK_UPDATE_ACTION,
                            payload: {
                                prev: prevBlockWhenUpdateConfig,
                                next: nextBlockWhenUpdateConfig,
                                blocks: {
                                    prev: prevBlocksWhenUpdateConfig,
                                    next: nextBlocksWhenUpdateConfig
                                }
                            }
                        },
                        {
                            action: NODE_UPDATE_ACTION,
                            payload: {
                                created: {
                                    createdBlocks,
                                    prevBlocks,
                                    nextBlocks
                                }
                            }
                        }
                    ])

                    // 先更新面板配置，再创建面板下的block
                    await srv.updateBlock({ pageId, updatedBlocks: [nextBlockWhenUpdateConfig], allBlocks: nextBlocksWhenUpdateConfig })

                    await set(createBlockAtom, {
                        rootPageId,
                        pageId,
                        stackId,
                        createdBlocks,
                        blocks: nextBlocks,
                        autoSelect: false
                    })

                    set(blockCreatingListAtom, s => s.filter(id => !getFlatAllBlock(createdBlocks).some(item => item.id === id)))
                }
            }
        )

        const onChange = useAtomCallback<void, [BlockAbstract]>((get, set, value) => {
            if (!selectedBlock) {
                return
            }

            if (value.type === BlockType.container && selectedBlock.type === BlockType.container && blockSettingActionRef.current) {
                makeMatchI('action')(blockSettingActionRef.current)({
                    createContainerView: payload => handleCreateContainerView(value, payload),
                    deleteContainerView: payload => handleDeleteContainerView(value, payload),
                    cloneContainerView: payload => handleCloneContainerView(value, payload)
                })

                blockSettingActionRef.current = null

                return
            }

            if (value.type === BlockType.view && selectedBlock.type === BlockType.view) {
                // 切换视图数据源时初始化配置
                if (value.config.pointer !== selectedBlock.config.pointer) {
                    const newDatasource = dataSourceList.find(item => item.id === value.config.pointer)
                    if (!newDatasource) {
                        return
                    }

                    const { viewOptions, schema } = newDatasource

                    if (value.config.viewType === 'gallery') {
                        Object.assign(value.config, generateGalleryConfig(newDatasource))
                    }

                    if (value.config.viewType === 'calendar') {
                        Object.assign(value.config, generateCalendarConfig(newDatasource))
                    }

                    const viewFieldSettings = getViewColumns({
                        tableProps: viewOptions.tableProps,
                        disabledFieldName: true,
                        schema
                    })
                    Object.assign(value.config, {
                        viewFieldSettings,
                        quickFilter: { mode: 'normal', rules: [] },
                        groupByFieldId: undefined,
                        groupConfig: undefined,
                        linkFilterController: {
                            expression: {
                                where: 'AND',
                                conditions: []
                            }
                        }
                    })
                }

                // 切换视图类型时，清空其他类型视图配置
                if (value.config.viewType !== selectedBlock.config.viewType) {
                    switch (value.config.viewType) {
                        case 'advancedTable':
                        case 'list':
                        case 'table': {
                            Object.assign(value.config, {
                                ...cleanGalleryConfig,
                                ...cleanCalendarConfig,
                                ...cleanKanbanConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        case 'calendar': {
                            Object.assign(value.config, {
                                ...cleanGalleryConfig,
                                ...cleanKanbanConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        case 'gallery': {
                            Object.assign(value.config, {
                                ...cleanCalendarConfig,
                                ...cleanKanbanConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        case 'kanban': {
                            Object.assign(value.config, {
                                ...cleanCalendarConfig,
                                ...cleanGalleryConfig
                            } satisfies Partial<ViewOptions>)
                            break
                        }

                        default: {
                            break
                        }
                    }
                }
            }

            if (value.type === BlockType.chart && selectedBlock.type === BlockType.chart) {
                if (chartTypeLevel[value.config.chartType] !== chartTypeLevel[selectedBlock.config.chartType]) {
                    switch (value.config.chartType) {
                        case ChartType.bar:
                        case ChartType.line:
                        case ChartType.composite: {
                            Object.assign(value.config, cleanBarConfig)
                            break
                        }

                        case ChartType.striation: {
                            Object.assign(value.config, cleanStriationConfig)
                            break
                        }

                        case ChartType.pie:
                        case ChartType.funnel: {
                            Object.assign(value.config, cleanPieConfig)
                            break
                        }

                        case ChartType.indicator: {
                            Object.assign(value.config, cleanIndicatorConfig)
                            break
                        }

                        default: {
                            break
                        }
                    }
                    // Object.assign(value, getInitChartBlockConfigurator(value, newDatasource)
                    // )
                }

                const { config } = value
                const { config: selectConfig } = selectedBlock
                if (config.pointer !== selectConfig.pointer) {
                    const newDatasource = dataSourceList.find(item => item.id === config.pointer)
                    if (newDatasource) {
                        Object.assign(value, getInitChartBlockConfigurator(value, newDatasource))
                    }
                }
            }

            onUpdateBlock({ prevBlock: selectedBlock, nextBlock: value })
            debounceAdd({ prevBlock: selectedBlock, nextBlock: value })
        })

        return selectedBlock && selectedNode ? (
            <BlockSettings
                key={selectedBlock.id}
                ref={ref}
                appId={appId}
                loading={loading}
                value={selectedBlock}
                node={selectedNode}
                onChange={onChange}
                dataSource={dataSource}
                dataSourceList={dataSourceList}
                allDataSources={dataSourceList}
                allPages={allPages ?? []}
                // allViews={allViews}
                pointer={pointer}
            />
        ) : null
    }
)

export default BlockSettingsController
