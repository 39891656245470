import { findNormalOrSyncBlock, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { createPortal } from 'react-dom'

import { dataDrawerStateAtom, syncComponentsAtom } from '@/atoms/application/state'
import { blocksAtom, lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { usePageCurrentDsAndRecord } from '@/hooks/usePageCurrentDsAndRecord'

import { PageDataDrawer } from './PageDataDrawer'
import { PageFooterToolBar } from './PageFooterToolBar'

export const PageFooter: React.FC = () => {
    const visible = useAtomData(dataDrawerStateAtom)

    const { pageId, curr, prev, isRecordPage } = usePageCurrentDsAndRecord()
    const page = useAtomData(pageAtomFamily(pageId))

    const syncComponents = useAtomData(syncComponentsAtom)

    const selectedNode = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => (s?.state.selectedNodes && s.state.selectedNodes.length === 1 ? s.state.selectedNodes[0] : undefined), [])
    )
    const selectedBlock = useAtomData(
        blocksAtom,
        useCallback(
            s => {
                if (!selectedNode) {
                    return
                }

                const blocks = s[pageId] || []

                return findNormalOrSyncBlock(selectedNode, blocks, syncComponents)
            },
            [pageId, selectedNode, syncComponents]
        )
    )

    const pageDsId = useMemo(() => {
        return isRecordPage ? curr.datasource?.id : page?.dsId
    }, [curr.datasource?.id, isRecordPage, page?.dsId])

    return (
        <>
            {visible &&
                createPortal(
                    <PageDataDrawer
                        blockData={selectedBlock}
                        pageId={pageId}
                        pageDsId={pageDsId}
                        currentPageData={curr}
                        prevPageData={prev}
                        // pageRecordId={curr.recordId}
                        // pageDataSourceId={curr.datasource?.id}
                        // parentRecordId={prev.recordId}
                        // parentDataSourceId={prev.datasource?.id}
                        isRecordPage={isRecordPage}
                    />,
                    document.body
                )}
            <PageFooterToolBar />
        </>
    )
}
