import { Button, IconFont } from '@byecode/ui'
import { type CollectEvent, type EVENT_VARIABLE_TYPE, CollectEventWriteType } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable, PrevPageDatasourceForVariable } from '@lighthouse/shared'
import { generateName, nanoid } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import { reduce } from 'rambda'
import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { CollectionEventItem } from './CollectionEventItem'

export interface CollectionEventListProps {
    value?: CollectEvent[]
    eventTypes?: EVENT_VARIABLE_TYPE[]
    curr?: CurrPageDatasourceForVariable
    prev?: PrevPageDatasourceForVariable
    onChange?: (val: CollectEvent[]) => void
}

const SCxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`

export const CollectionEventList: React.FC<CollectionEventListProps> = ({ value, eventTypes, curr, prev, onChange }) => {
    const [list, setList] = useImmer(value || [])
    const disabledWithVersion = useIsDisabledWithVersion()

    const [openedMap, setOpenMap] = useImmer(
        reduce<CollectEvent, Record<string, boolean>>(
            (preVal, { id }) => {
                preVal[id] = true
                return preVal
            },
            {},
            list
        )
    )

    const getInitEvent = useCallback(() => {
        return {
            id: nanoid(),
            title: '未命名采集器',
            enable: false,
            triggerType: eventTypes?.[0],
            writeType: CollectEventWriteType.RECORD
        }
    }, [eventTypes])

    const handleAdd = useCallback(() => {
        const newItem = getInitEvent()
        const newList = [...list]
        newList.push(newItem)
        setList(newList)
        onChange?.(newList)
        setOpenMap(draft => {
            draft[newItem.id] = true
        })
    }, [getInitEvent, list, onChange, setList, setOpenMap])

    const handleDelete = useCallback(
        (id: string) => {
            const newList = list.filter(item => item.id !== id)
            setList(newList)
            onChange?.(newList)
        },
        [list, onChange, setList]
    )

    const handleDuplicate = useCallback(
        (index: number) => {
            const field = list[index]
            if (!field) {
                return
            }
            const newList = [...list]
            const names = list.map(f => f.title)
            const newId = nanoid()
            const newField = {
                ...field,
                id: newId,
                title: generateName(names, field.title)
            }
            newList.splice(index + 1, 0, newField)
            setList(newList)
            onChange?.(newList)
            setOpenMap(draft => {
                draft[newField.id] = true
            })
        },
        [list, onChange, setList, setOpenMap]
    )

    const handleItemOnChange = useCallback(
        (val: CollectEvent) => {
            const newList = list.map(item => {
                if (item.id === val.id) {
                    return val
                }
                return item
            })
            setList(newList)
            onChange?.(newList)
        },
        [list, onChange, setList]
    )

    return (
        <SCxContainer>
            {list.map((item, index) => (
                <CollectionEventItem
                    key={item.id}
                    opened={openedMap[item.id]}
                    disabled={disabledWithVersion}
                    eventTypes={eventTypes}
                    value={item}
                    curr={curr}
                    prev={prev}
                    onChange={handleItemOnChange}
                    onDuplicate={() => handleDuplicate(index)}
                    onDelete={() => {
                        handleDelete(item.id)
                    }}
                    onChangeOpen={opened => {
                        setOpenMap(draft => {
                            draft[item.id] = opened
                        })
                    }}
                />
            ))}
            <Button
                style={{ justifyContent: 'flex-start', borderRadius: 100, marginTop: 8 }}
                onClick={handleAdd}
                icon={<IconFont size={16} color="var(--color-gray-500)" type="Add" />}
            >
                <Text>添加</Text>
            </Button>
        </SCxContainer>
    )
}
