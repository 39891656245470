import { Empty, Loading } from '@byecode/ui'
import type { ChartLikeProtocol, SingleChartOptionProtocol } from '@lighthouse/core'
import { areaChartType, ChartRenderer, getChartOption } from '@lighthouse/shared'
import { isEmpty, max, min } from 'rambda'
import React, { useEffect, useMemo, useState } from 'react'

import { ChartTypeIconMap, ChartTypePlaceHolderMap } from '../../constants'
import * as SC from './styles'

interface SingleChartViewContentProps {
    loading?: boolean
    pointer?: string
    title?: string
    width: number
    chartData?: ChartLikeProtocol
    chartOption: SingleChartOptionProtocol
}

export const SingleChartViewContent: React.FC<SingleChartViewContentProps> = ({
    loading,
    title,
    width,
    pointer,
    chartData,
    chartOption
}) => {
    const [height, setHeight] = useState<number | undefined>(undefined)
    // const { ref, breakPoint, width } = useBreakpoint()
    const { dimensions, mainAxis, chartType } = chartOption
    const isChartEmpty = useMemo(() => {
        if (areaChartType.has(chartType)) {
            if (!chartData || !dimensions) {
                return true
            }
            if (dimensions.length === 0) {
                return mainAxis.length < 2
            }
            if (dimensions.length === 1) {
                return isEmpty(mainAxis)
            }
        }
        return isEmpty(dimensions) || isEmpty(mainAxis) || !chartData
    }, [chartData, chartType, dimensions, mainAxis])

    useEffect(() => {
        if (width < 460) {
            return setHeight(max(Number(width), 300))
        }
        return setHeight(min(Math.floor(Number(width) * 0.8), 548))
    }, [width])

    const option = useMemo(() => {
        if (isChartEmpty) {
            return {}
        }
        return getChartOption(chartType, { ...chartOption, title }, chartData)
    }, [chartData, chartOption, chartType, isChartEmpty, title])

    const content = useMemo(() => {
        if (loading) {
            return <Loading />
        }
        if (!pointer || !chartData || isChartEmpty) {
            return (
                <SC.ChildContainer>
                    <Empty icon={ChartTypeIconMap[chartType]} description={ChartTypePlaceHolderMap[chartType]} />
                </SC.ChildContainer>
            )
        }

        return <ChartRenderer chartType={chartType} option={option} />
    }, [chartData, chartType, isChartEmpty, loading, option, pointer])

    return <SC.ChartViewContentWrapper height={height}>{content}</SC.ChartViewContentWrapper>
}
