import { Group } from '@byecode/ui';
import React from 'react';
import styled from 'styled-components';

import type { CollectionEventListProps } from './CollectionEventList';
import { CollectionEventList } from './CollectionEventList';

const SCxScrollContainer = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
`

interface CollectionEventProps extends CollectionEventListProps {
}

export const CollectionEvent: React.FC<CollectionEventProps> = (props) => {
    return (
        <Group label="事件采集">
            <SCxScrollContainer>
                <CollectionEventList {...props} />
            </SCxScrollContainer>
        </Group>
    )
}
