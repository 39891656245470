import { Empty } from '@byecode/ui'
import { DndContext, MeasuringStrategy } from '@dnd-kit/core'
import { arrayMove, horizontalListSortingStrategy, SortableContext } from '@dnd-kit/sortable'
import { getAssetUrl } from '@lighthouse/assets'
import type {
    AiFieldStatus,
    ButtonAction,
    DataSourceAbstract,
    FieldADTValue,
    KanbanColumnsSort,
    KanbanViewConfig,
    RecordLikeProtocol,
    RichTextContentProtocol,
    TableColumns,
    ViewBlockAbstract,
    ViewField
} from '@lighthouse/core'
import {
    ApplicationPreviewEnum,
    EMPTY_COLUMN_GROUP,
    EmptyNoRecordSvg,
    fieldConvertValue,
    getGroupAllVisibleOptions,
    getGroupVisibleWithLabelOptions,
    getPrimaryDataSourceEnableFieldIds,
    getViewColumns,
    mergeGroupVisibleOptions,
    useApplicationContext,
    useLanguageContext
} from '@lighthouse/shared'
import { BreakPointSize } from '@lighthouse/tools'
import type { atomWithImmer } from 'jotai-immer'
import { find } from 'rambda'
import type { FC } from 'react'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useImmer } from 'use-immer'

import { DragOverlay } from './DragOverlay'
import { DroppableContainer } from './DroppableContainer'
import { useDndSortable, useGroupRecordByField } from './hooks'
import * as SC from './styles'

interface Props {
    blockData: ViewBlockAbstract
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    records?: RecordLikeProtocol[]
    previewType: ApplicationPreviewEnum
    tablePropsCache?: TableColumns
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    blockWidth?: number
    cachedKanbanSort?: KanbanColumnsSort
    onChangeDisplay?: (data?: TableColumns) => void
    onCellChange?: (recordId: string, fieldValue: FieldADTValue) => void
    onRecordClick?: (id: string) => void
    onRecordAdd?: (initialValue?: Record<string, string>) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onRecordEdit: (recordId: string) => void
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
    onChangeCachedKanbanSort: (val: KanbanColumnsSort | undefined) => void
}

export const KanbanBoardBlock: FC<Props> = ({
    blockData,
    dataSource,
    dataSourceList,
    records,
    previewType,
    tablePropsCache,
    aiFieldStatusListAtom,
    blockWidth,
    cachedKanbanSort,
    onCellChange,
    onRecordClick,
    onRecordAdd,
    onRecordDelete,
    onRecordEdit,
    onAiGeneration,
    onRecordOperatorActionTrigger,
    onRecordClickedActionTrigger,
    onRenderButtonTitle,
    onChangeCachedKanbanSort
}) => {
    const { personOptions } = useApplicationContext()
    const { config, id } = blockData
    const {
        canViewRecord,
        viewingConfig,
        canSetKanban,
        canSortKanban,
        canCreateRecord,
        canDeleteRecord = false,
        canDisplay,
        kanbanGroupConfigure = { groupByFieldId: '', groupConfig: [] },
        creatingConfig,
        pointer,
        viewFieldSettings,
        canViewEdit = false,
        actions
    } = config as KanbanViewConfig
    const isEmptyImg = blockWidth && blockWidth >= 200
    const { groupByFieldId: kanbanGroupByField, groupConfig: kanbanGroupConfig } = kanbanGroupConfigure
    const {
        schema,
        viewOptions: { tableProps }
    } = dataSource
    const noData = !pointer
    const creatable = !!canCreateRecord && !!creatingConfig?.page
    const clickable = !!canViewRecord && !!viewingConfig?.page
    const { convertTextByLanguage } = useLanguageContext()
    // block 设置后的tableProps
    const settingColumns = useMemo(
        () =>
            getViewColumns({
                blockId: id,
                tableProps,
                value: viewFieldSettings,
                schema
            }),
        [id, schema, tableProps, viewFieldSettings]
    )

    // 用户字段设置后的tableProps
    const columns = useMemo(() => {
        const filterColumns = settingColumns.filter(item => item.visible)
        if (!tablePropsCache || tablePropsCache.length === 0 || !canDisplay) {
            return filterColumns
        }
        const columnsCache = tablePropsCache.reduce<ViewField[]>((prev, cur) => {
            const field = find(({ fieldId }) => fieldId === cur.id, filterColumns)
            if (field) {
                prev.push({
                    ...field,
                    visible: cur.visible
                })
            }
            return prev
        }, [])
        return filterColumns.reduce<ViewField[]>((prev, cur) => {
            if (!cur.visible) {
                return prev
            }
            const field = find(({ fieldId }) => fieldId === cur.fieldId, prev)
            if (!field) {
                prev.push({
                    ...cur,
                    visible: false
                })
            }
            return prev
        }, columnsCache)
    }, [settingColumns, tablePropsCache, canDisplay])

    const groupedFieldSchema = useMemo(() => (kanbanGroupByField ? schema[kanbanGroupByField] : undefined), [kanbanGroupByField, schema])

    // 合并校验后的选项配置
    const mergedSortRule = useMemo(() => {
        if (!kanbanGroupByField) {
            return
        }

        const currentViewCache = cachedKanbanSort?.[kanbanGroupByField] ?? []

        if (!kanbanGroupConfig && (!currentViewCache || currentViewCache.length === 0)) {
            return
        }

        const field = schema[kanbanGroupByField]
        if (!field) {
            return
        }
        const completeCurrentViewCache = getGroupVisibleWithLabelOptions(field, currentViewCache, { personOptions })
        if (!kanbanGroupConfig) {
            return completeCurrentViewCache
        }

        const completeKanbanVisibleConfig = mergeGroupVisibleOptions(
            getGroupVisibleWithLabelOptions(field, kanbanGroupConfig, { personOptions }),
            getGroupAllVisibleOptions(field, { personOptions })
        )

        if (completeCurrentViewCache.length === 0) {
            return completeKanbanVisibleConfig
        }

        return mergeGroupVisibleOptions(completeCurrentViewCache, completeKanbanVisibleConfig)
    }, [kanbanGroupByField, cachedKanbanSort, kanbanGroupConfig, schema, personOptions])

    const handleColumnSortChange = useCallback(
        (before: string, after: string) => {
            if (!mergedSortRule || !kanbanGroupByField) {
                return
            }

            const beforeIndex = mergedSortRule.findIndex(item => item.value === before)
            const afterIndex = mergedSortRule.findIndex(item => item.value === after)

            onChangeCachedKanbanSort({
                [kanbanGroupByField]: arrayMove(mergedSortRule, beforeIndex, afterIndex).map(item => ({
                    value: item.value,
                    visible: item.visible
                }))
            })
        },
        [kanbanGroupByField, mergedSortRule, onChangeCachedKanbanSort]
    )

    // 根据分组字段进行分组
    const groupList = useGroupRecordByField(kanbanGroupByField, schema, {
        records: records || [],
        visibleConfig: mergedSortRule,
        personOptions
    })

    // 拖拽数据及交互逻辑
    const { draggingData, activeId, items, containers, sensors, collisionDetection, onDragStart, onDragCancel, onDragEnd, onDragOver } =
        useDndSortable(
            groupList,
            groupedFieldSchema,
            useMemo(() => {
                if (!mergedSortRule) {
                    return undefined
                }
                if (mergedSortRule.length === 0) {
                    return undefined
                }
                return mergedSortRule.filter(item => item.visible).map(item => item.value)
            }, [mergedSortRule]),
            onCellChange,
            handleColumnSortChange
        )

    const groupedFieldValue = useMemo(() => [activeId], [activeId])

    // ##################################################################### //
    // ########################### 看板列分页状态 ########################### //
    // ##################################################################### //
    const [colPaging, setColPaging] = useImmer({ page: 1, pageSize: 10 })

    const pagingContainers = useMemo(() => {
        return containers.slice(0, colPaging.page * colPaging.pageSize)
    }, [colPaging.page, colPaging.pageSize, containers])

    const nextColPagingLoadNum = Math.min(10, containers.length - pagingContainers.length)

    const handlePagingNext = useCallback(() => {
        setColPaging(draft => {
            draft.page++
        })
    }, [setColPaging])

    // ##################################################################### //
    // ########################### 看板列显示状态 ########################### //
    // ##################################################################### //
    const [collapsedContainers, setCollapsedContainers] = useImmer<Record<string, boolean>>({})

    const handleCollapse = useCallback(
        (id: string) => {
            setCollapsedContainers(draft => void (draft[id] = !draft[id]))
        },
        [setCollapsedContainers]
    )

    const handleHidden = useCallback(
        (v: string) => {
            if (!kanbanGroupByField) {
                onChangeCachedKanbanSort(undefined)
                return
            }
            const cacheViewConfig = cachedKanbanSort?.[kanbanGroupByField]
            if (cacheViewConfig) {
                const newCacheViewConfig = cacheViewConfig.map(item => {
                    if (item.value === v) {
                        return {
                            ...item,
                            visible: false
                        }
                    }
                    return item
                })
                onChangeCachedKanbanSort({
                    [kanbanGroupByField]: newCacheViewConfig
                })
                return
            }
            if (mergedSortRule) {
                const initCachedKanbanSort = {
                    [kanbanGroupByField]: mergedSortRule.map(item => {
                        if (item.value === v) {
                            return { value: item.value, visible: false }
                        }
                        return { value: item.value, visible: item.visible }
                    })
                }
                onChangeCachedKanbanSort(initCachedKanbanSort)
            }
        },
        [cachedKanbanSort, kanbanGroupByField, mergedSortRule, onChangeCachedKanbanSort]
    )

    const [selectedRecords, setSelectedRecords] = useState<string[]>([])

    useEffect(() => {
        if (!canDeleteRecord) {
            setSelectedRecords([])
        }
    }, [canDeleteRecord])

    const handleRecordOperatorDelete = useCallback(
        async (dsId: string, ids: string[]) => {
            const isDelete = await onRecordDelete?.(dsId, ids)
            const newSelectedRecords = selectedRecords.reduce<string[]>((prev, cur) => {
                if (!ids.includes(cur)) {
                    prev.push(cur)
                }
                return prev
            }, [])
            setSelectedRecords(newSelectedRecords)
            return !!isDelete
        },
        [onRecordDelete, selectedRecords]
    )

    const handleSelectRecord = useCallback(
        (checked: boolean, id: string) => {
            if (!selectedRecords) {
                return
            }
            if (checked) {
                setSelectedRecords?.([...selectedRecords, id])
            } else {
                setSelectedRecords?.(selectedRecords.filter(rId => rId !== id))
            }
        },
        [selectedRecords]
    )

    const isMobile = useMemo(() => previewType === ApplicationPreviewEnum.mobile, [previewType])

    const primaryDataSourceFieldIds = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList)
    }, [dataSource, dataSourceList])

    return useMemo(() => {
        if (noData) {
            return (
                <Empty
                    styles={{ root: { padding: '40px 0' } }}
                    icon={isEmptyImg ? <EmptyNoRecordSvg color="var(--color-app-main)" /> : undefined}
                    description={convertTextByLanguage('noData')}
                />
            )
        }

        if (!kanbanGroupByField || !schema?.[kanbanGroupByField] || !primaryDataSourceFieldIds?.has(kanbanGroupByField)) {
            return (
                <Empty
                    styles={{ root: { padding: '40px 0' } }}
                    icon={<img style={{ width: 140, height: 144 }} src={getAssetUrl('empty', 'no_kanban_group.svg')} alt="没有分组依据" />}
                    description={convertTextByLanguage('pleasesSetKanbanGrouping')}
                />
            )
        }

        return (
            <SC.KanbanBoardRoot>
                <DndContext
                    sensors={sensors}
                    // collisionDetection={collisionDetection}
                    measuring={{ droppable: { strategy: MeasuringStrategy.Always } }}
                    onDragStart={onDragStart}
                    // onDragOver={onDragOver}
                    onDragEnd={onDragEnd}
                    onDragCancel={onDragCancel}
                >
                    <SortableContext
                        disabled={!canSortKanban || isMobile}
                        items={pagingContainers}
                        strategy={horizontalListSortingStrategy}
                    >
                        {pagingContainers.map(containerId => {
                            return (
                                <DroppableContainer
                                    blockId={id}
                                    key={containerId}
                                    id={containerId}
                                    actions={actions}
                                    disabled={!canSortKanban || isMobile}
                                    clickable={clickable}
                                    creatable={creatable}
                                    selectedList={selectedRecords}
                                    recordEditOpenable={canViewEdit}
                                    recordDeleteAble={canDeleteRecord}
                                    onSelectChange={canDeleteRecord ? handleSelectRecord : undefined}
                                    enableHidden={canSetKanban}
                                    list={items[containerId] ?? []}
                                    schema={schema}
                                    columns={columns}
                                    groupedFieldValue={[containerId]}
                                    fieldSchema={schema[kanbanGroupByField]}
                                    collapsed={collapsedContainers[containerId]}
                                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                                    handleCollapse={() => handleCollapse(containerId)}
                                    handleHidden={() => handleHidden(containerId)}
                                    handleClickAdd={() => {
                                        if (canCreateRecord && creatingConfig?.page) {
                                            onRecordAdd?.(
                                                containerId === EMPTY_COLUMN_GROUP
                                                    ? undefined
                                                    : {
                                                          [kanbanGroupByField]: fieldConvertValue({
                                                              ...groupedFieldSchema,
                                                              value: [containerId]
                                                          } as FieldADTValue)
                                                      }
                                            )
                                        }
                                    }}
                                    onRecordClick={onRecordClick}
                                    onRecordEdit={onRecordEdit}
                                    onRecordDelete={handleRecordOperatorDelete}
                                    onAiGeneration={onAiGeneration}
                                    onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                                    onRenderButtonTitle={onRenderButtonTitle}
                                />
                            )
                        })}

                        {nextColPagingLoadNum !== 0 && (
                            <SC.KanbanBoardLoadMoreBtn onClick={handlePagingNext}>
                                加载更多 {nextColPagingLoadNum}
                            </SC.KanbanBoardLoadMoreBtn>
                        )}
                    </SortableContext>

                    {groupedFieldSchema && (
                        <DragOverlay
                            blockId={id}
                            actions={actions}
                            draggingData={draggingData}
                            groupedFieldValue={groupedFieldValue}
                            schema={schema}
                            groupedFieldSchema={groupedFieldSchema}
                            columns={columns}
                            creatable={creatable}
                            enableHidden={canSetKanban}
                            collapsed={activeId ? collapsedContainers[activeId] : false}
                            recordEditOpenable={canViewEdit}
                            recordDeleteAble={canDeleteRecord}
                            aiFieldStatusListAtom={aiFieldStatusListAtom}
                            onRecordEdit={onRecordEdit}
                            onRecordDelete={onRecordDelete}
                            onRenderButtonTitle={onRenderButtonTitle}
                        />
                    )}
                </DndContext>
            </SC.KanbanBoardRoot>
        )
    }, [
        noData,
        kanbanGroupByField,
        schema,
        primaryDataSourceFieldIds,
        sensors,
        onDragStart,
        onDragEnd,
        onDragCancel,
        canSortKanban,
        isMobile,
        pagingContainers,
        nextColPagingLoadNum,
        handlePagingNext,
        groupedFieldSchema,
        id,
        actions,
        draggingData,
        groupedFieldValue,
        columns,
        creatable,
        canSetKanban,
        activeId,
        collapsedContainers,
        canViewEdit,
        canDeleteRecord,
        aiFieldStatusListAtom,
        onRecordEdit,
        onRecordDelete,
        onRenderButtonTitle,
        isEmptyImg,
        convertTextByLanguage,
        clickable,
        selectedRecords,
        handleSelectRecord,
        items,
        onRecordClick,
        handleRecordOperatorDelete,
        onAiGeneration,
        onRecordOperatorActionTrigger,
        onRecordClickedActionTrigger,
        handleCollapse,
        handleHidden,
        canCreateRecord,
        creatingConfig?.page,
        onRecordAdd
    ])
}
