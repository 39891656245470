import { Button, IconFont, Popover } from '@byecode/ui'
import type { Field, FieldType } from '@lighthouse/core'
import { type DataSourceAbstract, type VariableADTvalue, VariableType } from '@lighthouse/core'
import type { FlowNode, VariableOptions, VariableTree } from '@lighthouse/shared'
import {
    addresseeFieldType,
    getDefaultValueOptions,
    getUserDatasourceOptions,
    USER_DATASOURCE,
    VariableSelectDropDown,
    VariableSourceType,
    VariableTag
} from '@lighthouse/shared'
import { clone, find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { DepartmentTag } from './DepartmentTag'
import { PersonTag } from './PersonTag'
import { RoleTag } from './RoleTag'
import { Node } from './styles'

const SCxContainer = styled.div``

const SCxPersonList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;
`

const SCxButtonContent = styled(Button)``

const SCxIcon = styled(IconFont)`
    margin-right: 6px;
`

const SCxButtonContainer = styled.div`
    margin: 8px 0;
`

interface PersonItemProps {
    disabled?: boolean
    data: VariableADTvalue
    options: VariableOptions
    userOption?: VariableTree
    onClose: () => void
}

export const PersonItem: React.FC<PersonItemProps> = ({ disabled, data, options, userOption, onClose }) => {
    return useMemo(() => {
        if (data.type === VariableType.VALUE) {
            if (data.valueVariable?.type === 'role') {
                return <RoleTag withClose={!disabled} data={data} onClose={onClose} />
            }
            if (data.valueVariable?.type === 'department') {
                return <DepartmentTag withClose={!disabled} data={data} onClose={onClose} />
            }
            return <PersonTag withClose={!disabled} data={data} onClose={onClose} />
        }
        if (data.type === VariableType.UPSTREAM || data.type === VariableType.USER) {
            return <Node><VariableTag value={data} options={options} userDsOption={userOption} withClose={!disabled} onClose={onClose} /></Node>
        }
        return null
    }, [data, disabled, onClose, options, userOption])
}

interface MessageTargetPersonProps {
    value: VariableADTvalue[] | undefined
    parentNodes: FlowNode[] | undefined
    dataSourceList: DataSourceAbstract[]
    onChange: (val: VariableADTvalue[]) => void
}

const fields: Field[] = [
    { id: 'person', dsId: '', type: 'person', innerType: 'ARRAY', name: '成员', person: {} },
    { id: 'role', dsId: '', type: 'role', innerType: 'ARRAY', name: '角色' },
    { id: 'department', dsId: '', type: 'department', innerType: 'ARRAY', name: '部门' }
]

export const MessageTargetPerson: React.FC<MessageTargetPersonProps> = ({ value, parentNodes, dataSourceList, onChange }) => {
    const [open, setOpen] = useState(false)
    const disabledWithVersion = useIsDisabledWithVersion()
    const options = useMemo(
        () =>
            getDefaultValueOptions({
                sources: [
                    {
                        sourceType: VariableSourceType.parentNode,
                        dataSourceList,
                        parentNodes: parentNodes || []
                    }
                ],
                validateFieldType: fieldType => addresseeFieldType.has(fieldType)
            }),
        [dataSourceList, parentNodes]
    )

    const userOption = useMemo(() => {
        const userDataSource = find(item => item.id === USER_DATASOURCE, dataSourceList)
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: field => addresseeFieldType.has(field.type)
        })
    }, [dataSourceList])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    const getSplitValue = useCallback((val: VariableADTvalue) => {
        if (val.type === VariableType.VALUE && val.valueVariable?.type === 'department') {
            const ids = val.valueVariable.value
            return ids.map<VariableADTvalue>(id => ({
                type: VariableType.VALUE,
                valueVariable: {
                    type: 'department',
                    value: [id]
                }
            }))
        }
        return [val]
    }, [])

    const handleChange = useCallback(
        (val: VariableADTvalue) => {
            const originValue = clone(value || [])
            const result = getSplitValue(val)
            const stringValue = originValue?.map(item => JSON.stringify(item)) || []
            result.forEach(v => {
                if (stringValue.includes(JSON.stringify(v))) {
                    return
                }
                originValue.push(v)
                stringValue.push(JSON.stringify(v))
            })
            onChange(originValue)
        },
        [getSplitValue, onChange, value]
    )

    const handleDelete = useCallback(
        (i: number) => {
            if (!value) {
                return
            }
            const newValue = clone(value)
            newValue?.splice(i, 1)
            onChange(newValue)
        },
        [onChange, value]
    )

    return (
        <SCxContainer>
            <SCxPersonList>
                {value?.map((item, index) => (
                    <PersonItem
                        disabled={disabledWithVersion}
                        key={index}
                        data={item}
                        options={options}
                        userOption={userOption}
                        onClose={() => handleDelete(index)}
                    />
                ))}
            </SCxPersonList>
            <Popover position="bottom-start" disabled={disabledWithVersion} width={248} withinPortal opened={open} onChange={setOpen}>
                <Popover.Target>
                    <SCxButtonContainer>
                        <SCxButtonContent
                            disabled={disabledWithVersion}
                            radius={100}
                            icon={<SCxIcon type="Add" fill="var(--color-gray-500)" size={16} />}
                        >
                            添加
                        </SCxButtonContent>
                    </SCxButtonContainer>
                </Popover.Target>
                <Popover.Dropdown>
                    <VariableSelectDropDown
                        fields={fields}
                        options={options}
                        userOption={userOption}
                        onClose={handleClose}
                        onChange={handleChange}
                    />
                </Popover.Dropdown>
            </Popover>
        </SCxContainer>
    )
}
