import type {
    AppUser,
    ButtonAction,
    DataSourceAbstract,
    FilterCommonCondition,
    GroupConfigure,
    GroupTab,
    KanbanColumnsSort,
    QuickFilterData,
    RichTextContentProtocol,
    Sorter,
    TableColumns,
    ViewBlockAbstract,
    ViewField,
    ViewIndependentData,
    ViewType
} from '@lighthouse/core'
import { CUSTOM_VIEW_LAYOUT, DIRECTION } from '@lighthouse/core'
import type { BreakPointSize } from '@lighthouse/tools'
import { find } from 'rambda'
import React, { useMemo } from 'react'

import type { ApplicationPreviewEnum, ViewAppendParams } from '../../types'
import { getViewColumns } from '../../utils'
import { getGroupAllVisibleOptions, getGroupVisibleWithLabelOptions, mergeGroupVisibleOptions } from '../DataGroupConfigure'
import { PaginationFc } from '../Pagination'
import { TableTab } from '../TableTab'
import { ViewSettingToolbar } from './ViewSettingToolbar'

export interface ViewBlockToolProps {
    children?: React.ReactNode
    appId: string
    envId?: string
    scope?: string
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    tabList?: GroupTab[]
    tabValue?: string
    blockData: ViewBlockAbstract
    quickFilterData?: QuickFilterData
    viewIndependentDataOmitRecord: Omit<ViewIndependentData, 'recordIds'>
    tablePropsCache: TableColumns
    sortCache?: Sorter[]
    groupCache?: GroupConfigure
    personOptions?: AppUser[]
    cachedKanbanSort?: KanbanColumnsSort
    previewType: ApplicationPreviewEnum
    onTabChange?: (val: string) => void
    onRecordAdd?: () => void
    onSearch?: (val: string) => void
    // onSelectModeChange?: (mode?: SelectedMode) => void
    onFilter?: (data: FilterCommonCondition[]) => void
    // onRecordDelete?: (dsId: string, ids: string[], selectedMode?: SelectedMode) => void
    onChangeSorter?: (data?: Sorter[]) => void
    onChangeDisplay: (data?: TableColumns) => void
    onChangeGroup?: (data: GroupConfigure) => void
    onAppend?: (params: ViewAppendParams) => Promise<boolean>
    // onExport?: (params: ViewExportParams) => void
    // onPrint?: (params: ViewPrintWithTemplateParams) => void
    onToolbarActionTrigger?: (params: ButtonAction) => Promise<boolean | undefined>
    onChangePageNum?: (pageNum: number) => void
    onRenderButtonTitle: (v: RichTextContentProtocol) => string
    // onKanbanGroupConfigChange?: (v: GroupConfigure) => void
    onChangeCachedKanbanSort: (val: KanbanColumnsSort | undefined) => void
}

const enableSortView: Set<ViewType> = new Set(['table', 'advancedTable', 'list', 'gallery', 'kanban', 'custom'])
const enableDisplayView: Set<ViewType> = new Set(['table', 'advancedTable', 'list', 'kanban'])
const enablePagination: Set<ViewType> = new Set(['table', 'list', 'gallery', 'advancedTable', 'custom'])

export const ViewBlockTool: React.FC<ViewBlockToolProps> = ({
    children,
    appId,
    envId = '',
    scope,
    dataSource,
    dataSourceList,
    tabList,
    tabValue,
    blockData,
    quickFilterData,
    viewIndependentDataOmitRecord,
    // selectedRecords,
    // exportTemplateList,
    // selectedMode,
    tablePropsCache,
    sortCache,
    groupCache,
    personOptions,
    cachedKanbanSort,
    previewType,
    onTabChange,
    onRecordAdd,
    onSearch,
    // onSelectModeChange,
    onFilter,
    // onRecordDelete,
    onChangeSorter,
    onChangeDisplay,
    onChangeGroup,
    onAppend,
    // onExport,
    // onPrint,
    onToolbarActionTrigger,
    onChangePageNum,
    onRenderButtonTitle,
    onChangeCachedKanbanSort
}) => {
    const { config, title, id: blockId } = blockData

    const { schema, viewOptions } = dataSource
    const { tableProps } = viewOptions

    const {
        viewType,
        pointer,
        canSearch,
        canGroup,
        canSort,
        canDisplay,
        showTitle,
        canCreateRecord,
        canDeleteRecord,
        actions,
        pagination,
        viewFieldSettings,
        userImportSetting,
        userSortSetting,
        creatingConfig,
        canImport,
        canPaginate,
        canSetKanban,
        kanbanGroupConfigure = { groupByFieldId: '', groupConfig: [] },
        breakPoint: baseBreakPoint
    } = config
    const { viewLayout } = baseBreakPoint
    const { layout = CUSTOM_VIEW_LAYOUT.waterfall, direction = DIRECTION.vertical } = viewLayout || {}
    const { groupByFieldId: kanbanGroupByField, groupConfig: kanbanGroupConfig } = kanbanGroupConfigure
    const enableSort = useMemo(() => enableSortView.has(viewType) && canSort, [canSort, viewType])

    // 看板视图列拖拽排序缓存
    // const [cachedKanbanSort, setCachedKanbanSort] = useLocalStorage<Record<string, Record<string, { value: string; visible: boolean }[]>>>({
    //     key: `kanban-columns-sort`,
    //     defaultValue: {}
    // })
    const enableDisplay = useMemo(() => enableDisplayView.has(viewType) && canDisplay, [canDisplay, viewType])
    const isShowViewSettingToolbar = useMemo(
        () =>
            canImport ||
            canSearch ||
            enableDisplay ||
            canGroup ||
            enableSort ||
            showTitle ||
            canCreateRecord ||
            // canDeleteRecord ||
            (quickFilterData && quickFilterData?.rules?.length > 0) ||
            actions?.toolbar?.customized,
        [
            actions?.toolbar?.customized,
            canCreateRecord,
            canGroup,
            canImport,
            canSearch,
            enableDisplay,
            enableSort,
            quickFilterData,
            showTitle
        ]
    )

    const isCustomViewHorizontal = viewType === 'custom' && layout === CUSTOM_VIEW_LAYOUT.grid && direction === DIRECTION.horizontal
    const isGalleryViewHorizontal = viewType === 'gallery' && direction === DIRECTION.horizontal
    const isHasPagination = useMemo(
        () => enablePagination.has(viewType) && !isCustomViewHorizontal && !isGalleryViewHorizontal,
        [isCustomViewHorizontal, isGalleryViewHorizontal, viewType]
    )

    const settingColumns = useMemo(
        () =>
            getViewColumns({
                blockId,
                tableProps,
                value: viewFieldSettings,
                schema
            }),
        [blockId, schema, tableProps, viewFieldSettings]
    )

    // 用户字段设置后的tableProps
    const columns = useMemo(() => {
        if (!tablePropsCache || tablePropsCache.length === 0 || !canDisplay) {
            return settingColumns.filter(item => {
                return item.visible
            })
        }

        const columnsCache = tablePropsCache.reduce<ViewField[]>((prev, cur) => {
            const field = find(({ fieldId }) => fieldId === cur.id, settingColumns)
            if (field) {
                prev.push({
                    ...field,
                    visible: cur.visible
                })
            }
            return prev
        }, [])
        return settingColumns.reduce<ViewField[]>((prev, cur) => {
            if (!cur.visible) {
                return prev
            }
            const field = find(({ fieldId }) => fieldId === cur.fieldId, prev)
            if (!field) {
                prev.push({
                    ...cur,
                    visible: false
                })
            }
            return prev
        }, columnsCache)
    }, [tablePropsCache, canDisplay, settingColumns])

    // 合并校验后的选项配置
    const mergedSortRule = useMemo(() => {
        if (!kanbanGroupByField) {
            return
        }

        const currentViewCache = cachedKanbanSort?.[kanbanGroupByField] ?? []

        if (!kanbanGroupConfig && (!currentViewCache || currentViewCache.length === 0)) {
            return
        }

        const field = schema[kanbanGroupByField]
        if (!field) {
            return
        }
        const completeCurrentViewCache = getGroupVisibleWithLabelOptions(field, currentViewCache, { personOptions })
        if (!kanbanGroupConfig) {
            return completeCurrentViewCache
        }

        const completeKanbanVisibleConfig = mergeGroupVisibleOptions(
            getGroupVisibleWithLabelOptions(field, kanbanGroupConfig, { personOptions }),
            getGroupAllVisibleOptions(field, { personOptions })
        )

        if (completeCurrentViewCache.length === 0) {
            return completeKanbanVisibleConfig
        }

        return mergeGroupVisibleOptions(completeCurrentViewCache, completeKanbanVisibleConfig)
    }, [kanbanGroupByField, cachedKanbanSort, kanbanGroupConfig, schema, personOptions])

    const groupConfigure: GroupConfigure = useMemo(
        () => ({
            groupByFieldId: kanbanGroupByField,
            groupConfig: mergedSortRule || []
        }),
        [kanbanGroupByField, mergedSortRule]
    )

    // const handlePrint = useCallback(
    //     (templateId: string) => {
    //         onPrint?.({
    //             appId,
    //             dsId: pointer,
    //             recordIds: selectedRecords,
    //             templateId,
    //             mode: selectedMode,
    //             sorts: sortCache ?? [],
    //             quickFilters: quickFilterData?.rules ?? []
    //         })
    //     },
    //     [appId, onPrint, pointer, quickFilterData?.rules, selectedMode, selectedRecords, sortCache]
    // )

    // const handleExport = useCallback(() => {
    //     onExport?.({
    //         appId,
    //         viewId: blockId,
    //         mode: selectedMode,
    //         recordIds: selectedRecords,
    //         fileType: 'xlsx'
    //     })
    // }, [appId, blockId, onExport, selectedMode, selectedRecords])

    return (
        <>
            {isShowViewSettingToolbar && (
                <ViewSettingToolbar
                    appId={appId}
                    envId={envId}
                    dsId={pointer}
                    toolbarActions={actions?.toolbar}
                    viewType={viewType}
                    // selectedRecords={selectedRecords}
                    showTitle={showTitle}
                    title={title}
                    search={viewIndependentDataOmitRecord.search}
                    // exportTemplateList={exportTemplateList}
                    // selectedMode={selectedMode}
                    // rowTotal={pagination.rowTotal}
                    dataSource={dataSource}
                    dataSourceList={dataSourceList}
                    columns={columns}
                    settingColumns={settingColumns}
                    userImportSetting={userImportSetting}
                    quickFilter={quickFilterData}
                    userSortSetting={userSortSetting}
                    sort={sortCache}
                    enableSetKanban={canSetKanban}
                    enableGroup={canGroup}
                    group={groupCache}
                    addRecordText={creatingConfig?.label}
                    enableAddRecord={canCreateRecord && !!creatingConfig?.page}
                    enableSearch={canSearch}
                    // enableDeleteRecord={canDeleteRecord}
                    enableImport={canImport}
                    // enableExport={canExport}
                    // enablePrint={canPrint}
                    enableSort={enableSort}
                    enableDisplay={enableDisplay}
                    previewType={previewType}
                    kanbanGroupConfig={groupConfigure}
                    styles={{
                        root: {
                            padding: 0
                        }
                    }}
                    onAdd={onRecordAdd}
                    onSearch={onSearch}
                    // onChangeSelectState={onChangeSelectState}
                    // onSelectCurrentAll={handleSelectCurrentAll}
                    // onSelectAll={handleSelectAll}
                    // onSelectModeChange={onSelectModeChange}
                    onFilter={onFilter}
                    // onDelete={onRecordDelete}
                    onChangeSorter={onChangeSorter}
                    onChangeDisplay={onChangeDisplay}
                    onChangeGroup={onChangeGroup}
                    onAppend={onAppend}
                    // onExport={handleExport}
                    // onPrint={handlePrint}
                    onActionTrigger={onToolbarActionTrigger}
                    onRenderButtonTitle={onRenderButtonTitle}
                    onKanbanGroupConfigChange={v => {
                        if (!kanbanGroupByField) {
                            return
                        }
                        onChangeCachedKanbanSort({
                            [kanbanGroupByField]: v.groupConfig.map(item => ({ value: item.value, visible: item.visible }))
                        })
                    }}
                />
            )}
            {tabList && tabList.length > 0 && <TableTab tabList={tabList} value={tabValue} onTabChange={onTabChange} />}
            {children}
            {canPaginate && isHasPagination && (
                <PaginationFc
                    viewId={blockId}
                    scope={scope}
                    top={12}
                    enablePadding
                    previewType={previewType}
                    pagination={{ ...pagination, currentPage: viewIndependentDataOmitRecord.currentPage, rowTotal: viewIndependentDataOmitRecord.rowTotal }}
                    activeStyle={{
                        background: 'var(--color-app-main-tint)',
                        color: 'var(--color-app-main)'
                    }}
                    onChangePageNum={onChangePageNum}
                />
            )}
        </>
    )
}
