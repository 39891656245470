import { IconFont } from '@byecode/ui'
import type { ValueVariable } from '@lighthouse/core'
import { useApplicationContext } from '@lighthouse/shared'
import { find } from 'rambda'
import React from 'react'
import styled from 'styled-components'

import * as SC from './styles'


const PersonName = styled.span`
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
`

interface RoleTagProps {
    data: ValueVariable
    withClose?: boolean
    onClose?: () => void
}

export const RoleTag: React.FC<RoleTagProps> = ({ data, withClose = true, onClose }) => {
    const { roleOptions } = useApplicationContext()
    const valueUserId = data.valueVariable?.value && Array.isArray(data.valueVariable?.value) ? data.valueVariable?.value?.[0] : ''
    const role = find(item => item.id === valueUserId, roleOptions || [])

    return (
        <SC.Node>
            <SC.IconWrapper>
                <IconFont size={16} type="SpaceTeam" fill="var(--color-gray-400)" />
            </SC.IconWrapper>
            <PersonName>{role?.name}</PersonName>
            {withClose && <SC.Close type="Close" fill="var(--color-gray-600)" onClick={onClose} />}
        </SC.Node>
    )
}
