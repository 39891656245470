import { Flex } from '@byecode/ui/components/Flex'
import { Group } from '@byecode/ui/components/Group'
import { IconFont } from '@byecode/ui/components/IconFont'
import { Popover } from '@byecode/ui/components/Popover'
import { Tooltip } from '@byecode/ui/components/Tooltip'
import { getAssetUrl } from '@lighthouse/assets'
import type { Field, PageAbstract } from '@lighthouse/core'
import {
    getIsFindUseInRichText,
    getIsFindUseInVariable,
    getPageDataOptions,
    InnerTypeMapByFieldType,
    ListItem4ByecodeUi,
    NodeFieldPreview,
    useAtomData,
    useFindUseObjectContext,
    VariableSelect
} from '@lighthouse/shared'
import React, { useCallback, useId, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { uploadManagerInAppParams } from '@/utils/auth'

import { KeywordsAdder } from './KeywordsAdder'

const Tips = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 12px;
    border-radius: 6px;
    font-size: 14px;
    line-height: 22px;
    background: #f3f3f3;
`

const TipsLabel = styled.span`
    color: var(--color-gray-500);
`
const TipsHover = styled.span`
    color: var(--color-main);
    cursor: pointer;
`
const filterObjectUndefined = <T extends object>(v: undefined | T): v is T => !!v

export const PageShare = () => {
    const { control } = useFormContext<PageAbstract>()
    const findUseObject = useFindUseObjectContext()
    const disabledWithVersion = useIsDisabledWithVersion()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const {
        curr: { variableSourceType, datasource, page }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const options = useMemo(() => {
        const configure = [
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: (field: Field) => !['file', 'video', 'notes'].includes(field.type)
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, variableSourceType])

    const fileOptions = useMemo(() => {
        const configure = [
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: (field: Field) => field.type === 'file'
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, variableSourceType])

    const id = useId()

    return (
        <Group label="页面信息">
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <div>封面</div>
                <Controller
                    control={control}
                    name="shareConfig.image"
                    render={({ field }) => {
                        const isHighLight = getIsFindUseInVariable({
                            findUseObject,
                            variable: field.value,
                            currentDsId: datasource?.id
                        })
                        return (
                            <VariableSelect
                                {...field}
                                disabled={disabledWithVersion}
                                options={fileOptions}
                                highlighting={isHighLight}
                                uploadProps={{
                                    accept: 'image/*',
                                    multiple: false,
                                    uploadOptions: {
                                        info: { id, label: page?.name ?? '', groupId: pageId },
                                        options: {
                                            ...uploadManagerInAppParams()
                                        }
                                    }
                                }}
                                field={{ id: '', dsId: '', type: 'file', name: '', innerType: InnerTypeMapByFieldType['file'] }}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="baseline">
                <Flex alignItems="center">
                    <div>标题(T) </div>
                    <Tooltip
                        title={
                            <div style={{ lineHeight: '20px', width: 400 }}>
                                <p>标题 (Title)</p>
                                <p>
                                    告诉用户和搜索引擎当前页面的核心内容，是搜索引擎根据用户检索关键词进行排名的核心指标，一般标题中含有搜索的关键词才能获得较好的排名。
                                    {/* 编写技巧详见：<Anchor color='var(--color-theme-7)' href='https://zhuanlan.zhihu.com/p/250132996'>SEO优化技巧，页面TDK要怎么写？</Anchor> */}
                                </p>
                                PS：若留空则会默认使用“页面名称〞作为标题
                            </div>
                        }
                    >
                        <IconFont type="Question" color="var(--color-gray-400)" size={16} />
                    </Tooltip>
                </Flex>
                <Controller
                    control={control}
                    name="shareConfig.titleV2"
                    render={({ field }) => {
                        const isHighLight = getIsFindUseInRichText({
                            findUseObject,
                            doc: field.value,
                            currentDsId: datasource?.id
                        })
                        return (
                            <NodeFieldPreview
                                disabled={disabledWithVersion}
                                highlighting={isHighLight}
                                style={{ width: 180 }}
                                autoHeight
                                options={options}
                                value={field.value}
                                onBlur={field.onChange}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="baseline">
                <Flex alignItems="center">
                    <div>描述(D)</div>
                    <Tooltip
                        title={
                            <div style={{ lineHeight: '20px', width: 400 }}>
                                <p> 描述 (Description)</p>
                                <p>
                                    一句精准的话语概括出整个页面，显示在搜索结果中，用于告知用户网站页面的主要内容，作为引导用户点击的索引。
                                    {/* 编写技巧详见：<Anchor color='var(--color-theme-7)' href='https://zhuanlan.zhihu.com/p/250132996'>SEO优化技巧，页面TDK要怎么写？</Anchor> */}
                                </p>
                                PS：若留空则会默认使用“页面名称〞作为标题 PS：若留空则会默认使用页面中除了标题之外的第一段文字作为描述
                            </div>
                        }
                    >
                        <IconFont type="Question" color="var(--color-gray-400)" size={16} />
                    </Tooltip>
                </Flex>
                <Controller
                    control={control}
                    name="shareConfig.descriptionV2"
                    render={({ field }) => {
                        const isHighLight = getIsFindUseInRichText({
                            findUseObject,
                            doc: field.value,
                            currentDsId: datasource?.id
                        })
                        return (
                            <NodeFieldPreview
                                disabled={disabledWithVersion}
                                highlighting={isHighLight}
                                style={{ width: 180 }}
                                autoHeight
                                options={options}
                                value={field.value}
                                onBlur={field.onChange}
                            />
                        )
                    }}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="baseline">
                <Flex gap="4px" alignItems="center">
                    <div>关键词(K)</div>
                    <Tooltip
                        title={
                            <div style={{ width: 300, lineHeight: '20px' }}>
                                <p>关键词 (Keywords)</p>
                                告诉搜索引擎当前页面内容的关键词，用户无论在搜索结果还是浏览页面时都看不到页面关键词。
                            </div>
                        }
                    >
                        <IconFont type="Question" color="var(--color-gray-400)" size={16} style={{ cursor: 'pointer' }} />
                    </Tooltip>
                </Flex>

                <Controller
                    control={control}
                    name="shareConfig.keywords"
                    render={({ field }) => <KeywordsAdder disabled={disabledWithVersion} style={{ width: 180 }} {...field} />}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi style={{ padding: '8px 0' }}>
                <Tips>
                    <TipsLabel>这里的设置会影响：</TipsLabel>
                    <Flex alignItems="center" gap="6px">
                        <IconFont type="WeChatLogo" />
                        <TipsLabel>分享到微信的展示效果</TipsLabel>
                        <Popover position="left-start" withinPortal width={360} trigger="hover">
                            <Popover.Target>
                                <TipsHover>效果预览</TipsHover>
                            </Popover.Target>
                            <Popover.Dropdown style={{ padding: 16 }}>
                                <SharePreviewer />
                            </Popover.Dropdown>
                        </Popover>
                    </Flex>
                    <Flex alignItems="center" gap="6px">
                        <IconFont type="PageBroadContent" />
                        <TipsLabel>受访页面分析中的页面信息展示</TipsLabel>
                        <Popover position="left-start" withinPortal width={360} trigger="hover">
                            <Popover.Target>
                                <TipsHover>效果预览</TipsHover>
                            </Popover.Target>
                            <Popover.Dropdown style={{ padding: 16 }}>
                                <AnalyzePreviewer />
                            </Popover.Dropdown>
                        </Popover>
                    </Flex>
                    <Flex alignItems="center" gap="6px">
                        <IconFont type="SEO" />
                        <TipsLabel>搜索引擎的内容爬取规则（SEO）</TipsLabel>
                    </Flex>
                </Tips>
            </ListItem4ByecodeUi>
        </Group>
    )
}

const Container = styled.div``
const Title = styled.div`
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
`
const Description = styled.div`
    margin: 16px 0;
    font-size: 14px;
    line-height: 22px;
`
const SubTitle = styled.div`
    color: var(--color-gray-400);
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 10px;
`
const ImageWrapper = styled.div`
    margin-bottom: 16px;
`

function SharePreviewer() {
    return (
        <Container>
            <Title>分享到微信</Title>
            <Description>使用手机端浏览器分享到微信，可显示应用标题、描述和应用图</Description>
            <SubTitle>手机端浏览器分享</SubTitle>
            <ImageWrapper>
                <img src={getAssetUrl('common', 'wechat_share_preview.svg')} alt="手机端浏览器分享" />
            </ImageWrapper>

            <SubTitle>微信消息预览</SubTitle>
            <ImageWrapper>
                <img src={getAssetUrl('common', 'wechat_share_message.svg')} alt="微信消息预览" />
            </ImageWrapper>

            <SubTitle>朋友圈预览</SubTitle>
            <ImageWrapper>
                <img src={getAssetUrl('common', 'wechat_share_timeline.svg')} alt="朋友圈预览" />
            </ImageWrapper>
        </Container>
    )
}

function AnalyzePreviewer() {
    return (
        <Container>
            <Title>页面分析中的信息展示</Title>
            <Description>{'分析>页面分析>受访页面列表中第一列展示的是「分享与 SEO」的数据'}</Description>
            <SubTitle>效果预览</SubTitle>
            <ImageWrapper>
                <img src={getAssetUrl('common', 'page_analyze_preview.svg')} alt="朋友圈预览" />
            </ImageWrapper>
        </Container>
    )
}
