import { IconBlock } from '@lighthouse/block'
import type { IconBlockAbstract } from '@lighthouse/core'
import React from 'react'

import { useActionTrigger } from '@/hooks/useActionTrigger'

interface IconBlockProps {
    blockData: IconBlockAbstract
}

const IconBlockController: React.FC<IconBlockProps> = ({ blockData }) => {
    const {
        id: blockId,
        config: { floatPointer = '' }
    } = blockData
    const { handleActionTrigger } = useActionTrigger()

    return <IconBlock onActionTrigger={handleActionTrigger} blockData={blockData} />
}

export default IconBlockController
