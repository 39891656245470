import { Button, IconFont } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToVerticalAxis, restrictToWindowEdges } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { type EVENT_VARIABLE_TYPE } from '@lighthouse/core'
import {
    type CurrPageDatasourceForVariable,
    type PrevPageDatasourceForVariable,
    type SendEmailParagraph,
    PARAGRAPH_TYPE
} from '@lighthouse/shared'
import { arrayMove, nanoid } from '@lighthouse/tools'
import { Text } from '@mantine/core'
import { reduce } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { EmailParagraphItem } from './EmailParagraphItem'

export interface EmailParagraphProps {
    value?: SendEmailParagraph[]
    eventTypes?: EVENT_VARIABLE_TYPE[]
    curr?: CurrPageDatasourceForVariable
    prev?: PrevPageDatasourceForVariable
    onChange?: (val: SendEmailParagraph[]) => void
}

const SCxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
`

export const EmailParagraph: React.FC<EmailParagraphProps> = ({ value, eventTypes, curr, prev, onChange }) => {
    const list: SendEmailParagraph[] = useMemo(() => value || [], [value])
    // const [list, setList] = useImmer(value || [])
    const disabledWithVersion = useIsDisabledWithVersion()
    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))
    const [openedMap, setOpenMap] = useImmer(
        reduce<SendEmailParagraph, Record<string, boolean>>(
            (preVal, { id }) => {
                preVal[id] = true
                return preVal
            },
            {},
            list
        )
    )

    const getInitParagraph = useCallback(() => {
        return {
            id: nanoid(),
            type: PARAGRAPH_TYPE.NORMAL
        }
    }, [])

    const handleAdd = useCallback(() => {
        const newItem = getInitParagraph()
        const newList = [...list]
        newList.push(newItem)
        // setList(newList)
        onChange?.(newList)
        setOpenMap(draft => {
            draft[newItem.id] = true
        })
    }, [getInitParagraph, list, onChange, setOpenMap])

    const handleDelete = useCallback(
        (id: string) => {
            const newList = list.filter(item => item.id !== id)
            // setList(newList)
            onChange?.(newList)
        },
        [list, onChange]
    )

    const handleDuplicate = useCallback(
        (index: number) => {
            const field = list[index]
            if (!field) {
                return
            }
            const newList = [...list]
            const newId = nanoid()
            const newField = {
                ...field,
                id: newId
            }
            newList.splice(index + 1, 0, newField)
            // setList(newList)
            onChange?.(newList)
            setOpenMap(draft => {
                draft[newField.id] = true
            })
        },
        [list, onChange, setOpenMap]
    )

    const handleItemOnChange = useCallback(
        (val: SendEmailParagraph) => {
            const newList = list.map(item => {
                if (item.id === val.id) {
                    return val
                }
                return item
            })
            onChange?.(newList)
        },
        [list, onChange]
    )

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (active.id !== over?.id) {
                const oldIndex = list.findIndex(item => item.id === active.id)
                const newIndex = list.findIndex(item => item.id === over?.id)
                const newList = arrayMove(list, oldIndex, newIndex)
                onChange?.(newList)
            }
        },
        [list, onChange]
    )

    return (
        <SCxContainer>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                // onDragMove={handDragMove}
                onDragEnd={handleDragEnd}
                // onDragStart={handleDragStart}
                modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
            >
                <SortableContext items={list.map(d => d.id)} strategy={verticalListSortingStrategy}>
                    {list.map((item, index) => (
                        <EmailParagraphItem
                            key={item.id}
                            opened={openedMap[item.id]}
                            disabled={disabledWithVersion}
                            value={item}
                            index={index}
                            onChange={handleItemOnChange}
                            onDuplicate={() => handleDuplicate(index)}
                            onDelete={() => {
                                handleDelete(item.id)
                            }}
                            onChangeOpen={opened => {
                                setOpenMap(draft => {
                                    draft[item.id] = opened
                                })
                            }}
                        />
                    ))}
                </SortableContext>
            </DndContext>

            <Button
                style={{ justifyContent: 'flex-start', borderRadius: 100, marginTop: 8 }}
                onClick={handleAdd}
                icon={<IconFont size={16} color="var(--color-gray-500)" type="Add" />}
            >
                <Text>添加正文段落</Text>
            </Button>
        </SCxContainer>
    )
}
