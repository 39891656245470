import { FloatingTree } from '@floating-ui/react'
import { ContainerBlock, useContainerBlockContext } from '@lighthouse/block'
import { type ContainerBlockAbstract } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import {
    checkActionEvent,
    FloatBoxProvider,
    getDefaultAction,
    getWithScopeId,
    useActionRunningLoadings,
    useAtomAction,
    useRegisterBlockListener
} from '@lighthouse/shared'
import { useAtomCallback } from 'jotai/utils'
import React from 'react'

import { pageStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useFloatBlock } from '@/hooks/useFloatBlock'

interface ContainerControllerProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: ContainerBlockAbstract
    node: FlowLayoutNode
    disabled?: boolean
    onBlockChange?: (values: ContainerBlockAbstract, origin: ContainerBlockAbstract) => Promise<void> | void
}

const ContainerController = ({ blockData, onBlockChange, ...props }: ContainerControllerProps) => {
    const { config, id, synchronousId } = blockData
    const { action = getDefaultAction(), shown, floatPointer = '' } = config
    const { handleActionTrigger } = useActionTrigger()
    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()
    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()
    const { scope } = useContainerBlockContext()

    const withScopeId = getWithScopeId(id, scope)

    const handleViewChange = useAtomCallback<void, string[]>((_, set, view) => {
        set(pageStackAtom, draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (stack) {
                stack.blockRuntimeState.container = {
                    ...stack.blockRuntimeState.container,
                    [withScopeId]: {
                        ...stack.blockRuntimeState.container?.[withScopeId],
                        currentView: view
                    }
                }
            }
        })
    })

    useRegisterBlockListener(withScopeId, 'container', {
        switchPanel: payload => {
            payload && handleViewChange(payload.switchPanelId)
        },
        open: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: true } }
            onBlockChange?.(newBlockData, blockData)
        },
        close: () => {
            const newBlockData = { ...blockData, config: { ...config, shown: false } }
            onBlockChange?.(newBlockData, blockData)
        }
    })

    const provideValue = useFloatBlock(floatPointer, props.node.isRootSyncNode ? props.node.id : scope)
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const onClickAction = (ev: React.MouseEvent) => {
        if (!checkActionEvent(ev)) {
            return
        }

        if (action.type === 'none') {
            return
        }

        ev.stopPropagation()

        // eslint-disable-next-line no-return-await
        handleActionTriggerWithLoading({ type: 'click', action, id, trigger: async () => await handleActionTrigger(action) })
    }

    return (
        <FloatingTree>
            <FloatBoxProvider {...provideValue}>
                <ContainerBlock onClick={onClickAction} shown={shown} {...props} />
            </FloatBoxProvider>
        </FloatingTree>
    )
}

export default ContainerController
