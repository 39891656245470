import { Modal } from '@byecode/ui'
import type { DataSourceAbstract, Field, FieldADTValue, Pagination } from '@lighthouse/core'
import { DataSourceType, SelectedMode } from '@lighthouse/core'
import type { ColumnEvent, CreateFieldParams, InsertDirection, RowEvent } from '@lighthouse/shared'
import { createFieldNeedFetchData, Table, updateFieldNeedFetchData, useAtomAction, useAtomData, useHandleAbortPrevious } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import React, { useCallback, useEffect, useId, useMemo, useRef } from 'react'
import { useMount } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import {
    addAiFieldStatusAtom,
    createFieldAtom,
    createRecordAtom,
    deleteFieldAtom,
    deleteRecordAtom,
    fetchDataSourceAtom,
    loadDataSourceAtom,
    updateCellAtom,
    updateFieldAtom
} from '@/atoms/dataSource/action'
import { aiFieldStatusListAtom, recordPoolAtom, viewIndependentDataAtom } from '@/atoms/dataSource/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'
import { uploadManagerInAppParams } from '@/utils/auth'

const SCxContainer = styled.div`
    flex: 1;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 14px;
`

const SCxFooter = styled.div`
    height: 40px;
    margin: 20px 0 12px 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
`

interface RecordDataSourceProps {
    dataSource: DataSourceAbstract
    pageRecordId?: string
}

interface RecordDataSourceState {
    selectedRecords: string[]
    selectedMode?: SelectedMode
}

const emptyUploadOption = {
    info: { id: '', label: '', groupId: '' },
    options: {}
}

export const RecordDataSource: React.FC<RecordDataSourceProps> = ({ dataSource, pageRecordId }) => {
    const { id: dsId, schema, type, name } = dataSource
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const viewIndependentData = useAtomData(viewIndependentDataAtom, useCallback(s => s[dsId] || {}, [dsId]))
    const { recordIds } = viewIndependentData
    const { run: fetchDataSource, loading } = useAtomAction(fetchDataSourceAtom)
    const { mutation, abortRef } = useHandleAbortPrevious(fetchDataSource)
    const { run: loadDataSource } = useAtomAction(loadDataSourceAtom)
    const { run: createField } = useAtomAction(createFieldAtom)
    const { run: updateField } = useAtomAction(updateFieldAtom)
    const { run: deleteField } = useAtomAction(deleteFieldAtom)
    const { run: updateCell } = useAtomAction(updateCellAtom)
    const { run: createRecord } = useAtomAction(createRecordAtom)
    const { run: deleteRecord } = useAtomAction(deleteRecordAtom)
    const { run: addAiFieldStatus } = useAtomAction(addAiFieldStatusAtom)
    const isAggregateDataSource = type === DataSourceType.aggregateDataSource
    const selectedModeRef = useRef<SelectedMode>()
    const uploadId = useId()
    const createFieldParamsRef = useRef<CreateFieldParams>({
        sourceId: '',
        direction: '',
        fieldId: ''
    })

    const [state, setState] = useImmer<RecordDataSourceState>({
        selectedRecords: [],
        selectedMode: undefined
    })

    const { selectedRecords, selectedMode } = state

    const getDataSource = useCallback(() => {
        if (!pageRecordId) {
            return
        }
        mutation({
            envId,
            dsId,
            pagination: { currentPage: 1, pageSize: 100 },
            recordIds: [pageRecordId]
        })
    }, [dsId, envId, mutation, pageRecordId])

    useEffect(() => {
        if (dsId) {
            getDataSource()
        }
    }, [dsId, appId, getDataSource])

    useMount(() => {
        return () => {
            if (abortRef.current) {
                abortRef.current.abort()
            }
        }
    })

    const handleLoadDataSource = useCallback(
        (pagination: Pagination) => {
            return loadDataSource({
                envId,
                dsId,
                pagination,
                recordIds: pageRecordId ? [pageRecordId] : undefined
            })
        },
        [dsId, envId, loadDataSource, pageRecordId]
    )

    const handleSelectedRecords = useCallback(
        (selectedRecords: string[]) => {
            setState(draft => {
                draft.selectedRecords = selectedRecords
                draft.selectedMode = SelectedMode.CURRENT_PAGE
            })
            selectedModeRef.current = SelectedMode.CURRENT_PAGE
        },
        [setState]
    )

    const handleCellChange = useCallback(
        (recordId: string, fieldValue: FieldADTValue) => updateCell({ envId, dsId, recordId, fieldId: fieldValue.id, value: fieldValue }),
        [dsId, envId, updateCell]
    )

    const handleCellUpdate = useCallback(
        (recordId: string, fieldValue: FieldADTValue) =>
            srv.updateCell({
                envId,
                dsId,
                id: recordId,
                fieldId: fieldValue.id,
                content: fieldValue
            }),
        [dsId, envId]
    )

    const handleCreateRecord = useCallback(
        (beforeId?: string) => {
            return createRecord({ envId, dsId, beforeId })
        },
        [createRecord, dsId, envId]
    )

    // const handleDeleteRecord = useCallback(
    //     (recordIds: string[]) => {
    //         return deleteRecord({ envId, dsId, recordIds, mode: selectedMode })
    //     },
    //     [deleteRecord, dsId, envId, selectedMode]
    // )

    // const handleDeleteMultipleRecord = useCallback(async () => {
    //     const isConfirm = await Modal.confirm({
    //         title: '确认删除',
    //         content: '删除后无法恢复',
    //         okStatus: 'error',
    //         okText: '删除'
    //     })
    //     if (isConfirm) {
    //         const isDelete = await handleDeleteRecord(selectedRecords)
    //         if (isDelete) {
    //             setState(draft => {
    //                 draft.selectedRecords = []
    //                 draft.selectedMode = SelectedMode.CURRENT_PAGE
    //             })
    //             selectedModeRef.current = SelectedMode.CURRENT_PAGE
    //         }
    //     }
    // }, [handleDeleteRecord, selectedRecords, setState])

    const handleAiGeneration = useCallback(
        async (recordId: string, fieldId: string) => {
            const statusId = nanoid()
            const isSuccess = await srv.aiGenerate({ dsId, recordId, fieldId })
            if (isSuccess) {
                addAiFieldStatus({
                    id: statusId,
                    dataSourceId: dsId,
                    recordId,
                    fieldId,
                    state: 'STARTED'
                })
            }
            return isSuccess
        },
        [addAiFieldStatus, dsId]
    )

    const handleCreateField = useCallback(
        async (field: Field, sourceId: string, direction: InsertDirection) => {
            createFieldParamsRef.current = {
                sourceId,
                direction,
                fieldId: field.id
            }
            const innerType = await createField({
                envId,
                dsId,
                config: field,
                sourceId,
                direction
            })

            if (dsId !== field.dsId || createFieldNeedFetchData.has(field.type)) {
                getDataSource()
            }
            return !!innerType
        },
        [createField, dsId, envId, getDataSource]
    )

    const handleUpdateField = useCallback(
        async (fieldId: string, config: Field) => {
            const isUpdate = await updateField({
                envId,
                dsId,
                fieldId,
                config
            })
            const field = schema[fieldId]
            if ((field && field.type !== config.type) || updateFieldNeedFetchData.has(config.type)) {
                getDataSource()
            }
            return !!isUpdate
        },
        [dsId, envId, getDataSource, schema, updateField]
    )

    const handleSelectModeChange = useCallback(
        (val?: SelectedMode) => {
            if (val === SelectedMode.ALL) {
                setState(draft => {
                    draft.selectedMode = SelectedMode.ALL
                    draft.selectedRecords = recordIds
                })
                selectedModeRef.current = SelectedMode.ALL
                return
            }
            if (val === SelectedMode.CURRENT_PAGE) {
                setState(draft => {
                    draft.selectedMode = SelectedMode.CURRENT_PAGE
                    draft.selectedRecords = recordIds
                })
                selectedModeRef.current = SelectedMode.CURRENT_PAGE
                return
            }
            setState(draft => {
                draft.selectedMode = undefined
                draft.selectedRecords = []
            })
            selectedModeRef.current = undefined
        },
        [recordIds, setState]
    )

    const handleDeleteField = useCallback(
        async (field: Field) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: `确认删除列「${field.name ?? '未命名列'}」？`,
                okStatus: 'error'
            })
            if (isConfirm) {
                const isDelete = await deleteField({
                    envId,
                    dsId,
                    fieldId: field.id
                })
                return !!isDelete
            }
            return false
        },
        [deleteField, dsId, envId]
    )

    const handleClose = useCallback(() => {
        setState(draft => {
            draft.selectedMode = undefined
            draft.selectedRecords = []
        })
    }, [setState])

    const uploadOptions = useMemo(
        () => ({
            info: { id: uploadId, label: name, groupId: dsId },
            options: uploadManagerInAppParams()
        }),
        [dsId, name, uploadId]
    )

    const videoUploadOptions = useMemo(() => {
        return {
            info: { id: uploadId, label: name, groupId: dsId },
            options: uploadManagerInAppParams()
        }
    }, [dsId, name, uploadId])

    const rowEvent: RowEvent = useMemo(
        () => ({
            onRecordSelect: handleSelectedRecords,
            onCellChange: handleCellChange,
            onCellUpdate: handleCellUpdate,
            onLoadMoreData: handleLoadDataSource,
            onCreateRecord: handleCreateRecord,
            onAiGeneration: handleAiGeneration
        }),
        [handleAiGeneration, handleCellChange, handleCellUpdate, handleCreateRecord, handleLoadDataSource, handleSelectedRecords]
    )

    const columnEvent: ColumnEvent = useMemo(
        () => ({
            onCreateField: handleCreateField,
            onUpdateField: handleUpdateField,
            onDeleteField: handleDeleteField,
            onSelectModeChange: handleSelectModeChange
        }),
        [handleCreateField, handleDeleteField, handleSelectModeChange, handleUpdateField]
    )

    return (
        <>
            <SCxContainer>
                <Table
                    appId={appId}
                    headerFixed
                    viewIndependentData={viewIndependentData}
                    disableFindUse
                    disableFieldCreatable={isAggregateDataSource}
                    contentLoading={false}
                    data={dataSource}
                    dataSourceList={dataSourceList}
                    createFieldParams={createFieldParamsRef.current}
                    recordPoolAtom={recordPoolAtom}
                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                    selectedRecords={selectedRecords}
                    // disableFieldCreatable
                    disableSelect
                    disableAddRecord
                    pageSize={100}
                    columnEvent={columnEvent}
                    rowEvent={rowEvent}
                    uploadOptions={uploadOptions}
                    richTextUploadOptions={uploadManagerInAppParams()}
                    scrollBarOutside={false}
                    videoUploadOptions={videoUploadOptions}
                />
            </SCxContainer>
        </>
    )
}
