import type { Option } from '@byecode/ui'
import { Button, Select, Text } from '@byecode/ui'
import { useMounted } from '@lighthouse/tools'
import { Modal } from '@mantine/core'
import React, { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

import { useLanguageContext } from '../../contexts'

interface ExporterModalProps {
    open?: boolean
    onGetExportTemplateList: () => Promise<Option[]>
    onClose?: () => void
    onPrint?: (templateId: string) => void
}

const SCxTitle = styled.div`
    font-size: 20px;
    font-weight: var(--font-weight-bold);
    margin-bottom: 12px;
`

const SCxTip = styled.div`
    position: relative;
    font-size: var(--font-size-normal);
    color: var(--color-gray-500);
    margin-bottom: 12px;
`

const SCxFormItem = styled.div`
    margin-bottom: 12px;
`

const SCxFormItemLabel = styled.div`
    display: flex;
    padding: 12px 0;
    justify-content: space-between;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
`

const SCxFormItemLabelLeft = styled.span`
    &:after {
        content: '*';
        position: absolute;
        line-height: 18px;
        color: var(--color-red-500);
        margin-left: 6px;
    }
`

const SCxActionWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 80px 0 20px;
`

export const PrintModal: React.FC<ExporterModalProps> = ({ open = false, onGetExportTemplateList, onPrint, onClose }) => {
    const { control, handleSubmit } = useForm()
    const { convertTextByLanguage } = useLanguageContext()
    const [exportTemplateList, setExportTemplateList] = useState<Option[]>([])

    const handleGetExportTemplateList = useCallback(async () => {
        const list = await onGetExportTemplateList()
        setExportTemplateList(list)
    }, [onGetExportTemplateList])

    useMounted(() => {
        handleGetExportTemplateList()
    })

    const handlePrint = useCallback(() => {
        handleSubmit(({ templateId }) => {
            onPrint?.(templateId)
        })()
        onClose?.()
    }, [handleSubmit, onClose, onPrint])

    const handleClose = useCallback(() => {
        onClose?.()
    }, [onClose])

    return (
        <Modal
            centered
            opened={open}
            closeOnClickOutside={false}
            overlayOpacity={0.8}
            overlayBlur={3}
            radius={8}
            size={476}
            padding={0}
            styles={{
                modal: { overflow: 'hidden' },
                header: { padding: '20px 20px 0 20px', marginBottom: 0 },
                body: { padding: '0 24px' }
            }}
            onClose={handleClose}
        >
            <SCxTitle>{convertTextByLanguage('exportSettings')}</SCxTitle>
            <SCxTip>{convertTextByLanguage('aMaximumOfRecordsCanExportedAtATime', { count: 200 })}</SCxTip>
            <SCxFormItem>
                <SCxFormItemLabel>
                    <SCxFormItemLabelLeft>{convertTextByLanguage('printTemplate')}</SCxFormItemLabelLeft>
                </SCxFormItemLabel>
                <Controller
                    name="templateId"
                    control={control}
                    rules={{ required: true }}
                    render={({ field }) => (
                        <Select
                            value={field.value}
                            options={exportTemplateList}
                            size="lg"
                            emptyComponent={
                                <Text
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        color: '$colorGray600',
                                        fontSize: 14,
                                        fontWeight: 500,
                                        padding: '24px 0'
                                    }}
                                >
                                    {convertTextByLanguage('noExportTemplate')}
                                </Text>
                            }
                            placeholder={convertTextByLanguage('selectTemplate')}
                            onChange={field.onChange}
                        />
                    )}
                />
            </SCxFormItem>
            <SCxFormItem>
                <SCxFormItemLabel>
                    <SCxFormItemLabelLeft>{convertTextByLanguage('exportMethod')}</SCxFormItemLabelLeft>
                </SCxFormItemLabel>
                <Controller
                    name="exportWay"
                    control={control}
                    defaultValue="splitPage"
                    render={({ field }) => (
                        <Select
                            disabled
                            value={field.value}
                            options={[{ value: 'splitPage', label: convertTextByLanguage('exportByPage') }]}
                            size="lg"
                            placeholder="请选择导出方式"
                            onChange={field.onChange}
                        />
                    )}
                />
            </SCxFormItem>
            <SCxActionWrapper>
                <Button size="lg" style={{ marginRight: 8 }} onClick={handleClose}>
                    {convertTextByLanguage('cancel')}
                </Button>
                <Button size="lg" type="primary" onClick={handlePrint}>
                    {convertTextByLanguage('sure')}
                </Button>
            </SCxActionWrapper>
        </Modal>
    )
}
