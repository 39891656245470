import { Divider, IconFont, Popover, Switch, Toast, Tooltip } from '@byecode/ui'
import type { FilterFormType, Sorter } from '@lighthouse/core'
import { type DataSourceAbstract, DataSourceType, SelectedMode } from '@lighthouse/core'
import type { DsExportParams} from '@lighthouse/shared';
import { type AppendParams, type ImportParams, ImportModal,useLanguageContext  } from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'

import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'

import * as SC from './styles'

interface DataSourceAppenderProps {
    appId: string
    envId: string
    dataSource: DataSourceAbstract
    searchVal: string
    name: string
    // previewType: ApplicationPreviewEnum
    filter?: FilterFormType
    sorts?: Sorter[]
    onCreate?: (params: ImportParams) => Promise<boolean>
    onAppend?: (params: AppendParams) => Promise<boolean>
}

const DataSourceAppender: React.FC<DataSourceAppenderProps> = ({
    appId,
    envId,
    dataSource,
    searchVal,
    name,
    filter,
    sorts,
    onAppend,
    onCreate
}) => {
    const { type, id } = dataSource
    const [popoverOpen, setPopoverOpen] = useState(false)
    const [open, setOpen] = useState(false)
    const dataSourceList = useDataSourceList(appId, envId)
    const { convertTextByLanguage } = useLanguageContext()

    const handleImport = useCallback(() => {
        setPopoverOpen(false)
        setOpen(true)
    }, [])

    const handleExport = useCallback(async () => {
        Toast.warning(convertTextByLanguage('exportingOfProgress'))
        const params: DsExportParams = {
            envId,
            dsId: id,
            search: searchVal,
            mode: SelectedMode.ALL,
            fileType: 'xlsx',
            filter,
            sorts
        }
        const res = await srv.dsExport(params)
        const { data, headers } = res
        const fileName = headers?.['content-disposition']?.replace?.("attachment;filename*=utf-8''", '') ?? `${name}.xlsx`
        const dom = document.createElement('a')
        const url = window.URL.createObjectURL(data)
        dom.href = url
        dom.download = decodeURI(fileName)
        dom.style.display = 'none'
        document.body.append(dom)
        dom.click()
        dom.remove()
        window.URL.revokeObjectURL(url)
    }, [convertTextByLanguage, envId, filter, id, name, searchVal, sorts])

    const disableImport = useMemo(() => type === DataSourceType.aggregateDataSource, [type])
    // const disableApprove = useMemo(
    //     () =>
    //         type === DataSourceType.systemDataSource || type === DataSourceType.joinDataSource || type === DataSourceType.aggregateDataSource,
    //     [type]
    // )
    const disableApprove = useMemo(() => true, [])
    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <Popover width="auto" withinPortal opened={popoverOpen} onChange={setPopoverOpen}>
            <Popover.Target>
                <SC.ExtraButton type="text" icon={<IconFont type="DotsThree" />} />
            </Popover.Target>
            <Popover.Dropdown>
                <SC.ActionList>
                    {!disableImport && (
                        <SC.ActionItem onClick={handleImport}>
                            <IconFont type="UploadSimple" style={{ marginRight: 4, color: 'var(--color-gray-400)' }} />
                            导入数据
                        </SC.ActionItem>
                    )}
                    <SC.ActionItem onClick={handleExport}>
                        <IconFont type="Import" style={{ marginRight: 4, color: 'var(--color-gray-400)' }} />
                        导出数据
                    </SC.ActionItem>
                </SC.ActionList>
            </Popover.Dropdown>
            <ImportModal
                appId={appId}
                envId={envId}
                dataSource={dataSource}
                defaultImportType="append"
                open={open}
                dataSourceList={dataSourceList}
                onImport={onCreate}
                onAppend={onAppend}
                onClose={handleClose}
            />
        </Popover>
    )
}

export default DataSourceAppender
