import { IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const Node = styled.div<{ background?: string; color?: string }>`
    font-size: var(--font-size-sm);
    display: flex;
    height: 32px;
    line-height: 32px;
    align-items: center;
    justify-content: flex-start;
    border-radius: 4px;
    padding-right: 4px;
    color: ${({ color }) => color || 'var(--color-black)'};
`

export const DescriptionWrapper = styled.div`
    padding: 0 16px 8px 16px;
`

export const Description = styled.div<{ marginTop?: boolean}>`
    margin-top: ${({ marginTop }) => marginTop ? '6px' : 0};
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    font-weight: 400;
    line-height: 18px;
`

export const Content = styled.div`
    padding: 0 16px 12px 16px;
`



export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    margin-right: 4px;
`

export const SelectContainer = styled.div`
    width: 100%;
`
// export const LeftFill = styled.div`
//     display: flex;
//     align-items: center;
//     gap: 8px;
// `
// export const RightFill = styled.div`
//     display: none;
//     align-items: center;
//     justify-content: flex-end;
// `

export const VariableItem = styled.div`
    margin-bottom: 12px;
`

export const VariableTitle = styled.div`
    margin-bottom: 8px;
    font-weight: 400;
    color: var(--color-black);
`

export const Close = styled(IconFont)`
    font-size: 14px;
    margin-left: 4px;
    cursor: pointer;
`
