import type { ButtonAction, ButtonBlockAbstract, CollectEvent, RichTextContentProtocol } from '@lighthouse/core'
import { EVENT_VARIABLE_TYPE } from '@lighthouse/core'
import type { TriggerEventExtraOption } from '@lighthouse/shared'
import { ActionButton, useActionRunningLoadings } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'

interface ButtonBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: ButtonBlockAbstract
    onActionTrigger?: (params: ButtonAction) => Promise<boolean | undefined>
    onRenderTitle: (v: RichTextContentProtocol) => string
    onEventTrigger?: (collectEvents: CollectEvent[] | undefined, type: EVENT_VARIABLE_TYPE, option?: TriggerEventExtraOption) => void

    // onRenderButtonTitle: (v: RichTextContentProtocol) => string
}

const SCxContainer = styled(AnimationDecorators)<Pick<React.CSSProperties, 'justifyContent'>>`
    width: 100%;
    padding: var(--block-padding);
`

const ButtonBlock: React.FC<ButtonBlockProps> = ({ blockData, onActionTrigger, onRenderTitle, onEventTrigger }) => {
    const { config, id, title } = blockData
    const { radius = '0', size, collectEvents, breakPoint, ...restConfig } = config

    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()

    const actionConfig = useMemo(() => ({ id, ...restConfig }), [id, restConfig])

    return (
        <SCxContainer blockId={id} animation={breakPoint.animation}>
            <ActionButton
                styles={{
                    container: {
                        width: '100%'
                    }
                }}
                size={size}
                radius={restConfig.shape === 'rectangle' ? radius : '100'}
                config={actionConfig}
                loading={loadings[id]}
                disabled={loadings[id]}
                data-stop-action-propagation
                onClick={e => {
                    handleActionTriggerWithLoading({ type: 'click', action: restConfig.action, id, trigger: onActionTrigger })
                    onEventTrigger?.(collectEvents, EVENT_VARIABLE_TYPE.BUTTON_CLICK, { blockName: title })
                }}
                onMouseOver={e =>
                    handleActionTriggerWithLoading({ type: 'hover', action: restConfig.action, id, trigger: onActionTrigger })
                }
                onRenderTitle={onRenderTitle}
            />
        </SCxContainer>
    )
}

export default ButtonBlock
