import { ButtonBlock } from '@lighthouse/block'
import type { ButtonBlockAbstract } from '@lighthouse/core'
import React from 'react'

import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'

interface ButtonBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: ButtonBlockAbstract
}

const ButtonBlockController: React.FC<ButtonBlockProps> = ({ blockData, ...rest }) => {

    const { handleActionTrigger } = useActionTrigger()

    const { handleRenderTitle } = useRichTextToTitle()

    return <ButtonBlock onActionTrigger={handleActionTrigger} onRenderTitle={handleRenderTitle} blockData={blockData} {...rest} />
}

export default ButtonBlockController
