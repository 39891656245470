import { Button, IconFont } from '@byecode/ui'
import styled from 'styled-components'

export const ViewSettingToolbarContainer = styled.div`
    margin-bottom: 12px;
    padding: 0 5px;
`

export const ViewSettingToolbarContent = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
`

export const ViewSettingContainer = styled.div`
    display: flex;
    justify-content: flex-start;

`

export const ViewActionContainer = styled.div`
    max-width: 100%;
    flex: auto;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
`

export const ViewSettingContent = styled.div`
    display: flex;
    flex: 1;
    flex-shrink: 0;
    flex-flow: row wrap;
    justify-content: flex-end;
`

export const ViewActionPositive = styled.div<{ isMobile?: boolean }>`
    display: flex;
    flex-shrink: 0;
    flex-flow: row wrap;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    max-width: 100%;
    padding-right: ${({ isMobile }) => (isMobile ? 0 : '8px')};
    flex: ${({ isMobile }) => (isMobile ? 1 : 'none')};
`

export const SCxCancelSelect = styled(Button)`
    background-color: var(--color-white);
    color: var(--color-black);
    border-color: #dfdfdd;
`

export const SXcRecordDelete = styled(Button)`
    color: #d88987;
    background-color: var(--color-white);
`

export const RecordUnlink = styled(Button)`
    background-color: var(--color-white);
    margin: 8px 0;
    margin-left: 10px;
`

export const SXcRecordAdd = styled(Button)`
    color: var(--color-white);
`

export const Icon = styled(IconFont)``

export const PopoverContent = styled.div<{ height?: number }>`
    max-width: 625px;
    max-height: ${({ height }) => (height ? `${height}px` : '450px')};
    padding-bottom: 10px;
`
