import { Button, IconFont } from '@byecode/ui'
import { getFileSizeWithUnit } from '@lighthouse/tools'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { FILE_ICON_MAP } from '../../constants'
import { useLanguageContext } from '../../contexts'

interface UploadFileViewerProps {
    file: File
    onFileClear?: () => void
}

const FileViewerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 200px;
    align-items: center;
    border-radius: 6px;
    border: 1px solid var(--color-gray-200);
    background-color: var(--color-gray-50);
`

const FileIcon = styled(IconFont)`
    font-size: var(--font-size-lg);
    margin-bottom: 6px;
`

const FileName = styled.p`
    font-size: var(--font-size-lg);
    color: var(--color-black);
    margin-bottom: 6px;
`

const FileSize = styled.p`
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    margin-bottom: 24px;
`

const ClearFile = styled(Button)``

const ClearIcon = styled(IconFont)``

export const UploadFileViewer: React.FC<UploadFileViewerProps> = ({ file, onFileClear }) => {
    const { name, size } = file
    const unitedFileSize = useMemo(() => getFileSizeWithUnit(size), [size])
    const { convertTextByLanguage } = useLanguageContext()
    return (
        <FileViewerContainer>
            <FileIcon type={FILE_ICON_MAP.xls} size={24} />
            <FileName>{name}</FileName>
            <FileSize>{unitedFileSize}</FileSize>
            <ClearFile onClick={onFileClear} icon={<ClearIcon type="ArrowsClockwise" size={16} fill="var(--color-gray-400)" />}>
                {convertTextByLanguage('reupload')}
            </ClearFile>
        </FileViewerContainer>
    )
}
