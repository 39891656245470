import type { ApplicationPreviewEnum, NodeIdWithScope } from '@lighthouse/shared'
import { findNodeDom, PAGE_CONTAINER_HOST } from '@lighthouse/shared'

import { getApplicationScale } from '@/utils'

export const convertPosition = (
    node: NodeIdWithScope,
    type: 'absolute' | 'fixed',
    previewType: ApplicationPreviewEnum,
    parentNode?: NodeIdWithScope
) => {
    const dom = findNodeDom(node)
    const parentDom = parentNode && findNodeDom(parentNode)
    const rootNode = document.querySelector<HTMLDivElement>(PAGE_CONTAINER_HOST)
    const scale = getApplicationScale(previewType)

    if (!dom) {
        return {
            top: 0,
            left: 0
        }
    }

    const { top, left } = dom.getBoundingClientRect()
    if (type === 'absolute' && parentDom) {
        const { top: parentTop, left: parentLeft } = parentDom.getBoundingClientRect()
        return {
            top: top / scale - parentTop / scale,
            left: left / scale - parentLeft / scale
        }
    }

    if (rootNode) {
        const { top: rootTop, left: rootLeft } = rootNode.getBoundingClientRect()
        return {
            top: top / scale - rootTop / scale,
            left: left / scale - rootLeft / scale
        }
    }

    return {
        top: 0,
        left: 0
    }
}
