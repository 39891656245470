import type {
    DataSourceCount,
    ViewIndependentData
} from '@lighthouse/core';
import {
    type AiFieldStatus,
    type DataSourceAbstract,
    type DataSourceIdentity,
    type EnvironmentAbstract,
    type RecordIdentity,
    type RecordLikeProtocol,
    EnvironmentStruct
} from '@lighthouse/core';
import { getMainTableRecordId, USER_DATASOURCE } from '@lighthouse/shared';
import shallowEqual from 'fast-deep-equal';
import type { Draft } from 'immer';
import { atom } from 'jotai';
import { atomFamily } from 'jotai/utils';
import { atomWithImmer } from 'jotai-immer';
import { find } from 'rambda';

import { commonRoleIds } from '../application/constants';
import { appRoleAtom, currentAppIdAtom, currentEnvIdAtom } from '../application/state';
import { applyDraftPayload } from '../utils/applyDraftPayload';
import type { ViewRecordPool } from './types';

// export const dataSourceListIdsAtom = atomWithImmer<string[]>([])
export const dataSourcePoolAtom = atomWithImmer<DataSourceAbstract[]>([])

export const dataSourceCountAtom = atomWithImmer<DataSourceCount[]>([])
export const recordPoolAtom = atomWithImmer<RecordLikeProtocol[]>([])
export const currentDataSourceIdAtom = atom('')
export const aiFieldStatusListAtom = atomWithImmer<AiFieldStatus[]>([])
// export const dataSourceEnvIdAtom = atomWithImmer<string>('')
// export const dataSourceUserListAtom = atomWithImmer<AppUser[]>([])
// export const dataSourceRoleListAtom = atomWithImmer<RoleProtocols[]>([])
// export const dataSourceDepartmentListAtom = atomWithImmer<AppDepartment[]>([])
export const dataSourceEnvAtom = atomWithImmer<EnvironmentAbstract>({ ...EnvironmentStruct() })


export const viewIndependentDataAtom = atomWithImmer<ViewRecordPool>({})

export const dataSourceAtomFamily = atomFamily((params: DataSourceIdentity) => {
    const { appId, envId, dsId } = params
    return atom(
        get => get(dataSourcePoolAtom).find(item => item.appId === appId && item.envId === envId && item.id === dsId),
        (_, set, payload: DataSourceAbstract | undefined | ((draft: Draft<DataSourceAbstract | undefined>) => void)) =>
            set(dataSourcePoolAtom, draft => {
                const dataSource = draft.find(item => item.id === dsId && item.appId === appId && item.envId === envId)
                applyDraftPayload(dataSource, payload)
            })
    )
}, shallowEqual)

export const dataSourceCountAtomFamily = atomFamily((params: DataSourceIdentity) => {
    const { appId, envId, dsId } = params
    return atom(
        get => get(dataSourceCountAtom).find(item => item.appId === appId && item.envId === envId && item.dsId === dsId)?.count ?? 0,
        (_, set, payload: number) =>
            set(dataSourceCountAtom, draft => {
                const dataSourceCount = draft.find(item => item.dsId === dsId && item.appId === appId && item.envId === envId)
                if (!dataSourceCount) {
                    return
                }
                dataSourceCount.count = payload
            })
    )
}, shallowEqual)

export const recordAtomFamily = atomFamily((params: RecordIdentity) => {
    // 解决连接表问题
    const { appId, envId, dsId, recordId } = params
    return atom(
        get => get(recordPoolAtom).find(item => item.appId === appId && item.envId === envId && item.id === recordId && item.dsId === dsId),
        (_, set, payload: RecordLikeProtocol | ((draft: Draft<RecordLikeProtocol>) => void)) =>
            set(recordPoolAtom, draft => {
                const recordsIndex = draft.reduce<number[]>((prev, cur, index) => {
                    if (cur.appId === appId && cur.dsId === dsId && getMainTableRecordId(cur.id) === getMainTableRecordId(recordId)) {
                        prev.push(index)
                    }
                    return prev
                }, [])
                if (recordsIndex.length === 0) {
                    return
                }
                recordsIndex.forEach(i => {
                    applyDraftPayload(draft[i], payload)
                })
            })
    )
}, shallowEqual)

export const dataSourceListAtomFamily = atomFamily((params: { appId: string; envId: string }) => {
    const { appId, envId } = params
    return atom(get => get(dataSourcePoolAtom).filter(item => item.appId === appId && item.envId === envId))
})

export const recordListAtomFamily = atomFamily((params: { appId: string; dsId: string }) => {
    const { appId, dsId } = params
    return atom(get => get(recordPoolAtom).filter(item => item.appId === appId && item.dsId === dsId))
}, shallowEqual)

export const userRecordAtom = atom(get => {
    const recordList = get(recordPoolAtom)
    // const envId = get(dataSourceEnvIdAtom)
    const envId = get(currentEnvIdAtom)
    const userId = get(appRoleAtom)
    if (commonRoleIds.includes(userId)) {
        return
    }
    return find(
        record => record?.content?.['ID']?.value === userId && Boolean(userId) && record.dsId === USER_DATASOURCE && record.envId === envId,
        recordList ?? []
    )
})

export const userDataSourceAtom = atom(get => {
    const dataSourceList = get(dataSourcePoolAtom)
    // const envId = get(dataSourceEnvIdAtom)
    const envId = get(currentEnvIdAtom)
    return find(ds => ds.id === USER_DATASOURCE && ds.envId === envId, dataSourceList)
})

// key 为  appId-pageId-blockId  拼接而成
// export const filterBlockValueAtom = atomWithStorage<Record<string, FilterBlockValue>>('filterBlock', {})

export const viewIndependentDataAtomFamily = atomFamily((viewId: string) => {
    return atom(
        get => {
            const appId = get(currentAppIdAtom)
            const envId = get(currentEnvIdAtom)
            const ViewRecordPool = get(viewIndependentDataAtom)
            const dsRecords = get(recordPoolAtom)
            const viewRecords = ViewRecordPool?.[viewId]

            if (!viewRecords) {
                return undefined
            }
            const { recordIds = [], ...rest } = viewRecords
            const records = recordIds.reduce<RecordLikeProtocol[]>((records, viewRecordId) => {
                const record = find(
                    item => appId === item.appId && viewRecordId === item.id && item.envId === envId,
                    dsRecords
                )
                if (record) {
                    const viewRecordData = record.content
                    records.push({
                        id: viewRecordId,
                        appId,
                        envId,
                        dsId: record.dsId,
                        content: viewRecordData
                    })
                }
                return records
            }, [])
            return { records, ...rest }
        },
        (_, set, payload: ViewIndependentData | ((draft: Draft<ViewIndependentData>) => void)) =>
            set(viewIndependentDataAtom, draft => {
                if (typeof payload === 'function') {
                    if (!draft[viewId]) {
                        draft[viewId] = {
                            recordIds: [],
                            currentPage: 1,
                            rowTotal: 0,
                            search: ''
                        }
                    }
                    applyDraftPayload(draft[viewId], payload)
                    return
                }
                // applyDraftPayload(draft[viewId], payload)
                draft[viewId] = payload
            })
    )
}, shallowEqual)
