import { Flex, IconFont, Image, Text, Tooltip } from '@byecode/ui'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { tooltipDocumentMap } from '@/components/PopoverQuestion/constants'
import type { TooltipDocumentType } from '@/components/PopoverQuestion/types'
import type { BlockTreeItem } from '@/constants/Block/type'

interface BlockCardProps {
    data: BlockTreeItem
    onOpenQuestion?: (v: BlockTreeItem) => void
    onCreate?: (e: React.MouseEvent<HTMLDivElement>, v: BlockTreeItem) => void
}

const SCxItem = styled(Flex)`
    position: relative;
    width: 72px;
    height: 72px;
    background-color: var(--color-gray-100);
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;

    img {
        -webkit-user-drag: none;
    }

    &:hover {
        background-color: var(--color-gray-200);

        .tooltipIcon {
            display: block;
        }
    }

    .tooltipIcon {
        position: absolute;
        right: 2px;
        top: 2px;
        display: none;
        z-index: 1;
        color: var(--color-gray-400);

        &:hover {
            color: var(--color-black);
        }
    }
`

const SCxWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
`

const SCxBlockImage = styled(Image)`
    position: absolute;
    inset: 0;
    /* pointer-events: none; */
`
const BlockCard: React.FunctionComponent<BlockCardProps> = ({ data, onCreate, onOpenQuestion }) => {
    const { describeImage, name, color } = data

    const usedType = useMemo(() => {
        if (data.type === 'field') {
            return `${data.subType}-input`
        }
        if (data.type === 'container' || data.type === 'view' || data.type === 'chart') {
            return data.subType
        }
        return data.type
    }, [data])

    const isExitTooltip = useMemo(() => {
        return tooltipDocumentMap?.[usedType as TooltipDocumentType]?.file
    }, [usedType])

    return (
        <SCxItem>
            {isExitTooltip && (
                <Tooltip title="点击查看教程">
                    <IconFont type="Question" size={16} className="tooltipIcon" onClick={() => onOpenQuestion?.(data)} />
                </Tooltip>
            )}
            <SCxWrapper draggable="false" onMouseDown={e => onCreate?.(e, data)}>
                <SCxBlockImage draggable="false" src={describeImage} />
                <Text
                    draggable="false"
                    size={12}
                    lineHeight="20px"
                    algin="center"
                    style={{ marginBottom: 4, pointerEvents: 'none' }}
                    color="var(--color-gray-700)"
                >
                    {name}
                </Text>
            </SCxWrapper>
        </SCxItem>
    )
}

export default BlockCard
