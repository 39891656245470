import { Breadcrumbs, Button, IconFont, Input, Modal, Popover, SelectDropdown } from '@byecode/ui'
import { getBlockIcon, getBlockName } from '@lighthouse/block'
import type { BlockAbstract, BlockRuntimeState, BlockType } from '@lighthouse/core'
import { PAGE_TYPE } from '@lighthouse/core'
import { findBlockById, findNormalOrSyncBlock, getCurrentBlockChildren, LinkDocument, useAtomAction, useAtomData } from '@lighthouse/shared'
import { getNextIndexByDeleteList } from '@lighthouse/tools'
import { Flex, Menu } from '@mantine/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled, { css } from 'styled-components'

import { syncComponentsAtom } from '@/atoms/application/state'
import { removePageAtom } from '@/atoms/page/action'
import { lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageStackAtom } from '@/atoms/page/state'
import type { NodeIdWithScope } from '@/atoms/page/types'
import { AsideType } from '@/atoms/page/types'
import { stackFactory } from '@/atoms/page/utils'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentAppID, usePreviewType } from '@/hooks/useApplication'
import { useBlockActions } from '@/hooks/useBlock'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageList } from '@/hooks/usePage'

type PathInfo = {
    block: BlockAbstract
    scope?: string
}

function getBlockPath(
    node: NodeIdWithScope,
    blocks: BlockAbstract[],
    syncComponents: BlockAbstract[],
    runtimeState: undefined | BlockRuntimeState
) {
    function getSyncBlockPath(id: string, syncTree: BlockAbstract[]): PathInfo[] {
        for (const block of syncTree) {
            if (block.id === id) {
                return [{ block, scope: node.scope }]
            }

            const children = getCurrentBlockChildren(block, {
                blockRuntimeState: runtimeState,
                uniqueContainerId: block.isMasterSynchronous ? node.scope : `${node.scope}@${block.id}`
            })
            if (children) {
                const res = getSyncBlockPath(id, children)
                if (res.length > 0) {
                    return [{ block, scope: node.scope }, ...res]
                }
            }
        }
        return []
    }

    function recursion(id: string, tree: BlockAbstract[]): PathInfo[] {
        for (const block of tree) {
            if (block.id === id) {
                if (block.synchronousId) {
                    const syncComponent = findBlockById(block.synchronousId, syncComponents)
                    if (!syncComponent) {
                        continue
                    }
                    return [{ block: syncComponent }]
                }
                return [{ block }]
            }

            const children = getCurrentBlockChildren(block, {
                blockRuntimeState: runtimeState
            })

            if (children) {
                const res = recursion(id, children)
                if (res.length > 0) {
                    return [{ block }, ...res]
                }
            }
        }

        return []
    }

    if (node.scope) {
        const syncPaths = getSyncBlockPath(node.id, syncComponents)
        const normalPaths = recursion(node.scope, blocks)
        return [...normalPaths.slice(0, -1), ...syncPaths]
    }

    return recursion(node.id, blocks)
}

const EditIcon = styled(IconFont)`
    opacity: 0;
    margin-left: 2px;
`

const WithHoverBox = styled.div.withConfig<{ disabled?: boolean }>({ shouldForwardProp: p => p !== 'disabled' })`
    display: flex;
    align-items: center;
    gap: 4px;

    ${({ disabled }) =>
        !disabled &&
        css`
            &:hover {
                ${EditIcon} {
                    opacity: 1;
                }
            }
        `}
`

export const PageBreadcrumbs: React.FC = () => {
    const pageMeta = useAtomData(lastPageOfStackAtom)
    const { pageId = '', rootPageId = '', stackId = '', blockRuntimeState, state } = pageMeta ?? {}
    const { asideType, selectedNodes } = state ?? {}
    const selectedNode = selectedNodes && selectedNodes.length === 1 ? selectedNodes[0] : undefined

    const pageContent = useAtomData(pageAtomFamily(pageId))
    const disabledWithVersion = useIsDisabledWithVersion()

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(syncComponentsAtom)

    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const pageName = pageContent?.name || '无标题'

    const appId = useCurrentAppID()
    const pageList = usePageList()
    const { run: removePage } = useAtomAction(removePageAtom)
    const { onUpdateBlock } = useBlockActions(pageId, stackId)

    const [documentType, subNodeType] = useMemo(() => {
        if (selectedNode) {
            const block = findNormalOrSyncBlock(selectedNode, pageBlocks, syncComponents)
            if (block?.type === 'view') {
                return [block?.type, block.config.viewType]
            }
            if (block?.type === 'chart') {
                return [block?.type, block.config.chartType]
            }
            if (block?.type === 'field') {
                return [block?.type, block.config.inputType]
            }
            return [block?.type]
        }
        if (asideType) {
            return ['navbar' as unknown as BlockType]
        }
        return []
    }, [asideType, pageBlocks, selectedNode, syncComponents])

    /** 删除页面 */
    const onDeletePage = useCallback(() => {
        Modal.confirm({
            title: '确认删除页面',
            content: `确认删除页面「${pageName ?? '无标题'} 」？`,
            okStatus: 'error',
            okText: '删除'
        })?.then(async s => {
            if (s) {
                if (!pageList) {
                    return
                }
                await removePage(pageId)

                setPageStack(draft => {
                    draft.splice(0)
                    const rootPageList = pageList.filter(item => item.type === PAGE_TYPE.default)
                    const index = rootPageList.findIndex(item => item.id === pageId)
                    const nextIndex = getNextIndexByDeleteList(index, rootPageList)
                    const nextPageId = rootPageList[nextIndex].id
                    if (nextPageId) {
                        draft.push(
                            stackFactory({
                                appId,
                                pageId: nextPageId
                            })
                        )
                    }
                })
            }
        })
    }, [appId, pageId, pageList, pageName, removePage, setPageStack])

    const hasExtraMenu = useMemo(() => {
        return asideType === AsideType.PAGE && rootPageId !== pageId
    }, [asideType, pageId, rootPageId])

    const [editBlock, setEditBlock] = useState<BlockAbstract | null>(null)

    useUpdateEffect(() => {
        setEditBlock(null)
    }, [selectedNode])

    const { run: setSyncComponents } = useAtomAction(syncComponentsAtom)
    const previewType = usePreviewType()

    const handleUpdateBlockName = useCallback(
        (value: string) => {
            setEditBlock(null)

            if (!editBlock || !value) {
                return
            }

            const label = editBlock.title || getBlockName(editBlock)
            if (value === label) {
                return
            }

            if (editBlock.isLeafSynchronous || editBlock.isMasterSynchronous) {
                setSyncComponents(draft => {
                    const draftBlock = findBlockById(editBlock.id, draft)
                    if (!draftBlock) {
                        return
                    }

                    draftBlock.title = value
                })
            } else {
                onUpdateBlock({ nextBlock: { ...editBlock, title: value }, prevBlock: editBlock })
            }
        },
        [editBlock, onUpdateBlock, setSyncComponents]
    )

    const breadcrumbs = useMemo(() => {
        switch (asideType) {
            case AsideType.BLOCK: {
                if (!selectedNode) {
                    break
                }

                if (editBlock) {
                    const label = editBlock.title || getBlockName(editBlock)
                    return [
                        {
                            label: (
                                <Input
                                    disabled={disabledWithVersion}
                                    size="sm"
                                    defaultValue={label}
                                    onBlur={e => {
                                        const v = e.currentTarget.value
                                        handleUpdateBlockName(v)
                                    }}
                                    onKeyDown={e => {
                                        if (e.key === 'Enter') {
                                            const v = e.currentTarget.value
                                            handleUpdateBlockName(v)
                                        }
                                    }}
                                    autoFocus
                                    autoSelect
                                />
                            )
                        }
                    ]
                }

                const paths = getBlockPath(selectedNode, pageBlocks, syncComponents, blockRuntimeState)

                const needFold = paths.length > 3

                if (needFold) {
                    const foldOptions = paths.slice(1, -1).map(path => {
                        return {
                            label: path.block.title || getBlockName(path.block),
                            value: path.block.id,
                            scope: path.scope
                        }
                    })

                    const firstPath = paths[0]
                    const lastPath = paths[paths.length - 1]

                    return [
                        {
                            label: firstPath ? (
                                <span
                                    onClick={() => {
                                        setPageStack(draft => {
                                            const stack = equalPageStack({ rootPageId, stackId })(draft)
                                            if (stack) {
                                                stack.state.selectedNodes = [{ id: firstPath.block.id, scope: firstPath.scope }]
                                            }
                                        })
                                    }}
                                >
                                    {firstPath.block.title || getBlockName(firstPath.block)}
                                </span>
                            ) : (
                                ''
                            )
                        },
                        {
                            label: (
                                <Popover width={180} withinPortal>
                                    <Popover.Target>
                                        <span>...</span>
                                    </Popover.Target>
                                    <Popover.Dropdown>
                                        <SelectDropdown
                                            options={foldOptions}
                                            onSelect={id => {
                                                setPageStack(draft => {
                                                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                                                    const option = foldOptions.find(item => item.value === id)
                                                    if (stack && option) {
                                                        stack.state.selectedNodes = [{ id: option.value, scope: option.scope }]
                                                    }
                                                })
                                            }}
                                        />
                                    </Popover.Dropdown>
                                </Popover>
                            )
                        },
                        {
                            label: lastPath ? (
                                <WithHoverBox
                                    disabled={lastPath.block.isMasterSynchronous}
                                    onClick={() => {
                                        if (lastPath.block.isMasterSynchronous) {
                                            return
                                        }
                                        setEditBlock(lastPath.block)
                                    }}
                                >
                                    <IconFont type={getBlockIcon(lastPath.block, previewType)} size={16} color="var(--color-gray-400)" />
                                    <span>{lastPath.block.title || getBlockName(lastPath.block)}</span>
                                    <EditIcon type="edit" size={12} color="var(--color-gray-400)" />
                                </WithHoverBox>
                            ) : (
                                ''
                            )
                        }
                    ]
                }

                return paths.map((path, index, arr) => {
                    const { block, scope } = path

                    const label = block.title || getBlockName(block)
                    const isLast = index === arr.length - 1

                    if (isLast) {
                        return {
                            label: (
                                <WithHoverBox
                                    disabled={block.isMasterSynchronous}
                                    onClick={() => {
                                        if (block.isMasterSynchronous) {
                                            return
                                        }
                                        setEditBlock(block)
                                    }}
                                >
                                    <IconFont type={getBlockIcon(block, previewType)} size={16} color="var(--color-gray-400)" />
                                    <span>{label}</span>
                                    <EditIcon type="edit" size={12} color="var(--color-gray-400)" />
                                </WithHoverBox>
                            )
                        }
                    }

                    return {
                        label: (
                            <span
                                onClick={() => {
                                    setPageStack(draft => {
                                        const stack = equalPageStack({ rootPageId, stackId })(draft)
                                        if (stack) {
                                            stack.state.selectedNodes = [{ id: block.id, scope }]
                                        }
                                    })
                                }}
                            >
                                {label}
                            </span>
                        )
                    }
                })
            }

            case AsideType.NAVBAR: {
                return [{ label: '全局导航栏' }]
            }

            case AsideType.PAGE: {
                return [{ label: pageName }]
            }

            default: {
                break
            }
        }

        return []
    }, [
        asideType,
        blockRuntimeState,
        disabledWithVersion,
        editBlock,
        handleUpdateBlockName,
        pageBlocks,
        pageName,
        previewType,
        rootPageId,
        selectedNode,
        setPageStack,
        stackId,
        syncComponents
    ])

    return (
        <Breadcrumbs
            styles={{
                root: {
                    flex: 1
                },
                body: {
                    margin: '-8px 0'
                },
                item: {
                    cursor: 'pointer',
                    lineHeight: '22px'
                }
            }}
            separator=" / "
            items={breadcrumbs}
            extra={[
                <Flex key="breadcrumbs_extra" align="center" gap={4}>
                    {hasExtraMenu ? (
                        <Menu key="menu" shadow="sm">
                            <Menu.Target>
                                <Button
                                    size="xs"
                                    type="text"
                                    style={{ width: 20, height: 20 }}
                                    icon={<IconFont type="DotsThree" size={16} />}
                                />
                            </Menu.Target>
                            <Menu.Dropdown>
                                {asideType === AsideType.PAGE && (
                                    <Menu.Item key="del-page" onClick={onDeletePage}>
                                        删除
                                    </Menu.Item>
                                )}
                            </Menu.Dropdown>
                        </Menu>
                    ) : null}
                    <LinkDocument type={documentType} subNodeType={subNodeType} />
                </Flex>
            ]}
        />
    )
}
