import type { DateValue } from '@lighthouse/core'
import { differenceInDays, format, isValid, startOfDay, toDate } from 'date-fns'
import enUS from 'date-fns/locale/en-US/index'
import zhCN from 'date-fns/locale/zh-CN/index'
import zhTW from 'date-fns/locale/zh-TW/index'
import i18next from 'i18next'
import { LunarHour, SolarDay, SolarTime } from 'tyme4ts'

const dateFnsI18Map: Record<string, Locale> = {
    'zh-CN': zhCN,
    'zh-TW': zhTW,
    en: enUS
}

export const getTimestamp = function (value: DateValue) {
    if (value === '') {
        return ''
    }
    return isValid(value) ? toDate(Number(value)).getTime() : ''
}

export const getRelativeDate = function (date: number | Date, baseNumber: number | Date, language?: string) {
    const diffDay = differenceInDays(startOfDay(date), startOfDay(baseNumber))
    const week = format(date, 'iiii', {
        locale: dateFnsI18Map[language || i18next.language]
    })
    const negativeDistance = 2
    if (diffDay > negativeDistance || diffDay < -negativeDistance) {
        return `（${week}）`
    }

    return `（${
        ['beforeYesterday', 'yesterday', 'today', 'tomorrow', 'afterTomorrow'].map(key => i18next.t(key))[diffDay + negativeDistance]
    } ${week}）`
}

/** 提取农历日期转换为公历 */
export function extractLunarDate(text: string) {
    // 定义正则表达式，提取农历日期中的年、月、日、时、分
    const regex =
        // eslint-disable-next-line unicorn/no-unsafe-regex
        /((\d{4})年)?(正月|腊月|[一七三九二五八六十四]+月)?(初([一七三九二五八六十四廿]+)|[一七三九二五八六十四廿]+)?([丑亥午卯子寅巳戌未申辰酉]{1,2})?时?/

    const match = regex.exec(text)

    if (!match) {
        return
    }

    const monthMap: Record<string, number> = {
        正月: 1,
        二月: 2,
        三月: 3,
        四月: 4,
        五月: 5,
        六月: 6,
        七月: 7,
        八月: 8,
        九月: 9,
        十月: 10,
        十一月: 11,
        腊月: 12
    }
    const dayMap: Record<string, number> = {
        初一: 1,
        初二: 2,
        初三: 3,
        初四: 4,
        初五: 5,
        初六: 6,
        初七: 7,
        初八: 8,
        初九: 9,
        初十: 10,
        十一: 11,
        十二: 12,
        十三: 13,
        十四: 14,
        十五: 15,
        十六: 16,
        十七: 17,
        十八: 18,
        十九: 19,
        二十: 20,
        廿一: 21,
        廿二: 22,
        廿三: 23,
        廿四: 24,
        廿五: 25,
        廿六: 26,
        廿七: 27,
        廿八: 28,
        廿九: 29,
        三十: 30
    }
    const hourMap: Record<string, number> = {
        子: 23,
        丑: 1,
        寅: 3,
        卯: 5,
        辰: 7,
        巳: 9,
        午: 11,
        未: 13,
        申: 15,
        酉: 17,
        戌: 19,
        亥: 21
    }

    const [full, , year, month, day, , hour] = match

    const currentDate = new Date()
    const currentLunarDate = SolarDay.fromYmd(currentDate.getFullYear(), currentDate.getMonth() + 1, currentDate.getDate()).getLunarDay()
    const solarDate = LunarHour.fromYmdHms(
        Number.parseInt(year) || currentLunarDate.getMonth().getYear().getYear(),
        monthMap[month] || 1,
        dayMap[day] || 1,
        hourMap[hour] || 1,
        0,
        0
    ).getSolarTime()
    const solarDay = solarDate.getDay()
    const solarMonth = solarDay.getMonth()
    const solarYear = solarMonth.getYear()

    return new Date(solarYear.getYear(), solarMonth.getMonth() - 1, solarDay.getDay(), solarDate.getHour())
}

/** 公历转换为农历 */
export function format2LunarDate(date: Date, format: string): string {
    const lunarHour = SolarTime.fromYmdHms(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes(),
        date.getSeconds()
    ).getLunarHour()
    const lunarDay = lunarHour.getDay()
    const lunarMonth = lunarDay.getMonth()
    const lunarYear = lunarMonth.getYear()

    const yearText = `${lunarYear.getYear()}年`
    const monthText = `${lunarMonth.getName().replace('十二', '腊')}`
    const dayText = `${lunarDay.getName()}`
    const hourText = `${lunarHour.getName()}`

    if (format === 'yyyy/MM/dd') {
        return `${yearText}${monthText}${dayText}`
    }

    if (format === 'HH:mm') {
        return `${hourText}`
    }

    return `${yearText}${monthText}${dayText}${hourText}`
}
