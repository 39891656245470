import { getAssetUrl } from '@lighthouse/assets'

import { companyTypeOptions, jobOptions, personAppsOptions, sourceOptions } from './constants'
import type { Field } from './types'

export const formSchema: Record<string, Field> = {
    useType: {
        name: 'useType',
        label: '',
        config: {
            top: 64,
            type: 'check',
            required: true
        }
    },
    username: {
        name: 'username',
        label: '如何称呼您？',
        config: {
            // label: '您的姓名',
            top: 44,
            type: 'text',
            required: true,
            placeholder: '请输入（必填）'
        }
    },
    job: {
        name: 'job',
        label: '工作职位',
        config: {
            label: '其他职业',
            top: 40,
            type: 'chip',
            required: true,
            options: jobOptions,
            justifyContent: 'flex-start'
        }
    },
    source: {
        name: 'source',
        label: '',
        config: {
            label: '其他',
            top: 40,
            type: 'chip',
            required: true,
            size: 'large',
            options: sourceOptions,
            justifyContent: 'center'
        }
    },
    company: {
        name: 'company',
        label: '企业名称',
        config: {
            top: 44,
            type: 'text',
            required: true,
            placeholder: '请输入'
        }
    },
    companyType: {
        name: 'companyType',
        label: '企业类型',
        config: {
            top: 36,
            type: 'chip',
            required: true,
            size: 'middle',
            options: companyTypeOptions
        }
    },
    personApps: {
        name: 'apps',
        label: '应用',
        config: {
            top: 24,
            type: 'card',
            options: personAppsOptions
        }
    },
    customApps: {
        name: 'customApps',
        label: '想搭建的应用不在列表内？请输入👇🏻👇🏻',
        config: {
            top: 24,
            type: 'text',
            placeholder: '请输入',
            labelConfig: {
                justifyContent: 'center',
                color: 'var(--color-gray-400)',
                size: 16,
                fontWeight: 400
            }
        }
    },
    companyApps: {
        name: 'apps',
        label: '应用',
        config: {
            top: 24,
            type: 'card',
            required: true,
            options: []
        }
    }
}

export const formStepPersonMeta: {
    id: string
    stepTitle?: string
    stepDescription?: string
    stepList?: string[]
    fields?: (keyof typeof formSchema)[]
    stepImage?: string
}[] = [
        {
            id: 'useType',
            stepTitle: '您将如何使用 ByeCode？',
            stepDescription: '单选，这将决定我们如何为您服务',
            fields: ['useType']
        },
        {
            id: 'info',
            stepTitle: '您的基本信息',
            fields: ['username', 'job']
        },
        {
            id: 'company',
            stepTitle: '您所在的企业是？',
            fields: ['company']
        },
        {
            id: 'source',
            stepTitle: '你从哪里了解到ByeCode？',
            fields: ['source']
        },
        {
            id: 'personApps',
            stepTitle: '您打算搭建什么应用？',
            fields: ['personApps', 'customApps']
        },
        {
            id: 'communicate',
            stepTitle: '扫码加入用户交流群',
            stepDescription: '从小白到专家，与ByeCode伙伴连接，分享想法，共同成长',
            stepList: ['抢先了解产品更新内容', '专人解答产品使用问题'],
            stepImage: getAssetUrl('common', 'company_wechat.png')
        }
    ]

export const formStepCompanyMeta: {
    stepTitle?: string
    stepDescription?: string
    stepList?: string[]
    stepImage?: string
    fields?: (keyof typeof formSchema)[]
}[] = [
        {
            stepTitle: '您将如何使用 ByeCode？',
            stepDescription: '单选，这将决定我们如何为您服务',
            fields: ['useType']
        },
        {
            stepTitle: '你所在的企业是？',
            fields: ['company', 'companyType']
        },
        {
            stepTitle: '你从哪里了解到ByeCode？',
            fields: ['source']
        },
        {
            stepTitle: '您打算搭建什么应用？',
            fields: ['companyApps']
        },
        {
            stepTitle: '扫码加入用户交流群',
            stepList: ['抢先了解产品更新内容', '专人解答产品使用问题'],
            stepImage: getAssetUrl('common', 'company_wechat.png')
        }
    ]
