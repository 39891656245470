import type { CollapseBlockConfig } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'


export const generateCollapseBlock = (): CollapseBlockConfig => {
    const title = {
        type: 'doc',
        content: [{
            content: [{ type: 'text', text: '标题' }],
            type: 'paragraph'
        }]
    }
    const introduction = {
        type: 'doc',
        content: [{
            content: [{ type: 'text', text: '描述' }],
            type: 'paragraph'
        }]
    }
    const initList = [{
        id: nanoid(32),
        title,
        introduction
    }, {
        id: nanoid(32),
        title,
        introduction
    }, {
        id: nanoid(32),
        title,
        introduction
    }]

    return {
        list: initList,
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            position: {
                type: 'relative'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                },
                overflow: 'visible'
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
