import { Flex, Group, IconFont, Input, SegmentedControl, Select, Switch, Text } from '@byecode/ui'
import type { DataSourceAbstract, GalleryViewOptions, ViewBreakPointProtocol, ViewOptions } from '@lighthouse/core'
import { ARRANGEMENT } from '@lighthouse/core'
import { getViewColumns, ListItem4ByecodeUi } from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import { useAtomValue } from 'jotai'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useToggle } from 'react-use'

import { useSetExtendsKeys } from '@/components/DesignSetting/hooks/useMouseEventDistance'
import { InheritLabel } from '@/components/InheritLabel'
import { useCurrentAppID } from '@/hooks/useApplication'

import { dataSourceAtom } from '../../atoms'
import FormSegmentedControl from '../../Common/FormSegmentedControl'
import { BLOCK_SIZE_OPTIONS, DIRECTION_OPTIONS, GALLERY_CARD_STYLES, LAYOUT_CONTROL } from '../../constants'
import { InnerDrawer } from '../../InnerDrawer'
import { GalleryLayoutConfigure } from './LayoutConfigure'

const COLS_OPTIONS = Array.from({ length: 6 }).map((_, i) => ({
    label: `${i + 1} 列`,
    value: (i + 1).toString()
}))

const galleryCardSizeOptions = BLOCK_SIZE_OPTIONS.filter(item => item.value !== 'xs')

/**
 * 画廊相关数据配置
 * @date 7/3/2023 - 3:38:23 PM
 *
 * @param {{ dataSource?: DataSourceAbstract }} { dataSource }
 * @returns {*}
 */
const GalleryCardConfigure = ({ dataSource }: { dataSource?: DataSourceAbstract }) => {
    const { schema = {}, viewOptions: { tableProps = [] } = {} } = dataSource ?? {}

    const { control, getValues } = useFormContext<ViewOptions>()

    const viewFieldSettings = getValues('viewFieldSettings')

    const columns = useMemo(
        () =>
            getViewColumns({
                tableProps,
                value: viewFieldSettings,
                schema
            }),
        [schema, tableProps, viewFieldSettings]
    )

    const [open, toggle] = useToggle(false)

    return (
        <>
            <Divider color="var(--color-gray-200)" />
            <Group label="卡片">
                <ListItem4ByecodeUi
                    style={{ margin: '0 -8px', padding: '0 8px' }}
                    justifyContent="space-between"
                    alignItems="center"
                    enableHover
                    onClick={() => toggle()}
                >
                    <Text>卡片内容排版</Text>
                    <Flex alignItems="center" gap={4}>
                        <Text color="var(--color-gray-500)">前往设置</Text>
                        <IconFont type="ArrowRightSmall" />
                    </Flex>
                </ListItem4ByecodeUi>

                <Controller
                    control={control}
                    name="style"
                    defaultValue="block"
                    render={({ field }) => <FormSegmentedControl {...field} label="卡片风格" data={GALLERY_CARD_STYLES} />}
                />

                {open && (
                    <InnerDrawer style={{ paddingBottom: 360 }}>
                        <GalleryLayoutConfigure columns={columns} onGoBack={() => toggle(false)} />
                    </InnerDrawer>
                )}
            </Group>
        </>
    )
}

const GalleryBlockSettings: React.FC = () => {
    const { control, watch, register, getValues } = useFormContext<
        ViewBreakPointProtocol & GalleryViewOptions & { showTitle?: boolean; title?: string }
    >()
    const appId = useCurrentAppID()
    const { handleSetBreakKey } = useSetExtendsKeys()

    const showTitle = watch('showTitle', getValues('showTitle'))
    const arrangement = watch('breakPoint.viewLayout.arrangement', getValues('breakPoint.viewLayout.arrangement'))
    const dataSource = useAtomValue(dataSourceAtom)

    return (
        <>
            {/* 基础 */}
            <Group label="基础">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>显示视图标题</Text>
                    <Controller
                        control={control}
                        name="showTitle"
                        render={({ field: { value, onChange, ...restField } }) => (
                            <Switch checked={value} onChange={e => onChange(e.target.checked)} {...restField} />
                        )}
                    />
                </ListItem4ByecodeUi>
                {showTitle ? (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>视图标题</Text>
                        <Input style={{ width: 180 }} {...register('title')} />
                    </ListItem4ByecodeUi>
                ) : null}
            </Group>

            <Divider color="var(--color-gray-200)" />

            {/* 布局 */}
            <Group label="布局">
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="方向" name="breakPoint.viewLayout.direction" />
                    <Controller
                        control={control}
                        name="breakPoint.viewLayout.direction"
                        render={({ field }) => (
                            <SegmentedControl
                                data={DIRECTION_OPTIONS}
                                fullWidth
                                style={{ width: 180 }}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey('breakPoint.viewLayout.direction')
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="排列方式" name="breakPoint.viewLayout.arrangement" />
                    <Controller
                        control={control}
                        name="breakPoint.viewLayout.arrangement"
                        render={({ field }) => (
                            <SegmentedControl
                                style={{ width: 180 }}
                                fullWidth
                                data={LAYOUT_CONTROL}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey('breakPoint.viewLayout.arrangement')
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>

                {arrangement === ARRANGEMENT.fixed && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <InheritLabel label="每行显示" name="breakPoint.viewLayout.cols" />
                        <Controller
                            name="breakPoint.viewLayout.cols"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <Select
                                    value={value?.toString()}
                                    style={{ width: 180 }}
                                    searchable={false}
                                    options={COLS_OPTIONS}
                                    onChange={val => {
                                        onChange(Number(val))
                                        handleSetBreakKey('breakPoint.viewLayout.cols')
                                    }}
                                />
                            )}
                        />
                    </ListItem4ByecodeUi>
                )}
                {arrangement === ARRANGEMENT.fixed ? null : (
                    <ListItem4ByecodeUi key="size" justifyContent="space-between" alignItems="center">
                        <InheritLabel label="尺寸" name="breakPoint.viewLayout.size" />
                        <Controller
                            control={control}
                            name="breakPoint.viewLayout.size"
                            shouldUnregister={false}
                            render={({ field }) => (
                                <SegmentedControl
                                    size="md"
                                    fullWidth
                                    data={galleryCardSizeOptions}
                                    style={{ width: 180 }}
                                    value={field.value}
                                    onChange={val => {
                                        field.onChange(val)
                                        handleSetBreakKey('breakPoint.viewLayout.size')
                                    }}
                                />
                            )}
                        />
                    </ListItem4ByecodeUi>
                )}
            </Group>
            <GalleryCardConfigure dataSource={dataSource} />
        </>
    )
}

export default GalleryBlockSettings
