import type { FormResult } from '@lighthouse/block'
import { collectBlockAndChildren, getPureBlockLayoutTree } from '@lighthouse/block'
import type { FilterFormType } from '@lighthouse/core'
import {
    type BlockAbstract,
    type FilterCommonCondition,
    type GroupRecordCount,
    type PageAbstract,
    type PaginationProtocol,
    DataSourceType
} from '@lighthouse/core'
import type { AppDepartment, ChartExportParams, PageContentRes, PureBlockAbstract, ViewPrintWithTemplateParams } from '@lighthouse/shared'
import { getPureBlockProperty, transformBlockRes2Blocks } from '@lighthouse/shared'
import type { AxiosRequestConfig, AxiosResponse } from 'axios'
import { multiply, subtract } from 'rambda'

import type { CreatePageMode } from '@/atoms/page/types'
import type { HttpResp } from '@/http'
import http from '@/http'

import { JsonContentType } from './constant'
import type {
    ChartData,
    CreateBlockPayload,
    GetDsGraphViewPayload,
    GetRecordPayload,
    GroupRecordCountPayload,
    PageRecordRes,
    PageRecordResult,
    SubmitFormPayload,
    UpdateBlockPayload,
    UpdatePagePayload,
    ViewDataRes,
    ViewDataResult,
    ViewSettingParams,
    WechatShareResult
} from './types'

// ##################################################################### //
// ############################ 页面相关接口 ############################ //
// ##################################################################### //

type PageListRes = (Omit<PageAbstract, 'departments'> & {
    departments: (Omit<AppDepartment, 'departmentName'> & { name: string })[]
})[]
/**
 * 获取页面列表
 */
export async function getPages(): Promise<PageAbstract[]> {
    const res = await http.get<unknown, HttpResp<PageListRes>>('api/v1/page/listPage')
    return res.content.map(page => ({
        ...page,
        departments:
            page?.departments?.map(department => ({
                departmentId: department.id,
                name: (department as AppDepartment & { name: string }).name
            })) ?? []
    }))
}

/**
 * 添加页面
 * @param data
 * @returns
 */
export async function addPage(
    data: Partial<PageAbstract & { createMode?: CreatePageMode; viewId?: string; isSyncComponent?: boolean; lang: string }>
): Promise<string> {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/page/createPage', data, { headers: JsonContentType })
    return res.content
}

/**
 * 移动页面
 * @param {string} id
 * @param {string} before
 * @param {string} after
 */
export async function movePage(payload: { id: string; before: string; after: string }) {
    const res = await http.post<unknown, HttpResp>('api/v1/page/movePage', payload)
    return res.success
}

/**
 * 更新页面
 * @param {UpdatePagePayload} data
 */
export async function updatePage(data: UpdatePagePayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp>('api/v1/page/updatePage', data, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * 复制页面
 * @param {UpdatePagePayload} data
 */
export async function clonePage(pageId: string, lang: string): Promise<string> {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/page/copyPage', {
        pageId,
        lang
    })
    return res.content
}

/**
 * 删除页面
 * @param {string} id
 */
export async function deletePage(id: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp>('api/v1/page/deletePage', {
        params: {
            id
        }
    })
    return res.success
}

/**
 * 删除页面登录可见的角色
 * @param {String} pageId
 * @param {String} id groupId or departmentId
 * @param {String} type 'group' | 'department'
 */
export async function deleteRole(pageId: string, id: string, type: 'role' | 'department') {
    const res = await http.delete<unknown, HttpResp>('api/v1/page/deleteRole', {
        params: {
            pageId,
            id,
            type
        }
    })
    return res.success
}

/**
 * 添加页面登录可见的角色
 * @param {String} pageId
 * @param {String[]} roleIds
 * @param {String[]} departmentIds
 */
export async function addRoleToPage(pageId: string, roleIds: string[], departmentIds: string[]) {
    const res = await http.put<unknown, HttpResp>(
        'api/v1/page/addRoles',
        {
            pageId,
            roleIds,
            departmentIds
        },
        {
            headers: JsonContentType
        }
    )
    return res.success
}

/**
 * 获取页面的内容
 * @param {AxiosRequestConfig} config
 */
export async function getPageContent(config: AxiosRequestConfig) {
    const res = await http.get<unknown, HttpResp<PageContentRes>>('api/v1/page/getPage', config)
    if (!res.success) {
        return
    }
    const { blockNodes, pageConfig, ...rest } = res.content

    const transformBlocks = transformBlockRes2Blocks(blockNodes)

    return {
        ...pageConfig,
        ...rest,
        blocks: transformBlocks
    }
}
/**
 * 获取指定的行数据
 * @param {GetRecordPayload} payload
 */
export async function getRecord(payload: GetRecordPayload): Promise<PageRecordResult> {
    const { appId, envId, dsId } = payload
    const res = await http.get<unknown, HttpResp<PageRecordRes>>('api/v1/page/getRecord', {
        params: payload
    })
    const { datasource, record } = res.content
    return {
        datasource: {
            appId,
            envId,
            ...datasource
        },
        record: {
            appId,
            envId,
            dsId,
            ...record
        }
    }
}

// ##################################################################### //
// ############################ block相关接口 ############################ //
// ##################################################################### //

/**
 * 新增block
 * @param {CreateBlockPayload} data
 */
export async function createBlocks(data: CreateBlockPayload) {
    const pureBlockProperties = data.addedBlocks.reduce<PureBlockAbstract[]>(
        (total, curr) => [...total, ...collectBlockAndChildren(curr).map(getPureBlockProperty)],
        []
    )
    const pureBlockLayouts = data.allBlocks.map(getPureBlockLayoutTree)

    const res = await http.post<unknown, HttpResp>(
        'api/v1/page/createBlocks',
        {
            skipInspection: data.skipInspection,
            pageId: data.pageId,
            createBlocks: pureBlockProperties.map(item => ({
                ...item,
                config: {
                    [item.type]: item.config
                }
            })),
            nodes: pureBlockLayouts
        },
        {
            headers: JsonContentType
        }
    )
    return res.success
}
/**
 * 删除block
 * @param {String} id
 * @param {String} parentId
 */
export async function deleteBlock(data: { removedBlocks: BlockAbstract[]; blocks: BlockAbstract[]; pageId: string }) {
    const ids = data.removedBlocks.reduce<string[]>((total, curr) => [...total, ...collectBlockAndChildren(curr).map(item => item.id)], [])
    const res = await http.delete<unknown, HttpResp>('api/v1/page/deleteBlock', {
        data: { blockIds: ids, nodes: data.blocks.map(getPureBlockLayoutTree), pageId: data.pageId },
        headers: JsonContentType
    })
    return res.success
}

/**
 * 更新block
 * @param {UpdateBlockPayload} data
 * @param {String} parentId
 */
export async function updateBlock(data: UpdateBlockPayload) {
    const pureBlockProperties = data.updatedBlocks?.map(getPureBlockProperty)
    const pureBlockLayouts = data.allBlocks?.map(getPureBlockLayoutTree)

    const res = await http.post<unknown, HttpResp>(
        'api/v1/page/updateBlock',
        {
            pageId: data.pageId,
            nodes: pureBlockLayouts,
            updateBlocks: pureBlockProperties?.map(item => ({
                ...item,
                config: {
                    [item.type]: item.config
                }
            }))
        },
        {
            headers: JsonContentType
        }
    )
    return res.success
}

// ##################################################################### //
// ############################# 视图数据接口 ########################### //
// ##################################################################### //

/**
 * 获取视图各个分组的记录总数
 * @param {GroupRecordCountPayload} params
 * @return {*} Promise<boolean>
 */
export async function getGroupRecordCount(params: GroupRecordCountPayload): Promise<GroupRecordCount[] | undefined> {
    const res = await http.post<unknown, HttpResp<GroupRecordCount[]> | undefined>('/api/v1/view/getGroupRecordCount', params, {
        headers: JsonContentType,
        signal: params?.abort?.signal
    })
    return res?.content
}

/**
 * 获取blocks下的数据源
 * @param {string} blockId
 * @return {*} Promise<boolean>
 */
export async function getViewByBlock(payload: {
    appId: string
    envId: string
    viewId: string
    pagination: PaginationProtocol['pagination']
    params?: ViewSettingParams
    search?: string
    abort?: AbortController
    isSyncComponent?: boolean
}): Promise<ViewDataResult | undefined> {
    const { appId, envId, viewId, pagination, params, search, abort, isSyncComponent } = payload
    const { currentPage, pageSize } = pagination
    const res = await http.post<unknown, HttpResp<ViewDataRes> | undefined>(
        '/api/v1/view/getView',
        {
            viewId,
            currentPage,
            pageSize,
            search,
            isSyncComponent,
            ...params
        },
        {
            headers: JsonContentType,
            signal: abort?.signal
        }
    )
    if (!res) {
        return
    }
    const { datasource, records, config } = res.content
    const {
        view: {
            pagination: { currentPage: pageNum = 1, pageSize: size = 50 }
        }
    } = config
    const { type, id: dsId } = datasource
    const dsIncludesAppIdAndEnvId = {
        ...datasource,
        appId,
        envId
    }
    if (type === DataSourceType.joinDataSource) {
        const startIndex = multiply(subtract(pageNum, 1), size)
        const newRecord = records.map((item, index) => ({
            ...item,
            appId,
            envId,
            dsId,
            id: `${item.id}__${viewId}__${startIndex + index}`
        }))
        return {
            datasource: dsIncludesAppIdAndEnvId,
            config,
            records: newRecord
        }
    }

    return {
        datasource: dsIncludesAppIdAndEnvId,
        config,
        records: records.map(record => ({
            appId,
            dsId,
            envId,
            ...record
        }))
    }
}

/**
 * 获取blocks下的总行数
 * @param {string} viewId
 * @param {string} search
 * @return {*}  {Promise<number>}
 */

export async function getViewCount(payload: {
    viewId: string
    isSyncComponent?: boolean
    params?: ViewSettingParams
    search?: string
    abort?: AbortController
}): Promise<number | undefined> {
    const { viewId, isSyncComponent, search, params, abort } = payload
    const res = await http.post<unknown, HttpResp<number> | undefined>(
        '/api/v1/view/viewCount',
        { viewId, isSyncComponent, search, ...params },
        {
            headers: JsonContentType,
            signal: abort?.signal
        }
    )
    return res?.content
}

/**
 * 获取图表数据
 * @param {string} viewId
 * @param {string} versionId
 * @return {*}  {Promise<number>}
 */
export async function getDsGraphView(payload: GetDsGraphViewPayload) {
    const res = await http.post<unknown, HttpResp<ChartData>>('api/v1/view/getDsGraphView', payload, {
        headers: JsonContentType
    })
    return res.content
}

/**
 * 图表导出数据
 * @param params
 * @returns
 */
export async function exportChart(params: ChartExportParams) {
    // eslint-disable-next-line no-return-await
    return await http.post<unknown, AxiosResponse>('api/v1/view/exportDsGraphView', params, {
        headers: JsonContentType,
        responseType: 'blob'
    })
}

/**
 * 获取视图打印模板列表
 * @param dsId
 * @returns
 */
export async function getPrintTemplateList(dsId: string) {
    const res = await http.get<unknown, HttpResp<{ templateId: string; name: string; dsId: string; url: string }[]>>(
        'api/v1/view/getTemplateList',
        {
            params: {
                dsId
            }
        }
    )
    return res.content
}

/**
 * 删除视图打印模板
 * @param dsId
 * @returns
 */
export async function deletePrintTemplate(params: { templateId: string; dsId: string }) {
    const res = await http.get<unknown, HttpResp>('api/v1/ds/deleteTemplate', {
        params
    })
    return res.success
}

/**
 * 按模板导出/打印视图
 * @param params
 * @returns
 */
export async function printByTemplate(params: ViewPrintWithTemplateParams) {
    // eslint-disable-next-line no-return-await
    return await http.post<unknown, AxiosResponse>('api/v1/ds/exportTemplate', params, {
        headers: JsonContentType,
        responseType: 'blob'
    })
}

/** 提交表单 */
export async function submitForm(payload: SubmitFormPayload): Promise<FormResult> {
    const res = await http.post<unknown, HttpResp<FormResult>>(`/api/v1/page/submitForm`, payload, {
        headers: JsonContentType
    })
    const { appId, envId, dsId } = payload
    const { record, repeatFieldIds, failureMessage, subFormRepeat } = res?.content ?? {}
    return {
        record: record
            ? {
                  ...record,
                  appId,
                  envId,
                  dsId
              }
            : undefined,
        repeatFieldIds,
        failureMessage,
        subFormRepeat
    }
}

/**
 *
 * @param params
 * @returns
 */
export async function getWechatShareData(params: {
    pageId: string
    dsId?: string
    currentRecordId?: string
    parentRecordId?: string
}): Promise<WechatShareResult> {
    const res = await http.get<unknown, HttpResp<WechatShareResult>>('api/v1/page/getPageOutline', { params })
    return res.content
}

/**
 * 添加页面到seo的队列
 * @param data
 * @returns
 */
export async function autoSubmission(data: { id: string; url: string }) {
    const res = await http.put<unknown, HttpResp<boolean>>('api/v1/page/autoSubmission', data)
    return res.success
}

// /** 获取block的一些预设配置 */
// export async function getBlockPresetList() {
//     const res = await http.get<unknown, HttpResp<TextAdvanceOption[]>>('api/v1/app/blockPresetList')
//     return res.content
// }

// /** 添加block预设 */
// export async function createBlockPreset(data: Omit<TextAdvanceOption, 'id'>) {
//     const res = await http.put<unknown, HttpResp<string>>('api/v1/app/createBlockPreset', data, { headers: JsonContentType })
//     return res.content
// }
// /** 更新block预设 */
// export async function updateBlockPreset(data: Partial<TextAdvanceOption> & { id: string }) {
//     const res = await http.post<unknown, HttpResp<boolean>>('api/v1/app/updateBlockPreset', data, { headers: JsonContentType })
//     return res.success
// }
// /** 删除block预设 */
// export async function deleteBlockPreset(id: string) {
//     const res = await http.delete<unknown, HttpResp<boolean>>('api/v1/app/deleteBlockPreset', { params: { id } })
//     return res.success
// }
