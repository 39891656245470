/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Toast } from '@byecode/ui'
import { collectBlockAndChildren } from '@lighthouse/block'
import type { BaseBreakPointConfigProtocol, BlockAbstract, ContainerBlockAbstract, PageType } from '@lighthouse/core'
import { BlockType, DIRECTION, PAGE_TYPE } from '@lighthouse/core'
import type { CollisionArea, ResizeEvent, ResizeRestrict } from '@lighthouse/shared'
import {
    ApplicationPreviewEnum,
    findBlockById,
    findNormalOrSyncBlock,
    findParentBlockById,
    getBlockChildren,
    getCurrentBlockChildren,
    initBlockRuntimeState,
    isCustomViewBlock,
    isFloatBoxBlock,
    isFormContainerBlock,
    mergeSingleBreakPointConfigure
} from '@lighthouse/shared'
import { current, original } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import { clone, findIndex, mergeDeepRight } from 'rambda'

import { previewTypeAtom, syncComponentsAtom } from '@/atoms/application/state'
import { createBlockAtom, removeBlockAtom } from '@/atoms/page/action'
import {
    blockCreatingListAtom,
    blocksAtom,
    outsideDraggingNode,
    pageAtomFamily,
    pageBlocksAtom,
    pageStackAtom,
    pageStackAtomFamily
} from '@/atoms/page/state'
import type { NodeIdWithScope } from '@/atoms/page/types'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import { padBlocksByFormContainer } from '@/constants/Block/generate/field'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import {
    copyBlock,
    includeNotAllowedBlock,
    isActiveInOver,
    isCustomChildren,
    isFormContainerChildren,
    removeBlockById
} from '@/hooks/layoutEngine/utils'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useBlockActions } from '@/hooks/useBlock'
import { useDataSourceList } from '@/hooks/useDataSource'
import * as srv from '@/services'
import { BLOCK_UPDATE_ACTION, NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

type CheckNotAllowedOption = {
    fromBlock: BlockAbstract
    toBlock: BlockAbstract
    over: CollisionArea
    blocks: BlockAbstract[]
    pageType?: PageType
}
function checkNotAllowed({ fromBlock, toBlock, over, blocks, pageType }: CheckNotAllowedOption) {
    // 表单容器、视图及图表block禁止插入到自定义视图内
    if (
        includeNotAllowedBlock(fromBlock, [BlockType.formContainer, BlockType.view, BlockType.chart]) &&
        ((over.position === 'in' && toBlock && isCustomViewBlock(toBlock)) ||
            isCustomChildren(over.isVirtual ? over.parentId : over.id, blocks))
    ) {
        Toast.error('视图、图表、表单容器，不允许拖入自定义视图')
        return false
    }
    // 表单容器、视图及图表block禁止插入到弹出窗口内
    if (
        includeNotAllowedBlock(fromBlock, [BlockType.formContainer, BlockType.view, BlockType.chart]) &&
        over.position === 'in' &&
        toBlock &&
        isFloatBoxBlock(toBlock)
    ) {
        Toast.error('视图、图表、表单容器，不允许拖入弹出窗口')
        return
    }

    // 子表单只能拖入到表单页面的表单容器中
    if (
        includeNotAllowedBlock(fromBlock, [BlockType.subForm]) &&
        (pageType !== PAGE_TYPE.creator ||
            (over.position === 'in' && toBlock && !isFormContainerBlock(toBlock)) ||
            !isFormContainerChildren(over.isVirtual ? over.parentId : over.id, blocks))
    ) {
        Toast.error('子表单只能拖入到表单页面的表单容器中')
        return false
    }

    return true
}

type CheckSyncOption = {
    fromBlock: BlockAbstract
    toBlock: BlockAbstract
    over: CollisionArea
    fromScope?: string
}
function checkSync({ fromBlock, toBlock, over, fromScope }: CheckSyncOption) {
    if (fromBlock.isMasterSynchronous) {
        if (toBlock.isMasterSynchronous && over.position === 'in') {
            Toast.error('同步组件无法插入到同步组件内')
            return false
        }
        if (toBlock.isLeafSynchronous) {
            Toast.error('同步组件无法插入到同步组件内')
            return false
        }
    }

    // 如果active是同步组件的叶子节点，则找到对应的scope，如果scope和over的scope一致则允许
    if (fromBlock.isLeafSynchronous) {
        if (toBlock.isMasterSynchronous && over.position === 'in' && fromScope !== over.id) {
            Toast.error('同步组件无法插入到同步组件内')
            return false
        }
        if (toBlock.isLeafSynchronous && fromScope !== over.scope) {
            Toast.error('同步组件无法插入到同步组件内')
            return false
        }
    }

    const children = getBlockChildren(fromBlock)
    if (children) {
        const scope = fromBlock.synchronousId ? fromBlock.id : fromScope
        const hasError = children.some(child => !checkSync({ fromBlock: child, toBlock, over, fromScope: scope }))
        if (hasError) {
            return false
        }
    }

    return true
}

type GetActualNewBlockOption = {
    addingBlocksData: BlockAbstract | null
    syncComponents: BlockAbstract[]
    blocks: BlockAbstract[]
    activeId: NodeIdWithScope | null
}
/** 获取渲染时的实际拖起的block结构 */
function getActualNewBlock({ addingBlocksData, syncComponents, blocks, activeId }: GetActualNewBlockOption) {
    if (addingBlocksData) {
        if (addingBlocksData.synchronousId) {
            return findBlockById(addingBlocksData.synchronousId, syncComponents)
        }

        return addingBlocksData
    }

    if (activeId) {
        return findNormalOrSyncBlock(activeId, blocks, syncComponents)
    }
}

type UndoRedoBlocksInfo<T extends BlockAbstract = BlockAbstract> = {
    createdBlock?: BlockAbstract | undefined
    removedBlocks?: BlockAbstract[] | undefined
    prevBlocks: T[] | undefined
    nextBlocks: T[] | undefined
}

export const useLayoutEngineEvents = () => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSources = useDataSourceList(appId, envId)

    const onDragEnd = useAtomCallback<void, [NodeIdWithScope | null, CollisionArea | null]>(async (get, set, activeId, over) => {
        if (!over) {
            return
        }

        const page = get(pageAtomFamily(pageId))
        const stack = get(pageStackAtomFamily({ rootPageId, stackId }))
        const blocks = get(blocksAtom)[pageId] ?? []
        const syncComponents = get(syncComponentsAtom)

        const addingBlocksData = get(outsideDraggingNode)

        let newBlock: BlockAbstract | undefined

        // 如果是从外部添加的，走创建
        if (addingBlocksData) {
            const dataSource = dataSources.find(item => item.id === page?.dsId)

            newBlock = clone(addingBlocksData)

            // 如果创建的是表单容器, 需要额外填充字段block
            if (newBlock.type === BlockType.formContainer && dataSource) {
                const fieldBlocks = padBlocksByFormContainer(dataSource, dataSources)

                newBlock.children = fieldBlocks
            }
        } else if (activeId) {
            const readonlyBlock = findBlockById(activeId.id, blocks)
            newBlock = readonlyBlock && clone(readonlyBlock)
        }

        const actualNewBlock = getActualNewBlock({ addingBlocksData, activeId, blocks, syncComponents })
        if (!actualNewBlock) {
            return
        }
        const actualOverBlock = findNormalOrSyncBlock(
            { id: over.isVirtual ? over.parentId : over.id, scope: over.scope },
            blocks,
            syncComponents
        )

        // ////////////////////////////// 检测位置合法性 start //////////////////////////////
        if (
            actualOverBlock &&
            !checkNotAllowed({ fromBlock: actualNewBlock, toBlock: actualOverBlock, over, blocks, pageType: page?.type })
        ) {
            return
        }

        if (actualOverBlock && !checkSync({ fromBlock: actualNewBlock, toBlock: actualOverBlock, over, fromScope: activeId?.scope })) {
            return
        }
        // ////////////////////////////// 检测位置合法性 end //////////////////////////////

        const addedBlocksIds = addingBlocksData && newBlock ? collectBlockAndChildren(newBlock).map(item => item.id) : []
        if (addingBlocksData) {
            set(blockCreatingListAtom, s => [...s, ...addedBlocksIds])
        }

        // 拖拽插入逻辑处理：
        // 1.active -> normal block, over -> normal block
        // 2.active -> normal block, over -> sync block
        // 3.active -> sync block, over -> normal block
        // 4.active -> sync block, over -> sync block

        const createBlockUndoRedoInfo: UndoRedoBlocksInfo = {
            createdBlock: undefined,
            prevBlocks: undefined,
            nextBlocks: undefined
        }
        const removeBlockUndoRedoInfo: UndoRedoBlocksInfo = {
            removedBlocks: undefined,
            prevBlocks: undefined,
            nextBlocks: undefined
        }
        const blocksUndoRedoInfo: UndoRedoBlocksInfo = {
            prevBlocks: undefined,
            nextBlocks: undefined
        }
        const syncComponentsUndoRedoInfo: UndoRedoBlocksInfo<ContainerBlockAbstract> = {
            createdBlock: undefined,
            prevBlocks: undefined,
            nextBlocks: undefined
        }

        const isOverSyncComponents =
            !!actualOverBlock && !!((actualOverBlock.isMasterSynchronous && over.position === 'in') || actualOverBlock.isLeafSynchronous)
        // active是同步组件下的情况
        if (actualNewBlock.isLeafSynchronous) {
            set(syncComponentsAtom, draft => {
                syncComponentsUndoRedoInfo.prevBlocks = original(draft)
                if (!addingBlocksData) {
                    removeBlockById(actualNewBlock.id)(draft)
                }

                // over是同步组件的情况
                if (isOverSyncComponents) {
                    if (over.position === 'in') {
                        const draftOverBlock = findBlockById(actualOverBlock.id, draft)
                        if (!draftOverBlock) {
                            return
                        }

                        const children = getCurrentBlockChildren(draftOverBlock, {
                            blockRuntimeState: stack?.blockRuntimeState,
                            uniqueContainerId: draftOverBlock.isMasterSynchronous ? over.scope : `${over.scope}@${draftOverBlock.id}`
                        })
                        if (!children) {
                            return
                        }

                        children.push(actualNewBlock)
                    } else {
                        const draftOverParentBlock = findParentBlockById(over.id, draft)
                        if (draftOverParentBlock) {
                            const children = getCurrentBlockChildren(draftOverParentBlock, {
                                blockRuntimeState: stack?.blockRuntimeState,
                                uniqueContainerId: draftOverParentBlock.isMasterSynchronous
                                    ? over.scope
                                    : `${over.scope}@${draftOverParentBlock.id}`
                            })
                            if (!children) {
                                return
                            }

                            const index = children.findIndex(item => item.id === actualOverBlock.id)
                            if (index === -1) {
                                return
                            }

                            children.splice(index + (over.position === 'before' ? 0 : 1), 0, actualNewBlock)
                        }
                    }
                }

                syncComponentsUndoRedoInfo.nextBlocks = current(draft)
            })

            // over是普通组件的情况
            if (!isOverSyncComponents) {
                set(blocksAtom, draft => {
                    const draftBlocks = draft[pageId]
                    if (!draftBlocks) {
                        return
                    }

                    createBlockUndoRedoInfo.prevBlocks = original(draftBlocks)
                    blocksUndoRedoInfo.prevBlocks = original(draftBlocks)

                    newBlock = copyBlock([actualNewBlock])[0]
                    newBlock.isLeafSynchronous = undefined

                    if (over.id === 'root') {
                        draftBlocks.push(newBlock)
                    } else if (over.position === 'in') {
                        const draftOverBlock = findBlockById(over.isVirtual ? over.parentId : over.id, draftBlocks)
                        if (!draftOverBlock) {
                            return
                        }

                        const children = getCurrentBlockChildren(draftOverBlock, { blockRuntimeState: stack?.blockRuntimeState })
                        if (!children) {
                            return
                        }
                        children.push(newBlock)
                    } else {
                        const draftOverParentBlock = findParentBlockById(over.id, draftBlocks)

                        if (draftOverParentBlock) {
                            const children = getCurrentBlockChildren(draftOverParentBlock, { blockRuntimeState: stack?.blockRuntimeState })
                            if (!children) {
                                return
                            }

                            const index = children.findIndex(item => item.id === over.id)
                            if (index === -1) {
                                return
                            }

                            children.splice(index + (over.position === 'before' ? 0 : 1), 0, newBlock)
                        } else if (over.parentId === 'root') {
                            const index = draftBlocks.findIndex(item => item.id === over.id)
                            if (index === -1) {
                                return
                            }
                            draftBlocks.splice(index + (over.position === 'before' ? 0 : 1), 0, newBlock)
                        }
                    }

                    createBlockUndoRedoInfo.createdBlock = newBlock
                    createBlockUndoRedoInfo.nextBlocks = current(draftBlocks)
                    blocksUndoRedoInfo.nextBlocks = current(draftBlocks)
                })
            }
        }

        // active是普通组件的情况
        else if (!actualNewBlock.isLeafSynchronous && newBlock) {
            set(blocksAtom, draft => {
                const draftBlocks = draft[pageId]
                if (!draftBlocks) {
                    return
                }

                blocksUndoRedoInfo.prevBlocks = original(draftBlocks)

                if (!addingBlocksData) {
                    removeBlockUndoRedoInfo.prevBlocks = original(draftBlocks)
                    removeBlockUndoRedoInfo.removedBlocks = removeBlockById(newBlock!.id)(draftBlocks)?.map(current)
                }

                if (isOverSyncComponents) {
                    // 如果从普通组件拖往同步组件，则会删除普通block
                    if (!addingBlocksData) {
                        removeBlockUndoRedoInfo.nextBlocks = current(draftBlocks)
                    }
                } else {
                    // over是普通组件的情况

                    // 不是新增，并且是普通组件之间的拖拽，则是更新普通组件的结构
                    if (addingBlocksData) {
                        createBlockUndoRedoInfo.prevBlocks = current(draftBlocks)
                    }

                    if (over.id === 'root') {
                        draftBlocks.push(newBlock!)
                    } else if (over.position === 'in') {
                        const draftOverBlock = findBlockById(over.isVirtual ? over.parentId : over.id, draftBlocks)
                        if (!draftOverBlock) {
                            return
                        }

                        const children = getCurrentBlockChildren(draftOverBlock, { blockRuntimeState: stack?.blockRuntimeState })
                        if (!children) {
                            return
                        }

                        children.push(newBlock!)
                    } else {
                        const draftOverParentBlock = findParentBlockById(over.id, draftBlocks)

                        if (draftOverParentBlock) {
                            const children = getCurrentBlockChildren(draftOverParentBlock, { blockRuntimeState: stack?.blockRuntimeState })
                            if (!children) {
                                return
                            }

                            const index = children.findIndex(item => item.id === over.id)
                            if (index === -1) {
                                return
                            }

                            children.splice(index + (over.position === 'before' ? 0 : 1), 0, newBlock!)
                        } else if (over.parentId === 'root') {
                            const index = draftBlocks.findIndex(item => item.id === over.id)
                            if (index === -1) {
                                return
                            }

                            draftBlocks.splice(index + (over.position === 'before' ? 0 : 1), 0, newBlock!)
                        }
                    }

                    if (addingBlocksData) {
                        createBlockUndoRedoInfo.createdBlock = addingBlocksData ? newBlock : undefined
                        createBlockUndoRedoInfo.nextBlocks = current(draftBlocks)
                    }
                }

                blocksUndoRedoInfo.nextBlocks = current(draftBlocks)
            })

            // over是同步组件的情况
            if (isOverSyncComponents) {
                set(syncComponentsAtom, draft => {
                    syncComponentsUndoRedoInfo.prevBlocks = original(draft)
                    if (over.position === 'in') {
                        const draftOverBlock = findBlockById(actualOverBlock.id, draft)
                        if (!draftOverBlock) {
                            return
                        }

                        const children = getCurrentBlockChildren(draftOverBlock, {
                            blockRuntimeState: stack?.blockRuntimeState,
                            uniqueContainerId: draftOverBlock.isMasterSynchronous ? over.id : `${over.scope}@${draftOverBlock.id}`
                        })
                        if (!children) {
                            return
                        }

                        syncComponentsUndoRedoInfo.createdBlock = newBlock!
                        newBlock!.isLeafSynchronous = true
                        children.push(newBlock!)
                    } else {
                        const draftOverParentBlock = findParentBlockById(over.id, draft)
                        if (draftOverParentBlock) {
                            const children = getCurrentBlockChildren(draftOverParentBlock, {
                                blockRuntimeState: stack?.blockRuntimeState,
                                uniqueContainerId: draftOverParentBlock.isMasterSynchronous
                                    ? over.scope
                                    : `${over.scope}@${draftOverParentBlock.id}`
                            })
                            if (!children) {
                                return
                            }

                            const index = children.findIndex(item => item.id === actualOverBlock.id)
                            if (index === -1) {
                                return
                            }

                            syncComponentsUndoRedoInfo.createdBlock = newBlock!

                            newBlock!.isLeafSynchronous = true

                            children.splice(index + (over.position === 'before' ? 0 : 1), 0, newBlock!)
                        }
                    }

                    syncComponentsUndoRedoInfo.nextBlocks = current(draft)
                })
            }
        }
        if (syncComponentsUndoRedoInfo.nextBlocks) {
            // 更新同步block时，需要重新更新一下组件runtime state
            set(pageStackAtom, draftStack => {
                const stack = equalPageStack({ rootPageId, stackId })(draftStack)
                if (stack) {
                    initBlockRuntimeState(stack, {
                        blocks: get(pageBlocksAtom(pageId)),
                        syncComponents: syncComponentsUndoRedoInfo.nextBlocks!
                    })
                    stack.state.asideType = AsideType.BLOCK

                    const id = newBlock ? newBlock.id : activeId?.id || ''
                    const scope = actualOverBlock?.isMasterSynchronous ? over.id : over.scope
                    stack.state.selectedNodes = [{ id, scope }]
                }
            })

            if (syncComponentsUndoRedoInfo.createdBlock) {
                await srv.createSyncBlock({
                    createBlocks: [syncComponentsUndoRedoInfo.createdBlock],
                    blocks: syncComponentsUndoRedoInfo.nextBlocks
                })
                set(blockCreatingListAtom, s => s.filter(id => !addedBlocksIds.includes(id)))
            } else {
                srv.updateSyncComponents(syncComponentsUndoRedoInfo.nextBlocks)
            }
        }

        if (createBlockUndoRedoInfo.createdBlock && createBlockUndoRedoInfo.nextBlocks) {
            await set(createBlockAtom, {
                rootPageId,
                pageId,
                stackId,
                createdBlocks: [createBlockUndoRedoInfo.createdBlock],
                blocks: createBlockUndoRedoInfo.nextBlocks,
                scope: over.scope
            })

            set(blockCreatingListAtom, s => s.filter(id => !addedBlocksIds.includes(id)))
        }

        if (removeBlockUndoRedoInfo.removedBlocks && removeBlockUndoRedoInfo.nextBlocks) {
            await set(removeBlockAtom, {
                rootPageId,
                pageId,
                stackId,
                removedBlocks: removeBlockUndoRedoInfo.removedBlocks,
                blocks: removeBlockUndoRedoInfo.nextBlocks,
                clearSelected: false
            })
        }

        if (!addingBlocksData && blocksUndoRedoInfo.nextBlocks) {
            srv.updateBlock({ pageId, allBlocks: blocksUndoRedoInfo.nextBlocks })
        }

        pageUndoRedoController.add({
            action: NODE_UPDATE_ACTION,
            payload: {
                created:
                    createBlockUndoRedoInfo.createdBlock && createBlockUndoRedoInfo.prevBlocks && createBlockUndoRedoInfo.nextBlocks
                        ? {
                              createdBlocks: [createBlockUndoRedoInfo.createdBlock],
                              prevBlocks: createBlockUndoRedoInfo.prevBlocks,
                              nextBlocks: createBlockUndoRedoInfo.nextBlocks
                          }
                        : undefined,
                removed:
                    removeBlockUndoRedoInfo.removedBlocks && removeBlockUndoRedoInfo.prevBlocks && removeBlockUndoRedoInfo.nextBlocks
                        ? {
                              removedBlocks: removeBlockUndoRedoInfo.removedBlocks,
                              prevBlocks: removeBlockUndoRedoInfo.prevBlocks,
                              nextBlocks: removeBlockUndoRedoInfo.nextBlocks
                          }
                        : undefined,
                prev: {
                    blocks: blocksUndoRedoInfo.prevBlocks,
                    syncComponents: syncComponentsUndoRedoInfo.prevBlocks
                },
                next: {
                    blocks: blocksUndoRedoInfo.nextBlocks,
                    syncComponents: syncComponentsUndoRedoInfo.nextBlocks
                }
            }
        })
    })

    /** 碰撞时检测合法性 */
    const collisionAreaDetection = useAtomCallback<CollisionArea | null, [NodeIdWithScope | null, CollisionArea | undefined]>(
        (get, set, activeId, over) => {
            if (!over) {
                return null
            }

            // 忽略自身的相邻拖拽区域
            if (over.scope === activeId?.scope && over.id === activeId?.id) {
                return null
            }

            const blocks = get(blocksAtom)[pageId] || []
            const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState
            const syncComponents = get(syncComponentsAtom)

            let children: BlockAbstract[] = blocks
            const parentId = over.realParentId || over.parentId
            const parentBlock = findNormalOrSyncBlock({ id: parentId }, blocks, syncComponents)
            if (parentBlock) {
                children =
                    getCurrentBlockChildren(parentBlock, {
                        blockRuntimeState,
                        uniqueContainerId: parentBlock.isMasterSynchronous
                            ? parentId
                            : parentBlock.isLeafSynchronous
                            ? `${over.scope}@${parentBlock.id}`
                            : parentBlock.id
                    }) || []
            }

            const index = children.findIndex(item => item.id === over.id)
            // 忽略前后是自身的情况
            if (
                activeId &&
                ((over.position === 'after' && children[index + 1]?.id === activeId.id) ||
                    (over.position === 'before' && children[index - 1]?.id === activeId.id))
            ) {
                return null
            }

            if (activeId && isActiveInOver(activeId, over, blocks, syncComponents)) {
                return null
            }

            return over
        }
    )

    const { onUpdateBlock } = useBlockActions(pageId, stackId)

    /** block 尺寸拉伸的限制 */
    const getResizeRestrict = useAtomCallback<ResizeRestrict, [NodeIdWithScope]>((get, set, node) => {
        const blocks = get(blocksAtom)[pageId] || []
        const syncComponents = get(syncComponentsAtom)
        const block = findNormalOrSyncBlock(node, blocks, syncComponents)

        const restrict: ResizeRestrict = {
            direction: []
        }

        if (!block) {
            return restrict
        }

        restrict.minWidth = block.config.breakPoint.size.minWidth
        restrict.maxWidth = block.config.breakPoint.size.maxWidth
        restrict.minHeight = block.config.breakPoint.size.minHeight
        restrict.maxHeight = block.config.breakPoint.size.maxHeight

        const designOption = getBlockAndPageDesignLimit(isCustomViewBlock(block) ? 'customView' : block.type)

        if (!designOption.size?.disableWidth) {
            restrict.direction.push(DIRECTION.horizontal)
        }

        if (!designOption.size?.disableHeight && !designOption.size?.hideHeight) {
            restrict.direction.push(DIRECTION.vertical)
        }

        return restrict
    })

    const onResizeEnd = useAtomCallback<void, [NodeIdWithScope, ResizeEvent, boolean?]>((get, set, node, event, disableUndoRedo) => {
        const changeKey = event.direction === DIRECTION.horizontal ? 'width' : 'height'
        const data = {
            size: {
                [changeKey]: {
                    size: Math.round(event.size),
                    unit: 'px'
                }
            }
        }
        const previewType = get(previewTypeAtom)
        const pageBlocks = get(blocksAtom)[pageId] || []
        const syncComponents = get(syncComponentsAtom)

        const originBlock = findNormalOrSyncBlock(node, pageBlocks, syncComponents, false)

        if (originBlock) {
            const block: BlockAbstract = { ...originBlock }
            const { config } = block
            if (previewType === ApplicationPreviewEnum.desktop) {
                if (
                    originBlock.config.breakPoint.size[changeKey].size === 'auto' ||
                    originBlock.config.breakPoint.size[changeKey].size === 'fill'
                ) {
                    const changeName = changeKey === 'height' ? '高度' : '宽度'
                    Toast.info(`已自动将「${originBlock.title}」的${changeName}变更为固定${changeName}`, {
                        icon: false,
                        style: { borderRadius: 6 }
                    })
                }

                const newBlock = {
                    ...block,
                    config: {
                        ...config,
                        breakPoint: mergeDeepRight(config.breakPoint, data)
                    }
                } as BlockAbstract

                onUpdateBlock({ prevBlock: originBlock, nextBlock: newBlock })
                if (!disableUndoRedo) {
                    pageUndoRedoController.add({
                        action: BLOCK_UPDATE_ACTION,
                        payload: { prev: originBlock, next: newBlock }
                    })
                }
                return
            }

            const index = findIndex(b => b.id === previewType, config.breakPoints)
            if (index < 0) {
                return
            }
            const base = config.breakPoint
            const currentBreakPoint = config.breakPoints[index]
            const breakPoint = mergeSingleBreakPointConfigure(base, currentBreakPoint)
            const keyPaths = [`size.${changeKey}`]
            Object.assign(breakPoint, {
                breakKeys: [...new Set([...(breakPoint.breakKeys || []), ...keyPaths])]
            })

            if (breakPoint.size[changeKey].size === 'auto' || breakPoint.size[changeKey].size === 'fill') {
                const changeName = changeKey === 'height' ? '高度' : '宽度'
                Toast.info(`已自动将「${originBlock.title}」的${changeName}变更为固定${changeName}`, {
                    icon: false,
                    style: { borderRadius: 6 }
                })
            }

            const mergeBreakPoint = mergeDeepRight<BaseBreakPointConfigProtocol>(breakPoint, data)
            const newBlock = {
                ...block,
                config: {
                    ...config,
                    breakPoints: [mergeBreakPoint]
                }
            } as BlockAbstract

            onUpdateBlock({ prevBlock: originBlock, nextBlock: newBlock })
            if (!disableUndoRedo) {
                pageUndoRedoController.add({
                    action: BLOCK_UPDATE_ACTION,
                    payload: { prev: originBlock, next: newBlock }
                })
            }
        }
    })

    return { onDragEnd, collisionAreaDetection, getResizeRestrict, onResizeEnd }
}
