/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { collectBlockAndChildren } from '@lighthouse/block'
import type { BlockAbstract, ContainerBlockAbstract } from '@lighthouse/core'
import { findBlockById, findParentBlockById, getCurrentBlockChildren, initBlockRuntimeState, useAtomAction } from '@lighthouse/shared'
import { current, original } from 'immer'
import { useAtomCallback } from 'jotai/utils'

import { syncComponentsAtom } from '@/atoms/application/state'
import { createBlockAtom, removeBlockAtom } from '@/atoms/page/action'
import { blockCreatingListAtom, blocksAtom, pageBlocksAtom, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import type { NodeIdWithScope } from '@/atoms/page/types'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import * as srv from '@/services'
import { NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { copyBlock, removeBlockById } from './utils'

interface Params {
    pageId: string
    rootPageId: string
    stackId: string
}

type BlockUndoRedo<T extends BlockAbstract = BlockAbstract> = {
    prevBlocks?: T[]
    nextBlocks?: T[]
}

/**
 * 一些操作nodes的方法
 * @returns actions functions
 */
export const useNodeToolbarActions = ({ stackId, rootPageId, pageId }: Params) => {
    const { run: createBlock } = useAtomAction(createBlockAtom)
    const { run: removeBlock } = useAtomAction(removeBlockAtom)

    // 克隆block
    const onClone = useAtomCallback<void, [NodeIdWithScope]>(async (get, set, { scope, id }) => {
        const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState

        // 更改同步组件内
        if (scope) {
            const syncUndoRedoInfo: {
                createdBlock: BlockAbstract | undefined
                prevBlocks: ContainerBlockAbstract[] | undefined
                nextBlocks: ContainerBlockAbstract[] | undefined
            } = {
                createdBlock: undefined,
                prevBlocks: undefined,
                nextBlocks: undefined
            }
            set(syncComponentsAtom, draft => {
                syncUndoRedoInfo.prevBlocks = original(draft)
                const draftParentBlock = findParentBlockById(id, draft)
                const children = draftParentBlock
                    ? getCurrentBlockChildren(draftParentBlock, {
                          blockRuntimeState,
                          uniqueContainerId: draftParentBlock.isMasterSynchronous ? scope : `${scope}@${draftParentBlock.id}`
                      })
                    : draft
                if (!children) {
                    return
                }

                const index = children.findIndex(item => item.id === id)
                if (index === -1) {
                    return
                }
                const copiedBlock = current(children)[index]
                const newBlock = copyBlock([copiedBlock])[0]
                syncUndoRedoInfo.createdBlock = newBlock

                set(blockCreatingListAtom, s => [...s, ...collectBlockAndChildren(syncUndoRedoInfo.createdBlock!).map(item => item.id)])

                children.splice(index + 1, 0, newBlock)

                syncUndoRedoInfo.nextBlocks = current(draft)
            })

            if (syncUndoRedoInfo.prevBlocks && syncUndoRedoInfo.nextBlocks && syncUndoRedoInfo.createdBlock) {
                set(pageStackAtom, draft => {
                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                    if (stack) {
                        initBlockRuntimeState(stack, {
                            blocks: get(pageBlocksAtom(pageId)),
                            syncComponents: syncUndoRedoInfo.nextBlocks as BlockAbstract[]
                        })
                    }
                })

                await srv.createSyncBlock({ createBlocks: [syncUndoRedoInfo.createdBlock], blocks: syncUndoRedoInfo.nextBlocks })
                set(blockCreatingListAtom, s =>
                    s.filter(id => !collectBlockAndChildren(syncUndoRedoInfo.createdBlock!).some(item => item.id === id))
                )
                pageUndoRedoController.add({
                    action: NODE_UPDATE_ACTION,
                    payload: {
                        prev: {
                            blocks: undefined,
                            syncComponents: syncUndoRedoInfo.prevBlocks
                        },
                        next: {
                            blocks: undefined,
                            syncComponents: syncUndoRedoInfo.nextBlocks
                        }
                    }
                })
            }

            return
        }

        const undoRedoInfo: {
            createdBlock: BlockAbstract | undefined
            prevBlocks: BlockAbstract[] | undefined
            nextBlocks: BlockAbstract[] | undefined
        } = {
            createdBlock: undefined,
            prevBlocks: undefined,
            nextBlocks: undefined
        }

        set(blocksAtom, draft => {
            const pageBlocks = draft[pageId]
            if (!pageBlocks) {
                return
            }
            undoRedoInfo.prevBlocks = original(pageBlocks)

            const copiedBlock = findBlockById(id, current(pageBlocks))
            if (!copiedBlock) {
                return
            }

            const parentBlock = findParentBlockById(id, pageBlocks)

            const children = parentBlock ? getCurrentBlockChildren(parentBlock, { blockRuntimeState }) : pageBlocks
            if (!children) {
                return
            }

            const index = children.findIndex(item => item.id === copiedBlock.id)
            if (index === -1) {
                return
            }

            // 如果复制的容器，则递归复制容器内的子元素
            undoRedoInfo.createdBlock = copyBlock([copiedBlock])[0]

            set(blockCreatingListAtom, s => [...s, ...collectBlockAndChildren(undoRedoInfo.createdBlock!).map(item => item.id)])

            children.splice(index + 1, 0, undoRedoInfo.createdBlock)

            undoRedoInfo.nextBlocks = current(pageBlocks)
        })

        if (!undoRedoInfo.createdBlock || !undoRedoInfo.prevBlocks || !undoRedoInfo.nextBlocks) {
            return
        }

        pageUndoRedoController.add({
            action: NODE_UPDATE_ACTION,
            payload: {
                created: {
                    createdBlocks: [undoRedoInfo.createdBlock],
                    prevBlocks: undoRedoInfo.prevBlocks,
                    nextBlocks: undoRedoInfo.nextBlocks
                }
            }
        })

        await createBlock({ rootPageId, pageId, stackId, blocks: undoRedoInfo.nextBlocks, createdBlocks: [undoRedoInfo.createdBlock] })
        set(blockCreatingListAtom, s => s.filter(id => !collectBlockAndChildren(undoRedoInfo.createdBlock!).some(item => item.id === id)))
    })

    // 删除block
    const onRemove = useAtomCallback<void, [NodeIdWithScope[]]>((get, set, nodes) => {
        const blocksUndoRedoInfo: BlockUndoRedo = {}
        const syncComponentsUndoRedoInfo: BlockUndoRedo<ContainerBlockAbstract> = {}

        const removedBlocks: BlockAbstract[] = []

        const scopeNodes = nodes.filter(item => !!item.scope)
        const pageNodes = nodes.filter(item => !item.scope)

        if (scopeNodes.length > 0) {
            set(syncComponentsAtom, draft => {
                let hasChanged = false
                scopeNodes.forEach(node => {
                    const removed = removeBlockById(node.id)(draft)
                    if (removed && removed.length !== 0) {
                        hasChanged = true
                    }
                })

                if (hasChanged) {
                    syncComponentsUndoRedoInfo.prevBlocks = original(draft)
                    syncComponentsUndoRedoInfo.nextBlocks = current(draft)
                }
            })
        }

        if (pageNodes.length > 0) {
            set(blocksAtom, draft => {
                const draftBlocks = draft[pageId]
                if (!draftBlocks) {
                    return
                }

                blocksUndoRedoInfo.prevBlocks = original(draftBlocks)

                pageNodes.forEach(node => {
                    const removed = removeBlockById(node.id)(draftBlocks)
                    if (removed) {
                        removedBlocks.push(...removed.map(current))
                    }
                })

                blocksUndoRedoInfo.nextBlocks = current(draftBlocks)
            })
        }

        if (syncComponentsUndoRedoInfo.nextBlocks) {
            set(pageStackAtom, draft => {
                const stack = equalPageStack({ rootPageId, stackId })(draft)
                if (stack) {
                    stack.state.selectedNodes = undefined
                    stack.state.asideType = AsideType.PAGE
                }
            })
            srv.updateSyncComponents(syncComponentsUndoRedoInfo.nextBlocks)
        }

        if (blocksUndoRedoInfo.nextBlocks && removedBlocks.length !== 0) {
            removeBlock({ rootPageId, pageId, stackId, blocks: blocksUndoRedoInfo.nextBlocks, removedBlocks })
        }

        pageUndoRedoController.add({
            action: NODE_UPDATE_ACTION,
            payload: {
                removed:
                    removedBlocks.length !== 0 && blocksUndoRedoInfo.prevBlocks && blocksUndoRedoInfo.nextBlocks
                        ? {
                              removedBlocks,
                              prevBlocks: blocksUndoRedoInfo.prevBlocks,
                              nextBlocks: blocksUndoRedoInfo.nextBlocks
                          }
                        : undefined,
                prev: {
                    blocks: undefined,
                    syncComponents: syncComponentsUndoRedoInfo.prevBlocks
                },
                next: {
                    blocks: undefined,
                    syncComponents: syncComponentsUndoRedoInfo.nextBlocks
                }
            }
        })
    })

    // 交换顺序
    const onSwap = useAtomCallback<void, [NodeIdWithScope, NodeIdWithScope]>((get, set, from, to) => {
        if (from.scope === to.scope && from.id === to.id) {
            return
        }

        const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState

        function recursionSwap(blocks: BlockAbstract[]) {
            let fromIndex: number | undefined
            let toIndex: number | undefined
            for (const [index, node] of blocks.entries()) {
                if (node.id === from.id) {
                    fromIndex = index
                }

                if (node.id === to.id) {
                    toIndex = index
                }
                if (fromIndex !== undefined && toIndex !== undefined && fromIndex !== toIndex) {
                    ;[blocks[fromIndex], blocks[toIndex]] = [blocks[toIndex], blocks[fromIndex]]
                    return true
                }

                const children = getCurrentBlockChildren(node, {
                    blockRuntimeState,
                    uniqueContainerId: node.isMasterSynchronous ? from.scope : node.isLeafSynchronous ? `${from.scope}@${node.id}` : node.id
                })
                if (children) {
                    const isSwapped = recursionSwap(children)
                    if (isSwapped) {
                        return true
                    }
                }
            }

            return false
        }

        if (from.scope && to.scope) {
            const syncComponentsUndoRedoInfo: BlockUndoRedo<ContainerBlockAbstract> = {}
            set(syncComponentsAtom, draft => {
                syncComponentsUndoRedoInfo.prevBlocks = original(draft)

                const success = recursionSwap(draft)
                if (success) {
                    syncComponentsUndoRedoInfo.nextBlocks = current(draft)
                }
            })

            if (syncComponentsUndoRedoInfo.nextBlocks) {
                srv.updateSyncComponents(syncComponentsUndoRedoInfo.nextBlocks)
                pageUndoRedoController.add({
                    action: NODE_UPDATE_ACTION,
                    payload: {
                        prev: {
                            blocks: undefined,
                            syncComponents: syncComponentsUndoRedoInfo.prevBlocks
                        },
                        next: {
                            blocks: undefined,
                            syncComponents: syncComponentsUndoRedoInfo.nextBlocks
                        }
                    }
                })
            }
        } else {
            const blocksUndoRedoInfo: BlockUndoRedo = {}
            set(blocksAtom, draft => {
                const pageBlocks = draft[pageId]
                if (!pageBlocks) {
                    return
                }
                blocksUndoRedoInfo.prevBlocks = original(pageBlocks)

                const success = recursionSwap(pageBlocks)
                if (success) {
                    blocksUndoRedoInfo.nextBlocks = current(pageBlocks)
                }
            })

            if (blocksUndoRedoInfo.nextBlocks) {
                srv.updateBlock({
                    allBlocks: blocksUndoRedoInfo.nextBlocks,
                    pageId
                })
                pageUndoRedoController.add({
                    action: NODE_UPDATE_ACTION,
                    payload: {
                        prev: {
                            blocks: blocksUndoRedoInfo.prevBlocks,
                            syncComponents: undefined
                        },
                        next: {
                            blocks: blocksUndoRedoInfo.nextBlocks,
                            syncComponents: undefined
                        }
                    }
                })
            }
        }
    })

    return {
        onClone,
        onRemove,
        onSwap
    }
}
