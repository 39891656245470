import type { FormContainerBlockAbstract } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import { findBlockById, findParentBlockByType, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'

import { syncComponentsAtom } from '@/atoms/application/state'
import { blocksAtom, lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useVariableCustomDataSource } from '@/hooks/useVariableCustomViewOption'

import { FieldInputSetting } from '../Common/FieldInputSetting'

export interface BlockFieldSettingsProps {
    id: string
    pointer: string
}

const BlockFieldSettings: React.FunctionComponent<BlockFieldSettingsProps> = ({ id: blockId, pointer: pageDsId }) => {
    const [pageId, blockRuntimeState, selectNode] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.blockRuntimeState, s?.state.selectedNodes?.find(v => v.id === blockId)] as const, [blockId])
    )

    const syncComponents = useAtomData(syncComponentsAtom)

    const currentAppId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const blocks = useAtomData(pageBlocksAtom(pageId))

    const parentForm = useMemo(() => {
        return findParentBlockByType<FormContainerBlockAbstract>({
            id: blockId,
            blocks,
            syncComponents,
            blockRuntimeState,
            filter: block => block.type === BlockType.formContainer
        })
    }, [blockId, blocks, syncComponents, blockRuntimeState])

    const mode = useMemo(() => (parentForm ? 'form' : 'field'), [parentForm])

    const { customViewDsId } = useVariableCustomDataSource()

    const pointer = (mode === 'form' ? parentForm?.config.pointer : customViewDsId || pageDsId) ?? ''

    const dataSourceList = useDataSourceList(currentAppId, envId)

    return <FieldInputSetting dataSourceList={dataSourceList} mode={mode} pointer={pointer} />
}

export default BlockFieldSettings
