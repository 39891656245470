import { ImageBlock, useContainerBlockContext, useCustomViewBlockContext } from '@lighthouse/block'
import type { FileValue } from '@lighthouse/core'
import { type ImageBlockAbstract } from '@lighthouse/core'
import {
    commonPages,
    getFileNameByUrl,
    getFileSizeByUrl,
    getFileTypeByUrl,
    getVariableToFieldValue,
    urlRegex,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'

import { openPageStackAtom } from '@/atoms/page/action'
import { pageStackAtomFamily } from '@/atoms/page/state'
import { stackFactory } from '@/atoms/page/utils'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { useDataSource, useRecord } from '@/hooks/useDataSource'
import { useDefaultPageList, usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useUserRecord } from '@/hooks/useUserRecord'

interface ImageBlockControllerProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onClick'> {
    blockData: ImageBlockAbstract
    onBlockChange?: (values: ImageBlockAbstract, origin: ImageBlockAbstract) => Promise<void> | void
}

const ImageBlockController: React.FC<ImageBlockControllerProps> = ({ blockData, onBlockChange, ...rest }) => {
    const {
        config: { imageSource, sourceType, sources },
        id: blockId
    } = blockData
    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const previewType = usePreviewType()
    const { scope } = useContainerBlockContext()
    const selectedNodes = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.state?.selectedNodes, [])
    )
    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { curr, prev } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const prevRecord = useRecord(appId, envId, prev.datasource?.id ?? '', prev.recordId ?? '')
    const currentRecord = useRecord(appId, envId, curr.datasource?.id ?? '', curr.recordId ?? '')

    const pageList = useDefaultPageList()

    const { record: viewRecord, pointer } = useCustomViewBlockContext()

    const userRecord = useUserRecord()

    const viewDataSource = useDataSource(appId, envId, pointer)

    const { handleActionTrigger } = useActionTrigger()

    const fileList = useMemo(() => {
        if (sourceType === 'field') {
            if (!imageSource) {
                return []
            }
            const cellValue = getVariableToFieldValue({
                idVariable: imageSource,
                currentRecord: {
                    datasource: curr.datasource,
                    record: currentRecord
                },
                prevRecord: {
                    datasource: prev.datasource,
                    record: prevRecord
                },
                viewRecord: {
                    record: viewRecord,
                    datasource: viewDataSource
                },
                userRecord
            })

            const fileValue: FileValue = cellValue && cellValue.type === 'file' ? cellValue.value : []
            return (
                fileValue?.map(item => {
                    const name = getFileNameByUrl(item) || ''
                    const type = getFileTypeByUrl(item)
                    const size = getFileSizeByUrl(item)
                    return { name, size, url: item, type, title: '', link: '' }
                }) ?? []
            )
        }
        return sources || []
    }, [
        curr.datasource,
        currentRecord,
        imageSource,
        prev.datasource,
        prevRecord,
        sourceType,
        sources,
        userRecord,
        viewDataSource,
        viewRecord
    ])

    return (
        <ImageBlock
            disabled={selectedNodes ? selectedNodes.length > 1 || !selectedNodes.some(v => v.id === blockId && v.scope === scope) : true}
            isDefault
            blockData={blockData}
            previewType={previewType}
            value={fileList}
            onActionTrigger={handleActionTrigger}
            onClick={link => {
                if (!link) {
                    return
                }
                const isCommonPages = commonPages.includes(link)
                const isExitPage = find(item => item.id === link, pageList)
                if (isCommonPages || isExitPage) {
                    openPageStack(
                        stackFactory({
                            appId,
                            pageId: link,
                            rootPageId
                        })
                    )
                    return
                }
                const isUrl = urlRegex.test(link)
                if (isUrl) {
                    const skipUrl = link.startsWith('http') ? link : `http://${link}`
                    window.open(skipUrl, '_blank')
                }
            }}
            onBlockChange={onBlockChange}
            {...rest}
        />
    )
}

export default ImageBlockController
