import type { RecordLikeProtocol } from '@lighthouse/core'
import type {
    AliyunMessageActionPayload,
    UpdateWorkflowLogPayload,
    WxMessageInfoRes,
    WxTemplateMessageActionPayload
} from '@lighthouse/shared'
import {
    type ActiveAddIClickUserActionPayload,
    type ActiveDingTalkRobotActionPayload,
    type ActiveFindSingleRecordActionPayload,
    type ActiveSendEmailActionPayload,
    type ActiveStationMessageActionPayload,
    type ActiveWxRobotActionPayload,
    type AliyunMessageInfoPayload,
    type AliyunMessageInfoRes,
    type CreateRecordWithFieldsPayload,
    type TriggerAutomationActionPayload,
    type UpdateRecordWithFieldsPayload,
    type Workflow,
    getMainTableRecordId
} from '@lighthouse/shared'

import type { HttpResp } from '@/http'
import http from '@/http'

import { JsonContentType } from './constant'
import type {
    ActiveSubProcessPayload,
    GetBeUsedSubProcessPayload,
    GetWorkflowRunLogsPayload,
    RecordRes,
    UpdateWorkflowPayload,
    UsedWorkflowResult,
    WorkflowCreatePayload,
    WorkflowResult,
    WorkflowRunLogRes
} from './types'

/** 获取工作流列表 */
export async function listFlowNodes(): Promise<WorkflowResult> {
    const res = await http.get<unknown, HttpResp<WorkflowResult>>('api/v1/workflow/listWorkflow')
    return res.content
}

/** 创建新工作流 */
export async function createFlow(params: WorkflowCreatePayload): Promise<string> {
    const res = await http.put<unknown, HttpResp<string>>('api/v1/workflow/createWorkflow', params, {
        headers: JsonContentType
    })
    return res.content
}

/** 更新新工作流 */
export async function updateFlow(params: Partial<UpdateWorkflowPayload>): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<string>>('api/v1/workflow/updateWorkflow', params, {
        headers: JsonContentType
    })
    return res.success
}

/** 更新并启用工作流 */
export async function updateAndEnableWorkflow(params: Partial<UpdateWorkflowPayload>): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<string>>('api/v1/workflow/updateAndEnableWorkflow', params, {
        headers: JsonContentType
    })
    return res.success
}

/** 删除工作流 */
export async function deleteFlow(id: string): Promise<boolean> {
    const res = await http.delete<unknown, HttpResp<string>>('api/v1/workflow/deleteWorkflow', { params: { id } })
    return res.success
}

/** 复制工作流 */
export async function duplicateFlow(id: string): Promise<Workflow> {
    const res = await http.post<unknown, HttpResp<Workflow>>(
        'api/v1/workflow/duplicate',
        { id },
        {
            headers: JsonContentType
        }
    )
    return res.content
}

/** 启用停用工作流 */
export async function enableFlow(id: string, enable: boolean): Promise<HttpResp<string>> {
    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const res = await http.get<unknown, HttpResp<string>>('api/v1/workflow/enable', { params: { id, enable } })
    return res
}

/** 获取工作流 */
export async function fetchFlow(id: string): Promise<Workflow> {
    const res = await http.get<unknown, HttpResp<Workflow>>('api/v1/workflow/getWorkflow', { params: { id } })
    return res.content
}

// /** 放弃工作流草稿 */
// export async function dropFlowDraft(id: string): Promise<HttpResp<string>> {
//     // eslint-disable-next-line sonarjs/prefer-immediate-return
//     const res = await http.delete<unknown, HttpResp<string>>('api/v1/workflow/dropDraft', { params: { id } })
//     return res
// }

// /** 应用工作流草稿 */
// export async function saveFlowDraftAsRelease(id: string): Promise<HttpResp<string>> {
//     // eslint-disable-next-line sonarjs/prefer-immediate-return
//     const res = await http.get<unknown, HttpResp<string>>('api/v1/workflow/saveDraftAsRelease', { params: { id } })
//     return res
// }

/** 获取工作流运行日志 */
export async function fetchFlowRunLog(params?: GetWorkflowRunLogsPayload): Promise<HttpResp<WorkflowRunLogRes>> {
    // eslint-disable-next-line sonarjs/prefer-immediate-return
    const res = await http.get<unknown, HttpResp<WorkflowRunLogRes>>('api/v1/workflow/executionLogs', { params })
    return res
}

/**
 * 获取指定工作流的运行日志
 * @param workflowInstanceId
 * @returns
 */
export async function getFlowLog(workflowInstanceId?: string): Promise<Workflow> {
    const res = await http.get<unknown, HttpResp<Workflow>>('api/v1/workflow/getWorkflowLog', { params: { workflowInstanceId } })
    return res.content
}

/**
 * 创建动作流执行日志
 * @param flowId
 * @returns
 */
export async function createWorkflowLog(workflowId: string) {
    const res = await http.post<unknown, HttpResp<string>>(
        'api/v1/workflow/createWorkflowLog',
        { workflowId },
        { headers: JsonContentType }
    )
    return res.content
}

/**
 * 更新动作流执行日志
 */
export async function updateWorkflowLog(payload: UpdateWorkflowLogPayload): Promise<string> {
    const res = await http.post<unknown, HttpResp<string>>('api/v1/workflow/updateWorkflowLog', payload, { headers: JsonContentType })

    return res.content
}

// ##################################################################### //
// ############################# 微信机器人 ############################# //
// ##################################################################### //

/**
 * @description 动作流与动作中创建记录
 * @returns
 */
export async function createRecordWithFields(payload: CreateRecordWithFieldsPayload): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, ...rest } = payload
    const res = await http.post<unknown, HttpResp<RecordRes>>(
        'api/v1/workflow/createRecord',
        { envId, dsId, ...rest },
        { headers: JsonContentType }
    )
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 * @description 动作流与动作中更新记录
 * @returns
 */
export async function updateRecordWithFields(payload: UpdateRecordWithFieldsPayload): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, ...rest } = payload
    const params = {
        ...rest,
        dsId,
        recordId: getMainTableRecordId(payload.recordId)
    }
    const res = await http.post<unknown, HttpResp<RecordRes>>('api/v1/workflow/updateRecord', params, { headers: JsonContentType })
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 * @description 动作流查询单条记录
 * @returns
 */
export async function findSingleRecord(payload: ActiveFindSingleRecordActionPayload): Promise<RecordLikeProtocol> {
    const { appId, envId, dsId, ...rest } = payload
    const params = {
        dsId,
        ...rest
    }
    const res = await http.post<unknown, HttpResp<RecordRes>>('api/v1/workflow/activeFindSingleRecordAction', params, {
        headers: JsonContentType
    })
    return {
        appId,
        envId,
        dsId,
        ...res.content
    }
}

/**
 *
 * @description 动作流与动作中发送微信消息
 * @returns
 */
export async function activeWxRobotAction(params: ActiveWxRobotActionPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/workflow/activeWxRobotAction', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 *
 * @description 动作流与动作中发送钉钉消息
 * @returns
 */
export async function activeDingTalkRobotAction(params: ActiveDingTalkRobotActionPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/workflow/activeDingTalkRobotAction', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 *
 * @description 动作流与动作中发送站内信消息
 * @returns
 */
export async function activeStationMessageAction(params: ActiveStationMessageActionPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/workflow/activeStationMessageAction', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 *
 * @description 微信模版消息
 * @returns
 */
export async function wxTemplateMessageAction(params: WxTemplateMessageActionPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/workflow/wxTemplateMessageAction', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 *
 * @description 动作流与动作中发送邮件
 * @returns
 */
export async function activeSendEmailAction(params: ActiveSendEmailActionPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/workflow/activeSendEmailAction', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 *
 * @description 动作流与动作中发送阿里云短信
 * @returns
 */
export async function activeAliyunMessageAction(params: AliyunMessageActionPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/workflow/activeAliyunMessageAction', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * @description 动作流与动作中触发自动化
 * @returns
 */
export async function triggerAutomationAction(params: TriggerAutomationActionPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/workflow/triggerAutomationAction', params)
    return res.success
}

/**
 * @description 动作流与动作中添加用户到ICLICK
 * @returns
 */
export async function activeAddIClickUser(params: ActiveAddIClickUserActionPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/workflow/addIClickUser', params, {
        headers: JsonContentType
    })
    return res.success
}

/**
 * @description 获取阿里云短信配置相关信息
 * @param params
 * @returns
 */
export async function getAliyunMessageInfo(params: AliyunMessageInfoPayload): Promise<AliyunMessageInfoRes> {
    const res = await http.get<unknown, HttpResp<AliyunMessageInfoRes>>('api/v1/integration/getAliyunMessageInfo', {
        params
    })
    return res.content
}

/**
 * @description 获取微信短信模板相关信息
 * @returns
 */
export async function getWxMessageInfo(): Promise<WxMessageInfoRes> {
    const res = await http.post<unknown, HttpResp<WxMessageInfoRes>>('api/v1/integration/wechatMsgTemplateList')
    return res.content
}


/**
 * @description 获取子流程被哪些节点使用
 */
export async function getBeUsedSubProcess(payload: GetBeUsedSubProcessPayload): Promise<UsedWorkflowResult> {
    const { workflowId } = payload
    const res = await http.get<unknown, HttpResp<UsedWorkflowResult>>('api/v1/workflow/getNodesWithSubProcess', { params: { id: workflowId } })
    return res.content
}

/**
 * @description 执行子流程
 */
export async function activeSubProcess(params: ActiveSubProcessPayload): Promise<boolean> {
    const res = await http.post<unknown, HttpResp<boolean>>('api/v1/workflow/activeSubProcess', params, {
        headers: JsonContentType
    })
    return res.success
}
