import { MessageContainer, ModalsProvider as ByecodeModalProvider, ToastContainer } from '@byecode/ui'
import { MotionDemo } from '@lighthouse/block'
import { Space } from '@mantine/core'
import React, { useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { ButtonComponentsDemo } from './Button'
import { CascadeComponentsDemo } from './CascadeSelect'
import { CheckboxComponentsDemo } from './Checkbox'
import { ColorPickerComponentsDemo } from './ColorPicker'
import { DatePickerComponentsDemo } from './DatePicker'
import { DividerComponentsDemo } from './Divider'
import { IconPickerComponentsDemo } from './IconPicker'
import { InputComponentsDemo } from './Input'
import { LoadingComponentsDemo } from './Loading'
import { MenuComponentDemo } from './Menu'
import { MessageComponentDemo } from './Message'
import { ModalComponentDemo } from './Modal'
import { PopoverComponentDemo } from './Popover'
import { RadioComponentsDemo } from './Radio'
import { ScannerComponentsDemo } from './Scanner'
import { SegmentedControlComponentsDemo } from './SegmentedControl'
import { SelectComponentsDemo } from './Select'
import { SliderComponentsDemo } from './Slider'
import { SwitchComponentsDemo } from './Switch'
import { TableComponentsDemo } from './Table'
import { TooltipComponentsDemo } from './Tooltip'
import { TreeDemo } from './Tree'

const SCxComponentsDemo = styled.div`
    width: 100%;
    height: 100%;
    overflow: auto;
    padding: 20px;
    padding-bottom: 100px;
    display: block;
`

function Counter() {
    const [count, setCount] = useState(0)

    function handleAlertClick() {
        setTimeout(() => {
            // eslint-disable-next-line no-alert
            alert(`You clicked on: ${count}`)
        }, 3000)
    }

    return (
        <div>
            <p>You clicked {count} times</p>
            <button onClick={() => setCount(count + 1)}>Click me</button>
            <button onClick={handleAlertClick}>Show alert</button>
        </div>
    )
}

export const ComponentsDemo: React.FC = () => {
    return (
        <SCxComponentsDemo>
            {createPortal(<ToastContainer />, document.body)}
            <h1>组件演示</h1>
            <Space h={50} />
            <MotionDemo />
            <Space h={50} />
            <DividerComponentsDemo />
            <Space h={50} />
            <TreeDemo />
            <Space h={50} />
            <ScannerComponentsDemo />
            <Space h={50} />
            <IconPickerComponentsDemo />
            <Space h={50} />
            <CascadeComponentsDemo />
            <Space h={50} />
            <ButtonComponentsDemo />
            <Space h={50} />
            <InputComponentsDemo />
            <Space h={50} />
            <SelectComponentsDemo />
            <Space h={50} />
            <SwitchComponentsDemo />
            <Space h={50} />
            <CheckboxComponentsDemo />
            <Space h={50} />
            <RadioComponentsDemo />
            <Space h={50} />
            <SegmentedControlComponentsDemo />
            <Space h={50} />
            <LoadingComponentsDemo />
            <Space h={50} />
            <PopoverComponentDemo />
            <Space h={50} />
            <MenuComponentDemo />
            <Space h={50} />
            <MessageComponentDemo />
            <Space h={50} />
            <DatePickerComponentsDemo />
            <Space h={50} />
            <ModalComponentDemo />
            <Space h={50} />
            <ColorPickerComponentsDemo />
            <Space h={50} />
            <TableComponentsDemo />
            <Space h={50} />
            <TooltipComponentsDemo />
            <Space h={50} />
            <SliderComponentsDemo />
        </SCxComponentsDemo>
    )
}
