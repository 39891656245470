import { Calendar } from '@byecode/ui'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'
import { CELL_BORDER_BOTTOM_WIDTH } from './Cell'

export const Container = styled(AnimationDecorators)`
    position: relative;
`

export const StyledCalendar = styled(Calendar)`
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    .byecode-Calendar-dayBody {
        position: relative;
        padding: 0;
    }
    table {
        margin: unset;
        border-spacing: unset;
        thead {
            th {
                text-align: left;
                font-weight: 400;
                line-height: 22px;
                padding: 18px 0 18px 20px;
                border-bottom: 1px solid var(--color-gray-200);
            }
        }

        tr:not(:last-of-type) {
            border-bottom: ${CELL_BORDER_BOTTOM_WIDTH}px solid var(--color-gray-200);
        }
        td {
            padding: unset;
            height: 1px;
            min-height: 1px;
        }

        tr:last-of-type td:first-of-type > * {
            border-bottom-left-radius: 8px;
        }
        tr:last-of-type td:last-of-type > * {
            border-bottom-right-radius: 8px;
        }
    }
`
