import { Button, Empty, IconFont, Loading, Text } from '@byecode/ui'
import type { Environment } from '@lighthouse/core'
import type { EnvDiffs } from '@lighthouse/shared'
import { TitleInContentModal } from '@lighthouse/shared'
import React, { useCallback, useMemo, useRef } from 'react'
import { useAsyncFn } from 'react-use'
import styled from 'styled-components'
import type { Updater } from 'use-immer'

import { EnvTagSelect } from './EnvTagSelect'
import { MergeDataSource } from './MergeDataSource'

const SCxContainer = styled.div`
    padding: 0 32px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex: 1;
    overflow: hidden;
`

const SCxContent = styled.div`
    /* margin-top: 20px; */
    display: flex;
    flex-direction: column;
    gap: 12px;
    flex: 1;
    overflow: hidden;
`

const SCxHeader = styled.div`
    margin-top: 12px;
    display: flex;
    gap: 8px;
    align-items: center;
`

const SCxIcon = styled(IconFont)``

const SCxLink = styled.a`
    color: var(--color-main);
    &:active {
        color: var(--color-main);
    }
`

const SCxFooter = styled.div`
    width: 100%;
    padding: 12px 0;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
`

const SCxSubmit = styled(Button)`
    margin-left: 10px;
    height: 36px;
    width: 80px;
`

const SCxCancel = styled(Button)`
    height: 36px;
    width: 80px;
    background: var(--color-white);
    border: 1px solid var(--color-gray-200);
`

export interface MergeModalState {
    fromEnvId: string
    toEnvId: string
}

interface MergeModalProps {
    open: boolean
    data?: EnvDiffs
    currentEnv?: Environment
    envState: MergeModalState
    loading?: boolean
    envList: Environment[]
    onlyCurrentEnv?: boolean
    onChangeEnvId: Updater<MergeModalState>
    onClose?: () => void
    onMerge: () => Promise<boolean>
}

export const MergeModal: React.FC<MergeModalProps> = ({
    open,
    data,
    envState,
    loading,
    envList,
    onlyCurrentEnv,
    onChangeEnvId,
    onMerge,
    onClose
}) => {
    const [{ loading: mergeLoading }, run] = useAsyncFn(onMerge)
    const { fromEnvId, toEnvId } = envState
    const description = useMemo(() => {
        // if (!fromEnv) {
        //     return ''
        // }
        // return `将${fromEnv.envName}中的数据合并到${toEnv?.envName}`
        return (
            <>
                <Text>
                    合并数据环境只会将数据表和字段结构合并，不会合并记录数据。
                    <SCxLink
                        target="_blank"
                        href="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/xzvd2rim4k57yp8y?singleDoc#"
                    >
                        了解更多
                    </SCxLink>
                </Text>
            </>
        )
    }, [])

    const handleChangeFromEnvId = useCallback(
        (id: string) => {
            onChangeEnvId(draft => {
                draft.fromEnvId = id
            })
        },
        [onChangeEnvId]
    )

    const handleChangeToEnvId = useCallback(
        (id: string) => {
            onChangeEnvId(draft => {
                draft.toEnvId = id
            })
        },
        [onChangeEnvId]
    )

    const handleMerge = useCallback(async () => {
        if (mergeLoading) {
            return
        }
        await run()

    }, [mergeLoading, run])

    const content = useMemo(() => {
        if (loading && !data) {
            return <Loading />
        }

        if (fromEnvId === toEnvId) {
            return (
                <Empty
                    styles={{ wrapper: { height: '188px', background: 'var(--color-gray-100)', borderRadius: '8px' } }}
                    icon={<IconFont type="Forbidden" size={32} fill="var(--color-gray-400)" />}
                    description="相同环境无法合并"
                />
            )
        }

        return (
            <SCxContent>
                <MergeDataSource data={data || {}} />
            </SCxContent>
        )
    }, [data, fromEnvId, loading, toEnvId])

    return (
        <TitleInContentModal
            open={open}
            width={745}
            title="合并环境数据"
            styles={{
                desktop: {
                    body: {
                        overflow: 'hidden',
                        display: 'flex',
                        flexDirection: 'column'
                    }
                }
            }}
            description={description}
            onClose={onClose}
        >
            <SCxContainer>
                <SCxHeader>
                    <EnvTagSelect id={fromEnvId} onlyCurrentEnv={onlyCurrentEnv} envList={envList} onChange={handleChangeFromEnvId} />
                    <SCxIcon type="ArrowRight" fill="var(--color-gray-400)" />
                    <EnvTagSelect id={toEnvId} onlyCurrentEnv={onlyCurrentEnv} envList={envList} onChange={handleChangeToEnvId} />
                </SCxHeader>
                {content}
                <SCxFooter>
                    <SCxSubmit disabled={fromEnvId === toEnvId} loading={mergeLoading} type="primary" onClick={handleMerge}>
                        合并
                    </SCxSubmit>
                    <SCxCancel onClick={onClose}>取消</SCxCancel>
                </SCxFooter>
            </SCxContainer>
        </TitleInContentModal>
    )
}
