import { Input } from '@byecode/ui'
import isDeepEqual from 'fast-deep-equal'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { SizeInput } from '../SizeInput'
import { useSetGapHighlight } from './useSetGapHighlight'

type GapSizeValue = number | undefined

interface GapSizeInputProps {
    value?: GapSizeValue
    onChange: (value: GapSizeValue) => void
    disableHighlight?: boolean
}

const SCxRectSizeInputWrapper = styled.div`
    display: flex;
    width: 180px;
`

export const GapSizeInput: React.FC<GapSizeInputProps> = ({ value, onChange, disableHighlight }) => {
    const [moveOpen, setMoveOpen] = useState(false)
    const { onMouseEnterGap, onMouseLeaveGap } = useSetGapHighlight(disableHighlight || moveOpen)

    return (
        <SizeInput
            min={0}
            radius={0}
            icon="GapHorizontal"
            type="number"
            onFocus={e => e.currentTarget.select()}
            wrapperProps={{
                onMouseEnter: onMouseEnterGap,
                onMouseLeave: onMouseLeaveGap
            }}
            value={value}
            onChangeMove={setMoveOpen}
            onChange={onChange}
        />
    )
}
