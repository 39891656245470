import { IconFont, Input, Popover, SegmentedControl, Select, Text, useDisclosure } from '@byecode/ui'
import type { AnimationTypeEnum, ContainerBreakPointProtocol } from '@lighthouse/core'
import { PresetEnum } from '@lighthouse/core'
import { ListItem4ByecodeUi, TagIcon } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import AnimationSettingController from './AnimationSettingController'
import { getDefaultAnimation, getInitLayerInViewAnimationConfig } from './util'

interface AnimationCreatorProps {
    label: string
    animationLabel: string
    suffix: 'layerInView.enter' | 'layerInView.exit'
    onOpenAnimate: () => void
}

const AnimationCreator: React.FunctionComponent<AnimationCreatorProps> = ({ label, animationLabel, suffix, onOpenAnimate }) => {
    const { watch, setValue } = useFormContext<ContainerBreakPointProtocol>()
    const animate = watch(`breakPoint.animation.${suffix}`)
    return (
        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
            <Text>{label}</Text>
            <div style={{ width: 180 }} onClick={onOpenAnimate}>
                <Input
                    style={{ width: 180 }}
                    styles={{
                        input: {
                            cursor: 'pointer'
                        }
                    }}
                    value={animate ? animationLabel : ''}
                    placeholder="添加"
                    readOnly
                    prefix={
                        <TagIcon
                            icon="Stars"
                            size={24}
                            background={animate ? 'var(--color-theme-7)' : 'var(--color-gray-200)'}
                            iconColor={animate ? '#fff' : 'var(--color-gray-400)'}
                            radius={4}
                        />
                    }
                    suffix={
                        animate ? (
                            <IconFont
                                type="CloseCircle"
                                size={16}
                                onClick={e => {
                                    e.stopPropagation()
                                    setValue(`breakPoint.animation.${suffix}`, undefined)
                                     setValue(`breakPoint.animation.layerInView.preset`, PresetEnum.custom)
                                }}
                            />
                        ) : null
                    }
                />
            </div>
        </ListItem4ByecodeUi>
    )
}

export default AnimationCreator
