import { VariableType } from '@lighthouse/core'
import { type ActionFlowNode, type WechatTemplateMsgConfig, generateVariableValue, isIdsValue, resolvedUpstreamVariableValue } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleWechatTemplateMsg = async (node: ActionFlowNode<WechatTemplateMsgConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }
    const { templateId, personList: configAddresseeIds, values, url } = config
    const persons = resolvedUpstreamVariableValue({ data: configAddresseeIds, extraParams: excParams })
    const personListValues = persons.reduce<string[]>((prev, cur) => {
        if (cur.type === VariableType.VALUE) {
            const val = cur.valueVariable?.value
            if (val && isIdsValue(val)) {
                val.forEach(v => {
                    prev.push(v)
                })
            }
        }
        return prev
    }, [])
    const addresseeIds = [...new Set(personListValues.flat())]
    const valuesValues = values.map(value => ({
        variableName: value.variableName,
        value: generateVariableValue({ innerType: 'TEXT', jsonContent: value.value, extraParams: excParams, format: { dateFormat: true, fileFormat: true } }) as string
    }))
    const urlValue = generateVariableValue({ innerType: 'TEXT', jsonContent: url, extraParams: excParams }) as string

    await srv.wxTemplateMessageAction({
        addresseeIds,
        templateId,
        url: urlValue,
        values: valuesValues
    })

    return {
        addresseeIds,
        templateId,
        url: urlValue,
        values: valuesValues
    }
}
