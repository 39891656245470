import React, { createContext, useContext, useMemo } from 'react'

export interface BlockSettingContextValue {
    blockId: string
    scope?: string
}

export const BlockSettingContext = createContext<BlockSettingContextValue>({
    blockId: ''
})

export const BlockSettingProvider = ({ blockId, scope, children }: { blockId: string; scope?: string; children: React.ReactNode }) => {
    const ctxValue = useMemo(() => ({ blockId, scope }), [blockId, scope])
    return <BlockSettingContext.Provider value={ctxValue}>{children}</BlockSettingContext.Provider>
}

export const useBlockSettingContext = () => useContext(BlockSettingContext)

BlockSettingContext.displayName = 'blockSettingContext'
