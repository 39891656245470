import { Flex, Image, Text } from '@byecode/ui'
import type { BlockAbstract } from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import type { TemplateBlock } from '@/atoms/application/type'

interface BlockCardProps {
    data: TemplateBlock
    index: number
    onOpenQuestion?: (v: BlockAbstract) => void
    onCreate?: (e: React.MouseEvent<HTMLDivElement>, v: BlockAbstract) => void
}

const SCxItem = styled(Flex)`
    display: flex;
    flex-direction: column;
    position: relative;
    width: 112px;
    height: fit-content;
    min-height: 0;
    cursor: pointer;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
    gap: 4px;
    /* pointer-events: none; */

    img {
        -webkit-user-drag: none;
    }
`

const SCxWrapper = styled.div`
    width: 100%;
    padding: 6px;
    border-radius: 8px;
    background-color: var(--color-gray-100);
    &:hover {
        background-color: var(--color-gray-200);
    }

    img {
        -webkit-user-drag: none;
    }
`

const SCxBlockImage = styled(Image)`
    border-radius: 6px;
    pointer-events: none;
    overflow: hidden;
`
const BlockCard: React.FunctionComponent<BlockCardProps> = ({ data, index, onCreate }) => {
    const { name, image, block } = data

    return (
        <SCxItem style={{ order: (index % 2) + 1 }}>
            <SCxWrapper draggable="false" onMouseDown={e => onCreate?.(e, block)}>
                <SCxBlockImage width={100} draggable="false" src={image} />
            </SCxWrapper>
            <Text
                draggable="false"
                size={12}
                lineHeight="20px"
                algin="center"
                style={{ pointerEvents: 'none' }}
                color="var(--color-gray-700)"
            >
                {name}
            </Text>
        </SCxItem>
    )
}

export default BlockCard
