import type { Option, SegmentedControlItem } from '@byecode/ui'
import { Box, Flex, Group, IconFont, Input, Popover, SegmentedControl, Select, Slider, Switch, Text } from '@byecode/ui'
import type { Field, SubFormBlockConfig } from '@lighthouse/core'
import { type FieldInputConfigProtocol, BACKGROUND_TYPE } from '@lighthouse/core'
import type { RichTextEditorProps } from '@lighthouse/shared'
import {
    checkBoxShape,
    DATE_SYSTEM,
    FilledInput,
    FillPickerPopoverDropdown,
    getAllOptions,
    getImageFullUrlInApplication,
    IconPicker,
    ListItemPaddingByecodeUi,
    RichTextEditor,
    TagIcon,
    useAtomData
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { uploadManagerInAppParams } from '@/utils/auth'

import type { BaseFieldInputSetting } from '../types'

interface CheckboxConfigProps extends Pick<BaseFieldInputSetting, 'prefix'> {}

const shapeOptions = [
    {
        label: '复选框',
        icon: <IconFont size={16} color="var(--color-gray-400)" type="PropertyCheckboxBlock" />,
        value: 'checkbox'
    },
    {
        label: '开关',
        icon: <IconFont size={16} color="var(--color-gray-400)" type="switch" />,
        value: 'switch'
    }
]

const gapOptions = [
    {
        label: '固定间距',
        value: 'fixed'
    },
    {
        label: '自动分布',
        value: 'auto'
    }
]

/** 位置选项 */
const TEXT_POSITION_CONTROL: SegmentedControlItem[] = [
    {
        label: '左',
        value: 'left'
    },
    {
        label: '右',
        value: 'right'
    }
]
const SCxShapeDropContainer = styled.div`
    display: flex;
    gap: 8px;
    padding: 0 4px;
`

const SCxRichTextContainer = styled.div<{ highlighting?: boolean }>`
    margin-top: 12px;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;

    .ProseMirror {
        min-height: 120px;
    }

    ${({ highlighting }) =>
        highlighting &&
        css`
            border-color: var(--color-main);
            box-shadow: 0 0 4px 0 var(--color-main);
            overflow: hidden;
        `}
`

const defaultValueOptions = [
    {
        label: '是',
        value: 'true'
    },
    { label: '否', value: 'false' }
]

const checkboxTypeOptions: Option[] = [
    {
        label: '复选框',
        value: 'checkbox',
        icon: <IconFont type="PropertyCheckbox" fill="var(--color-gray-400)" />
    },
    {
        label: '开关',
        value: 'switch',
        icon: <IconFont type="Switch" fill="var(--color-gray-400)" />
    },
    {
        label: '自定义',
        value: 'custom',
        icon: <IconFont type="SmileCircleIconLibrary" fill="var(--color-gray-400)" />
    }
]

export const CheckboxConfig: React.FunctionComponent<CheckboxConfigProps> = ({ prefix }) => {
    const appId = useCurrentAppID()
    const { watch, control } = useFormContext<FieldInputConfigProtocol | SubFormBlockConfig>()
    const [gapType, type] = watch([`${prefix}checkbox.gapType`, `${prefix}checkbox.type`])
    const disabledWithVersion = useIsDisabledWithVersion()
    const [opened, setOpened] = useState(false)
    const colorSystemMethods = useColorSystemAction()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { prev, curr, dataSourceList } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const {
        userOption,
        pageOption: options,
        systemOption
    } = useMemo(() => {
        const filter = (field: Field) => !['file', 'video', 'notes'].includes(field.type)
        return getAllOptions({
            user: true,
            page: {
                prevDsId: prev.datasource?.id,
                curDsId: curr.datasource?.id
            },
            system: DATE_SYSTEM,
            dataSourceList,
            validateField: filter
        })
    }, [curr.datasource?.id, dataSourceList, prev.datasource?.id])

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({
            heading: false,
            bulletList: false,
            orderedList: false,
            taskList: false,
            fontSize: false,
            variable: {
                options,
                userOption,
                systemOption,
                dataSources: dataSourceList
            },
            image: false
        }),
        [dataSourceList, options, systemOption, userOption]
    )
    return (
        <Group label="开关">
            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                <Text>类型</Text>
                <div style={{ width: 180 }}>
                    <Controller
                        control={control}
                        name={`${prefix}checkbox.type`}
                        render={({ field }) => (
                            <Select style={{ width: '100%' }} value={field.value} onChange={field.onChange} options={checkboxTypeOptions} />
                        )}
                    />
                </div>
            </ListItemPaddingByecodeUi>
            {type === 'checkbox' && (
                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>形状</Text>
                    <Controller
                        control={control}
                        name={`${prefix}checkbox.shape`}
                        render={({ field }) => (
                            <Popover opened={opened} position="bottom-end" width="auto" onChange={setOpened}>
                                <Popover.Target>
                                    <TagIcon
                                        size={24}
                                        isActive={opened}
                                        radius={5}
                                        background="rgba(38, 65, 90, 0.06)"
                                        enableHover
                                        color="var(--color-main)"
                                        icon={find(v => v.value === field.value, checkBoxShape)?.icon}
                                    />
                                </Popover.Target>
                                <Popover.Dropdown>
                                    <SCxShapeDropContainer>
                                        {checkBoxShape.map(item => (
                                            <TagIcon
                                                size={24}
                                                enableHover
                                                key={item.value}
                                                icon={item.icon}
                                                radius={5}
                                                color="var(--color-main)"
                                                hoverBackground="rgba(38, 65, 90, 0.06)"
                                                onClick={() => {
                                                    field.onChange(item.value)
                                                    setOpened(false)
                                                }}
                                            />
                                        ))}
                                    </SCxShapeDropContainer>
                                </Popover.Dropdown>
                            </Popover>
                        )}
                    />
                </ListItemPaddingByecodeUi>
            )}
            {type === 'custom' && (
                <>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>当开启时</Text>
                        <Flex style={{ width: 180 }} gap={8}>
                            <Controller
                                control={control}
                                name={`${prefix}checkbox.checkedIcon.color`}
                                render={({ field }) => {
                                    const value = field.value && {
                                        type: field.value.type,
                                        color: field.value.color,
                                        gradient: field.value.gradient
                                    }
                                    return (
                                        <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                                            <Popover.Target>
                                                <FilledInput
                                                    style={{ width: 180 }}
                                                    value={value}
                                                    onClear={() => {
                                                        field.onChange({ ...field.value, type: BACKGROUND_TYPE.color, color: '' })
                                                    }}
                                                />
                                            </Popover.Target>
                                            <FillPickerPopoverDropdown
                                                value={field.value}
                                                onChange={field.onChange}
                                                enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
                                                {...colorSystemMethods}
                                            />
                                        </Popover>
                                    )
                                }}
                            />
                            <Controller
                                control={control}
                                name={`${prefix}checkbox.checkedIcon.icon`}
                                render={({ field }) => (
                                    <IconPicker
                                        value={field.value}
                                        uploadParams={uploadManagerInAppParams()}
                                        onGetFullUrl={url => getImageFullUrlInApplication(appId, url)}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </Flex>
                    </ListItemPaddingByecodeUi>
                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>当关闭时</Text>
                        <Flex style={{ width: 180 }} gap={8}>
                            <Controller
                                control={control}
                                name={`${prefix}checkbox.unCheckedIcon.color`}
                                render={({ field }) => {
                                    const value = field.value && {
                                        type: field.value.type,
                                        color: field.value.color,
                                        gradient: field.value.gradient
                                    }
                                    return (
                                        <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                                            <Popover.Target>
                                                <FilledInput
                                                    style={{ width: 180 }}
                                                    value={value}
                                                    onClear={() => {
                                                        field.onChange({ ...field.value, type: BACKGROUND_TYPE.color, color: '' })
                                                    }}
                                                />
                                            </Popover.Target>
                                            <FillPickerPopoverDropdown
                                                value={field.value}
                                                onChange={field.onChange}
                                                enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
                                                {...colorSystemMethods}
                                            />
                                        </Popover>
                                    )
                                }}
                            />
                            <Controller
                                control={control}
                                name={`${prefix}checkbox.unCheckedIcon.icon`}
                                render={({ field }) => (
                                    <IconPicker
                                        value={field.value}
                                        uploadParams={uploadManagerInAppParams()}
                                        onGetFullUrl={url => getImageFullUrlInApplication(appId, url)}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </Flex>
                    </ListItemPaddingByecodeUi>
                </>
            )}

            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                <Text>与描述的间距</Text>
                <div style={{ width: 180 }}>
                    <Controller
                        control={control}
                        name={`${prefix}checkbox.gapType`}
                        render={({ field }) => (
                            <SegmentedControl fullWidth data={gapOptions} value={field.value} onChange={field.onChange} />
                        )}
                    />
                </div>
            </ListItemPaddingByecodeUi>
            {gapType === 'fixed' && (
                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>间距</Text>
                    <Controller
                        control={control}
                        name={`${prefix}checkbox.gap`}
                        render={({ field }) => {
                            return (
                                <Slider
                                    min={0}
                                    max={5}
                                    step={0.125}
                                    defaultValue={field.value}
                                    onChangeEnd={field.onChange}
                                    style={{ width: 180 }}
                                    inputProps={{
                                        onFocus: e => {
                                            e.target.select()
                                        }
                                    }}
                                />
                            )
                        }}
                    />
                </ListItemPaddingByecodeUi>
            )}
            <Box style={{ padding: '12px 0' }}>
                <Text lineHeight="16px">描述</Text>
                <Controller
                    name={`${prefix}checkbox.describe`}
                    control={control}
                    render={({ field: { value, onChange } }) => {
                        return (
                            <SCxRichTextContainer>
                                <RichTextEditor
                                    disabled={disabledWithVersion}
                                    config={config}
                                    value={value}
                                    onChange={onChange}
                                    styles={{
                                        editorContent: {
                                            backgroundColor: 'var(--color-gray-50)',
                                            borderRadius: '0 0 8px 8px',
                                            overflow: 'hidden'
                                        }
                                    }}
                                />
                            </SCxRichTextContainer>
                        )
                    }}
                />
            </Box>
            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                <Text>描述位置</Text>
                <div style={{ width: 180 }}>
                    <Controller
                        control={control}
                        name={`${prefix}checkbox.describePosition`}
                        render={({ field }) => (
                            <SegmentedControl fullWidth data={TEXT_POSITION_CONTROL} value={field.value} onChange={field.onChange} />
                        )}
                    />
                </div>
            </ListItemPaddingByecodeUi>
        </Group>
    )
}
