import { Divider } from '@byecode/ui'
import type { IconBlockConfig } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import { getImageFullUrlInApplication, Group4ByecodeUi, IconPicker, ListItem4ByecodeUi } from '@lighthouse/shared'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { ActionAdder } from '@/components/ActionAdder'
import { getBlockAndPageDesignLimit, PositionSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { useSetExtendsKeys } from '@/components/DesignSetting/hooks/useMouseEventDistance'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { FillColor } from '@/components/DesignSetting/StyleSetting/FillColor'
import { InheritLabel } from '@/components/InheritLabel'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useCurrentAppID } from '@/hooks/useApplication'
import { usePageList } from '@/hooks/usePage'
import { uploadManagerInAppParams } from '@/utils/auth'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'

interface IconSettingProps {}

export const IconSetting: React.FC<IconSettingProps> = () => {
    const { control, watch } = useFormContext<IconBlockConfig>()
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const designProps = getBlockAndPageDesignLimit('icon')
    const appId = useCurrentAppID()

    const [action] = watch(['action'])
    const { handleSetBreakKey } = useSetExtendsKeys()
    const allPages = usePageList()
    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <Group4ByecodeUi label="图标">
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <InheritLabel label="选择图标" name="breakPoint.icon" />
                            <Controller
                                control={control}
                                name="breakPoint.icon"
                                render={({ field }) => (
                                    <IconPicker
                                        value={field.value}
                                        uploadParams={uploadManagerInAppParams()}
                                        onGetFullUrl={url => getImageFullUrlInApplication(appId, url)}
                                        onChange={v => {
                                            handleSetBreakKey('breakPoint.icon')
                                            field.onChange(v)
                                        }}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                    </Group4ByecodeUi>
                    <Divider />
                    <Group4ByecodeUi label="样式">
                        <FillColor
                            label="图标颜色"
                            enablePadding
                            enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
                            onSetBreakKey={handleSetBreakKey}
                        />
                    </Group4ByecodeUi>
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group4ByecodeUi
                        label="动作"
                        styles={{
                            collapse: {
                                padding: '0 8px !important'
                            }
                        }}
                    >
                        <ActionAdder
                            title=""
                            showLabel={false}
                            prefixName=""
                            events={{
                                params: [],
                                handleEvent: 'none',
                                triggerEvent: 'click'
                            }}
                            allPages={allPages}
                            action={
                                action ?? {
                                    type: 'none',
                                    params: {},
                                    trigger: 'click'
                                }
                            }
                        />
                    </Group4ByecodeUi>
                    <Divider />
                    <VisibilitySetting />
                </>
            )}
        </>
    )
}
