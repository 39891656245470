import { Divider, Flex, Input, SegmentedControl, Switch } from '@byecode/ui'
import type { FloatBlockConfig } from '@lighthouse/core'
import { Group4ByecodeUi, ListItem4ByecodeUi } from '@lighthouse/shared'
import React, { useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { AutoLayoutSetting, getBlockAndPageDesignLimit, StyleSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { useSetExtendsKeys } from '@/components/DesignSetting/hooks/useMouseEventDistance'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { InheritLabel } from '@/components/InheritLabel'
import { PopoverQuestion } from '@/components/PopoverQuestion'
import { VisibilitySetting } from '@/components/VisibilitySetting'

import { OffsetInput } from '../../DesignSetting/offset'
import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import {
    BLOCK_SETTINGS_SEGMENTED_ENUM,
    legendTranspose,
    POPOVER_HORIZONTAL_ALIGN_CONTROL,
    POPOVER_VERTICAL_ALIGN_CONTROL
} from '../constants'

interface FloatSettingProps {}
const SHOW_ON_OPTIONS = [
    {
        label: '鼠标移入',
        value: 'hover'
    },
    {
        label: '点击',
        value: 'click'
    }
]

const DISMISS_OPTIONS = [
    {
        label: '鼠标移出',
        value: 'hover'
    },
    {
        label: '点击',
        value: 'click'
    }
]

export const FloatSetting: React.FC<FloatSettingProps> = () => {
    const { control, watch } = useFormContext<FloatBlockConfig>()
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const designProps = getBlockAndPageDesignLimit('floatBox')

    const [position, autoPosition] = watch(['breakPoint.overlay.position', 'breakPoint.overlay.autoPosition'])
    const { handleSetBreakKey } = useSetExtendsKeys()

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <Group4ByecodeUi label="弹出窗口">
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <PopoverQuestion
                                type="overlay.showOn"
                                renderTarget={(node, ref) => (
                                    <Flex gap={4} alignItems="center" ref={ref}>
                                        <InheritLabel label="弹出时机" name="breakPoint.overlay.showOn" />
                                        {node}
                                    </Flex>
                                )}
                            />
                            <div style={{ width: 180 }}>
                                <Controller
                                    control={control}
                                    name="breakPoint.overlay.showOn"
                                    render={({ field }) => (
                                        <SegmentedControl
                                            value={field.value}
                                            fullWidth
                                            data={SHOW_ON_OPTIONS}
                                            onChange={v => {
                                                handleSetBreakKey('breakPoint.overlay.showOn')
                                                field.onChange(v)
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <PopoverQuestion
                                type="overlay.dismiss"
                                renderTarget={(node, ref) => (
                                    <Flex gap={4} alignItems="center" ref={ref}>
                                        <InheritLabel label="关闭时机" name="breakPoint.overlay.dismiss" />
                                        {node}
                                    </Flex>
                                )}
                            />
                            <div style={{ width: 180 }}>
                                <Controller
                                    control={control}
                                    name="breakPoint.overlay.dismiss"
                                    render={({ field }) => (
                                        <SegmentedControl
                                            value={field.value}
                                            fullWidth
                                            data={DISMISS_OPTIONS}
                                            onChange={v => {
                                                handleSetBreakKey('breakPoint.overlay.dismiss')
                                                field.onChange(v)
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <InheritLabel label="相对位置" name="breakPoint.overlay.position" />
                            <div style={{ width: 180 }}>
                                <Controller
                                    control={control}
                                    name="breakPoint.overlay.position"
                                    render={({ field }) => (
                                        <SegmentedControl
                                            value={field.value}
                                            fullWidth
                                            data={legendTranspose}
                                            onChange={v => {
                                                handleSetBreakKey('breakPoint.overlay.position')
                                                field.onChange(v)
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <InheritLabel label="对齐" name="breakPoint.overlay.align" />
                            <div style={{ width: 180 }}>
                                <Controller
                                    control={control}
                                    name="breakPoint.overlay.align"
                                    render={({ field }) => (
                                        <SegmentedControl
                                            value={field.value}
                                            data={
                                                ['left', 'right'].includes(position)
                                                    ? POPOVER_VERTICAL_ALIGN_CONTROL
                                                    : POPOVER_HORIZONTAL_ALIGN_CONTROL
                                            }
                                            fullWidth
                                            onChange={v => {
                                                handleSetBreakKey('breakPoint.overlay.align')
                                                field.onChange(v)
                                            }}
                                        />
                                    )}
                                />
                            </div>
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <InheritLabel label="偏移量" name="breakPoint.overlay.offset" />
                            <Controller
                                control={control}
                                name="breakPoint.overlay.offset"
                                render={({ field }) => (
                                    <OffsetInput
                                        value={field.value}
                                        onChange={val => {
                                            handleSetBreakKey('breakPoint.overlay.offset')
                                            field.onChange(val)
                                        }}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <InheritLabel label="自适应位置" name="breakPoint.overlay.autoPosition" />
                            <Controller
                                control={control}
                                name="breakPoint.overlay.autoPosition"
                                render={({ field }) => (
                                    <Switch
                                        checked={field.value}
                                        onChange={e => {
                                            handleSetBreakKey('breakPoint.overlay.autoPosition')
                                            field.onChange(e.target.checked)
                                        }}
                                    />
                                )}
                            />
                        </ListItem4ByecodeUi>
                        {autoPosition && (
                            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                                <InheritLabel label="自适应触发距离" name="breakPoint.overlay.autoDistance" />
                                <Controller
                                    control={control}
                                    name="breakPoint.overlay.autoDistance"
                                    render={({ field }) => (
                                        <Input
                                            style={{ width: 180 }}
                                            defaultValue={field.value}
                                            type="number"
                                            onBlur={e => {
                                                handleSetBreakKey('breakPoint.overlay.autoDistance')
                                                field.onChange(e.target.value)
                                            }}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                        )}
                    </Group4ByecodeUi>
                    <Divider />
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <AutoLayoutSetting {...designProps.layout} />
                    <Divider />
                    <StyleSetting {...designProps.design} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <VisibilitySetting />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}
        </>
    )
}
