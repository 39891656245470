import type { Option } from '@byecode/ui'
import { Divider, Flex, Group, IconFont, Popover, SegmentedControl, Select, Text } from '@byecode/ui'
import type { OnOffBlockConfig, PageAbstract } from '@lighthouse/core'
import { BACKGROUND_TYPE, SwitchTypeEnum } from '@lighthouse/core'
import {
    checkBoxShape,
    FilledInput,
    FillPickerPopoverDropdown,
    getImageFullUrlInApplication,
    Group4ByecodeUi,
    IconPicker,
    ListItem4ByecodeUi,
    ListItemPaddingByecodeUi,
    TagIcon
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { ActionAdder } from '@/components/ActionAdder'
import { getBlockAndPageDesignLimit, PositionSetting } from '@/components/DesignSetting'
import AnimationController from '@/components/DesignSetting/AnimationSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'
import { uploadManagerInAppParams } from '@/utils/auth'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'

const SCxShapeDropContainer = styled.div`
    display: flex;
    gap: 8px;
    padding: 0 4px;
`

const onOffTypeOptions: Option[] = [
    {
        label: '复选框',
        value: SwitchTypeEnum.CHECKBOX,
        icon: <IconFont type="PropertyCheckbox" fill="var(--color-gray-400)" />
    },
    {
        label: '开关',
        value: SwitchTypeEnum.SWITCH,
        icon: <IconFont type="Switch" fill="var(--color-gray-400)" />
    },
    {
        label: '自定义',
        value: SwitchTypeEnum.CUSTOM,
        icon: <IconFont type="SmileCircleIconLibrary" fill="var(--color-gray-400)" />
    }
]

const onOffDefaultValueOptions = [
    {
        label: '关闭',
        value: 'false'
    },
    {
        label: '开启',
        value: 'true'
    }
]

interface OnOffSettingProps {
    allPages: PageAbstract[]
}

export const OnOffSetting: React.FC<OnOffSettingProps> = ({ allPages }) => {
    const { control, watch } = useFormContext<OnOffBlockConfig>()
    const [opened, setOpened] = useState(false)
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const designProps = getBlockAndPageDesignLimit('onOff')
    const [type, openAction, closeAction] = watch(['showType', 'open.action', 'close.action'])
    const appId = useCurrentAppID()
    const colorSystemMethods = useColorSystemAction()
    return (
        <>
            <BlockConfigureSegmentedControl isInterAction value={currentTab} onChange={setCurrentTab} />
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <Group label="开关">
                        <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>类型</Text>
                            <div style={{ width: 180 }}>
                                <Controller
                                    control={control}
                                    name="showType"
                                    render={({ field }) => (
                                        <Select
                                            style={{ width: '100%' }}
                                            value={field.value}
                                            onChange={field.onChange}
                                            options={onOffTypeOptions}
                                        />
                                    )}
                                />
                            </div>
                        </ListItemPaddingByecodeUi>
                        {type === SwitchTypeEnum.CHECKBOX && (
                            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                <Text>形状</Text>
                                <Controller
                                    control={control}
                                    name="shape"
                                    render={({ field }) => (
                                        <Popover opened={opened} position="bottom-end" width="auto" onChange={setOpened}>
                                            <Popover.Target>
                                                <TagIcon
                                                    size={24}
                                                    isActive={opened}
                                                    radius={5}
                                                    background="rgba(38, 65, 90, 0.06)"
                                                    enableHover
                                                    color="var(--color-main)"
                                                    icon={find(v => v.value === field.value, checkBoxShape)?.icon}
                                                />
                                            </Popover.Target>
                                            <Popover.Dropdown>
                                                <SCxShapeDropContainer>
                                                    {checkBoxShape.map(item => (
                                                        <TagIcon
                                                            size={24}
                                                            enableHover
                                                            key={item.value}
                                                            icon={item.icon}
                                                            radius={5}
                                                            color="var(--color-main)"
                                                            hoverBackground="rgba(38, 65, 90, 0.06)"
                                                            onClick={() => {
                                                                field.onChange(item.value)
                                                                setOpened(false)
                                                            }}
                                                        />
                                                    ))}
                                                </SCxShapeDropContainer>
                                            </Popover.Dropdown>
                                        </Popover>
                                    )}
                                />
                            </ListItemPaddingByecodeUi>
                        )}
                        {type === SwitchTypeEnum.CUSTOM && (
                            <>
                                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>当开启时</Text>
                                    <Flex style={{ width: 180 }} gap={8}>
                                        <Controller
                                            control={control}
                                            name="checkedIcon.color"
                                            render={({ field }) => {
                                                const value = field.value && {
                                                    type: field.value.type,
                                                    color: field.value.color,
                                                    gradient: field.value.gradient
                                                }
                                                return (
                                                    <Popover
                                                        width={300}
                                                        withinPortal
                                                        position="left-start"
                                                        autoUpdate={false}
                                                        offsetOptions={168}
                                                    >
                                                        <Popover.Target>
                                                            <FilledInput
                                                                style={{ width: 180 }}
                                                                value={value}
                                                                onClear={() => {
                                                                    field.onChange({
                                                                        ...field.value,
                                                                        type: BACKGROUND_TYPE.color,
                                                                        color: ''
                                                                    })
                                                                }}
                                                            />
                                                        </Popover.Target>
                                                        <FillPickerPopoverDropdown
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
                                                            {...colorSystemMethods}
                                                        />
                                                    </Popover>
                                                )
                                            }}
                                        />
                                        <Controller
                                            control={control}
                                            name="checkedIcon.icon"
                                            render={({ field }) => (
                                                <IconPicker
                                                    value={field.value}
                                                    uploadParams={uploadManagerInAppParams()}
                                                    onGetFullUrl={url => getImageFullUrlInApplication(appId, url)}
                                                    onChange={field.onChange}
                                                />
                                            )}
                                        />
                                    </Flex>
                                </ListItemPaddingByecodeUi>
                                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>当关闭时</Text>
                                    <Flex style={{ width: 180 }} gap={8}>
                                        <Controller
                                            control={control}
                                            name="unCheckedIcon.color"
                                            render={({ field }) => {
                                                const value = field.value && {
                                                    type: field.value.type,
                                                    color: field.value.color,
                                                    gradient: field.value.gradient
                                                }
                                                return (
                                                    <Popover
                                                        width={300}
                                                        withinPortal
                                                        position="left-start"
                                                        autoUpdate={false}
                                                        offsetOptions={168}
                                                    >
                                                        <Popover.Target>
                                                            <FilledInput
                                                                style={{ width: 180 }}
                                                                value={value}
                                                                onClear={() => {
                                                                    field.onChange({
                                                                        ...field.value,
                                                                        type: BACKGROUND_TYPE.color,
                                                                        color: ''
                                                                    })
                                                                }}
                                                            />
                                                        </Popover.Target>
                                                        <FillPickerPopoverDropdown
                                                            value={field.value}
                                                            onChange={field.onChange}
                                                            enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
                                                            {...colorSystemMethods}
                                                        />
                                                    </Popover>
                                                )
                                            }}
                                        />
                                        <Controller
                                            control={control}
                                            name="unCheckedIcon.icon"
                                            render={({ field }) => (
                                                <IconPicker
                                                    value={field.value}
                                                    uploadParams={uploadManagerInAppParams()}
                                                    onGetFullUrl={url => getImageFullUrlInApplication(appId, url)}
                                                    onChange={field.onChange}
                                                />
                                            )}
                                        />
                                    </Flex>
                                </ListItemPaddingByecodeUi>
                            </>
                        )}
                        <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>默认</Text>
                            <Flex style={{ width: 180 }}>
                                <Controller
                                    control={control}
                                    name="defaultChecked"
                                    render={({ field }) => (
                                        <SegmentedControl
                                            fullWidth
                                            style={{ width: 172 }}
                                            size="md"
                                            value={field.value ? 'true' : 'false'}
                                            onChange={val => {
                                                field.onChange(val === 'true')
                                            }}
                                            data={onOffDefaultValueOptions}
                                        />
                                    )}
                                />
                            </Flex>
                        </ListItemPaddingByecodeUi>
                    </Group>
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && (
                <>
                    <Group4ByecodeUi
                        label="动作"
                        styles={{
                            collapse: {
                                padding: '0 8px !important'
                            }
                        }}
                    >
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <Text>当开启时执行</Text>
                        </ListItem4ByecodeUi>
                        <ActionAdder
                            title=""
                            showLabel={false}
                            prefixName="open"
                            events={{
                                params: [],
                                handleEvent: 'none',
                                triggerEvent: 'click'
                            }}
                            allPages={allPages}
                            action={
                                openAction ?? {
                                    type: 'none',
                                    params: {},
                                    trigger: 'click'
                                }
                            }
                        />
                        <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                            <Text>当关闭时执行</Text>
                        </ListItem4ByecodeUi>
                        <ActionAdder
                            title=""
                            showLabel={false}
                            prefixName="close"
                            events={{
                                params: [],
                                handleEvent: 'none',
                                triggerEvent: 'click'
                            }}
                            allPages={allPages}
                            action={
                                closeAction ?? {
                                    type: 'none',
                                    params: {},
                                    trigger: 'click'
                                }
                            }
                        />
                    </Group4ByecodeUi>
                    <Divider />
                    <AnimationController />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <VisibilitySetting />
                </>
            )}
        </>
    )
}
