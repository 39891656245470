import type { ElementProps, FloatingContext } from '@floating-ui/react'
import { useMemo } from 'react'

export interface useDismissEscapeProps {
    enabled?: boolean
}

export const useDismissEscape = (context: FloatingContext, props: useDismissEscapeProps) => {
    const { open, onOpenChange } = context

    const { enabled = true } = props

    const floating: ElementProps['floating'] = useMemo(
        () => ({
            onKeyDown(event) {
                if (event.key === 'Escape' && enabled) {
                    event.stopPropagation()
                    onOpenChange?.(false)
                }
            }
        }),
        [enabled, onOpenChange]
    )

    return useMemo(() => (enabled ? { floating } : {}), [enabled, floating])
}
