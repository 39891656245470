import { Empty, hoverScrollBar, IconFont, Text, Toast } from '@byecode/ui'
import type { SubFormColumn, ValueVariable, VariableADTvalue } from '@lighthouse/core'
import {
    type DataSourceAbstract,
    type FieldInputADTValue,
    type InputValueItem,
    type SubFormBlockAbstract,
    VariableType
} from '@lighthouse/core'
import type { FieldInputError, RelativeDataSourceConfigItem, SubFormErrors } from '@lighthouse/shared'
import {
    type AppendParams,
    type ApplicationPreviewEnum,
    type CascadeParam,
    type CheckBoxParam,
    type PersonParam,
    type PhoneNumberParam,
    type RelativeFieldParam,
    type UseUploadFileSParameter,
    getFieldInputInitialValue
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { Flex, ScrollArea } from '@mantine/core'
import type { UploadyProps } from '@rpldy/uploady'
import equal from 'fast-deep-equal'
import { find, findIndex, reduce } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useAsyncRetry, useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import type { FieldBlockInfo } from '../../components';
import { AnimationDecorators } from '../../components'
import { parseExcel } from './help'
import { SubFormContent } from './SubFormContent'
import type { SubFormFieldInputContextType } from './SubFormFieldInputProvider'
import { SubFormFieldInputProvider } from './SubFormFieldInputProvider'
import { SubFormFooter } from './SubFormFooter'
import { SubFormHeader } from './SubFormHeader'
import type { SubFormRecord } from './types'

export * from './types'

interface SubFormBlockProps
    extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>,
        RelativeFieldParam,
        CascadeParam,
        Pick<PhoneNumberParam, 'onFetchSmsCode'>,
        PersonParam,
        CheckBoxParam {
    dataList: SubFormRecord[]
    appId: string
    envId?: string
    blockData: SubFormBlockAbstract
    // field?: Field
    initData?: SubFormRecord
    previewType?: ApplicationPreviewEnum
    language: string
    dataSource?: DataSourceAbstract
    isValid: boolean
    errors?: SubFormErrors
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    richTextUploadOptions: UploadyProps
    videoUploadyOption: Pick<UseUploadFileSParameter, 'info' | 'options'>
    onChange?: (v: (v: Record<string, SubFormRecord[]>) => void) => void
    onInputChange?: (blockId: string, record: string, columnId: string, value: FieldInputADTValue) => void
    onVariableValueRender: (fieldBlockInfo: FieldBlockInfo, fieldBlockValueMap?: Record<string, InputValueItem>) => Promise<InputValueItem>
    onBlockChange?: (values: SubFormBlockAbstract, origin: SubFormBlockAbstract) => Promise<void> | void
    onInputBlur?: (blockId: string, recordId: string, columnId: string) => void
    onGetInitRecord?: (v: RelativeDataSourceConfigItem) => void
}

const SCxContainer = styled(AnimationDecorators)`
    width: 100%;
    height: 100%;
    overflow: visible;
    background-color: #ffffff;
    font-size: var(--font-size-normal);
    padding: var(--block-padding);
`

const SCxWrapper = styled.div`
    max-width: 100%;
    border: 1px solid var(--color-gray-200);
    border-radius: 8px;
    max-width: 100%;
    overflow: auto hidden;
    display: flex;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    vertical-align: middle;
    ${hoverScrollBar}
    ::-webkit-scrollbar-thumb {
        background: #ccc;
    }
`

const SCxTable = styled.div`
    display: inline-flex;
    flex-direction: column;
`

const SubForm: React.FunctionComponent<SubFormBlockProps> = ({
    appId,
    envId,
    blockData,
    dataList,
    relativeDataSource,
    isValid,
    language,
    previewType,
    dataSource,
    errors,
    dataSourceList,
    uploadyOptions,
    richTextUploadOptions,
    videoUploadyOption,
    initData,
    onVariableValueRender,
    onFetchCascadeOptions,
    onInputChange,
    onFetchDataSource,
    onFetchDataSourceMeta,
    onFetchPersonOptions,
    onFetchSmsCode,
    onLoadMoreData,
    onOpenPage,
    onChange,
    onInputBlur,
    onBlockChange,
    onGetInitRecord,
    onRenderLabel,
    ...rest
}) => {
    const {
        config,
        id: blockId,
        config: { columns, pointer = '', breakPoint }
    } = blockData

    const initRecordArr = useMemo(() => Object.entries(initData?.content ?? {}), [initData?.content])
    const initColumnsWidth = useMemo(() => Object.fromEntries(columns.map(({ id, width }) => [id, width])), [columns])
    const [columnsWidth, setColumnsWidth] = useImmer(initColumnsWidth)

    const handleChangeColumnWidth = useCallback(
        (id: string, width: number, isFinished: boolean) => {
            setColumnsWidth(draft => {
                draft[id] = width
                if (isFinished) {
                    const newColumns = columns.map(column => ({ ...column, width: draft[column.id] }))
                    onBlockChange?.({ ...blockData, config: { ...config, columns: newColumns } }, blockData)
                }
            })
        },
        [blockData, columns, config, onBlockChange, setColumnsWidth]
    )

    const handleAddRecord = useCallback(() => {
        if (!initRecordArr) {
            return
        }
        const newRecord: SubFormRecord = {
            id: nanoid(),
            content: Object.fromEntries(initRecordArr)
        }
        onChange?.(draft => {
            if (!draft[blockId]) {
                draft[blockId] = []
            }
            if (draft[blockId].length >= 100) {
                Toast.warning('最多添加100条')
                return
            }
            draft[blockId].push(newRecord)
        })
    }, [blockId, initRecordArr, onChange])

    const handleRecordCopy = useCallback(
        (id: string) => {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                const newRecordIndex = findIndex(i => i.id === id, draft[blockId])
                if (newRecordIndex === -1) {
                    return
                }
                const newRecord = draft[blockId][newRecordIndex]
                draft[blockId].splice(newRecordIndex, 0, { ...newRecord, id: nanoid() })
            })
        },
        [onChange, blockId]
    )

    const handleRecordRemove = useCallback(
        (id: string) => {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                const index = findIndex(i => i.id === id, draft[blockId])
                if (index === -1) {
                    return
                }
                draft[blockId].splice(index, 1)
            })
        },
        [onChange, blockId]
    )

    const getSubscribeFieldInput = useCallback(
        (columnId: string) =>
            columns.filter(item => {
                const conditions =
                    item.config?.initialValue?.type === VariableType.SELECT_DATASOURCE
                        ? item.config.initialValue.selectDataSourceVariable?.filter?.expression?.conditions
                        : []
                return conditions?.some(condition => {
                    const variable = condition.paramList?.[0]
                    if (variable?.type === VariableType.INPUT) {
                        return variable.inputVariable.blockId === columnId
                    }
                    return false
                })
            }),
        [columns]
    )

    const handleChangeColumnInitValue = useCallback(
        (columnId: string, recordId: string) => {
            const updateColumnList = getSubscribeFieldInput(columnId)
            onChange?.(draft => {
                const records = draft[blockId]
                updateColumnList.forEach(async item => {
                    const {
                        id,
                        config,
                        config: { fieldPointer: fieldId = '', inputType }
                    } = item
                    const fieldValueMap = records.find(v => v.id === recordId)?.content
                    const fieldInfo: FieldBlockInfo = { id, dsId: pointer, inputType, fieldId, config }
                    const value = await onVariableValueRender(fieldInfo, fieldValueMap)
                    config.inputType === 'relativeSelect' && onGetInitRecord?.({ config, value })

                    onInputChange?.(blockId, recordId, id, value)
                })
            })
        },
        [blockId, getSubscribeFieldInput, onChange, onGetInitRecord, onInputChange, onVariableValueRender, pointer]
    )

    const handleCellChange = useCallback(
        (recordId: string, columnId: string, fieldValue: FieldInputADTValue) => {
            onInputChange?.(blockId, recordId, columnId, fieldValue)
            handleChangeColumnInitValue(columnId, recordId)
        },
        [blockId, handleChangeColumnInitValue, onInputChange]
    )

    const handleCellBlur = useCallback(
        (recordId: string, columnId: string) => {
            onInputBlur?.(blockId, recordId, columnId)
        },
        [blockId, onInputBlur]
    )
    const handleSmsCodeChange = useCallback(
        (recordId: string, columnId: string, code: string) => {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                const record = find(i => i.id === recordId, draft[blockId])
                if (!record) {
                    return
                }
                record.content[columnId].code = code
            })
        },
        [blockId, onChange]
    )

    const handleAppend = useCallback(
        async (v: AppendParams) => {
            const {
                file,
                sheetDto: { fields = [], sheetName }
            } = v
            const { records: list } = await parseExcel(file, sheetName)
            const newList: SubFormRecord[] = await Promise.all(
                list.map(async (item, index) => {
                    const arrContent = await Promise.all(
                        columns.map(async column => {
                            const { id, config } = column
                            const { inputType, fieldPointer = '' } = config
                            const field = dataSource?.schema[id]
                            const keyIndex = fields.find(i => i.dsFieldId === fieldPointer)?.sheetFieldNo
                            const cellValue = item[Number(keyIndex ?? '0') + 1]
                            const valueVariable: VariableADTvalue = {
                                type: VariableType.VALUE,
                                valueVariable: { value: cellValue }
                            } as ValueVariable
                            const newValue = await getFieldInputInitialValue({
                                config: {
                                    ...config,
                                    initialValue: keyIndex === undefined ? keyIndex : valueVariable
                                }
                            })
                            return [
                                id,
                                {
                                    id: blockId,
                                    fieldId: fieldPointer,
                                    type: inputType,
                                    dsId: pointer,
                                    fieldType: field?.type,
                                    source: 'subForm',
                                    value: newValue
                                }
                            ]
                        })
                    )
                    return { id: nanoid(), content: Object.fromEntries(arrContent) }
                })

            )

            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                if (newList.length + draft[blockId].length > 100) {
                    Toast.warning('最多添加100条')
                    return
                }
                newList.forEach(item => {
                    draft[blockId].push(item)
                })
            })
            return true
        },
        [columns, dataSource?.schema, blockId, onChange, pointer]
    )

    useEffect(() => {
        if (initRecordArr) {
            const newRecord: SubFormRecord = {
                id: nanoid(),
                content: Object.fromEntries(initRecordArr)
            }
            onChange?.(draft => {
                if (!draft[blockId]) {
                    draft[blockId] = []
                }
                if (draft[blockId].length >= 100) {
                    Toast.warning('最多添加100条')
                    return
                }
                draft[blockId] = [newRecord]
            })
        }
    }, [blockId, initRecordArr, onChange])

    const prevInitRecordArrRef = useRef(initRecordArr)
    useUpdateEffect(() => {
        if (!initRecordArr || !prevInitRecordArrRef.current) {
            prevInitRecordArrRef.current = initRecordArr
            return
        }
        const isChangeNum = prevInitRecordArrRef.current.length !== initRecordArr.length
        if (isChangeNum) {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    return
                }
                draft[blockId] = draft[blockId].map(v => ({ id: v.id, content: Object.fromEntries(initRecordArr) }))
            })
            prevInitRecordArrRef.current = initRecordArr
            return
        }
        const isEqual = initRecordArr.every((v, i) => equal(v[1], prevInitRecordArrRef.current?.[i]?.[1]))
        if (!isEqual) {
            onChange?.(draft => {
                if (!draft[blockId]) {
                    return
                }
                draft[blockId] = draft[blockId].map(v => ({ id: v.id, content: Object.fromEntries(initRecordArr) }))
            })
            prevInitRecordArrRef.current = initRecordArr
        }
    }, [initRecordArr, blockId])

    useUpdateEffect(() => {
        setColumnsWidth(initColumnsWidth)
    }, [initColumnsWidth])

    const SubFormFieldInputProviderValue: SubFormFieldInputContextType = useMemo(
        () => ({
            language,
            isValid,
            columnsWidth,
            relativeDataSource,
            previewType,
            dataSource,
            dataSourceList,
            errors,
            uploadyOptions,
            richTextUploadOptions,
            videoUploadyOption,
            onChangeColumnWidth: handleChangeColumnWidth,
            onCellChange: handleCellChange,
            onRecordCopy: handleRecordCopy,
            onRecordRemove: handleRecordRemove,
            onChangeSmsCode: handleSmsCodeChange,
            onCellBlur: handleCellBlur,
            onFetchCascadeOptions,
            onFetchDataSource,
            onFetchDataSourceMeta,
            onFetchPersonOptions,
            onFetchSmsCode,
            onLoadMoreData,
            onOpenPage,
            onRenderLabel
        }),
        [
            language,
            isValid,
            columnsWidth,
            relativeDataSource,
            previewType,
            dataSource,
            dataSourceList,
            errors,
            uploadyOptions,
            richTextUploadOptions,
            videoUploadyOption,
            handleChangeColumnWidth,
            handleCellChange,
            handleRecordCopy,
            handleRecordRemove,
            handleSmsCodeChange,
            handleCellBlur,
            onFetchCascadeOptions,
            onFetchDataSource,
            onFetchDataSourceMeta,
            onFetchPersonOptions,
            onFetchSmsCode,
            onLoadMoreData,
            onOpenPage,
            onRenderLabel
        ]
    )

    return (
        <SCxContainer
            {...rest}
            blockId={blockId}
            animation={breakPoint?.animation}
            style={{ overflow: breakPoint.size.overflow, ...rest.style }}
        >
            <SCxWrapper>
                <SubFormFieldInputProvider value={SubFormFieldInputProviderValue}>
                    <SubFormHeader blockData={blockData} onBlockChange={onBlockChange} />
                    <SCxTable>
                        <SubFormContent data={dataList} columns={columns} />
                        {columns.length === 0 && (
                            <Empty
                                styles={{
                                    root: {
                                        position: 'absolute',
                                        top: 0,
                                        bottom: 0,
                                        right: 70,
                                        left: 44,
                                        width: 'auto',
                                        backgroundColor: '#fff',
                                        overflow: 'hidden'
                                    },
                                    content: {
                                        width: '100%',
                                        padding: '0 12px',
                                        marginTop: '0!important'
                                    }
                                }}
                                description={
                                    <Text color="var(--color-gray-400)" style={{ overflow: 'hidden' }}>
                                        请在「右侧栏{'>'}配置」中选择想要提交到的数据表
                                    </Text>
                                }
                            />
                        )}
                    </SCxTable>
                </SubFormFieldInputProvider>
            </SCxWrapper>
            {columns.length > 0 && (
                <SubFormFooter appId={appId} envId={envId} dataSource={dataSource} onRecordsAdd={handleAddRecord} onAppend={handleAppend} />
            )}
        </SCxContainer>
    )
}

export const SubFormBlock = React.memo(SubForm)
