import { IconFont, useElementSize } from '@byecode/ui'
import type { AppUser, ButtonAction, UserCenterBlockAbstract } from '@lighthouse/core'
import type { LoginAuthType, UserAvatarProps } from '@lighthouse/shared'
import {
    ApplicationPreviewEnum,
    getShadowStyle,
    IconRender,
    TagIcon,
    useActionRunningLoadings,
    useFillPickerContext,
    UserCenter
} from '@lighthouse/shared'
import React, { useMemo, useRef } from 'react'
import { useLatest } from 'react-use'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'

interface UserCenterBlockProps {
    blockData: UserCenterBlockAbstract
    user?: AppUser
    previewType: ApplicationPreviewEnum
    onLogout?: () => void
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

const SCxContainer = styled(AnimationDecorators)`
    padding: var(--block-padding);
`

const defaultUser: AppUser = {
    uniqueUserId: '',
    username: '',
    userId: '',
    mobile: ''
}

const UserCenterBlock: React.FunctionComponent<UserCenterBlockProps> = ({
    blockData,
    user,
    previewType,
    onLogout,
    onUpdateBindAccount
}) => {
    const {
        id,
        config: {
            breakPoint: { design, size, animation }
        }
    } = blockData
    const { shadow, color, border, background, radius } = design ?? {}

    const { palettes } = useFillPickerContext()

    const { ref, width = 32 } = useElementSize<HTMLDivElement>()

    const avatarProps: Partial<UserAvatarProps> = useMemo(() => {
        const size = width - ((border?.each?.[1] ?? 0) + (border?.each?.[3] ?? 0))
        const borderWidth = border?.each ?? [0, 0, 0, 0]
        const borderRadius = radius ?? [0, 0, 0, 0]
        return {
            color: color?.color,
            background: background?.color,
            size,
            fontSize: user?.userId ? size * 0.5 : size * 0.7,
            style: {
                ...getShadowStyle(shadow, palettes),
                borderWidth: `${borderWidth[1]}px ${borderWidth[2]}px ${borderWidth[3]}px ${borderWidth[0]}px`,
                borderRadius: `${borderRadius[0]}px ${borderRadius[1]}px ${borderRadius[3]}px ${borderRadius[2]}px`,
                borderColor: border?.color,
                borderStyle: 'solid',
                boxSizing: 'content-box'
            }
        }
    }, [background?.color, border?.color, border?.each, color?.color, palettes, radius, shadow, user, width])

    return (
        <SCxContainer ref={ref} blockId={id} animation={animation}>
            {user?.userId ? (
                <UserCenter
                    userData={user}
                    isMobile={previewType === ApplicationPreviewEnum.mobile}
                    avatarProps={avatarProps}
                    onLogout={onLogout}
                />
            ) : (
                <TagIcon
                    icon="User"
                    size={typeof avatarProps.size === 'number' ? avatarProps.size : 32}
                    iconColor={avatarProps.color}
                    iconSize={avatarProps.fontSize}
                    background={avatarProps.background}
                    style={avatarProps.style}
                />
            )}

            {/* <IconRender value={content} /> */}
        </SCxContainer>
    )
}

export default UserCenterBlock
