import { Select } from '@byecode/ui'
import type { FlowNode, NodeIdWithScope, NodeTypes } from '@lighthouse/shared'
import {
    CollapseBox,
    CONTROLLABLE_BLOCK_TYPES,
    findBlockById,
    findNormalOrSyncBlock,
    nodeTypeOptions,
    scroll2FlowNode,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { syncComponentsAtom } from '@/atoms/application/state'
import { blockHighlightAtom, lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'

import { ComponentSelector } from '../../Common/ComponentSelector'
import * as SC from '../../styles'
import { generateOptionsWithSync } from '../ScrollToActionConfigure'
import { ControlActionSelector } from './ControlActionSelector'

export interface ControlActionConfigureProps {
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
    prefixName?: string
}

export const ControlActionConfigure: React.FC<ControlActionConfigureProps> = ({ actionTypeSwitcher, prefixName = 'config' }) => {
    const { control, setValue } = useFormContext()
    const controlElementId = useWatch({ control, name: `${prefixName}.controlElementId` })

    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)
    const [pageId, selectedNodes] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.state.selectedNodes] as const, [])
    )

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(syncComponentsAtom)

    const controlElement = useMemo(() => {
        if (!controlElementId) {
            return
        }

        const currentNode = selectedNodes?.[0]
        if (!currentNode) {
            return
        }

        if (currentNode.scope) {
            return findBlockById(controlElementId, syncComponents)
        }

        const currentBlock = findBlockById(controlElementId, pageBlocks)
        if (!currentBlock) {
            return
        }

        if (currentBlock.synchronousId) {
            return findBlockById(controlElementId, syncComponents)
        }

        return currentBlock
    }, [controlElementId, pageBlocks, selectedNodes, syncComponents])

    const options = useMemo(() => {
        const currentNode = selectedNodes?.[0]
        if (!currentNode) {
            return []
        }

        return (
            generateOptionsWithSync(currentNode, pageBlocks, syncComponents)?.filter(item =>
                CONTROLLABLE_BLOCK_TYPES.has(item.block.type)
            ) ?? []
        )
    }, [pageBlocks, selectedNodes, syncComponents])

    const handleScroll2FlowNode = useCallback(
        (value?: NodeIdWithScope) => {
            setBlockHighlight(value ? [value.id] : [])
            value && scroll2FlowNode(value.id, value.scope)
        },
        [setBlockHighlight]
    )

    const clearBlockHighlight = useCallback(() => {
        setBlockHighlight([])
    }, [setBlockHighlight])

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
            <CollapseBox label="控制规则">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>选择组件</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.controlElementId`}
                                control={control}
                                render={({ field }) => (
                                    <ComponentSelector
                                        value={field.value}
                                        onChange={value => {
                                            field.onChange(value.id)
                                            setValue(`${prefixName}.controlAction`, undefined)
                                            const block = findNormalOrSyncBlock(value, pageBlocks, syncComponents)
                                            setValue(`${prefixName}.controlElementType`, block?.type)

                                            clearBlockHighlight()
                                        }}
                                        options={options}
                                        onHover={handleScroll2FlowNode}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    {controlElement && <ControlActionSelector controlElement={controlElement} prefixName={prefixName} />}
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
