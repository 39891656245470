import type { SelectProps } from '@byecode/ui'
import { Flex, IconFont, Select, singleTextEllipsis, Switch, tinyButtons } from '@byecode/ui'
import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { AppUser, Field, GroupConfigure } from '@lighthouse/core'
import { Center, Divider } from '@mantine/core'
import produce from 'immer'
import type { FC } from 'react'
import React, { forwardRef, useCallback, useMemo, useRef } from 'react'
import styled from 'styled-components'

import { useLanguageContext } from '../../contexts'
import { useAppThemeContext } from '../ApplicationContainer'
import { type GroupVisibleOption, getGroupAllVisibleOptions, getGroupVisibleWithLabelOptions, mergeGroupVisibleOptions } from './utils'

export * from './utils'

const GroupSettingWrapper = styled.div`
    width: 268px;
    margin-top: -4px;
    flex: 1;
    overflow-y: auto;
`

const StyledHeader = styled.div`
    padding-bottom: 12px;
`

const StyledTitle = styled.div`
    color: var(--color-gray-900);
    padding: 8px 16px;
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
`

const StyledOptions = styled.div`
    padding-top: 12px;
`

const StyledField = styled(Flex)`
    height: 40px;
    padding: 0 16px;
    align-items: center;
    white-space: nowrap;
    gap: 8px;
    white-space: nowrap;
`

const StyledItem = styled(Flex)`
    height: 40px;
    padding: 0 16px;
    font-size: 14px;
`

const StyledNoOptions = styled(Center)`
    color: var(--color-gray-6);
    padding: 12px;
`
const StyledList = styled.div<{ height?: number }>`
    max-height: ${({ height }) => (height ? `${height}px` : '200px')};
    overflow-y: auto;
    ${tinyButtons()}
`
const StyledSortItem = styled.div`
    font-size: 14px;
    padding: 0 12px;
    height: 36px;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    gap: 6px;
    overflow: hidden;
`
const StyledSortItemContent = styled.div`
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    /* ${singleTextEllipsis} */
`

interface Props {
    schema: Record<string, Field>
    options: Required<SelectProps>['options']
    height?: number
    color?: string
    selectProps?: Omit<SelectProps, 'options' | 'value' | 'onChange'>
    value?: GroupConfigure
    clearable?: boolean
    enableHiddenTab?: boolean
    personOptions?: AppUser[]
    enableAllGroup?: boolean
    onChange?: (v: GroupConfigure) => void
}

/** 数据分组配置 */
export const DataGroupConfigure = forwardRef<HTMLInputElement, Props>(
    (
        {
            schema,
            options,
            height,
            color,
            selectProps = {},
            value = { groupByFieldId: '', groupConfig: [] },
            clearable,
            enableHiddenTab,
            personOptions,
            enableAllGroup,
            onChange
        },
        ref
    ) => {
        const rootRef = useRef(null)
        const theme = useAppThemeContext()
        const { convertTextByLanguage, language } = useLanguageContext()
        const { groupConfig, canHiddenTabValue, groupByFieldId } = value

        const config = useMemo(() => {
            if (!groupByFieldId || !groupConfig) {
                return []
            }
            const groupByField = schema?.[groupByFieldId]
            if (!groupByField) {
                return []
            }
            return mergeGroupVisibleOptions(
                getGroupVisibleWithLabelOptions(groupByField, groupConfig, {
                    personOptions
                }),
                getGroupAllVisibleOptions(groupByField, { personOptions }, enableAllGroup)
            )
        }, [enableAllGroup, groupByFieldId, groupConfig, personOptions, schema])

        const items = useMemo(() => groupConfig.map(item => item.value), [groupConfig])

        const onDragEnd = useCallback(
            (e: DragEndEvent) => {
                const { active, over } = e
                if (over && config) {
                    const activeIndex = items.indexOf(active.id as string)
                    const overIndex = items.indexOf(over.id as string)
                    if (activeIndex !== overIndex) {
                        const newGroupConfig = arrayMove(config, activeIndex, overIndex)
                        onChange?.({
                            ...value,
                            groupConfig: newGroupConfig
                        })
                    }
                }
            },
            [value, items, config, onChange]
        )

        const handleHiddenTabChange = useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                onChange?.({
                    ...value,
                    canHiddenTabValue: e.currentTarget.checked
                })
            },
            [onChange, value]
        )

        const handleFieldIdChange = useCallback(
            (val: string) => {
                if (!val) {
                    onChange?.({
                        ...value,
                        groupByFieldId: '',
                        groupConfig: []
                    })
                    return
                }
                const config = mergeGroupVisibleOptions(
                    getGroupVisibleWithLabelOptions(schema[val], [], {
                        personOptions
                    }),
                    getGroupAllVisibleOptions(schema[val], { personOptions }, true)
                )
                onChange?.({
                    ...value,
                    groupByFieldId: val,
                    groupConfig: config
                })
            },
            [onChange, personOptions, schema, value]
        )

        const handleConfigureValueChange = useCallback(
            (c: GroupVisibleOption[]) => {
                onChange?.({
                    ...value,
                    groupConfig: c
                })
            },
            [onChange, value]
        )

        const switchHiddenTab = useMemo(
            () => (
                <StyledItem justifyContent="space-between" alignItems="center">
                    <span>{convertTextByLanguage('hideEmptyGroups')}</span>
                    <Switch checked={canHiddenTabValue} color={theme.mainColor} onChange={handleHiddenTabChange} />
                </StyledItem>
            ),
            [canHiddenTabValue, convertTextByLanguage, handleHiddenTabChange, theme.mainColor]
        )

        const groupField = useMemo(() => schema?.[groupByFieldId], [groupByFieldId, schema])

        return (
            <GroupSettingWrapper ref={rootRef}>
                {selectProps?.disabled ? null : (
                    <StyledHeader>
                        <StyledTitle>{convertTextByLanguage('groupBy')}</StyledTitle>
                        <StyledField>
                            {/* 多语言单独处理 */}
                            <span>{convertTextByLanguage('accordingTo')}</span>
                            <Select
                                target={rootRef.current}
                                // disabledPortal
                                styles={{ root: { flex: 1 } }}
                                options={options}
                                placeholder={convertTextByLanguage('pleasesSelect')}
                                ref={ref}
                                value={groupByFieldId}
                                clearable={clearable}
                                onChange={handleFieldIdChange}
                            />
                            {language !== 'en' && <span>{convertTextByLanguage('group')}</span>}
                        </StyledField>
                        {enableHiddenTab && groupByFieldId && switchHiddenTab}
                    </StyledHeader>
                )}

                {groupByFieldId ? (
                    <>
                        {!selectProps?.disabled && <Divider color="var(--color-gray-200)" />}
                        <StyledOptions>
                            <StyledTitle>{convertTextByLanguage('groupSetting')}</StyledTitle>
                            {config.length === 0 ? (
                                <StyledNoOptions>
                                    {convertTextByLanguage('noData')},{convertTextByLanguage('pleasesGoDataSourceAdd')}
                                </StyledNoOptions>
                            ) : (
                                <StyledList height={height}>
                                    <DndContext
                                        onDragEnd={onDragEnd}
                                        modifiers={[restrictToParentElement, restrictToVerticalAxis]}
                                        autoScroll={{
                                            threshold: {
                                                x: 0,
                                                y: 0.2
                                            }
                                        }}
                                    >
                                        <SortableContext items={items} strategy={verticalListSortingStrategy}>
                                            {config.map((item, index) => (
                                                <SortItem
                                                    key={item.value}
                                                    id={item.value}
                                                    color={color}
                                                    label={item.label}
                                                    visible={item.visible}
                                                    onVisibleChange={v => {
                                                        const newConfig = produce(config, draft => {
                                                            draft[index].visible = v
                                                        })
                                                        handleConfigureValueChange?.(newConfig)
                                                    }}
                                                />
                                            ))}
                                        </SortableContext>
                                    </DndContext>
                                </StyledList>
                            )}
                        </StyledOptions>
                    </>
                ) : null}
            </GroupSettingWrapper>
        )
    }
)

interface SortItemProps {
    id: string
    label: string
    color?: string
    visible?: boolean
    onVisibleChange?: (v: boolean) => void
}

const SortItem: FC<SortItemProps> = ({ id, label, color = 'var(--color-main)', visible, onVisibleChange }) => {
    const { attributes, listeners, transform, transition, setActivatorNodeRef, setNodeRef } = useSortable({ id })
    return (
        <StyledSortItem ref={setNodeRef} style={{ transition, transform: CSS.Translate.toString(transform) }}>
            <IconFont type="DotsSix" ref={setActivatorNodeRef} {...attributes} {...listeners} />
            <StyledSortItemContent>{label}</StyledSortItemContent>
            <Switch checked={visible} color={color} onChange={e => onVisibleChange?.(e.target.checked)} />
        </StyledSortItem>
    )
}
