export const FACE_ICON_KEY = 'icon-picker-recent-icons'

export const LINE_ICON_KEY = 'icon-picker-recent-icons'

/**
 * save recent color to local storage
 * @param color
 * @returns
 */
export const saveRecentIconsToStorage = (key: string, icon: string) => {
    const recentColors = getRecentIconsFromStorage(key)
    if (recentColors.includes(icon)) {
        recentColors.splice(recentColors.indexOf(icon), 1)
    }
    const newColors = [icon, ...recentColors].slice(0, 10)

    localStorage.setItem(key, JSON.stringify(newColors))
    return newColors
}

/**
 * get recent color from local storage
 * @returns
 */
export const getRecentIconsFromStorage = (key: string): string[] => {
    return JSON.parse(localStorage.getItem('icon-picker-recent-icons') || '[]')
}

