import type { DividerBlockConfig } from '@lighthouse/core'
import { BACKGROUND_TYPE, DIRECTION } from '@lighthouse/core'

export const generateDividerBlock = (): DividerBlockConfig => {
    return {
        visible: true,
        direction: DIRECTION.horizontal,
        style: 'SOLID',
        fill: {
            type: BACKGROUND_TYPE.color,
            color: '#E4E7EC'
        },
        size: 1,
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            position: {
                type: 'relative'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 20,
                    unit: 'px'
                }
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
