import { DEFAULT_LANG } from '../constants'
import { en, zhCN, zhTW } from '../language'
import type { I18TranslationKey } from '../types'

export function getTranslationValue(key: I18TranslationKey, language = DEFAULT_LANG) {
    switch (language) {
        case 'zh-CN': {
            return zhCN[key]
        }
        case 'en': {
            return en[key]
        }
        case 'zh-TW': {
            return zhTW[key]
        }
        default: {
            return zhCN[key]
        }
    }
}

export const getTranslation = (key: I18TranslationKey, v?: string) => {
    const language = document.documentElement.lang
    return getTranslationValue(key, language)
    // return typeof result === 'function' ? result(v) : result
}
