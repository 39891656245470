import { useUncontrolled } from '@byecode/ui/hooks/useUncontrolled'
import { isAfter, isBefore, lightFormat } from 'date-fns'
import React, { forwardRef, useMemo, useRef, useState } from 'react'

import { Box } from '../../Box'
import type { PanelMode } from '../../Calendar/Calendar.type'
import { Input } from '../../Input'
import type { BaseModalProps } from '../../Modal'
import type { DatePickerProps } from '../DatePicker'
import { MobileDatePickerModal } from './MobileDatePickerModal'

export type MobileDatePickerMode = Exclude<PanelMode, 'year'> | 'full'

function getRealFormat(format: string, mode: MobileDatePickerMode) {
    const [dateFormat, timeFormat] = format.split(' ')

    if (mode === 'day') {
        return dateFormat
    }

    if (mode === 'time') {
        return timeFormat
    }

    return format
}

export interface MobileDatePickerProps
    extends Omit<DatePickerProps, 'popoverProps' | 'inputRender' | 'mode' | 'defaultMode' | 'onModeChange'> {
    target?: HTMLElement | null | string
    mode?: MobileDatePickerMode
    inputStyle?: React.CSSProperties
    onClose?: () => void
    customRenderInputUi?: (date: Date | undefined, inputEle: React.MutableRefObject<HTMLInputElement | null>) => React.ReactNode
    modalProps?: Omit<BaseModalProps, 'onChange' | 'defaultValue' | 'value'>
}

export const MobileDatePicker = forwardRef<HTMLInputElement, MobileDatePickerProps>((props, ref) => {
    const {
        styles,
        style,
        classNames,
        unstyled,
        inputStyle,
        target,

        value,
        defaultValue,
        onChange,
        onClose,
        format = 'yyyy-MM-dd HH:mm:ss',

        // panel control props
        disabled,
        date,
        defaultDate,
        onDateChange,
        panelDate,
        defaultPanelDate,
        onPanelDateChange,
        mode = 'day',

        // panel settings props
        showTime = true,
        disableSecond,
        firstDayOfWeek,
        dayRowsCount,
        HeaderComponent,
        FooterComponent,
        CellComponent,
        onPrevYear,
        onPrevMonth,
        onNextYear,
        onNextMonth,

        minDate,
        maxDate,
        customRenderInputUi,

        modalProps,

        ...inputProps
    } = props

    const [_value, _setValue] = useUncontrolled({
        value,
        defaultValue,
        onChange
    })

    const valueString = useMemo(() => {
        if (!_value) {
            return ''
        }

        return lightFormat(_value, getRealFormat(format, mode))
    }, [mode, _value, format])

    const inputType = useMemo<React.HTMLInputTypeAttribute>(() => {
        if (mode === 'day') {
            return 'date'
        }

        if (mode === 'month') {
            return 'month'
        }

        if (mode === 'time') {
            return 'time'
        }

        return 'date'
    }, [mode])

    const isFullDateType = mode === 'full'
    const [open, setOpen] = useState(false)

    const realInputRef = useRef<HTMLInputElement | null>(null)

    const isIOS = /\(i[^;]+;( U;)? CPU.+Mac OS X/.test(navigator.userAgent)

    return (
        <Box style={{ position: 'relative', ...style }}>
            {customRenderInputUi ? (
                customRenderInputUi(_value, realInputRef)
            ) : (
                <Input
                    value={valueString}
                    placeholder="请选择"
                    readOnly
                    disabled={disabled}
                    style={inputStyle}
                    {...inputProps}
                    onClick={
                        isFullDateType
                            ? () => {
                                  setOpen(true)
                              }
                            : () => {
                                  if (realInputRef.current && 'showPicker' in realInputRef.current) {
                                      realInputRef.current.showPicker()
                                  }
                              }
                    }
                />
            )}

            {isFullDateType ? (
                <MobileDatePickerModal
                    open={open}
                    target={target}
                    onClose={() => {
                        onClose?.()
                        setOpen(false)
                    }}
                    value={_value}
                    onChange={_setValue}
                    onClick={e => e.stopPropagation()}
                    minDate={minDate}
                    maxDate={maxDate}
                    {...modalProps}
                />
            ) : (
                <input
                    ref={realInputRef}
                    type={inputType}
                    min={minDate && lightFormat(minDate, 'yyyy-MM-dd')}
                    max={maxDate && lightFormat(maxDate, 'yyyy-MM-dd')}
                    onFocus={e => {
                        e.target.defaultValue = valueString
                        e.target.value = valueString
                    }}
                    onInput={e => {
                        const target = e.currentTarget
                        const { value } = target

                        if (!value) {
                            _setValue(undefined)
                            return
                        }

                        let fullValue = ''
                        switch (mode) {
                            case 'day': {
                                fullValue = `${value} 00:00`
                                break
                            }

                            case 'time': {
                                fullValue = `${lightFormat(new Date(), 'yyyy-MM-dd')} ${value}`
                                break
                            }

                            case 'month': {
                                const str = `${value}-01`
                                fullValue = `${lightFormat(new Date(str), 'yyyy-MM-dd')}`
                                break
                            }

                            default: {
                                break
                            }
                        }

                        if (inputType !== 'time') {
                            if (minDate && isBefore(new Date(fullValue), minDate)) {
                                return
                            }

                            if (maxDate && isAfter(new Date(fullValue), maxDate)) {
                                return
                            }
                        }
                        _setValue(new Date(fullValue))
                    }}
                    onBlur={onClose}
                    style={{
                        pointerEvents: isIOS ? 'unset' : 'none',
                        opacity: 0,
                        position: 'absolute',
                        left: 0,
                        top: 0,
                        width: '100%',
                        height: '100%'
                    }}
                />
            )}
        </Box>
    )
})
