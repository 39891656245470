import React from "react"

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any

const useInsertionEffect = React.useInsertionEffect as AnyFunction | undefined

const useSafeInsertionEffect = useInsertionEffect || (fn => fn())

export function useEffectEvent<T extends AnyFunction>(callback?: T) {
    const ref = React.useRef<AnyFunction | undefined>(() => {
        //
    })
    useSafeInsertionEffect(() => {
        ref.current = callback
    })
    return React.useCallback<AnyFunction>((...args) => ref.current?.(...args), []) as T
}
