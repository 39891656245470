import { Divider, IconFont, Text } from '@byecode/ui'
import type { ViewOptions } from '@lighthouse/core'
import { CenteredWrapper } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'

import { dataSourceAtom } from '../atoms'
import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'
import { Data } from './Data'
import { Design } from './Design'
import { Operate } from './Operate'
import { ViewTypeSwitcher } from './ViewTypeSwitcher'

interface ViewSettingProps {
    id: string
}

/** 视图block设置 */
const ViewSetting: React.FC<ViewSettingProps> = ({ id }) => {
    const designProps = getBlockAndPageDesignLimit('view')
    const dataSource = useAtomValue(dataSourceAtom)
    const { watch } = useFormContext<ViewOptions>()
    const viewType = watch('viewType')

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    return (
        <>
            <BlockConfigureSegmentedControl isInterAction value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <ViewTypeSwitcher />
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <Design viewType={viewType} dataSource={dataSource} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <Data viewType={viewType} currentTab={currentTab} dataSource={dataSource} />
            )}

            {/* 用户操作 */}
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && dataSource && (
                <>
                    <Operate id={id} viewType={viewType} dataSource={dataSource} />
                    <Divider />
                    <AnimationSetting />
                </>
            )}
        </>
    )
}

export default ViewSetting
