import type { SegmentedControlItem } from "@byecode/ui";
import { CollectEventWriteType } from "@lighthouse/core";

/** 按钮样式 */
export const COLLECT_WAY_CONTROL: SegmentedControlItem[] = [
    {
        label: '写入数据',
        value: CollectEventWriteType.RECORD
    },
    {
        label: '执行流程',
        value: CollectEventWriteType.WORKFLOW
    }
]
