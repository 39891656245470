import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { getPositionStyle, getSizeStyle } from '../../../utils/layout'
import { useAppContainerContext } from '../../ApplicationContainer'
import { useContainerLayoutContext, useFlowLayoutContext } from '../Context'
import type { FlowLayoutNode } from '../types'

type StyledProps = {
    disabled?: boolean
}

const StyledNode = styled.div<StyledProps>`
    position: relative;
    flex-shrink: 0;
    &[data-highlight='true'] {
        background-color: #5551ff1f;
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }
`

export interface FlowLayoutBaseNodeProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutNode
    disabled?: boolean
    sortable?: boolean
    onDataDrawerVisible?: (val: boolean) => void
}

export const BaseNode = forwardRef<HTMLDivElement, FlowLayoutBaseNodeProps>((props, ref) => {
    const { data, style, disabled: propDisabled, sortable, onDataDrawerVisible, children, ...rest } = props

    const { size, position } = data.data ?? {}
    const { scale = 1 } = useAppContainerContext()
    const parentLayoutCtx = useContainerLayoutContext()

    const { highlight, nodeRender: NodeRender = React.Fragment } = useFlowLayoutContext()

    const mergeStyle: React.CSSProperties = {
        ...getSizeStyle(size, parentLayoutCtx.layout?.align?.direction, sortable, scale),
        ...style,
        ...getPositionStyle(position, parentLayoutCtx.level)
    }
    return (
        <StyledNode
            ref={ref}
            style={mergeStyle}
            data-type="node"
            data-node-id={data.id}
            data-highlight={!!highlight?.[data.id]?.self}
            {...rest}
        >
            <NodeRender node={data} />
            {children}
        </StyledNode>
    )
})
