import { useElementSize } from '@lighthouse/bui'
import type { ButtonAction, IconBlockAbstract } from '@lighthouse/core'
import {
    getImageFullUrlInApplication,
    getTextColorStyle,
    IconRender,
    useActionRunningLoadings,
    useApplicationContext,
    useAppThemeContext
} from '@lighthouse/shared'
import React, { useRef } from 'react'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'

interface IconBlockProps {
    blockData: IconBlockAbstract
    onActionTrigger?: (params: ButtonAction) => Promise<boolean | undefined>
}

const SCxContainer = styled(AnimationDecorators)``
const IconBlock: React.FunctionComponent<IconBlockProps> = ({ blockData, onActionTrigger }) => {
    const {
        id,
        config: {
            action,
            content,
            breakPoint: {
                icon,
                animation,
                design: { color }
            }
        }
    } = blockData
    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()

    const { ref, width } = useElementSize<HTMLDivElement>()

    const { appId } = useApplicationContext()

    const { theme } = useAppThemeContext()

    return (
        <SCxContainer
            ref={ref}
            blockId={id}
            animation={animation}
            onClick={e => !loadings[id] && handleActionTriggerWithLoading({ type: 'click', action, id, trigger: onActionTrigger })}
        >
            <IconRender
                size={width}
                isCompact
                readonly
                value={icon?.value ? icon : { type: 'icon', value: 'SmileCircleIconLibrary' }}
                color={getTextColorStyle(color, theme.palettes).color}
                onGetFullUrl={url => getImageFullUrlInApplication(appId, url)}
            />
        </SCxContainer>
    )
}

export default IconBlock
