import { Loading } from '@byecode/ui/components/Loading'
import { useElementSize } from '@byecode/ui/hooks/useResizeObserver'
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react'
import { useAsyncRetry, useCss } from 'react-use'
import styled from 'styled-components'

import { useApplicationContext } from '../../contexts'
import { getVideoSource } from '../../utils'
import { AliPlayer } from './AliPlayer'
import type { AliPlayerConfig, PlayerRef } from './AliPlayer/types'
import { BiliPlayer } from './BiliPlayer'
import { aliyunVideoReg, ratioReg } from './constants'
import { YoukuPlayer } from './YoukuPlayer'
import { YoutubePlayer } from './YoutubePlayer'

interface PlayerProps {
    /** 视频地址 */
    source?: string
    /** 宽度 */
    ratio?: string
    /** 自动播放 */
    autoplay?: boolean
    /** 视频圆角 */
    borderRadius?: string | number
    /** 视频控制器  */
    isController?: boolean
    /** 静音 */
    isMute?: boolean
    /** 循环播放 */
    isLoop?: boolean
    /** 布局 */
    fitType?: 'fill' | 'auto'
    /** 封面 */
    cover?: string
    /** 样式 */
    style?: React.CSSProperties
}
const mockBili = 'https://www.bilibili.com/video/BV1xt421E7y5/?share_source=copy_web'

const mokeYoutube = 'https://youtu.be/lGEMwh32soc?si=icG1UxQobAk6LwjC'

const mokeYouku =
    'https://v.youku.com/v_show/id_XNTE3MTc1MDg2MA==.html?spm=a2hja.14919748_WEBHOME_NEW.app.5~5!2~5!9~A&playMode=pugv&scm=20140719.rcmd.5497.video_XNTE3MTc1MDg2MA==_预览'

const mokeByecode =
    'https://byecode-userdata-prod.oss-cn-shanghai.aliyuncs.com/userdata/1b4af5d3d37242dea7d7665a31884366/91f08aa9e3c148f2b85908208ccd58ab/n4AhVM8x66014bfahJQR78iTJqRlKqJ8/15e0133bbd7440e583800a0abfe359b8/1231371504-1-16.mp4?size=2483490'

const mockAliyun = '40573e491c9271efa26f5017f1e80102/1231371504-1-16.mp4?size=2483490'

const SCxContainer = styled.div<Pick<React.CSSProperties, 'borderRadius'>>`
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    > * {
        border-radius: ${({ borderRadius }) => borderRadius};
    }
`

const SCxWrapper = styled.div<{ ratio: string }>`
    position: relative;
    width: 100%;
    aspect-ratio: ${({ ratio }) => ratio};
    object-fit: contain;
`

type AliyunPlayAuthResponse = {
    code: number
    success: boolean
    msg: string
    content: {
        playAuth: string
    }
}

// const fetchAliyunPlayAuth = function (videoId: string): Promise<AliyunPlayAuthResponse> {
//     return fetch(`/lighthouse/api/v1/oss/getVideoPlayAuth?videoId=${videoId}`, {})
//         .then(response => response.json())
//         .catch(reject => {
//             console.log(JSON.stringify(reject))
//         })
// }

function extractVideoId(url: string) {
    const parts = url.split('/')
    return parts[0]
}

export const VideoPlayer: React.FunctionComponent<PlayerProps> = ({
    source = '',
    borderRadius = 0,
    ratio,
    isController,
    isLoop,
    isMute,
    autoplay,
    fitType,
    cover,
    style
}) => {
    const { ref, width } = useElementSize<HTMLDivElement>()

    const aliPlayerRef = useRef<PlayerRef>(null)

    const [startTime, setStartTime] = useState<number>()

    const { fetchVideoPlayAuth } = useApplicationContext()

    const videoSource = useMemo(() => getVideoSource(source ?? ''), [source])

    const { value, loading, retry } = useAsyncRetry(async () => {
        if (videoSource === 'aliyun') {
            const videoId = extractVideoId(source)
            if (!videoId || !fetchVideoPlayAuth) {
                setStartTime(undefined)
                return null
            }
            const playAuth = await fetchVideoPlayAuth(videoId)
            setStartTime(Date.now())
            return { playAuth, videoId }
        }
        return null
    }, [videoSource, autoplay, isController, isLoop])

    const cssRatio = useMemo(() => {
        const playerRatio = ratio && ratioReg.test(ratio) ? ratio : '16:9'
        return playerRatio.replace(':', '/')
    }, [ratio])

    const alipLayerOption: Partial<Omit<AliPlayerConfig, 'source' | 'isLive'>> = useMemo(() => {
        return {
            vid: value?.videoId,
            playauth: value?.playAuth,
            autoplay,
            rePlay: isLoop,
            cover,
            // videoHeight: finallyHeight,
            controlBarVisibility: isController ? 'always' : 'never'
        }
    }, [autoplay, cover, isController, isLoop, value?.playAuth, value?.videoId])

    // const finallyHeight = useMemo(() => (fitType === 'fill' ? `${height}px` : '100%'), [fitType, height])

    const videoEle = useMemo(() => {
        switch (videoSource) {
            case 'bilibili': {
                return <BiliPlayer source={source} autoplay={autoplay} />
            }
            case 'youtube': {
                return <YoutubePlayer source={source} isController={isController} isLoop={isLoop} autoplay={autoplay} />
            }
            case 'youku': {
                return <YoukuPlayer source={source} />
            }
            case 'aliyun': {
                if (loading) {
                    return null
                }

                return <AliPlayer ref={aliPlayerRef} options={alipLayerOption} />
            }
            default: {
                const XgPlayer = React.lazy(() => import('./XgPlayer').then(module => ({ default: module.XgPlayer })))
                return (
                    <Suspense fallback={null}>
                        <XgPlayer
                            source={source}
                            cover={cover}
                            isController={isController}
                            isLoop={isLoop}
                            isMute={isMute}
                            // height={finallyHeight}
                            autoplay={autoplay}
                            fitType={fitType}
                        />
                    </Suspense>
                )
            }
        }
    }, [alipLayerOption, autoplay, cover, fitType, isController, isLoop, isMute, loading, source, videoSource])

    return (
        <SCxContainer borderRadius={borderRadius} ref={ref} style={style}>
            <SCxWrapper ratio={cssRatio}>{videoEle}</SCxWrapper>
        </SCxContainer>
    )
}
