import type { Field } from '@lighthouse/core'
import { type SchemaProtocol, AppUserStatus, DateFieldUnit, VariableType } from '@lighthouse/core'
import React, { useCallback, useMemo } from 'react'
import type { ControllerRenderProps, FieldValues } from 'react-hook-form'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { useApplicationContext } from '../../contexts'
import { getDateConfig, isIdsValue, isNumberValue, isTextValue } from '../../utils'
import { DEFAULT_FILTER_VALUE_VARIABLE } from '../'
import { CheckButton } from './CheckButton'
import * as SC from './styles'

interface ParamsTileModeProps {
    field: Field
    schema: SchemaProtocol['schema']
    prefixName: string
    width?: string
    focusOutLine?: string
    isMobile?: boolean
    enableCurrentUser?: boolean
}

const Box = styled.div<{ isMobile?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 12px;
    gap: 7px;

    ${props =>
        props.isMobile &&
        css`
            flex: 1;
        `}

    overflow: hidden;
`

export const ParamsTileMode: React.FC<ParamsTileModeProps> = ({
    field,
    schema,
    prefixName,
    focusOutLine,
    width = 'auto',
    isMobile,
    enableCurrentUser = true
}) => {
    const { control } = useFormContext()
    const { personOptions } = useApplicationContext()
    const { roleOptions } = useApplicationContext()
    const { departmentOptions } = useApplicationContext()

    const personOptionsWithCurrentUser = useMemo(() => {
        if (!enableCurrentUser) {
            return personOptions
        }
        return [
            {
                userId: '{currentUserId}',
                username: '当前登录用户',
                status: [AppUserStatus.ACTIVATED]
            },
            ...personOptions
        ]
    }, [enableCurrentUser, personOptions])
    const operator: string = useWatch({
        control,
        name: `${prefixName}.operator`
    })
    // const realField = useMemo(() => {
    //     if (!field) {
    //         return
    //     }
    //     if ((field.type === 'formula' || field.type === 'aggregation') && field.innerType) {
    //         return {
    //             ...field,
    //             type: AggregationInnerTypeToFieldType[field.innerType]
    //         } as Field
    //     }
    //     return field
    // }, [field])

    const options = useMemo(() => {
        const currentField = schema[field.id]
        if (!currentField) {
            return []
        }
        if (currentField.type === 'select' || currentField.type === 'singleSelect') {
            const options = currentField.type === 'select' ? currentField.select.options : currentField.singleSelect.options
            return options.map(item => ({
                label: item.label,
                value: item.label,
                color: item?.color
            }))
        }
        if (currentField.innerType === 'BOOL') {
            return [
                {
                    label: '是',
                    value: true
                },
                {
                    label: '否',
                    value: false
                }
            ]
        }
        if (currentField.type === 'person') {
            return personOptionsWithCurrentUser
                .filter(({ status }) => status?.[0] !== AppUserStatus.DEPART)
                .map(item => ({
                    label: item.username,
                    value: item.userId
                }))
        }
        if (currentField.type === 'role') {
            return roleOptions.map(item => ({
                label: item.name,
                value: item.id
            }))
        }
        if (currentField.type === 'department') {
            return departmentOptions.map(item => ({
                label: item.name,
                value: item.id
            }))
        }
        return []
    }, [departmentOptions, field.id, personOptionsWithCurrentUser, roleOptions, schema])

    const renderParams = useCallback(
        (ctlField: ControllerRenderProps<FieldValues, `${string}.paramList`>) => {
            if (operator === 'isEmpty' || operator === 'isNotEmpty') {
                return <div />
            }
            const [firstParam = DEFAULT_FILTER_VALUE_VARIABLE, secondParam = DEFAULT_FILTER_VALUE_VARIABLE] = ctlField.value || [
                DEFAULT_FILTER_VALUE_VARIABLE,
                DEFAULT_FILTER_VALUE_VARIABLE
            ]

            const firstValue = firstParam?.valueVariable?.value
            // const secondValue = secondParam?.valueVariable?.value
            if (field.innerType === 'DATE') {
                const firstDateValue = isNumberValue(firstValue) ? new Date(Number(firstValue)) : undefined
                // const secondDateValue = isNumberValue(secondValue) ? new Date(Number(secondValue)) : undefined
                const dateInfo = getDateConfig(field)
                const unit = dateInfo?.unit
                const format = dateInfo?.format
                // const calendarMode = unit === DateFieldUnit.MONTH ? 'month' : 'day'
                const showTime = unit === DateFieldUnit.MINUTE

                return (
                    <SC.ParamsDatePicker
                        clearable
                        key={`${prefixName}.paramList`}
                        // {...ctlField}
                        // disablePortal
                        popoverProps={{
                            withinPortal: true
                        }}
                        style={{ width: '100%' }}
                        showTime={showTime}
                        // defaultMode={calendarMode}
                        format={format}
                        value={firstDateValue}
                        placeholder="请选择"
                        onChange={val => {
                            if (!val) {
                                return ctlField.onChange([DEFAULT_FILTER_VALUE_VARIABLE])
                            }
                            ctlField.onChange([
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: { type: field?.type, value: val.valueOf() }
                                }
                            ])
                        }}
                    />
                )
            }

            if (field.innerType === 'BOOL') {
                // const [_, selectOptions] = getMultiSelectOptions(currentSchema, schema, allDataSources, personOptions, options)
                return options.map((option, index) => {
                    const isChecked = firstValue === option.value
                    return (
                        <CheckButton
                            key={index}
                            active={isChecked}
                            label={option.label}
                            onClick={() =>
                                ctlField.onChange([
                                    { type: VariableType.VALUE, valueVariable: { value: isChecked ? undefined : option.value } }
                                ])
                            }
                        />
                    )
                })
            }

            if (field.innerType === 'ARRAY' && field.type !== 'aggregation' && field.type !== 'formula') {
                const selectValue = isIdsValue(firstValue) ? firstValue : []
                // const [_, multiSelectOptions] = getMultiSelectOptions(currentSchema, schema, allDataSources, personOptions, options)
                return options.map((option, index) => {
                    const isChecked = selectValue.includes(String(option.value))
                    return (
                        <CheckButton
                            key={index}
                            active={isChecked}
                            label={option.label}
                            onClick={() => {
                                ctlField.onChange([
                                    {
                                        type: VariableType.VALUE,
                                        valueVariable: {
                                            value: isChecked
                                                ? selectValue.filter((v: string) => v !== option.value)
                                                : [...selectValue, option.value]
                                        }
                                    }
                                ])
                            }}
                        />
                    )
                })
            }
            const currentType = field.type
            const inputType = ['number'].includes(currentType) ? 'number' : 'text'
            const originValue = isTextValue(firstValue) ? firstValue : ''
            return (
                <SC.ParamsInput
                    key={`${prefixName}.paramList`}
                    width={width}
                    {...ctlField}
                    placeholder="搜索..."
                    value={originValue}
                    type={inputType}
                    onChange={ev => {
                        const { value: newValue } = ev.target
                        ctlField.onChange([
                            {
                                type: 'value',
                                valueVariable: { value: newValue }
                            }
                        ])
                    }}
                />
            )
        },
        [operator, options, prefixName, field, width]
    )

    if (!field) {
        return null
    }

    return (
        <Controller
            name={`${prefixName}.paramList`}
            control={control}
            render={({ field: ctlField }) => {
                return <Box isMobile={isMobile}>{renderParams(ctlField)}</Box>
            }}
        />
    )
}
