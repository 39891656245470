import { Select } from '@byecode/ui'
import type { FlowNode, NodeTypes, VariableSource } from '@lighthouse/shared'
import {
    CollapseBox,
    getAllCanBeUpstreamNodes,
    getDefaultValueOptions,
    getFormDatasourceOptions,
    getUserDatasourceOptions,
    getViewOptions,
    NodeFieldPreview,
    nodeTypeOptions,
    notificationStatusOptions,
    pureTextFieldTypes,
    useAtomData,
    VariableSourceType
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { userDataSourceAtom } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

import * as SC from '../styles'

export interface NotificationActionConfigureProps {
    prefixName?: string
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
}

export const NotificationActionConfigure: React.FC<NotificationActionConfigureProps> = ({
    prefixName = 'config',
    allParentNodes,
    actionTypeSwitcher
}) => {
    const { control, watch } = useFormContext()
    const disabledWithVersion = useIsDisabledWithVersion()

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const userDataSource = useAtomData(userDataSourceAtom)
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const { dsId, isForm, viewType } = useActionAdderDepParams()
    const dataSource = useDataSource(appId, envId, dsId ?? '')

    const { type } = useFlow()
    const isInFlow = !!type
    const isNotAutomation = type !== 'automation'

    const richTextOptions = useMemo(() => {
        // if (!dataSource) {
        //     return []
        // }

        return getDefaultValueOptions({
            sources: [
                isInFlow && {
                    sourceType: VariableSourceType.parentNode,
                    dataSourceList,
                    parentNodes: getAllCanBeUpstreamNodes(allParentNodes)
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.parentPage,
                    dataSource: prev.datasource,
                    page: prev.page
                },
                isNotAutomation && {
                    sourceType: VariableSourceType.page,
                    dataSource: curr.datasource,
                    page: curr.page
                }
            ].filter(Boolean) as VariableSource[],
            validateFieldType: type => pureTextFieldTypes.has(type)
        })
    }, [allParentNodes, curr.datasource, curr.page, dataSourceList, isInFlow, isNotAutomation, prev.datasource, prev.page])

    const formOption = useMemo(
        () => (isForm && !isInFlow ? getFormDatasourceOptions({ dataSource }) : undefined),
        [dataSource, isInFlow, isForm]
    )

    const { customViewOption } = useVariableCustomViewOption()
    const userOption = useMemo(() => {
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: field => pureTextFieldTypes.has(field.type)
        })
    }, [userDataSource])
    const viewOption = useMemo(() => {
        if (isInFlow) {
            return
        }
        return customViewOption || getViewOptions({ dataSource, viewType })
    }, [customViewOption, viewType, isInFlow, dataSource])
    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>内容</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                control={control}
                                name={`${prefixName}.notificationContent`}
                                render={({ field }) => (
                                    <NodeFieldPreview
                                        disabled={disabledWithVersion}
                                        autoHeight
                                        {...field}
                                        enablePageLink={type !== 'automation'}
                                        userOption={userOption}
                                        viewOption={viewOption}
                                        options={richTextOptions}
                                        formOption={formOption}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>提示类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.notificationStatus`}
                                control={control}
                                defaultValue="INFO"
                                render={({ field }) => (
                                    <Select
                                        disabledPortal
                                        value={field.value}
                                        options={notificationStatusOptions}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
