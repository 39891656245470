import { Text } from '@byecode/ui'
import type { DataSourceAbstract, EVENT_VARIABLE_TYPE, SubProcessArg } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable, FlowNode, PrevPageDatasourceForVariable } from '@lighthouse/shared'
import { innerTypeNameMap } from '@lighthouse/shared'
import type { JSONContent } from '@tiptap/core'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { SettingSubProcessParam } from './SettingSubProcessParam'

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const SCxItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px 0;
`

const SCxLabel = styled.div``

const SCxValue = styled.div`
    width: 100%;
`

interface SettingSubProcessContentProps {
    data: SubProcessArg[] | undefined
    dataSource?: DataSourceAbstract
    allParentNodes?: FlowNode[]
    parentNodes?: FlowNode[]
    eventType?: EVENT_VARIABLE_TYPE
    curr?: CurrPageDatasourceForVariable
    prev?: PrevPageDatasourceForVariable
    onChange?: (val: SubProcessArg[]) => void
}

export const SettingSubProcessContent: React.FC<SettingSubProcessContentProps> = ({
    data,
    dataSource,
    allParentNodes,
    parentNodes,
    eventType,
    curr,
    prev,
    onChange
}) => {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const handleChange = useCallback(
        (val: JSONContent, index: number) => {
            if (!data) {
                return
            }
            const newData = data.map((item, i) => {
                if (i === index) {
                    return {
                        ...item,
                        value: val
                    }
                }
                return item
            })
            onChange?.(newData)
        },
        [onChange, data]
    )

    return (
        <SCxContainer>
            {data?.map((item, index) => (
                <SCxItem key={item.id}>
                    <SCxLabel>
                        <Text inline size={14} color="var(--color-black)">
                            {item.name}
                        </Text>
                        <Text inline size={14} color="var(--color-gray-400)">
                            [{innerTypeNameMap[item.innerType]}]
                        </Text>
                    </SCxLabel>
                    <SCxValue>
                        <SettingSubProcessParam
                            innerType={item.innerType}
                            dataSource={dataSource}
                            value={item.value}
                            dataSourceList={dataSourceList}
                            allParentNodes={allParentNodes}
                            parentNodes={parentNodes}
                            eventType={eventType}
                            curr={curr}
                            prev={prev}
                            onChange={val => handleChange(val, index)}
                        />
                    </SCxValue>
                </SCxItem>
            ))}
        </SCxContainer>
    )
}
