import { Popover, SelectDropdown } from '@byecode/ui'
import { TagIcon } from '@lighthouse/shared'
import React, { useMemo, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { useSetExtendsKeys } from '../hooks/useMouseEventDistance'

const MinMaxRectSizeAdderWrapper = styled.div`
    color: var(--color-gray-900);
`

const SCxListItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

interface MinMaxRectSizeAdderProps {
    hideMinWidth?: boolean
    hideMaxHeight?: boolean
    hideMinHeight?: boolean
}

export const MinMaxRectSizeAdder: React.FC<MinMaxRectSizeAdderProps> = ({ hideMinWidth, hideMaxHeight, hideMinHeight }) => {
    const { control, setValue } = useFormContext()
    const { handleSetBreakKey } = useSetExtendsKeys()
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const values = useWatch({
        control,
        name: ['breakPoint.size.minWidth', 'breakPoint.size.maxWidth', 'breakPoint.size.minHeight', 'breakPoint.size.maxHeight']
    })
    const dropdownOptions = useMemo(() => {
        return [
            { label: '添加最小宽度', value: 'minWidth', icon: 'MinWidth' },
            { label: '添加最大宽度', value: 'maxWidth', icon: 'MaxWidth' },
            { label: '添加最小高度', value: 'minHeight', icon: 'MinHeight' },
            { label: '添加最大高度', value: 'maxHeight', icon: 'MaxHeight' }
        ].filter((item, index) => {
            if (hideMinWidth && item.value === 'minWidth') {
                return false
            }
            if (hideMinHeight && item.value === 'minHeight') {
                return false
            }
            if (hideMaxHeight && item.value === 'maxHeight') {
                return false
            }
            return values[index] === undefined
        })
    }, [hideMaxHeight, hideMinHeight, hideMinWidth, values])

    const handleAdd = (value: string) => {
        setValue(`breakPoint.size.${value}`, {
            unit: 'px'
        })
        handleSetBreakKey(`breakPoint.size.${value}`)
        setDropdownOpened(false)
    }

    if (dropdownOptions.length === 0) {
        return null
    }

    return (
        <MinMaxRectSizeAdderWrapper onClick={ev => ev.stopPropagation()}>
            <Popover opened={dropdownOpened} position="bottom-end" width={180} onChange={setDropdownOpened}>
                <Popover.Target>
                    <TagIcon icon="Add" size={24} enableHover hoverBackground="#26415A0F" color="var(--color-gray-400)" radius={4} />
                </Popover.Target>
                <Popover.Dropdown>
                    <SelectDropdown options={dropdownOptions} onSelect={handleAdd} />
                </Popover.Dropdown>
            </Popover>
        </MinMaxRectSizeAdderWrapper>
    )
}
