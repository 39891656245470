import { Divider, Switch, Text, Toast } from '@byecode/ui'
import type { ApplicationSettingAuthentication } from '@lighthouse/core'
import { ListItemPaddingByecodeUi } from '@lighthouse/shared'
import { mergeRight } from 'rambda'
import React, { useCallback } from 'react'
import { useSetState } from 'react-use'

import { WeChatOfficialAccountModal } from '@/components/OtherPlatFormModal'
import { TagIcon } from '@/components/TagIcon'
import { WechatIntegratedCard } from '@/components/WechatIntegratedCard'
import * as srv from '@/services'
import { useOtherPlatformList } from '@/shared/reusable'

import { LoginBlock } from '../LoginBlock'

interface LoginWeChatProps {
    label?: React.ReactNode
    value: ApplicationSettingAuthentication['login']['weChat']
    onChange: (v: ApplicationSettingAuthentication['login']['weChat']) => void
}

interface State {
    mode: 'add' | 'update'
    opened: boolean
    id?: string
    type?: 'website' | 'office'
}

export const LoginWeChat: React.FunctionComponent<LoginWeChatProps> = ({ label, value, onChange }) => {
    const { isOpened, isOpenedH5QrcodeLogin, isBindPhone } = value ?? {}
    const { data: otherPlatformList } = useOtherPlatformList()

    const [{ opened, mode, id, type }, setState] = useSetState<State>({
        opened: false,
        mode: 'add'
    })
    const wxOfficialAccount = otherPlatformList?.find(v => v.type === 'WECHAT_OFFICIAL_ACCOUNT')
    const isWxOfficialAccount = !!wxOfficialAccount

    const checkWxLoginConfig = useCallback(
        (v: () => void) => {
            const isBind =
                wxOfficialAccount?.type === 'WECHAT_OFFICIAL_ACCOUNT'
                    ? [wxOfficialAccount.config.token, wxOfficialAccount.config.encodingAesKey].every(Boolean)
                    : false
            if (isBind) {
                return v()
            }
            Toast.warning('请先完善“微信网页扫码登录”相关配置')
            setState(wxOfficialAccount?.id ? { opened: true, mode: 'update', id: wxOfficialAccount?.id } : { opened: true, mode: 'add' })
        },
        [setState, wxOfficialAccount]
    )


    const handleChange = useCallback(
        (v: Partial<ApplicationSettingAuthentication['login']['weChat']>) => {
            onChange(mergeRight(value, v))
        },
        [onChange, value]
    )

    return (
        <LoginBlock
            label={label}
            checked={isOpened}
            onChange={v => handleChange({ isOpened: v })}
            icon={
                <TagIcon
                    size={32}
                    iconSize={20}
                    radius={100}
                    iconColor="var(--color-green-500)"
                    background="var(--color-green-50)"
                    icon="WeChatLogoIconLibrary"
                />
            }
        >
            <div style={{ paddingBottom: 4 }}>
                <Text style={{ marginBottom: 8 }} lineHeight="26px" color="var(--color-gray-400)" size={14} whiteSpace="pre-wrap">
                    适合：拥有服务号（已微信认证）的用户 场景：一键登录、扫码关注公众号后登录 前提：使用微信登录前需先集成公众号
                </Text>
                <WechatIntegratedCard type="WECHAT_OFFICIAL_ACCOUNT" />
            </div>
            <Divider style={{ margin: '8px 0' }} />
            <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between" style={{ marginBottom: 4 }}>
                <Text>开启一键登录（仅支持微信浏览器）</Text>
                <Switch checked={isWxOfficialAccount} disabled />
            </ListItemPaddingByecodeUi>
            <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between" style={{ marginBottom: 4 }}>
                <Text>开启网页扫码登录（需关注公众号）</Text>
                <Switch
                    checked={isOpenedH5QrcodeLogin}
                    disabled={!isWxOfficialAccount}
                    onChange={e => {
                        const bool = e.target.checked
                        checkWxLoginConfig(() => {
                            handleChange({ isOpenedH5QrcodeLogin: bool })
                        })
                    }}
                />
            </ListItemPaddingByecodeUi>
            <ListItemPaddingByecodeUi alignItems="center" justifyContent="space-between" style={{ marginBottom: 4 }}>
                <Text>微信登录后绑定手机号</Text>
                <Switch
                    checked={isBindPhone}
                    disabled={!isWxOfficialAccount}
                    onChange={e => {
                        handleChange({ isBindPhone: e.target.checked })
                    }}
                />
            </ListItemPaddingByecodeUi>
            <WeChatOfficialAccountModal
                open={opened}
                id={id}
                mode={mode}
                isPerfectWebsiteInfo={isWxOfficialAccount}
                onClose={() => setState({ opened: false })}
            />
        </LoginBlock>
    )
}
