import { pointer } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import styled from 'styled-components'

export const Header = styled.div`
    position: relative;
    margin-bottom: 48px;
`

export const Back = styled.div`
    width: 28px;
    height: 28px;
    position: absolute;
    left: -28px;
    top: -14px;
    border-radius: 50%;
    background-color: var(--color-gray-100);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-200);
    }
`

export const BetaRequestForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px 56px;
    /* min-height: 650px; */
    gap: 60px;
    background-image: url(${getAssetUrl('common', 'beta_request_form_background.png')});
    background-size: cover;
`

export const BetaRequestStepTitleWrapper = styled.div`
    width: 100%;
    flex: 1;
`

export const BetaRequestStepIndicatorWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
`

export const BetaRequestStepIndicator = styled.div`
    width: 25px;
    height: 5px;
    border-radius: 3px;
    margin-right: 4px;
    background-color: #eeeeee;
`

export const BetaRequestStepTitle = styled.div`
    font-size: 36px;
    font-weight: 500;
    margin-bottom: 12px;
    text-align: center;
`

export const BetaRequestStepDescription = styled.div`
    font-size: 16px;
    color: var(--color-gray-400);
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
`

export const BetaRequestStepUl = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
    font-size: 16px;
`

export const BetaRequestStepLi = styled.div`
    display: flex;
    align-items: center;
    color: var(--color-gray-400); ;
`

export const BetaRequestStepLiDot = styled.div`
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: var(--color-gray-400);
    margin-right: 8px;
`

export const BetaRequestStepImage = styled.div<{ background: string }>`
    width: 189px;
    height: 189px;
    margin: 56px auto 0 auto;
    background: ${({ background }) => `url('${background}')`};
    background-size: contain;
    background-repeat: no-repeat;
`

export const BetaRequestFormFooter = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const BetaRequestFormSkip = styled.div`
    position: absolute;
    left: 0;
    color: var(--color-gray-500);
    ${pointer}
    &:hover {
        color: var(--color-gray-600);
    }
`

export const BetaRequestFormSuccessWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const BetaRequestFormSuccessIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 52px;
    height: 52px;
    margin-bottom: 24px;
    border-radius: 50%;
    background-color: #0ec273;
`

export const BetaRequestFormSuccessTip = styled.div`
    margin-bottom: 24px;
    font-size: 24px;
    font-weight: 600;
    color: var(--color - black);
`

export const BetaRequestFormSuccessUsername = styled.div`
    color: var(--color-black);
    margin-bottom: 8px;
`

export const BetaRequestFormSuccessFeedback = styled.div`
    color: var(--color-black);
`

export const BetaRequestFormSuccessFeedbackEmail = styled.a`
    color: var(--color-black);
`
