import { Button, Divider, Flex, IconFont, Tooltip } from '@byecode/ui'
import type { DataSourceAbstract, Field, SchemaProtocol } from '@lighthouse/core'
import { getDefaultAction, getViewColumns, Group4ByecodeUi, useAtomData } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { defaultPageListAtom } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { PopoverQuestion } from '@/components/PopoverQuestion'
import { ActionAdderProvider } from '@/contexts/ActionAdderContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { ButtonSettingList } from '../../Button/ButtonSettingList'
import { QuickFilterSetting } from '../../Common/QuickFilterSetting'
import {
    AllowExportSetting,
    AllowFieldSetting,
    AllowImportSetting,
    AllowPrintSetting,
    CreateRecordV2,
    RecordViewable,
    SwitchItem
} from '../../Common/UserOperate'

interface OperateProps {
    id: string
    dataSource: DataSourceAbstract
}

export const Operate: React.FC<OperateProps> = ({ id, dataSource }) => {
    const {
        id: dsId,
        viewOptions: { tableProps },
        schema,
        type
    } = dataSource
    const { watch, setValue, control } = useFormContext()
    const [
        viewFieldSettings,
        canSort,
        userSortSetting,
        canCreateRecord,
        creatingConfig,
        recordClickedCustomized,
        toolbarCustomized,
        recordClickedAction,
        canImport,
        userImportSetting,
        triggerWorkflow,
        canPrint,
        canExport
    ] = watch([
        'viewFieldSettings',
        'canSort',
        'userSortSetting',
        'canCreateRecord',
        'creatingConfig',
        'actions.recordClicked.customized',
        'actions.toolbar.customized',
        'actions.recordClicked.action',
        'canImport',
        'userImportSetting',
        'triggerWorkflow',
        'canPrint',
        'canExport'
    ])

    const allPages = useAtomData(defaultPageListAtom)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const columns = useMemo(
        () =>
            getViewColumns({
                tableProps,
                value: viewFieldSettings,
                schema
            }),
        [schema, tableProps, viewFieldSettings]
    )

    const isJoinDataSource = false /* useMemo(() => type === DataSourceType.joinDataSource, [type]) */

    // 合并数据源与视图上的字段
    const mergedSchema = useMemo(() => {
        return columns.reduce<SchemaProtocol['schema']>((acc, item) => {
            acc[item.fieldId] = { ...item, id: item.fieldId, name: item.title } as Field
            return acc
        }, {})
    }, [columns])

    return (
        <>
            <Group4ByecodeUi
                label={
                    <PopoverQuestion
                        type="toolOperation"
                        arrowOffset={20}
                        renderTarget={(node, ref) => (
                            <Flex gap={4} alignItems="center" ref={ref}>
                                工具栏操作{node}
                            </Flex>
                        )}
                    />
                }
                extra={
                    <Tooltip placement="top" title={toolbarCustomized ? '取消自定义动作' : '使用自定义动作'}>
                        <Button
                            style={toolbarCustomized ? { backgroundColor: 'var(--color-main)', color: 'var(--color-white)' } : {}}
                            radius={50}
                            onClick={ev => {
                                ev.stopPropagation()
                                setValue('actions.toolbar.customized', !toolbarCustomized)
                            }}
                            type="tag"
                            size="sm"
                            icon={<IconFont type="Lightning" />}
                        >
                            自定义
                        </Button>
                    </Tooltip>
                }
            >
                {toolbarCustomized ? (
                    <div style={{ padding: '0 8px' }}>
                        <ButtonSettingList allPages={allPages} prefixName="actions.toolbar.actionGroup." hideConfigs={{ fillWay: true }} />
                    </div>
                ) : (
                    <CreateRecordV2
                        viewId={id}
                        value={{ canCreateRecord, creatingConfig }}
                        dataSource={dataSource}
                        onChange={val => {
                            const { canCreateRecord, creatingConfig } = val
                            setValue('canCreateRecord', canCreateRecord)
                            setValue('creatingConfig', creatingConfig)
                        }}
                    />
                )}
            </Group4ByecodeUi>

            <Divider color="var(--color-gray-200)" />
            <Group4ByecodeUi
                label={
                    <PopoverQuestion
                        type="clickRecord"
                        arrowOffset={20}
                        renderTarget={(node, ref) => (
                            <Flex gap={4} alignItems="center" ref={ref}>
                                点击卡片后{node}
                            </Flex>
                        )}
                    />
                }
                extra={
                    <Tooltip placement="top" title={recordClickedCustomized ? '取消自定义动作' : '使用自定义动作'}>
                        <Button
                            style={recordClickedCustomized ? { backgroundColor: 'var(--color-main)', color: 'var(--color-white)' } : {}}
                            radius={50}
                            onClick={ev => {
                                ev.stopPropagation()
                                setValue('actions.recordClicked.customized', !recordClickedCustomized)
                                if (!recordClickedCustomized) {
                                    setValue('actions.recordClicked.action', {
                                        type: 'none',
                                        trigger: 'click',
                                        data: {
                                            none: {}
                                        }
                                    })
                                }
                            }}
                            type="tag"
                            size="sm"
                            icon={<IconFont type="Lightning" />}
                        >
                            自定义
                        </Button>
                    </Tooltip>
                }
            >
                {recordClickedCustomized ? (
                    <div style={{ padding: '0 8px' }}>
                        <ActionAdderProvider dsId={dsId} viewType="custom">
                            <ActionAdder
                                showLabel={false}
                                title="点击一行后"
                                allPages={allPages}
                                action={recordClickedAction ?? getDefaultAction()}
                                prefixName="actions.recordClicked."
                                events={{
                                    triggerEvent: 'click',
                                    params: [],
                                    handleEvent: 'none'
                                }}
                            />
                        </ActionAdderProvider>
                    </div>
                ) : (
                    <RecordViewable id={id} />
                )}
            </Group4ByecodeUi>
            <Divider color="var(--color-gray-200)" />
            <Group4ByecodeUi label="其它操作">
                {!isJoinDataSource && (
                    <AllowImportSetting
                        type="UploadSimple"
                        title="批量导入"
                        columns={columns}
                        value={{ canImport, userImportSetting, triggerWorkflow }}
                        dataSource={dataSource}
                        dataSourceList={dataSourceList}
                        onChange={val => {
                            const { canImport, userImportSetting, triggerWorkflow } = val
                            setValue('canImport', canImport)
                            setValue('userImportSetting', userImportSetting)
                            setValue('triggerWorkflow', triggerWorkflow)
                        }}
                    />
                )}
                <AllowPrintSetting
                    type="Print"
                    title="打印"
                    dsId={dsId}
                    value={{ canPrint }}
                    onChange={val => {
                        const { canPrint } = val
                        setValue('canPrint', canPrint)
                    }}
                />

                <AllowExportSetting
                    type="ImportExcel"
                    title="导出"
                    dsId={dsId}
                    columns={columns}
                    dataSource={dataSource}
                    dataSourceList={dataSourceList}
                    value={{ canExport }}
                    onChange={val => {
                        const { canExport } = val
                        setValue('canExport', canExport)
                    }}
                />

                <Controller
                    control={control}
                    name="canSearch"
                    render={({ field }) => <SwitchItem icon="SearchLine" label="搜索数据" value={field.value} onChange={field.onChange} />}
                />

                <QuickFilterSetting schema={mergedSchema} dataSourceList={dataSourceList} columns={columns} />

                <AllowFieldSetting
                    type="ArrowsDownUp"
                    title="排序"
                    columns={columns}
                    value={{ canSort, userSortSetting }}
                    onChange={val => {
                        const { canSort, userSortSetting } = val
                        setValue('canSort', canSort)
                        setValue('userSortSetting', userSortSetting)
                    }}
                />

                <Controller
                    control={control}
                    name="canGroup"
                    render={({ field }) => <SwitchItem icon="GroupBy" label="设置分组" value={field.value} onChange={field.onChange} />}
                />

                <Controller
                    control={control}
                    name="canPaginate"
                    render={({ field }) => <SwitchItem icon="PageBreakBar" label="分页栏" value={field.value} onChange={field.onChange} />}
                />
            </Group4ByecodeUi>
        </>
    )
}
