import type { AnyObject, InputProps } from '@byecode/ui';
import { Input } from '@byecode/ui'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { transform2Integer, transform2Number } from '@/utils/number'

import { SizeIcon } from './SizeSetting/SizeIcon'

type SizeInputValue = number

interface SizeInputProps extends Omit<InputProps, 'onChange' | 'value' | 'style' | 'prefix'> {
    icon?: string
    value?: SizeInputValue
    min?: number
    style?: React.CSSProperties
    deps?: AnyObject
    onChange: (value: SizeInputValue) => void
    onChangeMove?: (val: boolean) => void
}

const SCxRectSizeInputWrapper = styled.div`
    display: flex;
    width: 180px;
`

export const SizeInput: React.FC<SizeInputProps> = ({ icon, value, min, style, deps, onChange, onChangeMove,...rest }) => {
    const [v, setV] = useState(value)
    const [moveOpen, setMoveOpen] = useState(false)
    const handleChange = useCallback((ev: React.ChangeEvent<HTMLInputElement>) => {
        const val = ev.target.value
        const num = val ? transform2Number(val) : undefined
        setV(num)
    }, [])

    useUpdateEffect(() => {
        if(v === value) {
            return
        }
        setV(value)
    }, [value])

    const handleMoveChange = useCallback(
        (v: number) => {
            const num = transform2Integer(v)
            setV(num)
            if (num === value) {
                return
            }
            onChange(num)
        },
        [onChange, value]
    )

    const handleBlur = useCallback(
        (ev: React.FocusEvent<HTMLInputElement>) => {
            const v = ev.target.value
            const validNumber = transform2Number(Number(v).toFixed(2))
            setV(validNumber)
            if (validNumber === value) {
                return
            }
            onChange(validNumber)
        },
        [onChange, value]
    )

    const resizeStyle = useMemo(
        () =>
            moveOpen
                ? {
                      borderColor: 'var(--color-main)',
                      backgroundColor: 'var(--color-gray-200)'
                  }
                : {},
        [moveOpen]
    )

    return (
        <SCxRectSizeInputWrapper style={style}>
            <Input
                value={v}
                prefix={icon && <SizeIcon type={icon} min={min} value={v} onChangeMove={val => {
                    setMoveOpen(val)
                    onChangeMove?.(val)
                }} onChange={handleMoveChange} />}
                styles={{
                    wrapper: resizeStyle
                }}
                onFocus={e => e.currentTarget.select()}
                onChange={handleChange}
                type="number"
                onKeyDownCapture={ev => {
                    if (ev.key === 'Enter') {
                        ev.currentTarget.blur()
                    }
                }}
                onBlur={handleBlur}
                {...rest}
            />
        </SCxRectSizeInputWrapper>
    )
}
