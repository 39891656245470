import React from 'react'
import { Trans, useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { FILE_EXCEL_ACCEPT } from '../../../constants'
import { Translation, useLanguageContext } from '../../../contexts'
import UploadArea from '../../UploadArea'

interface UploadClickDropZoneProps {
    file: File | null
    disabled?: boolean
    onFileLoaded?: (file: File) => Promise<void> | void
    onFileClear?: () => void
}

const TipWrapper = styled.div`
    padding: 8px 0;
`

const Tip = styled.div`
    position: relative;
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    padding: 4px 10px;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        top: 10px;
        width: 4px;
        height: 4px;
        border-radius: 4px;
        background-color: var(--color-gray-400);
    }
`

const UploaderAreaTitleTip = styled.div`
    margin: 8px 0;
`
const UploaderAreaDesTip = styled.div`
    font-size: var(--font-size-sm);
`

const UploaderAreaBoldTip = styled.span`
    font-weight: 500;
    color: var(--color-gray-600);
`

// 限制 10 M
const fileSizeLimit = 10
// 限制 2 万行
const recordLimit = 2
export const UploadClickDropZone: React.FC<UploadClickDropZoneProps> = ({ file, disabled, onFileLoaded, onFileClear }) => {
    const { convertTextByLanguage, language } = useLanguageContext()

    return (
        <UploadArea
            file={file}
            accept={FILE_EXCEL_ACCEPT}
            disabled={disabled}
            sizeLimit={fileSizeLimit}
            extra={
                <TipWrapper>
                    <Tip>{convertTextByLanguage('uploadFileSizeLimitHowMuch', { v: fileSizeLimit })}</Tip>
                    <Tip>{convertTextByLanguage('importLimitNumberW', { v: recordLimit })} </Tip>
                    <Tip>{convertTextByLanguage('onlyXLSXorCSVofFile')}</Tip>
                    <Tip>{convertTextByLanguage('fileNoEmptySheet')}</Tip>
                    <Tip>{convertTextByLanguage('tableFileNoMergeCell')}</Tip>
                </TipWrapper>
            }
            description={
                <UploaderAreaTitleTip>
                    <Translation
                        i18nKey="uploadXLSXtips"
                        components={{
                            bold: (
                                <UploaderAreaBoldTip>
                                    {convertTextByLanguage('xlsxCsvFileNumber', { v: fileSizeLimit })}
                                </UploaderAreaBoldTip>
                            )
                        }}
                    />
                </UploaderAreaTitleTip>
            }
            tip={
                <UploaderAreaDesTip>
                    <Translation
                        i18nKey="uploadingXLSXemptyTips"
                        components={{
                            bold: <UploaderAreaBoldTip>{convertTextByLanguage('emptySheetOrMergeCell')}</UploaderAreaBoldTip>
                        }}
                    />
                </UploaderAreaDesTip>
            }
            onFileLoaded={onFileLoaded}
            onFileClear={onFileClear}
        />
    )
}

export default UploadClickDropZone
