import { Toast } from '@byecode/ui'
import { IconFont } from '@byecode/ui/components/IconFont'
import { useMove } from '@byecode/ui/hooks/useMove'
import chroma from 'chroma-js'
import React, { useRef } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

import type { HsvaColor } from '../type'

declare global {
    interface ColorSelectionOptions {
        signal?: AbortSignal
    }

    interface ColorSelectionResult {
        sRGBHex: string
    }

    interface EyeDropper {
        open: (options?: ColorSelectionOptions) => Promise<ColorSelectionResult>
    }

    interface EyeDropperConstructor {
        new (): EyeDropper
    }

    interface Window {
        EyeDropper?: EyeDropperConstructor
    }
}

const OpacitySvgRaw = `<svg xmlns="http://www.w3.org/2000/svg" width="68" height="68" viewBox="0 0 68 68" opacity="0.3">
<rect width="34" height="34" fill="black"/>
<rect x="34" width="34" height="34" fill="white"/>
<rect y="34" width="34" height="34" fill="white"/>
<rect x="34" y="34" width="34" height="34" fill="black"/>
</svg>`

const backgroundImage = `url('data:image/svg+xml;charset=utf8,${encodeURIComponent(OpacitySvgRaw)}')`

const Container = styled.div`
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px 0;
`

const ColorPicker = styled.div`
    display: inline-flex;
    align-items: center;
    padding: 4px;
    cursor: pointer;
    border-radius: 8px;
    &:hover {
        background-color: var(--color-gray-100);
    }
`

const Sliders = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
`

const ColorSlider = styled.div`
    position: relative;
    height: 8px;
    background: linear-gradient(
        to right,
        rgb(255, 0, 0),
        rgb(255, 255, 0),
        rgb(0, 255, 0),
        rgb(0, 255, 212),
        rgb(0, 0, 255),
        rgb(255, 0, 255),
        rgb(255, 0, 0)
    );
    border-radius: 4px;
`

const ColorSliderThumb = styled.button`
    all: unset;
    box-sizing: border-box;
    position: absolute;
    top: -4px;
    width: 16px;
    height: 16px;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
`

const ColorAlpha = styled.div`
    position: relative;
    height: 8px;
    background-size: 8px, cover;
    background-repeat: repeat;
`

const ColorAlphaThumb = styled.button`
    all: unset;
    box-sizing: border-box;
    position: absolute;
    top: -4px;
    width: 16px;
    height: 16px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
`

interface ColorPickerContainerProps {
    value: HsvaColor
    onChange: (value: HsvaColor) => void
}

export const ColorPickerContainer = ({ value, onChange }: ColorPickerContainerProps) => {
    const abortControllerRef = useRef<AbortController | null>(null)

    const onEyeDropperClick = async () => {
        if (!window.EyeDropper) {
            return Toast.info('请使用Chrome 95.0 以上版本或 Edge 浏览器使用吸管')
        }

        const eyeDropper = new window.EyeDropper()
        const abortController = new AbortController()
        abortControllerRef.current = abortController

        try {
            const res = await eyeDropper.open({ signal: abortController.signal })
            abortControllerRef.current = null

            const color = chroma(res.sRGBHex)
            const [h, s, v] = color.hsv()
            const alpha = color.alpha()
            onChange({ h, s, v, a: alpha })
        } catch {
            void 0
        }
    }

    useHotkeys('esc', () => {
        abortControllerRef.current?.abort()
    })

    const { ref: hueRef } = useMove(({ ratio: { x } }) => {
        onChange({ ...value, h: Math.round(x * 360) })
    })

    const { ref: alphaRef } = useMove(({ ratio: { x } }) => {
        onChange({ ...value, a: Math.round(x * 100) / 100 })
    })

    return (
        <Container>
            <ColorPicker onClick={onEyeDropperClick}>
                <IconFont size={24} type="Straw" />
            </ColorPicker>
            <Sliders>
                <ColorSlider ref={hueRef}>
                    <ColorSliderThumb style={{ left: `calc(${(value.h / 360) * 100}% - 8px)` }} />
                </ColorSlider>

                <ColorAlpha
                    ref={alphaRef}
                    style={{
                        backgroundImage: `${backgroundImage}, linear-gradient(90deg, transparent, ${chroma(
                            value.h,
                            value.s,
                            value.v,
                            'hsv'
                        ).hex()})`
                    }}
                >
                    <ColorAlphaThumb
                        style={{
                            left: `calc(${value.a * 100}% - 8px)`
                        }}
                    />
                </ColorAlpha>
            </Sliders>
        </Container>
    )
}
