import { IconFont } from '@byecode/ui'
import type { AnimationControlSetting, BlockType } from '@lighthouse/core'
import { AnimationTypeEnum, EaseEnum, LayerInViewAnimation, PresetEnum, TransitionTypeEnum } from '@lighthouse/core'
import React from 'react'

import type { DesignSettingSwitch } from './types'

export const getBlockAndPageDesignLimit = (type: BlockType | 'page' | 'customView'): DesignSettingSwitch => {
    switch (type) {
        case 'page': {
            return {
                size: {
                    disableWidth: true,
                    // disableHeight: true,
                    hideHeight: true,
                    // hideMinMaxRectSizeAdder: true,
                    hideMaxHeight: true,
                    hideMinHeight: true,
                    hideMinWidth: true,
                    hideOverflow: true
                },
                layout: {
                    disableDistributedAlignment: true
                },
                design: {
                    hideRadius: true,
                    hideFill: true,
                    hideTextBorder: true,
                    hideBorder: true,
                    hideShadow: true,
                    hideBlur: true,
                    hideLineColor: true
                }
            }
        }
        case 'breadcrumb': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideOverflow: true
                }
            }
        }
        case 'image':
        case 'video':
        case 'qrBarcode': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideHeight: true,
                    hideMaxHeight: true,
                    hideMinHeight: true
                }
            }
        }
        case 'card':
        case 'collapse':
        case 'field':
        case 'fieldGroup':
        case 'filter':
        case 'subForm':
        case 'view': {
            return {
                size: {
                    disableWidthAuto: true
                }
            }
        }
        case 'tabs': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideOverflow: true
                },
                design: {
                    hideBlur: true,
                    hideFill: true,
                    hideBorder: true
                }
            }
        }
        case 'button': {
            return {
                size: {
                    hideHeight: true,
                    hideMaxHeight: true,
                    hideMinHeight: true,
                    hideOverflow: true
                }
            }
        }
        case 'chart': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideHeight: true,
                    hideMaxHeight: true,
                    hideMinHeight: true,
                    hideOverflow: true
                }
            }
        }
        case 'container':
        case 'formContainer': {
            return {
                size: {},
                layout: {},
                design: {
                    hideFill: true,
                    hideTextBorder: true,
                    hideLineColor: true
                }
            }
        }

        case 'customView': {
            return {
                size: {
                    disableWidthAuto: true
                },
                layout: {
                    disableDistributedAlignment: true
                },
                design: {
                    hideBlur: true,
                    hideTextBorder: true,
                    hideFill: true,
                    hideLineColor: true
                }
            }
        }

        case 'divider': {
            return {
                size: {
                    disableWidthAuto: true,
                    disableHeightAuto: true,
                    hideOverflow: true
                }
            }
        }
        case 'iframe':
        case 'file': {
            return {
                size: {
                    disableWidthAuto: true,
                    disableHeightAuto: true
                }
            }
        }

        case 'text': {
            return {
                size: {},
                design: {
                    hideRadius: true,
                    hideBackground: true,
                    hideVeins: true,
                    hideBlur: true,
                    hideBorder: true,
                    hideLineColor: true
                },
                font: {}
            }
        }
        case 'userCenter': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideHeight: true,
                    hideOverflow: true,
                    hideMaxHeight: true,
                    hideMinHeight: true
                },
                design: {
                    hideLineColor: true,
                    hideTextBorder: true,
                    hideBlur: true,
                    hideVeins: true
                }
            }
        }
        case 'onOff':
        case 'icon': {
            return {
                size: {
                    disableWidthAuto: true,
                    hideHeight: true,
                    hideOverflow: true,
                    hideMaxHeight: true,
                    hideMinHeight: true
                }
            }
        }

        case 'floatBox': {
            return {
                design: {
                    hideFill: true,
                    // hideBorder: true,
                    hideTextBorder: true,
                    hideLineColor: true
                }
            }
        }
        default: {
            return {}
        }
    }
}

export const OVERFLOW_OPTIONS = [
    {
        label: '隐藏',
        value: 'hidden'
    },
    {
        label: '显示',
        value: 'visible'
    },
    {
        label: '滚动',
        value: 'auto'
    }
]

export const ANIMATION_POSITION_OPTIONS = [
    {
        label: <IconFont type="StartTop" size={16} />,
        value: 'top'
    },
    {
        label: <IconFont type="StartMiddle" size={16} />,
        value: 'center'
    },
    {
        label: <IconFont type="StartBottom" size={16} />,
        value: 'bottom'
    }
]

export const ANIMATION_PRESETS_OPTIONS: { label: string; value: PresetEnum; disable?: boolean }[] = [
    {
        label: '渐显',
        value: PresetEnum.fadeIn
    },
    {
        label: '缩放',
        value: PresetEnum.scaleIn
    },

    {
        label: '底部缩放',
        value: PresetEnum.scaleInBottom
    },
    {
        label: '水平翻转',
        value: PresetEnum.flipHorizontal
    },
    {
        label: '垂直翻转',
        value: PresetEnum.flipVertical
    },
    {
        label: '顶部滑入',
        value: PresetEnum.slideInTop
    },
    {
        label: '底部滑入',
        value: PresetEnum.slideInBottom
    },
    {
        label: '左侧滑入',
        value: PresetEnum.slideInLeft
    },
    {
        label: '右侧滑入',
        value: PresetEnum.slideInRight
    },
    {
        label: '自定义',
        value: PresetEnum.custom,
        disable: true
    }
]

export const TRANSITION_OPTIONS = [
    {
        label: '缓动',
        value: TransitionTypeEnum.ease
    },
    {
        label: '弹性',
        value: TransitionTypeEnum.spring
    }
]

export const ANIMATE_OPTIONS = [
    {
        label: '循环',
        value: 'loop'
    },
    {
        label: '镜像',
        value: 'mirror'
    }
]

export const BASE_ON_OPTIONS = [
    {
        label: '时间',
        value: 'time'
    },
    {
        label: '物理现象',
        value: 'physics'
    }
]

export const EASE_OPTIONS = [
    {
        label: '线性',
        value: EaseEnum.linear
    },
    {
        label: '渐入',
        value: EaseEnum.easeIn
    },
    {
        label: '渐出',
        value: EaseEnum.easeOut
    },
    {
        label: '渐入渐出',
        value: EaseEnum.easeInOut
    },
    {
        label: '退入',
        value: EaseEnum.backIn
    },
    {
        label: '退出',
        value: EaseEnum.backOut
    },
    {
        label: '退入退出',
        value: EaseEnum.backInOut
    }
    // {
    //     label: '自定义',
    //     value: EaseEnum.custom,
    //     disable: true
    // }
]

export type AnimationConfigKey = Record<keyof Omit<AnimationControlSetting, 'transition'>, boolean>

export const ROTATE_OPTIONS = [
    {
        label: '2D旋转',
        value: '2d'
    },
    {
        label: '3D旋转',
        value: '3d'
    }
]
