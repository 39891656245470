import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext } from 'react'


export type ContainerBlockContextType = {
    scope?: string
}

export const ContainerBlockContext = createContext<ContainerBlockContextType>({
})

type ContainerBlockProviderProps = PropsWithChildren<{
    value: ContainerBlockContextType
}>

export const ContainerBlockProvider: FC<ContainerBlockProviderProps> = props => <ContainerBlockContext.Provider {...props} />

export const useContainerBlockContext = () => useContext(ContainerBlockContext)
