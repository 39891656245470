import type { CollectEvent } from '@lighthouse/core'

import { eventValidators } from './validators/action'

const validators = eventValidators

export const validateEvent = (event: CollectEvent) => {
    const { writeType } = event

    const thisNodeValidator = validators.find(validator => validator.writeType === writeType)

    if (!thisNodeValidator) {
        return true
    }

    const { validators: nodeValidators } = thisNodeValidator

    for (const nodeValidator of nodeValidators) {
        // @ts-expect-error expect nodeValidator to be a function
        if (!nodeValidator(event)) {
            return false
        }
    }

    return true
}
