import { Divider, Flex, SegmentedControl, Select, Text } from '@byecode/ui'
import type { ContainerBreakPointProtocol } from '@lighthouse/core'
import { AnimationTypeEnum, PresetEnum } from '@lighthouse/core'
import { ListItem4ByecodeUi, TagIcon } from '@lighthouse/shared'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { SwitchControl } from '@/components/BlockSettings/Common/SwitchControl'

import { ANIMATION_POSITION_OPTIONS, ANIMATION_PRESETS_OPTIONS } from '../constants'
import AnimationCreator from './AnimationCreator'
import AnimationSettingController from './AnimationSettingController'
import { AnimationSettingHeader } from './AnimationSettingHeader'
import { getDefaultAnimation, getInitAnimation, getInitLayerInViewAnimationConfig } from './util'

interface LayerInViewAnimationControllerProps {
    onClose: () => void
}

const SCxContainer = styled.div`
    width: 300px;
`

const LayerInViewAnimationController: React.FunctionComponent<LayerInViewAnimationControllerProps> = ({ onClose }) => {
    const { control, setValue } = useFormContext<ContainerBreakPointProtocol>()
    const [suffix, setSuffix] = useState<'layerInView.enter' | 'layerInView.exit' | `hover.effect` | 'loop.effect' | null>()
    return (
        <>
            <SCxContainer>
                <div hidden={!!suffix} style={{padding: '0 16px'}}>
                    <AnimationSettingHeader title="出现效果" onClose={onClose} />
                    <ListItem4ByecodeUi alignItems="center" justifyContent="space-between">
                        <Text>开始位置</Text>
                        <div style={{ width: 180 }}>
                            <Controller
                                control={control}
                                name="breakPoint.animation.layerInView.start"
                                render={({ field }) => <SegmentedControl fullWidth data={ANIMATION_POSITION_OPTIONS} {...field} />}
                            />
                        </div>
                    </ListItem4ByecodeUi>
                    <Controller
                        control={control}
                        name="breakPoint.animation.layerInView.replay"
                        render={({ field }) => (
                            <SwitchControl label="重复播放" checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                        )}
                    />
                    <ListItem4ByecodeUi alignItems="center" justifyContent="space-between">
                        <Text>预设动画</Text>
                        <div style={{ width: 180 }}>
                            <Controller
                                control={control}
                                name="breakPoint.animation.layerInView.preset"
                                render={({ field }) => (
                                    <Select
                                        options={ANIMATION_PRESETS_OPTIONS}
                                        value={field.value}
                                        position="bottom-end"
                                        onChange={v => {
                                            field.onChange(v)
                                            const preset = getInitLayerInViewAnimationConfig(v as PresetEnum)
                                            setValue('breakPoint.animation.layerInView.exit', preset.exit)
                                            setValue('breakPoint.animation.layerInView.enter', preset.enter)
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </ListItem4ByecodeUi>
                    <AnimationCreator
                        label="组件进入"
                        animationLabel="进入效果"
                        suffix="layerInView.enter"
                        onOpenAnimate={() => {
                            setSuffix('layerInView.enter')
                        }}
                    />
                    <AnimationCreator
                        label="组件退出"
                        animationLabel="退出效果"
                        suffix="layerInView.exit"
                        onOpenAnimate={() => {
                            setSuffix('layerInView.exit')
                        }}
                    />
                </div>
                <div hidden={!suffix}>
                    {suffix && (
                        <Controller
                            name={`breakPoint.animation.${suffix}`}
                            control={control}
                            render={({ field }) => (
                                <AnimationSettingController
                                    title={suffix === 'layerInView.enter' ? '进入效果' : '退出效果'}
                                    type={AnimationTypeEnum.layerInView}
                                    value={field.value ?? getDefaultAnimation()}
                                    onBack={() => {
                                        setSuffix(null)
                                    }}
                                    onClose={() => {
                                        onClose()
                                        setSuffix(null)
                                    }}
                                    onChange={v => {
                                        field.onChange(v)
                                        setValue('breakPoint.animation.layerInView.preset', PresetEnum.custom)
                                    }}
                                />
                            )}
                        />
                    )}
                </div>
            </SCxContainer>
        </>
    )
}

export default LayerInViewAnimationController
