import type { MobileDatePickerMode } from '@byecode/ui';
import { IconFont , MobileDatePicker, Text } from '@byecode/ui'
import type { DateConfig, DateField, DateValue, FieldInputADTValue, FieldProtocol } from '@lighthouse/core'
import { add, endOfDay, format, isValid, lightFormat, parse, parseISO, startOfDay } from 'date-fns'
import zhCN from 'date-fns/locale/zh-CN/index'
import { max } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { DATE_FORMAT_OPTIONS } from '../../../../../constants'
import { useApplicationContext } from '../../../../../contexts'
import { SelectPlaceHolder } from '../../../SelectPlaceHolder'
import type { FieldBaseProps } from '../../../types'
import * as CM from '../../commonStyles'
import { extractLunarDate, format2LunarDate, getRelativeDate, getTimestamp } from '../help'

interface DateDrawerFieldProps extends FieldBaseProps {
    value: DateValue
    dateConfig: DateConfig
    dateField?: DateField | FieldProtocol
}

export const SCxContainer = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    min-height: 38px;
    font-size: var(--font-size-normal);
    border-radius: inherit;
`
const defaultDateFormat = DATE_FORMAT_OPTIONS[0].value

export const DateDrawerField: React.FunctionComponent<DateDrawerFieldProps> = props => {
    const { onCellChange, value = '', isControlled, dateConfig, dateField, readOnly, language } = props

    const fieldType = dateField?.type || 'date'

    const { placeholder, date } = dateConfig ?? {}

    const { dateFormat, range = 'ALL', customDays } = date ?? {}

    const withLunarFormat = useMemo(() => {
        if (dateField?.type === 'date') {
            return (dateField as DateField).date.format ?? defaultDateFormat
        }

        if (dateField?.type === 'text') {
            return dateFormat ?? 'yyyy/MM/dd'
        }

        return defaultDateFormat
    }, [dateField, dateFormat])

    const [isLunar, finalFormat] = useMemo(() => {
        const res = withLunarFormat.split('lunar-')

        return [res.length > 1, res[res.length - 1]]
    }, [withLunarFormat])

    const timestamp = useMemo(() => {
        if (fieldType === 'text') {
            if (!value) {
                return value
            }

            // 农历日期格式
            if (isLunar) {
                return extractLunarDate(value.toString())?.getTime() || ''
            }

            const date = parse(value.toString(), finalFormat, new Date())
            if (isValid(date)) {
                return date.getTime()
            }

            return ''
        }

        return getTimestamp(value)
    }, [fieldType, finalFormat, isLunar, value])
    const { pageTarget } = useApplicationContext()

    const [data, setData] = useState(timestamp)

    const usedValue = useMemo(() => {
        if (isControlled) {
            if (!value) {
                return value
            }
            if (fieldType === 'text') {
                // 农历日期格式
                if (isLunar) {
                    return extractLunarDate(value.toString())?.getTime() || ''
                }

                const date = parse(value.toString(), finalFormat, new Date())
                if (isValid(date)) {
                    return date.getTime()
                }

                return ''
            }
            return value
        }
        return data
    }, [data, fieldType, finalFormat, isControlled, isLunar, value])

    const isEmpty = usedValue === ''

    const suffixText = useMemo(() => {
        const currentTimestamp = parseISO(format(Date.now(), 'yyyy-MM-dd')).valueOf()
        const previewTimeStamp = usedValue ? Number(usedValue) : ''
        return previewTimeStamp && !isLunar ? getRelativeDate(previewTimeStamp, currentTimestamp, language) : ''
    }, [isLunar, language, usedValue])

    const [minDate, maxDate] = useMemo(() => {
        switch (range) {
            case 'AFTER_TODAY': {
                return [startOfDay(new Date()), undefined]
            }
            case 'BEFORE_TODAY': {
                return [undefined, endOfDay(new Date())]
            }
            case 'AFTER_CUSTOM': {
                return [
                    customDays
                        ? add(startOfDay(new Date()), {
                              days: max(customDays, 0)
                          })
                        : undefined,
                    undefined
                ]
            }
            default: {
                return []
            }
        }
    }, [customDays, range])

    const handleChange = useCallback(
        (updateData?: Date) => {
            setData(updateData ? updateData.valueOf() : '')
            if (fieldType === 'text') {
                if (!updateData) {
                    onCellChange?.({ type: 'text', value: '' })
                    return
                }

                if (isLunar) {
                    onCellChange?.({
                        type: 'text',
                        value: format2LunarDate(updateData, finalFormat)
                    })

                    return
                }
                onCellChange?.({ type: 'text', value: lightFormat(updateData, finalFormat) })
            } else {
                onCellChange?.({ type: 'date', value: updateData ? updateData.valueOf() : ''} as FieldInputADTValue)
            }
        },
        [fieldType, finalFormat, isLunar, onCellChange, setData]
    )

    const mode = useMemo<MobileDatePickerMode>(() => {
        if (finalFormat === 'HH:mm') {
            return 'time'
        }

        if (finalFormat === 'yyyy/MM' || finalFormat === 'yyyy-MM') {
            return 'month'
        }

        if (finalFormat.includes('HH')) {
            return 'full'
        }

        return 'day'
    }, [finalFormat])

    return (
        <>
            <SCxContainer>
                {isEmpty ? (
                    <SelectPlaceHolder label={placeholder} />
                ) : (
                    <Text>
                        {isLunar
                            ? format2LunarDate(new Date(usedValue), finalFormat)
                            : format(Number(usedValue), finalFormat, {
                                  locale: zhCN
                              })}
                        {suffixText}
                    </Text>
                )}
                <CM.IconWrapper>{!readOnly && <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />}</CM.IconWrapper>
                <MobileDatePicker
                    target={pageTarget}
                    mode={mode}
                    style={{ opacity: 0, position: 'absolute', inset: 0 }}
                    maxDate={maxDate}
                    minDate={minDate}
                    modalProps={{
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-expect-error
                        'data-ignore-click-away': true
                    }}
                    onChange={handleChange}
                />
            </SCxContainer>
        </>
    )
}
