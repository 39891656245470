import { Divider, Group, Popover, SegmentedControl, Text } from '@byecode/ui'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import type { SendEmailActionNode } from '@lighthouse/shared'
import { FilledInput, FillPickerPopoverDropdown, PARAGRAPH_WIDTH_TYPE } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { GapSizeInput } from '@/components/DesignSetting/AutoLayoutSetting/GapSizeInput'
import { PaddingInput } from '@/components/DesignSetting/AutoLayoutSetting/PaddingInput'
import { BorderField } from '@/components/DesignSetting/StyleSetting/BorderField'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import { FormItem } from '../../../Common/FormItem'

const bodyWidthOptions = [
    { value: PARAGRAPH_WIDTH_TYPE.NORMAL, label: '标准' },
    { value: PARAGRAPH_WIDTH_TYPE.WIDER, label: '较宽' },
    { value: PARAGRAPH_WIDTH_TYPE.FULL, label: '全宽' }
]

export const EmailStyle: React.FC = () => {
    const { control } = useFormContext<Pick<SendEmailActionNode, 'config'>>()
    const colorSystemMethods = useColorSystemAction()
    return (
        <>
            <Divider color="var(--color-gray-200)" my={8} />
            <Group
                label="邮件样式"
                styles={{
                    root: {
                        padding: '0!important'
                    }
                }}
            >
                <FormItem label="邮件背景">
                    <Controller
                        control={control}
                        name="config.background"
                        render={({ field }) => (
                            <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                                <Popover.Target>
                                    <FilledInput
                                        onClear={() => {
                                            field.onChange({ type: BACKGROUND_TYPE.color })
                                        }}
                                        value={field.value}
                                        style={{ width: 180 }}
                                    />
                                </Popover.Target>
                                <FillPickerPopoverDropdown
                                    title="背景"
                                    value={field.value}
                                    onChange={val => {
                                        field.onChange(val)
                                    }}
                                    enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                    {...colorSystemMethods}
                                />
                            </Popover>
                        )}
                    />
                </FormItem>
                <FormItem label="邮件边距">
                    <Controller
                        control={control}
                        name="config.padding"
                        render={({ field }) => <PaddingInput value={field.value} onChange={field.onChange} />}
                    />
                </FormItem>
            </Group>
            <Divider color="var(--color-gray-200)" my={8} />
            <Group
                label="正文样式"
                styles={{
                    root: {
                        padding: '0!important'
                    }
                }}
            >
                <FormItem label="正文段落宽度">
                    <Controller
                        control={control}
                        name="config.body.width"
                        render={({ field }) => (
                            <SegmentedControl
                                style={{ width: 180 }}
                                fullWidth
                                data={bodyWidthOptions}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                </FormItem>
                <FormItem label="正文背景">
                    <Controller
                        control={control}
                        name="config.body.background"
                        render={({ field }) => (
                            <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                                <Popover.Target>
                                    <FilledInput
                                        value={field.value}
                                        onClear={() => {
                                            field.onChange({ type: BACKGROUND_TYPE.color })
                                        }}
                                        style={{ width: 180 }}
                                    />
                                </Popover.Target>
                                <FillPickerPopoverDropdown
                                    title="背景"
                                    value={field.value}
                                    onChange={val => {
                                        field.onChange(val)
                                    }}
                                    enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                    {...colorSystemMethods}
                                />
                            </Popover>
                        )}
                    />
                </FormItem>

                <Controller
                    control={control}
                    name="config.body.border"
                    render={({ field }) => (
                        <BorderField
                            label={<Text>正文描边</Text>}
                            value={field.value}
                            onChange={v => {
                                if (isDeepEqual(v, field.value)) {
                                    return
                                }
                                field.onChange(v)
                            }}
                        />
                    )}
                />

                <FormItem label="正文边距">
                    <Controller
                        control={control}
                        name="config.body.padding"
                        render={({ field }) => <PaddingInput value={field.value} onChange={field.onChange} />}
                    />
                </FormItem>

                <FormItem label="正文间距">
                    <Controller
                        control={control}
                        name="config.body.gap"
                        render={({ field }) => <GapSizeInput value={field.value} onChange={field.onChange} />}
                    />
                </FormItem>
            </Group>
        </>
    )
}
