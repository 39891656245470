import {} from '@byecode/ui'
import type { VariableADTvalue } from '@lighthouse/core'
import { Divider } from '@mantine/core'
import React, { useMemo } from 'react'

import { isVariableItemActive } from '../../help'
import type { VariableTree } from '../../types'
import { VariableItem } from '../Item'

interface VariableSystemProps {
    systemOption?: VariableTree
    value?: VariableADTvalue
    opened: boolean
    onOpenChange: (v: boolean) => void
    onChange: (value: VariableADTvalue) => void
    onClose?: () => void
}

export const VariableSystem: React.FunctionComponent<VariableSystemProps> = ({
    value,
    systemOption,
    opened,
    onOpenChange,
    onChange,
    onClose
}) => {
    if (!systemOption || (systemOption?.children ?? []).length === 0) {
        return null
    }

    return (
        <VariableItem
            value={value}
            onChange={onChange}
            data={systemOption}
            opened={opened}
            onOpenChange={onOpenChange}
            onClose={onClose}
        />
    )
}
