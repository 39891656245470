/** 标题设置 */
import { Enum } from '@lighthouse/tools'

export interface TitleConfig {
    /** 是否显示标题，默认为1，显示 */
    showTitle: boolean
}

export const BlockType = Enum(
    'chart',
    'field',
    'image',
    'view',
    'relatedView',
    'button',
    'card',
    'divider',
    'fieldGroup',
    'video',
    'breadcrumb',
    'iframe',
    'collapse',
    'file',
    'container',
    'formContainer',
    'tabs',
    'text',
    'filter',
    'qrBarcode',
    'subForm',
    'icon',
    'subForm',
    'icon',
    'userCenter',
    'floatBox',
    'onOff'
)

export type BlockType = Enum<typeof BlockType>

export interface HttpResp<T = undefined> {
    success: boolean
    msg: string
    code: string
    content: T
}
