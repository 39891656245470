import BrowserDetector from 'browser-dtector'

export const getBrowser = (): BrowserDetector => {
    return new BrowserDetector(window.navigator.userAgent)
}

// export const isMobileDevice = () => {
//     const { userAgent } = getBrowser()
//     if (!userAgent) {
//         return false
//     }
//     return (/android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent));
// }

export const isWechatBrowser = () => !!getBrowser().userAgent?.toLocaleLowerCase().includes('micromessenger')

export const isIosPlatform = () => {
    const platform = getBrowser().getBrowserInfo().platform?.toString().toLocaleLowerCase()
    return platform === 'ipad' || platform === 'iphone' || platform === 'macintosh'
}


export const isSafaris = () => {
    const userAgent = getBrowser().userAgent?.toLowerCase() ?? ''
    return userAgent.includes('safari') && !userAgent.includes('chrome');
}
