import { Divider, Empty } from '@byecode/ui'
import type { BlockAbstract } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { useAtom } from 'jotai'
import React, { useCallback, useMemo, useState } from 'react'

import { templateBlockAtom } from '@/atoms/application/state'
import type { TemplateComponent } from '@/atoms/application/type'
import { outsideDraggingNode } from '@/atoms/page/state'
import { flowLayoutBeginAdding } from '@/utils/flowLayoutEventBus'

import { Search } from '../Search'
import * as CM from '../style'
import * as SC from './styles'
import { TemplateBlock } from './TemplateBlock'

export const TemplateList: React.FC = () => {
    const [_, setDragNode] = useAtom(outsideDraggingNode)
    const [searchWord, setSearchWord] = useState<string>('')
    const templateBlocks = useAtomData(templateBlockAtom)

    const searchBlockList: TemplateComponent[] = useMemo(() => {
        return templateBlocks.map(kind => ({ ...kind, components: kind.components.filter(block => block.name.includes(searchWord)) })).filter(
            v => v.components.length > 0
        )
    }, [searchWord, templateBlocks])

    const handleCreate = useCallback(
        (ev: React.MouseEvent, block: BlockAbstract) => {
            flowLayoutBeginAdding(ev.nativeEvent)

            setDragNode(block)
        },
        [setDragNode]
    )

    return (
        <SC.Container>
            <CM.Header>
                <Search value={searchWord} onChange={val => setSearchWord(val)} clearable />
            </CM.Header>
            <Divider style={{ margin: '8px 14px 0 14px' }} />
            <SC.ChunkContainer>
                {searchBlockList.map((option, index) => (
                    <React.Fragment key={option.group}>
                        <TemplateBlock
                            option={option}
                            onCreate={(e, v) => {
                                const block = v
                                if (!block) {
                                    return
                                }
                                handleCreate(e, block)
                            }}
                            // onOpenQuestion={onOpenQuestion}
                        />
                        {index + 1 !== searchBlockList.length && <Divider style={{ margin: '12px 14px' }} />}
                    </React.Fragment>
                ))}
                {searchBlockList.length === 0 && (
                    <Empty
                        styles={{
                            root: {
                                width: '100%',
                                height: 244
                            },
                            wrapper: {
                                gap: 12
                            }
                        }}
                        icon={<CM.Icon type="NoComponent" size={36} />}
                        description="找不到组件"
                    />
                )}
            </SC.ChunkContainer>
        </SC.Container>
    )
}
