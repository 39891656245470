import { Flex } from '@byecode/ui'
import React from 'react'

import { SizeInput } from '@/components/DesignSetting/SizeInput'

type OffsetSizeValue = [number, number] | undefined

interface OffsetInputProps {
    value?: OffsetSizeValue
    onChange: (value: OffsetSizeValue) => void
    disableHighlight?: boolean
}
export const OffsetInput: React.FC<OffsetInputProps> = ({ value, onChange, disableHighlight }) => {
    const [x, y] = value || [0, 0]

    return (
        <Flex style={{ width: 180 }} gap={8}>
            <SizeInput
                radius={0}
                icon="X"
                type="number"
                onFocus={e => e.currentTarget.select()}
                value={x}
                onChange={val => onChange([val, y])}
            />
            <SizeInput
                radius={0}
                icon="Y"
                type="number"
                onFocus={e => e.currentTarget.select()}
                value={y}
                onChange={val => onChange([x, val])}
            />
        </Flex>
    )
}
