import type { PopoverProps } from '@byecode/ui'
import { backgroundTransitionOnClick, Divider, Flex, IconFont, Input, Modal, pointer, Popover, useDisclosure } from '@byecode/ui'
import type { AppUser, UserProtocol } from '@lighthouse/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import type { UserAvatarProps } from '../../../components/UserAvatar';
import { UserAvatar } from '../../../components/UserAvatar'
import type { MenuOpItem } from '../../../components/UserMenu'
import { useApplicationContext } from '../../../contexts'
import { getColorById, getImageFullUrlInApplication } from '../../../utils'
import type { LoginAuthType } from '../../AccountPage'
import { BindUserInfo } from './BindUserInfo'

interface UserAvatarModalProps extends Pick<PopoverProps, 'position'> {
    userData: AppUser
    isMobile?: boolean
    style?: React.CSSProperties
    avatarProps?: Partial<UserAvatarProps>
    onLogout?: () => void
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

const SCxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`

const SCxUserMenuContainer = styled.div`
    width: 240px;
`

const SCxTitle = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

const SCxTitleName = styled.span`
    font-weight: 500;
    font-size: var(--font-size-base);
    color: var(--color-black);
    line-height: 24px;
`

const SCxUserInfo = styled(Flex)`
    padding: 8px 16px;
`

const SCxTitlePhone = styled.span`
    font-size: var(--font-size-sm);
    color: var(--color-gray-400);
    line-height: 22px;
`

const SCxSettingContainer = styled.div``

const SCxSettingItem = styled.div`
    display: flex;
    align-items: center;
    height: 36px;
    padding: 0 16px;
    ${backgroundTransitionOnClick}
    ${pointer}
`

const SCxSettingName = styled.span`
    font-size: var(--font-size-normal);
    color: var(--color-black);
    flex: 1;
`

export const PopoverUserCenter: React.FunctionComponent<UserAvatarModalProps> = ({
    userData,
    style,
    position,
    avatarProps,
    onLogout,
    onUpdateBindAccount
}) => {
    const [open, setOpen] = useState(false)
    const [modalOpened, setModalOpened] = useState(false)
    const { pageTarget, appId } = useApplicationContext()
    const { t } = useTranslation()

    return (
        <>
            <Popover opened={open} onChange={setOpen} width="auto" withinPortal position={position}>
                <Popover.Target>
                    <SCxContainer style={style}>
                        <UserAvatar
                            id={userData.userId}
                            color="var(--color-white)"
                            avatar={getImageFullUrlInApplication(appId, userData.avatar)}
                            borderColor={userData.avatar ? 'var(--color-gray-200)' : getColorById(userData.uniqueUserId)}
                            background={getColorById(userData.uniqueUserId)}
                            name={userData.username}
                            size={36}
                            {...avatarProps}
                        />
                    </SCxContainer>
                </Popover.Target>
                <Popover.Dropdown>
                    <SCxUserMenuContainer>
                        <SCxUserInfo justifyContent="space-between">
                            <SCxTitle>
                                <SCxTitleName>{userData.username ?? '未命名'}</SCxTitleName>
                                <SCxTitlePhone>{userData.mobile || '-'}</SCxTitlePhone>
                            </SCxTitle>
                            <UserAvatar
                                id={userData.userId}
                                color="var(--color-white)"
                                avatar={getImageFullUrlInApplication(appId, userData.avatar)}
                                borderColor={userData.avatar ? 'var(--color-gray-200)' : getColorById(userData.uniqueUserId)}
                                background={getColorById(userData.uniqueUserId)}
                                name={userData.username}
                                {...avatarProps}
                                size={48}
                                fontSize={20}
                            />
                        </SCxUserInfo>

                        <Divider style={{ margin: '8px 0' }} />
                        <SCxSettingContainer>
                            <SCxSettingItem
                                onClick={() => {
                                    setModalOpened(true)
                                    setOpen(false)
                                }}
                            >
                                <SCxSettingName>{t('accountSetting')}</SCxSettingName>
                            </SCxSettingItem>
                            <SCxSettingItem
                                onClick={() => {
                                    onLogout?.()
                                    setOpen(false)
                                }}
                            >
                                <SCxSettingName>{t('logout')}</SCxSettingName>
                            </SCxSettingItem>
                        </SCxSettingContainer>
                    </SCxUserMenuContainer>
                </Popover.Dropdown>
            </Popover>
            <Modal
                title={t('accountSetting')}
                target={pageTarget}
                open={modalOpened}
                onClose={() => setModalOpened(false)}
                onClick={e => e.stopPropagation()}
            >
                <BindUserInfo userData={userData} onUpdateBindAccount={onUpdateBindAccount} />
            </Modal>
        </>
    )
}
