import type { FilterOption } from '@lighthouse/core'
import type { Draft } from 'immer'
import { atom } from 'jotai'
import { atomFamily } from 'jotai/utils'
import { atomWithImmer } from 'jotai-immer'
import shallowEqual from 'shallowequal'


// id 为 blockId-filterItemId
export const filterBlockOptionsAtom = atomWithImmer<Record<string, FilterOption[]>>({})

export const filterBlockOptionsAtomFamily = atomFamily(() => {
    return atom(
        get => {
            return get(filterBlockOptionsAtom)
        },
        (
            _,
            set,
            payload:
                | Record<string, FilterOption[]>
                | ((draft: Draft<Record<string, FilterOption[]>>) => Draft<Record<string, FilterOption[]>>)
        ) =>
            set(filterBlockOptionsAtom, draft => {
                if (typeof payload === 'function') {
                    payload(draft)
                    return
                }
                draft = payload
            })
    )
}, shallowEqual)
