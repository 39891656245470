import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import type { FilterBlockAbstract, FilterWay, OperatorButtonConfigure } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { omit, reduce } from 'rambda'
import React, { useCallback, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { getFilterBlockItem } from '@/constants/Block/generate/filter'
import { useCurrentAppID } from '@/hooks/useApplication'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { AddFilterItem } from './AddFilterItem'
import { FilterItem } from './FilterItem'

interface FilterListProps {
    blockId: string
}

const SCxContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    padding: 0 8px;
`

export const FilterList: React.FunctionComponent<FilterListProps> = ({ blockId }) => {
    const { control } = useFormContext<FilterBlockAbstract['config']>()
    const [activeId, setActiveId] = useState('')
    const appId = useCurrentAppID()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const {
        fields: f,
        remove,
        append,
        move
    } = useFieldArray({
        control,
        name: 'filterItems',
        keyName: 'key'
    })

    const fields = f as unknown as OperatorButtonConfigure[]

    const [openedMap, setOpenMap] = useImmer(
        reduce<OperatorButtonConfigure, Record<string, boolean>>(
            (preVal, { id }) => {
                preVal[id] = true
                return preVal
            },
            {},
            fields
        )
    )

    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    const handleDragStart = useCallback((event: DragStartEvent) => {
        const { active } = event
        setActiveId(active?.id.toString())
    }, [])

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            if (active.id !== over?.id) {
                const oldIndex = fields.findIndex(item => item.id === active.id)
                const newIndex = fields.findIndex(item => item.id === over?.id)
                move?.(oldIndex, newIndex)
            }
            setActiveId('')
        },
        [fields, move]
    )

    const handleAdd = useCallback(
        (type: string) => {
            const newItem = getFilterBlockItem(type as FilterWay)
            append(newItem)
            setOpenMap(draft => {
                draft[newItem.id] = true
            })
        },
        [append, setOpenMap]
    )

    return (
        <SCxContainer>
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                onDragStart={handleDragStart}
                modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
            >
                <SortableContext items={fields.map(d => d.id)} strategy={verticalListSortingStrategy}>
                    {fields.map((item, index) => (
                        <FilterItem
                            key={item.id}
                            appId={appId}
                            pageId={pageId}
                            blockId={blockId}
                            currentPageRecordId={curr.recordId}
                            id={item.id}
                            // index={index}
                            prefixName={`filterItems.${index}`}
                            opened={openedMap[item.id] && !activeId}
                            onChangeOpen={opened => {
                                setOpenMap(draft => {
                                    draft[item.id] = opened
                                })
                            }}
                            onDelete={() => {
                                remove(index)
                                setOpenMap(draft => {
                                    draft = omit([item.id], draft)
                                })
                            }}
                        />
                    ))}
                </SortableContext>
            </DndContext>
            <AddFilterItem onAdd={handleAdd} />
        </SCxContainer>
    )
}
