import type { AnimationTypeEnum } from '@lighthouse/core'
import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext } from 'react'

export type AnimationContextType = {
    queue: { blockId: string; scope?: string; type?: AnimationTypeEnum; played?: boolean }[]
    onAnimationComplete?: (v: { blockId: string; scope?: string; }) => void
    onAnimationStart?: (v: { blockId: string; scope?: string;  }) => void
}

export const AnimationContext = createContext<AnimationContextType>({
    queue: []
})

type AnimationProviderProps = PropsWithChildren<{
    value: AnimationContextType
}>

export const AnimationProvider: FC<AnimationProviderProps> = props => <AnimationContext.Provider {...props} />

export const useAnimationContext = () => useContext(AnimationContext)
