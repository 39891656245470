import type { FlowNode } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

export const handleCloseFloat = (node: FlowNode, excParams: AnyObject) => {
    const { actionEvents, clickTriggerNodeParams } = excParams
   actionEvents.closeFloat()
    return {
        success: true
    }
}
