import type { AnyObject } from '@byecode/ui'
import type { DataSourceAbstract, EVENT_VARIABLE_TYPE, InputValueItem, PageAbstract, PageMetaData, RecordLikeProtocol } from '@lighthouse/core'

import type { FlowNode, NodeConfig } from './flow'

// import type { VariableSourceType } from "../components"

export enum VariableSourceType {
    page = 'page',
    module = 'module',
    parentPage = 'parentPage',
    parentNode = 'parentNode',
    parentJoinNode = 'parentJoinNode',
    form = 'form'
}

export type VariableUpstreamSource = Record<'sourceType', VariableSourceType.parentNode> & {
    parentNodes: FlowNode[]
    dataSourceList: DataSourceAbstract[]
}

export type VariableFormUpstreamSource = Record<'sourceType', VariableSourceType.form> & {
    parentNodes: FlowNode[]
    dataSourceList: DataSourceAbstract[]
}

export type VariablePageSource = Record<'sourceType', VariableSourceType.page | VariableSourceType.parentPage> & {
    dataSource?: DataSourceAbstract
    page?: PageAbstract | null
}

export type VariableSource = VariablePageSource | VariableUpstreamSource | VariableFormUpstreamSource

// 当前和上游页面数据
export type PrevPageDatasourceForVariable = {
    page?: PageAbstract
    recordId?: string
    variableSourceType: VariableSourceType.parentPage
    datasource?: DataSourceAbstract
    formState?: Record<string, InputValueItem>
}

export type CurrPageDatasourceForVariable = {
    page?: PageAbstract
    recordId?: string
    variableSourceType: VariableSourceType.page
    datasource?: DataSourceAbstract
    formState?: Record<string, InputValueItem>
}

export type TriggerEventExtraOption = {
    pageName?: string
    blockName?: string
}

export type ResolvedVariableExtraParams =  Record<string, AnyObject> & {
    blockName?: string
    pageName?: string
    event: {
        eventType?: EVENT_VARIABLE_TYPE
        eventName?: string
    }
    dataSourceList?: DataSourceAbstract[]
    pageList?: PageAbstract[]
    userRecord?: {
        datasource: DataSourceAbstract | undefined;
        record: RecordLikeProtocol | undefined;
    }
    formRecord?: RecordLikeProtocol
    viewRecord?: RecordLikeProtocol
    pageRecord?: RecordLikeProtocol
    getCurrentPageLink?: () => string
    getCurrentPageDeps?: () => PageMetaData | undefined
    pageStackFormState?: Record<string, InputValueItem>
    nodes?: FlowNode[]
    clickTriggerNodeParams: {
        record?: RecordLikeProtocol
        prevRecord?: RecordLikeProtocol
        currentAppId: string
        currentEnvId: string
        currentUserId: string
        currentPageId: string
    }
}
