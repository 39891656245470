import { Empty } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { VariableADTvalue } from '@lighthouse/core'
import { DIRECTION } from '@lighthouse/core'
import { mergeRefs } from '@lighthouse/tools'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'

import { getPaddingStyle } from '../../utils/design'
import { getLayoutStyle, getSizeStyle } from '../../utils/layout'
import { useAppContainerContext } from '../ApplicationContainer'
import type { VisibleDomProps } from '../VisibleDom'
import type { ContainerLayoutContextValue } from './Context'
import { ContainerLayoutProvider, DEFAULT_LAYOUT_CONTEXT_VALUE, FlowLayoutProvider } from './Context'
import { HighlightOverlay } from './HighlightOverlay'
import { NodeRender } from './NodeRender'
import type { ContainerHighlight, FlowLayoutNode, LayoutProperty, NodeIdWithScope, NodeRenderProps } from './types'

// const OutlineWrapper = styled.div`
//     position: absolute;
//     inset: 0;
//     pointer-events: none;
//     display: none;
//     border: 2px dashed var(--color-main);
//     border-radius: 0 0 12px 12px;
// `
const Container = styled.div<{ disabled?: boolean }>`
    position: relative;
    z-index: 1;
    margin: 0 auto; ;
`

interface LayoutEngineProps {
    data: FlowLayoutNode[]
    size?: LayoutProperty['size']
    layout?: LayoutProperty['layout']
    design?: LayoutProperty['design']
    style?: React.CSSProperties
    parseBackgroundVariableImage?: (value: VariableADTvalue | undefined) => string
    disabled?: boolean
    scale?: number
    highlight?: Record<string, ContainerHighlight | undefined>
    selectedNodes?: NodeIdWithScope[]
    userId?: string
    visibleParams?: VisibleDomProps
    sortable?: boolean
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
}

export const LayoutEngine = React.forwardRef<HTMLDivElement, LayoutEngineProps>((props, ref) => {
    const { data, size, layout, design, style, userId, visibleParams, sortable, ...rest } = props
    const { scale = 1 } = useAppContainerContext()
    const [root, setRoot] = useState<HTMLDivElement | null>(null)

    const context = useMemo<ContainerLayoutContextValue>(() => ({ size, layout, design, level: 1 }), [design, layout, size])

    return (
        <FlowLayoutProvider
            value={{
                ...DEFAULT_LAYOUT_CONTEXT_VALUE,
                data,
                rootElement: root,
                size,
                layout,
                design,
                ...rest
            }}
        >
            <Container
                ref={mergeRefs([setRoot, ref])}
                data-layout-node="root"
                tabIndex={0}
                disabled={rest.disabled}
                style={{
                    ...style,
                    ...getSizeStyle(size, DIRECTION.vertical, sortable, scale),
                    // height: 1,
                    ...getLayoutStyle(layout),
                    ...getPaddingStyle(layout?.padding)
                }}
            >
                {!rest.disabled && data.length === 0 ? (
                    <Empty
                        style={{ pointerEvents: 'none' }}
                        icon={<img style={{ width: 315 }} src={getAssetUrl('empty', 'no_block.svg')} alt="未找到数据" />}
                        description="点击左侧【添加】，拖拽「组件」到此区域"
                    />
                ) : (
                    <ContainerLayoutProvider value={context}>
                        {data.map(item => (
                            <NodeRender key={item.id} data={item} userId={userId} visibleParams={visibleParams} sortable={sortable} />
                        ))}
                    </ContainerLayoutProvider>
                )}

                <HighlightOverlay />

                {/* {rest.disabled ? null : <OutlineWrapper />} */}
            </Container>
        </FlowLayoutProvider>
    )
})
