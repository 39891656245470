import { Divider } from '@byecode/ui/components/Divider'
import { Flex } from '@byecode/ui/components/Flex'
import { Group } from '@byecode/ui/components/Group'
import { Select } from '@byecode/ui/components/Select'
import { type FlowNode, getFieldOptions, getPrimaryDsSystemFieldId, getUpstreamRealDsId, pureTextFieldTypes } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { FieldSelect } from '@/components/FieldSelect'
import { PopoverQuestion } from '@/components/PopoverQuestion'
import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'

import { SettingRecordContent } from '../../../SettingRecordContent'
import { PaySuccessAfter } from '../component/PaySuccessAfter'
import * as SC from './styles'

interface PayProps {
    prefixName: string
    allParentNodes?: FlowNode[]
}

const EmptyArray: FlowNode[] = []

const Pay: React.FunctionComponent<PayProps> = props => {
    const { allParentNodes = EmptyArray, prefixName } = props
    const { control, watch } = useFormContext()

    const { type } = useFlow()
    const isActionFlow = Boolean(type)
    const { dsId } = useActionAdderDepParams()
    const nodeId = watch(`${prefixName}.payConfig.nodeId`)

    const dataSourceId = useMemo(() => {
        return isActionFlow ? getUpstreamRealDsId(nodeId, allParentNodes) : dsId
    }, [allParentNodes, dsId, isActionFlow, nodeId])

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSource = useDataSource(appId, envId, dataSourceId ?? '')
    const dataSourceList = useDataSourceList(appId, envId)

    const lookTextFieldOptions = useMemo(() => {
        if (!dataSource) {
            return []
        }
        return getFieldOptions(
            dataSource,
            field => pureTextFieldTypes.has(field.type) || (field.type === 'formula' && field.innerType === 'TEXT')
        )
    }, [dataSource])

    const lookNumberFieldOptions = useMemo(() => {
        if (!dataSource) {
            return []
        }
        return getFieldOptions(dataSource, field => field.type === 'number' || (field.type === 'formula' && field.innerType === 'NUMBER'))
    }, [dataSource])

    if (!dataSource) {
        return null
    }

    return (
        <>
            <Group label="支付订单信息">
                <SC.FieldList>
                    <SC.FieldItem>
                        <PopoverQuestion
                            type="orderId"
                            renderTarget={(node, ref) => (
                                <Flex gap={16} ref={ref}>
                                    <SC.FieldTitle required>订单号</SC.FieldTitle>
                                    {node}
                                </Flex>
                            )}
                        />
                        <Select
                            value={getPrimaryDsSystemFieldId('ID', { dataSource, dataSourceList })}
                            options={lookTextFieldOptions}
                            styles={{
                                root: {
                                    width: '100%'
                                }
                            }}
                            disabled
                        />
                    </SC.FieldItem>
                    <SC.FieldItem>
                        <PopoverQuestion
                            type="shopDescribe"
                            renderTarget={(node, ref) => (
                                <Flex gap={16} ref={ref}>
                                    <SC.FieldTitle required>商品描述</SC.FieldTitle>
                                    {node}
                                </Flex>
                            )}
                        />

                        <Controller
                            control={control}
                            name={`${prefixName}.payConfig.descFieldId`}
                            render={({ field }) => (
                                <FieldSelect
                                    options={lookTextFieldOptions}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    placeholder="从支付订单中选择字段"
                                    {...field}
                                />
                            )}
                        />
                    </SC.FieldItem>
                    <SC.FieldItem>
                        <PopoverQuestion
                            type="orderMoney"
                            renderTarget={(node, ref) => (
                                <Flex gap={16} ref={ref}>
                                    <SC.FieldTitle required>订单金额 （元）</SC.FieldTitle>
                                    {node}
                                </Flex>
                            )}
                        />
                        <Controller
                            control={control}
                            name={`${prefixName}.payConfig.amountFieldId`}
                            render={({ field }) => (
                                <FieldSelect
                                    options={lookNumberFieldOptions}
                                    styles={{
                                        root: {
                                            width: '100%'
                                        }
                                    }}
                                    placeholder="从支付订单中选择字段"
                                    {...field}
                                />
                            )}
                        />
                    </SC.FieldItem>
                </SC.FieldList>
            </Group>
            <Divider />
            <Group label="支付成功后更新订单">
                <SettingRecordContent
                    prefixName={`${prefixName}.payConfig`}
                    fieldsName="succeedFieldSettings"
                    dataSourceId={dataSource.id}
                    disablePageLink
                    disableFormOption
                    disableViewOption
                    disablePageOption
                />
            </Group>
            <Divider />
            <PaySuccessAfter prefixName={prefixName} dataSource={dataSource} isActionFlow={isActionFlow} allParentNodes={allParentNodes} />

            {/* <Divider />
            <Group label="支付失败后更新订单">
                <SettingRecordContent
                    prefixName={`${prefixName}.payConfig`}
                    fieldsName="failedFieldSettings"
                    allParentNodes={allParentNodes}
                    dataSourceId={dataSource.id}
                />
            </Group> */}
        </>
    )
}

export default Pay
