import { Popover } from '@byecode/ui'
import type { BackgroundNoImageProtocol, BackgroundType, ContainerBlockConfig } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import { FilledInput, FillPickerPopoverDropdown, ListItem4ByecodeUi } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { InheritLabel } from '@/components/InheritLabel'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import { combinePath } from './utils'

interface FillColorProps {
    prefixPath?: string
    label?: string
    enabledBackgroundTypes?: BackgroundType[]
    enablePadding?: boolean
    onSetBreakKey?: (name: string) => void
}

const defaultBackgroundTypes: BackgroundType[] = [BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]

export const FillColor: React.FC<FillColorProps> = ({
    label = '填充',
    prefixPath,
    enablePadding,
    enabledBackgroundTypes = defaultBackgroundTypes,
    onSetBreakKey
}) => {
    const { control } = useFormContext<ContainerBlockConfig>()
    const fillColorName = combinePath(prefixPath, 'breakPoint.design.color')
    const colorSystemMethods = useColorSystemAction()

    return (
        <ListItem4ByecodeUi enablePadding={enablePadding} justifyContent="space-between" alignItems="center">
            <InheritLabel label={label} name="breakPoint.design.color" />
            <Controller
                control={control}
                name={fillColorName}
                render={({ field }) => {
                    return (
                        <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={168}>
                            <Popover.Target>
                                <FilledInput
                                    style={{ width: 180 }}
                                    value={field.value}
                                    onClear={() => {
                                        field.onChange({ type: BACKGROUND_TYPE.color })
                                        onSetBreakKey?.(fillColorName)
                                    }}
                                />
                            </Popover.Target>
                            <FillPickerPopoverDropdown
                                title="填充"
                                value={field.value}
                                onChange={val => {
                                    if (isDeepEqual(val, field.value)) {
                                        return
                                    }
                                    field.onChange?.(val)
                                    onSetBreakKey?.(fillColorName)
                                }}
                                enabledBackgroundTypes={enabledBackgroundTypes}
                                {...colorSystemMethods}
                            />
                        </Popover>
                    )
                }}
            />
        </ListItem4ByecodeUi>
    )
}
