import type { AnimationControlSetting, AnimationProtocols, LayerInViewAnimation, TransitionProtocol } from '@lighthouse/core'
import { AnimationBreakPoint, AnimationTypeEnum, EaseEnum, HoverAnimation, PresetEnum, TransitionTypeEnum } from '@lighthouse/core'

// const getTransition

export const getInitTransition = function (type: EaseEnum | 'spring'): TransitionProtocol {
    switch (type) {
        case EaseEnum.custom: {
            return {
                type: TransitionTypeEnum.ease,
                ease: EaseEnum.custom,
                basedOn: 'time',
                time: 0.4,
                delay: 0,
                bezier: '0.25,0.1,0.25,0.1',
                bounce: 0.2,
                stiffness: 0,
                damping: 0,
                mass: 0
            }
        }
        case EaseEnum.linear: {
            return {
                type: TransitionTypeEnum.ease,
                ease: EaseEnum.linear,
                basedOn: 'time',
                time: 0.4,
                delay: 0,
                bezier: '0,0,1,1',
                bounce: 0.2,
                stiffness: 0,
                damping: 0,
                mass: 0
            }
        }
        case EaseEnum.easeIn: {
            return {
                type: TransitionTypeEnum.ease,
                ease: EaseEnum.easeIn,
                basedOn: 'time',
                time: 0.4,
                delay: 0,
                bezier: '0.5,0,0.88,0.77',
                bounce: 0.2,
                stiffness: 0,
                damping: 0,
                mass: 0
            }
        }
        case EaseEnum.easeOut: {
            return {
                type: TransitionTypeEnum.ease,
                ease: EaseEnum.easeOut,
                basedOn: 'time',
                time: 0.4,
                delay: 0,
                bezier: '0.12,0.23,0.5,1',
                bounce: 0.2,
                stiffness: 0,
                damping: 0,
                mass: 0
            }
        }
        case EaseEnum.easeInOut: {
            return {
                type: TransitionTypeEnum.ease,
                ease: EaseEnum.easeInOut,
                basedOn: 'time',
                time: 0.4,
                delay: 0,
                bezier: '0.44,0,0.56,1',
                bounce: 0.2,
                stiffness: 0,
                damping: 0,
                mass: 0
            }
        }
        case EaseEnum.backOut: {
            return {
                type: TransitionTypeEnum.ease,
                ease: EaseEnum.backOut,
                basedOn: 'time',
                time: 0.4,
                delay: 0,
                bezier: '0.79, -0.33, 0.79, 0.33',
                bounce: 0.2,
                stiffness: 0,
                damping: 0,
                mass: 0
            }
        }
        case EaseEnum.backIn: {
            return {
                type: TransitionTypeEnum.ease,
                ease: EaseEnum.backIn,
                basedOn: 'time',
                time: 0.4,
                delay: 0,
                bezier: '0.15, 0.45, 0.15, 1.35',
                bounce: 0.2,
                stiffness: 0,
                damping: 0,
                mass: 0
            }
        }
        case EaseEnum.backInOut: {
            return {
                type: TransitionTypeEnum.ease,
                ease: EaseEnum.backInOut,
                basedOn: 'time',
                time: 0.4,
                delay: 0,
                bezier: '0.7, -0.35, 0.3, 1.35',
                bounce: 0.2,
                stiffness: 0,
                damping: 0,
                mass: 0
            }
        }
        // 默认为弹性动画
        default: {
            return {
                type: TransitionTypeEnum.spring,
                basedOn: 'physics',
                time: 0.4,
                delay: 0,
                bezier: '0.25,0.1,0.25,1.0',
                bounce: 0.2,
                stiffness: 400,
                damping: 30,
                mass: 1
            }
        }
    }
}

export const getInitLayerInViewAnimationConfig = function (type: PresetEnum): Pick<LayerInViewAnimation, 'exit' | 'enter'> {
    switch (type) {
        case PresetEnum.slideInBottom: {
            const animation: AnimationControlSetting = {
                opacity: 0,
                scale: 1,
                rotateType: '2d',
                rotate: 0,
                rotate3d: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                skew: [0, 0],
                offset: [0, 150],
                transition: getInitTransition('spring')
            }
            return {
                enter: animation,
                exit: animation
            }
        }
        case PresetEnum.flipHorizontal: {
            const animation: AnimationControlSetting = {
                opacity: 1,
                scale: 1,
                rotateType: '3d',
                rotate: 0,
                skew: [0, 0],
                offset: [0, 0],
                rotate3d: {
                    x: 0,
                    y: -180,
                    z: 0
                },
                transition: getInitTransition('spring')
            }
            return {
                enter: animation,
                exit: animation
            }
        }
        case PresetEnum.flipVertical: {
            const animation: AnimationControlSetting = {
                opacity: 1,
                scale: 1,
                rotateType: '3d',
                rotate: 0,
                skew: [0, 0],
                offset: [0, 0],
                rotate3d: {
                    x: -180,
                    y: 0,
                    z: 0
                },
                transition: getInitTransition('spring')
            }
            return {
                enter: animation,
                exit: animation
            }
        }
        case PresetEnum.slideInRight: {
            const animation: AnimationControlSetting = {
                opacity: 0,
                scale: 1,
                rotateType: '2d',
                rotate: 0,
                rotate3d: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                skew: [0, 0],
                offset: [150, 0],
                transition: getInitTransition('spring')
            }
            return {
                enter: animation,
                exit: animation
            }
        }
        case PresetEnum.slideInLeft: {
            const animation: AnimationControlSetting = {
                opacity: 0,
                scale: 1,
                rotateType: '2d',
                rotate: 0,
                rotate3d: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                skew: [0, 0],
                offset: [-150, 0],
                transition: getInitTransition('spring')
            }
            return {
                enter: animation,
                exit: animation
            }
        }
        case PresetEnum.slideInTop: {
            const animation: AnimationControlSetting = {
                opacity: 0,
                scale: 1,
                rotateType: '2d',
                rotate: 0,
                rotate3d: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                skew: [0, 0],
                offset: [0, -150],
                transition: getInitTransition('spring')
            }
            return {
                enter: animation,
                exit: animation
            }
        }
        case PresetEnum.scaleIn: {
            const animation: AnimationControlSetting = {
                opacity: 0,
                scale: 0.5,
                rotateType: '2d',
                rotate: 0,
                rotate3d: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                skew: [0, 0],
                offset: [0, 0],
                transition: getInitTransition('spring')
            }
            return {
                enter: animation,
                exit: animation
            }
        }
        case PresetEnum.scaleInBottom: {
            const animation: AnimationControlSetting = {
                opacity: 0,
                scale: 0.5,
                rotateType: '2d',
                rotate: 0,
                rotate3d: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                skew: [0, 0],
                offset: [0, 50],
                transition: getInitTransition('spring')
            }
            return {
                enter: animation,
                exit: animation
            }
        }
        // 默认为淡入淡出 fadeIn
        default: {
            const animation: AnimationControlSetting = {
                opacity: 0,
                scale: 1,
                rotateType: '2d',
                rotate: 0,
                rotate3d: {
                    x: 0,
                    y: 0,
                    z: 0
                },
                skew: [0, 0],
                offset: [0, 0],
                transition: getInitTransition('spring')
            }
            return {
                enter: animation,
                exit: animation
            }
        }
    }
}

export const getInitAnimation = function (type: AnimationTypeEnum): AnimationProtocols {
    switch (type) {
        case 'hover': {
            return {
                type: 'hover',
                effect: {
                    opacity: 1,
                    scale: 1.1,
                    rotateType: '2d',
                    rotate: 0,
                    rotate3d: {
                        x: 0,
                        y: 0,
                        z: 0
                    },
                    skew: [0, 0],
                    offset: [0, 0],
                    transition: getInitTransition(EaseEnum.easeInOut)
                }
            }
        }
        // case 'text': {
        //     return {
        //         // type: 'text'
        //     }
        // }

        case 'loop': {
            return {
                type: 'loop',
                effect: {
                    repeatType: 'loop',
                    opacity: 1,
                    scale: 1,
                    rotateType: '2d',
                    rotate: 360,
                    rotate3d: {
                        x: 0,
                        y: 0,
                        z: 0
                    },
                    skew: [0, 0],
                    offset: [0, 0],
                    transition: {
                        ...getInitTransition(EaseEnum.linear),
                        time: 5
                    }
                }
            }
        }
        default: {
            return {
                type: 'layerInView',
                start: 'center',
                preset: PresetEnum.fadeIn,
                replay: true,
                ...getInitLayerInViewAnimationConfig(PresetEnum.fadeIn)
            }
        }
    }
}

export const getDefaultAnimation = function (): AnimationControlSetting {
    return getInitLayerInViewAnimationConfig(PresetEnum.fadeIn).enter ?? {
        opacity: 1,
        scale: 1,
        rotateType: '2d',
        rotate: 0,
        rotate3d: {
            x: 0,
            y: 0,
            z: 0
        },
        skew: [0, 0],
        offset: [0, 50],
        transition: getInitTransition('spring')
    }
}

export const animationNameMap: Record<AnimationTypeEnum, string> = {
    [AnimationTypeEnum.hover]: '悬停效果',
    [AnimationTypeEnum.layerInView]: '出现效果',
    [AnimationTypeEnum.loop]: '循环效果',
    [AnimationTypeEnum.text]: '文本特效'
}

export function getAnimationSettingRule(type: AnimationTypeEnum) {
    switch (type) {
        case AnimationTypeEnum.layerInView: {
            return {
                type: false,
                opacity: true,
                scale: true,
                rotate: true,
                rotate3d: true,
                rotateType: true,
                skew: true,
                offset: true,
                background: false,
                shadow: false
            }
        }
        case AnimationTypeEnum.hover: {
            return {
                type: false,
                opacity: true,
                scale: true,
                rotate: true,
                rotate3d: true,
                rotateType: true,
                skew: true,
                offset: true,
                background: true,
                shadow: true
            }
        }
        case AnimationTypeEnum.loop: {
            return {
                type: true,
                opacity: true,
                scale: true,
                rotate: true,
                rotate3d: true,
                rotateType: true,
                skew: true,
                offset: true,
                background: false,
                shadow: false
            }
        }
        default: {
            return {
                //
            }
        }
    }
}
