import type { DataSourceAbstract, ViewFieldProps } from '@lighthouse/core'
import React, { useCallback, useState } from 'react'

import { useLanguageContext } from '../../contexts'
import type { AppendParams } from '../../types'
import { useAppThemeContext } from '../ApplicationContainer'
import { ImportModal } from '../DataSourceImporter'
import SettingButton from './SettingButton'
import * as SC from './styles'

export interface ImportProps {
    appId: string
    envId: string
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    userImportSetting?: string[]
    isMobile?: boolean
    viewColumns?: ViewFieldProps[]
    onAppend?: (params: AppendParams) => Promise<boolean>
    // noSettingFields?: Set<FieldType>
    // onChangeImporter?: (data?: Importer[]) => void
}

export const Import: React.FC<ImportProps> = ({
    appId,
    envId,
    dataSource,
    dataSourceList,
    viewColumns,
    userImportSetting,
    isMobile,
    onAppend
}) => {
    const { convertTextByLanguage } = useLanguageContext()
    const [open, setOpen] = useState(false)
    const theme = useAppThemeContext()

    const handleImportOpen = useCallback(() => {
        setOpen(true)
    }, [])

    const handleImportClose = useCallback(() => {
        setOpen(false)
    }, [])

    return (
        <>
            <SC.SettingBox mainColor={theme.mainColor} tintMainColor={theme.tintMainColor} onClick={handleImportOpen}>
                <SettingButton color={theme.mainColor} icon="UploadSimple" size={isMobile ? 20 : 16} />
                {!isMobile && <SC.SettingText>{convertTextByLanguage('batchImport')}</SC.SettingText>}
            </SC.SettingBox>
            <ImportModal
                viewColumns={viewColumns}
                isShowRepeatConfig={false}
                appId={appId}
                envId={envId}
                dataSource={dataSource}
                dataSourceList={dataSourceList}
                disableSelectDataSource
                defaultImportType="append"
                open={open}
                userImportSetting={userImportSetting}
                onAppend={onAppend}
                onClose={handleImportClose}
            />
        </>
    )
}
