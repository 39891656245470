import { Flex, Input, Select } from '@byecode/ui'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { CardSelect } from './CardSelect'
import { CheckSelect } from './CheckSelect'
import { ChipSelect } from './ChipSelect'
import type { Field } from './types'

interface FormItemRenderProps {
    name: string
    field: Field
}

const SCxTitle = styled(Flex)`
    margin-bottom: 16px;
`

const SCxLabel = styled.span`
    color: var(--color-gray-900);
    font-size: 20px;
`

const SCxWrapper = styled.div<{ top?: number }>`
    margin-top: ${({ top }) => top && `${top}px`};
`

export const FormItemRender: React.FC<FormItemRenderProps> = ({ name, field }) => {
    const form = useFormContext()
    const isOtherField = name.includes('other')
    const depField = isOtherField ? name.slice(5).toLowerCase() : ''
    const depFieldValue = form.watch(depField)
    const { label, config } = field

    return useMemo(() => {
        if (isOtherField && !(depFieldValue === '其他' || depFieldValue?.includes('其他'))) {
            return null
        }

        const { type, required } = config

        switch (type) {
            case 'check': {
                const { top } = config
                return (
                    <SCxWrapper top={top}>
                        <Controller
                            name={name}
                            rules={{ required }}
                            render={({ field: { value, onChange } }) => <CheckSelect value={value} onChange={onChange} />}
                        />
                    </SCxWrapper>
                )
            }
            case 'text': {
                const { placeholder, top, size = 'lg', labelConfig } = config
                const { size: labelSize = 20, color, justifyContent, fontWeight = 500  } = labelConfig || {}
                return (
                    <SCxWrapper top={top}>
                        {label && (
                            <SCxTitle justifyContent={justifyContent}>
                                <SCxLabel style={{ fontWeight, fontSize: `${labelSize}px`, color }}>{label}</SCxLabel>
                            </SCxTitle>
                        )}
                        <Controller
                            name={name}
                            rules={{ required }}
                            render={({ field: { value, onChange } }) => (
                                <Input
                                    placeholder={placeholder || '请输入'}
                                    type={type}
                                    radius="md"
                                    value={value}
                                    onChange={onChange}
                                    size={size}
                                    required={required}
                                />
                            )}
                        />
                    </SCxWrapper>
                )
            }
            case 'select': {
                const { required, multiple, options, top } = config
                return (
                    <SCxWrapper top={top}>
                        {label && (
                            <SCxTitle>
                                <SCxLabel>{label}</SCxLabel>
                            </SCxTitle>
                        )}
                        <Controller
                            name={name}
                            rules={{ required }}
                            render={({ field: { value, onChange } }) => (
                                <Select size="lg" placeholder="请选择" options={options ?? []} value={value} onChange={onChange} />
                            )}
                        />
                    </SCxWrapper>
                )
            }
            case 'chip': {
                const { options = [], size, top, justifyContent, label: title } = config
                return (
                    <SCxWrapper top={top}>
                        {label && (
                            <SCxTitle>
                                <SCxLabel>{label}</SCxLabel>
                            </SCxTitle>
                        )}
                        <Controller
                            name={name}
                            rules={{ required: required && `请选择${label}` }}
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <ChipSelect
                                        label={title}
                                        name={name}
                                        options={options}
                                        justifyContent={justifyContent}
                                        size={size}
                                        value={value}
                                        onChange={onChange}
                                    />
                                )
                            }}
                        />
                    </SCxWrapper>
                )
            }
            case 'card': {
                const { options = [], top } = config
                return (
                    <SCxWrapper top={top}>
                        <Controller
                            name={name}
                            rules={{ required: required && `请选择${label}` }}
                            render={({ field: { value, onChange } }) => {
                                return <CardSelect options={options} value={value} onChange={onChange} />
                            }}
                        />
                    </SCxWrapper>
                )
            }
            default: {
                return null
            }
        }
    }, [isOtherField, depFieldValue, config, name, label])
}
