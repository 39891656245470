import { Loading } from '@byecode/ui'
import React, { useMemo } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useAsyncRetry } from 'react-use'
import styled from 'styled-components'

import { getBrowse } from '@/services'

interface WorkSpaceProps {}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const WorkSpace: React.FC<WorkSpaceProps> = () => {
    const { applicationId: pathApplicationId } = useParams()

    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    // const spaceList = useWorkSpaceList()

    const { loading, value } = useAsyncRetry(async () => {
        const { appId: browserAppId } = await getBrowse()
        if (pathApplicationId) {
            navigate(`/${pathApplicationId}`, { replace: true })
            return { appId: pathApplicationId }
        }
        if (browserAppId) {
            navigate(`/${browserAppId}`, { replace: true })
            return { appId: browserAppId }
        }
        navigate({ pathname: '/workbench', search: searchParams.toString() }, { replace: true })
        return { appId: undefined }
    }, [])
    return (
        <SCxContainer>
            {useMemo(() => {
                if (loading || !value) {
                    return <Loading />
                }

                return null
            }, [loading, value])}
        </SCxContainer>
    )
}
