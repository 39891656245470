import { Divider } from '@byecode/ui'
import { useAtomValue } from 'jotai'
import React, { useState } from 'react'

import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { AutoLayoutSetting } from '@/components/DesignSetting/AutoLayoutSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { StyleSetting } from '@/components/DesignSetting/StyleSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'

import { dataSourceAtom } from '../atoms'
import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'
import { DataSetting } from './Basic/DataSetting'
import { DefaultDataSource } from './Basic/DataSource'
import { StyleSwitcher } from './Basic/StyleSwitcher'
import { View } from './Basic/View'
import { Operate } from './Operate'

interface CustomViewSettingProps {
    id: string
}

export const CustomViewSetting: React.FC<CustomViewSettingProps> = ({ id }) => {
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const dataSource = useAtomValue(dataSourceAtom)
    const designProps = getBlockAndPageDesignLimit('customView')
    return (
        <>
            <BlockConfigureSegmentedControl isInterAction value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <StyleSwitcher />
                    <Divider />
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <View />
                    <Divider />
                    <AutoLayoutSetting label="卡片布局" {...designProps.layout} />
                    <Divider />
                    <StyleSetting label="卡片样式" {...designProps.design} customViewDsId={dataSource?.id} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <DefaultDataSource />
                    {dataSource && (
                        <>
                            <Divider />
                            <DataSetting dataSource={dataSource} />
                            <Divider />
                            <VisibilitySetting currentDsId={dataSource?.id} />
                        </>
                    )}
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && dataSource && (
                <>
                    <Operate id={id} dataSource={dataSource} />
                    <Divider />
                    <AnimationSetting />
                </>
            )}
        </>
    )
}
