import { Tooltip } from '@byecode/ui'
import { ApplicationPreviewEnum, useAtomAction, useAtomData } from '@lighthouse/shared'
import * as React from 'react'
import { useCallback } from 'react'
import styled from 'styled-components'

import { setPreviewTypeAtom } from '@/atoms/application/action'
import { pageStackOfFloatBlockAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom, pageStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { usePreviewType } from '@/hooks/useApplication'

import * as SC from './styles'

interface PreviewIconProps {
    hoverColor?: string
}

const SCxPreviewContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 30%;
`

const SCxWrapper = styled.div`
    display: flex;
    align-items: center;
    border-radius: 6px;
    overflow: hidden;
    background-color: var(--color-gray-100);
    padding: 2px;
    gap: 2px;
`

const SCxIconWrapper = styled.div<{ hoverColor?: string }>`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 28px;
    width: 28px;
    cursor: pointer;
    border-radius: 5px;

    &.active {
        background-color: var(--color-white);
    }
    &:hover {
        background-color: ${({ hoverColor }) => hoverColor};
    }
`

const SCxBox = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 8px;
    box-shadow: 0px 2px 8px 0px #00000026;
`

const SCxTitle = styled.div`
    color: var(--color-white);
    font-size: var(--font-size-sm);
`

const SCxDescription = styled.div`
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
`

interface BreakPointDescriptionProps {
    title: string
    description: string
}

const BreakPointDescription: React.FC<BreakPointDescriptionProps> = ({ title, description }) => {
    return (
        <SCxBox>
            <SCxTitle>{title}</SCxTitle>
            <SCxDescription>{description}</SCxDescription>
        </SCxBox>
    )
}

export const PreviewIcon: React.FunctionComponent<PreviewIconProps> = ({ hoverColor }) => {
    const previewType = usePreviewType()
    const { run: setAppServerlessState } = useAtomAction(setPreviewTypeAtom)
    const { run: setPageStackOfFloatBlock } = useAtomAction(pageStackOfFloatBlockAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)
    const { rootPageId, stackId } = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => ({ rootPageId: s?.rootPageId ?? '', stackId: s?.stackId ?? '' }), [])
    )
    const background = previewType === ApplicationPreviewEnum.desktop ? 'var(--color-gray-100)' : 'var(--color-gray-200)'
    return (
        <SCxPreviewContainer>
            <SCxWrapper style={{ background }}>
                <Tooltip
                    width={172}
                    styles={{ tooltip: { padding: '0px!important' } }}
                    placement="bottom"
                    title={
                        <BreakPointDescription title="电脑端: ✱主断点" description="电脑端设计会自动同步到其他端，除非在其他端更改了设计" />
                    }
                >
                    <SCxIconWrapper
                        className={previewType === ApplicationPreviewEnum.desktop ? 'active' : undefined}
                        onClick={() => {
                            setAppServerlessState(ApplicationPreviewEnum.desktop)
                            // setPageStackOfFloatBlock([])
                            // setPageStack(draft => {
                            //     const stack = equalPageStack({ rootPageId, stackId })(draft)
                            //     if (stack) {
                            //         stack.state.selectedNodes = []
                            //         stack.state.asideType = AsideType.PAGE
                            //     }
                            // })
                        }}
                        hoverColor={hoverColor}
                    >
                        <SC.Icon
                            fill={previewType === ApplicationPreviewEnum.desktop ? 'var(--color-black)' : 'var(--color-gray-500)'}
                            type="Desktop"
                            size={18}
                        />
                    </SCxIconWrapper>
                </Tooltip>
                <Tooltip
                    styles={{ tooltip: { padding: '0px!important' } }}
                    placement="bottom"
                    title={<BreakPointDescription title="手机端" description="查看设计在手机端的呈现效果" />}
                >
                    <SCxIconWrapper
                        className={previewType === ApplicationPreviewEnum.mobile ? 'active' : undefined}
                        onClick={() => {
                            setAppServerlessState(ApplicationPreviewEnum.mobile)
                            // setPageStackOfFloatBlock([])
                            // setPageStack(draft => {
                            //     const stack = equalPageStack({ rootPageId, stackId })(draft)
                            //     if (stack) {
                            //         stack.state.selectedNodes = []
                            //         stack.state.asideType = AsideType.PAGE
                            //     }
                            // })
                        }}
                        hoverColor={hoverColor}
                    >
                        <SC.Icon
                            fill={previewType === ApplicationPreviewEnum.mobile ? 'var(--color-black)' : 'var(--color-gray-500)'}
                            type="DeviceMobile"
                            size={18}
                        />
                    </SCxIconWrapper>
                </Tooltip>
            </SCxWrapper>
        </SCxPreviewContainer>
    )
}
