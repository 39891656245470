import { pointer } from '@byecode/ui'
import React, { useEffect, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

export interface DragState {
    clientRect: {
        x: number
        y: number
    }
    delta: {
        x: number
        y: number
    }
    isDragging: boolean
}

interface GuideLineProps {
    position: 'left' | 'right'
    onDragStart?: (state: DragState) => void
    onDragEnd?: (state: DragState) => void
    onDragMove?: (state: DragState) => void
    style?: React.CSSProperties
}

const activeLine = css`
    background-color: var(--color-main);
`

export const Line = styled.div<{ isDragging: boolean }>`
    width: 7px;
    height: 64px;
    max-height: 100%;
    background-color: var(--color-gray-300);
    z-index: 100;
    border-radius: 6px;
    display: flex;
    flex-shrink: 1;
    ${({ isDragging }) => isDragging && activeLine}

    &:hover {
        ${activeLine}
    }

    ::after {
        height: 64px;
        top: 0;
        bottom: 0;
        width: 7px;
        height: 100%;
        display: flex;
        align-items: center;
        z-index: 100;
        background-color: inherit;
    }
`

export const Contain = styled.div`
    position: absolute;
    height: 100%;
    top: 0;
    bottom: 0;
    width: 7px;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    z-index: 10;
    ${pointer}
`

const GuideLine: React.FunctionComponent<GuideLineProps> = ({ position, onDragEnd, onDragMove, onDragStart, style }) => {
    const dragHandleRef = useRef<HTMLDivElement | null>(null)
    const styles = position === 'left' ? { left: 8 } : { right: 8 }

    const [dragState, setDragState] = useState<DragState>({
        clientRect: {
            x: 0,
            y: 0
        },
        delta: {
            x: 0,
            y: 0
        },
        isDragging: false
    })

    useEffect(() => {
        if (!dragState.isDragging) {
            return
        }
        function mouseUpHandel(e: MouseEvent) {
            setDragState(state => ({ ...state, isDragging: false }))
            onDragEnd?.(dragState)
        }
        document.addEventListener('mouseup', mouseUpHandel)
        return () => {
            document.removeEventListener('mouseup', mouseUpHandel)
        }
    }, [dragState, onDragEnd])

    useEffect(() => {
        const dragHandle = dragHandleRef.current
        if (!dragState.isDragging || !dragHandle) {
            return
        }
        function moveHandle(e: MouseEvent) {
            const moveX = position === 'right' ? e.clientX - dragState.clientRect.x : dragState.clientRect.x - e.clientX
            const moveY = e.clientY - dragState.clientRect.y
            requestAnimationFrame(() => {
                onDragMove?.({
                    clientRect: {
                        x: dragHandle?.getBoundingClientRect().x ?? 0,
                        y: dragHandle?.getBoundingClientRect().y ?? 0
                    },
                    delta: {
                        x: moveX,
                        y: moveY
                    },
                    isDragging: dragState.isDragging
                })
            })
        }
        document.addEventListener('mousemove', moveHandle)
        return () => {
            document.removeEventListener('mousemove', moveHandle)
        }
    }, [dragState, dragState.delta.x, dragState.delta.y, onDragMove, onDragStart, position])

    useEffect(() => {
        const dragHandle = dragHandleRef.current
        if (!dragHandle) {
            return
        }
        function mouseDownHandel(e: MouseEvent) {
            const newDragState = {
                clientRect: {
                    x: dragHandle?.getBoundingClientRect().x ?? 0,
                    y: dragHandle?.getBoundingClientRect().y ?? 0
                },
                delta: {
                    x: 0,
                    y: 0
                },
                isDragging: true
            }
            setDragState(newDragState)
            onDragStart?.(newDragState)
        }
        dragHandle.addEventListener('mousedown', mouseDownHandel)

        return () => {
            dragHandle.removeEventListener('mousedown', mouseDownHandel)
        }
    }, [onDragStart])

    return (
        <Contain style={{ ...styles, ...style }} ref={dragHandleRef}>
            <Line draggable={false} isDragging={dragState.isDragging} />
        </Contain>
    )
}

export default GuideLine
