import type { BaseBreakPointProtocol } from '@lighthouse/core';
import { BaseBreakPointConfigProtocol } from '@lighthouse/core'
import type { RefObject } from 'react'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useFormContext, useWatch } from 'react-hook-form'
import { useLatest } from 'react-use'
import { throttle } from 'throttle-debounce'

export interface UseMouseEventDistanceOptions<E extends HTMLElement> {
    ref: RefObject<E>
    disabled?: boolean
}

type Effect = {
    (diff: number): void
}

/** 根据鼠标计算移动距离 */
export const useMouseEventDistance = <E extends HTMLElement>(effect: Effect, options: UseMouseEventDistanceOptions<E>) => {
    const { ref, disabled } = options

    const latestEffectRef = useLatest(effect)

    const initialCoordinateRef = useRef(0)

    const [downStatus, setDownStatus] = useState(false)

    useEffect(() => {
        const el = ref.current
        if (!el || disabled) {
            return
        }

        function start(e: MouseEvent) {
            initialCoordinateRef.current = e.x
            setDownStatus(true)
        }

        el.addEventListener('mousedown', start)

        return () => {
            el.removeEventListener('mousedown', start)
        }
    }, [disabled, ref])

    useEffect(() => {
        if (!downStatus || disabled) {
            return
        }

        const throttleMove = throttle(50, (initial: number, current: number) => {
            const diff = current - initial
            initialCoordinateRef.current = current
            latestEffectRef.current(diff)
        })

        function move(e: MouseEvent) {
            window.getSelection()?.removeAllRanges()
            throttleMove(initialCoordinateRef.current, e.x)
        }

        function end() {
            setDownStatus(false)
            initialCoordinateRef.current = 0
        }

        document.addEventListener('mousemove', move)
        document.addEventListener('mouseup', end)

        return () => {
            document.removeEventListener('mousemove', move)
            document.removeEventListener('mouseup', end)
        }
    }, [disabled, downStatus, latestEffectRef])
}

export const useSetExtendsKeys = () => {
    const { control, setValue, getValues } = useFormContext<BaseBreakPointProtocol>()
    const breakKeys = useWatch({ name: 'breakPoint.breakKeys', control })

    const handleSetBreakKey = useCallback((name: string) => {
        // const breakKeys = getValues('breakPoint.breakKeys')
        const keyPaths = new Set(breakKeys || [])
        const list = name.split('.')
        const key = list.includes('breakPoint') ? list.slice(1).join('.') : name
        if (!key) {
            return
        }
        keyPaths.add(key)
        setValue('breakPoint.breakKeys', [...keyPaths])
    }, [breakKeys, setValue])

    const handleRemoveBreakKey = useCallback((name: string) => {
        const breakKeys = getValues('breakPoint.breakKeys')
        const keyPaths = new Set(breakKeys || [])
        const list = name.split('.')
        const key = list.includes('breakPoint') ? list.slice(1).join('.') : name
        if (!key) {
            return
        }
        keyPaths.delete(key)
        setValue('breakPoint.breakKeys', [...keyPaths])
    }, [getValues, setValue])


    const handleClearBreakKey = useCallback(() => {
        setValue('breakPoint.breakKeys', [])
    }, [setValue])

    return {
        handleSetBreakKey, handleRemoveBreakKey, handleClearBreakKey, breakKeys
    }

}
