import { ByecodeUIThemeProvider, IconFont, Tooltip } from '@byecode/ui'
import { autoUpdate, offset, shift, useFloating } from '@floating-ui/react'
import { getBlockName } from '@lighthouse/block'
import type { BlockAbstract, ContainerBlockAbstract, FloatBlockAbstract } from '@lighthouse/core'
import { BlockType, DIRECTION } from '@lighthouse/core'
import {
    findNodeDom,
    findNormalOrSyncBlock,
    findParentBlockById,
    getCurrentBlockChildren,
    hasChildrenBlock,
    isContainerBlock,
    isFloatBoxBlock,
    PAGE_CONTAINER_HOST_ID,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import { findParentByClassName, findParentById, mergeRefs } from '@lighthouse/tools'
import produce, { current, original } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import React, { startTransition, useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { createPortal, flushSync } from 'react-dom'
import { useHotkeys } from 'react-hotkeys-hook'
import styled from 'styled-components'

import { dataDrawerStateAtom, syncComponentsAtom } from '@/atoms/application/state'
import { removeBlockAtom } from '@/atoms/page/action'
import { blocksAtom, pageAtomFamily, pageBlocksAtom, pageStackAtomFamily } from '@/atoms/page/state'
import type { NodeIdWithScope } from '@/atoms/page/types'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useNodeToolbarActions } from '@/hooks/layoutEngine/useNodeToolbarActions'
import * as srv from '@/services'
import { NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { CreateSyncModal } from './Sync/CreateSyncModal'
import { useSyncHooks } from './Sync/useSyncHooks'

function getBoundaryByClass(currentElement: null | HTMLElement, className: string) {
    // 如果在弹窗中
    const boundary = findParentByClassName(currentElement, 'byecode-Modal-modal')
    if (boundary) {
        return boundary
    }

    return findParentByClassName(currentElement, className) || document.body
}
function getBoundaryById(currentElement: null | HTMLElement, id: string) {
    // 如果在弹窗中
    const boundary = findParentByClassName(currentElement, 'byecode-Modal-modal')
    if (boundary) {
        return boundary
    }

    return findParentById(currentElement, id)
}

const Container = styled.div({
    padding: '0.25rem',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
    height: '28px',
    borderRadius: '4px',
    color: '#fff',
    background: 'var(--color-purple-900)',
    boxShadow: '0px 4px 12px 0px rgba(16, 24, 40, 0.10)',
    cursor: 'auto',
    zIndex: '200'
})

const Sep = styled.div({
    alignSelf: 'stretch',
    width: 1,
    margin: '6px 2px',
    background: 'rgba(255,255,255, 0.3)'
})

const IconButton = styled.div({
    touchAction: 'none',
    userSelect: 'none',
    padding: 2,
    display: 'flex',
    gap: '2px',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    color: '#fff',
    '&[data-enable-hover]': {
        color: '#E4E7EC'
    },
    '&[data-enable-hover]:hover': {
        borderRadius: '5px',
        color: '#fff',
        background: 'rgba(255, 255, 255, 0.25)'
    }
})

const Label = styled.span({
    marginLeft: 2,
    fontSize: 12,
    lineHeight: '20px'
})

interface BlockToolbarProps extends React.ComponentPropsWithoutRef<'div'> {
    selectedNode: NodeIdWithScope
    onChangeSelectedId: (ids: NodeIdWithScope[]) => void
    onDragBar?: (e: React.MouseEvent) => void
}

export const BlockToolbar = React.forwardRef<HTMLDivElement, BlockToolbarProps>((props, ref) => {
    const { selectedNode, onChangeSelectedId, onDragBar, ...rest } = props

    const stackId = useCurrentStackIdContext()
    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()

    const pageBlocks = useAtomData(
        blocksAtom,
        useCallback(s => s[pageId] || [], [pageId])
    )
    const syncComponents = useAtomData(syncComponentsAtom)

    const block = useMemo(() => findNormalOrSyncBlock(selectedNode, pageBlocks, syncComponents), [pageBlocks, selectedNode, syncComponents])

    const [reference, setReference] = useState(findNodeDom(selectedNode))
    const isNotFloatBox = block?.type !== BlockType.floatBox

    useLayoutEffect(() => {
        let reference = findNodeDom(selectedNode)

        startTransition(() => {
            setReference(reference)
        })

        const observer = new MutationObserver(list => {
            list.forEach(mutation => {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(addedNode => {
                        if (addedNode instanceof HTMLElement) {
                            const newReference = findNodeDom(selectedNode)
                            if (newReference && addedNode.contains(newReference)) {
                                reference = newReference
                                startTransition(() => {
                                    setReference(newReference)
                                })
                            }
                        }
                    })

                    mutation.removedNodes.forEach(removedNode => {
                        if (removedNode instanceof HTMLElement && removedNode.contains(reference)) {
                            flushSync(() => {
                                setReference(null)
                            })
                        }
                    })
                }
            })
        })

        const target = document.querySelector(`#${PAGE_CONTAINER_HOST_ID}`)
        if (!target) {
            return
        }
        observer.observe(target, { childList: true, subtree: true })

        return () => {
            observer.disconnect()
            setReference(null)
        }
    }, [selectedNode])

    const actions = useNodeToolbarActions({ stackId, rootPageId, pageId })
    const { run: onVisibleDataDrawer } = useAtomAction(dataDrawerStateAtom)

    const { floatingStyles, refs } = useFloating<HTMLDivElement>({
        strategy: 'fixed',
        placement: 'top-start',
        elements: { reference },
        middleware: [
            offset(4),
            shift({
                boundary: getBoundaryById(reference, PAGE_CONTAINER_HOST_ID) || undefined,
                crossAxis: true
            })
        ],
        whileElementsMounted: autoUpdate
    })

    const onSelectParent = useAtomCallback((get, set) => {
        const blocks = get(blocksAtom)[pageId] || []

        const actualBlock = findNormalOrSyncBlock(selectedNode, blocks, syncComponents)
        const parent =
            actualBlock &&
            (actualBlock.isLeafSynchronous
                ? findParentBlockById(actualBlock.id, syncComponents)
                : findParentBlockById(selectedNode.id, blocks))

        onChangeSelectedId?.(
            parent
                ? [
                      {
                          scope: parent.isMasterSynchronous ? undefined : selectedNode.scope,
                          id: parent.isMasterSynchronous ? selectedNode.scope || '' : parent.id
                      }
                  ]
                : []
        )
    })

    const onRemoveContainer = useAtomCallback((get, set) => {
        const blocks = get(blocksAtom)[pageId] || []

        const actualBlock = findNormalOrSyncBlock(selectedNode, blocks, syncComponents)
        if (!actualBlock) {
            return
        }

        if (actualBlock.isMasterSynchronous || !isContainerBlock(actualBlock)) {
            return
        }

        if (actualBlock.isLeafSynchronous) {
            const removeUndoRedoInfo: {
                removedBlocks?: BlockAbstract[]
                prevSyncComponents?: ContainerBlockAbstract[]
                nextSyncComponents?: ContainerBlockAbstract[]
            } = {}
            set(syncComponentsAtom, draft => {
                const draftParentBlock = findParentBlockById(selectedNode.id, draft)
                if (!draftParentBlock) {
                    return
                }

                const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState

                const parentChildren = getCurrentBlockChildren(draftParentBlock, {
                    blockRuntimeState,
                    uniqueContainerId: draftParentBlock.isMasterSynchronous
                        ? selectedNode.scope
                        : `${selectedNode.scope}@${draftParentBlock.id}`
                })

                if (!parentChildren) {
                    return
                }

                const index = parentChildren.findIndex(item => item.id === selectedNode.id)
                if (index === -1) {
                    return
                }

                const draftBlock = parentChildren[index] as ContainerBlockAbstract

                // 删除容器本身、其他视图、以及当前视图下的浮层block
                const currentBlock = current(draftBlock)
                const removedSelfBlock = produce(currentBlock, d => void (d.children = []))
                let removedFloatBlocksOfCurrentView: FloatBlockAbstract[] = []
                const currentView = currentBlock.children.find(
                    item => item.id === blockRuntimeState?.container?.[`${selectedNode.scope}@${currentBlock.id}`].currentView
                )
                if (currentView) {
                    removedFloatBlocksOfCurrentView = currentView.children.filter(isFloatBoxBlock)
                }
                const willRemoveViews = currentBlock.children.filter(
                    view => view.id !== blockRuntimeState?.container?.[`${selectedNode.scope}@${currentBlock.id}`].currentView
                )

                const removedBlocksOfOtherViews = willRemoveViews.reduce<BlockAbstract[]>((total, curr) => [...total, ...curr.children], [])
                removeUndoRedoInfo.removedBlocks = [removedSelfBlock, ...removedFloatBlocksOfCurrentView, ...removedBlocksOfOtherViews]

                const draftBlockChildren = getCurrentBlockChildren(draftBlock, {
                    blockRuntimeState,
                    uniqueContainerId: `${selectedNode.scope}@${draftBlock.id}`
                })?.filter(item => !isFloatBoxBlock(item))

                parentChildren.splice(index, 1, ...(draftBlockChildren || []))

                removeUndoRedoInfo.prevSyncComponents = original(draft)
                removeUndoRedoInfo.nextSyncComponents = current(draft)
            })

            if (!removeUndoRedoInfo.removedBlocks || !removeUndoRedoInfo.nextSyncComponents) {
                return
            }

            pageUndoRedoController.add({
                action: NODE_UPDATE_ACTION,
                payload: {
                    removed: {
                        removedBlocks: removeUndoRedoInfo.removedBlocks,
                        prevSyncComponents: removeUndoRedoInfo.prevSyncComponents,
                        nextSyncComponents: removeUndoRedoInfo.nextSyncComponents
                    }
                }
            })
            srv.updateSyncComponents(removeUndoRedoInfo.nextSyncComponents)
        } else {
            const removeUndoRedoInfo: {
                removedBlocks?: BlockAbstract[]
                prevBlocks?: BlockAbstract[]
                nextBlocks?: BlockAbstract[]
            } = {}

            set(blocksAtom, draft => {
                const draftBlocks = draft[pageId]
                if (!draftBlocks) {
                    return
                }

                const draftParentBlock = findParentBlockById(selectedNode.id, draftBlocks)
                const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState

                const parentChildren = draftParentBlock
                    ? getCurrentBlockChildren(draftParentBlock, {
                          blockRuntimeState
                      })
                    : draftBlocks

                if (!parentChildren) {
                    return
                }

                const index = parentChildren.findIndex(item => item.id === selectedNode.id)
                if (index === -1) {
                    return
                }

                const draftBlock = parentChildren[index] as ContainerBlockAbstract

                // 删除容器本身、其他视图、以及当前视图下的浮层block
                const currentBlock = current(draftBlock)
                const removedSelfBlock = produce(currentBlock, d => void (d.children = []))
                let removedFloatBlocksOfCurrentView: FloatBlockAbstract[] = []
                const currentView = currentBlock.children.find(
                    item => item.id === blockRuntimeState?.container?.[currentBlock.id].currentView
                )
                if (currentView) {
                    removedFloatBlocksOfCurrentView = currentView.children.filter(isFloatBoxBlock)
                }
                const willRemoveViews = currentBlock.children.filter(
                    view => view.id !== blockRuntimeState?.container?.[currentBlock.id].currentView
                )
                const removedBlocksOfOtherViews = willRemoveViews.reduce<BlockAbstract[]>((total, curr) => [...total, ...curr.children], [])
                removeUndoRedoInfo.removedBlocks = [removedSelfBlock, ...removedFloatBlocksOfCurrentView, ...removedBlocksOfOtherViews]

                const draftBlockChildren = getCurrentBlockChildren(draftBlock, {
                    blockRuntimeState
                })?.filter(item => !isFloatBoxBlock(item))

                parentChildren.splice(index, 1, ...(draftBlockChildren || []))

                removeUndoRedoInfo.prevBlocks = original(draftBlocks)
                removeUndoRedoInfo.nextBlocks = current(draftBlocks)
            })

            if (!removeUndoRedoInfo.removedBlocks || !removeUndoRedoInfo.nextBlocks) {
                return
            }

            pageUndoRedoController.add({
                action: NODE_UPDATE_ACTION,
                payload: {
                    removed: {
                        removedBlocks: removeUndoRedoInfo.removedBlocks,
                        prevBlocks: removeUndoRedoInfo.prevBlocks,
                        nextBlocks: removeUndoRedoInfo.nextBlocks
                    }
                }
            })

            set(removeBlockAtom, {
                rootPageId,
                stackId,
                blocks: removeUndoRedoInfo.nextBlocks,
                removedBlocks: removeUndoRedoInfo.removedBlocks,
                pageId
            })
        }
    })

    useHotkeys('shift+enter', onSelectParent, [onSelectParent])

    const onSwapKeydown = useAtomCallback<void, Parameters<Parameters<typeof useHotkeys>[1]>>((get, set, _, hotkeysEvent) => {
        const blocks = get(pageBlocksAtom(pageId))
        const page = get(pageAtomFamily(pageId))
        const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState

        let direction = page?.breakPoint.layout?.align?.direction

        const actualBlock = findNormalOrSyncBlock(selectedNode, blocks, syncComponents)
        const parent =
            actualBlock &&
            (actualBlock.isLeafSynchronous
                ? findParentBlockById(actualBlock.id, syncComponents)
                : findParentBlockById(selectedNode.id, blocks))

        if (parent && hasChildrenBlock(parent)) {
            direction = parent.config.breakPoint.layout?.align?.direction
        }

        const children = parent
            ? getCurrentBlockChildren(parent, {
                  blockRuntimeState,
                  uniqueContainerId: parent.isMasterSynchronous
                      ? selectedNode.scope
                      : parent.isLeafSynchronous
                      ? `${selectedNode.scope}@${parent.id}`
                      : undefined
              }) || []
            : blocks

        switch (hotkeysEvent.key) {
            case 'up': {
                if (direction === DIRECTION.horizontal) {
                    return
                }
                const currentIndex = children.findIndex(item => item.id === selectedNode.id)
                if (currentIndex === 0) {
                    return
                }
                const prev = children[currentIndex - 1].id
                actions.onSwap(selectedNode, { id: prev, scope: selectedNode.scope })
                break
            }
            case 'down': {
                if (direction === DIRECTION.horizontal) {
                    return
                }
                const currentIndex = children.findIndex(item => item.id === selectedNode.id)
                if (currentIndex === children.length - 1) {
                    return
                }
                const next = children[currentIndex + 1].id
                actions.onSwap(selectedNode, { id: next, scope: selectedNode.scope })
                break
            }
            case 'left': {
                if (!direction || direction === DIRECTION.vertical) {
                    return
                }
                const currentIndex = children.findIndex(item => item.id === selectedNode.id)
                if (currentIndex === 0) {
                    return
                }
                const prev = children[currentIndex - 1].id
                actions.onSwap(selectedNode, { id: prev, scope: selectedNode.scope })
                break
            }
            case 'right': {
                if (!direction || direction === DIRECTION.vertical) {
                    return
                }
                const currentIndex = children.findIndex(item => item.id === selectedNode.id)
                if (currentIndex === children.length - 1) {
                    return
                }
                const next = children[currentIndex + 1].id
                actions.onSwap(selectedNode, { id: next, scope: selectedNode.scope })
                break
            }

            default: {
                break
            }
        }
    })

    useHotkeys('up, down, left, right', onSwapKeydown, [onSwapKeydown])

    const { creatingSyncNodeId, setCreatingSyncNodeId, onCreateSyncNode, onSetSyncNode, onUnsetSyncNode } = useSyncHooks({
        rootPageId,
        pageId,
        stackId
    })

    const setRef = useMemo(() => mergeRefs([ref, refs.setFloating]), [ref, refs.setFloating])

    const defaultDom = (
        <ByecodeUIThemeProvider>
            <Container
                ref={setRef}
                style={{
                    ...floatingStyles,
                    ...(block?.isMasterSynchronous || block?.isLeafSynchronous
                        ? { backgroundColor: '#6160DC', boxShadow: '0px 4px 12px 0px #1018281A' }
                        : { backgroundColor: 'var(--color-purple-900)', boxShadow: '0px 4px 12px 0px #10182819' })
                }}
                onMouseDown={e => {
                    e.stopPropagation()
                    // e.preventDefault()
                }}
                {...rest}
            >
                {isNotFloatBox && (
                    <>
                        <Tooltip title="移动">
                            <IconButton onMouseDownCapture={onDragBar} style={{ cursor: 'grab' }}>
                                <IconFont type="DotsSix" />
                                <Label>{block ? block.title || getBlockName(block) : '未知节点'}</Label>
                            </IconButton>
                        </Tooltip>
                        <Sep />
                    </>
                )}

                <Tooltip title="选择上一级">
                    <IconButton data-enable-hover onClickCapture={onSelectParent}>
                        <IconFont type="SelectHigher" />
                    </IconButton>
                </Tooltip>

                {block?.type === BlockType.container && !block.isMasterSynchronous && (
                    <Tooltip title="取消容器">
                        <IconButton data-enable-hover onClickCapture={onRemoveContainer}>
                            <IconFont type="UnContainer" />
                        </IconButton>
                    </Tooltip>
                )}

                {isNotFloatBox && (
                    <>
                        <Tooltip title="组件绑定的数据">
                            <IconButton data-enable-hover onClickCapture={() => onVisibleDataDrawer(true)}>
                                <IconFont fill="var(--color-white)" type="SearchData2" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="创建副本">
                            <IconButton data-enable-hover onClickCapture={() => actions.onClone(selectedNode)}>
                                <IconFont type="Duplicate" />
                            </IconButton>
                        </Tooltip>
                    </>
                )}
                <Tooltip title="删除">
                    <IconButton
                        data-enable-hover
                        onClickCapture={() => {
                            actions.onRemove([selectedNode])
                        }}
                    >
                        <IconFont type="Trash" />
                    </IconButton>
                </Tooltip>

                {block && isNotFloatBox && !block.isLeafSynchronous && !block.isMasterSynchronous && (
                    <Tooltip title="设为同步组件">
                        <IconButton data-enable-hover onClick={() => onSetSyncNode(selectedNode.id)}>
                            <IconFont type="SyncComponent" />
                        </IconButton>
                    </Tooltip>
                )}

                {block && block.isMasterSynchronous && (
                    <Tooltip title="断开同步">
                        <IconButton data-enable-hover onClick={() => onUnsetSyncNode(selectedNode.id)}>
                            <IconFont type="UnSync" />
                        </IconButton>
                    </Tooltip>
                )}

                {creatingSyncNodeId && <CreateSyncModal onCancel={() => setCreatingSyncNodeId(null)} onCreate={onCreateSyncNode} />}
            </Container>
        </ByecodeUIThemeProvider>
    )

    const target = getBoundaryByClass(reference, 'applicationContainer')

    return createPortal(defaultDom, target)
})
