import type { IconBlockConfig } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'

export const generateIconBlock = (): IconBlockConfig => {
    const actionId = nanoid()
    return {
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            position: {
                type: 'relative'
            },
            size: {
                width: {
                    size: 24,
                    unit: 'px'
                },
                height: {
                    size: 'auto'
                }
            },
            icon: {
                type: 'icon',
                value: ''
            },
            design: {
                color: {
                    type: 'color',
                    color: '#98A2B3'
                }
            },
            layout: {}
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ],
        content: {},
        action: {
            id: actionId,
            type: 'none',
            trigger: 'click',
            data: {
                none: {}
            }
        }
    }
}
