import { useFloatingTree } from '@floating-ui/react'
import { FloatBlock } from '@lighthouse/block'
import type { FloatBlockAbstract } from '@lighthouse/core'
import type { FlowLayoutNode} from '@lighthouse/shared';
import { PAGE_SCROLL_PARENT_CONTENT, useFloatBoxContext, useFlowLayoutContext , usePageContainer } from '@lighthouse/shared'
import { mergeRefs, useMounted } from '@lighthouse/tools'
import React, { useRef } from 'react'
import { useClickAway, useUnmount } from 'react-use'
import styled from 'styled-components'

interface FloatBlockProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onSubmit'> {
    blockData: FloatBlockAbstract
    node: FlowLayoutNode
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
`

const FloatBlockController: React.FC<FloatBlockProps> = ({ blockData, node, ...rest }) => {
    const { context, floating, onClose } = useFloatBoxContext()
    const { rootElement } = useFlowLayoutContext()
    const tree = useFloatingTree()
    const awayRef = useRef<HTMLDivElement>(null)
    const { dragPlugin } = usePageContainer()

    useClickAway(awayRef, e => {
        const { target } = e
        const parentElement = rootElement
        const childElement = awayRef.current
        if (target instanceof Element) {
            const isInsideParent = parentElement && parentElement.contains(target)
            const isInsideChild = childElement && childElement.contains(target)
            const isInPopoverTarget = (context.refs.reference?.current as HTMLDivElement)?.contains(target)
            const isInPopoverTreeChild = tree?.nodesRef.current.some(v => (v.context?.refs.floating.current)?.contains(target) )
            // const isInChildFloat =
            if (isInsideParent && !isInsideChild && !isInPopoverTarget && !isInPopoverTreeChild) {
                // onClose()
            }
        }
    })
    useMounted(() => {
        dragPlugin?.collectNodes()
    })

    return (
        <SCxContainer ref={mergeRefs([floating, awayRef])}>
            <FloatBlock node={node} blockData={blockData} {...rest} />
        </SCxContainer>
    )
}

export default FloatBlockController
