import type { Option } from '@byecode/ui';
import type { EMAIL_EDIT_TYPE,FlowNode,VariableOptions } from '@lighthouse/shared';
import React, { createContext, useContext } from 'react';

export type SendEmailContextValue = {
    options: VariableOptions
    multiNodeOptions: Option[]
    editType?: EMAIL_EDIT_TYPE
}

export type SendEmailProviderProps = SendEmailContextValue & {
    children: React.ReactNode
}

export const SendEmailContext = createContext<SendEmailContextValue>({
    options: [],
    multiNodeOptions: []
})

export const SendEmailProvider: React.FC<SendEmailProviderProps> = ({ options, editType, multiNodeOptions, children }) => {
    const contextValue = React.useMemo(
        () => ({ options, editType, multiNodeOptions }),
        [multiNodeOptions, editType, options]
    )

    return <SendEmailContext.Provider value={contextValue}>{children}</SendEmailContext.Provider>
}

export const useSendEmailContext = () => useContext(SendEmailContext)

SendEmailContext.displayName = 'SendEmailContext'
