import type { ChartLikeProtocol, ChartOptionProtocol, PieChartOptionProtocol, SingleChartOptionProtocol } from '@lighthouse/core'

import type { ECOption } from '../../components'
import { defaultColors, legendOffset, legendWidth } from './common'

export const getLegend = (params: PieChartOptionProtocol, chartData: ChartLikeProtocol): ECOption['legend'] => {
    const { showLegend, legendTranspose, dimensions } = params
    const { legend, xAxis } = chartData
    const data = dimensions.length > 0 ? xAxis.map(item => item[0]).filter(Boolean) : legend
    const legendConfigurator: ECOption['legend'] = {
        type: 'scroll',
        show: showLegend,
        data,
        bottom: legendOffset,
        icon: 'circle'
    }
    switch (legendTranspose) {
        case 'top': {
            legendConfigurator.top = legendOffset
            break
        }
        case 'bottom': {
            legendConfigurator.bottom = legendOffset
            break
        }
        case 'left': {
            legendConfigurator.left = legendOffset
            legendConfigurator.top = 'center'
            legendConfigurator.width = legendWidth
            legendConfigurator.orient = 'vertical'
            break
        }
        case 'right': {
            legendConfigurator.right = legendOffset
            legendConfigurator.width = legendWidth
            legendConfigurator.top = 'center'
            legendConfigurator.orient = 'vertical'
            break
        }
        default: {
            break
        }
    }

    return legendConfigurator
}

const getSeries = (option: ChartOptionProtocol, chartData: ChartLikeProtocol): ECOption['series'] => {
    const { series, xAxis, legend } = chartData

    if (series.length === 0) {
        return {}
    }
    const [seriesName] = legend

    const getSeriesData = () => {
        if (series.length === 0) {
            return []
        }
        const [firstSeries] = series
        const { value: seriesValue } = firstSeries
        if (seriesValue.length > 1) {
            return xAxis.map((item, index) => {
                const value = Number(seriesValue?.[index] || 0)
                return { value, name: item[0] || '空' }
            })
        }
        if (seriesValue.length === 1) {
            // 解决当维度指标只有一个时，饼图图例显示指标名而不是指标数据名的问题
            if (option.chartType === 'pie') {
                return series.map(item => ({
                    value: Number(item.value?.[0] || 0),
                    name: xAxis[0]?.[0] || '空'
                }))
            }
            return series.map(item => ({
                value: Number(item.value?.[0] || 0),
                name: item.name
            }))
        }
        return series.map(item => ({
            value: Number(item.value?.[0] || 0),
            name: item.name
        }))
    }

    return {
        name: seriesName,
        type: 'pie',
        data: getSeriesData(),
        bottom: '20px',
        top: '20px',
        emphasis: {
            focus: 'self'
        },
        // label: { show: showLabel },
        blur: {
            itemStyle: {
                opacity: 0.6
            }
        }
    }
}

const getPieCommonOption = (params: SingleChartOptionProtocol) => {
    const { colors, showHeadTitle, title } = params
    const commonOption: Pick<ECOption, 'color' | 'title'> = {
        color: colors?.length > 0 ? colors : defaultColors,
        title: {
            text: showHeadTitle && title ? title : undefined,
            textStyle: {
                fontSize: 24,
                fontWeight: 500,
                color: '#101828'
            }
        }
    }
    return commonOption
}

const getPieOption = (params: PieChartOptionProtocol, chartData?: ChartLikeProtocol, themeColors?: string[]) => {
    if (!chartData) {
        return {}
    }
    const commonOption = getPieCommonOption(params)
    const mergedOption: ECOption = {
        tooltip: {
            show: true,
            formatter: params => {
                const param = Array.isArray(params) ? params[0] : params
                const { marker, name, value, percent, seriesName } = param
                return `<div style="margin-bottom: 12px; font-weight: bold">
                            ${name || '空'}
                        </div>
                        ${marker} ${seriesName}：${String(value)} (${percent}%)`
            }
        },
        ...commonOption,
        legend: getLegend(params, chartData),
        series: getSeries(params, chartData)
    }
    return mergedOption
}

export default getPieOption




// export const getLegendOption: Pick<ECOption, 'legend'> = (showLegend: boolean | undefined,showHeadTitle: boolean, canExport?: boolean) => {
//     if()
//     return {

//     }
// }
