import type { DataSourceAbstract } from '@lighthouse/core'
import { FilterWay } from '@lighthouse/core'
import type { FieldOption} from '@lighthouse/shared';
import { getRealField } from '@lighthouse/shared'


export const getFieldOptions = (fieldOptions: FieldOption[], dataSource: DataSourceAbstract | undefined, filterWay: FilterWay) => {
    return fieldOptions.filter(item => {
        const field = dataSource?.schema?.[item.value]
        if (!field) {
            return false
        }
        const realField = getRealField(field)
        switch (filterWay) {
            case FilterWay.textFilter: {
                return realField?.innerType === 'TEXT'
            }
            case FilterWay.numberFilter: {
                return realField?.innerType === 'NUMBER'
            }
            case FilterWay.dateFilter: {
                return realField?.innerType === 'DATE'
            }
            case FilterWay.personFilter: {
                return realField?.type === 'person'
            }
            case FilterWay.selectFilter: {
                return realField?.type === 'select'
            }
            default: {
                return realField?.innerType === 'TEXT'
            }
        }
    })
}
