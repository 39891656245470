import {
    Popover,
    Text
} from '@byecode/ui'
import type { Field } from '@lighthouse/core'
import {
    ActionItemPattern,
    BACKGROUND_TYPE
} from '@lighthouse/core'
import {
    btnTypeList,
    BUTTON_PATTERN_CONTROL,
    FilledInput,
    FillPickerPopoverDropdown,
    getIsFindUseInRichText,
    getPageDataOptions,
    getUserDatasourceOptions,
    ListItem4ByecodeUi,
    NodeFieldPreview,
    PURE_ALLOW_ICON_TYPES,
    useAtomData,
    useFindUseObjectContext
} from '@lighthouse/shared'
import { filterObjectUndefined } from '@lighthouse/tools'
import React, {
    useCallback,
    useMemo
} from 'react'
import {
    Controller,
    useFormContext
} from 'react-hook-form'
import styled from 'styled-components'

import { userDataSourceAtom } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import FormSegmentedControl from '../Common/FormSegmentedControl'
import { IconControl } from '../Common/IconControl'
import * as CM from '../styles'
import {
    ICON_POSITION_CONTROL
} from './constant'
import * as SC from './styles'

const SCxListItemBaseInfo = styled.div`
    gap: 0;
    padding: 0 16px;
`

interface ButtonConfigureProps {
    style?: React.CSSProperties
}

const filterField = (field: Field) => field.innerType === 'NUMBER' || field.innerType === 'TEXT'

export const ButtonConfigure: React.FunctionComponent<ButtonConfigureProps> = ({
    style
}) => {
    const { control, watch } = useFormContext()
    const { icon, showType, iconPosition, fillWay, backgroundColor, color, borderColor, pattern } =
        watch()

    const disabledWithVersion = useIsDisabledWithVersion()

    const findUseObject = useFindUseObjectContext()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const userDataSource = useAtomData(userDataSourceAtom)
    const {
        prev,
        curr: { variableSourceType, datasource }
    } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const options = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filterField
            },
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: filterField
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, prev.datasource, prev.variableSourceType, variableSourceType])

    const userOption = useMemo(() => {
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: filterField
        })
    }, [userDataSource])

    // const viewOption = useMemo(() => {
    //     if (isViewOption && pointerDataSource) {
    //         return getViewOptions({
    //             dataSource: pointerDataSource,
    //             viewType,
    //             validateFieldType: filterField
    //         })
    //     }
    //     return customViewOption
    // }, [customViewOption, isViewOption, pointerDataSource, viewType])

    const colorSystemMethods = useColorSystemAction()
    return (
        <SCxListItemBaseInfo style={style}>
            <ListItem4ByecodeUi
                alignItems="center"
                justifyContent="space-between"
                compact
                styles={{
                    root: {
                        gap: 0
                    }
                }}
            >
                <Text color="var(--color-gray-600)">标题</Text>
                <SC.RightFill>
                    <Controller
                        control={control}
                        name='name'
                        render={({ field }) => {
                            const isHighLight = getIsFindUseInRichText({
                                doc: field.value,
                                findUseObject,
                                currentDsId: datasource?.id
                            })
                            return (
                                <NodeFieldPreview
                                    disabled={disabledWithVersion}
                                    highlighting={isHighLight}
                                    style={{ width: 180 }}
                                    autoHeight
                                    placeholder="输入按钮名称"
                                    options={options}
                                    userOption={userOption}
                                    // viewOption={viewOption}
                                    value={field.value}
                                    onChange={field.onChange}
                                />
                            )
                        }}
                    />
                </SC.RightFill>
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi alignItems="center" compact justifyContent="space-between">
                <Text>按钮元素</Text>
                <SC.RightFill>
                    <Controller
                        name='showType'
                        control={control}
                        defaultValue={showType}
                        render={({ field }) => (
                            <CM.BlockSettingsSelect
                                // withinPortal
                                searchable={false}
                                // rightSection={<IconFont type="ArrowDownSmall" />}
                                name="pointer"
                                defaultValue="icon-name"
                                value={field.value}
                                options={btnTypeList}
                                style={{
                                    width: field.value === 'name' ? 180 : 140
                                }}
                                onChange={field.onChange}
                            />
                        )}
                    />
                    {showType !== 'name' && (
                        <Controller
                            name='icon'
                            control={control}
                            defaultValue={icon}
                            render={({ field }) => <IconControl allowIconTypes={PURE_ALLOW_ICON_TYPES} value={field.value} onChange={field.onChange} clearable={false} />}
                        />
                    )}
                </SC.RightFill>
            </ListItem4ByecodeUi>

            {showType !== 'name' && (
                <Controller
                    name='iconPosition'
                    control={control}
                    defaultValue={iconPosition}
                    render={({ field }) => (
                        <FormSegmentedControl
                            label="图标位置"
                            data={ICON_POSITION_CONTROL}
                            value={field.value}
                            compact
                            onChange={field.onChange}
                        />
                    )}
                />
            )}
            {/* {!hideConfigs?.fillWay && (
                <Controller
                    name='fillWay'
                    control={control}
                    defaultValue={fillWay}
                    render={({ field }) => (
                        <FormSegmentedControl
                            label="宽度规则"
                            data={FIT_TYPE_CONTROL}
                            value={field.value}
                            compact
                            onChange={field.onChange}
                        />
                    )}
                />
            )} */}
            <Controller
                name='pattern'
                control={control}
                defaultValue={pattern}
                render={({ field }) => (
                    <FormSegmentedControl
                        label="颜色样式"
                        compact
                        data={BUTTON_PATTERN_CONTROL}
                        value={field.value}
                        size="sm"
                        onChange={field.onChange}
                    />
                )}
            />

            {pattern === ActionItemPattern.custom && (
                <>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>背景色</Text>
                        <Controller
                            name='backgroundColor'
                            control={control}
                            defaultValue={backgroundColor}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput
                                            style={{ width: 180 }}
                                            value={field.value}
                                            onClear={() => field.onChange({ type: BACKGROUND_TYPE.color })}
                                        />
                                    </Popover.Target>
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>图标色</Text>
                        <Controller
                            name='iconColor'
                            control={control}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput
                                            style={{ width: 180 }}
                                            value={field.value}
                                            onClear={() => field.onChange({ type: BACKGROUND_TYPE.color })}
                                        />
                                    </Popover.Target>
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>文字色</Text>
                        <Controller
                            name='color'
                            control={control}
                            defaultValue={color}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput
                                            style={{ width: 180 }}
                                            value={field.value}
                                            onClear={() => field.onChange({ type: BACKGROUND_TYPE.color })}
                                        />
                                    </Popover.Target>
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color, BACKGROUND_TYPE.gradient]}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>描边色</Text>
                        <Controller
                            name='borderColor'
                            control={control}
                            defaultValue={borderColor}
                            render={({ field }) => (
                                <Popover width={300} withinPortal position="left-start" autoUpdate={false} offsetOptions={152}>
                                    <Popover.Target>
                                        <FilledInput
                                            style={{ width: 180 }}
                                            value={field.value}
                                            onClear={() => field.onChange({ type: BACKGROUND_TYPE.color })}
                                        />
                                    </Popover.Target>
                                    <FillPickerPopoverDropdown
                                        title="填充"
                                        value={field.value}
                                        onChange={field.onChange}
                                        enabledBackgroundTypes={[BACKGROUND_TYPE.color]}
                                        {...colorSystemMethods}
                                    />
                                </Popover>
                            )}
                        />
                    </ListItem4ByecodeUi>
                </>
            )}
        </SCxListItemBaseInfo>
    )
}
