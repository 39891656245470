import type { CheckboxShapeEnum } from '@lighthouse/core'
import { type ButtonAction, type OnOffBlockAbstract, SwitchTypeEnum } from '@lighthouse/core'
import {
    applyFillPickerValue4CssColorValue,
    checkBoxShapeMap,
    InputCheckBox,
    InputSwitch,
    useActionRunningLoadings,
    useFillPickerContext
} from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'

interface OnOffBlockProps {
    blockData: OnOffBlockAbstract
    onActionTrigger?: (params: ButtonAction) => Promise<boolean | undefined>
}

const SCxContainer = styled(AnimationDecorators)<{ loading?: boolean }>``

const OnOffBlock: React.FunctionComponent<OnOffBlockProps> = ({ blockData, onActionTrigger }) => {
    const { id, config } = blockData
    const { defaultChecked, showType, shape, open, close, unCheckedIcon, checkedIcon, breakPoint } = config
    const { palettes } = useFillPickerContext()
    // const { size: { width: configureWidth }} = breakPoint
    const { action: openAction } = open
    const { action: closeAction } = close
    const { loadings, handleActionTriggerWithLoading } = useActionRunningLoadings()

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const val = ev.target.checked
            if (val) {
                handleActionTriggerWithLoading({ type: 'click', action: openAction, id, trigger: onActionTrigger })
                return
            }
            handleActionTriggerWithLoading({ type: 'click', action: closeAction, id, trigger: onActionTrigger })
        },
        [closeAction, handleActionTriggerWithLoading, id, onActionTrigger, openAction]
    )

    const content = useMemo(() => {
        if (showType === SwitchTypeEnum.CHECKBOX) {

            const isCheckbox = !shape || shape === 'checkbox'
            const checkBoxShape = shape ? checkBoxShapeMap[shape] : checkBoxShapeMap.checkbox
            return (
                <InputCheckBox
                    isCheckbox={isCheckbox}
                    progressed={loadings[id]}
                    size="100%"
                    defaultBgcolor={isCheckbox ? 'var(--color-white)' : 'none'}
                    defaultChecked={defaultChecked}
                    unCheckIcon={checkBoxShape?.[0]}
                    unCheckIconColor="var(--color-app-main)"
                    checkIcon={checkBoxShape?.[1]}
                    checkIconColor="var(--color-app-main)"
                    onChange={handleChange}
                />
            )
        }

        if (showType === SwitchTypeEnum.SWITCH) {
            return (
                <InputSwitch
                    progressed={loadings[id]}
                    defaultChecked={defaultChecked}
                    color="var(--color-app-main)"
                    onChange={handleChange}
                />
            )
        }

        const checkIconColor = applyFillPickerValue4CssColorValue(checkedIcon?.color?.color, palettes) || 'var(--color-app-main)'
        const unCheckIconColor = applyFillPickerValue4CssColorValue(unCheckedIcon?.color?.color, palettes) || 'var(--color-gray-400)'
        return (
            <InputCheckBox
                size="100%"
                defaultBgcolor='transparent'
                progressed={loadings[id]}
                defaultChecked={defaultChecked}
                unCheckIcon={unCheckedIcon?.icon?.value || 'SmileCircleIconLibrary'}
                unCheckIconColor={unCheckIconColor}
                checkIcon={checkedIcon?.icon?.value || 'SmileCircleIconLibrary'}
                checkIconColor={checkIconColor}
                onChange={handleChange}
            />
        )
    }, [
        checkedIcon?.color?.color,
        checkedIcon?.icon?.value,
        defaultChecked,
        handleChange,
        id,
        loadings,
        palettes,
        shape,
        showType,
        unCheckedIcon?.color?.color,
        unCheckedIcon?.icon?.value
    ])

    return (
        <SCxContainer animation={breakPoint.animation} blockId={id}>
            {content}
        </SCxContainer>
    )
}

export default OnOffBlock
