import { nanoid } from '@lighthouse/tools'
import { mergeDeepRight } from 'rambda'

import type { PageAbstract } from '../abstracts'
import { PAGE_TYPE, PageOpenType } from '../abstracts'

export function PageStruct(initial: Partial<PageAbstract> = {}): PageAbstract {
    const DEFAULT_VALUE: PageAbstract = {
        type: PAGE_TYPE.default,
        id: nanoid(32),
        name: '新页面',
        icon: 'default',
        appId: '',
        dsId: '',
        open: PageOpenType.all,
        isHome: false,
        showFooter: false,
        roleIds: [],
        departmentIds: [],
        language: '',
        collectEvents: [],
        isShowNavbar: true,
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            size: {
                width: {
                    size: 100,
                    unit: '%'
                },
                maxWidth: {
                    size: 1200,
                    unit: 'px'
                },
                height: {
                    size: 'fill'
                },
                minHeight: {
                    size: 100,
                    unit: '%'
                },
                overflow: 'auto'
            },
            layout: {
                align: {
                    direction: 'vertical',
                    isDistributedAlignment: false,
                    /**
                     * 对齐方式
                     */
                    alignX: 'flex-start',
                    alignY: 'flex-start'
                },
                gap: 8,
                padding: [24, 24, 24, 24]
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
    return mergeDeepRight(DEFAULT_VALUE, initial)
}
