import type { I18TranslationKey } from '../types'

export const en: Record<I18TranslationKey, string> = {
    bindMobile: 'Bind mobile number',
    bindEmail: 'Bind email',
    firstLoginNeedAccount: 'First WeChat login requires binding {{account}}',
    mobileCode: 'Mobile Number',
    noVerificationCodeReceived: 'Did not receive the verification code?',
    noNeedLogin: 'Login temporarily unsupported',
    registerAccount: 'Register Account',
    mobileLogin: 'Mobile Number Login',
    emailLogin: 'Email Login',
    wechatLogin: 'WeChat Login',
    emailUrl: 'Email Address',
    noAccount: "Don't have an account yet?",
    next: 'Next',
    goLogin: 'Go to Login',
    alreadyHaveAnAccount: 'Already have an account?',
    selectRegisterWay: 'Choose registration method',
    perfectInfo: 'Complete Information',
    verificationCode: 'Verification Code',
    sure: 'Confirm',
    yourRole: 'Your Role',
    emailFormatIsIllegal: 'Invalid email format',
    mobilePhoneNumberFormatIsIllegal: 'Invalid phone number format',
    mobileRegister: 'Phone',
    emailRegister: 'Email',
    email: 'Email',
    shortMessage: 'SMS',
    pleaseInputYourEmail: 'Please enter email',
    pleaseInputYourMobile: 'Please enter a valid email',
    pleaseInputYourValidEmail: 'Please enter phone number',
    pleaseInputYourValidMobile: 'Please enter a valid phone number',
    getVerificationCode: 'Get Verification Code',
    register: 'Register',
    pleaseFillOut: 'Please fill out',
    notEmpty: 'Can not be empty',
    getAgain: 'Resend',
    loginSuccess: 'login successful',
    confirmMergeAccounts: `Confirm to merge accounts?`,
    merge: 'merge',
    iAwareRisks: 'I am aware of the risks',
    cancel: 'Cancel',
    changeBindMobile: 'Change mobile phone number',
    mergeAccount: 'Merge accounts',
    keepMobileAndDeleteWechat: 'Keep the mobile phone number account Delete the WeChat account',
    keepWechatAndDeleteMobile: 'Keep WeChat account Delete mobile phone account',
    youAccountUnderReview: 'Your account is under review',
    backLoginPage: 'Return to login page',
    MergeWeChatAccountDescription:
        'To merge the accounts registered with WeChat and mobile phone numbers into one account, please select the account to keep:',
    deleteWechatAccountWarn: 'This operation will delete your WeChat account and cannot be restored, so please operate with caution!',
    mobileAlreadyRegister: 'Mobile phone number has been registered',
    mobileAlreadyRegisterDesc:
        'The mobile phone number has been registered as: {{name}} ({{userId}}). If you still want to bind the mobile phone number, please click "Merge Accounts".',
    goHome: 'Return to Homepage',
    resendTime: `Resend after {{v}} s`,
    verificationMessage: `We have sent a verification code to {{v}}, please check your email`,
    verificationSMS: `We have sent a SMS verification code to {{v}}`,
    inputVerificationCode: `Enter {{v}} verification code`,
    search: 'Search',
    personNameOrPersonId: 'Personnel name, personnel number',
    noFindData: 'No data',
    noFindPerson: 'No user data',
    selected: 'Selected',
    pleaseWeChatLoginInWeChatBrowser: 'Please log in with WeChat in the WeChat browser.',
    refresh: 'Refresh',
    expiredPleaseRefresh: 'Expired, please refresh.',
    scanWechatLogin: 'Scan the QR code with WeChat to follow the official account to log in.',
    accountSetting: 'Account Settings',
    logout: 'Logout',
    notBind: 'Unable to Unbind',
    mobileNumber: 'Mobile Number',
    wechat: 'WeChat',
    removeBindAccountDescribe:
        '{{account}} is the only login method for this account. Please first bind another account as an alternative login method.',
    knew: 'Understood',
    continueUnbinding: 'Continue Unbinding',
    emailAccount: 'Email Account',
    notEnabledAccountLogin: '{{account}} login function is not enabled',
    alreadyBind: 'Already Bound',
    wechatAccount: 'WeChat Account',
    bindAfterUsedAccountLogin: 'After binding, you can log in using {{account}}',
    bindAccountAfterAccountNotEnable:
        'This {{account}} is already registered. Please change your {{account}}. If you still wish to bind, please first disassociate the previously failed {{account}} from its original account before binding it to the currently logged-in account.',
    bindMeetQuestion: 'Encountered a problem during binding',
    changeAccount: 'Change {{account}}',
    goBind: 'Go to Bind',
    unbind: 'Unbind',
    notEnable: 'Not Enabled',
    bindMobileNumber: 'Bind Mobile Number',
    bindMobileNumberAfterLoginApp: 'After binding, you can log in to the app using your mobile number.',
    enterSmsCode: 'Enter SMS Verification Code',
    confirmUnbinding: 'Are you sure you want to continue with the unbinding?',
    unBindNotLoginConfirmDescribe:
        'After unbinding, you will no longer be able to log in via {{account}}. Are you sure you want to continue with the unbinding?',
    alreadyUnbindAccount: ' Mobile {{account}} binding has been unlinked',
    clickToUpload: 'Click to Upload',
    rename: 'Rename',
    delete: 'Delete',
    download: 'Download',
    view: 'View',
    recordVideo: 'Record Video',
    selectFromGallery: 'Select from Gallery',
    localFiles: 'Local Files',
    more: 'More',
    photograph: 'Photograph',
    onlyPicture: 'Only supports images',
    fileExceedsUploadAvatarLimit: 'Avatat exceeds {{v}}mb upload limit',
    fileExceedsUploadLimit: 'File exceeds {{v}}mb upload limit',
    unsupportedFormatOnlyImageUploads: 'Unsupported format. Only image uploads are allowed.',
    unsupportedVideoFormatPleaseUploadMP4file: 'Unsupported video format. Please upload an MP4 file.',
    sort: 'Sort',
    sortEmptyDescribe: 'Add a sorting rule to keep your data organized',
    sortRule: 'Sort',
    add: 'Add',
    group: 'Group',
    linesTotal: 'Total {{count}} rows',
    field: 'Fields',
    pasteLink: 'Paste the link.',
    displayedField: 'Displayed',
    unDisplayedField: 'Undisplayed',
    hideAll: 'Hide all',
    hideEmptyGroups: 'Hide empty groups',
    noData: 'No data available',
    pleasesGoDataSourceAdd: 'Please go to the data source to add.',
    export: 'Export',
    sureDelete: 'Are you sure to delete? ',
    deleteRecordWaring: 'After deleting the selected {{count}} records, they cannot be recovered. Please operate carefully.',
    undo: 'Undo',
    redo: 'Redo',
    uploadXLSXtips: 'Click or drag  <bold /> to this area.',
    uploadingXLSXemptyTips: 'Please ensure that there are <bold /> in the files.',
    uploadFileSizeLimitHowMuch: 'The file size cannot exceed 10MB.',
    importLimitNumberW: 'Each import cannot exceed 20,000 rows of data.',
    onlyXLSXorCSVofFile: 'Only files in.xlsx and.csv formats are supported.',
    fileNoEmptySheet: "There can't be any empty Sheets in the files.",
    tableFileNoMergeCell: "There can't be any merged cells in the files.",
    localImport: 'Local import. ',
    exportingOfProgress: "Exporting is in progress. Please don't click repeatedly.",
    xlsxCsvFileNumber: '.xlsx and.csv files (within {{v}}M)',
    emptySheetOrMergeCell: 'no empty worksheets and merged cells',
    groupBy: 'Group by',
    pleasesSelect: 'Select',
    xItemsSelected: 'Select {{count}} items',
    print: 'Print',
    edit: 'Edit',
    kanbanSetting: 'Kanban settings',
    foldingBoard: 'Collapse',
    hideBoard: 'Hide',
    text: 'Text',
    firstLevelHeading: 'Heading 1',
    secondLevelHeading: 'Heading 2',
    thirdLevelHeading: 'Heading 3',
    todoList: 'To-Do List',
    numberList: 'Numbered list',
    bulletedList: 'Bulleted list',
    alginCenter: 'Align center',
    alginLeft: 'Align left',
    alginRight: 'Align right',
    clearFormat: 'Clear format',
    bold: 'Bold',
    strikethrough: 'Strikethrough',
    italic: 'Italic',
    underline: 'Underline',
    textColor: 'Text color',
    textBackgroundColor: 'Text background color',
    hyperlink: 'Hyperlink',
    insertCode: 'Code',
    uploadImage: 'Image',
    insertCitation: 'Quote',
    divider: 'Divider',
    operation: 'Actions',
    addOneRow: 'Add a row',
    accordingTo: 'According to',
    batchImport: 'Batch Import',
    collapseKanban: 'Collapse',
    hideKanban: 'Hide',
    groupSetting: 'Group settings',
    startImport: 'Start importing',
    againImport: 'Reimport',
    previous: 'Previous step',
    createNewTable: 'reate new table',
    appendImportTable: 'Append import to existing table',
    pleasesSelectSheet: 'Please select <number /> in <name /> Sheet',
    a: 'one',
    reupload: 'Re-upload',
    fieldMapping: 'Field mapping',
    ofColumn: 'Columns',
    ofField: 'Fields',
    mapping: 'Correspond',
    importSuccess: 'Import successful',
    showAll: 'Show All',
    pleasesSetKanbanGrouping: 'Please set the basis for kanban grouping.',

    exportSettings: 'Exporting',
    selectRecords: 'Select records',
    exportAllRecords: 'Export all records',
    exportSpecifiedRecords: 'Export specified records',
    exporting: 'Exporting',
    aMaximumOfRecordsCanExportedAtATime: 'A maximum of {{count}} records can be exported at a time',
    printTemplate: 'Print template',
    selectTemplate: 'Select template',
    exportMethod: 'Export method',
    exportByPage: 'Page-by-page export (All data is combined into one PDF file and the data is paged)',
    noExportTemplate: 'There is currently no export template',
    exportingPDFforPrinting: 'Exporting a PDF file for printing',
    exportFailedTheExportFileCannotExceedNumber: 'Export failed. The size of the exported file cannot exceed {{size}}mb.',
    exportFailedOfNumberRecordsExport: 'Export failed. A maximum of {{count}} records can be exported at a time.',

    // date
    beforeYesterday: 'The day before yesterday',
    yesterday: 'Yesterday',
    today: 'Today',
    tomorrow: 'Tomorrow',
    afterTomorrow: 'The day after tomorrow',

    '0000000': 'Success',
    '0011001': 'Incorrect username or password',
    '0011002': 'User not registered',
    '0011004': 'Application does not exist on client side',
    '0011005': 'Page has no access permission',
    '0011009': 'User does not exist',
    '0011010': 'Name duplicate',
    '0011011': 'Verification code has expired',
    '0011013': 'Unable to enable workflow, please check the configuration and try again',
    '0011017': 'Application has been taken offline',
    '0011018': 'Domain name already exists, please re-enter',
    '0011019': 'User already registered',
    '0011021': 'Your account is under review',
    '0011029': 'No permission for application',
    '0011023': 'Test account connection failed',
    '0011024': 'Number of applications reached the limit',
    '0011025': 'Number of records reached the limit',
    '0011027': 'Attachment capacity reached the limit',
    '0011030': 'Duplicate phone number',
    '0011031': 'Department name duplicate',
    '0011032': 'Number of users reached the limit',
    '0011033': 'Number of applications with bound custom domain reached the limit',
    '0011036': 'Export exceeds 500 records',
    '0011037': 'Export exceeds 50MB',
    '0011039': 'Account exception, please contact administrator',
    '0011042': 'WeChat official account unauthorized',
    '0011043': 'Duplicate email',
    '0011044': 'Phone number already bound',
    '0011045': 'Please open the page in the WeChat browser or on a PC web page before making the payment.',
    '0011047': 'Access traffic exceeds quota',
    '0011048': 'SMS quota exceeded',
    '0011049': 'No records found',
    '0011050': '',
    '0011052': 'WeChat template message exception',
    '0011054': 'Environment has anomalies, merging is prohibited',
    '0011055': 'Modifications are prohibited in the production environment',
    '0011057':
        'The user of this application has already been bound by other applications and cannot be bound to other applications any more.',
    '0011058': 'Private template is forbidden to operate',
    '0011059': 'WeChat cannot be linked repeatedly',
    '0011060': 'The two environment data tables are inconsistent, unable to switch to the synchronized data table environment',
    /**
     * 动作流相关节点异常 start
     */
    '0011061': 'Email sending exception',
    '0011062': 'SMS sending exception',
    '0011063': 'DingTalk robot exception',
    '0011064': 'WeChat robot exception',
    '0011065': 'The order has expired, please create a new order',
    /**
     * 动作流相关节点异常 end
     */
    '0011066': 'Page does not exist',
    '0011068': 'UV out of limit',
    '0011069':
        'Application replication failed because the application contains tables synchronized from other applications or synchronized users.',
    '0011070': 'Application template does not exist',
    '0011073': 'The configuration for the verification code SMS is not complete.',
    '0011074': 'The workflow could not be published, please check the configuration and try again',
    '0011076': '',
    '0020000': 'Page does not exist or page not public!',

    '0030000': 'Filter exception, no need to throw'
}
