import type { FlowNode, RichTextEditorProps, StationMessageActionNode } from '@lighthouse/shared'
import {
    getAllCanBeUpstreamNodes,
    getDefaultValueOptions,
    NodeFieldPreview,
    pureTextFieldTypes,
    RichTextEditor,
    VariableSourceType
} from '@lighthouse/shared'
import { Divider, Text } from '@mantine/core'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useFlow } from '@/contexts/FlowContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import { GroupRequired } from '../Common/GroupRequired'
import { MessageTargetPerson } from './WxTemplateActionConfigure/MessageTargetPerson'

interface StationMessageActionConfigureProps {
    allParentNodes: FlowNode[]
}

const SCxItem = styled.div`
    width: 100%;
    margin: 6px 0 16px 0;
`

const SCxRightFill = styled.div`
    width: 100%;
    margin-top: 8px;

    & div[data-node-view-wrapper] {
        top: 0 !important;
    }
`

export const StationMessageActionConfigure: React.FunctionComponent<StationMessageActionConfigureProps> = ({ allParentNodes }) => {
    const { control } = useFormContext<Pick<StationMessageActionNode, 'config'>>()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const { type } = useFlow()
    const dataSourceList = useDataSourceList(appId, envId)
    const disabledWithVersion = useIsDisabledWithVersion()


    const upstreamOption = useMemo(
        () =>
            getDefaultValueOptions({
                sources: [
                    {
                        sourceType: VariableSourceType.parentNode,
                        dataSourceList,
                        parentNodes: getAllCanBeUpstreamNodes(allParentNodes)
                    }
                ],
                validateFieldType: type => pureTextFieldTypes.has(type)
            }),
        [allParentNodes, dataSourceList]
    )

    // const actorOptions = useMemo(
    //     () =>
    //         getDefaultValueOptions({
    //             sources: [
    //                 {
    //                     sourceType: VariableSourceType.parentNode,
    //                     dataSourceList,
    //                     parentNodes: getAllCanBeUpstreamNodes(allParentNodes) || []
    //                 }
    //             ],
    //             validateFieldType: type => type === 'person'
    //         }),
    //     [allParentNodes, dataSourceList]
    // )

    const richTextConfig: RichTextEditorProps['config'] = useMemo(() => {
        return {
            variable: {
                options: upstreamOption,
                enablePageLink: type !== 'automation'
            },
            heading: false,
            taskList: false,
            bulletList: false,
            orderedList: false,
            codeBlock: false,
            image: false,
            quote: false,
            line: false
        }
    }, [type, upstreamOption])

    return (
        <>
            <GroupRequired
                required
                label="配置参数"
                styles={{
                    root: {
                        padding: 0,
                        marginBottom: 20
                    }
                }}
            >
                <SCxItem>
                    <Text size={14}>标题</Text>
                    <SCxRightFill>
                        <Controller
                            control={control}
                            name="config.title"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <NodeFieldPreview
                                        disabled={disabledWithVersion}
                                        placeholder="请输入文字或添加变量"
                                        value={value}
                                        onChange={onChange}
                                        autoHeight
                                        enablePageLink={type !== 'automation'}
                                        // dataSources={dataSourceList}
                                        // baseNodesData={baseNodesData}
                                        options={upstreamOption}
                                    />
                                )
                            }}
                        />
                    </SCxRightFill>
                </SCxItem>
                <SCxItem>
                    <Text size={14}>正文</Text>
                    <SCxRightFill>
                        <Controller
                            control={control}
                            name="config.content"
                            render={({ field: { value, onChange } }) => {
                                return (
                                    <RichTextEditor
                                        disabled={disabledWithVersion}
                                        config={richTextConfig}
                                        value={value}
                                        styles={{
                                            editorContainer: {
                                                width: '100%',
                                                minHeight: 200,
                                                borderRadius: 6,
                                                border: '1px solid var(--color-gray-200)'
                                            }
                                        }}
                                        disableToolbar={false}
                                        onChange={val => {
                                            onChange(val)
                                        }}
                                    />
                                )
                            }}
                        />
                    </SCxRightFill>
                </SCxItem>
            </GroupRequired>
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <GroupRequired
                required
                label="收信人"
                styles={{
                    root: {
                        padding: '2px 0'
                    }
                }}
            >
                <Controller
                    control={control}
                    name="config.addresseeIds"
                    render={({ field }) => (
                        <MessageTargetPerson
                            dataSourceList={dataSourceList}
                            parentNodes={allParentNodes}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
            </GroupRequired>
        </>
    )
}
