import { Select } from '@byecode/ui'
import type { NodeTypes } from '@lighthouse/shared'
import { CollapseBox, nodeTypeOptions } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import * as SC from '../styles'

export interface CloseFloatActionConfigureProps {
    actionTypeSwitcher?: React.ReactNode
}

export const CloseFloatActionConfigure: React.FC<CloseFloatActionConfigureProps> = ({ actionTypeSwitcher }) => {
    const { control } = useFormContext()

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
