import { hideScrollBar, IconFont, singleTextEllipsis } from '@byecode/ui'
import type { ApplicationSettingThemeColor, TabsColorConfig, VariableADTvalue } from '@lighthouse/core'
import {
    applyFillPickerValue4CssColorValue,
    combineBackgroundStyle,
    getBackgroundStyle,
    getBorderStyle,
    getRadiusStyle,
    getShadowStyle,
    getTextColorStyle,
    getVeinsStyle,
    TEXT_FONT_NORMAL,
    useApplicationContext,
    useFillPickerContext
} from '@lighthouse/shared'
import classnames from 'classnames'
import type { FC } from 'react'
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import type { BaseTabsProps } from '../Tabs.type'
import { TabsContainer } from '../TabsContainer'
import { TabsController } from '../TabsController'

const TabIcon = styled(IconFont)``
const TabLabel = styled.div`
    flex: 1;
`
type TabProps = {
    appId: string | undefined
    lineClamp?: number
    normal?: TabsColorConfig
    hover?: TabsColorConfig
    active?: TabsColorConfig
    palettes: ApplicationSettingThemeColor[]
    parseVariableImage?: (value: VariableADTvalue) => string
}
const Tab = styled.button.withConfig<TabProps>({
    shouldForwardProp: p => !['palettes', 'parseVariableImage', 'lineClamp', 'normal', 'hover', 'active'].includes(p)
})`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    /* font-size: 14px;
    font-weight: 400; */
    padding: 7px 14px;
    border-radius: 36px;
    /* color: var(--color-gray-600);
    background-color: var(--color-white); */
    display: flex;
    flex-wrap: nowrap;
    /* justify-content: center; */
    align-items: center;
    gap: 8px;
    border: 1px solid transparent;
    /* overflow: hidden; */
    white-space: nowrap;
    transition: all 0.3s;

    ${TabLabel} {
        ${({ lineClamp }) => {
            if (!lineClamp) {
                return
            }

            return lineClamp === 1
                ? singleTextEllipsis
                : css`
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: ${lineClamp};
                      overflow: hidden;
                  `
        }};
    }

    ${({ appId, normal, hover, active, parseVariableImage, palettes }) => {
        return {
            '&:not([data-active])&:not(:hover)': {
                ...getBackgroundStyle(appId, normal?.background, palettes, parseVariableImage),
                borderColor: applyFillPickerValue4CssColorValue(normal?.borderColor?.color, palettes),
                [TabLabel]: {
                    ...getTextColorStyle(normal?.color, palettes)
                },
                [TabIcon]: {
                    ...getTextColorStyle(normal?.iconColor, palettes)
                }
            },

            '&:not([data-active]):hover': {
                ...getBackgroundStyle(appId, hover?.background, palettes, parseVariableImage),
                borderColor: applyFillPickerValue4CssColorValue(hover?.borderColor?.color, palettes),
                [TabLabel]: {
                    ...getTextColorStyle(hover?.color, palettes)
                },
                [TabIcon]: {
                    ...getTextColorStyle(hover?.iconColor, palettes)
                }
            },

            '&.active': {
                ...getBackgroundStyle(appId, active?.background, palettes, parseVariableImage),
                borderColor: applyFillPickerValue4CssColorValue(active?.borderColor?.color, palettes),
                [TabLabel]: {
                    ...getTextColorStyle(active?.color, palettes)
                },
                [TabIcon]: {
                    ...getTextColorStyle(active?.iconColor, palettes)
                }
            }
        }
    }}
`
const TabContent = styled.div`
    display: flex;
    gap: 8px;
    overflow-x: auto;
    ${hideScrollBar()}
`

export const RoundTabs: FC<BaseTabsProps> = ({ list, value, onChange, onClickTab, align, design, font, parseVariableImage, ...rest }) => {
    const { background, veins, shadow, border, radius } = design ?? {}
    const { fontFamily, fontStyle, fontWeight, fontSize, letterSpacing, lineClamp, lineHeight, align: textAlign } = font ?? {}
    const { appId } = useApplicationContext()
    const index = useMemo(() => list.findIndex(item => item.id === value), [list, value])

    const contentRef = useRef<null | HTMLDivElement>(null)

    const [isOverflow, setIsOverflow] = useState(false)

    useLayoutEffect(() => {
        const contentEl = contentRef.current
        if (!contentEl) {
            return
        }

        // 检测是否出现滚动条
        const handler: ResizeObserverCallback = ([e]) => {
            setIsOverflow(Math.round(e.target.scrollWidth) > Math.round(e.contentRect.width))
        }
        const observer = new ResizeObserver(handler)

        observer.observe(contentEl)

        return () => {
            observer.unobserve(contentEl)
        }
    }, [index])

    // const hasTabSizeFill = useMemo(() => list.some(item => item.sizeFit === SIZE_FIT.fill), [list])

    const { palettes } = useFillPickerContext()

    return (
        <TabsContainer style={{ justifyContent: align, padding: '4px 0' }} {...rest}>
            {isOverflow && (
                <TabsController
                    onClick={() => {
                        const el = contentRef.current
                        if (!el) {
                            return
                        }
                        const to = Math.max(0, el.scrollLeft - el.clientWidth / 2)
                        el.scrollTo({
                            left: to,
                            behavior: 'smooth'
                        })
                    }}
                >
                    <IconFont type="ArrowLeftSmall" />
                </TabsController>
            )}

            <TabContent
                ref={contentRef}
                style={{
                    // flex: hasTabSizeFill ? 1 : undefined,
                    ...combineBackgroundStyle([
                        getBackgroundStyle(appId, background, palettes, parseVariableImage),
                        getVeinsStyle(veins, palettes)
                    ]),
                    ...getShadowStyle(shadow, palettes),
                    ...getBorderStyle(border, palettes),
                    ...getRadiusStyle(radius)
                }}
            >
                {list.map(tab => (
                    <Tab
                        key={tab.id}
                        type="button"
                        data-active={tab.id === value || undefined}
                        className={classnames({ active: tab.id === value })}
                        onClick={e => {
                            onClickTab?.(e, tab.id)
                            if (value === tab.id) {
                                return
                            }

                            onChange?.(tab.id)
                        }}
                        style={{
                            // flex: tab.sizeFit === SIZE_FIT.fill ? 1 : undefined,
                            fontFamily: fontFamily === TEXT_FONT_NORMAL ? undefined : fontFamily,
                            fontStyle,
                            fontWeight,
                            fontSize: `${fontSize}em`,
                            letterSpacing: `${letterSpacing ?? 0}rem`,
                            lineHeight: `${lineHeight ?? 1}`,
                            textAlign
                        }}
                        appId={appId}
                        lineClamp={lineClamp}
                        parseVariableImage={parseVariableImage}
                        palettes={palettes}
                        normal={{
                            background: tab.background,
                            color: tab.color,
                            iconColor: tab.iconColor,
                            borderColor: tab.borderColor
                        }}
                        hover={tab.hover}
                        active={tab.active}
                    >
                        {tab.icon && <TabIcon type={tab.icon} style={tab.iconPosition === 'right' ? { order: 1 } : undefined} />}
                        <TabLabel>{tab.name}</TabLabel>
                    </Tab>
                ))}
            </TabContent>

            {isOverflow && (
                <TabsController
                    onClick={() => {
                        const el = contentRef.current
                        if (!el) {
                            return
                        }
                        const to = Math.min(el.scrollWidth, el.scrollLeft + el.clientWidth / 2)
                        el.scrollTo({
                            left: to,
                            behavior: 'smooth'
                        })
                    }}
                >
                    <IconFont type="ArrowRightSmall" />
                </TabsController>
            )}
        </TabsContainer>
    )
}
