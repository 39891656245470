import clsx from 'clsx'
import { addMonths, addYears, getYear, lightFormat } from 'date-fns'
import React, { forwardRef, useMemo } from 'react'

import type { Selectors, StyleComponentProps } from '../../../theme/types'
import { Box } from '../../Box'
import { Button } from '../../Button'
import { IconFont } from '../../IconFont'
import type { PanelView } from '../Calendar.type'
import { useStyles } from './CalendarHeader.style'

export type CalendarHeaderStylesName = Selectors<typeof useStyles>

export interface CalendarHeaderProps extends StyleComponentProps<CalendarHeaderStylesName> {
    date?: Date
    onChangePanelDate: (date: Date) => void
    onPrevYear?: (date: Date) => void
    onPrevMonth?: (date: Date) => void
    onNextYear?: (date: Date) => void
    onNextMonth?: (date: Date) => void

    panelView: PanelView
    onChangePanelView: (mode: PanelView) => void
}

export const CalendarHeader = forwardRef<HTMLDivElement, CalendarHeaderProps>((props, ref) => {
    const {
        date = new Date(),
        onPrevYear,
        onPrevMonth,
        onNextYear,
        onNextMonth,
        onChangePanelDate,
        panelView,
        onChangePanelView,
        unstyled,
        classNames,
        styles,
        ...rest
    } = props

    const { classes } = useStyles({}, { name: 'Calendar', unstyled, classNames, styles })

    const title = useMemo(() => {
        switch (panelView) {
            case 'month': {
                return (
                    <Button size="sm" type="text" onClick={() => onChangePanelView('year')}>
                        {lightFormat(date, 'yyyy')}
                    </Button>
                )
            }

            case 'year': {
                const year = getYear(date)

                return (
                    <>
                        <Box>{year - (year % 10)}</Box>
                        <span style={{ margin: '0 8px' }}>-</span>
                        <Box>{year - (year % 10) + 10}</Box>
                    </>
                )
            }
            case 'time': {
                return <span style={{ padding: 3 }}>选择时间</span>
            }

            default: {
                return (
                    <>
                        <Button size="sm" type="text" onClick={() => onChangePanelView('year')}>
                            {lightFormat(date, 'yyyy')}
                        </Button>
                        <span>-</span>
                        <Button size="sm" type="text" onClick={() => onChangePanelView('month')}>
                            {lightFormat(date, 'MM')}
                        </Button>
                    </>
                )
            }
        }
    }, [date, panelView, onChangePanelView])

    const leftActions = useMemo(() => {
        switch (panelView) {
            case 'day': {
                return (
                    <>
                        <Button
                            size="sm"
                            type="text"
                            icon={<IconFont type="ArrowLeftDouble" />}
                            onClick={() => onPrevYear?.(addMonths(date, -12))}
                        />
                        <Button
                            size="sm"
                            type="text"
                            icon={<IconFont type="ArrowLeftSmall" />}
                            onClick={() => onPrevMonth?.(addMonths(date, -1))}
                        />
                    </>
                )
            }

            case 'month': {
                return (
                    <Button
                        size="sm"
                        type="text"
                        icon={<IconFont type="ArrowLeftSmall" />}
                        onClick={() => onChangePanelDate(addYears(date, -1))}
                    />
                )
            }

            case 'year': {
                return (
                    <Button
                        size="sm"
                        type="text"
                        icon={<IconFont type="ArrowLeftSmall" />}
                        onClick={() => onChangePanelDate(addYears(date, -10))}
                    />
                )
            }

            default: {
                return null
            }
        }
    }, [date, panelView, onChangePanelDate, onPrevMonth, onPrevYear])

    const rightActions = useMemo(() => {
        switch (panelView) {
            case 'day': {
                return (
                    <>
                        <Button
                            size="sm"
                            type="text"
                            icon={<IconFont type="ArrowRightSmall" />}
                            onClick={() => onNextMonth?.(addMonths(date, 1))}
                        />
                        <Button
                            size="sm"
                            type="text"
                            icon={<IconFont type="ArrowRightDouble" />}
                            onClick={() => onNextYear?.(addMonths(date, 12))}
                        />
                    </>
                )
            }

            case 'month': {
                return (
                    <Button
                        size="sm"
                        type="text"
                        icon={<IconFont type="ArrowRightSmall" />}
                        onClick={() => onChangePanelDate(addYears(date, 1))}
                    />
                )
            }

            case 'year': {
                return (
                    <Button
                        size="sm"
                        type="text"
                        icon={<IconFont type="ArrowRightSmall" />}
                        onClick={() => onChangePanelDate(addYears(date, 10))}
                    />
                )
            }

            default: {
                return null
            }
        }
    }, [date, panelView, onChangePanelDate, onNextMonth, onNextYear])

    return (
        <Box ref={ref} className={clsx(classes.header)} {...rest}>
            <Box>{leftActions}</Box>

            <Box className={classes.title}>{title}</Box>

            <Box>{rightActions}</Box>
        </Box>
    )
})
