import type { PopoverProps } from '@byecode/ui'
import type { AppUser } from '@lighthouse/core'
import  React, { useState } from 'react'

import type { LoginAuthType } from '../../AccountPage'
import type { UserAvatarProps } from '../../UserAvatar'
import { ModalUserCenter } from './ModalUserCenter'
import { PopoverUserCenter } from './PopoverUserCenter'

interface UserCenterProps extends Pick<PopoverProps, 'position'> {
    userData: AppUser
    isMobile?: boolean
    avatarProps?: Partial<UserAvatarProps>
    style?: React.CSSProperties
    onLogout?: () => void
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

export const UserCenter: React.FunctionComponent<UserCenterProps> = ({
    userData ,
    style,
    isMobile,
    position,
    avatarProps,
    onLogout,
    onUpdateBindAccount
}) => {
    if (isMobile) {
        return (
            <ModalUserCenter
                avatarProps={avatarProps}
                userData={userData}
                style={style}
                onLogout={onLogout}
                onUpdateBindAccount={onUpdateBindAccount}
            />
        )
    }

    return (
        <PopoverUserCenter
            avatarProps={avatarProps}
            userData={userData}
            position={position}
            style={style}
            onLogout={onLogout}
            onUpdateBindAccount={onUpdateBindAccount}
        />
    )
}
