import type { BlockAbstract } from '@lighthouse/core'

import type { NodeIdWithScope } from '../components'
import type { OperatorNodeType } from './flow'

export type ActionType = OperatorNodeType | 'CREATE_SINGLE_RECORD_ACTION' | 'UPDATE_RECORD_ACTION'

export const controllableBlocks = ['view', 'container'] as const

export type WithBlockOption = {
    label: string
    value: NodeIdWithScope
    block: BlockAbstract
    children?: WithBlockOption[]
    level: number
}
