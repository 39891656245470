import type { Option } from '@byecode/ui'
import { Divider, SegmentedControl, Select } from '@byecode/ui'
import type { DataSourceAbstract, ViewType } from '@lighthouse/core'
import { DataSourceType, RecordOpenType } from '@lighthouse/core'
import type { ClickTriggerConfig, FlowNode, NodeTypes, TriggerFlowNode } from '@lighthouse/shared'
import {
    canAsOpenRecordPageUpstreamRecordNodeTypes,
    CollapseBox,
    getIsAllowedDataSourceAsFlowOption,
    getNodeInfo,
    getUpstreamRealDsId,
    nodeTypeOptions,
    OpenDetailPageSelectedRecordType,
    OpenTypeData,
    SelectDataSource,
    selectedRecordTypeOptions
} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useNotAggregateDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlocksWithDsId } from '@/hooks/useFieldBlocksWithDsId'
import { useRecordEditPageList } from '@/hooks/usePage'
import { useVariableCustomDataSource } from '@/hooks/useVariableCustomViewOption'

import { RecordMatchFilterConfigurator } from '../../RecordMatchFilterConfigurator'
import * as SC from '../styles'

export interface OpenRecordEditPageActionConfigureProps {
    prefixName?: string
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
}

export const OpenRecordEditPageActionConfigure: React.FC<OpenRecordEditPageActionConfigureProps> = ({
    prefixName = 'config',
    allParentNodes,
    actionTypeSwitcher
}) => {
    const { control } = useFormContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useNotAggregateDataSourceList(appId, envId)
    const { isForm, dsId, viewType } = useActionAdderDepParams()
    // const { data: listDs = [] } = useSWR(`/fetch/listDs/${appId}`, () => srv.listDs(appId))

    const { type } = useFlow()

    const isInFlow = !!type

    const { fieldBlocksWithDsId } = useFieldBlocksWithDsId()

    const dsOptions = useMemo(
        () =>
            dataSourceList.reduce<DataSourceAbstract[]>((listDs, item) => {
                if (getIsAllowedDataSourceAsFlowOption(item)) {
                    listDs.push(item)
                }
                return listDs
            }, []),
        [dataSourceList]
    )

    const dataSourceId = useWatch({ control, name: `${prefixName}.dataSourceId` })

    const selectedRecordType: string = useWatch({
        control,
        name: `${prefixName}.selectedRecordType`
    })

    const allRecordEditPages = useRecordEditPageList()

    const upstreamRecordNodeId: string = useWatch({
        control,
        name: `${prefixName}.upstreamRecordNodeId`
    })

    const depDataSourceId = useMemo(() => {
        switch (selectedRecordType) {
            case OpenDetailPageSelectedRecordType.CURRENT_RECORD: {
                return dsId
            }
            case OpenDetailPageSelectedRecordType.MATCHED_RECORD: {
                return dataSourceId
            }
            case OpenDetailPageSelectedRecordType.UPSTREAM_RECORD: {
                const upstreamRecordNode = allParentNodes.find(node => node.id === upstreamRecordNodeId)
                const upstreamRecordNodeConfig = upstreamRecordNode?.data?.config
                if (!upstreamRecordNodeConfig) {
                    return undefined
                }
                if (upstreamRecordNode.type === 'ACTION' && upstreamRecordNode.data.nodeType === 'UPDATE_RECORD_ACTION') {
                    return getUpstreamRealDsId(upstreamRecordNodeId, allParentNodes)
                }
                if (upstreamRecordNodeConfig && 'dataSourceId' in upstreamRecordNodeConfig) {
                    return upstreamRecordNodeConfig.dataSourceId
                }
                return undefined
            }

            default: {
                return ''
            }
        }
    }, [allParentNodes, dataSourceId, dsId, selectedRecordType, upstreamRecordNodeId])

    const recordPageOptions = useMemo(() => {
        return allRecordEditPages
            .filter(r => r.dsId === depDataSourceId)
            .map(item => ({
                label: item.name,
                value: item.id,
                icon: 'DataSource'
            }))
    }, [allRecordEditPages, depDataSourceId])

    const clickTriggerNode = useMemo(() => {
        return allParentNodes.find(node => node.data.nodeType === 'CLICK_TRIGGER') as TriggerFlowNode<ClickTriggerConfig> | undefined
    }, [allParentNodes])

    // const isDifferentDataSource = clickTriggerNode && clickTriggerNode.data.config?.dataSourceId !== dataSourceId

    const isShowMatch = useMemo(() => {
        // if (!dataSourceId) {
        //     return false
        // }
        // if (isForm) {
        //     return true
        // }
        // return isDifferentDataSource
        return !!dataSourceId
    }, [dataSourceId])

    const { customViewDataSource } = useVariableCustomDataSource()

    const [viewDsId, currentViewType]: [string | undefined, ViewType | undefined] = useMemo(() => {
        if (customViewDataSource) {
            return [customViewDataSource?.id, 'custom']
        }
        return [dsId, viewType]
    }, [customViewDataSource, dsId, viewType])

    const openRecordPageUpstreamRecordOptions = useMemo(() => {
        return allParentNodes.reduce<Option[]>((prev, cur) => {
            if (!canAsOpenRecordPageUpstreamRecordNodeTypes.has(cur.data.nodeType)) {
                return prev
            }
            if (cur.data.config && 'dataSourceId' in cur.data.config) {
                const { dataSourceId } = cur.data.config
                const dataSource = find(item => item.id === dataSourceId, dataSourceList)
                if (!dataSource || dataSource.type === DataSourceType.aggregateDataSource) {
                    return prev
                }
            }
            prev.push({
                label: cur.data.name,
                value: cur.id,
                icon: getNodeInfo(cur.data.nodeType).icon
            })
            return prev
        }, [])
    }, [allParentNodes, dataSourceList])

    const filteredSelectedRecordTypeOptions = useMemo(() => {
        if (isInFlow) {
            return selectedRecordTypeOptions.filter(item => item.value !== OpenDetailPageSelectedRecordType.CURRENT_RECORD)
        }
        return selectedRecordTypeOptions.filter(item => item.value !== OpenDetailPageSelectedRecordType.UPSTREAM_RECORD)
    }, [isInFlow])

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>

            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="打开哪条记录的编辑页">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>选择记录</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.selectedRecordType`}
                                control={control}
                                render={({ field }) => <Select options={filteredSelectedRecordTypeOptions} {...field} />}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    {selectedRecordType === OpenDetailPageSelectedRecordType.MATCHED_RECORD && (
                        <>
                            <SC.FormItem>
                                <SC.FormItemLabelWrapper>
                                    <SC.FormItemLabel required>数据表</SC.FormItemLabel>
                                </SC.FormItemLabelWrapper>
                                <SC.FormItemContent>
                                    <Controller
                                        name={`${prefixName}.dataSourceId`}
                                        control={control}
                                        render={({ field }) => <SelectDataSource withinPortal dataSourceList={dsOptions} {...field} />}
                                    />
                                </SC.FormItemContent>
                            </SC.FormItem>
                            {isShowMatch && clickTriggerNode && (
                                <div style={{ margin: '4px 0' }}>
                                    <RecordMatchFilterConfigurator
                                        prefixName={prefixName}
                                        fieldBlocksWithDsId={fieldBlocksWithDsId}
                                        clickTriggerNode={clickTriggerNode}
                                        allParentNodes={allParentNodes}
                                        formDsId={isForm && !isInFlow ? dsId : ''}
                                        viewType={isInFlow ? undefined : currentViewType}
                                        viewDsId={isInFlow ? undefined : viewDsId}
                                    />
                                </div>
                            )}
                        </>
                    )}
                    {selectedRecordType === OpenDetailPageSelectedRecordType.UPSTREAM_RECORD && (
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel required>上游节点</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name={`${prefixName}.upstreamRecordNodeId`}
                                    control={control}
                                    render={({ field }) => <Select options={openRecordPageUpstreamRecordOptions} {...field} />}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                    )}
                </SC.Content>
            </CollapseBox>
            <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="详情页">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>记录编辑页</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.recordEditPageId`}
                                control={control}
                                render={({ field }) => <Select options={recordPageOptions} {...field} />}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>打开方式</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name={`${prefixName}.pageOpenType`}
                                control={control}
                                defaultValue={RecordOpenType.modal}
                                render={({ field }) => <SegmentedControl fullWidth {...field} data={OpenTypeData} />}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
