import { Button, Flex, Input, Modal, Text } from '@byecode/ui'
import React, { useEffect, useState } from 'react'

interface CreateSyncModalProps {
    onCreate: (name: string) => void
    onCancel: () => void
}

export const CreateSyncModal = ({ onCreate, onCancel }: CreateSyncModalProps) => {
    const [value, setValue] = useState('')

    return (
        <Modal
            open
            width={360}
            closeOnClickOverlay={false}
            closeOnEscape={false}
            withCloseIcon={false}
            styles={{ desktop: { body: { padding: 24 } } }}
        >
            <Text weight={600} size={16}>
                创建同步组件
            </Text>
            <Text my={12} size={14} lineHeight="22px" color="var(--color-gray-600)" ellipsis={false} whiteSpace="unset">
                {'可通过“左侧栏>添加>同步组件”查看所有已创建的同步组件。'}
            </Text>
            <Input
                value={value}
                onChange={e => {
                    const { value } = e.target
                    setValue(value)
                }}
                placeholder="同步组件名称"
                maxLength={20}
                size="lg"
            />
            <Flex gap={8} justifyContent="flex-end" mt={24}>
                <Button size="lg" onClick={onCancel}>
                    取消
                </Button>
                <Button size="lg" disabled={!value} type="primary" onClick={() => value && onCreate(value)}>
                    确定
                </Button>
            </Flex>
        </Modal>
    )
}
