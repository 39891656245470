import { SegmentedControl } from '@byecode/ui/components/SegmentedControl'
import { Text } from '@byecode/ui/components/Text'
import type { DataSourceAbstract, Field, Fit, VariableADTvalue } from '@lighthouse/core'
import { FIT, VariableType } from '@lighthouse/core'
import React from 'react'

import { InnerTypeMapByFieldType } from '../../../constants/dataSource'
import { ListItem4ByecodeUi } from '../../ListItemMenu'
import type { UploadDropZoneProps } from '../../UploadManage'
import type { VariableOptions, VariableTree } from '../../Variable'
import { VariableSelect } from '../../Variable'
import type { EditBackgroundProtocol } from '../type'

const IMAGE_SIZE_OPTIONS = [
    {
        label: '铺满',
        value: FIT.cover
    },
    {
        label: '适应',
        value: FIT.contain
    }
]

const FILE_FIELD: Field = { id: 'test', dsId: '', type: 'file', name: '', innerType: InnerTypeMapByFieldType['file'] }

export interface ImageContainerProps {
    value: EditBackgroundProtocol
    onChange: (value: EditBackgroundProtocol) => void
    options?: VariableOptions
    uploadProps?: UploadDropZoneProps
    userOption?: VariableTree
    viewOption?: VariableTree
}

export const ImageContainer = ({ value, onChange, options, uploadProps, userOption, viewOption }: ImageContainerProps) => {
    return (
        <>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text size={14}>选择图片</Text>
                <VariableSelect
                    value={value.image?.resource}
                    onChange={v => onChange({ ...value, image: { resource: v, fit: value.image?.fit || FIT.cover } })}
                    options={options}
                    uploadProps={uploadProps}
                    userOption={userOption}
                    viewOption={viewOption}
                    field={FILE_FIELD}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <Text size={14}>图片适应</Text>
                <SegmentedControl
                    data={IMAGE_SIZE_OPTIONS}
                    value={value.image?.fit}
                    onChange={v =>
                        onChange({
                            ...value,
                            image: {
                                resource:
                                    value.image?.resource ||
                                    ({
                                        type: VariableType.VALUE,
                                        valueVariable: {
                                            type: 'file',
                                            value: []
                                        }
                                    } satisfies VariableADTvalue),
                                fit: v as Fit
                            }
                        })
                    }
                    fullWidth
                    style={{ width: 180 }}
                />
            </ListItem4ByecodeUi>
        </>
    )
}
