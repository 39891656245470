import { IconFont } from '@byecode/ui'
import { CheckboxShapeEnum } from '@lighthouse/core'
import { useUncontrolled } from '@lighthouse/tools'
import type { ChangeEvent } from 'react'
import React, { useCallback, useId, useMemo } from 'react'
import styled, { css } from 'styled-components'

const SCxCheckboxIcon = styled(IconFont)``

const SCxCheckboxInner = styled.label<{
    size: string
    border?: boolean
    checked: boolean
    defaultBgcolor: string
    disabled?: boolean
    progressed?: boolean
    color?: string
}>`
    display: flex;
    justify-content: center;
    border-radius: 4px;
    width: ${({ size }) => size};
    height: auto;
    outline: none;
    aspect-ratio: 1;
    cursor: pointer;

    ${({ disabled }) =>
        disabled &&
        css`
            opacity: 0.4;
            cursor: not-allowed;
        `}

    ${({ progressed }) =>
        progressed &&
        css`
            cursor: progress;
        `}

    ${({ checked, defaultBgcolor, color, border }) =>
        checked
            ? css`
                  background-color: ${border ? color : defaultBgcolor};
                  border: ${border ? `1px solid ${color}` : 'none'};
              `
            : css`
                  background-color: ${defaultBgcolor};
                  border: ${border ? '1px solid var(--color-gray-400)' : 'none'};
                  &:hover {
                      border: ${border ? `1px solid ${color}` : 'none'};
                      background-color: ${defaultBgcolor || 'var(--color-purple-25)'};
                  }
              `}
`
const SCxCheckboxWrapper = styled.div`
    position: relative;
    display: fixed;
    align-items: center;
`

const SCxCheckboxInput = styled.input`
    position: absolute;
    overflow: hidden;
    width: 0;
    height: 0;
    z-index: -1;
    visibility: hidden;
`

export interface InputCheckBoxProps {
    checked?: boolean
    defaultChecked?: boolean
    disabled?: boolean
    progressed?: boolean
    defaultBgcolor?: string
    // shape?: CheckboxShapeEnum
    isCheckbox?: boolean
    size?: string
    checkIcon?: string
    checkIconColor?: string
    unCheckIcon?: string
    unCheckIconColor?: string
    onChange?: (ev: ChangeEvent<HTMLInputElement>) => void
}

export const checkBoxShape = [
    {
        icon: 'PropertyCheckboxBlock',
        value: CheckboxShapeEnum.checkbox
    },
    {
        icon: 'HeartFill',
        value: CheckboxShapeEnum.heart
    },
    {
        icon: 'StartFill',
        value: CheckboxShapeEnum.collect
    },
    {
        icon: 'FlagFill',
        value: CheckboxShapeEnum.flag
    },
    {
        icon: 'LikeFill',
        value: CheckboxShapeEnum.like
    }
]

export const InputCheckBox: React.FunctionComponent<InputCheckBoxProps> = ({
    checked,
    defaultChecked,
    disabled,
    progressed,
    defaultBgcolor = 'var(--color-white)',

    // shape = CheckboxShapeEnum.checkbox,
    isCheckbox,
    size = '16px',
    checkIcon,
    checkIconColor,
    unCheckIcon,
    unCheckIconColor,
    onChange
}) => {
    const domId = useId()
    const [_value, handleChange] = useUncontrolled({ value: checked, finalValue: defaultChecked })

    const handleCheckedChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            if (disabled) {
                return
            }
            handleChange(ev.target.checked)
            onChange?.(ev)
        },
        [disabled, handleChange, onChange]
    )

    const iconType = useMemo(() => (_value ? checkIcon : unCheckIcon), [_value, checkIcon, unCheckIcon])

    const backgroundColor = useMemo(() => {
        if (_value) {
            return checkIconColor
        }
        return unCheckIconColor
    }, [_value, checkIconColor, unCheckIconColor])

    const iconColor = useMemo(() => {
        if (_value) {
            return isCheckbox ? 'var(--color-white)' : checkIconColor
        }
        return unCheckIconColor
    }, [_value, isCheckbox, checkIconColor, unCheckIconColor])

    return (
        <SCxCheckboxWrapper>
            <SCxCheckboxInner
                size={size}
                border={isCheckbox}
                disabled={disabled}
                progressed={progressed}
                defaultBgcolor={defaultBgcolor}
                color={backgroundColor}
                checked={!!_value}
                htmlFor={domId}
            >
                <SCxCheckboxInput
                    disabled={disabled || progressed}
                    type="checkbox"
                    id={domId}
                    checked={_value}
                    onChange={handleCheckedChange}
                />
                <SCxCheckboxIcon size={size} type={iconType || ''} fill={iconColor} />
            </SCxCheckboxInner>
        </SCxCheckboxWrapper>
    )
}
