import { Empty, Image, Menu, Popover, Toast, useDisclosure } from '@byecode/ui'
import { flip, limitShift, offset, shift, useClick, useDismiss, useFloating, useInteractions } from '@floating-ui/react'
import { getAssetUrl } from '@lighthouse/assets'
import type { ContainerBreakPointProtocol, HoverAnimation, LayerInViewAnimation, LoopAnimation } from '@lighthouse/core'
import { AnimationTypeEnum, BlockType } from '@lighthouse/core'
import { findNormalOrSyncBlock, Group4ByecodeUi, ListItem4ByecodeUi, TagIcon, useAtomData } from '@lighthouse/shared'
import { mergeRefs } from '@lighthouse/tools'
import { findLast } from 'rambda'
import React, { useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { blockAnimationStateAtom, syncComponentsAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { InheritLabel } from '@/components/InheritLabel'

import { useSetExtendsKeys } from '../hooks/useMouseEventDistance'
import AnimationPreview from './AnimationPreview'
import AnimationSettingController from './AnimationSettingController'
import LayerInViewAnimationSetting from './LayerInViewAnimationSetting'
import { animationNameMap, getDefaultAnimation, getInitAnimation } from './util'

interface AnimationControllerProps {
    // value: AnimationProtocols
    // onChange: (v: AnimationProtocols) => void
}

const SCxContainer = styled.div`
    border-radius: 8px;
    margin: 8px;
    border: 1px dashed var(--color-gray-200);
    background-color: var(--color-gray-25);
`

const SCxList = styled.div``

const SCxDropDown = styled.div`
    z-index: 200;
    background: #fff;
    padding: 8px 0;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    box-shadow: 0px 4px 12px rgba(16, 24, 40, 0.1);
`

const AnimationConfig: React.FunctionComponent<{ animationType: AnimationTypeEnum }> = ({ animationType }) => {
    const { watch, setValue, control } = useFormContext<ContainerBreakPointProtocol>()
    const [opened, { close, toggle }] = useDisclosure()
    const stack = useAtomData(lastPageOfStackAtom)
    const { state: { selectedNodes = [] } = {} } = stack ?? {}
    const selectNode = selectedNodes?.[0] ?? {}
    const blockAnimationState = useAtomData(blockAnimationStateAtom)
    const isPlayed = findLast(
        v => v.blockId === selectNode.id && v.scope === selectNode.scope && animationType === v.type,
        blockAnimationState
    )?.played

    const disabled = findLast(v => v.blockId === selectNode.id && v.scope === selectNode.scope, blockAnimationState)?.played

    const animationFloating = useFloating({
        open: opened,
        onOpenChange: toggle,
        strategy: 'fixed',
        placement: 'left-start',
        middleware: [shift({ limiter: limitShift() })]
    })
    const click = useClick(animationFloating.context, { enabled: disabled })
    const dismiss = useDismiss(animationFloating.context)
    const interactionProps = useInteractions([dismiss, click])

    const animationController = useMemo(() => {
        return {
            layerInView: <LayerInViewAnimationSetting onClose={close} />,
            hover: (
                <Controller
                    name="breakPoint.animation.hover.effect"
                    control={control}
                    render={({ field }) => (
                        <AnimationSettingController
                            title="悬停效果"
                            type={AnimationTypeEnum.hover}
                            value={field.value ?? getDefaultAnimation()}
                            onClose={close}
                            onChange={v => {
                                field.onChange(v)
                            }}
                        />
                    )}
                />
            ),
            text: null,
            loop: (
                <Controller
                    name="breakPoint.animation.loop.effect"
                    control={control}
                    render={({ field }) => (
                        <AnimationSettingController
                            title="循环效果"
                            type={AnimationTypeEnum.loop}
                            value={field.value ?? getDefaultAnimation()}
                            onClose={close}
                            onChange={v => {
                                field.onChange(v)
                            }}
                        />
                    )}
                />
            )
        }
    }, [close, control])

    const AnimationComp = animationController[animationType]

    return (
        <>
            <div ref={animationFloating.refs.setReference} {...interactionProps.getReferenceProps({ onClick: () => toggle() })}>
                <AnimationPreview
                    isPlayed={!!isPlayed}
                    type={animationType}
                    disabled={disabled}
                    onClick={e => {
                        if (disabled) {
                            e.stopPropagation()
                            Toast.error('请先暂停动画')
                        }
                    }}
                />
            </div>
            {opened && (
                <SCxDropDown
                    ref={animationFloating.refs.setFloating}
                    style={{ ...animationFloating.floatingStyles, left: -132 }}
                    {...interactionProps.getFloatingProps()}
                >
                    {AnimationComp}
                </SCxDropDown>
            )}
        </>
    )
}

const AnimationController: React.FunctionComponent<AnimationControllerProps> = () => {
    const { watch, setValue, control } = useFormContext<ContainerBreakPointProtocol>()
    const [animation = {}] = watch(['breakPoint.animation'])
    const { handleSetBreakKey } = useSetExtendsKeys()
    const isEmptyAnimation = Object.entries(animation).every(([key, v]) => !v)
    const isCanAddAnimation = true
    const stack = useAtomData(lastPageOfStackAtom)
    const { pageId = '', state: { selectedNodes = [] } = {} } = stack ?? {}
    const selectNode = selectedNodes?.[0] ?? {}
    const blocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponent = useAtomData(syncComponentsAtom)
    const block = findNormalOrSyncBlock(selectNode, blocks, syncComponent)
    return (
        <Group4ByecodeUi
            label="动效"
            styles={{
                collapse: {
                    padding: '0 8px !important'
                }
            }}
            extra={
                isCanAddAnimation && (
                    <Menu width={200} closeOnItemClick>
                        <Menu.Target>
                            <TagIcon
                                icon="Add"
                                size={24}
                                enableHover
                                hoverBackground="#26415A0F"
                                color="var(--color-gray-400)"
                                radius={4}
                                onClick={e => {
                                    e.stopPropagation()
                                }}
                            />
                        </Menu.Target>
                        <Menu.Dropdown>
                            <Menu.Item
                                disabled={!!animation?.hover?.type || block?.type === BlockType.floatBox}
                                onClick={e => {
                                    e.stopPropagation()
                                    setValue(`breakPoint.animation.hover`, getInitAnimation(AnimationTypeEnum.hover) as HoverAnimation)
                                    handleSetBreakKey('breakPoint.animation.hover')
                                }}
                            >
                                {animationNameMap['hover']}
                            </Menu.Item>

                            <Menu.Item
                                disabled={!!animation?.layerInView?.type}
                                onClick={e => {
                                    e.stopPropagation()
                                    setValue(
                                        `breakPoint.animation.layerInView`,
                                        getInitAnimation(AnimationTypeEnum.layerInView) as LayerInViewAnimation
                                    )
                                    handleSetBreakKey('breakPoint.animation.layerInView')
                                }}
                            >
                                {animationNameMap['layerInView']}
                            </Menu.Item>
                            <Menu.Item
                                disabled={!!animation?.loop?.type || block?.type === BlockType.floatBox}
                                onClick={e => {
                                    e.stopPropagation()
                                    setValue(`breakPoint.animation.loop`, getInitAnimation(AnimationTypeEnum.loop) as LoopAnimation)
                                    handleSetBreakKey('breakPoint.animation.loop')
                                }}
                            >
                                {animationNameMap['loop']}
                            </Menu.Item>

                            {/* <Divider style={{ margin: '8px 0' }} />
                            <Menu.Item disabled closeMenuOnClick>
                                {animationNameMap['text']}
                            </Menu.Item> */}
                        </Menu.Dropdown>
                    </Menu>
                )
            }
        >
            {isEmptyAnimation ? (
                <SCxContainer>
                    <Empty
                        icon={<Image width={48} src={getAssetUrl('empty', 'empty_animation.png')} />}
                        styles={{
                            root: {
                                height: 130
                            },
                            wrapper: {
                                gap: 12
                            }
                        }}
                        description="添加动效让你的网站动起来"
                    />
                </SCxContainer>
            ) : (
                <SCxList>
                    {Object.entries(animation).map(([key, animation]) => {
                        if (!animation?.type) {
                            return null
                        }
                        const animationType = key as AnimationTypeEnum
                        const title = animationNameMap[animationType]

                        return (
                            <ListItem4ByecodeUi key={key} enablePadding justifyContent="space-between" alignItems="center">
                                <InheritLabel label={title} name={`breakPoint.animation.${animationType}`} />
                                <AnimationConfig animationType={animationType} />
                            </ListItem4ByecodeUi>
                        )
                    })}
                </SCxList>
            )}
        </Group4ByecodeUi>
    )
}

export default AnimationController
