import type { JSONContent } from '@tiptap/react'

import type { SubProcessArg } from "./flow"
import type { NodeFieldContent } from './richtext'
import type { EVENT_VARIABLE_TYPE } from './variable'

export enum CollectEventWriteType {
    RECORD = 'RECORD',
    WORKFLOW = 'WORKFLOW',
}

export type CommonCollectEvent = {
    id: string
    title: string
    enable: boolean
    triggerType?: EVENT_VARIABLE_TYPE
    writeType: CollectEventWriteType
}

export type CollectEventRecord = CommonCollectEvent & {
    record?: {
        dataSourceId?: string
        fields: NodeFieldContent[]  // 同工作流设置记录内容 类型
    }
}

export type CollectEventSubProcess = CommonCollectEvent & {
    workflow?: {
        subProcessId: string   // 与执行子流程 循环节点  类型相同
        args: SubProcessArg[]  // 与执行子流程 循环节点  类型相同
    }
}

export type CollectEventCreateRecord = Record<'writeType', CollectEventWriteType.RECORD> & CollectEventRecord
export type CollectEventHandleSubProcess = Record<'writeType', CollectEventWriteType.WORKFLOW> & CollectEventSubProcess

export type CollectEvent = CollectEventHandleSubProcess | CollectEventCreateRecord
