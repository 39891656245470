import { Toast } from '@byecode/ui'
import type { AppendParams } from '@lighthouse/shared'
import { useAtomAction, useAtomAsyncAction, useAtomData, useHandleAbortPrevious } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import React, { useCallback, useEffect, useRef } from 'react'
import { useParams } from 'react-router-dom'

import { fetchDataSourceAtom, setCurrentDataSourceIdAtom } from '@/atoms/dataSource/action'
import { viewIndependentDataAtom } from '@/atoms/dataSource/state'
import { filterCacheAtomFamily, sortsCacheAtomFamily } from '@/atoms/storage/state'
import { DataSourceContent } from '@/components/DataSourceContent'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useDataSource, useDataSourceEnvId } from '@/hooks/useDataSource'
import * as srv from '@/services'

export interface DataSourcePreviewerProps {}

export const DataSourcePreviewer: React.FC<DataSourcePreviewerProps> = () => {
    const dataSourceIdRef = useRef('')
    const { dataSourceId = '' } = useParams()
    const currentAppId = useCurrentAppID()
    const envId = useDataSourceEnvId()
    const filter = useAtomValue(filterCacheAtomFamily({ appId: currentAppId, envId, id: dataSourceId }))
    const sorts = useAtomValue(sortsCacheAtomFamily({ appId: currentAppId, envId, id: dataSourceId }))
    // const sortRef = useRef<Sorter[] | undefined>()
    const { run: setCurrentDataSourceId } = useAtomAction(setCurrentDataSourceIdAtom)
    const dataSourceData = useDataSource(currentAppId, envId, dataSourceId)
    const viewIndependentData = useAtomData(viewIndependentDataAtom, useCallback(s => s[dataSourceId] || {}, [dataSourceId]))
    // TODO: @KeLin、JinBang，这里的逻辑其他地方也要注意一下，useAtomAction 和 useAtomAsyncAction 俩的封装规整
    const { run: fetchDataSource, loading } = useAtomAsyncAction(fetchDataSourceAtom)
    const { run: fetchSilentDataSource } = useAtomAsyncAction(fetchDataSourceAtom)
    const { mutation } = useHandleAbortPrevious(fetchSilentDataSource)
    const onDataAppended = useCallback(
        async (params: AppendParams) => {
            const { dsId } = params.sheetDto
            const isAppend = await srv.appendDataToDataSource(params)
            isAppend && Toast.success('成功追加导入到数据源')
            if (dsId === dataSourceId) {
                fetchDataSource({
                    envId,
                    dsId: dataSourceId,
                    pagination: { currentPage: 1, pageSize: 100 },
                    filter,
                    sorts
                })
            }
            return isAppend
        },
        [dataSourceId, envId, fetchDataSource, filter, sorts]
    )

    useEffect(() => {
        if (dataSourceId) {
            setCurrentDataSourceId({ dsId: dataSourceId })
            fetchDataSource({
                envId,
                dsId: dataSourceId,
                pagination: { currentPage: 1, pageSize: 100 },
                filter,
                sorts
            })
                .then(resolve => {
                    dataSourceIdRef.current = dataSourceId
                })
                .catch(console.warn)
        }
    }, [currentAppId, dataSourceId, envId, fetchDataSource, filter, setCurrentDataSourceId, sorts])

    if (!dataSourceData) {
        return null
    }

    return (
        <DataSourceContent
            appId={currentAppId}
            envId={envId}
            id={dataSourceId}
            data={dataSourceData}
            viewIndependentData={viewIndependentData}
            filter={filter}
            sorts={sorts}
            loading={loading || dataSourceIdRef.current !== dataSourceId}
            onDataAppended={onDataAppended}
        />
    )
}
