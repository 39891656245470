import { SegmentedControl } from '@byecode/ui'
import React, { useState } from 'react'
import styled from 'styled-components'

import type { BlockTreeItem } from '@/constants/Block/type'

import { BlockList } from '../BlockList'
import { BLOCK_OPTIONS } from '../constant'
import { SyncBlockList } from '../SyncBlockList'
import { TemplateList } from '../TemplateList'

interface BlockCenterProps {
    onBack?: () => void
    onOpenQuestion?: (v: BlockTreeItem) => void
}

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`

const SCxItem = styled.div`
    width: 100%;
    flex: 1;
    overflow: hidden;
`

const BlockCenter: React.FunctionComponent<BlockCenterProps> = ({ ...rest }) => {
    const [tab, setTab] = useState('base')
    return (
        <SCxContainer>
            <div style={{ margin: '12px 12px 0 12px' }}>
                <SegmentedControl value={tab} fullWidth size="lg" onChange={setTab} data={BLOCK_OPTIONS} />
            </div>
            <SCxItem key="base" hidden={tab !== 'base'}>
                <BlockList {...rest} />
            </SCxItem>
            <SCxItem key="template" hidden={tab !== 'template'}>
                <TemplateList />
            </SCxItem>
            <SCxItem key="sync" hidden={tab !== 'sync'}>
                <SyncBlockList />
            </SCxItem>
        </SCxContainer>
    )
}

export default BlockCenter
