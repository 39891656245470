import { DatePicker, IconFont } from '@byecode/ui'
import {
    type DateConfig,
    type DateField as DateFieldConfig,
    type DateValue,
    type FieldInputADTValue,
    type FieldProtocol,
    DateFieldUnit
} from '@lighthouse/core'
import { Text } from '@mantine/core'
import { add, endOfDay, format, isValid, lightFormat, parse, parseISO, startOfDay } from 'date-fns'
import { clamp, max } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { dateUnitToMode } from '../../../../constants'
import { getDateUnitByFormat } from '../../../../utils'
import { minPopoverWidth } from '../../constant'
import type { FieldBaseProps } from '../../types'
import * as CM from '../commonStyles'
import { extractLunarDate, format2LunarDate, getRelativeDate, getTimestamp } from './help'

interface DateFieldProps extends FieldBaseProps {
    dateConfig: DateConfig
    value?: DateValue
    dateField?: DateFieldConfig | FieldProtocol
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 8px;
    font-size: var(--font-size-normal);
`

const SCxClear = styled(IconFont)`
    color: var(--color-gray-400);
    margin-right: 8px;
    cursor: pointer;

    &:hover {
        color: var(--color-gray-900);
    }
`

const SCxInput = styled.input`
    width: 100%;
    padding: 0 8px;
    height: 20px;
    line-height: 20px;

    ::placeholder {
        font-weight: var(--font-weight-normal);
        color: var(--color-gray-300);
    }
`

const SCxPreview = styled.div`
    width: 100%;
    padding: 8px 0;
    min-height: 38px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    * {
        line-height: 20px;
    }
`
const defaultFormat = 'yyyy-MM-dd HH:mm:ss'
const DateField: React.FC<DateFieldProps> = props => {
    const { readOnly, dateConfig, onCellChange, value = '', dateField, isControlled, language } = props

    const fieldType = dateField?.type || 'date'

    const { placeholder, date } = dateConfig ?? {}

    const { dateFormat, range = 'ALL', customDays } = date ?? {}
    const withLunarFormat = useMemo(() => {
        if (dateField?.type === 'date') {
            return (dateField as DateFieldConfig).date.format ?? defaultFormat
        }

        if (dateField?.type === 'text') {
            return dateFormat ?? 'yyyy/MM/dd'
        }

        return defaultFormat
    }, [dateField, dateFormat])

    const [isLunar, finalFormat] = useMemo(() => {
        const res = withLunarFormat.split('lunar-')

        return [res.length > 1, res[res.length - 1]]
    }, [withLunarFormat])

    const { unit } = useMemo(() => getDateUnitByFormat(finalFormat), [finalFormat])

    const panelMode = useMemo(() => {
        return dateUnitToMode[unit]
    }, [unit])

    const timestamp = useMemo(() => {
        if (fieldType === 'text') {
            if (!value) {
                return value
            }

            // 农历日期格式
            if (isLunar) {
                return extractLunarDate(value.toString())?.getTime() || ''
            }

            const date = parse(value.toString(), finalFormat, new Date())
            if (isValid(date)) {
                return date.getTime()
            }

            return ''
        }

        return getTimestamp(value)
    }, [fieldType, finalFormat, isLunar, value])

    const containerRef = useRef<HTMLDivElement>(null)

    const inputRef = useRef<HTMLInputElement>(null)

    const [data, setData] = useState<DateValue>(timestamp)

    const [isHover, setIsHover] = useState(false)

    const [opened, setOpened] = useState(false)

    const [isFocus, setIsFocus] = useState(false)

    const inputWidth = containerRef.current?.getBoundingClientRect()?.width ?? 0

    const popoverWidth = useMemo(() => clamp(minPopoverWidth, 380, inputWidth), [inputWidth])

    const usedValue = useMemo(() => {
        if (isControlled) {
            if (!value) {
                return value
            }
            if (fieldType === 'text') {
                // 农历日期格式
                if (isLunar) {
                    return extractLunarDate(value.toString())?.getTime() || ''
                }

                const date = parse(value.toString(), finalFormat, new Date())
                if (isValid(date)) {
                    return date.getTime()
                }

                return ''
            }
            return value
        }
        return data
    }, [data, fieldType, finalFormat, isControlled, isLunar, value])

    const [minDate, maxDate] = useMemo(() => {
        switch (range) {
            case 'AFTER_TODAY': {
                return [startOfDay(new Date()), undefined]
            }
            case 'BEFORE_TODAY': {
                return [undefined, endOfDay(new Date())]
            }
            case 'AFTER_CUSTOM': {
                return [
                    customDays
                        ? add(startOfDay(new Date()), {
                              days: max(customDays, 0)
                          })
                        : undefined,
                    undefined
                ]
            }
            default: {
                return []
            }
        }
    }, [customDays, range])

    const handleChange = useCallback(
        (date?: Date) => {
            const newDate = date?.valueOf()
            setData(newDate ?? '')
            setIsHover(false)
            inputRef.current?.blur()

            if (fieldType === 'text') {
                if (!date) {
                    onCellChange?.({ type: 'text', value: '' })
                    return
                }

                if (isLunar) {
                    onCellChange?.({
                        type: 'text',
                        value: format2LunarDate(date, finalFormat)
                    })

                    return
                }
                onCellChange?.({ type: 'text', value: lightFormat(date, finalFormat) })
            } else {
                onCellChange?.({ type: 'date', value: newDate ?? '' } as FieldInputADTValue)
            }
        },
        [fieldType, finalFormat, isLunar, onCellChange]
    )

    const valueDate = useMemo(() => {
        const currentValue = usedValue ? new Date(usedValue) : undefined
        if (!currentValue) {
            return
        }
        return isValid(currentValue) ? currentValue : undefined
    }, [usedValue])

    const handleClear = useCallback(() => {
        setData('')

        onCellChange?.({ type: fieldType, value: '' } as FieldInputADTValue)
    }, [fieldType, onCellChange])

    useEffect(() => {
        if (opened && inputRef.current) {
            inputRef.current.focus()
        }
    }, [inputRef, opened])

    return (
        <SCxContainer
            // data-field-border={opened}
            onMouseEnter={() => {
                !readOnly && setIsHover(true)
            }}
            onMouseLeave={() => {
                setIsHover(false)
            }}
            ref={containerRef}
        >
            <DatePicker
                popoverProps={{
                    width: popoverWidth,
                    onChange: setOpened,
                    offsetOptions: {
                        mainAxis: 10,
                        crossAxis: -2
                    },
                    returnFocus: false
                }}
                minDate={minDate}
                maxDate={maxDate}
                inputRender={({ date, value, onChange }) => {
                    const currentTimestamp = parseISO(format(Date.now(), defaultFormat)).valueOf()

                    const text = date ? (isLunar ? format2LunarDate(date, finalFormat) : value) : value

                    const suffixText =
                        date && !isLunar && unit === DateFieldUnit.DAY ? getRelativeDate(date, currentTimestamp, language) : ''

                    return (
                        <SCxPreview onClick={() => setIsFocus(true)} onBlur={() => setIsFocus(false)}>
                            {isFocus ? (
                                <SCxInput ref={inputRef} disabled={readOnly} value={text} onChange={onChange} placeholder={placeholder} />
                            ) : (
                                <Text style={{ padding: ' 0 8px' }} color={date ? 'var(--color-black)' : 'var(--color-gray-300)'}>
                                    {text || placeholder}
                                    {`${suffixText}`}
                                </Text>
                            )}
                        </SCxPreview>
                    )
                }}
                disabled={readOnly}
                format={finalFormat}
                mode={panelMode}
                showTime={unit === DateFieldUnit.MINUTE || unit === DateFieldUnit.DAY_MINUTE}
                disableSecond
                placeholder={placeholder}
                value={valueDate}
                onChange={handleChange}
            />
            <CM.IconWrapper>
                {usedValue && (opened || isHover) && <SCxClear type="CloseCircle" size={18} onClick={handleClear} />}
                {!readOnly && <IconFont style={{ marginRight: 4 }} type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />}
            </CM.IconWrapper>
        </SCxContainer>
    )
}

export default DateField
