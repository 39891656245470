import type { ChartLikeProtocol, PieChartOptionProtocol } from '@lighthouse/core'

import type { ECOption } from '../../components'
import { defaultColors, legendOffset, legendWidth } from './common'

const getFunnelCommonOption = (params: PieChartOptionProtocol) => {
    const { showLegend, colors, showHeadTitle, title } = params
    const commonOption: Pick<ECOption, 'color' | 'legend' | 'title'> = {
        color: colors?.length > 0 ? colors : defaultColors,
        legend: {
            show: showLegend
        },
        title: {
            text: showHeadTitle && title ? title : undefined,
            textStyle: {
                fontSize: 24,
                fontWeight: 500,
                color: '#101828'
            }
        }
    }
    return commonOption
}

export const getPieLegend = (params: PieChartOptionProtocol, chartData: ChartLikeProtocol): ECOption['legend'] => {
    const { showLegend, legendTranspose, dimensions } = params
    const { legend, xAxis } = chartData
    const data = dimensions.length > 0 ? xAxis.map(item => item[0]).filter(Boolean) : legend
    const legendConfigurator: ECOption['legend'] = {
        type: 'scroll',
        show: showLegend,
        data,
        bottom: legendOffset,
        icon: 'circle'
    }
    switch (legendTranspose) {
        case 'top': {
            legendConfigurator.top = legendOffset
            break
        }
        case 'bottom': {
            legendConfigurator.bottom = legendOffset
            break
        }
        case 'left': {
            legendConfigurator.left = legendOffset
            legendConfigurator.top = 'center'
            legendConfigurator.width = legendWidth
            legendConfigurator.orient = 'vertical'
            break
        }
        case 'right': {
            legendConfigurator.right = legendOffset
            legendConfigurator.width = legendWidth
            legendConfigurator.top = 'center'
            legendConfigurator.orient = 'vertical'
            break
        }
        default: {
            break
        }
    }

    return legendConfigurator
}

const getSeries = (chartData: ChartLikeProtocol): ECOption['series'] => {
    const { series, xAxis, legend } = chartData
    if (series.length === 0) {
        return {}
    }

    const [seriesName] = legend

    const getSeriesData = () => {
        if (series.length === 0) {
            return []
        }
        const [firstSeries] = series
        const { value: seriesValue } = firstSeries
        if (seriesValue.length > 1) {
            return xAxis.map((item, index) => {
                const value = Number(seriesValue?.[index] || 0)
                return { value, name: item[0] || '空' }
            })
        }
        if (seriesValue.length === 1) {
            return series.map(item => ({
                value: Number(item.value?.[0] || 0),
                name: item.name
            }))
        }
        return series.map(item => ({
            value: Number(item.value?.[0] || 0),
            name: item.name
        }))
    }

    return {
        name: seriesName,
        type: 'funnel',
        data: getSeriesData(),
        left: '10%',
        top: 60,
        bottom: 60,
        width: '80%',
        gap: 2,
        emphasis: {
            focus: 'self'
        },
        blur: {
            itemStyle: {
                opacity: 0.6
            }
        }
    }
}

const getFunnelOption = (params: PieChartOptionProtocol, chartData?: ChartLikeProtocol, themeColors?: string[]) => {
    if (!chartData) {
        return {}
    }
    const commonOption = getFunnelCommonOption(params)
    const mergedOption: ECOption = {
        tooltip: {
            show: true,
            formatter: params => {
                const param = Array.isArray(params) ? params[0] : params
                const { marker, name, value, percent, seriesName } = param
                return `<div style="margin-bottom: 12px; font-weight: bold">
                            ${name || '空'}
                        </div>
                        ${marker} ${seriesName}：${String(value)} (${percent}%)`
            }
        },
        ...commonOption,
        legend: getPieLegend(params, chartData),
        series: getSeries(chartData)
    }
    return mergedOption
}

export default getFunnelOption
