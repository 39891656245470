import { Divider, Group } from '@byecode/ui'
import type { EVENT_VARIABLE_TYPE, SubProcessArg } from '@lighthouse/core'
import type { BaseFlowNode, CurrPageDatasourceForVariable, PrevPageDatasourceForVariable, VariableTriggerConfig } from '@lighthouse/shared'
import { CollapseBox, useAtomAsyncAction, useAtomData } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { fetchWorkflowListAtom } from '@/atoms/workflow/action'
import { workflowListAtom } from '@/atoms/workflow/state'
import { SettingSubProcessContent } from '@/containers/FlowDetail/components/NodeSetting/Actions/component/SettingSubProcessContent'
import { SubProcessSelector } from '@/containers/FlowDetail/components/NodeSetting/Actions/component/SubProcessSelector'

import * as SC from './styles'

interface WorkflowConfigureProps {
    prefixName: string
    eventType?: EVENT_VARIABLE_TYPE
    curr?: CurrPageDatasourceForVariable
    prev?: PrevPageDatasourceForVariable
}

export const WorkflowConfigure: React.FC<WorkflowConfigureProps> = ({ prefixName, eventType, curr, prev }) => {
    const { control } = useFormContext()
    const workflows = useAtomData(workflowListAtom)
    const { run: updateWorkflow } = useAtomAsyncAction(fetchWorkflowListAtom)
    const subProcessId = useWatch({ control, name: `${prefixName}.subProcessId` })

    const handleFetchWorkflows = useCallback(() => {
        return updateWorkflow()
    }, [updateWorkflow])

    const subProcessArgs = useMemo(() => {
        const workflow = find(item => item.id === subProcessId && item.type === 'subProcess', workflows)
        if (!workflow) {
            return
        }
        const nodeList = workflow.content?.nodes || []
        const variableNode = find(item => item.data.nodeType === 'ARG_TRIGGER', nodeList) as BaseFlowNode<VariableTriggerConfig>
        if (!variableNode) {
            return
        }
        const { config } = variableNode.data
        if (!config || !config?.args) {
            return
        }
        return config.args
    }, [subProcessId, workflows])

    return (
        <>
            <Controller
                name={`${prefixName}.subProcessId`}
                control={control}
                render={({ field }) => (
                    <SubProcessSelector
                        workflows={workflows}
                        value={field.value}
                        onFetchWorkflows={handleFetchWorkflows}
                        onChange={val => {
                            field.onChange?.(val)
                        }}
                    />
                )}
            />
            {subProcessId && (
                <>
                    <Divider my={12} />
                    <Group label="子流程参数值设置">
                        <SC.Content>
                            <SC.Tip>设置子流程的参数值，参数值将会传递到子流程中。</SC.Tip>
                            {/* <Controller
                                name={`${prefixName}.args`}
                                control={control}
                                render={({ field }) => {
                                    return (
                                        <SettingSubProcessContent
                                            data={field.value}
                                            prefixName={prefixName}
                                            // parentNodes={allSingeParentNode}
                                            // allParentNodes={allParentNodes}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            /> */}
                            <Controller
                                name={`${prefixName}.args`}
                                control={control}
                                render={({ field }) => {
                                    const list = (field.value || []) as SubProcessArg[]
                                    const data =
                                        subProcessArgs?.map(item => {
                                            const dataItem = find(d => d.id === item.id, list)
                                            return {
                                                ...item,
                                                value: dataItem?.value
                                            }
                                        }) || []
                                    return (
                                        <SettingSubProcessContent
                                            data={data}
                                            eventType={eventType}
                                            curr={curr}
                                            prev={prev}
                                            // currentNodeFields={currentNodeFields}
                                            onChange={field.onChange}
                                        />
                                    )
                                }}
                            />
                        </SC.Content>
                    </Group>
                </>
            )}
        </>
    )
}
