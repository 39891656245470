import { Divider, Slider, Switch } from '@byecode/ui'
import { type FilterBlockAbstract, FilterBlockMode } from '@lighthouse/core'
import { Group4ByecodeUi, ListItem4ByecodeUi, ModeSegmented } from '@lighthouse/shared'
import React, { useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'
import { TABS } from './constants'
import { FilterList } from './FilterList'

const SCxHeader = styled.div`
    padding: 12px 16px;
`

const SCxPopupTitle = styled.div`
    margin-bottom: 8px;
    height: 36px;
    font-weight: 600;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--color-black);
`

interface FilterSettingProps {
    blockId: string
}

export const FilterSetting: React.FC<FilterSettingProps> = ({ blockId }) => {
    const designProps = getBlockAndPageDesignLimit('filter')
    const { control } = useFormContext<FilterBlockAbstract['config']>()
    const mode = useWatch({ control, name: 'mode' })
    const showLabel = useWatch({ control, name: 'showLabel' })

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <SCxHeader>
                        <SCxPopupTitle>筛选模式</SCxPopupTitle>
                        <Controller
                            control={control}
                            name="mode"
                            render={({ field: { value, onChange } }) => <ModeSegmented options={TABS} value={value} onChange={onChange} />}
                        />
                    </SCxHeader>
                    <Divider color="var(--color-gray-200)" />
                    <Group4ByecodeUi label="筛选项">
                        <FilterList blockId={blockId} />
                    </Group4ByecodeUi>

                    {mode === FilterBlockMode.tile && (
                        <>
                            <Divider color="var(--color-gray-200)" />
                            <Group4ByecodeUi label="设计">
                                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                                    <div>显示标题</div>
                                    <Controller
                                        control={control}
                                        name="showLabel"
                                        render={({ field: { value, onChange } }) => {
                                            return <Switch checked={value} onChange={onChange} />
                                        }}
                                    />
                                </ListItem4ByecodeUi>
                                {showLabel && (
                                    <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                                        <div>标题宽度</div>
                                        <Controller
                                            control={control}
                                            name="labelWidth"
                                            render={({ field }) => (
                                                <Slider
                                                    min={1}
                                                    max={400}
                                                    step={1}
                                                    value={field.value ?? 80}
                                                    onChange={field.onChange}
                                                    style={{ width: 180 }}
                                                    inputProps={{
                                                        onFocus: e => {
                                                            e.target.select()
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    </ListItem4ByecodeUi>
                                )}
                            </Group4ByecodeUi>
                        </>
                    )}
                    {/* FilterWay */}
                    <Divider color="var(--color-gray-200)" />
                    <VisibilitySetting />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}
        </>
    )
}
