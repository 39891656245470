import { Input, pointer } from '@byecode/ui'
import type { Size } from '@lighthouse/shared'
import type { CSSProperties } from 'react'
import React, { useCallback, useState } from 'react'
import styled, { css } from 'styled-components'

import type { Options } from './types'

const SCxContainer = styled.div``

const SCxChipWrapper = styled.div<{ justifyContent: CSSProperties['justifyContent'] }>`
    display: flex;
    flex-direction: row;
    flex-flow: row wrap;
    justify-content: ${({ justifyContent }) => justifyContent};
    gap: 20px;
`

const SCxChip = styled.div<{ active?: boolean; size?: Size }>`
    border-width: 2px;
    border-style: solid;
    display: flex;
    justify-content: center;
    align-items: center;
    ${pointer}

    ${({ active }) =>
        active
            ? css`
                  border-color: var(--color-main);
                  color: var(--color-white);
                  background-color: var(--color-main);
              `
            : css`
                  border-color: var(--color-gray-200);
                  color: var(--color-gray-900);
                  background-color: var(--color-white);
              `}

    ${({ size }) =>
        size === 'large'
            ? css`
                  width: 140px;
                  height: 56px;
                  border-radius: 100px;
                  font-size: 20px;
              `
            : css`
                  padding: 12px 16px;
                  border-radius: 100px;
                  font-size: 20px;
              `}
`

const SCxTitle = styled.div`
    margin-bottom: 16px;
    margin-top: 40px;
`

const SCxLabel = styled.span`
    color: var(--color-gray-900);
    font-size: 20px;
`

interface ChipSelectProps {
    name?: string
    label?: string
    value: string
    options: Options
    size?: Size
    justifyContent?: CSSProperties['justifyContent']
    onChange: (val: string) => void
}

export const ChipSelect: React.FC<ChipSelectProps> = ({
    name,
    label,
    value: selectValue,
    options,
    justifyContent = 'flex-start',
    size,
    onChange
}) => {
    const [value, setValue] = useState(selectValue)
    const [isShowInput, setIsShowInput] = useState(false)
    const handleChange = useCallback(
        (val: string) => {
            setValue(val)
            // 工作职位
            if (name === 'job' && val === '其他') {
                setIsShowInput(true)
                onChange('')
                return
            }

            // 渠道
            if (name === 'source' && val === '其他') {
                setIsShowInput(true)
                onChange('')
                return
            }
            setIsShowInput(false)
            onChange(val)
        },
        [name, onChange]
    )

    const handleInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const val = e.target.value
            onChange(val.trim())
        },
        [onChange]
    )

    return (
        <SCxContainer>
            <SCxChipWrapper justifyContent={justifyContent}>
                {options.map(item => {
                    const { value: v, icon, label } = item
                    return (
                        <SCxChip active={v === value} key={v} size={size} onClick={() => handleChange(v)}>
                            {icon}
                            {label}
                        </SCxChip>
                    )
                })}
            </SCxChipWrapper>
            {isShowInput && (
                <>
                    <SCxTitle>
                        <SCxLabel>{label}</SCxLabel>
                    </SCxTitle>
                    <Input placeholder="请输入" radius="md" onChange={handleInputChange} size="lg" required />
                </>
            )}
        </SCxContainer>
    )
}
