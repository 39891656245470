import type { ChangeEvent } from 'react'
import React, { forwardRef, useCallback, useId } from 'react'

import { useComponentConfig } from '../../hooks/useComponentConfig'
import { useUncontrolled } from '../../hooks/useUncontrolled'
import { Box } from '../Box'
import { useStyles } from './styles'
import type { SwitchProps } from './types'

export const Switch = forwardRef<HTMLInputElement, SwitchProps>(
    ({ unstyled, styles, classNames, size = 'md', color, checked, disabled: _disabled, defaultChecked, onChange, ...rest }, ref) => {
        const { disabled } = useComponentConfig('switch', { disabled: _disabled })
        const [_value, handleChange] = useUncontrolled({ value: checked, defaultValue: defaultChecked ?? false })

        const { classes } = useStyles({ disabled, checked: _value, color, size }, { name: 'Switch', unstyled, classNames, styles })
        const id = useId()

        const handleCheckedChange = useCallback(
            (ev: ChangeEvent<HTMLInputElement>) => {
                handleChange(ev.target.checked)

                onChange?.(ev)
            },
            [handleChange, onChange]
        )

        return (
            <Box className={classes.wrapper} {...rest} ref={ref}>
                <input
                    className={classes.input}
                    disabled={disabled}
                    type="checkbox"
                    id={id}
                    checked={_value}
                    onChange={handleCheckedChange}
                />
                <label className={classes.track} htmlFor={id}>
                    <Box className={classes.thumb} />
                </label>
            </Box>
        )
    }
)
