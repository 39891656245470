import { Button, IconFont } from '@byecode/ui'
import { SelectedMode } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useLanguageContext } from '../../contexts'

const SCxContainer = styled.div`
    width: fit-content;
    padding: 8px 12px;
    background-color: var(--color-gray-700);
    box-shadow: 0px 4px 12px 0px #1018281a;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 28px;
    font-size: var(--font-size-normal);
    color: var(--color-white);
    border-radius: 8px;
`

const SCxSelectCount = styled.div`
    white-space: nowrap;
    opacity: 0.6;
`

const SCxContent = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 8px;
`

const SCxGroup = styled.div`
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 2px 4px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
        background-color: #ffffff20;
    }
`

const SCxButton = styled(Button)`
    color: var(--color-white);
`

const SCxGroupIcon = styled(IconFont)``

const SCxGroupText = styled.div`
    white-space: nowrap;
`

const SCxClose = styled(IconFont)`
    cursor: pointer;
    &:hover {
        opacity: 0.9;
    }
`

interface SuspendPaginationProps {
    selectIds?: string[]
    total?: number
    mode?: SelectedMode
    enablePrint?: boolean
    enableExport?: boolean
    enableDelete?: boolean
    onExport?: () => void
    onPrint?: () => void
    onDelete?: () => void
    onClose?: () => void
}

export const SuspendPagination: React.FC<SuspendPaginationProps> = ({
    selectIds,
    total,
    mode,
    enablePrint,
    enableExport,
    enableDelete,
    onExport,
    onPrint,
    onDelete,
    onClose
}) => {
    const { convertTextByLanguage, language } = useLanguageContext()
    const selectText = useMemo(() => {
        if (mode === SelectedMode.ALL) {
            return total ?? 0
        }
        return selectIds?.length ?? 0
    }, [mode, selectIds?.length, total])

    return (
        <SCxContainer>
            <SCxSelectCount>{convertTextByLanguage('linesTotal', { count: selectText })}</SCxSelectCount>
            <SCxContent>
                {enablePrint && (
                    <SCxGroup onClick={onPrint}>
                        <SCxGroupIcon size={16} fill="var(--color-gray-200)" type="Print" />
                        <SCxGroupText>{convertTextByLanguage('print')}</SCxGroupText>
                    </SCxGroup>
                )}
                {enableExport && (
                    <SCxGroup onClick={onExport}>
                        <SCxGroupIcon size={16} fill="var(--color-gray-200)" type="ImportExcel" />
                        <SCxGroupText>{convertTextByLanguage('export')}</SCxGroupText>
                    </SCxGroup>
                )}
                {enableDelete && (
                    <SCxGroup onClick={onDelete}>
                        <SCxGroupIcon size={16} fill="var(--color-gray-200)" type="Trash" />
                        <SCxGroupText>{convertTextByLanguage('delete')}</SCxGroupText>
                    </SCxGroup>
                )}
            </SCxContent>
            <SCxClose type="Close" size={16} onClick={onClose} />
        </SCxContainer>
    )
}
