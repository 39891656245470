import { Divider, Group } from '@byecode/ui'
import type { IframeBlockAbstract } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { getBlockAndPageDesignLimit, PositionSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'
import { IframeInput } from './IframeInput'

interface IframeSettingProps {}

export const IframeSetting: React.FC<IframeSettingProps> = () => {
    const { control } = useFormContext<IframeBlockAbstract['config']>()
    const designProps = getBlockAndPageDesignLimit('iframe')
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    return (
        <>
            <BlockConfigureSegmentedControl isInterAction value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="网页链接">
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Controller
                                control={control}
                                name="source"
                                render={({ field }) => <IframeInput {...field} placeholder="请输入" />}
                            />
                        </ListItem4ByecodeUi>
                    </Group>
                    <Divider />
                    <VisibilitySetting />
                </>
            )}
        </>
    )
}
