import clsx from 'clsx'
import { addMonths } from 'date-fns'
import React, { forwardRef, useEffect, useMemo, useState } from 'react'

import { useUncontrolled } from '../../hooks/useUncontrolled'
import type { StyleComponentProps } from '../../theme/types'
import { Box } from '../Box'
import { useStyles } from './Calendar.style'
import type { BaseCalendarProps, CalendarSettingsProps, CalendarStylesName, PanelView } from './Calendar.type'
import { CalendarFooter } from './CalendarFooter'
import { CalendarHeader } from './CalendarHeader'
import { DayCalendar } from './DayCalendar'
import { MonthCalendar } from './MonthCalendar'
import { TimeCalendar } from './TimeCalendar'
import { YearCalendar } from './YearCalendar'

export { useCalendarDays } from './DayCalendar/DayCalendar.hook'

export interface CalendarProps
    extends BaseCalendarProps,
        CalendarSettingsProps,
        StyleComponentProps<CalendarStylesName>,
        React.ComponentPropsWithoutRef<'div'> {}

export const Calendar = forwardRef<HTMLDivElement, CalendarProps>((props, ref) => {
    const {
        className,
        classNames,
        styles,
        unstyled,

        // control props
        disabled,
        date: propDate,
        defaultDate,
        minDate,
        maxDate,
        onDateChange,
        panelDate,
        defaultPanelDate,
        onPanelDateChange,
        mode = 'day',

        // settings props
        dayRowsCount,
        firstDayOfWeek,
        weekFormat,
        showTime,
        disableSecond,
        hideNow,
        hideHeader,
        hideFooter,
        HeaderComponent,
        FooterComponent,
        CellComponent,
        onPrevYear,
        onPrevMonth,
        onNextMonth,
        onNextYear,
        onConfirm,
        onCellMouseEnter,
        onCellMouseLeave,
        ...rest
    } = props

    const { classes } = useStyles({}, { name: 'Calendar', classNames, styles, unstyled })

    const [panelView, setPanelView] = useState<PanelView>(mode)

    const [date, setDate] = useUncontrolled({
        value: propDate,
        defaultValue: defaultDate,
        // finalValue: new Date(),
        onChange: onDateChange
    })

    const [panelViewDate, setPanelViewDate] = useUncontrolled({
        value: panelDate,
        defaultValue: defaultPanelDate,
        finalValue: new Date(),
        onChange: onPanelDateChange
    })

    useEffect(() => {
        if (date) {
            setPanelViewDate(date)
        }
    }, [date, setPanelViewDate])

    const renderHeader = useMemo(() => {
        if (hideHeader) {
            return null
        }
        if (HeaderComponent) {
            return <HeaderComponent panelView={panelView} date={date} panelViewDate={panelViewDate} onPanelViewDate={setPanelViewDate} />
        }

        return (
            <CalendarHeader
                styles={styles}
                date={panelViewDate}
                panelView={panelView}
                onChangePanelView={setPanelView}
                onPrevYear={d => {
                    onPrevYear?.(d)
                    setPanelViewDate(addMonths(panelViewDate, -12))
                }}
                onPrevMonth={d => {
                    onPrevMonth?.(d)
                    setPanelViewDate(addMonths(panelViewDate, -1))
                }}
                onNextYear={d => {
                    onNextYear?.(d)
                    setPanelViewDate(addMonths(panelViewDate, 12))
                }}
                onNextMonth={d => {
                    onNextMonth?.(d)
                    setPanelViewDate(addMonths(panelViewDate, 1))
                }}
                onChangePanelDate={setPanelViewDate}
            />
        )
    }, [
        hideHeader,
        HeaderComponent,
        styles,
        panelViewDate,
        panelView,
        setPanelViewDate,
        date,
        onPrevYear,
        onPrevMonth,
        onNextYear,
        onNextMonth
    ])

    const renderFooter = useMemo(() => {
        if (hideFooter) {
            return null
        }

        if (FooterComponent) {
            return <FooterComponent panelView={panelView} date={date} panelViewDate={panelViewDate} />
        }

        if (panelView === 'day' || panelView === 'time' || mode === 'month' || mode === 'year') {
            return (
                <CalendarFooter
                    styles={styles}
                    panelView={panelView}
                    mode={mode}
                    showTime={showTime}
                    hideNow={hideNow}
                    onClear={() => {
                        setDate(undefined)
                    }}
                    onClickNow={() => {
                        const now = new Date()
                        setDate(now)
                        //   setPanelViewDate(now)
                    }}
                    onClickShowTime={() => setPanelView('time')}
                    onClickShowDay={() => setPanelView('day')}
                    onConfirm={() => onConfirm?.(date)}
                />
            )
        }

        return null
    }, [hideFooter, FooterComponent, panelView, mode, date, panelViewDate, styles, showTime, hideNow, setDate, onConfirm])

    return (
        <Box ref={ref} className={clsx(className, classes.calendar)} {...rest}>
            {renderHeader}

            {/* 选择日期面板 */}
            {panelView === 'day' && (
                <DayCalendar
                    styles={styles}
                    disabled={disabled}
                    date={date}
                    onSelect={setDate}
                    minDate={minDate}
                    maxDate={maxDate}
                    panelViewDate={panelViewDate}
                    CellComponent={CellComponent}
                    onCellMouseEnter={onCellMouseEnter}
                    onCellMouseLeave={onCellMouseLeave}
                    weekFormat={weekFormat}
                    dayRowsCount={dayRowsCount}
                    firstDayOfWeek={firstDayOfWeek}
                />
            )}

            {/* 选择时间面板 */}
            {panelView === 'time' && showTime && (
                <TimeCalendar disabled={disabled} disableSecond={disableSecond} date={date} onChange={setDate} />
            )}

            {/* 选择月份面板 */}
            {panelView === 'month' && (
                <MonthCalendar
                    styles={styles}
                    disabled={disabled}
                    date={date}
                    onSelect={d => {
                        setDate(d)
                        if (mode === 'day') {
                            setPanelView('day')
                        }
                    }}
                    onCellMouseEnter={onCellMouseEnter}
                    onCellMouseLeave={onCellMouseLeave}
                    panelViewDate={panelViewDate}
                    CellComponent={CellComponent}
                />
            )}

            {/* 选择年份面板 */}
            {panelView === 'year' && (
                <YearCalendar
                    styles={styles}
                    disabled={disabled}
                    date={date}
                    onSelect={d => {
                        setDate(d)
                        if (mode === 'day') {
                            setPanelView('day')
                        }
                        if (mode === 'month') {
                            setPanelView('month')
                        }
                    }}
                    onCellMouseEnter={onCellMouseEnter}
                    onCellMouseLeave={onCellMouseLeave}
                    panelViewDate={panelViewDate}
                    CellComponent={CellComponent}
                />
            )}

            {renderFooter}
        </Box>
    )
})
