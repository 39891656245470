import type { VariableADTvalue } from '@lighthouse/core'
import { DIRECTION } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import {
    combineBackgroundStyle,
    getBackgroundStyle,
    getBorderStyle,
    getLayoutStyle,
    getPaddingStyle,
    getRadiusStyle,
    getShadowStyle,
    getVeinsStyle,
    useApplicationContext,
    useContainerLayoutContext,
    useFillPickerContext,
    useFlowLayoutContext
} from '@lighthouse/shared'
import React, { forwardRef, useMemo } from 'react'
import styled from 'styled-components'

import { useCustomViewBlockContext } from '../../blocks'
import { EmptyContainer } from '../../components/EmptyContainer'
import { ContainerBlockProvider, useContainerBlockContext } from '../../context/ContainerBlockContext'
import { AnimationDecorators } from '../AnimationDecorators'

const Root = styled(AnimationDecorators)`
    /* flex: 1 0 0;
    width: 100%;
    height: 100%; */
    min-width: inherit;
    min-height: inherit;
`

interface ContainerLayoutProps extends React.ComponentPropsWithoutRef<'div'> {
    disabled?: boolean
    node: FlowLayoutNode
    extraSlot?: React.ReactNode
}
export const ContainerLayout = forwardRef<HTMLDivElement, ContainerLayoutProps>(
    ({ disabled: propDisabled, node, children, extraSlot, style, ...rest }, ref) => {
        const { appId } = useApplicationContext()
        const { isRootSyncNode, data, id } = node
        const { layout, size, design, animation } = data ?? {}
        const { scope: contextScope } = useContainerBlockContext()
        const { padding } = layout ?? {}
        const { overflow } = size ?? {}
        const { background, veins, border, shadow, blur, radius } = design ?? {}

        const { disabled: globalDisabled, parseBackgroundVariableImage } = useFlowLayoutContext()
        const disabled = propDisabled ?? globalDisabled
        const noChildren = !node.children || node.children.filter(v => v.type !== 'overlay').length === 0

        const { palettes } = useFillPickerContext()

        const { record } = useCustomViewBlockContext()

        const { layout: parentLayout } = useContainerLayoutContext()
        const parentDirection = parentLayout?.align?.direction
        const parseBackground = parseBackgroundVariableImage && ((v: VariableADTvalue) => parseBackgroundVariableImage(v, record))

        const containerScope = useMemo(
            () => ({
                scope: isRootSyncNode ? id : contextScope
            }),
            [contextScope, id, isRootSyncNode]
        )

        return (
            <Root
                ref={ref}
                blockId={id}
                animation={animation}
                style={{
                    ...style,
                    ...(parentDirection === DIRECTION.vertical
                        ? {
                              width: '100%',
                              flex: 1
                          }
                        : {
                              height: '100%',
                              flex: 1
                          }),

                    // ...getSizeStyle(size, parentLayoutCtx.layout?.align?.direction),
                    ...getPaddingStyle(padding),
                    ...getLayoutStyle(layout),
                    overflow,
                    backdropFilter: blur ? `blur(${blur}px)` : undefined,
                    ...combineBackgroundStyle([
                        getBackgroundStyle(appId, background, palettes, parseBackground),
                        getVeinsStyle(veins, palettes)
                    ]),
                    ...getRadiusStyle(radius),
                    ...getBorderStyle(border, palettes),
                    ...getShadowStyle(shadow, palettes)
                }}
                {...rest}
            >
                <ContainerBlockProvider value={containerScope}>
                    {children}
                    {!disabled && noChildren && <EmptyContainer />}
                    {extraSlot}
                </ContainerBlockProvider>
            </Root>
        )
    }
)
