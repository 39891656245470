import type { FloatingContext, Padding, Placement, ReferenceType, useInteractions } from '@floating-ui/react'
import type React from 'react'
import type { RefObject } from 'react'
import { createContext, useContext } from 'react'

export type PopoverWidth = 'target' | number | 'auto'
export type PopoverTrigger = 'hover' | 'click' | 'contextMenu'
export type PopoverOutsideEvent = "click" | "pointerdown" | "mousedown"
export interface PopoverContextValue {
    context: FloatingContext
    x: number
    y: number
    interactionProps: ReturnType<typeof useInteractions>
    opened: boolean
    reference: (node: ReferenceType) => void
    floating: (node: HTMLElement) => void
    width?: PopoverWidth
    withArrow?: boolean
    arrowRef: RefObject<SVGSVGElement>
    arrowSize: number
    arrowOffset: Padding
    trapFocus: boolean
    placement: Placement
    target?: HTMLElement | null
    withinPortal: boolean
    closeOnEscape: boolean
    zIndex: React.CSSProperties['zIndex']
    onClose?: () => void
    onToggle: () => void
    disabled: boolean
    returnFocus: boolean
    withinOverlay: boolean
    arrowColor?: string
}

const PopoverContext = createContext<null | PopoverContextValue>(null) as React.Context<PopoverContextValue>

export const PopoverProvider = PopoverContext.Provider

export const usePopoverContext = () => useContext(PopoverContext)
