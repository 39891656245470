import { useDismissEscape } from '@byecode/ui'
import type { FloatingContext, ReferenceType } from '@floating-ui/react'
import {
    autoUpdate,
    flip,
    FloatingNode,
    offset,
    shift,
    useFloating,
    useFloatingNodeId,
    useFloatingTree,
    useInteractions,
    useRole
} from '@floating-ui/react'
import type { FloatBlockConfig } from '@lighthouse/core'
import { useDebounce } from '@lighthouse/tools'
import { useSetAtom } from 'jotai'
import { findIndex } from 'rambda'
import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react'
import { useLatest, useUpdateEffect } from 'react-use'

import { PAGE_CONTAINER_HOST } from '../constants'
import { findPopoverTree, popoverListAtom, usePopoverTrigger } from '../hooks/usePopoverTrigger'
import { ApplicationPreviewEnum } from '../types'

export interface FloatBoxContextValue {
    context: FloatingContext
    x: number
    y: number
    opened: boolean
    disabled?: boolean
    blockId: string
    interactionProps: ReturnType<typeof useInteractions>
    floating: (node: HTMLDivElement) => void
    reference: (node: HTMLDivElement) => void
    onToggle: () => void
    onOpen: () => void
    onClose: () => void
}

export interface FloatBoxContextProps {
    opened: boolean
    config?: FloatBlockConfig
    blockId: string
    disableDismiss?: boolean
    disabled?: boolean
    isOpenToggle?: boolean
    disableEscape?: boolean
    parentFloatId?: string
    rootFloatTargetId?: string
    previewType: ApplicationPreviewEnum
    updateDragNode?: () => void
    onOpenChange: (v: boolean, blockId?: string) => void
    onClose: () => void
    onOpen: () => void
    onCloseAll?: () => void
    // checkRootChildren?: (id: string) => boolean
    children?: React.ReactNode
}

const FloatBoxContext = createContext<null | FloatBoxContextValue>(null) as React.Context<FloatBoxContextValue>

function isInViewport(viewDom: HTMLElement | Window, targetDom: HTMLElement): boolean {
    const viewRect =
        viewDom instanceof Window
            ? { top: 0, left: 0, right: window.innerWidth, bottom: window.innerHeight }
            : viewDom.getBoundingClientRect()

    const targetRect = targetDom.getBoundingClientRect()

    // 判断 targetDom 是否完全在 viewDom 的可视区域内
    const isInHorizontal = targetRect.left >= viewRect.left && targetRect.right <= viewRect.right
    const isInVertical = targetRect.top >= viewRect.top && targetRect.bottom <= viewRect.bottom

    return isInHorizontal && isInVertical
}

export const FloatBoxProvider: React.FC<FloatBoxContextProps> = ({
    config,
    blockId,
    opened,
    disableDismiss,
    previewType,
    disableEscape,
    parentFloatId,
    isOpenToggle = true,
    rootFloatTargetId,
    updateDragNode,
    disabled,
    onCloseAll,
    onClose,
    onOpen,
    onOpenChange,
    children
}) => {
    const { breakPoint } = config ?? {}
    const { overlay } = breakPoint ?? {}

    const setPopoverList = useSetAtom(popoverListAtom)

    const {
        showOn = 'click',
        dismiss: overlayDismiss = 'click',
        position = 'bottom',
        align = 'center',
        offset: overLayOffset,
        autoPosition,
        autoDistance = 0
    } = overlay ?? {}
    const limitRef = useLatest(autoDistance)
    const tree = useFloatingTree()
    const nodeId = useFloatingNodeId()
    const mainAxis = position === 'left' || position === 'right' ? overLayOffset?.[0] ?? 0 : overLayOffset?.[1] ?? 0
    const crossAxis = position === 'left' || position === 'right' ? overLayOffset?.[1] ?? 0 : overLayOffset?.[0] ?? 0

    const { context, refs, update, x, y, placement } = useFloating({
        nodeId,
        open: opened,
        onOpenChange: v => {
            onOpenChange(v, blockId)
        },
        placement: align === 'center' ? position : `${position}-${align}`,
        strategy: 'fixed',
        middleware: [
            offset({ mainAxis, crossAxis }),
            autoPosition && flip({ boundary: document.querySelector<HTMLElement>(`${PAGE_CONTAINER_HOST}`) || document.body }),
            autoPosition &&
                // shift({
                //     limiter: limitShift({
                //         offset: autoDistance,
                //     }),
                //     boundary: document.querySelector<HTMLElement>(pageTarget ?? '') || document.body
                // })
                shift({
                    limiter: {
                        fn: state => {
                            const { x, y, rects, elements } = state

                            // 获取自定义视窗 DOM 元素（默认使用 document.body）
                            const container = document.querySelector<HTMLElement>(`.applicationWrapper`) || document.body
                            const pageEle = document.querySelector<HTMLElement>(`${PAGE_CONTAINER_HOST}`)
                            if (!container || !pageEle) {
                                return state
                            }
                            const isRefInView = isInViewport(pageEle, elements.reference)
                            if (isRefInView) {
                                return state
                            }
                            const containerRect = container.getBoundingClientRect()
                            const pageRect = pageEle.getBoundingClientRect()
                            // 获取视口的宽度和高度
                            const viewWidth = pageRect.width
                            const viewHeight = pageRect.height
                            const viewY = previewType === ApplicationPreviewEnum.mobile ? containerRect.height - pageRect.height : 0
                            const viewX = 0

                            // 设置一个极限值
                            let limit = limitRef.current // 可根据需要调整该值

                            // 获取浮动元素的宽度和高度
                            const floatingWidth = rects.floating.width
                            const floatingHeight = rects.floating.height

                            // 获取容器的宽度和高度
                            const containerWidth = containerRect.width
                            const containerHeight = containerRect.height

                            const maxY = viewY + viewHeight - rects.floating.height - limit
                            const maxX = viewX + viewWidth - rects.floating.width - limit
                            const minY = viewY + limit
                            const minX = viewX + limit
                            // 动态计算 maxLimit：
                            // 基于浮动元素的宽度、高度和容器的可视区域宽度、高度来计算
                            const maxLimitX = (containerWidth - floatingWidth) / 2 // 容器宽度减去浮动元素宽度
                            const maxLimitY = (containerHeight - floatingHeight) / 2 // 容器高度减去浮动元素高度
                            const maxLimit = Math.min(maxLimitX, maxLimitY)

                            // 如果 limit 超过了最大 limit 值，则将其视为无效并重新设置为 0
                            if (limit > maxLimit) {
                                limit = 0 // 将 limit 设置为 0 或其他合适的默认值
                            }

                            // 判断 x 轴是否超出视口的左右边界
                            let correctedX = x

                            if (x < minX) {
                                correctedX = minX // 如果左边超出，设置为极限值
                            } else if (x > maxX) {
                                correctedX = maxX // 如果右边超出，设置为视口右侧极限值
                            }
                            // 判断 y 轴是否超出视口的上下边界
                            let correctedY = y
                            if (y < minY) {
                                correctedY = minY // 如果上边超出，设置为极限值
                            } else if (y > maxY) {
                                correctedY = maxY // 如果下边超出，设置为视口下侧极限值
                            }

                            // 防止 limit 过大导致位置异常
                            // correctedX = Math.max(limit, Math.min(correctedX, maxX))
                            // correctedY = Math.max(limit, Math.min(correctedY, maxY))

                            // 返回修正后的 x, y 坐标
                            return { x: correctedX, y: correctedY }
                        }
                    }
                })
        ],
        whileElementsMounted: autoUpdate
    })

    const role = useRole(context, { role: 'menu' })
    const trigger = usePopoverTrigger(context, {
        blockId,
        enabled: !disabled,
        enabledEscape: !disableEscape,
        openEvent: showOn,
        closeEvent: overlayDismiss,
        toggle: isOpenToggle,
        boundary: `${PAGE_CONTAINER_HOST}`
    })
    const escape = useDismissEscape(context, {
        enabled: !disableEscape
    })
    const interactionProps = useInteractions([role, escape, trigger])

    const onToggle = useCallback(() => {
        opened ? onClose() : onOpen()
    }, [onClose, onOpen, opened])

    useEffect(() => {
        if (!onCloseAll) {
            return
        }
        tree?.events.on('closeAll', onCloseAll)
        return () => {
            tree?.events.off('closeAll', onCloseAll)
        }
    }, [onCloseAll, tree])

    const debounceY = useDebounce(y, 200)

    useUpdateEffect(() => {
        update()
    }, [config, previewType])

    useUpdateEffect(() => {
        updateDragNode?.()
    }, [debounceY])

    useEffect(() => {
        setPopoverList(draft => {
            if (parentFloatId) {
                const tree = findPopoverTree(draft, parentFloatId)
                const index = findIndex(v => v.id === blockId, tree?.children ?? [])
                if (opened && tree) {
                    const newPopover = { id: blockId, reference: refs.reference, floating: refs.floating, open: opened, children: [] }
                    tree.children.push(newPopover)
                    return draft
                }
                if (index !== -1) {
                    tree?.children.splice(index, 1)
                }
                return draft
            }

            if (opened) {
                const newPopover = { id: blockId, reference: refs.reference, floating: refs.floating , open: opened, children: [] }
                draft.push(newPopover)
                return draft
            }
            const index = findIndex(v => v.id === blockId, draft)
            if (index !== -1) {
                draft.splice(index, 1)
            }
            return draft
        })
        return () => {
            // setPopoverList(draft => {
            //     const index = findIndex(v => v.id === blockId, draft)
            //     if (index !== -1) {
            //         draft.splice(index, draft.length - index)
            //     }
            //     return draft
            // })
        }
    }, [blockId, opened, parentFloatId, refs.floating, refs.reference, rootFloatTargetId, setPopoverList])

    const floating = useCallback(
        (node: HTMLDivElement) => {
            refs.setFloating(node)
        },
        [refs]
    )

    const reference = useCallback(
        (node: ReferenceType) => {
            refs.setReference(node)
        },
        [refs]
    )

    const provideValue = useMemo(
        () => ({ context, x, y, floating, reference, interactionProps, blockId, onToggle, onClose, onOpen, disabled, opened }),
        [context, x, y, floating, reference, interactionProps, blockId, onToggle, onClose, onOpen, disabled, opened]
    )
    return (
        <FloatBoxContext.Provider value={provideValue}>
            <FloatingNode id={nodeId}>{children}</FloatingNode>
        </FloatBoxContext.Provider>
    )
}

export const useFloatBoxContext = () => useContext(FloatBoxContext)
