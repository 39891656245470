import type { DataSourceAbstract, DateRangeSystemVariableValue, FilterBlockItemConfig, FilterOption } from '@lighthouse/core'
import { FilterWay, NumberRangeMode } from '@lighthouse/core'
import { dateRangeVariableSystemNameMap, USER_DATASOURCE } from '@lighthouse/shared'
import { useDebounce } from '@lighthouse/tools'
import equal from 'fast-deep-equal'
import { find } from 'rambda'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { CUSTOM_VALUE } from './constants'
import type { GetCascadeOptionPayload, GetTextOptionPayload } from './types'

type GetOptionPayload = {
    value?: string[]
    config: FilterBlockItemConfig
    dataSourceList: DataSourceAbstract[]
    recordId?: string
    onFetchTextOptions?: (params: GetTextOptionPayload) => Promise<FilterOption[]>
    onFetchCascadeOptions?: (params: GetCascadeOptionPayload) => Promise<FilterOption[]>
}

const boolOptions: FilterOption[] = [
    {
        label: '是',
        value: 'true'
    },
    {
        label: '否',
        value: 'false'
    }
]

export const useGetOption = (params: GetOptionPayload) => {
    const { value, config: paramsConfig, dataSourceList, recordId, onFetchTextOptions, onFetchCascadeOptions } = params
    const initValue = useRef(value)
    const config = useDebounce(paramsConfig, 500)
    const configRef = useRef<FilterBlockItemConfig | undefined>()
    const [loading, setLoading] = useState(false)
    const [RecordOptions, setRecordOptions] = useState<FilterOption[]>([])
    const getPersonRecordOptions = useCallback(
        async (params: GetTextOptionPayload) => {
            setLoading(true)
            const recordOptions = await onFetchTextOptions?.({ ...params })
            setLoading(false)
            if (recordOptions) {
                const optionValues = new Set(recordOptions.map(item => item.value))
                // optionValues
                const noExitValues = initValue.current?.filter(v => !optionValues.has(v)) || []
                const noExitOptions: FilterOption[] = noExitValues.map(v => ({
                    label: '不存在的用户',
                    value: v
                }))
                setRecordOptions([...recordOptions, ...noExitOptions])
            }
        },
        [onFetchTextOptions]
    )

    const getTextRecordOptions = useCallback(
        async (params: GetTextOptionPayload) => {
            setLoading(true)
            const recordOptions = await onFetchTextOptions?.({ ...params })
            setLoading(false)
            if (recordOptions) {
                const optionValues = new Set(recordOptions.map(item => item.value))
                // optionValues
                const noExitValues = initValue.current?.filter(v => !optionValues.has(v)) || []
                const noExitOptions: FilterOption[] = noExitValues.map(v => ({
                    label: v,
                    value: v
                }))
                setRecordOptions([...recordOptions, ...noExitOptions])
            }
        },
        [onFetchTextOptions]
    )

    const getRecordCascadeOptions = useCallback(
        async (params: GetCascadeOptionPayload) => {
            const { fieldPointer, parentFieldPointer, dsId, showFieldPointer } = params
            const isEmptyConfig = !fieldPointer || !parentFieldPointer || !dsId || !showFieldPointer
            if (isEmptyConfig) {
                return
            }
            setLoading(true)
            const options = await onFetchCascadeOptions?.(params)
            setLoading(false)
            options && setRecordOptions(options)
        },
        [onFetchCascadeOptions]
    )

    const handleFetchOption = useCallback(
        (config: FilterBlockItemConfig) => {
            if (config.filterWay === FilterWay.textFilter && config.pointer && config.fieldPointer && config.showFieldPointer) {
                getTextRecordOptions({
                    dsId: config.pointer,
                    fieldId: config.fieldPointer,
                    showFieldId: config.showFieldPointer,
                    filterItemId: config.id,
                    filter: config.filter,
                    sorts: config.sorts
                })
            }
            if (config.filterWay === FilterWay.personFilter) {
                getPersonRecordOptions({
                    dsId: USER_DATASOURCE,
                    fieldId: 'ID',
                    showFieldId: 'USERNAME',
                    filterItemId: config.id,
                    filter: config.filter,
                    sorts: config.sorts
                })
            }
            if (config.filterWay === FilterWay.cascadeFilter) {
                const { id, parentFieldPointer = '', fieldPointer = '', sortFieldPointer, pointer = '', showFieldPointer = '' } = config
                getRecordCascadeOptions({
                    dsId: pointer,
                    fieldPointer,
                    showFieldPointer,
                    parentFieldPointer,
                    sortFieldPointer,
                    filterItemId: id
                })
            }
        },
        [getTextRecordOptions, getPersonRecordOptions, getRecordCascadeOptions]
    )

    const onRetry = useCallback(() => {
        return handleFetchOption(config)
    }, [config, handleFetchOption])

    useEffect(() => {
        if (!config || equal(configRef.current, config)) {
            return
        }
        configRef.current = config
        handleFetchOption(config)
    }, [config, handleFetchOption])

    useUpdateEffect(() => {
        handleFetchOption(config)
    }, [recordId])

    const options: FilterOption[] = useMemo(() => {
        switch (config.filterWay) {
            case FilterWay.textFilter:
            case FilterWay.personFilter: {
                return RecordOptions
            }
            case FilterWay.cascadeFilter: {
                return RecordOptions
            }
            case FilterWay.numberFilter: {
                const validateNumberOptions = config.numberOptions?.filter(item => {
                    return item.label && item.value && (item.value[0] !== undefined || item.value[1] !== undefined)
                })
                const options =
                    validateNumberOptions?.map(v => ({
                        label: v.label,
                        value: v.id
                    })) || []
                if (config.numberRangeMode && config.numberRangeMode !== NumberRangeMode.disable) {
                    options.push({
                        label: '自定义',
                        value: CUSTOM_VALUE
                    })
                }
                return options
            }
            case FilterWay.dateFilter: {
                const options =
                    config.dateVariables?.map(v => ({
                        label: dateRangeVariableSystemNameMap[v as DateRangeSystemVariableValue],
                        value: v
                    })) || []
                if (config.isDateRange) {
                    options.push({
                        label: '自定义',
                        value: CUSTOM_VALUE
                    })
                }
                return options
            }
            case FilterWay.selectFilter: {
                const ds = find(item => item.id === config.pointer, dataSourceList)
                if (!ds) {
                    return []
                }
                const { schema } = ds
                const field = schema[config.fieldPointer || '']
                if (!field || field.type !== 'select') {
                    return []
                }
                const customOpts = config.customOptions
                return field.select.options.reduce<FilterOption[]>((prev, cur) => {
                    if (customOpts && customOpts.includes(cur.label)) {
                        prev.push({
                            label: cur.label,
                            value: cur.label
                        })
                        return prev
                    }
                    return prev
                }, [])
            }
            case FilterWay.boolFilter: {
                return boolOptions
            }
            default: {
                return []
            }
        }
    }, [RecordOptions, config, dataSourceList])

    return { options, loading, retry: onRetry }
}
