import type { DataSourceAbstract, Field, FieldBlockAbstract, FieldBlockConfig, RelativeSelectConfig, SizeConfigure } from '@lighthouse/core'
import {
    type FieldInputConfigProtocol,
    type FieldInputType,
    type FieldOptionsProtocol,
    CheckboxShapeEnum,
    DIRECTION,
    RecordOpenType
} from '@lighthouse/core'
import { DATE_FORMAT_OPTIONS, FieldTypeToInputTypeMap, getCanEditFieldIds, getFieldOptions } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { mergeDeepRight, reduce } from 'rambda'

const FIELD_INPUT_STYLES: FieldOptionsProtocol = {
    canEdit: true,
    showTitle: true,
    size: 'middle',
    direction: 'column'
}

/**
 * 生成字段block的配置
 *
 * @param {FieldInputType} type
 * @returns {FieldInputConfigProtocol}
 */
export const generateFieldBlock = (type: FieldInputType): FieldBlockConfig => {
    function getFieldConfig(type: FieldInputType): FieldInputConfigProtocol {
        switch (type) {
            case 'text':
            case 'url':
            case 'phoneNumber':
            case 'email':
            case 'file':
            case 'notes': {
                return {
                    inputType: type,
                    ...FIELD_INPUT_STYLES,
                    placeholder: '请输入'
                }
            }
            case 'checkbox': {
                return {
                    inputType: type,
                    ...FIELD_INPUT_STYLES,
                    checkbox: {
                        gapType: 'fixed',
                        gap: 0.625,
                        checkedIcon: {
                            icon: {
                                type: 'icon',
                                value: 'AltArrowUpLinear'
                            },
                            color: { type: 'color', color: '#98A2B3' }
                        },
                        unCheckedIcon: {
                            icon: {
                                type: 'icon',
                                value: 'AltArrowDownLinear'
                            },
                            color: { type: 'color', color: '#98A2B3' }
                        },
                        shape: CheckboxShapeEnum.checkbox,
                        type: 'checkbox',
                        describePosition: 'right'
                    }
                }
            }
            case 'date': {
                return {
                    inputType: type,
                    ...FIELD_INPUT_STYLES,
                    placeholder: '请选择',
                    date: {
                        dateFormat: DATE_FORMAT_OPTIONS[0].value,
                        range: 'ALL'
                    }
                }
            }
            case 'number': {
                return {
                    inputType: type,
                    ...FIELD_INPUT_STYLES,
                    placeholder: '请输入',
                    number: {}
                }
            }
            // case 'select': {
            //     return {
            //         inputType: type,
            //         placeholder: '请选择',
            //         ...FIELD_INPUT_STYLES,
            //         select: {
            //             canMultipleChoice: false
            //         }
            //     }
            // }
            case 'person': {
                return {
                    inputType: type,
                    placeholder: '请选择',
                    ...FIELD_INPUT_STYLES,
                    person: {
                        canMultipleChoice: false,
                        filter: {}
                    }
                }
            }
            case 'relativeSelect': {
                return {
                    inputType: type,
                    placeholder: '请选择',
                    ...FIELD_INPUT_STYLES,
                    relativeSelect: {
                        showType: 'table',
                        viewFieldSettings: [],
                        canCreateRecord: false,
                        direction: 'vertical',
                        showMode: 'input',
                        creatingConfig: {
                            page: '',
                            openType: 'modal',
                            label: '添加'
                        }
                    }
                }
            }
            case 'slider': {
                return {
                    inputType: type,
                    ...FIELD_INPUT_STYLES,
                    placeholder: '请输入',
                    slider: {
                        min: 0,
                        max: 100,
                        step: 1,
                        isInput: true,
                        colorMode: 'theme',
                        color: 'var(--color-main)'
                    }
                }
            }
            case 'cascade': {
                return {
                    inputType: type,
                    ...FIELD_INPUT_STYLES,
                    placeholder: '请选择',
                    cascade: {
                        cascadePointer: '',
                        cascadeFieldPointer: 'ID',
                        cascadeShowFieldPointer: '',
                        parentFieldPointer: '',
                        sortFieldPointer: '',
                        isLastLevel: true,
                        showPath: true
                    }
                }
            }
            default: {
                return { inputType: 'text' }
            }
        }
    }

    const minHeightConfigure = type === 'notes' ? {
        minHeight: {
            size: 300,
            unit: 'px',
        } as SizeConfigure
    } : undefined

    return {
        ...getFieldConfig(type),
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            position: {
                type: 'relative'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                },
                ...minHeightConfigure,
                overflow: 'visible'
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}

/**
 * 需要额外创建至多5个上层数据源对应的非系统表单字段
 * textGeneration、selectGenerationByText、lookup、formula除外
 * 置入创建的表单容器内
 * @param dataSource
 */
export function padBlocksByFormContainer(dataSource: DataSourceAbstract, dataSourceList: DataSourceAbstract[]) {
    // 过滤系统字段
    const noSystemFields = getCanEditFieldIds(dataSource, dataSourceList)
    const fieldOptionIds = getFieldOptions(dataSource, field => noSystemFields.includes(field.id)).map(v => v.value)
    // 筛选前五个字段并生成block
    const fieldBlocks = reduce<string, FieldBlockAbstract[]>(
        (pre, cur) => {
            const field = dataSource?.schema?.[cur]
            const inputType = FieldTypeToInputTypeMap[field.type]
            if (!inputType || !field) {
                return pre
            }
            const config = generateFieldBlock(inputType)
            config.fieldPointer = field.id
            config.canDownload = false
            config.canEdit = true
            config.direction = 'column'
            config.showTitle = true
            config.size = 'middle'
            config.required = false
            config.noRepeat = false
            config.title = field.name

            switch (config.inputType) {
                case 'date': {
                    config.placeholder = '请选择'
                    break
                }

                case 'number': {
                    config.placeholder = '请输入'
                    config.number = mergeDeepRight(config.number || {}, {
                        mode: 'number',
                        accuracy: 0,
                        prefix: '',
                        suffix: ''
                    })
                    break
                }

                case 'phoneNumber':
                case 'text':
                case 'url':
                case 'email':
                case 'file':
                case 'notes': {
                    config.placeholder = '请输入'
                    break
                }

                case 'person': {
                    config.placeholder = '请选择'
                    config.person = {
                        canMultipleChoice: false,
                        filter: {}
                    }
                    break
                }

                case 'cascade': {
                    config.placeholder = '请选择'
                    break
                }
                case 'relativeSelect': {
                    config.placeholder = '请选择'
                    if (field.type === 'singleSelect' || field.type === 'select') {
                        break
                    }
                    if (field.type === 'role' && config.relativeSelect) {
                        config.relativeSelect = mergeDeepRight<RelativeSelectConfig['relativeSelect']>(config.relativeSelect, {
                            canMultipleChoice: false,
                            relativePointer: 'ROLE_DATASOURCE',
                            relativeFieldPointer: 'ID',
                            viewFieldSettings: [],
                            sorts: [],
                            relativeShowFieldPointer: 'ROLE_NAME',
                            showType: 'list'
                        })
                        break
                    }
                    config.relativeSelect = mergeDeepRight(config.relativeSelect || {}, {
                        canMultipleChoice: false,
                        canCreateRecord: false,
                        direction: DIRECTION.vertical,
                        filter: {},
                        showType: 'table',
                        sorts: [],
                        viewFieldSettings: [],
                        creatingConfig: {
                            openType: RecordOpenType.modal,
                            label: '添加',
                            page: ''
                        }
                    } satisfies typeof config.relativeSelect)
                    break
                }

                default: {
                    break
                }
            }

            return [
                ...pre,
                {
                    id: `field-${nanoid(12)}`,
                    type: 'field',
                    title: field.name,
                    config
                }
            ]
        },
        [],
        fieldOptionIds
    ).slice(0, 5)

    return [...fieldBlocks]
}
