import type { AnyObject } from '@byecode/ui/types'
import type {
    BasicPageStackMeta,
    BlockAbstract,
    BlockRuntimeState,
    BlockVisibilityProtocol,
    ContainerBlockAbstract,
    DataSourceAbstract,
    FilterFormType,
    FormContainerBlockAbstract,
    PageAbstract,
    RecordData,
    RecordLikeProtocol,
    Sorter,
    ViewOptions
} from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'

import type { FlowLayoutNode } from '../components'
import type { ApplicationPreviewEnum } from '../types'
import {
    findBlockById,
    getBlockChildren,
    getBreakPointConfigure,
    getWithScopeId,
    isContainerBlock,
    isCustomViewBlock,
    isFormContainerBlock
} from './block'

const filterNilLayoutNode = (node: FlowLayoutNode | null): node is FlowLayoutNode => {
    return !!node
}

/** 普通页面内容返回 */
export type PageContentRes = Omit<PageAbstract, 'filter' | 'sorts'> & {
    pageConfig?: {
        filter: FilterFormType
        sorts: Sorter[]
    }
    blockNodes: (Omit<BlockAbstract, 'config'> & {
        config: Record<BlockType, AnyObject>
    })[]
    /** 除了普通页面，其他页面都会有该字段，存放的是筛选的数据源信息 */
    recordPage?: {
        datasource: DataSourceAbstract
        records: RecordLikeProtocol[]
    }
}

/** 将后端block数据转换为前端需要的格式 */
export function transformBlockRes2Blocks(blockRes: PageContentRes['blockNodes']): BlockAbstract[] {
    try {
        return blockRes.reduce<BlockAbstract[]>((total, curr) => {
            const { config, type } = curr
            if (type === BlockType.container) {
                return [
                    ...total,
                    {
                        ...curr,
                        config: config[type],
                        children: (curr as unknown as ContainerBlockAbstract).children.map(view => {
                            return {
                                ...view,
                                children: transformBlockRes2Blocks(view.children as unknown as PageContentRes['blockNodes'])
                            }
                        })
                    } as BlockAbstract
                ]
            }

            if (
                type === BlockType.formContainer ||
                (type === BlockType.view && (config.view as ViewOptions).viewType === 'custom') ||
                type === BlockType.floatBox
            ) {
                return [
                    ...total,
                    {
                        ...curr,
                        config: config[type],
                        children: transformBlockRes2Blocks(
                            (curr as unknown as FormContainerBlockAbstract).children as unknown as PageContentRes['blockNodes']
                        )
                    } as BlockAbstract
                ]
            }

            return [...total, { ...curr, config: config[type] } as BlockAbstract]
        }, [])
    } catch (error) {
        console.log('🚀 ~ transformBlockRes2Blocks ~ error:', error)
        return []
    }
}

/** 将后端同步组件数据转换为前端需要的格式 */
export function transformSyncComponentsRes2Blocks(res: PageContentRes['blockNodes'], isLeaf?: boolean): BlockAbstract[] {
    return res.reduce<BlockAbstract[]>((total, curr) => {
        const { config, type } = curr
        if (type === BlockType.container) {
            return [
                ...total,
                {
                    ...curr,
                    config: config[type],
                    children: (curr as unknown as ContainerBlockAbstract).children.map(view => {
                        return {
                            ...view,
                            children: transformSyncComponentsRes2Blocks(view.children as unknown as PageContentRes['blockNodes'], true)
                        }
                    }),
                    isLeafSynchronous: isLeaf,
                    isMasterSynchronous: !isLeaf
                } as ContainerBlockAbstract
            ]
        }

        if (
            type === BlockType.formContainer ||
            (type === BlockType.view && (config.view as ViewOptions).viewType === 'custom') ||
            type === BlockType.floatBox
        ) {
            return [
                ...total,
                {
                    ...curr,
                    config: config[type],
                    children: transformSyncComponentsRes2Blocks(
                        (curr as unknown as FormContainerBlockAbstract).children as unknown as PageContentRes['blockNodes'],
                        true
                    ),
                    isLeafSynchronous: isLeaf,
                    isMasterSynchronous: !isLeaf
                } as BlockAbstract
            ]
        }

        return [...total, { ...curr, config: config[type], isLeafSynchronous: isLeaf, isMasterSynchronous: !isLeaf } as BlockAbstract]
    }, [])
}

type TransformParams = {
    blocks: BlockAbstract[]
    blockRuntimeState?: BlockRuntimeState
    getIsVisible?: (payload: { visibility?: BlockVisibilityProtocol; viewRecord?: RecordData; userId?: string }) => boolean
    userId?: string
    previewType: ApplicationPreviewEnum
    syncComponents: ContainerBlockAbstract[]
}

/** 将blocks转换为栅格节点格式 */
export function transformBlock2FlowLayoutNode({
    blocks,
    blockRuntimeState,
    getIsVisible,
    userId,
    previewType,
    syncComponents
}: TransformParams): FlowLayoutNode[] {
    function recursion(tree: BlockAbstract[], parentIsCustomView?: boolean, scope?: string): FlowLayoutNode[] {
        return tree
            .map<FlowLayoutNode | null>(n => {
                const isMasterSyncBlock = !!n.synchronousId

                let block: BlockAbstract | undefined

                if (scope) {
                    block = findBlockById(n.id, syncComponents)
                } else if (isMasterSyncBlock) {
                    block = fillSyncBlock(n, syncComponents)
                } else {
                    block = n
                }

                if (!block) {
                    return null
                }

                if (!parentIsCustomView && getIsVisible) {
                    const visible = getIsVisible?.({
                        userId,
                        visibility: block.config.breakPoint?.visibility
                    })
                    if (!visible) {
                        return null
                    }
                }

                if (block.type === BlockType.view && block.config.viewType === 'custom') {
                    return {
                        id: block.id,
                        scope,
                        isRootSyncNode: isMasterSyncBlock,
                        type: 'custom',
                        block,
                        data: block.config.breakPoint,
                        children: recursion(block.children ?? [], true, isMasterSyncBlock ? n.id : scope)
                    }
                }

                if (block.type === BlockType.container) {
                    const { id, config, children } = block
                    const fullId = getWithScopeId(id, scope)
                    const view = children.find(item => item.id === blockRuntimeState?.container?.[fullId]?.currentView)
                    if (!view) {
                        return null
                    }
                    return {
                        id,
                        scope,
                        isRootSyncNode: isMasterSyncBlock,
                        type: 'container',
                        block,
                        data: config.breakPoint,
                        children: recursion(view?.children ?? [], parentIsCustomView, isMasterSyncBlock ? n.id : scope)
                    }
                }

                if (block.type === BlockType.formContainer) {
                    return {
                        id: block.id,
                        scope,
                        isRootSyncNode: isMasterSyncBlock,
                        type: 'container',
                        block,
                        data: block.config.breakPoint,
                        children: recursion(block.children, parentIsCustomView, isMasterSyncBlock ? n.id : scope)
                    }
                }

                if (block.type === BlockType.floatBox) {
                    return {
                        id: n.id,
                        scope,
                        isRootSyncNode: isMasterSyncBlock,
                        type: 'overlay',
                        disableDrag: true,
                        block,
                        data: block.config.breakPoint,
                        children: recursion(block.children, parentIsCustomView, isMasterSyncBlock ? n.id : scope)
                    }
                }

                return {
                    id: block.id,
                    scope,
                    isRootSyncNode: isMasterSyncBlock,
                    block,
                    data: block.config.breakPoint,
                    type: 'block'
                }
            })
            .filter(filterNilLayoutNode)
    }
    return recursion(blocks)
}

type RuntimeStateOption = {
    blocks: BlockAbstract[]
    syncComponents: BlockAbstract[]
}
export function initBlockRuntimeState(stack: BasicPageStackMeta, option: RuntimeStateOption) {
    const { blocks, syncComponents } = option

    function deepInit(block: BlockAbstract, _scope?: string) {
        const filledBlock = fillSyncBlock(block, syncComponents)
        const newScope = _scope || (filledBlock.isMasterSynchronous ? filledBlock.id : undefined)

        const uniqueId = _scope ? `${_scope}@${filledBlock.id}` : filledBlock.id
        switch (filledBlock.type) {
            case BlockType.container: {
                // if (stack.blockRuntimeState.container?.[uniqueId]?.currentView) {
                //     break
                // }
                const { container } = stack.blockRuntimeState
                stack.blockRuntimeState.container = {
                    ...container,
                    [uniqueId]: {
                        ...container?.[uniqueId],
                        currentView: filledBlock.config.breakPoint.panel?.defaultPanel || filledBlock.config.viewList?.[0].id
                    }
                }

                break
            }

            case BlockType.tabs: {
                // if (stack.blockRuntimeState.tabs?.[uniqueId]?.currentTab) {
                //     break
                // }
                const { tabs } = stack.blockRuntimeState
                stack.blockRuntimeState.tabs = {
                    ...tabs,
                    [uniqueId]: {
                        ...tabs?.[uniqueId],
                        currentTab: filledBlock.config.baseList?.[0]?.id
                    }
                }
                break
            }

            default: {
                break
            }
        }

        const children = getBlockChildren(filledBlock)
        if (children && children.length !== 0) {
            children.forEach(block => {
                deepInit(block, newScope)
            })
        }
    }

    try {
        blocks.forEach(block => {
            deepInit(block)
        })
    } catch (error) {
        console.log('🚀 ~ initBlockRuntimeState ~ error:', error)
        throw error
    }
}

/** 将同步组件数据同步到同步组件实例中 */
export const fillSyncBlock = (block: BlockAbstract, syncComponents: BlockAbstract[]): BlockAbstract => {
    if (block.synchronousId) {
        return {
            ...block,
            ...findBlockById(block.synchronousId, syncComponents),
            id: block.id
        } as BlockAbstract
    }

    return block
}

export const deepFillSyncBlock = (blocks: BlockAbstract[], syncComponents: BlockAbstract[]): BlockAbstract[] => {
    return blocks.reduce<BlockAbstract[]>((total, curr) => {
        if (isContainerBlock(curr)) {
            if (curr.synchronousId) {
                return [...total, fillSyncBlock(curr, syncComponents)]
            }

            return [
                ...total,
                {
                    ...curr,
                    children: curr.children.map(view => {
                        return {
                            ...view,
                            children: deepFillSyncBlock(view.children, syncComponents)
                        }
                    })
                }
            ]
        }

        if (isFormContainerBlock(curr) || isCustomViewBlock(curr)) {
            if (curr.synchronousId) {
                return [...total, fillSyncBlock(curr, syncComponents)]
            }

            return [
                ...total,
                {
                    ...curr,
                    children: deepFillSyncBlock(curr.children, syncComponents)
                }
            ]
        }

        return [...total, fillSyncBlock(curr, syncComponents)]
    }, [])
}
