import { IconFont, Text, Tooltip } from '@byecode/ui'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { SendEmailParagraph } from '@lighthouse/shared'
import { TagIcon } from '@lighthouse/shared'
import { Collapse } from '@mantine/core'
import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useSendEmailContext } from '../context'
import { EmailParagraphConfigure } from './EmailParagraphConfigure'
import * as SC from './styles'

interface EmailParagraphItemProps {
    value: SendEmailParagraph
    index: number
    opened: boolean
    disabled?: boolean
    onChangeOpen: (opened: boolean) => void
    onChange: (val: SendEmailParagraph) => void
    onDuplicate: (id: string) => void
    onDelete: (id: string) => void
}

export const EmailParagraphItem: React.FC<EmailParagraphItemProps> = ({
    value,
    index,
    opened,
    disabled,
    // eventTypes,
    onChange,
    onDuplicate,
    onDelete,
    onChangeOpen
}) => {
    const { id } = value
       const { editType } = useSendEmailContext()
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id
    })

    const style: React.CSSProperties = useMemo(
        () => ({
            transform: CSS.Transform.toString(transform),
            transition,
            zIndex: 2
        }),
        [transform, transition]
    )

    const methods = useForm<SendEmailParagraph>({
        defaultValues: value
    })

    useEffect(() => {
        const subscribe = methods.watch(values => {
            onChange(values as SendEmailParagraph)
        })

        return () => {
            subscribe.unsubscribe()
        }
    }, [id, methods, onChange])

    return (
        <FormProvider {...methods}>
            <SC.Container style={style} ref={setNodeRef} {...attributes} role="">
                <SC.Header>
                    <SC.LeftFill>
                        <SC.Handle {...listeners}>
                            <SC.Icon type="DotsSix" color="var(--color-gray-400)" />
                        </SC.Handle>
                        <Text size={14}>第{index + 1}段落</Text>
                    </SC.LeftFill>
                    <SC.RightFill style={{ width: 'auto' }}>
                        <Tooltip title="创建副本">
                            <TagIcon
                                icon="Duplicate"
                                size={24}
                                radius={5}
                                hoverBackground="var(--color-gray-100)"
                                enableHover
                                iconColor="var(--color-gray-500)"
                                onClick={() => onDuplicate(id)}
                            />
                        </Tooltip>
                        <Tooltip title="删除">
                            <TagIcon
                                icon="Trash"
                                size={24}
                                radius={5}
                                hoverBackground="var(--color-gray-100)"
                                enableHover
                                iconColor="var(--color-gray-500)"
                                onClick={() => onDelete(id)}
                            />
                        </Tooltip>

                        <IconFont
                            size={16}
                            color="var(--color-gray-500)"
                            type={opened ? 'ArrowUpSmall' : 'ArrowDownSmall'}
                            onClick={() => onChangeOpen(!opened)}
                        />
                    </SC.RightFill>
                </SC.Header>
                <Collapse in={opened}>
                    <SC.Content>
                        <EmailParagraphConfigure disabled={disabled} />
                    </SC.Content>
                </Collapse>
            </SC.Container>
        </FormProvider>
    )
}
