import type { FlowLayoutNode } from '@lighthouse/shared'
import { useApplicationContext, useFloatBoxContext } from '@lighthouse/shared'
import { mergeRefs } from '@lighthouse/tools'
import React, { forwardRef, useCallback } from 'react'

import { ContainerLayout } from '../../components/ContainerLayout'

interface ContainerBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    disabled?: boolean
    node: FlowLayoutNode
    shown?: boolean
}
const ContainerBlock = forwardRef<HTMLDivElement, ContainerBlockProps>(({ disabled, node, shown = true, ...rest }, ref) => {
    const { reference, interactionProps, onOpen } = useFloatBoxContext()

    if (!shown) {
        return null
    }
    return (
        <ContainerLayout
            ref={mergeRefs([ref, reference])}
            node={node}
            disabled={disabled}
            {...rest}
            {...interactionProps.getReferenceProps({ ...rest })}
        />
    )
})

export default ContainerBlock
