import { Loading } from '@byecode/ui'
import { ActivityType } from '@lighthouse/core'
import { useAtomAction, useAtomData } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import { find } from 'rambda';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Outlet, useMatches, useNavigate, useSearchParams } from 'react-router-dom'
import { useAsyncRetry, useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { fetchUserAtom } from '@/atoms/auth/action'
import { invitationActivityAtom, userDataAtom } from '@/atoms/auth/state'
import { fetchActivityListAtom } from '@/atoms/workSpace/action'
import { activityListAtom } from '@/atoms/workSpace/state'
import { QuotaDayWarningModal } from '@/components/QuotaDayWarningModal'
import { Profile } from '@/containers/Profile'
import { Promotion } from '@/containers/Promotion'
import { SpaceContext } from '@/contexts/SpaceContext'

interface ByecodeProps {}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

// enum STATUS {
//     ENTER = 0,
//     FETCHED = 1,
//     UPDATED = 2
// }
export const Byecode: React.FC<ByecodeProps> = () => {
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [open, setOpen] = useState(false)
    const [promotionOpen, setPromotionOpen] = useState(false)
    const user = useAtomValue(userDataAtom)
    const activityList = useAtomValue(activityListAtom)
    const invitationActivity = useAtomValue(invitationActivityAtom)
    const { run: fetchUser } = useAtomAction(fetchUserAtom)
    const { run: fetchActivityList } = useAtomAction(fetchActivityListAtom)
    const { loading, value } = useAsyncRetry(() => {
        if (!user.userId) {
            return fetchUser()
        }
        return Promise.resolve(user)
    }, [])

    const inviteActivity = useMemo(() => find(act => act.type === ActivityType.INVITATION_USER, activityList || []), [activityList])

    useEffect(() => {
        fetchActivityList()
    }, [fetchActivityList])

    const userId = value?.userId
    const username = value?.username

    const handleInviteUser = useCallback(() => {
        setPromotionOpen(true)
    }, [])

    const handleClose = useCallback(() => {
        setOpen(false)
    }, [])

    useUpdateEffect(() => {
        if (!invitationActivity && user.userId) {
            setPromotionOpen(true)
        }
    }, [user.isNewUser])

    useEffect(() => {
        if (loading) {
            return
        }
        if (!userId) {
            return
        }
        if (!username) {
            navigate({pathname: '/account/perfect', search: searchParams.toString() }, {
                replace: true
            })
        }
    }, [loading, navigate, searchParams, userId, username, value])

    const spaceContextValue = useMemo(
        () => ({
            open,
            setOpen,
            promotionOpen,
            setPromotionOpen
        }),
        [open, promotionOpen]
    )

    return (
        <SCxContainer>
            {useMemo(() => {
                if (loading) {
                    return <Loading />
                }

                if (!value) {
                    return null
                }
                return (
                    <SpaceContext.Provider value={spaceContextValue}>
                        <Outlet context={value} />
                        <Profile open={open} onClose={handleClose} onInviteUser={handleInviteUser} />
                        {promotionOpen && <Promotion open={promotionOpen} onChange={setPromotionOpen} />}
                        <QuotaDayWarningModal />
                    </SpaceContext.Provider>
                )
            }, [handleClose, handleInviteUser, loading, open, promotionOpen, spaceContextValue, value])}
        </SCxContainer>
    )
}
