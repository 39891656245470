import type { BaseModalProps } from '@byecode/ui'
import { DesktopModal, Modal } from '@byecode/ui'
import React from 'react'

import type { WeChatOfficialAccountFormState, WeChatOfficialAccountMode } from '../type'
import WeChatOfficialAccount from '../WeChatOfficialAccount'

interface WeChatOfficialAccountModalProps extends BaseModalProps {
    mode: WeChatOfficialAccountMode
    id?: string
    isPerfectWebsiteInfo?: boolean
}

export const WeChatOfficialAccountModal: React.FunctionComponent<WeChatOfficialAccountModalProps> = ({
    mode,
    id,
    isPerfectWebsiteInfo,
    ...rest
}) => {
    return (
        <DesktopModal
            width={676}
            trapFocus={!isPerfectWebsiteInfo}
            {...rest}
            styles={{
                modal: {
                    maxHeight: 'calc(100% - 48px)'
                },
                body: {
                    padding: '0!important',
                    overflow: 'hidden!important',
                    display: 'flex'
                },
                header: {
                    borderStyle: 'none'
                }
            }}
        >
            <WeChatOfficialAccount mode={mode} id={id} isPerfectWebsiteInfo={isPerfectWebsiteInfo} onClose={rest.onClose} />
        </DesktopModal>
    )
}
