import { tinyButtons } from '@byecode/ui'
import type {
    ActionsProtocol,
    ButtonAction,
    CustomViewLayout,
    DataSourceAbstract,
    Pagination,
    RecordLikeProtocol,
    ViewBlockAbstract
} from '@lighthouse/core'
import type { FlowLayoutNode, VisibleDomProps } from '@lighthouse/shared'
import { mergeRefs } from '@lighthouse/tools'
import { useUpdateEffect } from '@react-hookz/web'
import { useVirtualizer } from '@tanstack/react-virtual'
import React, { forwardRef, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'
import { Item } from './Item'
import { setDeadCopyNode } from './utils'

const ScrollArea = styled.div`
    overflow: auto hidden;
    padding-bottom: 4px;
    ${tinyButtons}
`

const Root = styled.div<{ gap: number }>`
    display: flex;
    flex-wrap: nowrap;
    /* gap: ${({ gap }) => `${gap}rem`}; */
`

interface Props extends React.ComponentPropsWithoutRef<'div'>, ActionsProtocol {
    blockData: ViewBlockAbstract
    node: FlowLayoutNode
    itemWidth: number
    showAction?: boolean
    title: string
    viewId: string
    pointer: string
    records: RecordLikeProtocol[]
    cols: number
    gap: number
    layout: CustomViewLayout
    readonly?: boolean
    pagination: Pagination
    scale?: number
    dataSource: DataSourceAbstract
    visibleParams?: VisibleDomProps
    userId?: string
    onFilterNode?: (node: FlowLayoutNode, record: RecordLikeProtocol) => FlowLayoutNode

    onLoadMoreData?: (pageNum: number) => Promise<RecordLikeProtocol[]>
    onRecordClick?: (recordId: string) => void
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
}

export const HorizontalScrollView = forwardRef<HTMLDivElement, Props>((props, ref) => {
    const {
        blockData,
        node,
        itemWidth,
        showAction,
        title,
        viewId,
        pointer,
        records,
        cols,
        gap,
        layout,
        readonly,
        pagination,
        scale = 1,
        actions,
        dataSource,
        visibleParams: propsVisibleParams,
        userId,
        onFilterNode,
        onLoadMoreData,
        onRecordClick,
        onRecordClickedActionTrigger,
        ...rest
    } = props
    const { id, config: { breakPoint } } = blockData
    const innerRef = useRef<HTMLDivElement | null>(null)
    const loadingRef = useRef(false)
    const virtualizer = useVirtualizer({
        horizontal: true,
        count: records.length,
        getItemKey: i => records[i].id,
        getScrollElement: () => innerRef.current,
        estimateSize: () => (innerRef.current?.clientWidth ?? 1200) * (1 / cols),
        overscan: 6,
        gap
    })

    const { currentPage = 1, pageSize = 10, rowTotal = 0 } = pagination

    const customViewData = useMemo(
        () => ({
            name: blockData.title,
            dataSource
        }),
        [blockData.title, dataSource]
    )

    const visibleParams: VisibleDomProps | undefined = useMemo(
        () =>
            propsVisibleParams
                ? {
                      ...propsVisibleParams,
                      customViewData
                  }
                : undefined,
        [customViewData, propsVisibleParams]
    )

    useUpdateEffect(() => {
        void (async () => {
            if (!rowTotal || loadingRef.current || !virtualizer.range) {
                return
            }

            const rangOut = currentPage >= Math.ceil(rowTotal / pageSize)
            const isReachRight = virtualizer.range.endIndex >= records.length - 1 - 6
            const hasMore = rowTotal > records.length
            if (!rangOut && isReachRight && hasMore && onLoadMoreData) {
                loadingRef.current = true
                await onLoadMoreData(currentPage + 1)
                // eslint-disable-next-line require-atomic-updates
                loadingRef.current = false
            }
        })()
    }, [currentPage, records.length, rowTotal, virtualizer.range?.endIndex])

    const itemHeightRef = useRef<Map<React.Key, HTMLElement | null>>(new Map())
    const items = virtualizer.getVirtualItems()

    const [currentMaxHeight, setCurrentMaxHeight] = useState<number>()

    useLayoutEffect(() => {
        let maxHeight = 0
        let raf: number

        function handleResize() {
            items.forEach(item => {
                const el = itemHeightRef.current.get(item.key)
                if (!el) {
                    return
                }
                const oldMinHeight = el.style.minHeight
                el.style.minHeight = ''
                const { height } = el.getBoundingClientRect()
                el.style.minHeight = oldMinHeight
                maxHeight = Math.max(maxHeight, height)
            })

            if (maxHeight !== currentMaxHeight) {
                flushSync(() => {
                    setCurrentMaxHeight(maxHeight)
                })
            }
        }

        const observer = new ResizeObserver(() => {
            raf = requestAnimationFrame(handleResize)
        })

        items.forEach(item => {
            const el = itemHeightRef.current.get(item.key)
            if (!el) {
                return
            }
            observer.observe(el)
        })

        return () => {
            if (raf) {
                cancelAnimationFrame(raf)
            }

            if (observer) {
                observer.disconnect()
            }
        }
    }, [currentMaxHeight, items])

    return (
        <ScrollArea ref={mergeRefs([innerRef, ref])} id={id} {...rest}>
            <Root
                gap={gap}
                style={{
                    position: 'relative',
                    height: currentMaxHeight ? currentMaxHeight / scale : undefined,
                    width: virtualizer.getTotalSize() / scale
                }}
            >
                {items.map((item, index) => {
                    const record = records[item.index]

                    return (
                        <div
                            key={item.key}
                            ref={virtualizer.measureElement}
                            data-index={item.index}
                            data-view-item={item.index}
                            style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                height: '100%',
                                transform: `translateX(${item.start}px)`,
                                cursor: 'pointer'
                            }}
                        >
                            <Item
                                ref={r => {
                                    itemHeightRef.current.set(item.key, r)
                                }}
                                data={{
                                    id: record.id,
                                    scope: node.scope,
                                    type: 'container',
                                    disabled: readonly || item.index !== 0,
                                    virtual: true,
                                    data: node.data && {
                                        design: node.data.design,
                                        size: {
                                            width: {
                                                size: itemWidth,
                                                unit: 'px'
                                            },
                                            height: {
                                                size: 'auto'
                                            },
                                            minHeight: {
                                                size: 'fill'
                                            },
                                            overflow: 'hidden'
                                        },
                                        layout: node.data.layout,
                                        position: node.data.position
                                    },
                                    children: setDeadCopyNode(node.children || [], item.index === 0, viewId)
                                }}
                                title={title}
                                viewId={viewId}
                                pointer={pointer}
                                record={record}
                                records={records}
                                blockData={blockData}
                                visibleParams={index === 0 ? visibleParams : undefined}
                                userId={userId}
                                onRecordClick={onRecordClick}
                                onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                                onFilterNode={onFilterNode}
                            />
                        </div>
                    )
                })}
            </Root>
        </ScrollArea>
    )
})
