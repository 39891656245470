import type { BlockAbstract, ContainerBlockAbstract } from '@lighthouse/core'
import { findBlockById, useAtomAction, useAtomData } from '@lighthouse/shared'
import produce, { current } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import { useCallback, useMemo } from 'react'
import { debounce } from 'throttle-debounce'

import { syncComponentsAtom } from '@/atoms/application/state'
import { viewIndependentDataAtomFamily } from '@/atoms/dataSource/state'
import { updateLocalBlockAtom, updateRemoteBlockAtom } from '@/atoms/page/action'
import { hasBlockNegativeUpdate } from '@/atoms/utils/block'
import { useRootPageContext } from '@/contexts/PageContext'
import * as srv from '@/services'

import { useCurrentAppID } from './useApplication'

export type BlockUpdateOption = {
    prevBlock: BlockAbstract
    nextBlock: BlockAbstract
    prevBlocks?: BlockAbstract[]
    nextBlocks?: BlockAbstract[]
    prevSyncComponents?: ContainerBlockAbstract[]
    nextSyncComponents?: ContainerBlockAbstract[]
}

export const useBlockActions = (pageId: string, stackId: string) => {
    const appId = useCurrentAppID()

    const { run: updateRemoteBlock } = useAtomAction(updateRemoteBlockAtom)
    const { run: updateLocalBlock } = useAtomAction(updateLocalBlockAtom)
    const { run: setSyncComponents } = useAtomAction(syncComponentsAtom)
    const { rootPageId } = useRootPageContext()

    /** 更新远程block */
    const onUpdateRemoteBlock = useMemo(
        () =>
            debounce(500, async ({ prevBlock, nextBlock, nextBlocks }: BlockUpdateOption) => {
                await updateRemoteBlock({ appId, block: nextBlock, origin: prevBlock, blocks: nextBlocks, pageId, rootPageId, stackId })
            }),
        [updateRemoteBlock, appId, pageId, rootPageId, stackId]
    )

    const onUpdateSyncComponents = useMemo(
        () =>
            debounce(500, async (blocks: ContainerBlockAbstract[]) => {
                const res = await srv.updateSyncComponents(blocks)
                if (res) {
                    setSyncComponents(blocks)
                }
            }),
        [setSyncComponents]
    )

    /** 更新block */
    const onUpdateBlock = useAtomCallback(
        useCallback(
            (get, set, option: BlockUpdateOption) => {
                const { prevBlock, nextBlock } = option
                // 是同步组件
                if (prevBlock.isMasterSynchronous || prevBlock.isLeafSynchronous) {
                    if (!hasBlockNegativeUpdate(appId, nextBlock, prevBlock)) {
                        set(syncComponentsAtom, draft => {
                            const draftBlock = findBlockById(nextBlock.id, draft)
                            if (!draftBlock) {
                                return
                            }
                            Object.entries(nextBlock).forEach(([k, v]) => {
                                Reflect.set(draftBlock, k, v)
                            })
                            onUpdateSyncComponents(current(draft))
                        })
                    }

                    const finalSyncComponents = produce(get(syncComponentsAtom), draft => {
                        const draftBlock = findBlockById(nextBlock.id, draft)
                        if (!draftBlock) {
                            return
                        }
                        Object.entries(nextBlock).forEach(([k, v]) => {
                            Reflect.set(draftBlock, k, v)
                        })
                    })

                    onUpdateSyncComponents(finalSyncComponents)

                    return
                }

                if (!hasBlockNegativeUpdate(appId, nextBlock, prevBlock)) {
                    updateLocalBlock({ block: nextBlock, pageId })
                }
                onUpdateRemoteBlock(option)
            },
            [appId, onUpdateRemoteBlock, onUpdateSyncComponents, pageId, updateLocalBlock]
        )
    )

    return useMemo(
        () => ({
            onUpdateBlock
        }),
        [onUpdateBlock]
    )
}

export const useViewIndependentData = (viewId: string) => {
    return useAtomData(viewIndependentDataAtomFamily(viewId))
}
