import { IconFont, Tooltip } from '@byecode/ui'
import { FileButton, Popover, Text } from '@mantine/core'
import type { Editor } from '@tiptap/react'
import classNames from 'classnames'
import React, { forwardRef, useCallback, useRef, useState } from 'react'

import { useLanguageContext } from '../../../contexts'
import { useTipTapContext } from '../Context'
import { DropdownLink } from './DropdownLink'
import { CustomActionIcon } from './styles'

interface LinkButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
    editor: Editor | null
    onToggleLink: () => void
}
const LinkButton = forwardRef<HTMLButtonElement, LinkButtonProps>(({ editor, className, onClick, onToggleLink, ...props }, ref) => {
    const { convertTextByLanguage } = useLanguageContext()
    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>{convertTextByLanguage('hyperlink')}</Text>
                    <Text color="var(--color-gray-400)">⌘+K</Text>
                </div>
            }
        >
            <CustomActionIcon
                ref={ref}
                className={classNames({ active: editor?.isActive('link'), className })}
                {...props}
                onClick={e => {
                    onClick?.(e)
                    onToggleLink()
                }}
            >
                <IconFont type="LinkSimple" />
            </CustomActionIcon>
        </Tooltip>
    )
})

export const LinkMenu = () => {
    const editor = useTipTapContext()

    const [linkOpened, setLinkOpened] = useState(false)

    // 切换link
    const onToggleLink = useCallback(() => {
        const isLink = editor?.isActive('link')
        if (isLink) {
            return editor?.chain().focus().unsetLink().run()
        }
        setLinkOpened(s => !s)
    }, [editor])

    // 更新link地址
    const handleUpdateLink = (url: string) => {
        editor?.chain().focus().extendMarkRange('link').setLink({ href: url, target: '_blank' }).run()
        setLinkOpened(false)
    }

    return (
        <Popover
            trapFocus
            opened={linkOpened}
            onClose={() => setLinkOpened(false)}
            position="bottom"
            shadow="md"
            styles={{ dropdown: { padding: 12, borderRadius: 8 } }}
        >
            <Popover.Target>
                <LinkButton editor={editor} onToggleLink={onToggleLink} />
            </Popover.Target>
            <Popover.Dropdown>
                <DropdownLink onConfirm={handleUpdateLink} />
            </Popover.Dropdown>
        </Popover>
    )
}

export const CodeMenu = () => {
    const editor = useTipTapContext()
    const { convertTextByLanguage } = useLanguageContext()

    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>{convertTextByLanguage('insertCode')}</Text>
                    <Text color="var(--color-gray-400)">⌘+Option+C</Text>
                </div>
            }
        >
            <CustomActionIcon
                className={classNames({ active: editor?.isActive('codeBlock') })}
                onClick={() => editor?.chain().focus().toggleCodeBlock().run()}
            >
                <IconFont type="TextCodeInline" />
            </CustomActionIcon>
        </Tooltip>
    )
}

// 添加网络图片
// export const ImageMenu: FC<Props> = ({ editor }) => {
//     const [imageOpened, setImageOpened] = useState(false)

//     // 更新image地址
//     const handleUpdateImage = (url: string) => {
//         editor.chain().focus().setImage({ src: url }).run()
//         setImageOpened(false)
//     }

//     return (
//         <Popover
//             trapFocus
//             opened={imageOpened}
//             onClose={() => setImageOpened(false)}
//             position="bottom"
//             shadow="md"
//             styles={{ dropdown: { padding: 12, borderRadius: 8 } }}
//         >
//             <Popover.Target>
//                 <CustomActionIcon variant="subtle" onClick={() => setImageOpened(s => !s)}>
//                     <IconFont type="BlockImage" />
//                 </CustomActionIcon>
//             </Popover.Target>
//             <Popover.Dropdown>
//                 <DropdownLink onConfirm={handleUpdateImage} />
//             </Popover.Dropdown>
//         </Popover>
//     )
// }

export const ImageMenu = () => {
    const editor = useTipTapContext()
    const ref = useRef<() => void>(() => void 0)
    const { convertTextByLanguage } = useLanguageContext()

    const onChange = useCallback(
        (payload: File[]) => {
            editor?.commands.insertImg(payload)
            // 上传完后清除input
            ref.current()
        },
        [editor?.commands]
    )

    return (
        <FileButton resetRef={ref} onChange={onChange} accept="image/*" multiple>
            {props => (
                <Tooltip title={convertTextByLanguage('uploadImage')}>
                    <CustomActionIcon {...props} variant="subtle">
                        <IconFont type="BlockImage" />
                    </CustomActionIcon>
                </Tooltip>
            )}
        </FileButton>
    )
}

export const QuoteMenu = () => {
    const editor = useTipTapContext()
    const { convertTextByLanguage } = useLanguageContext()
    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>{convertTextByLanguage('insertCitation')}</Text>
                    <Text color="var(--color-gray-400)">⌘+Shift+U</Text>
                </div>
            }
        >
            <CustomActionIcon
                className={classNames({ active: editor?.isActive('blockquote') })}
                onClick={() => editor?.chain().focus().toggleBlockquote().run()}
            >
                <IconFont type="Quote" />
            </CustomActionIcon>
        </Tooltip>
    )
}

export const LineMenu = () => {
    const editor = useTipTapContext()
    const { convertTextByLanguage } = useLanguageContext()
    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>{convertTextByLanguage('divider')}</Text>
                    <Text color="var(--color-gray-400)">---</Text>
                </div>
            }
        >
            <CustomActionIcon variant="subtle" onClick={() => editor?.chain().focus().setHorizontalRule().run()}>
                <IconFont type="BlockLine" />
            </CustomActionIcon>
        </Tooltip>
    )
}
