import type { OnOffBlockConfig } from '@lighthouse/core'
import { CheckboxShapeEnum, SwitchTypeEnum } from '@lighthouse/core'
import { nanoid } from '@lighthouse/tools'



/**
 * 生成按钮block的配置
 *
 * @returns {OnOffBlockConfig}
 */
export const generateOnOffBlock = (): OnOffBlockConfig => {
    const openActionId = nanoid()
    const closeActionId = nanoid()
    return {
        checkedIcon: {
            icon: {
                type: 'icon',
                value: 'AltArrowUpLinear'
            },
            color: { type: 'color', color: '#98A2B3' }
        },
        unCheckedIcon: {
            icon: {
                type: 'icon',
                value: 'AltArrowDownLinear'
            },
            color: { type: 'color', color: '#98A2B3' }
        },
        defaultChecked: false,
        shape: CheckboxShapeEnum.checkbox,
        showType: SwitchTypeEnum.SWITCH,
        open: {
            action: {
                id: openActionId,
                type: 'none',
                trigger: 'click',
                data: {
                    none: {}
                }
            },
        },
        close: {
            action: {
                id: closeActionId,
                type: 'none',
                trigger: 'click',
                data: {
                    none: {}
                }
            },
        },

        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            position: {
                type: 'relative'
            },
            size: {
                width: {
                    size: 44,
                    unit: 'px'
                },
                height: {
                    size: 'auto'
                }
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
