import { COLORS } from '@lighthouse/shared'
import { randomData } from '@lighthouse/tools'
import React, { memo } from 'react'

import * as SC from './styles'

interface LinkAnchorProps {
    label: string
    color: string
    href: string
}

const LinkAnchor: React.FC<LinkAnchorProps> = ({ label, color, href }) => {
    return (
        <SC.LinkItem style={{ color: `var(${color})` }} href={href} target="_blank">
            {label}
        </SC.LinkItem>
    )
}

const getColor = () => randomData(COLORS, 1)[0]?.darkColor ?? ''
/**
 *
 * @deprecated 已废弃block
 */
const LinkGroupBlock = () => {
    return (
        <SC.LinkGroupContainer>
            <LinkAnchor color={getColor()} href="https://airtable.com" label="airTable" />
            <LinkAnchor color={getColor()} href="https://www.stackerhq.com/" label="stacker hq" />
            <LinkAnchor color={getColor()} href="https://qingflow.com/" label="清流" />
        </SC.LinkGroupContainer>
    )
}

export default memo(LinkGroupBlock)
