import { nanoid } from '@lighthouse/tools'
import { useEffect, useMemo } from 'react'
import useWebSocket from 'react-use-websocket'

const { VITE_SENTRY_ENVIRONMENT } = import.meta.env
// function getCookieByName(name: string) {
//     const match = new RegExp(`(^| )${name}=([^;]+)`).exec(document.cookie)
//     if (match) {
//         return match[2]
//     }
// }

const getDomainPrefix = () => {
    const domainPrefix = VITE_SENTRY_ENVIRONMENT

    switch (domainPrefix) {
        case 'prod': {
            return 'app'
        }
        case 'dev':
        case 'dev01':
        case 'dev02':
        case 'dev03':
        case 'test01':
        case 'pre': {
            return `xxxxx.${domainPrefix}`
        }
        // 本地开发环境，随便写，proxy url 是哪个就填哪个
        default: {
            return 'xxxxx.pre'
        }
    }
}

const sessionId = nanoid(10)

export function useMessage<MessageType>(appId: string, enable: boolean, uniqueUserId: string) {
    const domainPrefix = getDomainPrefix()
    // const wsUrl = `ws://dev.byecode.com:8002/lighthouse/app/ws?sessionId=${sessionId}&jwt=${getCookieByName('jwt')}&appId=${appId}`

    const wsUrl = `wss://${domainPrefix}.byecode.site/lighthouse/ws?sessionId=${sessionId}&appId=${appId}&uniqueUserId=${uniqueUserId}`

    const socket = useWebSocket(
        wsUrl,
        {
            onOpen: () => {
                // eslint-disable-next-line no-console
                console.log('socket opened')
            },
            retryOnError: true,
            shouldReconnect: () => true
        },
        enable
    )

    const { sendJsonMessage, lastMessage: lastRawMessage, getWebSocket } = socket

    const lastMessage: MessageType = useMemo(() => {
        if (lastRawMessage) {
            const { data } = lastRawMessage
            const jsonData = JSON.parse(data)

            if (jsonData.type === 'HEART_BEAT') {
                return
            }

            return jsonData
        }
    }, [lastRawMessage])

    useEffect(() => {
        const timer = setInterval(() => {
            sendJsonMessage({
                id: 'HEART_BEAT',
                type: 'HEART_BEAT'
            })
        }, 10000)

        return () => {
            clearInterval(timer)
        }
    }, [sendJsonMessage])

    return useMemo(
        () => ({
            lastMessage,
            send: sendJsonMessage,
            getWebSocket
        }),
        [getWebSocket, lastMessage, sendJsonMessage]
    )
}
