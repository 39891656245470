import { IconFont, pointer } from '@byecode/ui'
import { MaxVersionLevel } from '@lighthouse/core'
import { type AvatarSize, getBrand } from '@lighthouse/shared'
import { Flex, Text } from '@mantine/core'
import cls from 'classnames'
import React from 'react'
import styled from 'styled-components'

import { RoundAvatar } from '@/components/RoundAvatar'
import { openSaleSpaceGrade, openSpaceGrade, SpaceGradeTag } from '@/components/SpaceGrade'
import { guideStepList } from '@/components/SpaceGuide/constant'
import { useSpaceQuota } from '@/shared/reusable'

export interface SpaceAvatarProps extends React.ComponentPropsWithoutRef<'div'> {
    name?: string
    icon?: string
    members?: number
    enableHover?: boolean
    active?: boolean
    size?: AvatarSize
    enableTooltip?: boolean
    dot?: boolean
    radius?: string
}

const SCxContainer = styled.div`
    width: 100%;
    height: 56px;
    padding: 8px 4px;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${pointer}

    &:hover {
        background: var(--color-gray-100);
    }

    &.active {
        background: var(--color-gray-200);
    }
`

const SCxSpaceContent = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex: 1;
    overflow: hidden;
`

const SCxSpaceInfo = styled.div`
    height: 40px;
    margin-left: 12px;
    flex: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
`

const SCxSpaceMenu = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    overflow: hidden;
`

const SCxSpaceDot = styled.div`
    height: 20px;
    display: flex;
    align-items: center;
    margin-left: 8px;
`

const SCxIcon = styled(IconFont)`
    transform: rotate(90deg);
`

const SCxSpaceName: typeof Text = styled(Text)`
    font-size: 16px;
    line-height: normal;
    height: 20px;
    line-height: 20px;
    font-weight: 400;
    color: var(--color-gray-900);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxSpaceMember = styled.span`
    font-size: 12px;
    font-weight: 500;
    color: var(--color-theme-7);
`

export const SpaceAvatar = React.forwardRef<HTMLDivElement, SpaceAvatarProps>(
    ({ name, icon, members = 0, enableHover, enableTooltip = false, dot, radius = '8px', active, size = 'large', ...restProps }, ref) => {
        const { data } = useSpaceQuota()

        return (
            <SCxContainer {...restProps} ref={ref} className={cls({ active })} id={guideStepList.spaceList.id}>
                <SCxSpaceContent>
                    <RoundAvatar
                        enableHover={!icon && enableHover}
                        enableTooltip={enableTooltip}
                        tooltipLabel={`空间：${name}`}
                        color="var(--color-black)"
                        radius={radius}
                        style={{
                            borderColor: 'var(--color-gray-200)'
                        }}
                        dot={dot}
                        icon={icon}
                        name={name || ''}
                        size={size}
                    />
                    <SCxSpaceInfo>
                        <SCxSpaceMenu>
                            <Flex style={{ flex: 1, overflow: 'hidden', display: 'flex' }}>
                                <SCxSpaceName>{name}</SCxSpaceName>
                                <SCxSpaceDot>
                                    <SCxIcon type="ArrowRightSmall" size={16} fill="var(--color-gray-500)" className={cls({ active })} />
                                </SCxSpaceDot>
                            </Flex>
                            <SpaceGradeTag value={data?.currentVersionCode ?? 0} isLimitHidden={false} />
                        </SCxSpaceMenu>
                        {MaxVersionLevel !== data?.currentVersionCode && (
                            <SCxSpaceMember
                                onClick={e => {
                                    e.stopPropagation()
                                    const brand = getBrand()
                                    brand === 'I_CLICK' ? openSaleSpaceGrade() : openSpaceGrade('version_upgrade')
                                }}
                            >
                                升级版本
                            </SCxSpaceMember>
                        )}
                    </SCxSpaceInfo>
                </SCxSpaceContent>
            </SCxContainer>
        )
    }
)
