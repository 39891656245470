import { Enum } from '@lighthouse/tools'

export const ICON_TYPE = Enum('icon', 'upload')
export type IconType = Enum<typeof ICON_TYPE>

export const ICON_STYLE = Enum('face', 'line')
export type IconStyle = Enum<typeof ICON_STYLE>

export const ICON_TABS = {...ICON_STYLE, upload: ICON_TYPE.upload}
export type IconTabs = Enum<typeof ICON_TABS>

export type IconDecorationProtocol = {
    type: IconType
    value?: string
}
