import mitt from 'mitt'
import type { MakeADT } from 'ts-adt/MakeADT'

type CreateContainerViewPayload = {
    newId: string
}

type DeleteContainerViewPayload = {
    index: number
}

type CloneContainerViewPayload = {
    newId: string
    fromIndex: number
}

export type BlockSettingActionADT = MakeADT<
    'action',
    {
        createContainerView: CreateContainerViewPayload
        deleteContainerView: DeleteContainerViewPayload
        cloneContainerView: CloneContainerViewPayload
    }
>

type BlockSettingEvent = {
    event: BlockSettingActionADT
}

export const BlockSettingMitt = mitt<BlockSettingEvent>()
