import { mergeRefs } from '@byecode/ui/hooks/useMergedRef'
import { useContextPopoverHeight } from '@byecode/ui/hooks/usePopoverHeight'
import type { Selectors, StyleComponentProps } from '@byecode/ui/theme/types'
import clsx from 'clsx'
import React, { forwardRef, useMemo, useState } from 'react'

import { Box } from '../../Box'
import { IconFont } from '../../IconFont'
import { Input } from '../../Input'
import { usePopoverContext } from '../../Popover'
import { TooltipWithTextOverflow } from '../../TooltipWithTextOverflow'
import { SelectItem } from '../SelectItem/SelectItem'
import type { SelectItemStyleNames } from '../SelectItem/SelectItem.type'
import type { Option, SelectValueType } from '../types'
import { useStyles } from './SelectDropdown.style'

export type SelectDropdownStylesNames = Selectors<typeof useStyles> | SelectItemStyleNames

export interface SelectDropdownProps
    extends StyleComponentProps<SelectDropdownStylesNames>,
        Omit<React.ComponentPropsWithoutRef<'div'>, 'onSelect'> {
    searchable?: boolean
    value?: string
    options?: Option[]
    optionComponent?: React.FC<Omit<React.ComponentPropsWithRef<'div'>, 'value' | 'children'> & Option>
    onSelect?: (value: SelectValueType) => void
    extra?: React.ReactNode
    hiddenEmpty?: boolean
    emptyComponent?: React.ReactNode
    itemExtra?: React.ReactNode | ((value: string) => React.ReactNode)
}

const MAX_HEIGHT = 640

export const SelectDropdown = forwardRef<HTMLDivElement, SelectDropdownProps>((props, ref) => {
    const {
        searchable,
        value: selectValue,
        options,
        optionComponent: Item = SelectItem,
        onSelect,
        extra,
        hiddenEmpty = false,
        emptyComponent,
        itemExtra,
        className,
        classNames,
        styles,
        unstyled,
        ...rest
    } = props

    const [searchLabel, setSearchLabel] = useState('')

    const { context } = usePopoverContext()
    const [maxHeight, internalRef] = useContextPopoverHeight({ context, initMaxHeight: MAX_HEIGHT })

    const filterOptions = useMemo(() => {
        if (!options) {
            return []
        }

        function filter(tree: Option[]): Option[] {
            return tree.reduce<Option[]>((total, current) => {
                const isContain = (typeof current.label === 'string' ? current.label.toUpperCase() : current.value.toUpperCase()).includes(
                    searchLabel.toUpperCase()
                )

                if (isContain) {
                    return [...total, current]
                }

                if (current.children) {
                    const child = filter(current.children)
                    if (child.length > 0) {
                        return [...total, { ...current, children: child }]
                    }
                }

                return total
            }, [])
        }

        return filter(options)
    }, [options, searchLabel])

    const { classes } = useStyles({}, { name: 'SelectDropdown', classNames, styles, unstyled })

    const selectContent = useMemo(() => {
        return filterOptions.length > 0 ? (
            <Box className={clsx(classes.itemsWrapper)}>
                {filterOptions.map(item => {
                    const { value, label, disable, disableTooltip, children } = item

                    if (children) {
                        return (
                            <Box key={value} className={clsx(classes.groupItem)}>
                                <Box className={clsx(classes?.groupItemTitle)}>{label}</Box>
                                {children.map(option => (
                                    <TooltipWithTextOverflow key={option.value} title={label} disabled={option.disableTooltip}>
                                        <Item
                                            {...option}
                                            extra={
                                                itemExtra
                                                    ? typeof itemExtra === 'function'
                                                        ? itemExtra(option.value)
                                                        : itemExtra
                                                    : option.extra
                                            }
                                            className={clsx(className, classes.item)}
                                            role="option"
                                            data-disable={disable}
                                            styles={styles}
                                            onMouseDown={e => {
                                                if (disable) {
                                                    return
                                                }
                                                // e.preventDefault()
                                                onSelect?.(option.value)
                                            }}
                                        />
                                    </TooltipWithTextOverflow>
                                ))}
                            </Box>
                        )
                    }
                    return (
                        <TooltipWithTextOverflow key={value} title={label} disabled={disableTooltip}>
                            <Item
                                className={clsx(className, classes.item)}
                                role="option"
                                data-disable={disable}
                                styles={styles}
                                onMouseDown={e => {
                                    if (disable) {
                                        return
                                    }
                                    // e.preventDefault()
                                    onSelect?.(value)
                                }}
                                {...item}
                                extra={itemExtra ? (typeof itemExtra === 'function' ? itemExtra(value) : itemExtra) : item.extra}
                            />
                        </TooltipWithTextOverflow>
                    )
                })}
            </Box>
        ) : (
            !hiddenEmpty && (emptyComponent ?? <Box className={clsx(classes.notFound)}>暂无数据</Box>)
        )
    }, [
        filterOptions,
        classes.itemsWrapper,
        classes.notFound,
        classes.item,
        classes.groupItem,
        classes?.groupItemTitle,
        hiddenEmpty,
        emptyComponent,
        Item,
        className,
        styles,
        onSelect,
        itemExtra
    ])

    return (
        <Box ref={mergeRefs(ref, internalRef)} className={clsx(classes.selectDropdown, className)} style={{ maxHeight }} {...rest}>
            {searchable && (
                <Box className={clsx(classes.searchWrapper)}>
                    <Input
                        onChange={e => setSearchLabel(e.target.value)}
                        className={clsx(classes.searchInput)}
                        prefix={<IconFont type="Search" />}
                        placeholder="搜索"
                    />
                </Box>
            )}
            {selectContent}
            {extra && <Box className={clsx(classes.dropdownExtra)}>{extra}</Box>}
        </Box>
    )
})
