import { Modal } from '@byecode/ui'
import type { DataSourceAbstract, Field } from '@lighthouse/core'
import type { ColumnEvent, CreateFieldParams, InsertDirection } from '@lighthouse/shared'
import { Table, useAtomAction } from '@lighthouse/shared'
import React, { useCallback, useMemo, useRef } from 'react'

import { createFieldAtom, deleteFieldAtom, updateFieldAtom } from '@/atoms/dataSource/action'
import { aiFieldStatusListAtom, recordPoolAtom } from '@/atoms/dataSource/state'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

interface FormDataSourceProps {
    dataSource: DataSourceAbstract
}

const emptyUploadOption = {
    info: { id: '', label: '', groupId: '' },
    options: {}
}

export const FormDataSource: React.FC<FormDataSourceProps> = ({ dataSource }) => {
    const { id: dsId } = dataSource
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const { run: createField } = useAtomAction(createFieldAtom)
    const { run: updateField } = useAtomAction(updateFieldAtom)
    const { run: deleteField } = useAtomAction(deleteFieldAtom)

    const data = useMemo(
        () => ({
            ...dataSource,
            records: []
        }),
        [dataSource]
    )

    const createFieldParamsRef = useRef<CreateFieldParams>({
        sourceId: '',
        direction: '',
        fieldId: ''
    })

    const handleCreateField = useCallback(
        async (field: Field, sourceId: string, direction: InsertDirection) => {
            createFieldParamsRef.current = {
                sourceId,
                direction,
                fieldId: field.id
            }
            const innerType = await createField({
                envId,
                dsId,
                config: field,
                sourceId,
                direction
            })

            return !!innerType
        },
        [createField, envId, dsId]
    )

    const handleUpdateField = useCallback(
        async (fieldId: string, config: Field) => {
            const isUpdate = await updateField({
                envId,
                dsId,
                fieldId,
                config
            })
            return !!isUpdate
        },
        [dsId, envId, updateField]
    )

    const handleDeleteField = useCallback(
        async (field: Field) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: `确认删除列「${field.name ?? '未命名列'}」？`,
                okStatus: 'error'
            })
            if (isConfirm) {
                const isDelete = await deleteField({
                    envId,
                    dsId,
                    fieldId: field.id
                })
                return !!isDelete
            }
            return false
        },
        [deleteField, dsId, envId]
    )

    // const getVideoUploadOptions = useCallback(() => emptyUploadOption, [])
    const initViewIndependentData = useMemo(
        () => ({
            recordIds: [],
            rowTotal: 0,
            currentPage: 1
        }),
        []
    )

    const columnEvent: ColumnEvent = useMemo(
        () => ({
            onCreateField: handleCreateField,
            onUpdateField: handleUpdateField,
            onDeleteField: handleDeleteField
        }),
        [handleCreateField, handleDeleteField, handleUpdateField]
    )

    return (
        <Table
            appId={appId}
            dataSourceList={dataSourceList}
            viewIndependentData={initViewIndependentData}
            pageSize={1}
            rowEvent={{}}
            headerFixed
            contentLoading={false}
            data={data}
            createFieldParams={createFieldParamsRef.current}
            recordPoolAtom={recordPoolAtom}
            aiFieldStatusListAtom={aiFieldStatusListAtom}
            columnEvent={columnEvent}
            uploadOptions={emptyUploadOption}
            videoUploadOptions={emptyUploadOption}
            richTextUploadOptions={emptyUploadOption.options}
            disableCreateRecord
            disableEditRecord
            disableSelect
            scrollBarOutside={false}
        />
    )
}
