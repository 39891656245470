import type { AnyObject } from '@byecode/ui/types'

import { errorCodeMap } from '../constants'
import type { I18TranslationKey } from '../types'

export const zhCN: Record<I18TranslationKey, string> = {
    // 登录注册页面文本
    bindMobile: '绑定手机号',
    bindEmail: '绑定邮箱',
    firstLoginNeedAccount: '首次微信登录需绑定{{account}}',
    mobileCode: '手机号',
    noVerificationCodeReceived: '没有收到验证码？',
    noNeedLogin: '暂不支持登录',
    registerAccount: '注册账号',
    mobileLogin: '手机号登录',
    emailLogin: '邮箱登录',
    wechatLogin: '微信登录',
    emailUrl: '邮箱地址',
    noAccount: '还没有账号？',
    next: '下一步',
    goLogin: '去登录',
    alreadyHaveAnAccount: '已有账号？',
    selectRegisterWay: '选择注册方式',
    perfectInfo: '完善信息',
    verificationCode: '验证码',
    sure: '确定',
    yourRole: '您的角色',
    emailFormatIsIllegal: '邮箱格式不合法',
    mobilePhoneNumberFormatIsIllegal: '手机号格式不合法',
    mobileRegister: '手机号注册',
    emailRegister: '邮箱注册',
    email: '邮箱',
    shortMessage: '短信',
    pleaseInputYourEmail: '请输入邮箱',
    pleaseInputYourMobile: '请输入手机号',
    pleaseInputYourValidEmail: '请输入正确的邮箱',
    pleaseInputYourValidMobile: '请输入正确的手机号',
    getVerificationCode: '获取验证码',
    getAgain: '再次获取',
    register: '注册',
    notEmpty: '不能为空',
    pleaseFillOut: '请填写',
    loginSuccess: '登录成功',
    pleaseWeChatLoginInWeChatBrowser: '请在微信浏览器中使用微信登录',
    refresh: '刷新',
    expiredPleaseRefresh: '已过期，刷新',
    scanWechatLogin: '使用微信扫码关注公众号即可登录',
    accountSetting: '账号设置',
    // 动态文本
    resendTime: `{{v}} s后重发`,
    verificationMessage: `我们已向 {{v}} 发送了验证码，请查看您的邮箱`,
    verificationSMS: `我们已向 {{v}} 发送了短信验证码`,
    inputVerificationCode: `输入{{v}}验证码`,
    confirmMergeAccounts: `确认合并账号？`,
    merge: '合并',
    mergeAccount: '合并账号',
    iAwareRisks: '我已知晓风险',
    cancel: '取消',
    backLoginPage: '返回登录页',
    youAccountUnderReview: '你的账号正在审核',
    keepMobileAndDeleteWechat: '保留手机号的账号 删除微信的账号',
    keepWechatAndDeleteMobile: ' 保留微信的账号 删除手机号的账号',
    MergeWeChatAccountDescription: '将微信和手机号注册的账号合并为一个账号请选择保留的账号：',
    changeBindMobile: '换绑手机号',
    deleteWechatAccountWarn: '此操作将删除微信的账号，且不可恢复，请谨慎操作！',
    mobileAlreadyRegister: '手机号已经注册',
    search: '搜索',
    personNameOrPersonId: '人员名称、人员编号',
    noFindData: '未找到数据',
    noFindPerson: '没有找到人员',
    selected: '只看已选',
    goHome: '回到主页',
    mobileAlreadyRegisterDesc: '该手机号已被注册为：{{name}} ({{userId}})，若仍想绑定该手机号，请点击「合并账号」。',
    logout: '退出登录',
    notBind: '无法解绑',
    mobileNumber: '手机号',
    wechat: '微信',
    removeBindAccountDescribe: '{{account}} 作为本账号唯一登录方式，请先绑定其他账号作为替代登录方式。',
    knew: '知道了',
    continueUnbinding: '继续解绑',
    emailAccount: '邮箱账号',
    alreadyBind: '已绑定',
    wechatAccount: '微信账号',
    notEnabledAccountLogin: '未启用{{account}}登录功能',
    bindAfterUsedAccountLogin: '绑定后可使用{{account}}登录',
    bindAccountAfterAccountNotEnable:
        '该{{account}}已经注册过账号，请更换{{account}}若您仍想绑定，请先解除刚刚绑定失败的{{account}}的原绑定关系后再将其与当前登录的账号绑定',
    bindMeetQuestion: '绑定遇到问题',
    changeAccount: '更换{{account}}',
    goBind: '去绑定',
    unbind: '解绑',
    notEnable: '未启用',
    bindMobileNumber: '绑定手机号',
    bindMobileNumberAfterLoginApp: '绑定后可使用手机号登录应用',
    enterSmsCode: '输入短信验证码',
    confirmUnbinding: '确认解绑',
    unBindNotLoginConfirmDescribe: '解绑后无法再通过{{account}}途经登录，确认继续解绑？',
    alreadyUnbindAccount: '已解除{{account}}绑定',
    clickToUpload: '点击上传',
    rename: '重命名',
    delete: '删除',
    download: '下载',
    view: '查看',
    recordVideo: '录像',
    photograph: '拍照',
    selectFromGallery: '从手机相册选择',
    localFiles: '本地文件',
    more: '更多',
    onlyPicture: '僅支援圖片',
    fileExceedsUploadAvatarLimit: '不能上传大于 {{v}}mb 的头像',
    fileExceedsUploadLimit: '不能上传大于 {{v}}mb 的文件',
    unsupportedFormatOnlyImageUploads: '格式错误，仅支持上传图片',
    unsupportedVideoFormatPleaseUploadMP4file: '视频格式错误，请上传 MP4 格式的视频',
    sort: '排序',
    sortEmptyDescribe: '添加排序规则，让你的数据井然有序',
    sortRule: '排序规则',
    add: '添加',
    group: '分组',
    linesTotal: '共{{ count }}行',
    field: '字段',
    groupBy: '分组依据',
    pleasesSelect: '请选择',
    xItemsSelected: '已选择{{count}}项',
    print: '打印',
    edit: '编辑',
    kanbanSetting: '看板设置',
    foldingBoard: '折叠看板',
    hideBoard: '隐藏看板',
    text: '正文',
    firstLevelHeading: '一级标题',
    secondLevelHeading: '二级标题',
    thirdLevelHeading: '三级标题',
    todoList: '待办列表',
    numberList: '有序列表',
    bulletedList: '无序列表',
    alginCenter: '居中',
    alginLeft: '居左',
    alginRight: '居右',
    clearFormat: '清除格式',
    bold: '加粗',
    strikethrough: '删除线',
    italic: '斜体',
    underline: '下划线',
    textColor: '文本颜色',
    textBackgroundColor: '文本背景色',
    hyperlink: '超级链接',
    insertCode: '插入代码',
    uploadImage: '上传图片',
    insertCitation: '插入引用',
    divider: '分割线',
    operation: '操作',
    addOneRow: '添加一行',
    accordingTo: '按',
    pasteLink: '粘贴链接',
    displayedField: '显示字段',
    unDisplayedField: '未显示字段',
    hideAll: '全部隐藏',
    showAll: '全部显示',
    hideEmptyGroups: '隐藏空的分组',
    noData: '暂无数据',
    pleasesGoDataSourceAdd: '请前往数据源添加',
    export: '导出',
    sureDelete: '确认删除？',
    deleteRecordWaring: '删除选中的{{count}}条记录后无法恢复，请谨慎操作',
    undo: '撤销',
    redo: '重做',
    uploadXLSXtips: '点击或拖拽<bold />到此区域',
    uploadingXLSXemptyTips: '请确保：文件中不存在<bold />',
    uploadFileSizeLimitHowMuch: '文件大小不能超过{{v}}MB',
    importLimitNumberW: '每次导入不能超过{{v}}万行数据',
    onlyXLSXorCSVofFile: '仅支持.xlsx、.csv 格式的文件',
    fileNoEmptySheet: '文件中不能有空 Sheet',
    tableFileNoMergeCell: '文件中不能存有合并的单元格',
    localImport: '本地导入',
    exportingOfProgress: '正在导出，请勿重复点击',
    xlsxCsvFileNumber: '.xlsx、.csv文件（{{v}}M以内）',
    emptySheetOrMergeCell: '空工作表和合并单元格',
    batchImport: '批量导入',
    collapseKanban: '折叠看板',
    hideKanban: '隐藏看板',
    groupSetting: '分组设置',
    startImport: '开始导入',
    againImport: '重新导入',
    previous: '上一步',
    createNewTable: '创建新的表',
    appendImportTable: '追加导入到已有表',
    pleasesSelectSheet: '请在 <name /> 中选择 <number /> Sheet',
    a: '一个',
    reupload: '重新上传',
    fieldMapping: '字段映射',
    ofColumn: '的列',
    ofField: '的字段',
    mapping: '映射',
    importSuccess: '导入成功',
    pleasesSetKanbanGrouping: '请设置看板分组依据',

    exportSettings: '导出设置',
    selectRecords: '选择记录',
    exportAllRecords: '导出所有记录',
    exportSpecifiedRecords: '导出指定记录',
    exporting: '正在导出',
    aMaximumOfRecordsCanExportedAtATime: '单次最多导出 {{count}} 条记录',
    printTemplate: '打印模板',
    selectTemplate: '选择模板',
    exportMethod: '导出方式',
    exportByPage: '分页导出（所有数据合成一个pdf文件，且数据分页）',
    noExportTemplate: '暂无导出模板',
    exportingPDFforPrinting: '正在导出用于打印的PDF文件',
    exportFailedTheExportFileCannotExceedNumber: '导出失败，导出的文件大小不能超过 {{size}}mb',
    exportFailedOfNumberRecordsExport: '导出失败，每次最多导出 {{count}} 条记录',
    // date
    beforeYesterday: '前天',
    yesterday: '昨天',
    today: '今天',
    tomorrow: '明天',
    afterTomorrow: '后天',
    // toast
    ...errorCodeMap
}
