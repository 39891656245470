import { IconFont } from '@byecode/ui'
import type { DataSourceAbstract, DTSelect, DTSelectItem, RelativeSelectConfig, SelectField, SingleSelectField } from '@lighthouse/core'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'

import { useApplicationContext } from '../../../../../contexts'
import { SelectDrawer } from '../../../../FieldDrawer'
import { SelectItemPreviewer } from '../../../SelectItemPreviewer'
import { SelectPlaceHolder } from '../../../SelectPlaceHolder'
import type { FieldBaseProps } from '../../../types'
import * as CM from '../../commonStyles'
import { CheckBoxGroup } from '../CheckBoxGroup'
import * as SC from '../styles'
import { TagGroup } from '../TagGroup'

interface SelectDrawerFieldProps extends FieldBaseProps {
    value: string[]
    selectConfig?: DTSelect
    relativeSelectConfig: RelativeSelectConfig
}

export const SelectDrawerField: React.FunctionComponent<SelectDrawerFieldProps> = props => {
    const {
        onCellChange,
        value: data,
        config,
        isControlled,
        readOnly,
        dataSource,
        selectConfig,
        relativeSelectConfig,
        onFetchDataSourceMeta
    } = props
    const { fieldPointer = '', placeholder, relativeSelect } = relativeSelectConfig
    const { showMode, direction } = relativeSelect ?? {}
    const { id: pointer } = dataSource ?? {}

    const { options: selectOptions = [], multiple: canMultipleChoice } = selectConfig ?? {}
    const [value, setValue] = useState(data ?? [])
    const [newDataSource, setNewDataSource] = useState<DataSourceAbstract>()
    const [opened, setOpened] = useState(false)

    const usedValue = useMemo(() => (isControlled ? data : value), [data, isControlled, value])

    const fieldName = newDataSource?.schema?.[fieldPointer]?.name ?? ''

    const { pageTarget } = useApplicationContext()

    const options = useMemo(() => {
        if (newDataSource) {
            const field = newDataSource.schema?.[fieldPointer]
            const options: DTSelectItem[] =
                field.type === 'singleSelect' ? (field as SingleSelectField).singleSelect.options : (field as SelectField).select.options
            return options.map(({ label, id, color }) => ({
                label,
                id,
                value: label,
                color,
                isDelete: !readOnly
            }))
        }
        return selectOptions.map(({ label, color, id }) => ({
            label,
            value: label,
            color,
            id,
            isDelete: !readOnly
        }))
    }, [fieldPointer, newDataSource, readOnly, selectOptions])

    const showValue = useMemo(
        () =>
            usedValue
                .map(label => ({ label, color: find(item => item.label === label, options)?.color }))
                .filter(item => options.find(option => option.label === item.label)),
        [options, usedValue]
    )

    const handleMultiChange = useCallback(
        (value: string[]) => {
            setValue(value)
            onCellChange?.({ type: 'relativeSelect', value })
        },
        [onCellChange]
    )

    const handleOpen = useCallback(async () => {
        if (readOnly) {
            return
        }
        setOpened(true)
        if (!pointer) {
            return
        }
        const dataSource = await onFetchDataSourceMeta?.({ dsId: pointer })
        setNewDataSource(dataSource)
    }, [onFetchDataSourceMeta, pointer, readOnly])

    return useMemo(() => {
        switch (showMode) {
            case 'checkbox': {
                return (
                    <SC.Container>
                        <CheckBoxGroup
                            options={options}
                            isMultiple={canMultipleChoice}
                            direction={direction}
                            value={usedValue}
                            onChange={handleMultiChange}
                        />
                    </SC.Container>
                )
            }
            case 'tag': {
                return (
                    <SC.Container>
                        <TagGroup
                            options={options}
                            isMultiple={canMultipleChoice}
                            direction={direction}
                            value={usedValue}
                            onChange={handleMultiChange}
                        />
                    </SC.Container>
                )
            }
            default: {
                return (
                    <SC.Container data-field-border={opened}>
                        <SC.Wrapper onClick={handleOpen}>
                            <SC.TagWrapper>
                                {showValue.map(item => (
                                    <SelectItemPreviewer key={item.label} data={item} />
                                ))}
                                {showValue.length === 0 && <SelectPlaceHolder label={placeholder} />}
                            </SC.TagWrapper>
                            <CM.IconWrapper>
                                {!readOnly && <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" size={16} />}
                            </CM.IconWrapper>
                        </SC.Wrapper>
                        <SelectDrawer
                            title={fieldName}
                            value={usedValue}
                            selectOptions={options}
                            isMultiple={canMultipleChoice}
                            onChange={handleMultiChange}
                            opened={opened}
                            onClose={() => setOpened(false)}
                            target={pageTarget}
                            tagComponent={({ data }) => <SelectItemPreviewer data={data} />}
                        />
                    </SC.Container>
                )
            }
        }
    }, [
        canMultipleChoice,
        direction,
        fieldName,
        handleMultiChange,
        handleOpen,
        opened,
        options,
        pageTarget,
        placeholder,
        readOnly,
        showMode,
        showValue,
        usedValue
    ])
}
