import { Button, IconFont } from '@byecode/ui'
import { filter } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import type { Control } from 'react-hook-form'
import { useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { useLanguageContext } from '../../../contexts';
import type { FormValue } from './types'

interface UploaderFooterProps {
    step: number
    control: Control<FormValue>
    fileType: 'excel' | 'csv'
    onBack?: () => void
    onNext: () => void
    onImport: () => Promise<void> | void
    onCancel?: () => void
}

const UploaderFooterContainer = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
`

const FooterLeftContainer = styled.div``

const FooterRightContainer = styled.div`
    display: flex;
    justify-content: flex-end;
`

const BackButton = styled(Button)`
    color: var(--color-gray-400);
`

export const UploaderFooter: React.FC<UploaderFooterProps> = ({ step, control, fileType, onNext, onBack, onImport, onCancel }) => {
    const { importType, dsId, sheetName, fieldMappings, repeat, importMode, compareFields } = useWatch({ control })
    const { convertTextByLanguage } = useLanguageContext()
    const isNotCSV = fileType !== 'csv'

    const buttonDisabled = useMemo(() => {
        switch (step) {
            case 1: {
                return false
            }
            case 2: {
                return !!(importType === '2' && (!dsId || (isNotCSV && !sheetName)))
            }
            case 3: {
                if (!fieldMappings) {
                    return true
                }

                if (repeat) {
                    return !importMode || compareFields?.length === 0
                }

                const filteredFiledMappings = filter(field => field?.sheetFieldNo !== '', fieldMappings)
                return filteredFiledMappings.length === 0
            }
            default: {
                return false
            }
        }
    }, [step, importType, dsId, isNotCSV, sheetName, fieldMappings, repeat, importMode, compareFields?.length])

    const nextButtonText = useMemo(() => {
        switch (step) {
            case 1: {
                return convertTextByLanguage('next')
            }
            case 2: {
                if (importType === '1') {
                    return convertTextByLanguage('startImport')
                }
                return convertTextByLanguage('next')
            }
            case 3: {
                return convertTextByLanguage('startImport')
            }
            default: {
                return ''
            }
        }
    }, [importType, step, convertTextByLanguage])

    const handleClick = useCallback(() => {
        switch (step) {
            case 1: {
                onNext()
                break
            }
            case 2: {
                if (importType === '1') {
                    onImport()
                    break
                }
                onNext()
                break
            }
            case 3: {
                onImport()
                break
            }
            default: {
                break
            }
        }
    }, [importType, onImport, onNext, step])

    return (
        <UploaderFooterContainer>
            <FooterLeftContainer>
                {step > 1 && (
                    <BackButton type="text" icon={<IconFont type="ArrowLeftSmall" fill="var(--color-gray-400)" />} onClick={onBack}>
                        {convertTextByLanguage('previous')}
                    </BackButton>
                )}
            </FooterLeftContainer>
            <FooterRightContainer>
                <Button style={{ marginRight: 8 }} onClick={onCancel}>
                    {convertTextByLanguage('cancel')}
                </Button>
                <Button type="primary" disabled={buttonDisabled} onClick={handleClick}>
                    {nextButtonText}
                </Button>
            </FooterRightContainer>
        </UploaderFooterContainer>
    )
}
