import { Group } from '@byecode/ui'
import type { BlockAbstract } from '@lighthouse/core'
import React, { useCallback, useLayoutEffect, useRef, useState } from 'react'
import { flushSync } from 'react-dom'
import styled from 'styled-components'

import type { TemplateComponent } from '@/atoms/application/type'

import { TEMPLATE_BLOCK_TYPE_NAME } from '../constant'
import BlockCard from './BlockCard'

interface TemplateBlockProps {
    option: TemplateComponent
    onOpenQuestion?: (v: BlockAbstract) => void
    onCreate?: (e: React.MouseEvent<HTMLDivElement>, v: BlockAbstract) => void
}

const SCxContainer = styled.div`
    /* padding: 12px 0; */
`

const SCxTitle = styled.div`
    color: var(--color-gray-500);
    text-align: left;
    /* 12/SC-Medium */
    font-size: 12px;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
`

const SCxContent = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-start;
    gap: 12px 8px;
`

const gap = 12

export const TemplateBlock: React.FunctionComponent<TemplateBlockProps> = ({ option, onCreate, onOpenQuestion }) => {
    const innerRef = useRef<HTMLDivElement>(null)
    const [maxHeightColumn, setMaxHeightColumn] = useState<number>()
    const handleResize = useCallback(() => {
        const rootEl = innerRef.current
        if (!rootEl) {
            return
        }
        const columnHeights = Array.from<number>({ length: 2 }).fill(0)
        let broken = false
        const children = rootEl.childNodes as NodeListOf<HTMLElement>
        children.forEach(childNode => {
            if (broken) {
                return
            }
            const { height } = childNode.getBoundingClientRect()
            if (height === 0) {
                broken = true
                return
            }

            const minColumnIndex = columnHeights.indexOf(Math.min(...columnHeights))
            columnHeights[minColumnIndex] += height + gap
            childNode.style.order = `${1 + minColumnIndex}`
        })

        if (broken) {
            return
        }
        flushSync(() => {
            setMaxHeightColumn(Math.ceil(Math.max(...columnHeights)))
        })
    }, [])

    useLayoutEffect(() => {
        const el = innerRef.current
        if (!el) {
            return
        }
        const observer = new ResizeObserver(() => {
            handleResize()
        })

        const children = el.childNodes as NodeListOf<HTMLElement>
        children.forEach(childNode => {
            observer.observe(childNode)
        })

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [handleResize])

    return (
        <SCxContainer>
            <Group
                defaultOpen
                styles={{
                    collapse: {
                        padding: '0 16px!important'
                    },
                    root: {
                        padding: '0px!important'
                    }
                }}
                iconColor="var(--color-gray-400)"
                label={<SCxTitle>{TEMPLATE_BLOCK_TYPE_NAME[option.group]}</SCxTitle>}
            >
                <SCxContent ref={innerRef} style={{ height: `${maxHeightColumn}px` }}>
                    {option.components.map((item, index) => (
                        <BlockCard data={item} index={index} key={item.id} onCreate={onCreate} onOpenQuestion={onOpenQuestion} />
                    ))}
                </SCxContent>
            </Group>
        </SCxContainer>
    )
}
