import { Loading } from '@byecode/ui'
import type { WorkSpaceAbstract } from '@lighthouse/core'
import { AccountBox, ApplicationPreviewEnum, getBrand, getDeviceType, useAtomAction, useAtomData } from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { Outlet, useMatches, useNavigate, useSearchParams } from 'react-router-dom'
import { useAsyncRetry, useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { fetchApplicationListAtom } from '@/atoms/application/action'
import { createWorkSpaceAtom, fetchWorkSpaceAtom, fetchWorkSpaceListAtom, setWorkSpaceIdAtom } from '@/atoms/workSpace/action'
import { workSpaceListAtom } from '@/atoms/workSpace/state'
import { CardCreator } from '@/components/CardCreator'
import SpaceGuide from '@/components/SpaceGuide'
import { VersionModal } from '@/components/VersionModal'
import { BetaRequestForm } from '@/containers/BetaRequestForm'
import { MobileSupport } from '@/containers/MobileSupport'
import SpaceDetail from '@/containers/WorkSpace'
import { useSpaceContext } from '@/contexts/SpaceContext'
import { useUser } from '@/hooks/useUser'
import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import { getBrowse } from '@/services'

interface WorkbenchProps {}

export type LoaderData = {
    workspace: WorkSpaceAbstract
}

const SCxContainer = styled.div`
    width: 100%;
    height: 100%;
`

const Workbench: React.FC<WorkbenchProps> = () => {
    const [searchParams] = useSearchParams()
    const matches = useMatches()
    const navigator = useNavigate()
    const user = useUser()
    const spaceId = useCurrentSpaceID()
    const spaceList = useAtomData(workSpaceListAtom)
    const { promotionOpen } = useSpaceContext()
    const { run: fetchWorkSpaceList } = useAtomAction(fetchWorkSpaceListAtom)
    // const spaceIdRef = useRef('')
    const { run: fetchWorkSpace } = useAtomAction(fetchWorkSpaceAtom)
    const { run: setWorkSpaceId } = useAtomAction(setWorkSpaceIdAtom)
    const { run: fetchApplicationList } = useAtomAction(fetchApplicationListAtom)
    const { run: createWorkSpace, loading: createLoading } = useAtomAction(createWorkSpaceAtom)
    const result = getDeviceType()
    const isMobile = result === ApplicationPreviewEnum.mobile
    const brand = getBrand()

    const { loading, value } = useAsyncRetry(async () => {
        let currentSpaceId = spaceId
        const spaceList = await fetchWorkSpaceList()
        if (currentSpaceId) {
            await fetchWorkSpace(spaceId)
        }
        const space = find(space => space.id === spaceId, spaceList)
        if (!space && spaceList.length > 0) {
            const { spaceId: browserSpaceId = '' } = await getBrowse()
            const isExitSpace = find(space => space.id === browserSpaceId, spaceList)
            currentSpaceId = isExitSpace ? browserSpaceId : spaceList?.[0]?.id ?? ''
            if (currentSpaceId) {
                setWorkSpaceId(currentSpaceId)
            }
        }
        if (matches.length === 3) {
            navigator({ pathname: 'applications', search: searchParams.toString() }, { replace: true })
        }
    }, [])

    const handleReloadSpace = useCallback(async () => {
        await fetchApplicationList(spaceId)
        await fetchWorkSpace(spaceId)
    }, [fetchApplicationList, fetchWorkSpace, spaceId])

    useUpdateEffect(() => {
        if (spaceList.length > 0) {
            handleReloadSpace()
        }
    }, [spaceId])

    const { username, profileComplete, isNewUser } = user
    const defaultSpaceName = useMemo(() => (username ? `${username}的空间` : ''), [username])

    const content = useMemo(() => {
        if (loading) {
            return <Loading />
        }

        if (spaceList && spaceList.length === 0) {
            return (
                <AccountBox title="">
                    <CardCreator
                        title="创建并进入"
                        value={{ name: defaultSpaceName, icon: '' }}
                        loading={createLoading}
                        onConfirm={async ({ name = '', icon = '' }) => {
                            const workspaceId = await createWorkSpace({ name, icon })
                            await fetchWorkSpaceList()
                            setWorkSpaceId(workspaceId)
                            navigator(`/workbench/applications`)
                        }}
                    />
                </AccountBox>
            )
        }
        return (
            <SpaceDetail>
                <Outlet />
            </SpaceDetail>
        )
    }, [createLoading, createWorkSpace, defaultSpaceName, fetchWorkSpaceList, loading, navigator, setWorkSpaceId, spaceList])

    return (
        <>
            <SCxContainer>{content}</SCxContainer>
            {isMobile && <MobileSupport />}
            {!profileComplete && !isMobile && brand === 'BYE_CODE' && <BetaRequestForm />}
            {!isNewUser && !promotionOpen && <VersionModal />}
        </>
    )
}

export default Workbench
