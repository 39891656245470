import type {
    BaseBreakPointConfigProtocol,
    BasicPageStackMeta,
    BlockAbstract,
    DeepPartial,
    PageAbstract,
    RecordLikeProtocol
} from '@lighthouse/core'

export type FetchPageAtomPayload = {
    pageId: string
    stackId: string
    rootPageId: string
}

/** 设置页面信息参数类型 */
export type SetPageAtomPayload = FetchPageAtomPayload &
    Partial<PageAbstract> & {
        blocks: BlockAbstract[]
    }

/** 移动页面参数类型 */
export type MovePageAtomPayload = {
    id: string
    oldId: string
    newId: string
}

/** 创建页面block参数类型 */
export type CreateBlockAtomPayload = {
    blocks: BlockAbstract[]
    createdBlocks: BlockAbstract[]
    rootPageId: string
    pageId: string
    stackId: string
    autoSelect?: boolean
    scope?: string
    skipInspection?: boolean
}

/** 发送请求更新block的参数类型 */
export type UpdateBlockAtomPayload = FetchPageAtomPayload & {
    appId: string
    block: BlockAbstract
    origin: BlockAbstract
    blocks?: BlockAbstract[]
}
export type UpdateLocalBlockAtom = {
    pageId: string
    block: BlockAbstract
}

/** 移除页面block参数类型 */
export type RemoveBlockAtomPayload = {
    rootPageId: string
    stackId: string
    blocks: BlockAbstract[]
    removedBlocks: BlockAbstract[]
    pageId: string
    clearSelected?: boolean
}

export type CreatePageMode = 'CAN_OPEN_RECORD' | 'CAN_CREATE_RECORD' | 'CAN_EDIT_RECORD'

export type CreatePagePayload = {
    name?: string
    dsId?: string
    /** 创建页面时，给页面添加标题block的内容 */
    title?: string
    /** 创建页面后给页面添加表单block 或者 字段block */
    createMode?: CreatePageMode
    viewId?: string
    isSyncComponent?: boolean
    lang?: string
}

/** 右侧栏类型 */
export enum AsideType {
    'BLOCK',
    // 'MODULE',
    'PAGE',
    'NAVBAR'
    // 'PAGE_FOOTER',
    // 'PAGE_FAB'
}

export type NodeIdWithScope = {
    scope?: string
    id: string
}
export type PageMetaState = {
    asideType: AsideType
    /** 选中的node */
    selectedNodes?: NodeIdWithScope[]
    /** 表单状态 */
    // formState?: Record<string, InputValueItem>
}

/**
 * 页面元数据信息
 * @date 9/20/2023 - 11:37:45 AM
 *
 * @interface PageMetaData
 * @typedef {PageMetaData}
 */
export interface PageMetaData extends BasicPageStackMeta {
    /** modal、drawer形式的页面拥有的方法 */
    onClose?: () => void
    onSave?: (record?: RecordLikeProtocol) => void

    /** 各字段block对应的数据源及记录 */
    depMachFieldDataMap?: Record<string, { appId: string; dsId: string; recordId: string }>

    /**
     * 页面的一些临时状态
     */
    state: PageMetaState
    children?: PageMetaData[]
}

export interface PageStackTree extends PageMetaData {
    children?: PageStackTree[]
}

export interface ContainerDesignState {
    paddingLeft: boolean | undefined
    paddingTop: boolean | undefined
    paddingRight: boolean | undefined
    paddingBottom: boolean | undefined
    gap: boolean | undefined
}

// export type UpdateBaseBreakPointAdt = MakeADT<'previewType', {
//     desktop: {
//         fn: (draft: Draft<BaseBreakPointConfigProtocol>) => void
//     }
//     mobile: {
//         fn: (draft: Draft<PartialExcludesIdAndName<BaseBreakPointConfigProtocol>>) => void
//     }
// }>

export type UpdateBlockBreakPointPayload = {
    pageId: string
    blockId: string
    data: DeepPartial<BaseBreakPointConfigProtocol>
}
