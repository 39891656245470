import { Box, Divider, Group, IconFont, Input, Menu, Text } from '@byecode/ui'
import type { BlockAbstract } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import { a } from '@react-spring/web'
import produce, { current } from 'immer'
import { useAtom } from 'jotai'
import { useAtomCallback } from 'jotai/utils'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'

import { syncComponentsAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom, outsideDraggingNode } from '@/atoms/page/state'
import { useSyncHooks } from '@/containers/PageDetail/PageContentV2/Sync/useSyncHooks'
import { copyBlock } from '@/hooks/layoutEngine/utils'
import * as srv from '@/services'
import { flowLayoutBeginAdding } from '@/utils/flowLayoutEventBus'

const StyledList = styled.ul`
    display: flex;
    flex-direction: column;
    gap: 12px;
`

const StyledMenu = styled.button`
    outline: none;
    opacity: 0;
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 2px;
    border-radius: 4px;
    &:hover {
        background-color: var(--color-gray-100);
    }
`

const StyledItem = styled.li`
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    padding: 13.5px 8px 13.5px 16px;
    &:hover {
        background-color: var(--color-gray-50);
        ${StyledMenu} {
            opacity: 1;
        }
    }
`

export const SyncBlockList = () => {
    const syncComponents = useAtomData(syncComponentsAtom)

    const [dragNode, setDragNode] = useAtom(outsideDraggingNode)

    const [renameId, setRenameId] = useState('')
    const [searchValue, setSearchValue] = useState('')

    const filterSyncComponents = useMemo(() => {
        if (!searchValue) {
            return syncComponents
        }
        return syncComponents.filter(item => item.title.includes(searchValue))
    }, [searchValue, syncComponents])

    const handleRename = useAtomCallback((get, set, name: string) => {
        setRenameId('')
        set(syncComponentsAtom, draft => {
            const draftBlock = draft.find(item => item.id === renameId)
            if (!draftBlock) {
                return
            }

            if (draftBlock.title === name || !name) {
                return
            }

            draftBlock.title = name

            srv.updateSyncComponents(current(draft))
        })
    })

    useEffect(() => {
        // 抬起清除拖拽数据
        const mouseupListener = () => {
            if (dragNode) {
                setDragNode(null)
            }
        }
        window.addEventListener('mouseup', mouseupListener)
        return () => {
            window.removeEventListener('mouseup', mouseupListener)
        }
    }, [dragNode, setDragNode])

    const handleCreate = useCallback(
        (ev: React.MouseEvent, block: BlockAbstract) => {
            flowLayoutBeginAdding(ev.nativeEvent)

            setDragNode(block)
        },
        [setDragNode]
    )

    const { rootPageId = '', pageId = '', stackId = '' } = useAtomData(lastPageOfStackAtom) ?? {}
    const { onRemoveSyncNode } = useSyncHooks({ rootPageId, pageId, stackId })

    return (
        <Box p={12}>
            <Input
                prefix={<IconFont type="Search" color="var(--color-gray-400)" />}
                value={searchValue}
                placeholder="搜索"
                size="md"
                clearable
                onChange={e => setSearchValue(e.currentTarget.value)}
                styles={{
                    wrapper: {
                        marginBottom: 16,
                        borderRadius: 6
                    }
                }}
            />
            <StyledList>
                {filterSyncComponents.map(item => (
                    <StyledItem
                        key={item.id}
                        onMouseDown={e => {
                            handleCreate(
                                e,
                                copyBlock([
                                    produce(item, draft => {
                                        draft.synchronousId = draft.id
                                        draft.isMasterSynchronous = undefined
                                        draft.config.viewList = []
                                        draft.children = []
                                    })
                                ])[0]
                            )
                        }}
                    >
                        <IconFont type="SyncComponent" size={20} fill="var(--color-main)" />

                        {renameId === item.id ? (
                            <Input
                                styles={{
                                    wrapper: { height: 16, borderRadius: 0, border: 'none', padding: 0, fontSize: 12 },
                                    input: { padding: '0 !important' }
                                }}
                                autoSelect
                                defaultValue={item.title}
                                onBlur={e => {
                                    const { value } = e.currentTarget
                                    handleRename(value)
                                }}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        const { value } = e.currentTarget
                                        handleRename(value)
                                    }
                                }}
                            />
                        ) : (
                            <Text color="var(--color-gray-500)" size={12} styles={{ root: { height: 16 } }}>
                                {item.title}
                            </Text>
                        )}

                        <Menu width={204} trigger="click" closeOnItemClick returnFocus={false}>
                            <Menu.Target>
                                <StyledMenu onMouseDown={e => e.stopPropagation()}>
                                    <IconFont type="DotsThree" size={16} fill="var(--color-gray-400)" />
                                </StyledMenu>
                            </Menu.Target>
                            <Menu.Dropdown onMouseDown={e => e.stopPropagation()}>
                                <Menu.Item onClick={() => setRenameId(item.id)}>重命名</Menu.Item>
                                <Menu.Item onClick={() => onRemoveSyncNode(item.id)}>删除</Menu.Item>
                            </Menu.Dropdown>
                        </Menu>
                    </StyledItem>
                ))}
            </StyledList>
        </Box>
    )
}
