import { Toast } from '@byecode/ui'
import {
    errorCodeMap, limitExceededCodeList, notFoundCodeList, notFoundTemplateCodeList, noUserCodeList, skipCodeList, useServiceMessageCodeList
} from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import type { AxiosInstance } from 'axios'
import axios from 'axios'
import Qs from 'qs'
import serialize from 'serialize-javascript'

import { openSpaceGrade } from './components/SpaceGrade/event'
import { QuotaMessage } from './components/SpaceQuota/QuotaMessage'
import { ERROR_CODE_QUOTA_KEY_MAP } from './components/SpaceQuota/QuotaMessage/constants'
import type { TokenResult } from './services/types'

export interface Params<T> {
    body?: T
    params?: T
}

export interface HttpResp<T = undefined> {
    success: boolean
    msg: string
    code: string
    content: T
}

const http: AxiosInstance = axios.create({
    // timeout: 8000,
    withCredentials: true,
    baseURL: '/lighthouse',
    headers: {
        'Request-Id': nanoid(20)
    },
    transformRequest: [
        (data, headers) => {
            if (headers?.['Content-Type'] === 'application/json') {
                return serialize(data, { space: 0, isJSON: true })
            }
            if (headers?.['Content-Type'] === 'multipart/form-data') {
                return data
            }
            return Qs.stringify(data)
        }
    ]
})

const getPath = () => {
    const { pathname, search } = window.location
    const url = new URLSearchParams()
    if (pathname === '/') {
        return [undefined, search]
    }
    return [pathname, search]
}

/**
 * 获取token
 * @param payload
 * @returns
 */
const getToken: () => Promise<TokenResult> = async () => {
    const res = await http.get<unknown, HttpResp<TokenResult>>('api/auth/v1/_csrf')
    return res.content
}

http.interceptors.response.use(
    resp => {
        const { data } = resp ?? {}
        const { code, msg } = data ?? {}

        if (notFoundCodeList.includes(code)) {
            window.location.href = '/404'
            return data
        }

        if (notFoundTemplateCodeList.includes(code)) {
            window.location.href = '/template/404'
            return data
        }

        if (skipCodeList.includes(code)) {
            return data
        }

        if (noUserCodeList.includes(code)) {
            return data
        }

        if (Object.hasOwn(ERROR_CODE_QUOTA_KEY_MAP, code)) {
            QuotaMessage({
                type: ERROR_CODE_QUOTA_KEY_MAP[code]
            })
            return data
        }
        if (limitExceededCodeList.includes(code)) {
            requestAnimationFrame(() => {
                Toast.error(`${errorCodeMap[code as keyof typeof errorCodeMap] || '未知错误'}，`, {
                    linkText: '请升级版本',
                    onLinkClick: () => openSpaceGrade('version_upgrade')
                })
            })
            return data
        }

        if (useServiceMessageCodeList.includes(code)) {
            Toast.error(msg)
            return data
        }
        const levelCode = code?.[2]
        // 暂时处理 jwt 失效，走完 refreshAuthLogic 逻辑后，未重新刷新，是因为进到下面 default，被 reject 了
        // 也是属于之前写这块逻辑的时候埋的坑，刷 token 跟后面这些拦截器本来就不应该走同一套拦截器
        if (!levelCode) {
            return Promise.resolve(resp)
        }

        switch (levelCode) {
            case '0': {
                return data
            }
            case '1': {
                const msg = errorCodeMap[code as keyof typeof errorCodeMap]
                if (msg) {
                    requestAnimationFrame(() => {
                        Toast.warning(msg)
                    })
                }
                return data
            }
            case '2': {
                requestAnimationFrame(() => {
                    Toast.error(errorCodeMap[code as keyof typeof errorCodeMap] || '未知错误')
                })
                return Promise.resolve(data)
            }
            default: {
                const message = errorCodeMap[code as keyof typeof errorCodeMap]
                if (message) {
                    requestAnimationFrame(() => {
                        Toast.error(message)
                    })
                }
                return Promise.reject(data)
            }
        }
    },
    // eslint-disable-next-line promise/prefer-await-to-callbacks
    error => {
        const { response, code } = error
        // 2024-07-12 12:01:28 当网络异常时，提示网络异常
        if (code === 'ERR_NETWORK') {
            Toast.error('网络异常，操作失败')
        }
        if (response?.status === 401) {
            const [pathname, search] = getPath()
            const redirect = pathname
            const searchParams = new URLSearchParams(search)
            if (redirect) {
                searchParams.set('redirect', redirect)
            }
            const params = searchParams.toString()
            window.location.href = `/account/login${params ? `?${params}` : ''}`
        }
        if (axios.isCancel(error)) {
            return
        }
        return Promise.reject(error)
    }
)

// Instantiate the interceptor
// createAuthRefreshInterceptor(http, refreshAuthLogic)

// const skipReqUrl = []

http.interceptors.request.use(async config => {
    const rawUrl = config.url

    if (config.headers?.isCsrf) {
        const { headerName, token } = await getToken()
        config.headers = {
            ...config.headers,
            [headerName]: token
        }
    }

    // 排除直接指定完整 url 请求外部接口的情况
    if (!rawUrl || rawUrl?.startsWith('http')) {
        return config
    }

    if (config.headers?.['Content-Type'] === 'application/json' && typeof config.data === 'string') {
        config.data = JSON.parse(config.data)
    }

    return config
})

export default http
