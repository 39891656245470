import { IconFont, Image, Text } from '@byecode/ui'
import { ErrorFallback } from '@lighthouse/block'
import { getFileTypeByUrl, TagIcon, TitleInContentModal, VideoPlayer } from '@lighthouse/shared'
import { animated, easings, useSpring } from '@react-spring/web'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { useKeyPress } from 'react-use'
import styled from 'styled-components'

import SettingTheme from '@/components/ApplicationSetting/SettingTheme'
import { BlockQuestion } from '@/components/PopoverQuestion/BlockQuestion'
import { tooltipDocumentMap } from '@/components/PopoverQuestion/constants'
import type { TooltipDocumentType } from '@/components/PopoverQuestion/types'
import type { BlockTreeItem } from '@/constants/Block/type'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

import BlockCenter from './BlockCenter'
import { BlockList } from './BlockList'
import LayersCenter from './LayersCenter'
import type { TabType } from './Navbar'
import { NavBar } from './Navbar'
import PageCenter from './PageCenter'
import * as CM from './style'

const AnimateDiv = styled(animated.div)`
    height: 100%;
    border-style: solid;
    border-color: var(--color-gray-200);
    border-width: 0 1px 0 1px;
    /* overflow: hidden; */
`

const SCxQuestion = styled.div`
    position: absolute;
    /* left: 344px; */
    top: 16px;
    width: 320px;
    background-color: var(--color-black);
    box-shadow: 0px 4px 12px 0px rgba(16, 24, 40, 0.1);
    border-radius: 12px;
    overflow: hidden;
    z-index: 210;

    .close {
        display: none;
    }
    &:hover .close {
        display: flex;
    }
`

const drawerFromStyle = { x: -240 }

export const PageLeftPanel: React.FC = () => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const [tab, setTab] = useState<TabType>(disabledWithVersion ? 'page' : 'component')

    const [questionType, setQuestionType] = useState<TooltipDocumentType | null>(null)
    const [opened, setOpened] = useState(false)

    const data = questionType ? tooltipDocumentMap[questionType] : undefined
    const fileType = getFileTypeByUrl(data?.file ?? '')
    const drawerToStyle = useMemo(() => ({ x: tab ? 0 : -240 }), [tab])

    const [drawerStyle, drawerApi] = useSpring(() => ({
        from: { x: 0 },
        to: drawerToStyle,
        config: {
            easing: easings.easeOutCirc,
            duration: 300,
        }
    }))

    const handleTabChang = useCallback((newTab: TabType) => {
        setTab(newTab)
    }, [])

    const handleOpenQuestion = useCallback((v: BlockTreeItem) => {
        if (v.type === 'field') {
            setQuestionType(`${v.subType}-input`)
            return
        }
        if (v.type === 'container' || v.type === 'view' || v.type === 'chart') {
            setQuestionType(v.subType)
            return
        }
        setQuestionType(v.type)
    }, [])

    useEffect(() => {
        drawerApi.start(tab ? { x: 0 } : drawerFromStyle)
    }, [drawerApi, tab])

    useKeyPress(e => {
        if (e.code === 'Escape') {
            setQuestionType(null)
        }
        return true
    })

    const PanelEle = useMemo(() => {
        switch (tab) {
            case 'page': {
                return <PageCenter />
            }

            case 'layers': {
                return <LayersCenter />
            }

            case 'component': {
                return <BlockCenter onBack={() => handleTabChang(null)} onOpenQuestion={handleOpenQuestion} />
            }
            case 'theme': {
                return <SettingTheme />
            }
            default: {
                return null
            }
        }
    }, [handleOpenQuestion, handleTabChang, tab])

    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <CM.ComponentPanelContainer>
                <NavBar value={tab} onTabChange={handleTabChang} />
                <CM.Chunk style={{ width: tab ? 264 : 0 }}>
                    <AnimateDiv style={{ ...drawerStyle, translateX: drawerStyle.x }}>{PanelEle}</AnimateDiv>
                </CM.Chunk>
                {questionType && !opened && (
                    <SCxQuestion style={{ left: tab ? 264 + 72 + 16 : 80 }}>
                        <TagIcon
                            icon="Close"
                            radius={100}
                            size={24}
                            className="close"
                            iconColor="#fff"
                            background="rgba(99,96,96, 0.6)"
                            style={{ position: 'absolute', right: 10, top: 10, zIndex: 10 }}
                            enableHover
                            onClick={() => setQuestionType(null)}
                        />
                        <BlockQuestion
                            type={questionType}
                            onLookVideo={() => {
                                setOpened(true)
                            }}
                        />
                    </SCxQuestion>
                )}
                <TitleInContentModal
                    open={opened}
                    width={738}
                    onClose={() => {
                        setQuestionType(null)
                        setOpened(false)
                    }}
                    styles={{
                        desktop: {
                            modal: {
                                borderStyle: 'none',
                                '&:hover': {
                                    '.byecode-Modal-close': {
                                        display: 'flex'
                                    }
                                }
                            },
                            close: {
                                display: 'none',
                                backgroundColor: 'rgba(99,96,96, 0.6)!important',
                                'iconpark-icon': {
                                    color: '#fff!important'
                                }
                            }
                        }
                    }}
                >
                    <div style={{ background: fileType === 'image' ? 'var(--color-white)' : 'var(--color-black)', filter: 'grayscale(0)' }}>
                        {data?.file ? (
                            fileType === 'image' ? (
                                <Image src={data?.file} />
                            ) : (
                                <video src={data?.file} style={{ width: '100%', display: 'block' }} autoPlay controls />
                            )
                        ) : null}
                    </div>
                    <div style={{ padding: 24 }}>
                        <Text whiteSpace="pre-wrap" size={16} lineHeight="24px">
                            {data?.describe}
                        </Text>
                    </div>
                </TitleInContentModal>
            </CM.ComponentPanelContainer>
        </ErrorBoundary>
    )
}
