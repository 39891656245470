import type { PanelMode } from '@byecode/ui'
import { Calendar } from '@byecode/ui'
import type { DateField, VariableFieldADTValue } from '@lighthouse/core'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'

import { defaultFormat } from '../../../../constants'

interface DateCalendarProps {
    value?: Date
    config: DateField['date']
    mode?: PanelMode
    onChange: (value: VariableFieldADTValue) => void
}

const DateCalendar: React.FunctionComponent<DateCalendarProps> = ({ value, config, mode, onChange }) => {
    const showTime = useMemo(() => (config?.format ? config.format.includes('HH:mm') : true), [config?.format])

    // 各种事件变化中的预览日期
    const [internalDate, setInternalDate] = useState<Date | undefined>()

    const handleChange = useCallback(
        (v?: Date) => {
            onChange({ type: 'date', value: v?.valueOf() ?? '', format: defaultFormat })
        },
        [onChange]
    )

    useUpdateEffect(() => {
        setInternalDate(value)
    }, [value])

    return (
        <Calendar
            style={{ width: 320 }}
            showTime={showTime}
            disableSecond
            date={internalDate}
            onDateChange={val => {
                setInternalDate(val)
            }}
            mode={mode}
            onConfirm={handleChange}
        />
    )
}

export default DateCalendar
