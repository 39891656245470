import type { Direction, LayoutSize, SizeConfigure } from '@lighthouse/core'
import { DIRECTION } from '@lighthouse/core'

import type { NodeIdWithScope, Rect } from '../types'
import type { LayoutInteractionInstance, LayoutInteractionPlugin } from './LayoutInteractionEngine'
import {
    DOM_DATA_NODE_SCOPE,
    DOM_DATA_PARENT_NAME,
    findNodeById,
    findNodeDom,
    layoutInteractionEventBus
} from './utils'

const RESIZE_HANDLER_SIZE = 8

const DOM_DATA_RESIZE_NAME = 'data-layout-resize-name'

export type ResizeEvent = {
    direction: Direction
    size: number
}

export type ResizeRestrict = Omit<LayoutSize, 'width' | 'height' | 'overflow'> & {
    direction: Direction[]
}

interface DragEngineConstructorParams {
    getResizeRestrict?: (node: NodeIdWithScope) => ResizeRestrict
    onResizeStart?: (node: NodeIdWithScope, event: ResizeEvent) => void
    onResizing?: (node: NodeIdWithScope, event: ResizeEvent) => void
    onResizeEnd?: (node: NodeIdWithScope, event: ResizeEvent) => void
}

export class ResizePlugin implements LayoutInteractionPlugin {
    name = 'RESIZE_PLUGIN'

    #isResizing = false

    #resizeOverlayDomRefs: {
        container: HTMLElement
        top: HTMLElement
        right: HTMLElement
        bottom: HTMLElement
        left: HTMLElement
    } | null = null

    #resizeTriggerRef: HTMLElement | null = null

    #resizeInitialCoordinate = {
        x: 0,
        y: 0
    }

    #resizeDelta = {
        x: 0,
        y: 0
    }

    #coreInstance: LayoutInteractionInstance | null = null

    #getResizeRestrict?: DragEngineConstructorParams['getResizeRestrict']

    #onResizeStart?: DragEngineConstructorParams['onResizeStart']

    #onResizing?: DragEngineConstructorParams['onResizing']

    #onResizeEnd?: DragEngineConstructorParams['onResizeEnd']

    constructor(props?: DragEngineConstructorParams) {
        this.#getResizeRestrict = props?.getResizeRestrict
        this.#onResizeStart = props?.onResizeStart
        this.#onResizing = props?.onResizing
        this.#onResizeEnd = props?.onResizeEnd
    }

    init(instance: LayoutInteractionInstance) {
        this.#coreInstance = instance

        this.#drawResizeHandler()

        layoutInteractionEventBus.on('updateConfig', this.#handleConfigUpdate)
    }

    destroy() {
        this.#clear()
        layoutInteractionEventBus.off('updateConfig', this.#handleConfigUpdate)
    }

    onNodesRectUpdate = () => {
        this.#drawResizeHandler()
    }

    #handleConfigUpdate = () => {
        this.#drawResizeHandler()
    }

    #drawResizeHandler() {
        if (!this.#coreInstance) {
            return
        }

        this.#clearResizeHandler()

        const nodes = this.#coreInstance.selectedIds
        if (nodes.length !== 1) {
            return
        }

        const node = nodes[0]

        const boundaryRect = this.#coreInstance.boundary.getBoundingClientRect()

        const rect = this.#coreInstance.nodesMap.get(`${node.scope || ''}@${node.id}`)
        if (!rect) {
            return
        }

        const scale = this.#coreInstance.boundary === this.#coreInstance.root ? this.#coreInstance.scale : 1

        const resizeOverlay = document.createElement('div')
        const offsetX = (-boundaryRect.left + rect.left) / scale
        const offsetY = (-boundaryRect.top + rect.top) / scale
        resizeOverlay.style.position = 'absolute'
        resizeOverlay.style.zIndex = '10'
        resizeOverlay.style.left = '0'
        resizeOverlay.style.top = '0'
        resizeOverlay.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0)`
        resizeOverlay.style.width = `${rect.width / scale}px`
        resizeOverlay.style.height = `${rect.height / scale}px`
        resizeOverlay.style.pointerEvents = 'none'

        const resizeRestrict = this.#getResizeRestrict?.(node)

        const topHandler = document.createElement('div')
        topHandler.setAttribute(DOM_DATA_RESIZE_NAME, 'top')
        topHandler.style.position = 'absolute'
        topHandler.style.left = '0px'
        topHandler.style.top = `-${RESIZE_HANDLER_SIZE / 2 / scale}px`
        topHandler.style.width = '100%'
        topHandler.style.height = `${RESIZE_HANDLER_SIZE / scale}px`
        if (!resizeRestrict || resizeRestrict.direction.includes(DIRECTION.vertical)) {
            topHandler.style.cursor = 'ns-resize'
            topHandler.style.pointerEvents = 'auto'
        }

        const rightHandler = document.createElement('div')
        rightHandler.setAttribute(DOM_DATA_RESIZE_NAME, 'right')
        rightHandler.style.position = 'absolute'
        rightHandler.style.right = `-${RESIZE_HANDLER_SIZE / 2 / scale}px`
        rightHandler.style.top = '0px'
        rightHandler.style.width = `${RESIZE_HANDLER_SIZE / scale}px`
        rightHandler.style.height = '100%'
        if (!resizeRestrict || resizeRestrict.direction.includes(DIRECTION.horizontal)) {
            rightHandler.style.cursor = 'ew-resize'
            rightHandler.style.pointerEvents = 'auto'
        }

        const bottomHandler = document.createElement('div')
        bottomHandler.setAttribute(DOM_DATA_RESIZE_NAME, 'bottom')
        bottomHandler.style.position = 'absolute'
        bottomHandler.style.left = '0px'
        bottomHandler.style.bottom = `-${RESIZE_HANDLER_SIZE / 2 / scale}px`
        bottomHandler.style.width = '100%'
        bottomHandler.style.height = `${RESIZE_HANDLER_SIZE / scale}px`
        if (!resizeRestrict || resizeRestrict.direction.includes(DIRECTION.vertical)) {
            bottomHandler.style.cursor = 'ns-resize'
            bottomHandler.style.pointerEvents = 'auto'
        }

        const leftHandler = document.createElement('div')
        leftHandler.setAttribute(DOM_DATA_RESIZE_NAME, 'left')
        leftHandler.style.position = 'absolute'
        leftHandler.style.left = `-${RESIZE_HANDLER_SIZE / 2 / scale}px`
        leftHandler.style.top = '0px'
        leftHandler.style.width = `${RESIZE_HANDLER_SIZE / scale}px`
        leftHandler.style.height = '100%'
        if (!resizeRestrict || resizeRestrict.direction.includes(DIRECTION.horizontal)) {
            leftHandler.style.cursor = 'ew-resize'
            leftHandler.style.pointerEvents = 'auto'
        }

        if (!resizeRestrict || resizeRestrict.direction.includes(DIRECTION.horizontal)) {
            rightHandler.addEventListener('mousedown', this.#resizeStartHandler)
            leftHandler.addEventListener('mousedown', this.#resizeStartHandler)
        }

        if (!resizeRestrict || resizeRestrict.direction.includes(DIRECTION.vertical)) {
            topHandler.addEventListener('mousedown', this.#resizeStartHandler)
            bottomHandler.addEventListener('mousedown', this.#resizeStartHandler)
        }

        resizeOverlay.append(topHandler, rightHandler, bottomHandler, leftHandler)
        this.#coreInstance.boundary.append(resizeOverlay)
        this.#resizeOverlayDomRefs = {
            container: resizeOverlay,
            top: topHandler,
            right: rightHandler,
            bottom: bottomHandler,
            left: leftHandler
        }
    }

    #updateResizeHandler(rect: Rect) {
        if (!this.#coreInstance) {
            return
        }

        if (!this.#resizeOverlayDomRefs) {
            return
        }

        const scale = this.#coreInstance.boundary === this.#coreInstance.root ? this.#coreInstance.scale : 1

        const { container } = this.#resizeOverlayDomRefs
        const boundaryRect = this.#coreInstance.boundary.getBoundingClientRect()
        const offsetX = (-boundaryRect.left + rect.left) / scale
        const offsetY = -boundaryRect.top + rect.top / scale
        container.style.transform = `translate3d(${offsetX}px, ${offsetY}px, 0)`
        container.style.width = `${rect.width / scale}px`
        container.style.height = `${rect.height / scale}px`
    }

    #resizeStartHandler = (e: MouseEvent) => {
        e.stopPropagation()

        const target = e.target as HTMLElement

        const resizeName = target.getAttribute(DOM_DATA_RESIZE_NAME)
        if (!resizeName) {
            return
        }

        const direction = resizeName === 'left' || resizeName === 'right' ? DIRECTION.horizontal : DIRECTION.vertical

        document.documentElement.style.setProperty('cursor', direction === DIRECTION.vertical ? 'ns-resize' : 'ew-resize', 'important')
        document.documentElement.style.setProperty('pointer-events', 'none', 'important')

        if (!this.#coreInstance) {
            return
        }

        const nodes = this.#coreInstance.selectedIds
        if (nodes.length !== 1) {
            return
        }
        const node = nodes[0]

        const initRect = this.#coreInstance.nodesMap.get(`${node.scope || ''}@${node.id}`)
        if (!initRect) {
            return
        }

        const parentId = target.getAttribute(DOM_DATA_PARENT_NAME)
        const parentScope = target.getAttribute(DOM_DATA_NODE_SCOPE) || ''

        const parentRect = parentId ? this.#coreInstance.nodesMap.get(`${parentScope}@${parentId}`) : undefined

        const resizeRestrict = this.#getResizeRestrict?.(node)

        this.#onResizeStart?.(node, {
            direction,
            size:
                direction === DIRECTION.horizontal
                    ? Math.min(
                          getMaxPxSize(resizeRestrict?.maxWidth, parentRect ? parentRect.width : 0),
                          Math.max(
                              getMinPxSize(resizeRestrict?.minWidth, parentRect ? parentRect.width : 0),
                              initRect.width / this.#coreInstance.scale
                          )
                      )
                    : Math.min(
                          getMaxPxSize(resizeRestrict?.maxHeight, parentRect ? parentRect.height : 0),
                          Math.max(
                              getMinPxSize(resizeRestrict?.minHeight, parentRect ? parentRect.height : 0),
                              initRect.height / this.#coreInstance.scale
                          )
                      )
        })

        this.#isResizing = true
        this.#coreInstance.preventObserveCallback = true
        this.#resizeInitialCoordinate = {
            x: e.clientX,
            y: e.clientY
        }
        this.#resizeTriggerRef = target

        document.addEventListener('mousemove', this.#resizingHandler)
        document.addEventListener('mouseup', this.#resizeEndHandler)
    }

    #resizingHandler = (e: MouseEvent) => {
        if (!this.#coreInstance) {
            return
        }
        if (!this.#isResizing || !this.#resizeTriggerRef || !this.#resizeOverlayDomRefs) {
            return
        }

        const nodes = this.#coreInstance.selectedIds
        if (nodes.length !== 1) {
            return
        }
        const node = nodes[0]

        window.getSelection()?.removeAllRanges()

        const resizeName = this.#resizeTriggerRef.getAttribute(DOM_DATA_RESIZE_NAME)
        if (!resizeName) {
            return
        }
        const isResizeWidth = resizeName === 'left' || resizeName === 'right'
        this.#resizeDelta = {
            x: (e.clientX - this.#resizeInitialCoordinate.x) * (resizeName === 'left' ? -1 : 1),
            y: (e.clientY - this.#resizeInitialCoordinate.y) * (resizeName === 'top' ? -1 : 1)
        }

        const initRect = this.#coreInstance.nodesMap.get(`${node.scope || ''}@${node.id}`)
        if (!initRect) {
            return
        }

        const target = findNodeDom(node, this.#coreInstance.root)
        if (!target) {
            return
        }

        const parentId = target.getAttribute(DOM_DATA_PARENT_NAME)
        const parentScope = target.getAttribute(DOM_DATA_NODE_SCOPE) || ''

        const parentRect = parentId ? this.#coreInstance.nodesMap.get(`${parentScope}@${parentId}`) : undefined
        const parentNode = parentId ? findNodeById(parentId, parentScope)(this.#coreInstance.data) : undefined
        const parentDirection = parentNode?.data?.layout?.align?.direction || DIRECTION.vertical

        const resizeRestrict = this.#getResizeRestrict?.(node)

        const size = isResizeWidth
            ? Math.min(
                  getMaxPxSize(resizeRestrict?.maxWidth, parentRect ? parentRect.width : 0),
                  Math.max(
                      getMinPxSize(resizeRestrict?.minWidth, parentRect ? parentRect.width : 0),
                      initRect.width / this.#coreInstance.scale + this.#resizeDelta.x
                  )
              )
            : Math.min(
                  getMaxPxSize(resizeRestrict?.maxHeight, parentRect ? parentRect.height : 0),
                  Math.max(
                      getMinPxSize(resizeRestrict?.minHeight, parentRect ? parentRect.height : 0),
                      initRect.height / this.#coreInstance.scale + this.#resizeDelta.y
                  )
              )

        if (isResizeWidth) {
            target.style.width = `${size}px`
            if (parentDirection === DIRECTION.horizontal) {
                target.style.flex = '0 0 auto'
            }
        } else {
            target.style.height = `${size}px`
            if (parentDirection === DIRECTION.vertical) {
                target.style.flex = '0 0 auto'
            }
        }

        const direction = isResizeWidth ? DIRECTION.horizontal : DIRECTION.vertical

        this.#onResizing?.(node, {
            direction,
            size
        })

        const newRect = target.getBoundingClientRect()
        this.#updateResizeHandler(newRect)
        this.#coreInstance.updateSelectedBorder(node, newRect)
    }

    #resizeEndHandler = () => {
        document.documentElement.style.removeProperty('cursor')
        document.documentElement.style.removeProperty('pointer-events')
        if (!this.#coreInstance) {
            return
        }
        const nodes = this.#coreInstance.selectedIds
        if (nodes.length !== 1) {
            return
        }
        const node = nodes[0]

        const initRect = this.#coreInstance?.nodesMap.get(`${node.scope || ''}@${node.id}`)
        if (!initRect) {
            return
        }

        const resizeName = this.#resizeTriggerRef?.getAttribute(DOM_DATA_RESIZE_NAME)
        if (!resizeName) {
            return
        }
        const isResizeWidth = resizeName === 'left' || resizeName === 'right'
        const direction = isResizeWidth ? DIRECTION.horizontal : DIRECTION.vertical
        const resizeRestrict = this.#getResizeRestrict?.(node)

        const target = findNodeDom(node, this.#coreInstance.root)
        if (!target) {
            return
        }

        const parentId = target.getAttribute(DOM_DATA_PARENT_NAME)
        const parentRect = parentId
            ? this.#coreInstance.nodesMap.get(`${node.scope || ''}@${parentId}`) || this.#coreInstance.nodesMap.get(`@${parentId}`)
            : undefined

        this.#onResizeEnd?.(node, {
            direction,
            size:
                direction === DIRECTION.horizontal
                    ? Math.min(
                          getMaxPxSize(resizeRestrict?.maxWidth, parentRect ? parentRect.width : 0),
                          Math.max(
                              getMinPxSize(resizeRestrict?.minWidth, parentRect ? parentRect.width : 0),
                              initRect.width / this.#coreInstance.scale + this.#resizeDelta.x
                          )
                      )
                    : Math.min(
                          getMaxPxSize(resizeRestrict?.maxHeight, parentRect ? parentRect.height : 0),
                          Math.max(
                              getMinPxSize(resizeRestrict?.minHeight, parentRect ? parentRect.height : 0),
                              initRect.height / this.#coreInstance.scale + this.#resizeDelta.y
                          )
                      )
        })

        this.#isResizing = false
        this.#coreInstance.preventObserveCallback = false
        this.#resizeTriggerRef = null
        this.#resizeInitialCoordinate = {
            x: 0,
            y: 0
        }

        this.#resizeDelta = {
            x: 0,
            y: 0
        }
        this.#coreInstance?.collectNodes()
        document.removeEventListener('mousemove', this.#resizingHandler)
        document.removeEventListener('mouseup', this.#resizeEndHandler)
    }

    #clear() {
        document.documentElement.style.cursor = ''
        document.documentElement.style.pointerEvents = ''

        this.#clearResizeHandler()
    }

    #clearResizeHandler() {
        if (this.#resizeOverlayDomRefs) {
            this.#resizeOverlayDomRefs.top.removeEventListener('mousedown', this.#resizeStartHandler)
            this.#resizeOverlayDomRefs.top.remove()
            this.#resizeOverlayDomRefs.right.removeEventListener('mousedown', this.#resizeStartHandler)
            this.#resizeOverlayDomRefs.right.remove()
            this.#resizeOverlayDomRefs.bottom.removeEventListener('mousedown', this.#resizeStartHandler)
            this.#resizeOverlayDomRefs.bottom.remove()
            this.#resizeOverlayDomRefs.left.removeEventListener('mousedown', this.#resizeStartHandler)
            this.#resizeOverlayDomRefs.left.remove()
            this.#resizeOverlayDomRefs.container.remove()

            this.#resizeOverlayDomRefs = null
        }
    }
}

function getMinPxSize(size: Partial<SizeConfigure> | undefined, parentSize: number) {
    if (!size) {
        return 0
    }

    if (!size.size || typeof size.size !== 'number') {
        return 0
    }

    switch (size.unit) {
        case 'px':
        case undefined: {
            return size.size
        }

        case '%': {
            return (parentSize * size.size) / 100
        }

        default: {
            return 0
        }
    }
}

function getMaxPxSize(size: Partial<SizeConfigure> | undefined, parentSize: number) {
    if (!size) {
        return Number.MAX_SAFE_INTEGER
    }

    if (!size.size || typeof size.size !== 'number') {
        return Number.MAX_SAFE_INTEGER
    }

    switch (size.unit) {
        case 'px':
        case undefined: {
            return size.size
        }

        case '%': {
            return (parentSize * size.size) / 100
        }

        default: {
            return Number.MAX_SAFE_INTEGER
        }
    }
}
