import type { FloatBlockConfig } from '@lighthouse/core'
import { FLEX_ALIGN, SubFormBlockConfig } from '@lighthouse/core'

export const generateFloatBlock = (): FloatBlockConfig => {
    return {
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            size: {
                width: {
                    size: 200
                },
                height: {
                    size: 150
                },
                overflow: 'hidden'
            },
            layout: {
                align: {
                    direction: 'vertical',
                    isDistributedAlignment: false,
                    alignX: FLEX_ALIGN['flex-start'],
                    alignY: FLEX_ALIGN['flex-start']
                },
                gap: 8,
                padding: [4, 4, 4, 4]
            },
            design: {
                radius: [10, 10, 10, 10],
                background: {
                    type: 'color',
                    color: '#ffffff'
                },
                blur: 0,
                shadow: {
                    x: 0,
                    y: 2,
                    diffusion: 2,
                    blur: 5,
                    color: '#00000014'
                }
            },
            overlay: {
                showOn: 'hover',
                dismiss: 'hover',
                position: 'bottom',
                align: 'center',
                offset: [0, 0],
                autoPosition: true,
                autoDistance: 16
            }
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
