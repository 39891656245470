import { Flex, Group, IconFont, Modal, SegmentedControl, Text, Tooltip } from '@byecode/ui'
import { ListItem4ByecodeUi, TagIcon, useAtomAction, useAtomData } from '@lighthouse/shared'
import * as React from 'react'
import { useCallback, useMemo } from 'react'

import { updateAppAtom, updateAppVersionConfigAtom } from '@/atoms/application/action'
import { websiteApplicationSettingAtom } from '@/atoms/application/state'
import { updatePageAtom } from '@/atoms/page/action'
import { lastPageOfStackAtom, pageAtomFamily, pageStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'

interface NavbarVisibilityProps {
    pageId: string
}

const NAVBAR_SHOW_OPTIONS = [
    {
        label: '显示',
        value: '1'
    },
    {
        label: '隐藏',
        value: '0'
    }
]

const NavbarVisibility: React.FunctionComponent<NavbarVisibilityProps> = ({ pageId }) => {
    const [rootPageId, stackId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(stack => [stack?.rootPageId ?? '', stack?.stackId ?? ''], [])
    )
    const isShowPageNavbar = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => s?.isShowNavbar ?? false, [])
    )
    const navbar = useAtomData(
        websiteApplicationSettingAtom,
        useCallback(s => s?.navbar, [])
    )

    const showNavbar = useMemo(() => {
        if (navbar?.isNavbarVisibilityLockPage) {
            return isShowPageNavbar
        }
        return navbar?.isShowNavbar
    }, [isShowPageNavbar, navbar?.isNavbarVisibilityLockPage, navbar?.isShowNavbar])

    const { run: updatePage } = useAtomAction(updatePageAtom)
    const { run: updateAppVersionConfig } = useAtomAction(updateAppVersionConfigAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    return (
        <Group label="全局导航栏">
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <div>导航栏显隐</div>
                <Flex gap={8}>
                    <SegmentedControl
                        data={NAVBAR_SHOW_OPTIONS}
                        value={Number(showNavbar).toString()}
                        onChange={v => {
                            if (!navbar?.isNavbarVisibilityLockPage && navbar) {
                                updateAppVersionConfig({
                                    config: { navbar: { ...navbar, isShowNavbar: Boolean(Number(v)) } }
                                })
                                return
                            }
                            updatePage({ id: pageId, isShowNavbar: Boolean(Number(v)) })
                        }}
                    />
                    <Tooltip
                        title={
                            <p style={{ width: 200, lineHeight: '20px', fontSize: 12 }}>
                                全局设置开关：启用后，导航栏的显隐设置影响所有页面；关闭后，可以为每个页面单独设置导航栏的显隐配置
                            </p>
                        }
                    >
                        <div
                            onClick={async () => {
                                if (navbar?.isNavbarVisibilityLockPage) {
                                    const res = await Modal.confirm({
                                        okStatus: 'primary',
                                        okText: '确定',
                                        title: '确定将导航栏显隐设为全局配置？',
                                        content: `启用后，所有页面的导航栏都将设为“${showNavbar ? '显示' : '隐藏'}”。`
                                    })
                                    if (res && navbar) {
                                        updateAppVersionConfig({
                                            config: { navbar: { ...navbar, isNavbarVisibilityLockPage: false, isShowNavbar: showNavbar } }
                                        })
                                    }
                                    return
                                }
                                navbar &&
                                    updateAppVersionConfig({
                                        config: { navbar: { ...navbar, isNavbarVisibilityLockPage: true } }
                                    })
                            }}
                        >
                            {navbar?.isNavbarVisibilityLockPage ? (
                                <TagIcon
                                    icon="LinkBreak"
                                    size={32}
                                    radius={6}
                                    enableHover
                                    background="var(--color-gray-100)"
                                    iconColor="var(--color-gray-400)"
                                    hoverColor="var(--color-gray-900)"
                                    hoverBackground="var(--color-gray-200)"
                                />
                            ) : (
                                <TagIcon
                                    icon="PropertyLink"
                                    size={32}
                                    radius={6}
                                    enableHover
                                    background="var(--color-theme-4)"
                                    hoverColor="var(--color-theme-7)"
                                    hoverBackground="var(--color-theme-5)"
                                    iconColor="var(--color-theme-7)"
                                />
                            )}
                        </div>
                    </Tooltip>
                </Flex>
            </ListItem4ByecodeUi>
            {showNavbar && (
                <ListItem4ByecodeUi
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        setPageStack(draft => {
                            const stack = equalPageStack({ rootPageId, stackId })(draft)
                            if (stack) {
                                stack.state.selectedNodes = []
                                stack.state.asideType = AsideType.NAVBAR
                            }
                        })
                    }}
                >
                    <div>配置导航栏</div>
                    <Flex>
                        <Text color="var(--color-gray-400)">点击前往</Text>
                        <IconFont size={16} type="ArrowRightSmall" color="var(--color-gray-400)" />
                    </Flex>
                </ListItem4ByecodeUi>
            )}
        </Group>
    )
}

export default NavbarVisibility
