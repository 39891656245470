import { Button, Group, TextInput } from '@mantine/core'
import type { FC } from 'react'
import React, { useState } from 'react'

import { useLanguageContext } from '../../../contexts'

interface DropdownLinkProps {
    onConfirm: (value: string) => void
}

export const DropdownLink: FC<DropdownLinkProps> = ({ onConfirm }) => {
    const [value, setValue] = useState<string | undefined>()
   const { convertTextByLanguage } = useLanguageContext()
    return (
        <Group style={{ gap: 12, padding: 0 }}>
            <TextInput value={value} onChange={e => setValue(e.target.value)} placeholder={convertTextByLanguage('pasteLink')} />
            <Button px={7} disabled={!value} onClick={() => value && onConfirm(value)}>
                {convertTextByLanguage('sure')}
            </Button>
        </Group>
    )
}
