import { Flex, IconFont, Input, Toast } from '@byecode/ui'
import type { ContainerBreakPointProtocol } from '@lighthouse/core';
import { AnimationState,AnimationTypeEnum  } from '@lighthouse/core'
import { TagIcon, useAtomAction, useAtomData } from '@lighthouse/shared'
import { find, findLast } from 'rambda'
import React from 'react'
import { useFormContext } from 'react-hook-form'

import { blockAnimationStateAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom } from '@/atoms/page/state'

import { useSetExtendsKeys } from '../hooks/useMouseEventDistance'
import { animationNameMap } from './util'

interface AnimationPreviewProps {
    type: AnimationTypeEnum
    isPlayed: boolean
    disabled?: boolean
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const AnimationPreview = React.forwardRef<HTMLDivElement, AnimationPreviewProps>(({ type, isPlayed, disabled, onClick }, ref) => {
    const title = animationNameMap[type]
    const stack = useAtomData(lastPageOfStackAtom)
    const { state: { selectedNodes = [] } = {} } = stack ?? {}
    const selectNode = selectedNodes?.[0] ?? {}
    const { run: setBlockAnimationStateAtom } = useAtomAction(blockAnimationStateAtom)
    const { watch, setValue } = useFormContext<ContainerBreakPointProtocol>()
    const { handleSetBreakKey } = useSetExtendsKeys()
    const animation = watch(`breakPoint.animation.${type}`)

    return (
        <Flex gap={8} ref={ref} onClick={onClick}>
            <Input
                style={{ width: 180 }}
                styles={{
                    input: {
                        cursor: 'pointer'
                    }
                }}
                value={title}
                readOnly
                prefix={<TagIcon icon="Stars" size={24} iconColor="#fff" background="var(--color-theme-7)" radius={4} color="#fff" />}
                suffix={
                    <TagIcon
                        icon="CloseCircle"
                        hoverColor="var(--color-black)"
                        enableHover
                        size={16}
                        onClick={e => {
                            if (disabled) {
                                return
                            }
                            e.stopPropagation()
                            setValue(`breakPoint.animation.${type}`, undefined)
                            handleSetBreakKey(`breakPoint.animation.${type}`)
                        }}
                    />
                }
            />
            <TagIcon
                icon={isPlayed ? 'Stop' : 'Play'}
                size={32}
                iconColor="var(--color-gray-400)"
                background="var(--color-gray-100)"
                hoverColor="var(--color-black)"
                hoverBackground="var(--color-gray-200)"
                radius={6}
                onClick={async e => {
                    e.stopPropagation()
                    if (animation?.type === AnimationTypeEnum.layerInView && !animation.enter) {
                        Toast.error('动效配置未完善，无法预览')
                        return
                    }
                    if (isPlayed) {
                        setBlockAnimationStateAtom(draft => {
                            draft.forEach((v, i) => {
                                draft[i].played = false
                                draft[i].state = AnimationState.Idle
                            })
                        })
                    } else {
                        await setBlockAnimationStateAtom(draft => {
                            draft.forEach((v, i) => {
                                draft[i].played = false
                                draft[i].state = AnimationState.Idle
                            })
                        })

                        setBlockAnimationStateAtom(draft => {
                            const animate = findLast(v => v.blockId === selectNode.id && v.scope === selectNode.scope, draft)
                            if (animate) {
                                // 播放当前动效
                                animate.played = true
                                animate.state = AnimationState.Idle
                                animate.type = type
                            } else {
                                // 添加当前动效到队列
                                draft.push({
                                    state: AnimationState.Idle,
                                    blockId: selectNode.id,
                                    scope: selectNode.scope,
                                    type,
                                    played: true
                                })
                            }
                        })
                    }
                }}
            />
        </Flex>
    )
})

export default AnimationPreview
