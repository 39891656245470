import type { AutoLayout, Direction, LayoutPosition, LayoutSize, SizeConfigure } from '@lighthouse/core'
import { DIRECTION } from '@lighthouse/core'
import { isEmpty } from 'rambda'

import { PAGE_CONTAINER_HOST } from '../constants'

function getWidthSize(configure: SizeConfigure, parentDirection: Direction = DIRECTION.horizontal, sortable = false, scale = 1) {
    if (configure?.size === 'fill') {
        if (parentDirection === DIRECTION.horizontal) {
            return {
                flex: '1 0 0'
            }
        }

        return {
            width: '100%'
        }
    }

    if (configure?.size === 'auto') {
        if (parentDirection === DIRECTION.vertical) {
            return {
                flex: '0 0 auto'
            }
        }
        return {}
    }
    if (sortable && configure.unit === 'vw') {
        const pageContainer = document.querySelector<HTMLDivElement>(PAGE_CONTAINER_HOST)
        if (!pageContainer) {
            return { width: `${configure?.size ?? 0}${configure?.unit || 'px'}` }
        }
        const size = configure.size ?? 0
        const pageContainerRect = pageContainer.getBoundingClientRect()
        const width = Number(pageContainerRect.width * (size / scale / 100)).toFixed(2)
        return { width: `${width}px` }
    }

    return { width: `${configure?.size ?? 0}${configure?.unit || 'px'}` }
}
function getHeightSize(configure: SizeConfigure | undefined, parentDirection: Direction = DIRECTION.horizontal, sortable = false, scale = 1) {
    if (!configure) {
        return {}
    }
    if (configure?.size === 'fill') {
        if (parentDirection === DIRECTION.vertical) {
            return {
                flex: '1 0 0'
            }
        }

        return {
            height: '100%'
        }
    }

    if (configure?.size === 'auto') {
        if (parentDirection === DIRECTION.vertical) {
            return {
                flex: '0 0 auto'
            }
        }
        return {}
    }

    if (sortable && configure.unit === 'vh') {
        const pageContainer = document.querySelector<HTMLDivElement>(PAGE_CONTAINER_HOST)
        if (!pageContainer) {
            return { height: `${configure?.size ?? 0}${configure?.unit || 'px'}` }
        }
        const windowHeight = window.innerHeight
        const size = configure.size ?? 0
        const pageContainerRect = pageContainer.getBoundingClientRect()
        const height = Number(pageContainerRect.height * (size / scale / 100)).toFixed(2)
        return { height: `${height}px` }
    }

    return { height: `${configure?.size ?? 0}${configure?.unit || 'px'}` }
}

function getMinMaxSize(configure: Partial<SizeConfigure> | undefined) {
    if (configure?.size === 'auto') {
        return undefined
    }
    if (configure?.size === 'fill') {
        return '100%'
    }
    if (configure?.size) {
        return `${configure.size}${configure?.unit || 'px'}`
    }
    return undefined
}

export function getSizeStyle(layoutSize?: LayoutSize, parentDirection: Direction = DIRECTION.horizontal, sortable = false, scale = 1): React.CSSProperties {
    if (!layoutSize) {
        return {}
    }

    return {
        ...getWidthSize(layoutSize.width, parentDirection, sortable, scale),
        minWidth: getMinMaxSize(layoutSize.minWidth) || 0,
        maxWidth: getMinMaxSize(layoutSize.maxWidth),
        ...getHeightSize(layoutSize.height, parentDirection, sortable, scale),
        minHeight: getMinMaxSize(layoutSize.minHeight) || 0,
        maxHeight: getMinMaxSize(layoutSize.maxHeight)
    }
}

export function getLayoutStyle(layout?: AutoLayout): React.CSSProperties {
    if (!layout) {
        return {}
    }

    const isVertical = layout.align?.direction === DIRECTION.vertical
    const isDistributedAlignment = !!layout.align?.isDistributedAlignment

    return {
        display: 'flex',
        flexDirection: isVertical ? 'column' : 'row',
        justifyContent: isDistributedAlignment ? 'space-between' : isVertical ? layout.align?.alignY : layout.align?.alignX,
        alignItems: isVertical ? layout.align?.alignX : layout.align?.alignY,
        gap: layout.gap && `${layout.gap}px`
    }
}

export function getPositionStyle(position?: LayoutPosition, level = 1): React.CSSProperties {
    if (!position || !position.type) {
        return {
            position: 'relative'
        }
    }
    if (position.type === 'relative') {
        return {
            position: 'relative'
        }
    }
    const verticalKey = position.location?.vertical === 'bottom' ? 'bottom' : 'top'
    if (position.type === 'sticky') {

        return {
            position: 'sticky',
            [verticalKey]: position.location?.verticalValue,
            zIndex: level + 1
        }
    }

    const horizontalKey = position.location?.horizontal === 'right' ? 'right' : 'left'


    return {
        position: position.type,
        [horizontalKey]: position.location?.horizontalValue,
        [verticalKey]: position.location?.verticalValue,
        zIndex: level + 1
    }
}
