import type { BlockAbstract, ChartBlockAbstract, FieldBlockAbstract, ViewBlockAbstract, ViewType } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import type { ApplicationPreviewEnum} from '@lighthouse/shared';
import { getBlockWithMergedBreakPoint, hasChildrenBlock, isContainerBlock } from '@lighthouse/shared'
import { deepOmitNil } from '@lighthouse/tools'
import isDeepEqual from 'fast-deep-equal'

export const viewTypeMap: Record<ViewType, number> = {
    table: 1,
    advancedTable: 1,
    gallery: 1,
    list: 1,
    kanban: 2,
    calendar: 3,
    custom: 0
}

export const isViewBlock = (block: BlockAbstract): block is ViewBlockAbstract => {
    return block.type === BlockType.view
}

export const isFieldBlock = (block: BlockAbstract): block is FieldBlockAbstract => {
    return block.type === BlockType.field
}

export const isChartBlock = (block: BlockAbstract): block is ChartBlockAbstract => {
    return block.type === BlockType.chart
}

export const shouldNoRefetchView = (values: BlockAbstract, origin: BlockAbstract) => {
    if (isViewBlock(values) && isViewBlock(origin)) {
        const {
            config: { viewType, canGroup, groupConfigure }
        } = values
        const {
            config: { viewType: oViewType, canGroup: oCanGroup, groupConfigure: oGroupConfigure }
        } = origin
        return viewTypeMap[viewType] !== viewTypeMap[oViewType] || canGroup !== oCanGroup || !isDeepEqual(groupConfigure, oGroupConfigure)
    }
    return false
}

/** 存在一些block的更新，需要先更新远程，再更新本地 */
export const hasBlockNegativeUpdate = (appId: string, values: BlockAbstract, origin: BlockAbstract) => {
    if (isViewBlock(values) && isViewBlock(origin)) {
        const { id, config } = values
        const { viewType, filter, sorts, pagination, pointer, quickFilter, canGroup, groupConfigure, viewFieldSettings } = config
        const {
            viewType: oViewType,
            filter: oFilter,
            sorts: oSorts,
            pagination: oPagination,
            pointer: oPointer,
            quickFilter: oQuickFilter,
            canGroup: oCanGroup,
            groupConfigure: oGroupConfigure,
            viewFieldSettings: oViewFieldSettings
        } = origin.config

        // if (quickFilter.mode !== oQuickFilter.mode && quickFilter.mode === 'tile') {
        //     ViewSettingCache.setFilterCache(appId, id, [])
        // }
        return (
            pointer !== oPointer ||
            viewTypeMap[viewType] !== viewTypeMap[oViewType] ||
            !isDeepEqual(filter, oFilter) ||
            !isDeepEqual(sorts, oSorts) ||
            !isDeepEqual(pagination, oPagination) ||
            !isDeepEqual(quickFilter, oQuickFilter) ||
            canGroup !== oCanGroup ||
            !isDeepEqual(groupConfigure, oGroupConfigure)
        )
    }
    // pointer
    if (isChartBlock(values) && isChartBlock(origin)) {
        const { config } = values
        const { linkFilterController, breakPoint, breakPoints, ...restConfig } = config
        const { linkFilterController: oLinkFilterController, breakPoint: oBreakPoint, breakPoints: oBreakPoints, ...oRestConfig } = origin.config
        return !isDeepEqual(deepOmitNil(restConfig), deepOmitNil(oRestConfig))
    }
    return false
}
