/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { Toast } from '@byecode/ui'
import { getFlatAllBlock } from '@lighthouse/block'
import type { BlockAbstract, ContainerBlockAbstract } from '@lighthouse/core'
import { BlockType } from '@lighthouse/core'
import {
    blockHasSomeChildren,
    deepClearSyncBlock,
    findBlockById,
    findNormalOrSyncBlock,
    findParentBlockById,
    getCurrentBlockChildren,
    initBlockRuntimeState,
    PAGE_LAYER_ID,
    useAtomAction
} from '@lighthouse/shared'
import { getBrowser } from '@lighthouse/tools'
import { current, original } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import { clone } from 'rambda'
import { useHotkeys } from 'react-hotkeys-hook'

import { syncComponentsAtom } from '@/atoms/application/state'
import { createBlockAtom } from '@/atoms/page/action'
import { blockCreatingListAtom, blocksAtom, pageBlocksAtom, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import type { NodeIdWithScope } from '@/atoms/page/types'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { copiedBoxSelectionNodesSnapshotAtom } from '@/atoms/workSpace/state'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import * as srv from '@/services'
import { NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { useNodeToolbarActions } from './useNodeToolbarActions'
import { copyBlock, transformBlock2SyncComponents } from './utils'

type Configure = {
    ref: React.RefObject<HTMLDivElement | null>
    enabled?: boolean
}

type BlockUndoRedo<T extends BlockAbstract = BlockAbstract> = {
    createdBlocks?: BlockAbstract[]
    prevBlocks?: T[]
    nextBlocks?: T[]
}

/** 选中的节点操作 */
export const useSelectionNodesActions = ({ ref, enabled }: Configure) => {
    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()

    const { run: createBlock } = useAtomAction(createBlockAtom)

    const actions = useNodeToolbarActions({ stackId, pageId, rootPageId })

    const handleCopy = useAtomCallback((get, set, copyIds: NodeIdWithScope[]) => {
        const blocks = get(blocksAtom)[pageId]
        const syncComponents = get(syncComponentsAtom)
        const isFloatBoxBlock = copyIds?.some(id => findNormalOrSyncBlock(id, blocks ?? [], syncComponents)?.type === BlockType.floatBox)
        if (!blocks || isFloatBoxBlock) {
            return
        }
        set(copiedBoxSelectionNodesSnapshotAtom, { copiedIds: [...copyIds], blocks: clone(blocks) })
        Toast.success(`已复制${copyIds.length}个组件`)
    })

    const handlePaste = useAtomCallback(
        async (
            get,
            set,
            snapshot: {
                copiedIds: NodeIdWithScope[]
                blocks: BlockAbstract[]
            }
        ) => {
            const stack = get(pageStackAtomFamily({ stackId, rootPageId }))
            if (!stack) {
                return
            }
            const { state, blockRuntimeState } = stack
            const { asideType, selectedNodes } = state

            const pageBlocks = get(blocksAtom)[pageId] || []
            const syncComponents = get(syncComponentsAtom)

            const pasteBlocks = snapshot.copiedIds
                .map(id => findNormalOrSyncBlock(id, snapshot.blocks, syncComponents))
                .filter(Boolean) as BlockAbstract[]

            const blocksUndoRedoInfo: BlockUndoRedo = {}
            const syncComponentsUndoRedoInfo: BlockUndoRedo<ContainerBlockAbstract> = {}

            let isPaste2SyncComponents = false

            if (asideType === AsideType.PAGE) {
                set(blocksAtom, draft => {
                    const draftBlocks = draft[pageId]
                    if (!draftBlocks) {
                        return
                    }

                    blocksUndoRedoInfo.createdBlocks = copyBlock(
                        snapshot.copiedIds
                            .map(id => {
                                const pageBlock = findBlockById(id.id, snapshot.blocks)
                                const actualBlock = pageBlock ? undefined : findNormalOrSyncBlock(id, snapshot.blocks, syncComponents)
                                if (!pageBlock && !actualBlock) {
                                    return null
                                }

                                // 同步组件内子元素，复制后删除同步标识
                                if (actualBlock?.isLeafSynchronous) {
                                    deepClearSyncBlock(actualBlock)
                                    return actualBlock
                                }

                                return pageBlock
                            })
                            .filter(Boolean) as BlockAbstract[]
                    )

                    set(blockCreatingListAtom, s => [...s, ...getFlatAllBlock(blocksUndoRedoInfo.createdBlocks!).map(item => item.id)])

                    draftBlocks.push(...blocksUndoRedoInfo.createdBlocks)
                    blocksUndoRedoInfo.prevBlocks = original(draftBlocks)
                    blocksUndoRedoInfo.nextBlocks = current(draftBlocks)
                })
            } else if (asideType === AsideType.BLOCK) {
                if (!selectedNodes || selectedNodes.length !== 1) {
                    return
                }

                const selectedNode = selectedNodes[0]
                const actualTargetBlock = findNormalOrSyncBlock(selectedNode, pageBlocks, syncComponents)
                if (!actualTargetBlock) {
                    return
                }

                isPaste2SyncComponents = !!actualTargetBlock.isMasterSynchronous || !!actualTargetBlock.isLeafSynchronous

                // 粘往同步组件内
                if (isPaste2SyncComponents) {
                    set(syncComponentsAtom, draft => {
                        syncComponentsUndoRedoInfo.createdBlocks = copyBlock(pasteBlocks)
                        if (
                            syncComponentsUndoRedoInfo.createdBlocks.some(item =>
                                blockHasSomeChildren(block => !!block.isMasterSynchronous)(item)
                            )
                        ) {
                            Toast.error('同步组件不能粘贴到同步组件中')
                            return
                        }

                        // 将blocks设置为子同步元素
                        syncComponentsUndoRedoInfo.createdBlocks = syncComponentsUndoRedoInfo.createdBlocks.map(block =>
                            transformBlock2SyncComponents(block, false)
                        )

                        set(blockCreatingListAtom, s => [
                            ...s,
                            ...getFlatAllBlock(syncComponentsUndoRedoInfo.createdBlocks!).map(item => item.id)
                        ])

                        const draftTarget = findBlockById(actualTargetBlock.id, draft)
                        if (!draftTarget) {
                            return
                        }
                        const children = getCurrentBlockChildren(draftTarget, {
                            blockRuntimeState,
                            uniqueContainerId: draftTarget.isMasterSynchronous ? selectedNode.id : `${selectedNode.scope}@${draftTarget.id}`
                        })

                        if (children) {
                            children.push(...syncComponentsUndoRedoInfo.createdBlocks)
                        } else {
                            const parent = findParentBlockById(draftTarget.id, draft)
                            if (!parent) {
                                return
                            }

                            const parentChildren = getCurrentBlockChildren(parent, {
                                blockRuntimeState,
                                uniqueContainerId: parent.isMasterSynchronous ? selectedNode.scope : `${selectedNode.scope}@${parent.id}`
                            })

                            if (!parentChildren) {
                                return
                            }

                            const index = parentChildren.findIndex(item => item.id === draftTarget.id)
                            if (index === -1) {
                                return
                            }

                            parentChildren.splice(index + 1, 0, ...syncComponentsUndoRedoInfo.createdBlocks)
                        }
                        syncComponentsUndoRedoInfo.prevBlocks = original(draft)
                        syncComponentsUndoRedoInfo.nextBlocks = current(draft)
                    })
                } else {
                    set(blocksAtom, draft => {
                        const draftBlocks = draft[pageId]
                        if (!draftBlocks) {
                            return
                        }

                        const draftBlock = findBlockById(selectedNode.id, draftBlocks)
                        if (!draftBlock) {
                            return
                        }

                        blocksUndoRedoInfo.createdBlocks = copyBlock(
                            snapshot.copiedIds
                                .map(id => {
                                    const pageBlock = findBlockById(id.id, snapshot.blocks)
                                    const actualBlock = pageBlock ? undefined : findNormalOrSyncBlock(id, snapshot.blocks, syncComponents)
                                    if (!pageBlock && !actualBlock) {
                                        return null
                                    }

                                    // 同步组件内子元素，复制后删除同步标识
                                    if (actualBlock?.isLeafSynchronous) {
                                        deepClearSyncBlock(actualBlock)
                                        return actualBlock
                                    }

                                    return pageBlock
                                })
                                .filter(Boolean) as BlockAbstract[]
                        )

                        set(blockCreatingListAtom, s => [...s, ...getFlatAllBlock(blocksUndoRedoInfo.createdBlocks!).map(item => item.id)])

                        const children = getCurrentBlockChildren(draftBlock, { blockRuntimeState })
                        if (children) {
                            children.push(...blocksUndoRedoInfo.createdBlocks)
                        } else {
                            const parent = findParentBlockById(draftBlock.id, draftBlocks)
                            const parentChildren = parent
                                ? getCurrentBlockChildren(parent, {
                                      blockRuntimeState
                                  })
                                : draftBlocks

                            if (!parentChildren) {
                                return
                            }

                            const index = parentChildren.findIndex(item => item.id === draftBlock.id)
                            if (index === -1) {
                                return
                            }

                            parentChildren.splice(index + 1, 0, ...blocksUndoRedoInfo.createdBlocks)
                        }

                        blocksUndoRedoInfo.prevBlocks = original(draftBlocks)
                        blocksUndoRedoInfo.nextBlocks = current(draftBlocks)
                    })
                }
            }

            if (isPaste2SyncComponents) {
                if (
                    !syncComponentsUndoRedoInfo.prevBlocks ||
                    !syncComponentsUndoRedoInfo.nextBlocks ||
                    !syncComponentsUndoRedoInfo.createdBlocks
                ) {
                    return
                }

                set(pageStackAtom, draft => {
                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                    if (stack) {
                        initBlockRuntimeState(stack, {
                            blocks: get(pageBlocksAtom(pageId)),
                            syncComponents: syncComponentsUndoRedoInfo.nextBlocks!
                        })
                    }
                })

                pageUndoRedoController.add({
                    action: NODE_UPDATE_ACTION,
                    payload: {
                        prev: {
                            blocks: undefined,
                            syncComponents: syncComponentsUndoRedoInfo.prevBlocks
                        },
                        next: {
                            blocks: undefined,
                            syncComponents: syncComponentsUndoRedoInfo.nextBlocks
                        }
                        // created: {
                        //     createdBlocks: syncComponentsUndoRedoInfo.createdBlocks,
                        //     prevBlocks: syncComponentsUndoRedoInfo.prevBlocks,
                        //     nextBlocks: syncComponentsUndoRedoInfo.nextBlocks
                        // }
                    }
                })

                await srv.createSyncBlock({
                    createBlocks: syncComponentsUndoRedoInfo.createdBlocks,
                    blocks: syncComponentsUndoRedoInfo.nextBlocks
                })

                set(blockCreatingListAtom, s =>
                    s.filter(id => !getFlatAllBlock(syncComponentsUndoRedoInfo.createdBlocks!).some(item => item.id === id))
                )
            } else {
                if (!blocksUndoRedoInfo.createdBlocks || !blocksUndoRedoInfo.prevBlocks || !blocksUndoRedoInfo.nextBlocks) {
                    return
                }

                pageUndoRedoController.add({
                    action: NODE_UPDATE_ACTION,
                    payload: {
                        created: {
                            createdBlocks: blocksUndoRedoInfo.createdBlocks,
                            prevBlocks: blocksUndoRedoInfo.prevBlocks,
                            nextBlocks: blocksUndoRedoInfo.nextBlocks
                        }
                    }
                })

                await createBlock({
                    rootPageId,
                    pageId,
                    stackId,
                    createdBlocks: blocksUndoRedoInfo.createdBlocks,
                    blocks: blocksUndoRedoInfo.nextBlocks
                })
                set(blockCreatingListAtom, s =>
                    s.filter(id => !getFlatAllBlock(blocksUndoRedoInfo.createdBlocks!).some(item => item.id === id))
                )
            }
        }
    )

    const handleKeydown = useAtomCallback<void, Parameters<Parameters<typeof useHotkeys>[1]>>((get, set, event, hotkeysEvent) => {
        const copiedBoxSelectionNodesSnapshot = get(copiedBoxSelectionNodesSnapshotAtom)
        const selectedNodes = get(pageStackAtomFamily({ stackId, rootPageId }))?.state.selectedNodes

        const isMac = getBrowser().getBrowserInfo().platform === 'Macintosh'

        if (selectedNodes && selectedNodes.length > 0 && (isMac ? hotkeysEvent.key === '⌘+c' : hotkeysEvent.key === 'ctrl+c')) {
            if (
                !ref.current?.contains(event.target as Node) &&
                !document.querySelector(`#${PAGE_LAYER_ID}`)?.contains(event.target as Node)
            ) {
                return
            }
            handleCopy(selectedNodes)
        }

        if (copiedBoxSelectionNodesSnapshot.copiedIds.length > 0 && (isMac ? hotkeysEvent.key === '⌘+v' : hotkeysEvent.key === 'ctrl+v')) {
            // TODO: @zjb 为什么加这个限制？  hkl
            if (
                !ref.current?.contains(event.target as Node) &&
                !document.querySelector(`#${PAGE_LAYER_ID}`)?.contains(event.target as Node)
            ) {
                return
            }
            handlePaste(copiedBoxSelectionNodesSnapshot)
        }

        if (
            selectedNodes &&
            selectedNodes.length > 0 &&
            (isMac ? hotkeysEvent.key === 'Backspace' : ['Delete', 'Backspace'].includes(hotkeysEvent.key))
        ) {
            handleDelete(selectedNodes)
        }
    })

    const handleDelete = useAtomCallback((get, set, ids: NodeIdWithScope[]) => {
        actions.onRemove(ids)
    })

    useHotkeys('ctrl+c, ⌘+c, ctrl+v, ⌘+v, Delete, Backspace', handleKeydown, { enabled }, [enabled])
}
