import { Box, Flex, IconFont, Input } from '@byecode/ui'
import { ICON_STYLE } from '@lighthouse/core'
import { useDebounce } from '@lighthouse/tools'
import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useStyles as useRootStyles } from '../IconPicker.styles'
import type { IconDecoration, IconListItem, IconPickerListProps } from '../IconPicker.types'
import { IconRender } from '../IconRender'
import { FACE_ICON_KEY, getRecentIconsFromStorage, LINE_ICON_KEY, saveRecentIconsToStorage } from '../util'
import { useStyles } from './IconList.styles'

const RECENT_ID = 9999

export const IconPickerList: React.FunctionComponent<IconPickerListProps> = ({ type, searchable, value, data, onChange, onClose }) => {
    const ICON_KEY = type === ICON_STYLE.face ? FACE_ICON_KEY : LINE_ICON_KEY
    const [searchLabel, setSearchLabel] = useState('')
     const [recentIcons, setRecentIcons] = useState<string[]>(getRecentIconsFromStorage(ICON_KEY))
    const { classes } = useStyles({}, { name: 'iconPickerIconList' })
    const { classes: rootClasses } = useRootStyles({}, { name: 'iconPicker' })
    const iconListScrollRef = React.useRef<HTMLDivElement>(null)
    const kindListScrollRef = useRef<HTMLDivElement>(null)

    const isClickJumpKind = useRef(false)

    const [viewKindId, setViewKindId] = useState(RECENT_ID.toString())

    const searchData: IconListItem[] = useMemo(() => {
        if (searchLabel === '') {
            return data.filter(kind => kind.items.length > 0)
        }
        const itemList = data
            .map(kind => ({
                ...kind,
                items: kind.items.filter(icon =>
                    [icon.name_zh, icon.name].map(name => name?.toLowerCase()).some(name => name?.includes(searchLabel.toLowerCase()))
                )
            }))
            .flatMap(kind => kind.items)
        return [{ id: 99999, name: '', icon: '', items: itemList }].filter(kind => kind.items.length > 0)
    }, [data, searchLabel])

    const filterData = useDebounce(searchData, 300)

    const isEmpty = useMemo(() => filterData.flatMap(kind => kind.items).length === 0, [filterData])

    const recentKind = useMemo(() => {
        const allItems = data.flatMap(kind => kind.items)
        //  检查图标库的重复图标
        // const iconIds = allItems.map(item => item.name)
        // console.log("🚀 ~ file: index.tsx:40 ~ recentKind ~ iconIds:", iconIds.filter(item => item.includes('IconLibrary-')))
        // const [ids, obj] = iconIds.reduce<[string[], Record<string, boolean>]>(([ids, obj], curVal) => {
        //     if (obj[curVal]) {
        //         return [[...ids,curVal], obj]
        //     }
        //     obj[curVal] = true
        //     return [ids, obj]
        // },[[''], {}])
        //  console.log(ids);
        return {
            id: RECENT_ID,
            name: '最近',
            items: recentIcons.reduce<IconListItem['items']>((preVal, iconName) => {
                const item = allItems.find(item => item.name === iconName)
                return item ? [...preVal, item] : preVal
            }, [])
        }
    }, [data, recentIcons])

    const handleIconListScroll = useCallback((ev: React.WheelEvent<HTMLDivElement>) => {
        if (ev.target instanceof HTMLDivElement && !isClickJumpKind.current) {
            const viewRect = ev.target?.getBoundingClientRect()
            const { bottom: viewBottom = 0, y: viewY = 0 } = viewRect ?? {}
            // const top = scrollEleScrollTop + focusEleTop - scrollEleTop
            const allEle = document.querySelectorAll('[data-icon-kind]')
            const ele = [...allEle].find(ele => {
                const { y } = ele.getBoundingClientRect()
                // viewY <= y && viewBottom <= window.innerHeight - top - height
                return viewY <= y
            })
            const viewKindId = ele?.getAttribute('data-icon-kind') ?? ''
            const kindListScrollEle = kindListScrollRef.current
            const kindLabelEle = document.querySelector(`[data-icon-kind-footer = "${viewKindId}"]`)
            const scrollLeft =
                (kindListScrollEle?.scrollLeft ?? 0) +
                (kindLabelEle?.getBoundingClientRect().left ?? 0) -
                (kindListScrollEle?.getBoundingClientRect().left ?? 0)
            kindListScrollEle?.scroll({ left: scrollLeft, behavior: 'smooth' })
            setViewKindId(viewKindId)
        }
    }, [])

    const handleWheel = useCallback(() => {
        if (isClickJumpKind.current) {
            isClickJumpKind.current = false
        }
    }, [])

    const handleChange = useCallback(
        (icon?: IconDecoration) => {
            if (icon?.value) {
                const recentIcons = saveRecentIconsToStorage(ICON_KEY, icon.value)
                setRecentIcons(recentIcons)
            }
            onClose()
            onChange?.(icon)
        },
        [ICON_KEY, onChange, onClose]
    )

    const handleScrollKind = useCallback((id: number) => {
        const ele = document.querySelector(`[data-icon-kind = "${id}"]`)
        isClickJumpKind.current = true
        setViewKindId(id.toString())
        ele?.scrollIntoView({ behavior: 'smooth' })
    }, [])

    const handleArrowScroll = useCallback((way: 'left' | 'right') => {
        const kindListScrollEle = kindListScrollRef?.current
        const rect = kindListScrollEle?.getBoundingClientRect()
        const [offsetLeft, width] = [kindListScrollEle?.scrollLeft ?? 0, rect?.width ?? 0]
        const offsetDistance = { left: -width, right: width }[way]
        kindListScrollRef?.current?.scroll({ left: offsetLeft + offsetDistance, behavior: 'smooth' })
    }, [])

    const handleChangeSearch = useCallback((value: string) => {
        setSearchLabel(value)
    }, [])

    return (
        <Box className={rootClasses.dropContent}>
            {searchable && (
                <Box className={classes.searchWrapper}>
                    <Input
                        value={searchLabel}
                        onChange={e => handleChangeSearch(e.target.value)}
                        prefix={<IconFont size={16} color="var(--color-gray-500)" type="Search" />}
                        placeholder="搜索图标"
                        className={classes.searchInput}
                    />
                </Box>
            )}
            <Box className={classes.listContainer} ref={iconListScrollRef} onWheel={handleWheel} onScroll={handleIconListScroll}>
                {recentKind.items.length > 0 && !searchLabel && (
                    <Box className={classes.kindContainer} data-icon-kind={recentKind.id}>
                        <Box className={classes.kindTitle}>{recentKind.name}</Box>
                        <Flex wrap="wrap" className={classes.listIcon}>
                            {recentKind.items.map(item => (
                                <Box
                                    className={classNames(classes.icon, value?.value === item.name && classes.active)}
                                    key={item.id}
                                    onClick={() => handleChange?.({ type: 'icon', value: item.name })}
                                >
                                    <IconRender readonly value={{ type: 'icon', value: item.name }} />
                                </Box>
                            ))}
                        </Flex>
                    </Box>
                )}
                {filterData.map((kind, index) => (
                    <Box key={kind.id} className={classes.kindContainer} data-icon-kind={kind.id}>
                        {kind.name && <Box className={classes.kindTitle}>{kind.name}</Box>}
                        <Flex wrap="wrap" className={classes.listIcon}>
                            {kind.items.map(item => (
                                <Box
                                    className={classNames(classes.icon, value?.value === item.name && classes.active)}
                                    key={item.id}
                                    onClick={() => handleChange?.({ type: 'icon', value: item.name })}
                                >
                                    <IconRender readonly value={{ type: 'icon', value: item.name }} />
                                </Box>
                            ))}
                        </Flex>
                    </Box>
                ))}
                {isEmpty && (
                    <Flex className={classes.empty} direction="column" gap="12px" justifyContent="center">
                        <IconRender
                            readonly
                            size={56}
                            color="var(--color-gray-300)"
                            value={{ type: 'icon', value: 'SmileCircleIconLibrary' }}
                        />
                        <Box>未查询到图标</Box>
                    </Flex>
                )}
            </Box>
            {!searchLabel && (
                <Flex gap="12px" alignItems="center" className={classes.footer}>
                    <Flex alignItems="center" className={classes.arrow} onClick={() => handleArrowScroll('left')}>
                        <IconFont size={12} color="var(--color-gray-300)" type="ArrowLeftSmall" />
                    </Flex>
                    <Flex className={classes.listKind} ref={kindListScrollRef}>
                        {recentKind.items.length > 0 && (
                            <Flex
                                alignItems="center"
                                data-icon-kind-footer={recentKind.id}
                                className={classNames(classes.kindLabel, viewKindId === recentKind.id.toString() && classes.kindActive)}
                                onClick={() => handleScrollKind(recentKind.id)}
                            >
                                <IconFont color="var(--color-gray-500)" type="Clock" size={16} />
                            </Flex>
                        )}
                        {filterData.map(kind => (
                            <Flex
                                key={kind.id}
                                alignItems="center"
                                data-icon-kind-footer={kind.id}
                                className={classNames(classes.kindLabel, viewKindId === kind.id.toString() && classes.kindActive)}
                                onClick={() => handleScrollKind(kind.id)}
                            >
                                {kind.name}
                            </Flex>
                        ))}
                    </Flex>
                    <Flex alignItems="center" className={classes.arrow} onClick={() => handleArrowScroll('right')}>
                        <IconFont size={12} color="var(--color-gray-300)" type="ArrowRightSmall" />
                    </Flex>
                </Flex>
            )}
        </Box>
    )
}
