import type {
    BaseBreakPointConfigProtocol,
    BlockRuntimeState,
    ContainerBlockAbstract,
    ContainerViewChildren,
    // BreakPointDesignLayoutProtocol,
    // DesignLayoutProtocol,
    FilterFormType,
    FloatBlockAbstract,
    FormContainerBlockAbstract,
    PageAbstract,
    Sorter,
    ViewBlockAbstract
} from '@lighthouse/core'
import { type BlockAbstract, BlockType, ChartType } from '@lighthouse/core'
import { findParentScroller } from '@lighthouse/tools'
import { isDraft } from 'immer'
import { find } from 'rambda'

import type { NodeIdWithScope } from '../components'
import { DOM_DATA_NODE_NAME, DOM_DATA_NODE_SCOPE, findNodeDom } from '../components'
import { MAIN_BREAK_POINT, PAGE_SCROLL_CONTAINER_HOST, PAGE_SCROLL_PARENT_CONTENT } from '../constants'
import type { ApplicationPreviewEnum } from '../types'

export function getWithScopeId(id: string, scope?: string) {
    return scope ? `${scope}@${id}` : id
}

export function isCustomViewBlock(block: BlockAbstract): block is ViewBlockAbstract {
    return block.type === BlockType.view && block.config.viewType === 'custom'
}

export function isContainerBlock(block: BlockAbstract): block is ContainerBlockAbstract {
    return block.type === BlockType.container
}

export function isFormContainerBlock(block: BlockAbstract): block is FormContainerBlockAbstract {
    return block.type === BlockType.formContainer
}

export function isFloatBoxBlock(block: BlockAbstract): block is FloatBlockAbstract {
    return block.type === BlockType.floatBox
}

export function hasChildrenBlock(
    block: BlockAbstract
): block is ViewBlockAbstract | ContainerBlockAbstract | FormContainerBlockAbstract | FloatBlockAbstract {
    return isCustomViewBlock(block) || isContainerBlock(block) || isFormContainerBlock(block) || isFloatBoxBlock(block)
}

export type PureBlockAbstract = Omit<BlockAbstract, 'children'>
/** 获取block的配置信息 */
export function getPureBlockProperty(block: BlockAbstract): PureBlockAbstract {
    if (hasChildrenBlock(block)) {
        const { children, ...rest } = block
        return rest
    }
    //
    return block
}

type GetBlockDataSourceIdResult = {
    dsId?: string
    filter?: FilterFormType
    sorts?: Sorter[]
}
export const getBlockDataSourceId = (block: BlockAbstract, pointer: string | undefined): GetBlockDataSourceIdResult => {
    switch (block.type) {
        case BlockType.chart: {
            if (block.config.chartType === ChartType.indicator) {
                return { dsId: undefined }
            }
            return { dsId: block.config.pointer, filter: block.config.ruleFilter }
        }
        case BlockType.view: {
            return { dsId: block.config.pointer, filter: block.config.filter, sorts: block.config.sorts }
        }
        case BlockType.formContainer: {
            return { dsId: block.config.pointer || pointer }
        }
        case BlockType.fieldGroup:
        case BlockType.field: {
            return { dsId: pointer }
        }
        default: {
            return { dsId: pointer }
        }
    }
}

type GetCurrentChildrenOption = {
    blockRuntimeState?: BlockRuntimeState
    uniqueContainerId?: string
}
/** 获取当前的block children */
export function getCurrentBlockChildren(block: BlockAbstract, option?: GetCurrentChildrenOption) {
    const { blockRuntimeState, uniqueContainerId } = option ?? {}

    if (block.type === BlockType.container) {
        const view = block.children.find(item => item.id === blockRuntimeState?.container?.[uniqueContainerId || block.id]?.currentView)
        if (view) {
            return view.children
        }
    }

    if (block.type === BlockType.formContainer || block.type === BlockType.floatBox) {
        return block.children
    }

    if (block.type === BlockType.view && block.config.viewType === 'custom') {
        return block.children
    }
}

/** 获取block children，会获取容器下的所有view的children */
export function getBlockChildren(block: BlockAbstract): BlockAbstract[] | undefined {
    if (block.type === BlockType.container) {
        return block.children.reduce<BlockAbstract[]>((total, curr) => [...total, ...curr.children], [])
    }

    if (block.type === BlockType.formContainer || block.type === BlockType.floatBox) {
        return block.children
    }

    if (block.type === BlockType.view && block.config.viewType === 'custom') {
        return block.children
    }
}

/** 递归查找block */
// eslint-disable-next-line etc/no-misused-generics
export function findBlockById<T extends BlockAbstract = BlockAbstract>(id: string, blocks: BlockAbstract[]): T | undefined {
    const recursion = (tree: BlockAbstract[]): undefined | T => {
        for (const block of tree) {
            if (block.id === id) {
                return block as T
            }

            const children = getBlockChildren(block)
            if (children) {
                const res = recursion(children)
                if (res) {
                    return res
                }
            }
        }
    }

    return recursion(blocks)
}

export function filterBlock(blocks: BlockAbstract[], filter: (val: BlockAbstract) => boolean): PureBlockAbstract[] {
    return blocks.reduce<PureBlockAbstract[]>((prev, cur) => {
        const isFilter = filter(cur)
        if (isFilter) {
            const block = getPureBlockProperty(cur)
            prev.push(block)
        }
        const children = getBlockChildren(cur)
        if (children) {
            prev = [...prev, ...filterBlock(children, filter)]
        }
        return prev
    }, [])
}

export function filterSyncBlock(blocks: BlockAbstract[], filter: (val: BlockAbstract) => boolean): PureBlockAbstract[] {
    return blocks.reduce<PureBlockAbstract[]>((prev, cur) => {
        const isFilter = filter(cur)
        if (isFilter) {
            const block = getPureBlockProperty(cur)
            prev.push(block)
        }
        const children = getBlockChildren(cur)
        if (children) {
            prev = [...prev, ...filterBlock(children, filter)]
        }
        return prev
    }, [])
}

/** 深度遍历查找父级block */
// eslint-disable-next-line etc/no-misused-generics
export function findParentBlockById<T extends BlockAbstract = BlockAbstract>(id: string, blocks: BlockAbstract[]): T | undefined {
    let parent: BlockAbstract | undefined

    const recursion = (tree: BlockAbstract[]) => {
        for (const node of tree) {
            if (parent) {
                return
            }

            if (node.id === id) {
                return true
            }

            const children = getBlockChildren(node)
            if (children) {
                const res = recursion(children)
                if (res) {
                    if (!parent) {
                        parent = node
                    }

                    return true
                }
            }
        }
    }

    recursion(blocks)

    return parent as T | undefined
}

/**
 * 根据scope查找父级Block
 * **注意！！！**，如果父级是同步组件实例，则返回的是同步组件树中的block信息，同步block的id和页面上的block的id不同
 * @param params
 * @param blocks
 * @returns
 */
// eslint-disable-next-line etc/no-misused-generics
export function findParentBlockWithScopeById<T extends BlockAbstract = BlockAbstract>(
    params: NodeIdWithScope,
    blocks: BlockAbstract[],
    syncComponents: BlockAbstract[]
): T | undefined {
    if (params.scope) {
        return findParentBlockById(params.id, syncComponents)
    }

    return findParentBlockById(params.id, blocks)
}

type FindParentBlockOptions = {
    id: string
    blocks: BlockAbstract[]
    syncComponents?: ContainerBlockAbstract[]
    filter: (block: BlockAbstract) => boolean
    blockRuntimeState?: BlockRuntimeState
}

/** 查找父级block */
export const findParentBlockByType = <T extends BlockAbstract>({
    id,
    blocks,
    blockRuntimeState,
    syncComponents,
    filter
}: FindParentBlockOptions): T | undefined => {
    let parentBlock: T | undefined

    const recursion = (tree: BlockAbstract[], scope?: string): boolean => {
        for (const node of tree) {
            if (parentBlock) {
                return true
            }
            if (node.id === id) {
                return true
            }

            const actualBlock = findNormalOrSyncBlock({ id: node.id, scope }, blocks, syncComponents || [], false)
            const children = actualBlock
                ? getCurrentBlockChildren(actualBlock, {
                      blockRuntimeState,
                      uniqueContainerId: actualBlock.isMasterSynchronous
                          ? node.id
                          : actualBlock.isLeafSynchronous
                          ? `${scope}@${node.id}`
                          : node.id
                  })
                : []

            if (children) {
                const found = recursion(children, actualBlock?.isMasterSynchronous ? node.id : scope)
                if (found) {
                    if (filter(node)) {
                        parentBlock = node as T
                        return false
                    }
                    return true
                }
            }
        }
        return false
    }
    recursion(blocks)
    return parentBlock
}

/** 查找所有父级block */
export const findAllParentBlocksByType = ({
    id,
    blocks,
    blockRuntimeState,
    syncComponents,
    filter
}: FindParentBlockOptions): BlockAbstract[] => {
    const parentBlocks: BlockAbstract[] = []

    const recursion = (tree: BlockAbstract[], scope?: string): boolean => {
        for (const node of tree) {
            if (node.id === id) {
                return true
            }
            const actualBlock = findNormalOrSyncBlock({ id: node.id, scope }, blocks, syncComponents || [], false)
            const children = actualBlock
                ? getCurrentBlockChildren(actualBlock, {
                      blockRuntimeState,
                      uniqueContainerId: actualBlock.isMasterSynchronous
                          ? node.id
                          : actualBlock.isLeafSynchronous
                          ? `${scope}@${node.id}`
                          : node.id
                  })
                : []

            if (children) {
                const found = recursion(children, actualBlock?.isMasterSynchronous ? node.id : scope)
                if (found) {
                    if (filter(node)) {
                        parentBlocks.push(node)
                        return true
                    }
                    return true
                }
            }
        }
        return false
    }

    recursion(blocks)

    return parentBlocks
}

/**
 * 查找普通block或者同步block
 * 如果在同步组件中找到了则返回
 * 如果在页面block中找到了则判断是否有同步标识，如果有则根据同步标识id在同步组件中找，否则直接返回结果
 * @param node
 * @param pageBlocks
 * @param syncComponents
 * @returns
 */
export function findNormalOrSyncBlock(
    node: NodeIdWithScope,
    pageBlocks: BlockAbstract[],
    syncComponents: BlockAbstract[],
    recursion = true
): BlockAbstract | undefined {
    if (node.scope) {
        return findBlockById(node.id, syncComponents)
    }

    const normalBlock = findBlockById(node.id, pageBlocks)
    if (normalBlock?.synchronousId) {
        return findBlockById(normalBlock.synchronousId, syncComponents)
    }

    if (!normalBlock) {
        return
    }

    if (recursion) {
        if (isContainerBlock(normalBlock)) {
            const newChildren = normalBlock.children.map(view => ({
                ...view,
                children: view.children
                    .map(item => findNormalOrSyncBlock({ id: item.id, scope: node.scope }, pageBlocks, syncComponents))
                    .filter(Boolean) as BlockAbstract[]
            }))

            if (isDraft(normalBlock)) {
                normalBlock.children = newChildren
            } else {
                return {
                    ...normalBlock,
                    children: newChildren
                }
            }
        }

        if (isFormContainerBlock(normalBlock) || isCustomViewBlock(normalBlock) || isFloatBoxBlock(normalBlock)) {
            const newChildren = normalBlock.children
                .map(item => findNormalOrSyncBlock({ id: item.id, scope: node.scope }, pageBlocks, syncComponents))
                .filter(Boolean) as BlockAbstract[]

            if (isDraft(normalBlock)) {
                normalBlock.children = newChildren
            } else {
                return {
                    ...normalBlock,
                    children: newChildren
                }
            }
        }
    }

    return normalBlock
}

/** 递归清除同步block标识 */
export function deepClearSyncBlock<T extends BlockAbstract>(block: T) {
    block.synchronousId = ''
    block.isLeafSynchronous = undefined
    block.isMasterSynchronous = undefined

    if (isContainerBlock(block)) {
        block.children.forEach(view => {
            view.children.forEach(deepClearSyncBlock)
        })
    }

    if (isFormContainerBlock(block) || isCustomViewBlock(block)) {
        block.children.forEach(deepClearSyncBlock)
    }
}

/** block是否包含特定的组件 */
export function blockHasSomeChildren(recipe: (block: BlockAbstract) => boolean) {
    function recursion(block: BlockAbstract): boolean {
        if (recipe(block)) {
            return true
        }

        const children = getBlockChildren(block)
        if (!children) {
            return false
        }

        for (const child of children) {
            const res = recursion(child)
            if (res) {
                return res
            }
        }

        return false
    }

    return recursion
}

/**
 * 滚动到某个节点
 * @param id
 */
export function scroll2FlowNode(id: string, scope?: string) {
    const element = findNodeDom({ id, scope })
    if (!element) {
        return
    }

    const rootScroller = document.querySelector(`#${PAGE_SCROLL_CONTAINER_HOST}`)
    const rootFallbackScroller = document.querySelector(`#${PAGE_SCROLL_PARENT_CONTENT}`)

    let targetScrollTop = 0

    function scrollParentToVisible(parent: HTMLElement) {
        if (!element) {
            return
        }

        const isRoot = rootScroller === parent || rootFallbackScroller === parent
        const rect = parent.getBoundingClientRect()
        const elementRect = element.getBoundingClientRect()

        // 判断当前父容器是否可见, 如果是根，则加上导航栏的高度
        if (elementRect.top < rect.top + (isRoot ? 60 : 0) || elementRect.bottom > rect.bottom) {
            targetScrollTop = parent.scrollTop + elementRect.top - rect.top - (isRoot ? 60 : 0)
            if (!isRoot) {
                parent.addEventListener('scroll', onScrollEnd)
            }

            // 滚动父容器使元素可见
            parent.scrollTo({ top: targetScrollTop, behavior: 'smooth' })

            return
        }

        if (isRoot) {
            return
        }

        // 如果父容器有父容器，递归调用
        const parentScroller = findParentScroller(parent.parentElement)
        if (parentScroller) {
            scrollParentToVisible(parentScroller)
        }
    }

    // 滚动结束时才递归调用滚动
    function onScrollEnd(e: Event) {
        const target = e.target as HTMLElement

        if ((target.scrollTop = targetScrollTop)) {
            target.removeEventListener('scroll', onScrollEnd)

            // 如果父容器有父容器，递归调用
            const parentScroller = findParentScroller(target.parentElement)
            if (parentScroller) {
                scrollParentToVisible(parentScroller)
            }
        }
    }

    // 初始滚动，从元素的父容器开始
    const parentScroller = findParentScroller(element.parentElement)
    if (parentScroller) {
        requestAnimationFrame(() => {
            scrollParentToVisible(parentScroller)
        })
    }
}

/**
 * 滚动到视图中指定的某个元素
 * @param viewId
 * @param index
 * @param scrollIntoViewOptions
 */
export function scroll2ViewItem(node: NodeIdWithScope, index: number, scrollIntoViewOptions?: ScrollIntoViewOptions) {
    requestAnimationFrame(() => {
        document
            .querySelector(
                `${node.scope ? `[${DOM_DATA_NODE_SCOPE}="${node.scope}"]` : ''}[${DOM_DATA_NODE_NAME}="${
                    node.id
                }"] div[data-view-item="${index}"]`
            )
            ?.scrollIntoView({ behavior: 'smooth', ...scrollIntoViewOptions })
    })
}

/**
 * 滚动到视图指定位置，左或右，到底
 * @param dom
 * @param scrollOptions
 */
export function scroll2EndLeftOrRight(dom: HTMLElement, scrollOptions?: ScrollToOptions) {
    requestAnimationFrame(() => {
        dom?.scrollTo(scrollOptions)
    })
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const merge = (target: Record<string, any>, source: Record<string, any>, path: string[]) => {
    let sourceValue = source

    // 遍历路径
    for (const part of path) {
        if (sourceValue && typeof sourceValue === 'object') {
            sourceValue = sourceValue?.[part]
        } else {
            break
        }
    }
    // if(!sourceValue){
    //     return
    // }
    // 在目标对象中设置更新的值
    let targetRef = target
    for (let i = 0; i < path.length - 1; i++) {
        const part = path[i]
        if (!targetRef[part] && sourceValue) {
            targetRef[part] = {} // 创建嵌套对象
        }
        targetRef = targetRef[part]
    }
    if (typeof targetRef === 'object') {
        targetRef[path[path.length - 1]] = sourceValue
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const deepMerge = (target: Record<string, any>, source: Record<string, any>, breakKeys: string[] = [], currentPath = '') => {
    const mergeObj = JSON.parse(JSON.stringify(target))
    const sourceObj = JSON.parse(JSON.stringify(source))
    for (const path of breakKeys) {
        const pathParts = path.split('.')
        merge(mergeObj, sourceObj, pathParts)
    }
    return mergeObj
}

export function mergeSingleBreakPointConfigure<T extends BaseBreakPointConfigProtocol>(base: T, breakPoint: Partial<T>): T {
    const target = {
        ...base,
        breakKeys: breakPoint.breakKeys
    }
    return deepMerge(target, breakPoint, ['id', 'name', ...(breakPoint.breakKeys || [])]) as T
}

export function mergeBreakPointConfigure<T extends BaseBreakPointConfigProtocol>(base: T, breakPoints: Partial<T>[]): T[] | undefined {
    return breakPoints?.map(breakPoint => {
        return mergeSingleBreakPointConfigure(base, breakPoint)
    })
}

export function getBreakPointConfigure<T extends BaseBreakPointConfigProtocol>(
    previewType: ApplicationPreviewEnum,
    base: T,
    breakPoints: Partial<T>[]
) {
    if (previewType === MAIN_BREAK_POINT) {
        return base
    }
    const data = mergeBreakPointConfigure(base, breakPoints)
    const alternative = { ...base, id: previewType }
    return find(item => item.id === previewType, data || []) || alternative
}

export function getBlockWithMergedBreakPoint<T extends BlockAbstract>(previewType: ApplicationPreviewEnum, data: T) {
    if (previewType === MAIN_BREAK_POINT) {
        return data
    }
    const { breakPoint, breakPoints } = data.config
    const mergeBreakPoints = mergeBreakPointConfigure(breakPoint, breakPoints)
    const currentBreakPoint = find(item => item.id === previewType, mergeBreakPoints || [])
    return {
        ...data,
        config: {
            ...data.config,
            breakPoint: currentBreakPoint || breakPoint
        }
    }
}

export function getPageWithMergedBreakPoint<T extends PageAbstract>(previewType: ApplicationPreviewEnum, data: T) {
    if (previewType === MAIN_BREAK_POINT) {
        return data
    }
    const { breakPoint, breakPoints, ...rest } = data
    const mergeBreakPoints = mergeBreakPointConfigure(breakPoint, breakPoints)
    const currentBreakPoint = find(item => item.id === previewType, mergeBreakPoints || [])
    return {
        ...data,
        breakPoint: currentBreakPoint || breakPoint
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const findMissingKeysDeep = (data: any, maxLevel: number | undefined, parentKey = '', level = 1): string[] => {
    let missingKeys: string[] = []
    if (typeof data !== 'object' || Array.isArray(data)) {
        return missingKeys
    }
    if (maxLevel && maxLevel < level) {
        return [parentKey]
    }
    Object.keys(data).forEach(key => {
        const currentKey = parentKey ? `${parentKey}.${key}` : key
        if (typeof data[key] === 'object' && data[key] !== null && !Array.isArray(data[key])) {
            const nestedMissingKeys = findMissingKeysDeep(data[key], maxLevel, currentKey, level + 1)
            missingKeys = [...missingKeys, ...nestedMissingKeys]
        } else {
            missingKeys.push(currentKey)
        }
    })
    return missingKeys
}

/** 查找父级同步容器 */
export const findParentSyncContainerBlock = ({ id, blocks, blockRuntimeState }: Omit<FindParentBlockOptions, 'filter'>) => {
    let containerBlock: ContainerBlockAbstract | undefined

    const recursion = (tree: BlockAbstract[]) => {
        for (const node of tree) {
            if (containerBlock) {
                return
            }

            if (node.id === id) {
                return true
            }

            const children = getCurrentBlockChildren(node, { blockRuntimeState })

            if (children) {
                const res = recursion(children)
                if (res) {
                    if (node.type === BlockType.container && node.isMasterSynchronous) {
                        containerBlock = node
                        return
                    }
                    return true
                }
            }
        }
    }

    recursion(blocks)

    return containerBlock
}

export const generateBlockRenderTree = ({
    blocks,
    syncComponents,
    blockRuntimeState
}: {
    blocks: BlockAbstract[]
    syncComponents: ContainerBlockAbstract[]
    blockRuntimeState: BlockRuntimeState
}): BlockAbstract[] => {
    // 根据block递归拼接
    function getBlockTree(block: BlockAbstract): BlockAbstract {
        const children = getCurrentBlockChildren(block, { blockRuntimeState })
        if (children) {
            if (block.type === BlockType.container && block.isMasterSynchronous) {
                const syncComponentChildren = syncComponents.find(v => v.id === block.synchronousId)?.children
                return { ...block, children: syncComponentChildren ?? [] }
            }
            return { ...block, children: children.map(getBlockTree) ?? [] } as BlockAbstract
        }
        return block
    }
    return blocks.map(getBlockTree)
}

export function transformOriginBlock<T extends BlockAbstract = BlockAbstract>(blocks: T[], previewType: ApplicationPreviewEnum): T[] {
    return blocks.map<T>(block => {
        const newBlock = getBlockWithMergedBreakPoint(previewType, block)
        if (hasChildrenBlock(newBlock)) {
            if (isContainerBlock(newBlock) && newBlock.children) {
                return {
                    ...newBlock,
                    children: newBlock.children.map(item => ({
                        ...item,
                        children: transformOriginBlock(item.children, previewType)
                    }))
                }
            }
            if (newBlock.children && newBlock.children.length > 0) {
                return {
                    ...newBlock,
                    children: transformOriginBlock(newBlock.children as BlockAbstract[], previewType)
                } as T
            }
        }
        return newBlock
    })
}

// export const findMissingKeysDeep = (base: any, data: any, maxLevel: number, parentKey = '', level = 0) => {
//     let missingKeys: string[] = [];
//     if (typeof base !== 'object' || Array.isArray(base) || typeof data !== 'object' || Array.isArray(base)) {
//         return missingKeys
//     }
//     if (maxLevel < level) {
//         return missingKeys
//     }
//     // 遍历 obj1 中的所有键
//     Object.keys(base).forEach(key => {
//         const currentKey = parentKey ? `${parentKey}.${key}` : key;

//         // eslint-disable-next-line no-prototype-builtins
//         if (base.hasOwnProperty(key)) {
//             // eslint-disable-next-line no-prototype-builtins
//             if (data.hasOwnProperty(key)) {
//                 // 如果 obj1 和 obj2 的该 key 对应的值还是对象，则递归
//                 if (typeof base[key] === 'object' && base[key] !== null && !Array.isArray(base[key])) {
//                     const nestedMissingKeys = findMissingKeysDeep(base[key], data[key] || {}, maxLevel, currentKey, level + 1);
//                     missingKeys = [...missingKeys, ...nestedMissingKeys]; // 合并嵌套的缺失键
//                 }
//             } else {
//                 missingKeys.push(currentKey); // 如果 obj2 没有该 key，记录下来
//             }
//         }
//     });

//     return missingKeys;
// }
