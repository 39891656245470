import { IconFont, Indicator } from '@byecode/ui'
import cls from 'classnames'
import React from 'react'
import styled from 'styled-components'

const SCxQuickFilterActorPreviewer = styled.div`
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 0 8px;
    border-radius: 5px;
    color: var(--color-black);
    font-size: var(--font-size-normal);
    overflow: hidden;
    max-width: 100%;
    cursor: pointer;

    &.active {
        color: var(--color-app-main);
        background-color: var(--color-app-main-tint);
    }
`

const SCxText = styled.div`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxClear = styled.div`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--color-red-500);
    cursor: pointer;
    &:hover {
        background: var(--color-red-400);
    }
`

interface NormalButtonProps {
    title: string
    active?: boolean
    description?: string
    enableClear?: boolean
    onClick?: () => void
    onClear?: () => void
}

export const NormalButton = React.forwardRef<HTMLDivElement, NormalButtonProps>(
    ({ title, active, description, enableClear, onClear, ...rest }, ref) => {
        return (
            <Indicator
                style={{
                    maxWidth: '100%'
                }}
                offset="6px"
                size={16}
                disabled={!description}
                color="var(--color-white)"
                label={
                    <SCxClear onClick={onClear}>
                        <IconFont type="Close" size={14} />
                    </SCxClear>
                }
            >
                <SCxQuickFilterActorPreviewer className={cls({ active })} ref={ref} {...rest}>
                    <SCxText>
                        {title}
                        {description && `: ${description}`}
                    </SCxText>
                    <IconFont size={16} type="ArrowDownSmall" />
                </SCxQuickFilterActorPreviewer>
            </Indicator>
        )
    }
)
