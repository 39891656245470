import { Box, Divider, Group } from '@byecode/ui'
import { type ButtonBlockAbstract, type PageAbstract, type RichTextContentProtocol, EVENT_VARIABLE_TYPE } from '@lighthouse/core'
import { generateText, ListItemPaddingByecodeUi, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { CollectionEvent } from '@/components/CollectionEvent'
import { getBlockAndPageDesignLimit, PositionSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableValueRender } from '@/hooks/useVariableValueRender'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import FormSegmentedControl from '../Common/FormSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'
import { ButtonConfigure } from './ButtonConfigure'
import { BUTTON_RADIUS_OPTIONS, BUTTON_SHAPE_OPTIONS, BUTTON_SIZE_OPTIONS } from './constant'

// import { getSelectData } from './help'

interface ButtonSettingProps {
    allPages: PageAbstract[]
}

const SCxContainer = styled.div`
    /* padding: 0 16px; */
    height: 100%;
`

const buttonEventTypes = [EVENT_VARIABLE_TYPE.BUTTON_CLICK]

export const ButtonSetting: React.FC<ButtonSettingProps> = ({ allPages }) => {
    const { control, watch } = useFormContext<ButtonBlockAbstract['config']>()
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const [name, shape, events, action] = watch(['name', 'shape', 'events', 'action'])
    const designProps = getBlockAndPageDesignLimit('button')

    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )

    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { recordId } = curr
    const renderLabel = useVariableValueRender(prev.recordId, recordId)

    const handleRenderTitle = useCallback(
        (v: RichTextContentProtocol) => {
            return generateText(v, { variable: { renderLabel: v => renderLabel(v.attrs.value, {}) } })
        },
        [renderLabel]
    )

    return (
        <Box style={{ height: '100%' }}>
            <BlockConfigureSegmentedControl isInterAction value={currentTab} onChange={setCurrentTab} />
            <SCxContainer key={BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN} hidden={currentTab !== BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN}>
                <PositionSetting {...designProps.position} />
                <Divider />
                <SizeSetting {...designProps.size} />
                <Divider />
                <Group label="按钮">
                    <ButtonConfigure style={{ padding: 0 }} />
                </Group>
                <Divider />
                <Group label="样式">
                    <Controller
                        name="shape"
                        control={control}
                        render={({ field }) => (
                            <FormSegmentedControl
                                label="风格"
                                size="md"
                                compact
                                data={BUTTON_SHAPE_OPTIONS}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />

                    {shape === 'rectangle' && (
                        <Controller
                            name="radius"
                            control={control}
                            render={({ field }) => (
                                <FormSegmentedControl
                                    label="圆角"
                                    size="md"
                                    compact
                                    data={BUTTON_RADIUS_OPTIONS}
                                    value={field.value}
                                    onChange={v => field.onChange(v)}
                                />
                            )}
                        />
                    )}

                    <Controller
                        name="size"
                        control={control}
                        render={({ field }) => (
                            <FormSegmentedControl
                                label="尺寸"
                                size="md"
                                compact
                                data={BUTTON_SIZE_OPTIONS}
                                value={field.value}
                                onChange={v => field.onChange(v)}
                            />
                        )}
                    />
                </Group>
            </SCxContainer>

            <SCxContainer key={BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION} hidden={currentTab !== BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION}>
                <Group label="动作" styles={{ collapse: { padding: '0 8px !important' } }}>
                    <ActionAdder title={handleRenderTitle(name)} allPages={allPages} events={events} action={action} showLabel={false} />
                </Group>
                <Divider />
                <AnimationSetting />
            </SCxContainer>
            <SCxContainer key={BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE} hidden={currentTab !== BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE}>
                <Controller
                    control={control}
                    name="collectEvents"
                    render={({ field }) => (
                        <CollectionEvent
                            curr={curr}
                            prev={prev}
                            eventTypes={buttonEventTypes}
                            value={field.value}
                            onChange={field.onChange}
                        />
                    )}
                />
                <Divider />
                <VisibilitySetting />
            </SCxContainer>
        </Box>
    )
}
