import { Modal, Toast } from '@byecode/ui'
import { getFlatAllBlock } from '@lighthouse/block'
import type { BlockAbstract, ContainerBlockAbstract } from '@lighthouse/core'
import { BlockType, DIRECTION } from '@lighthouse/core'
import {
    deepClearSyncBlock,
    findBlockById,
    findParentBlockById,
    findParentBlockWithScopeById,
    getBlockChildren,
    getCurrentBlockChildren,
    initBlockRuntimeState,
    transformOriginBlock
} from '@lighthouse/shared'
import { current, original } from 'immer'
import { selectAtom, useAtomCallback } from 'jotai/utils'
import { clone } from 'rambda'
import { useCallback, useState } from 'react'

import { currentAppIdAtom, previewTypeAtom, syncComponentsAtom } from '@/atoms/application/state'
import { createBlockAtom, removeBlockAtom, updateRemoteBlockAtom } from '@/atoms/page/action'
import { blockCreatingListAtom, blocksAtom, pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { convertSize } from '@/components/DesignSetting/SizeSetting/utils'
import { getBlockInitConfig } from '@/constants/Block/help'
import { copyBlock, transformBlock2SyncComponents } from '@/hooks/layoutEngine/utils'
import * as srv from '@/services'

type Params = {
    rootPageId: string
    pageId: string
    stackId: string
}

export const useSyncHooks = (params: Params) => {
    const { pageId, stackId, rootPageId } = params
    const [creatingSyncNodeId, setCreatingSyncNodeId] = useState<string | null>(null)

    // 设置同步组件，打开创建弹窗
    const handleSetSyncNode = useCallback((id: string) => {
        setCreatingSyncNodeId(id)
    }, [])

    // 创建同步组件
    const handleCreateSyncNode = useAtomCallback(async (get, set, name: string) => {
        if (!creatingSyncNodeId) {
            return
        }
        const pageBlocks = get(blocksAtom)[pageId] ?? []
        const block = findBlockById(creatingSyncNodeId, pageBlocks)
        if (!block) {
            return
        }
        const blockRuntimeState = get(pageStackAtomFamily({ stackId, rootPageId }))?.blockRuntimeState
        // 如果不是容器block， 则自动创建一个容器block包裹
        if (block.type === BlockType.container) {
            const syncComponent = transformBlock2SyncComponents(copyBlock([block])[0])
            syncComponent.title = name

            let syncComponents: ContainerBlockAbstract[] = []
            set(syncComponentsAtom, draft => {
                set(blockCreatingListAtom, s => [...s, ...getFlatAllBlock([syncComponent]).map(item => item.id)])
                draft.push(syncComponent)

                syncComponents = current(draft)
            })

            await srv.createSyncBlock({ createBlocks: [syncComponent], blocks: syncComponents })
            set(blockCreatingListAtom, s => s.filter(id => !getFlatAllBlock([syncComponent]).some(item => item.id === id)))

            set(blocksAtom, draft => {
                const draftBlocks = draft[pageId]
                if (!draftBlocks) {
                    return
                }

                const draftBlock = findBlockById<ContainerBlockAbstract>(creatingSyncNodeId, draftBlocks)
                if (!draftBlock) {
                    return
                }

                const removedBlocks = current(draftBlock.children).reduce<BlockAbstract[]>(
                    (total, curr) => [...total, ...curr.children],
                    []
                )
                draftBlock.children = []
                draftBlock.config.viewList = []
                draftBlock.synchronousId = syncComponent.id

                set(pageStackAtom, draft => {
                    if (removedBlocks && removedBlocks.length > 0) {
                        const stack = equalPageStack({ rootPageId, stackId })(draft)
                        if (stack && stack.state) {
                            initBlockRuntimeState(stack, {
                                blocks: transformOriginBlock([current(draftBlock)], get(previewTypeAtom)),
                                syncComponents
                            })
                        }
                    }
                })

                if (removedBlocks.length !== 0) {
                    set(removeBlockAtom, {
                        rootPageId,
                        stackId,
                        blocks: current(draftBlocks),
                        removedBlocks,
                        pageId
                    })
                }

                const origin = original(draftBlock)
                if (origin) {
                    set(updateRemoteBlockAtom, {
                        pageId,
                        rootPageId,
                        stackId,
                        block: current(draftBlock),
                        origin,
                        appId: get(currentAppIdAtom)
                    })
                }
            })
        } else {
            const newBlock = getBlockInitConfig({ type: BlockType.container, subType: DIRECTION.vertical }) as ContainerBlockAbstract
            const previewType = get(previewTypeAtom)
            const parentBlock = findParentBlockWithScopeById({ id: creatingSyncNodeId }, pageBlocks, get(syncComponentsAtom))
            newBlock.config.breakPoint.size.width.size = convertSize({ id: creatingSyncNodeId }, 'width', 'px', previewType, parentBlock)
            newBlock.config.breakPoint.size.width.unit = 'px'
            newBlock.config.breakPoint.size.height.size = convertSize({ id: creatingSyncNodeId }, 'height', 'px', previewType, parentBlock)
            newBlock.config.breakPoint.size.height.unit = 'px'
            if (newBlock.config.breakPoint.layout) {
                newBlock.config.breakPoint.layout.padding = undefined
            }
            newBlock.children[0].children = [block]

            const removedBlocks = [block]

            const syncComponent = transformBlock2SyncComponents(clone(newBlock))
            syncComponent.title = name

            newBlock.children = []
            newBlock.config.viewList = []
            newBlock.synchronousId = syncComponent.id

            set(blockCreatingListAtom, s => [...s, ...getFlatAllBlock([syncComponent]).map(item => item.id)])

            let finalSyncComponents: ContainerBlockAbstract[] = []
            set(syncComponentsAtom, draft => {
                draft.push(syncComponent)

                finalSyncComponents = current(draft)

                set(pageStackAtom, draft => {
                    if (removedBlocks && removedBlocks.length > 0) {
                        const stack = equalPageStack({ rootPageId, stackId })(draft)
                        if (stack && stack.state) {
                            initBlockRuntimeState(stack, {
                                blocks: transformOriginBlock(removedBlocks, previewType),
                                syncComponents: finalSyncComponents
                            })
                        }
                    }
                })
            })

            await srv.createSyncBlock({ createBlocks: [syncComponent], blocks: finalSyncComponents })
            set(blockCreatingListAtom, s => s.filter(id => !getFlatAllBlock([syncComponent]).some(item => item.id === id)))

            set(blocksAtom, draft => {
                const draftBlocks = draft[pageId]
                if (!draftBlocks) {
                    return
                }

                const draftBlock = findParentBlockById<ContainerBlockAbstract>(creatingSyncNodeId, draftBlocks)
                const children = draftBlock ? getCurrentBlockChildren(draftBlock, { blockRuntimeState }) : draftBlocks
                if (!children) {
                    return
                }
                const index = children.findIndex(item => item.id === block.id)
                if (index === -1) {
                    return
                }

                children.splice(index, 1)
                set(removeBlockAtom, {
                    rootPageId,
                    stackId,
                    blocks: current(draftBlocks),
                    removedBlocks,
                    pageId
                })

                const createdBlocks = [newBlock]
                children.splice(index, 0, newBlock)
                set(createBlockAtom, { createdBlocks, blocks: current(draftBlocks), rootPageId, pageId, stackId })
            })
        }

        setCreatingSyncNodeId(null)
    })

    // 删除同步组件
    const handleRemoveSyncNode = useAtomCallback(
        useCallback(async (get, set, id: string) => {
            const block = get(selectAtom(syncComponentsAtom, s => s.find(item => item.id === id)))
            if (!block) {
                return
            }

            const isConfirm = await Modal.confirm({
                title: `确认删除同步组件 “${block.title}”？`,
                content: '删除后，使用到该组件的地方都会断开同步，此操作不可撤回，请谨慎操作！'
            })
            if (!isConfirm) {
                return
            }

            set(syncComponentsAtom, draft => {
                const index = draft.findIndex(item => item.id === id)
                if (index === -1) {
                    return
                }

                draft.splice(index, 1)
            })

            const res = await srv.deleteSyncBlock(id)
            if (res) {
                window.location.reload()
            }
        }, [])
    )

    // 取消同步
    const handleUnSetSyncNode = useAtomCallback(
        useCallback(
            async (get, set, id: string) => {
                const syncComponents = get(syncComponentsAtom)

                const appId = get(currentAppIdAtom)
                const undoRedoInfo: {
                    prevBlock?: BlockAbstract
                    nextBlock?: BlockAbstract
                    nextBlocks?: BlockAbstract[]
                } = {}

                let createdBlocks: BlockAbstract[] | undefined

                set(blocksAtom, draft => {
                    const draftBlocks = draft[pageId]
                    if (!draftBlocks) {
                        return
                    }

                    const draftBlock = findBlockById<ContainerBlockAbstract>(id, draftBlocks)
                    undoRedoInfo.prevBlock = original(draftBlock)
                    if (!draftBlock || !undoRedoInfo.prevBlock) {
                        return
                    }

                    const syncComponent = syncComponents.find(item => item.id === draftBlock.synchronousId)
                    if (!syncComponent) {
                        return
                    }

                    const newBlock = copyBlock([syncComponent])[0]
                    createdBlocks = getBlockChildren(newBlock)
                    if (createdBlocks) {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        set(blockCreatingListAtom, s => [...s, ...getFlatAllBlock(createdBlocks!).map(item => item.id)])
                    }

                    draftBlock.config = newBlock.config
                    draftBlock.title = newBlock.title
                    draftBlock.children = newBlock.children
                    deepClearSyncBlock(draftBlock)

                    undoRedoInfo.nextBlock = current(draftBlock)
                    undoRedoInfo.nextBlocks = current(draftBlocks)

                    set(pageStackAtom, draft => {
                        if (createdBlocks && createdBlocks.length > 0) {
                            const stack = equalPageStack({ rootPageId, stackId })(draft)
                            if (stack && stack.state) {
                                initBlockRuntimeState(stack, {
                                    blocks: transformOriginBlock(createdBlocks, get(previewTypeAtom)),
                                    syncComponents
                                })
                            }
                        }
                    })

                    Toast.success('已取消同步')
                })

                if (!undoRedoInfo.prevBlock || !undoRedoInfo.nextBlock || !undoRedoInfo.nextBlocks) {
                    return
                }

                await set(updateRemoteBlockAtom, {
                    rootPageId,
                    pageId,
                    stackId,
                    appId,
                    block: undoRedoInfo.nextBlock,
                    origin: undoRedoInfo.prevBlock
                })

                if (createdBlocks && createdBlocks.length > 0) {
                    await set(createBlockAtom, {
                        rootPageId,
                        pageId,
                        stackId,
                        createdBlocks,
                        blocks: undoRedoInfo.nextBlocks,
                        autoSelect: false
                    })

                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    set(blockCreatingListAtom, s => s.filter(id => !getFlatAllBlock(createdBlocks!).some(item => item.id === id)))
                }
            },
            [pageId, rootPageId, stackId]
        )
    )

    return {
        creatingSyncNodeId,
        setCreatingSyncNodeId,
        onSetSyncNode: handleSetSyncNode,
        onUnsetSyncNode: handleUnSetSyncNode,
        onCreateSyncNode: handleCreateSyncNode,
        onRemoveSyncNode: handleRemoveSyncNode
    }
}
