import { IconFont, Tooltip } from '@byecode/ui'
import type { ButtonEvents, ButtonHandleEvent } from '@lighthouse/core'
import { type ButtonBlockHandleEvent, ActionItemPattern, RecordOpenType } from '@lighthouse/core'
import { type SegmentedControlItem, Text } from '@mantine/core'
import React from 'react'

import { OpenTypeData } from '../Common/UserOperate/constants'
import type { ButtonConfig } from './type'

export const buttonActions: Record<ButtonBlockHandleEvent, ButtonConfig[]> = {
    none: [],
    openPage: [
        {
            type: 'select',
            placeHolder: '请选择页面',
            label: '选择页面',
            name: 'page',
            index: 0
        },
        {
            type: 'segmented',
            placeHolder: '',
            label: '展开方式',
            name: 'pageOpenType',
            data: OpenTypeData,
            index: 1
        }
    ],
    openFormPage: [],
    openRecordPage: [],
    openRecordEditPage: [],
    openLink: [
        {
            type: 'input',
            placeHolder: 'https://',
            label: '链接',
            name: 'url',
            index: 0
        }
    ],
    scrollTo: [],
    control: [],
    goBack: [],
    notification: [
        {
            type: 'input',
            placeHolder: '请输入',
            label: '内容',
            name: 'content',
            index: 0
        },
        {
            type: 'select',
            placeHolder: '',
            label: '提示类型',
            name: 'notificationType',
            index: 1
        }
    ],
    copy: [
        {
            type: 'select',
            placeHolder: '',
            label: '复制数据',
            name: 'copyType',
            index: 0
        },
        {
            type: 'input',
            placeHolder: '请输入',
            label: '自定义',
            name: 'content',
            index: 1
        }
    ],
    createRecord: [
        {
            type: 'select',
            placeHolder: '请选择数据表',
            label: '数据表',
            name: 'datasource',
            index: 0
        },
        {
            type: 'select',
            placeHolder: '请选择视图',
            label: '视图',
            name: 'view',
            index: 1
        }
    ],
    updateRecord: [],
    deleteRecord: [],
    shareToWechat: [],
    downloadFile: [],
    call: [],
    iClick: [],
    aliyunMessage: [],
    wechatPay: [],
    wechatTemplateMsg: [],
    stripe: [],
    refreshPage: [],
    closeFloat: []
}

export const optionActions = [
    {
        section: '打开',
        items: [
            {
                icon: 'BlockLinkToPage',
                name: '打开页面',
                action: 'openPage'
            },
            {
                icon: 'LinkSimple',
                name: '打开链接',
                action: 'openLink'
            }
        ]
    },
    {
        section: '记录',
        items: [
            {
                icon: 'Add',
                name: '创建记录',
                action: 'createRecord'
            },
            {
                icon: 'ArrowCounterClockwise',
                name: '更新记录',
                action: 'updateRecord'
                // disable: true
            },
            {
                icon: 'Trash',
                name: '删除记录',
                action: 'deleteRecord'
                // disable: true
            }
        ]
    }
]

export const data: {
    value: ButtonBlockHandleEvent
    label: string
    icon: string
    group: string
    disable?: boolean
}[] = [
    { value: 'openPage', label: '打开页面', icon: 'BlockLinkToPage', group: '打开' },
    { value: 'openLink', label: '打开链接', icon: 'LinkSimple', group: '打开' },
    { value: 'shareToWechat', label: '分享到微信', icon: 'BrandLogoqiyeweixin', group: '打开' },
    { value: 'goBack', label: '返回上一步', icon: 'Undo', group: '打开' },
    { value: 'notification', label: '显示提示', icon: 'Bell', group: '打开' },
    { value: 'copy', label: '复制到剪切板', icon: 'Duplicate', group: '打开' },
    { value: 'createRecord', label: '创建记录', disable: true, icon: 'Add', group: '记录' },
    { value: 'updateRecord', label: '更新记录', disable: true, icon: 'ArrowCounterClockwise', group: '记录' },
    { value: 'deleteRecord', label: '删除记录', disable: true, icon: 'Trash', group: '记录' }
]

export const btnTypeList: { value: string; label: string }[] = [
    {
        value: 'name',
        label: '仅文字'
    },
    {
        value: 'icon',
        label: '仅图标'
    },
    {
        value: 'icon-name',
        label: '图标加文字'
    }
]

export const commonPages = [
    {
        label: '注册页面',
        value: '/account/register',
        group: 'common'
    },
    {
        label: '登录页面',
        value: '/account/login',
        group: 'common'
    }
]

export const BUTTON_SHAPE_OPTIONS = [
    {
        label: (
            <Tooltip title="矩形按钮">
                <IconFont size={16} color="var(--color-gray-500)" type="Rectangle" />
            </Tooltip>
        ),
        value: 'rectangle'
    },
    {
        label: (
            <Tooltip title="圆角按钮">
                <IconFont size={16} color="var(--color-gray-500)" type="Pill" />
            </Tooltip>
        ),
        value: 'round'
    }
]

export const BUTTON_RADIUS_OPTIONS = [
    {
        label: (
            <Tooltip title="无圆角">
                <IconFont size={16} color="var(--color-gray-500)" type="RadiusOne" />
            </Tooltip>
        ),
        value: '0'
    },
    {
        label: (
            <Tooltip title="较小圆角">
                <IconFont size={16} color="var(--color-gray-500)" type="RadiusTwo" />
            </Tooltip>
        ),
        value: '4'
    },
    {
        label: (
            <Tooltip title="标准圆角">
                <IconFont size={16} color="var(--color-gray-500)" type="RadiusThree" />
            </Tooltip>
        ),
        value: '8'
    },
    {
        label: (
            <Tooltip title="较大圆角">
                <IconFont size={16} color="var(--color-gray-500)" type="RadiusFour" />
            </Tooltip>
        ),
        value: '10'
    }
]

export const BUTTON_SIZE_OPTIONS = [
    {
        label: (
            <Tooltip title="最大">
                <Text>XXL</Text>
            </Tooltip>
        ),
        value: 'xxl'
    },
    {
        label: (
            <Tooltip title="超大">
                <Text>XL</Text>
            </Tooltip>
        ),
        value: 'xl'
    },

    {
        label: (
            <Tooltip title="大">
                <Text>L</Text>
            </Tooltip>
        ),
        value: 'lg'
    },
    {
        label: (
            <Tooltip title="中">
                <Text>M</Text>
            </Tooltip>
        ),
        value: 'md'
    },
    {
        label: (
            <Tooltip title="小">
                <Text>S</Text>
            </Tooltip>
        ),
        value: 'sm'
    }
]

/** 位置选项 */
export const ICON_POSITION_CONTROL: SegmentedControlItem[] = [
    {
        label: '左',
        value: 'left'
    },
    {
        label: '右',
        value: 'right'
    }
]

/** 宽度规则 */
export const FIT_TYPE_CONTROL: SegmentedControlItem[] = [
    {
        label: (
            <Tooltip title="包住内容">
                <IconFont type="ArrowsInLineHorizontal" />
            </Tooltip>
        ),
        value: 'auto'
    },
    {
        label: (
            <Tooltip title="撑满容器">
                <IconFont type="ArrowsOutLineHorizontalContain" />
            </Tooltip>
        ),
        value: 'contain'
    }
]

/** 按钮样式 */
export const BUTTON_PATTERN_CONTROL: SegmentedControlItem[] = [
    {
        label: '主按钮',
        value: ActionItemPattern.primary
    },
    {
        label: '次按钮',
        value: ActionItemPattern.secondary
    },
    {
        label: '自定义',
        value: ActionItemPattern.custom
    }
]

export const buttonEventsMap = new Map<ButtonHandleEvent, ButtonEvents>([
    [
        'none',
        {
            handleEvent: 'none',
            params: [],
            triggerEvent: 'click'
        }
    ],
    [
        'createRecord',
        {
            handleEvent: 'createRecord',
            params: ['', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'deleteRecord',
        {
            handleEvent: 'deleteRecord',
            params: ['', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'updateRecord',
        {
            handleEvent: 'updateRecord',
            params: ['', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'openLink',
        {
            handleEvent: 'openLink',
            params: [''],
            triggerEvent: 'click'
        }
    ],
    [
        'openPage',
        {
            handleEvent: 'openPage',
            params: ['', RecordOpenType.modal],
            triggerEvent: 'click'
        }
    ],
    [
        'goBack',
        {
            handleEvent: 'goBack',
            params: [],
            triggerEvent: 'click'
        }
    ],
    [
        'notification',
        {
            handleEvent: 'notification',
            params: ['', 'info'],
            triggerEvent: 'click'
        }
    ],
    [
        'copy',
        {
            handleEvent: 'copy',
            params: ['custom', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'closeFloat',
        {
            handleEvent: 'closeFloat',
            params: [],
            triggerEvent: 'click'
        }
    ]
])
