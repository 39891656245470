import React, { useMemo } from 'react'
import { createSearchParams } from 'react-router-dom'
import styled from 'styled-components'

interface BiliPlayerProps {
    source?: string
    autoplay?: boolean
}

const regex = /\/video\/([\dA-Za-z]+)/u

const SCxIframe = styled.iframe`
    position: absolute;
    width: 100%;
    height: 100%;
    inset: 0;
`

export const BiliPlayer: React.FunctionComponent<BiliPlayerProps> = ({ source, autoplay = false }) => {
    const search = useMemo(() => {
        const match = source?.match(regex)
        const videoId = match ? match[1] : ''
        const searchParams = new URLSearchParams()
        searchParams.append('bvid', videoId)
        searchParams.append('autoplay', String(autoplay))
        return searchParams.toString()
    }, [autoplay, source])

    return (
        <SCxIframe
            src={`https://player.bilibili.com/player.html?${search}`}
            // allowfullscreen="allowfullscreen"
            title="bilibili"
            allowFullScreen
            // frameBorder="no"
            // scrolling="no"
            sandbox="allow-top-navigation allow-same-origin allow-forms allow-scripts allow-popups"
        >
            没有找到对应视频
        </SCxIframe>
    )
}
