import { Button, Flex, Image, Popover, Text } from '@byecode/ui'
import { getFileTypeByUrl, TagIcon, TitleInContentModal, VideoPlayer } from '@lighthouse/shared'
import type { PopoverBaseProps } from '@mantine/core'
import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { tooltipDocumentMap } from './constants'
import type { TooltipDocumentType } from './types'

interface QuestionDropDownProps {
    type: TooltipDocumentType
    onPointerEnter?: () => void
    onPointerLeave?: () => void
    onLookVideo?: () => void
}

const SCxDropDown = styled.div`
    width: 100%;
    padding: 12px;
    color: var(--color-white);
    display: flex;
    flex-direction: column;
    gap: 8px;
`

export const BlockQuestion: React.FC<QuestionDropDownProps> = ({ type, onPointerEnter, onLookVideo, onPointerLeave }) => {
    const data = tooltipDocumentMap[type]
    const fileType = getFileTypeByUrl(data.file ?? '')
    return (
        <SCxDropDown
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
            style={{
                padding: 0,
                overflow: 'hidden',
                gap: 0
            }}
        >
            {fileType === 'image' ? (
                <Image src={data.file} style={{ backgroundColor: '#EDEDFF' }} />
            ) : (
                <video src={data.file} autoPlay controls />
            )}
            <Flex style={{ padding: 16, background: 'var(--color-black)' }} direction="column">
                <Text size={16} whiteSpace="pre-wrap" weight={600} lineHeight="22px" color="var(--color-white)">
                    {data.title}
                </Text>
                <Text size={14} whiteSpace="pre-wrap" lineHeight="22px" color="var(--color-white)" style={{ marginTop: 12 }}>
                    {data.describe}
                </Text>
                <Flex style={{ marginTop: 16 }} gap={12}>
                    {data.link && (
                        <Button
                            style={{
                                background: 'var(--color-theme-7)',
                                height: 40,
                                color: 'var(--color-white)',
                                borderColor: 'transparent'
                            }}
                            block
                            onClick={() => window.open(data.link, '_target')}
                        >
                            {data.linkText}
                        </Button>
                    )}
                    {fileType === 'video' && (
                        <Button
                            style={{ background: '#FFFFFF38', height: 40, color: 'var(--color-white)', borderColor: 'transparent' }}
                            block
                            onClick={onLookVideo}
                        >
                            查看视频
                        </Button>
                    )}
                </Flex>
            </Flex>
        </SCxDropDown>
    )
}
