import { Divider, Flex, Group, Switch } from '@byecode/ui'
import type { ContainerBlockConfig, FormContainerBlockConfig } from '@lighthouse/core'
import { BlockType, EVENT_VARIABLE_TYPE } from '@lighthouse/core'
import {
    getDefaultAction,
    Group4ByecodeUi,
    ListItem4ByecodeUi,
    SelectDataSource,
    useAtomData,
    useSharedConfigDisabledWithVersion
} from '@lighthouse/shared'
import { stopPropagation } from '@lighthouse/tools'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { lastPageOfStackAtom, pageAtomFamily } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { CollectionEvent } from '@/components/CollectionEvent'
import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import AnimationController from '@/components/DesignSetting/AnimationSetting'
import { AutoLayoutSetting } from '@/components/DesignSetting/AutoLayoutSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { StyleSetting } from '@/components/DesignSetting/StyleSetting'
import { InheritLabel } from '@/components/InheritLabel'
import { PopoverQuestion } from '@/components/PopoverQuestion'
import { ValidationRuleAdder } from '@/components/ValidationRuleAdder'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { WeChatCollector } from '@/components/WeChatCollector'
import { ActionAdderProvider } from '@/contexts/ActionAdderContext'
import { useBlockSettingContext } from '@/contexts/BlockSettingContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useNotAggregateDataSourceList } from '@/hooks/useDataSource'
import { useDefaultPageList, usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { ButtonItemConfigure } from '../Button/ButtonItemConfigure'
import { BUTTON_RADIUS_OPTIONS, BUTTON_SIZE_OPTIONS } from '../Button/constant'
import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import { FloatingControl } from '../Common/FloatingControl'
import FormSegmentedControl from '../Common/FormSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM, POSITION_ALIGN_CONTROL } from '../constants'
import { ViewListSettings } from './ViewListSettings'

interface ContainerSettingProps {
    type: Extract<BlockType, 'container' | 'formContainer' | 'tabsContainer'>
}

const formEventTypes = [EVENT_VARIABLE_TYPE.BUTTON_CLICK]

export const ContainerSetting: React.FC<ContainerSettingProps> = ({ type }) => {
    const { watch, register, control } = useFormContext<ContainerBlockConfig | FormContainerBlockConfig>()
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const designProps = getBlockAndPageDesignLimit(type)
    const isFormContainer = type === BlockType.formContainer
    const allPages = useDefaultPageList()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useNotAggregateDataSourceList(appId, envId)
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )

    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const [pageDsId, pageType] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(p => [p?.dsId, p?.type], [])
    )
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const { blockId } = useBlockSettingContext()

    const [action, formPointer] = watch(['action', 'pointer'])

    const formModuleDsId = useMemo(() => formPointer || pageDsId || '', [formPointer, pageDsId])
    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <AutoLayoutSetting {...designProps.layout} />
                    <Divider />
                    <StyleSetting {...designProps.design} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && (
                <>
                    {!isFormContainer && (
                        <>
                            <Controller
                                name="floatPointer"
                                control={control}
                                render={({ field }) => {
                                    return <FloatingControl value={field.value ?? ''} blockId={blockId} onChange={field.onChange} />
                                }}
                            />
                            <Divider />
                        </>
                    )}
                    <AnimationController />
                </>
            )}
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    {!isFormContainer && (
                        <>
                            <Group
                                label={
                                    <PopoverQuestion
                                        type="panel"
                                        renderTarget={(node, ref) => (
                                            <Flex gap={4} alignItems="center" ref={ref} onClick={stopPropagation}>
                                                <InheritLabel label="面板" name="breakPoint.panel.defaultPanel" />
                                                {node}
                                            </Flex>
                                        )}
                                    />
                                }
                            >
                                <ViewListSettings />
                            </Group>

                            <Divider />
                        </>
                    )}
                    {isFormContainer && (
                        <>
                            <Group4ByecodeUi label={isFormContainer ? '数据' : '容器配置'}>
                                {isFormContainer && (
                                    <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                                        <div>{isFormContainer ? '提交数据到' : '数据来源'}</div>
                                        <Controller
                                            name="pointer"
                                            control={control}
                                            render={({ field: { value, onChange } }) => (
                                                <SelectDataSource
                                                    withinPortal
                                                    placeholder="请选择数据表"
                                                    value={value}
                                                    disabled={disabledWithVersion}
                                                    onChange={onChange}
                                                    // 表单编辑页可以使用用户表
                                                    dataSourceList={dataSourceList}
                                                />
                                            )}
                                        />
                                    </ListItem4ByecodeUi>
                                )}
                            </Group4ByecodeUi>
                            <Divider color="var(--color-gray-200)" />
                            <Group label="提交按钮">
                                <ButtonItemConfigure
                                    prefixName="submitButton"
                                    listKeyName=""
                                    style={{ padding: 0 }}
                                    hideConfigs={{ fillWay: true }}
                                    // themeColor="primaryColor"
                                />
                                <Controller
                                    name="submitButton.size"
                                    control={control}
                                    render={({ field }) => (
                                        <FormSegmentedControl
                                            label="尺寸"
                                            size="md"
                                            compact
                                            data={BUTTON_SIZE_OPTIONS}
                                            value={field.value}
                                            onChange={v => field.onChange(v)}
                                        />
                                    )}
                                />
                                <Controller
                                    name="submitButton.radius"
                                    control={control}
                                    render={({ field }) => (
                                        <FormSegmentedControl
                                            label="圆角"
                                            size="md"
                                            compact
                                            data={BUTTON_RADIUS_OPTIONS}
                                            value={field.value}
                                            onChange={v => field.onChange(v)}
                                        />
                                    )}
                                />
                                <Controller
                                    name="submitButton.align"
                                    control={control}
                                    render={({ field }) => (
                                        <FormSegmentedControl
                                            label="对齐"
                                            size="md"
                                            compact
                                            data={POSITION_ALIGN_CONTROL}
                                            value={field.value}
                                            onChange={v => field.onChange(v)}
                                        />
                                    )}
                                />
                            </Group>
                            <Divider />
                            <Group collapseProps={{ style: { padding: '0 8px' } }} label="表单提交后动作">
                                {action && (
                                    <ActionAdderProvider dsId={formModuleDsId} isForm>
                                        <ActionAdder
                                            title="表单提交后"
                                            allPages={allPages}
                                            events={{
                                                params: [],
                                                handleEvent: 'copy',
                                                triggerEvent: 'click'
                                            }}
                                            action={action}
                                            prefixName=""
                                            showLabel={false}
                                        />
                                    </ActionAdderProvider>
                                )}
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                            <Controller
                                control={control}
                                name="collectEvents"
                                render={({ field }) => (
                                    <CollectionEvent
                                        eventTypes={formEventTypes}
                                        value={field.value}
                                        curr={curr}
                                        prev={prev}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                            <Divider color="var(--color-gray-200)" />
                            <Group label="自定义校验规则">
                                <ValidationRuleAdder />
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                            <Group label="自动采集">
                                <WeChatCollector />
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                        </>
                    )}
                    {!isFormContainer && (
                        <>
                            <Group label="动作" styles={{ collapse: { padding: '0 8px !important' } }}>
                                <ActionAdder
                                    showLabel={false}
                                    title="点击容器后"
                                    allPages={allPages}
                                    action={action ?? getDefaultAction()}
                                    prefixName=""
                                />
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                            <Group label="其他" styles={{ collapse: { padding: '0 16px' } }}>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <div>展开容器</div>
                                    <Controller
                                        control={control}
                                        name="shown"
                                        render={({ field }) => (
                                            <Switch checked={field.value} onChange={e => field.onChange(e.target.checked)} />
                                        )}
                                    />
                                </ListItem4ByecodeUi>
                            </Group>
                            <Divider color="var(--color-gray-200)" />
                        </>
                    )}
                    <VisibilitySetting />
                </>
            )}
        </>
    )
}
