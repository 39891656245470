import { pointer } from '@byecode/ui'
import React, { useCallback } from 'react'
import styled, { css } from 'styled-components'

import type { Options } from './types'

interface CardSelectProps {
    value?: string[]
    options: Options
    onChange: (val: string[]) => void
}

const SCxContainer = styled.div`
    display: flex;
    gap: 20px;
    display: flex;
    flex-flow: row wrap;
`

const SCxItem = styled.div<{ active?: boolean }>`
    width: 162px;
    height: 162px;
    border-radius: 8px;
    border-width: 2px;
    border-color: var(--color-gray-200);
    border-style: solid;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    ${pointer}

    ${({ active }) =>
        active &&
        css`
            box-shadow: 0px 4px 12px 0px #1018281a;
            border-color: var(--color-main);
            border-width: 3px;
        `}
`

const SCxImage = styled.div<{ background?: string }>`
    width: 100%;
    flex: 1;
    background: ${({ background }) => (background ? `url('${background}')` : 'var(--color-gray-100)')};
    background-size: contain;
`

const SCxText = styled.div<{ active?: boolean }>`
    height: 32px;
    line-height: 32px;
    text-align: center;
    font-size: var(--font-size-normal);
    color: ${({ active }) => (active ? 'var(--color-main)' : 'var(--color-gray-900)')};
`

export const CardSelect: React.FC<CardSelectProps> = ({ value, options, onChange }) => {
    const handleClick = useCallback(
        (val: string) => {
            if (!value) {
                onChange([val])
                return
            }
            if (value.includes(val)) {
                const newValue = value.filter(v => v !== val)
                onChange(newValue)
                return
            }
            onChange([...value, val])
        },
        [onChange, value]
    )
    return (
        <SCxContainer>
            {options.map(item => (
                <SCxItem key={item.value} active={value?.includes(item.value)} onClick={() => handleClick(item.value)}>
                    <SCxImage background={item.icon} />
                    <SCxText active={value?.includes(item.value)}>{item.label}</SCxText>
                </SCxItem>
            ))}
        </SCxContainer>
    )
}
