import { Flex, Loading } from '@byecode/ui'
import { type DataSourceAbstract, type ViewFieldProps } from '@lighthouse/core'
import { Modal } from '@mantine/core'
import React, { useCallback, useState } from 'react'
import styled from 'styled-components'

import type { AppendParams, ImportParams } from '../../types'
import { TableUploader } from './TableUploader'

export * from './TableUploader'
interface ImportModalProps {
    appId: string
    envId: string
    dataSource?: DataSourceAbstract
    open: boolean
    defaultImportType?: 'create' | 'append'
    dataSourceList?: DataSourceAbstract[]
    disableSelectDataSource?: boolean
    userImportSetting?: string[]
    isShowRepeatConfig?: boolean
    viewColumns?: ViewFieldProps[]
    onImport?: (params: ImportParams) => Promise<boolean | undefined>
    onAppend?: (params: AppendParams) => Promise<boolean | undefined>
    onClose: () => void
}

const SCxLoading = styled(Loading)`
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 239px;
`

const SCxTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    color: var(--color-black);
`

const SCxDescription = styled.div`
    font-size: 14px;
    color: var(--color-gray-400);
`

export const ImportModal: React.FC<ImportModalProps> = ({
    appId,
    envId,
    dataSource,
    open,
    defaultImportType = 'create',
    dataSourceList,
    disableSelectDataSource,
    userImportSetting,
    isShowRepeatConfig = true,
    viewColumns,
    onImport,
    onAppend,
    onClose
}) => {
    const [loading, setLoading] = useState(false)
    const handleAppend = useCallback(
        async (params: AppendParams) => {
            if (onAppend) {
                setLoading(true)
                const isAppend = await onAppend(params)
                setLoading(false)
                return isAppend
            }
            return false
        },
        [onAppend]
    )

    const handleImport = useCallback(
        async (params: ImportParams) => {
            if (onImport) {
                setLoading(true)
                const isImport = await onImport(params)
                setLoading(false)
                return isImport
            }
            return false
        },
        [onImport]
    )

    return (
        <>
            <Modal
                centered
                closeOnClickOutside={false}
                padding={0}
                radius={12}
                size={690}
                overlayColor="var(--color-black)"
                overlayOpacity={0.75}
                overlayBlur={3}
                withCloseButton={false}
                opened={open}
                onClose={onClose}
            >
                <TableUploader
                    appId={appId}
                    envId={envId}
                    dataSource={dataSource}
                    defaultImportType={defaultImportType}
                    dataSourceList={dataSourceList}
                    disableSelectDataSource={disableSelectDataSource}
                    userImportSetting={userImportSetting}
                    isShowRepeatConfig={isShowRepeatConfig}
                    viewColumns={viewColumns}
                    onImport={handleImport}
                    onAppend={handleAppend}
                    onClose={onClose}
                />
            </Modal>
            {loading && (
                // eslint-disable-next-line no-empty-function
                <Modal centered opened size={344} radius={12} withCloseButton={false} onClose={() => {}}>
                    <SCxLoading
                        size={48}
                        outlined
                        description={
                            <Flex direction="column" gap={12} alignItems='center'>
                                <SCxTitle>正在导入数据...</SCxTitle>
                                <SCxDescription>请不要刷新页面，导入开始后不可中途停止</SCxDescription>
                            </Flex>
                        }
                    />
                </Modal>
            )}
        </>
    )
}
