import type { FC, PropsWithChildren } from 'react'
import React, { createContext, useContext, useMemo } from 'react'

import type { DragPlugin, LayoutInteractionEngine, ResizePlugin } from '../components'

export type PageContainerContextType = {
    endpoint: 'xl' | 'lg' | 'md' | 'sm' | 'xs'
    dragPlugin?: LayoutInteractionEngine<DragPlugin | ResizePlugin> | null
}

export const PageContainerContext = createContext<PageContainerContextType>({
    endpoint: 'xl',
    dragPlugin: null
})

type PageContainerProviderProps = PropsWithChildren<PageContainerContextType>

export const PageContainerProvider: FC<PageContainerProviderProps> = props => {
    const { children, endpoint, dragPlugin } = props

    const contextValue = useMemo(() => ({ endpoint, dragPlugin }), [endpoint, dragPlugin])

    return <PageContainerContext.Provider value={contextValue}>{children}</PageContainerContext.Provider>
}

export const usePageContainer = () => useContext(PageContainerContext)
