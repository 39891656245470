import type { DataSourceAbstract } from '@lighthouse/core'
import { type ConditionFlowNode, type ConditionNodeConfig, filterRecordChecker, resolveFilter } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'
import { find } from 'rambda'

export const handleCondition = async (node: ConditionFlowNode, extraParams: AnyObject) => {
    const {
        data: { config }
    } = node
    const { filter } = config || {}
    const { clickTriggerNodeParams, dataSourceList, userRecord, prevRecord, viewRecord, currentPageLink, pageStackFormState } = extraParams
    const { record } = clickTriggerNodeParams

    // 是否通过校验
    let isPass = false

    if (filter?.expression?.conditions?.length) {
        const resolvedFilter = resolveFilter({ filter, extraParams, useInFilterRecordChecker: true })

        const dataSource = find(({ id: dsId }) => record?.dsId === dsId, dataSourceList as DataSourceAbstract[])
        const prevDataSource = find(({ id: dsId }) => prevRecord?.dsId === dsId, dataSourceList as DataSourceAbstract[])
        const viewDataSource = find(({ id: dsId }) => viewRecord?.dsId === dsId, dataSourceList as DataSourceAbstract[])
        if (resolvedFilter?.expression?.conditions?.length /* && dataSource */) {
            isPass = filterRecordChecker({
                filter: resolvedFilter,
                currentRecord: {
                    record,
                    datasource: dataSource
                },
                prevRecord: {
                    record: prevRecord,
                    datasource: prevDataSource
                },
                viewRecord: {
                    record: viewRecord,
                    datasource: viewDataSource
                },
                pageStackFormState,
                userRecord,
                currentPageLink,
                initStatus: false
            })
        }
    }

    // eslint-disable-next-line no-return-await
    return await Promise.resolve({
        success: isPass
    })
}
