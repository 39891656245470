import { SegmentedControl } from '@byecode/ui/components/SegmentedControl'
import { IconFont, singleTextEllipsis } from '@lighthouse/bui'
import type { ApplicationSettingThemeColor, TabsColorConfig, VariableADTvalue } from '@lighthouse/core'
import {
    applyFillPickerValue4CssColorValue,
    combineBackgroundStyle,
    getBackgroundStyle,
    getBorderStyle,
    getRadiusStyle,
    getShadowStyle,
    getTextColorStyle,
    getVeinsStyle,
    TEXT_FONT_NORMAL,
    useApplicationContext,
    useFillPickerContext
} from '@lighthouse/shared'
import classnames from 'classnames'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import styled, { css } from 'styled-components'

import type { BaseTabsProps } from '../Tabs.type'
import { TabsContainer } from '../TabsContainer'

const Segmented = styled(SegmentedControl).withConfig<{ borderRadius?: number }>({ shouldForwardProp: p => p !== 'borderRadius' })`
    max-width: 720px;

    border-radius: ${({ borderRadius }) => borderRadius && `${borderRadius}px`};

    & > span {
        border-radius: ${({ borderRadius }) => borderRadius && `${borderRadius}px`};
    }

    label {
        color: var(--color-gray-600);
    }
`

const TabIcon = styled(IconFont)``
const TabLabel = styled.div`
    flex: 1;
`
type TabProps = {
    appId: string | undefined
    lineClamp?: number
    normal?: TabsColorConfig
    hover?: TabsColorConfig
    active?: TabsColorConfig
    palettes: ApplicationSettingThemeColor[]
    parseVariableImage?: (value: VariableADTvalue) => string
}
const Tab = styled.button.withConfig<TabProps>({
    shouldForwardProp: p => !['palettes', 'parseVariableImage', 'lineClamp', 'normal', 'hover', 'active'].includes(p)
})`
    all: unset;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    padding: 6px 16px;
    border: 1px solid transparent;
    /* overflow: hidden; */

    ${TabLabel} {
        ${({ lineClamp }) => {
            if (!lineClamp) {
                return
            }

            return lineClamp === 1
                ? singleTextEllipsis
                : css`
                      display: -webkit-box;
                      -webkit-box-orient: vertical;
                      -webkit-line-clamp: ${lineClamp};
                      overflow: hidden;
                  `
        }};
    }

    ${({ appId, normal, hover, active, parseVariableImage, palettes }) => {
        return {
            '&:not([data-active])&:not(:hover)': {
                ...getBackgroundStyle(appId, normal?.background, palettes, parseVariableImage),
                borderColor: applyFillPickerValue4CssColorValue(normal?.borderColor?.color, palettes),
                [TabLabel]: {
                    ...getTextColorStyle(normal?.color, palettes)
                },
                [TabIcon]: {
                    ...getTextColorStyle(normal?.iconColor, palettes)
                }
            },

            '&:not([data-active]):hover': {
                ...getBackgroundStyle(appId, hover?.background, palettes, parseVariableImage),
                borderColor: applyFillPickerValue4CssColorValue(hover?.borderColor?.color, palettes),
                [TabLabel]: {
                    ...getTextColorStyle(hover?.color, palettes)
                },
                [TabIcon]: {
                    ...getTextColorStyle(hover?.iconColor, palettes)
                }
            },

            '&.active': {
                ...getBackgroundStyle(appId, active?.background, palettes, parseVariableImage),
                borderColor: applyFillPickerValue4CssColorValue(active?.borderColor?.color, palettes),
                boxShadow: 'rgba(16, 24, 40, 0.1) 0px 1px 4px 0px',
                [TabLabel]: {
                    ...getTextColorStyle(active?.color, palettes)
                },
                [TabIcon]: {
                    ...getTextColorStyle(active?.iconColor, palettes)
                }
            }
        }
    }}
`

const TabsContent = styled.div`
    display: flex;
    gap: 2px;
    padding: 2px;
`

interface SegmentedTabsProps extends BaseTabsProps {}

export const SegmentedTabs: FC<SegmentedTabsProps> = ({
    design,
    list,
    value,
    onChange,
    onClickTab,
    align,
    font,
    parseVariableImage,
    ...rest
}) => {
    const { background, veins, shadow, border, radius } = design ?? {}
    const { fontFamily, fontStyle, fontWeight, fontSize, letterSpacing, lineClamp, lineHeight, align: textAlign } = font ?? {}
    const { appId } = useApplicationContext()

    const { palettes } = useFillPickerContext()

    return (
        <TabsContainer style={{ justifyContent: align }} {...rest}>
            <TabsContent
                style={{
                    ...combineBackgroundStyle([
                        getBackgroundStyle(appId, background, palettes, parseVariableImage),
                        getVeinsStyle(veins, palettes)
                    ]),
                    ...getShadowStyle(shadow, palettes),
                    ...getBorderStyle(border, palettes),
                    ...getRadiusStyle(radius)
                }}
            >
                {list.slice(0, 5).map(tab => (
                    <Tab
                        key={tab.id}
                        type="button"
                        data-active={tab.id === value || undefined}
                        className={classnames({ active: tab.id === value })}
                        onClick={e => {
                            onClickTab?.(e, tab.id)
                            if (value === tab.id) {
                                return
                            }

                            onChange?.(tab.id)
                        }}
                        style={{
                            ...getRadiusStyle(radius),
                            // flex: tab.sizeFit === SIZE_FIT.fill ? 1 : undefined,
                            fontFamily: fontFamily === TEXT_FONT_NORMAL ? undefined : fontFamily,
                            fontStyle,
                            fontWeight,
                            fontSize: `${fontSize}em`,
                            letterSpacing: `${letterSpacing ?? 0}rem`,
                            lineHeight: `${lineHeight ?? 1}`,
                            textAlign
                        }}
                        appId={appId}
                        lineClamp={lineClamp}
                        parseVariableImage={parseVariableImage}
                        palettes={palettes}
                        normal={{
                            background: tab.background,
                            color: tab.color,
                            iconColor: tab.iconColor,
                            borderColor: tab.borderColor
                        }}
                        hover={tab.hover}
                        active={tab.active}
                    >
                        {tab.icon && <TabIcon type={tab.icon} style={tab.iconPosition === 'right' ? { order: 1 } : undefined} />}
                        <TabLabel>{tab.name}</TabLabel>
                    </Tab>
                ))}
            </TabsContent>
        </TabsContainer>
    )
}
