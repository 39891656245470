import { type FieldValue, SelectedMode } from '@lighthouse/core'
import { getCascadeOption, getMainTableRecordId } from '@lighthouse/shared'
import { nanoid } from '@lighthouse/tools'
import { atom } from 'jotai'

import * as srv from '@/services'

import { currentAppIdAtom, currentEnvIdAtom } from '../application/state'
import { addRecordAtom, deleteRecordAtom, setRecordAtom, setViewRecordAtom } from '../dataSource/action'
import { dataSourceAtomFamily, recordPoolAtom } from '../dataSource/state'
import type { CreateViewRecordInDataDrawerAtomPayload } from '../dataSource/types'
import { blockUpdateListAtom } from '../page/state'
import { filterBlockOptionsAtom } from './state'
import type {
    FetchChartBlockPayload,
    FetchViewRecordsAtomPayload,
    HandleFetchFilterCascadeOptionsPayload,
    HandleFetchFilterOptionsPayload,
    updateViewSearchAtomPayload
} from './types'




/** 获取视图数据 */
export const fetchViewRecordsAtom = atom(null, async (get, set, payload: FetchViewRecordsAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const envId = get(currentEnvIdAtom)
    set(blockUpdateListAtom, [])
    const { viewId, scope, isSyncComponent, pagination, search, params, pageId, disableFetchCount, abort } = payload
    const viewData = await srv.getViewByBlock({ appId, envId, viewId, isSyncComponent, pagination, params, search, abort })
    if (!viewData) {
        return
    }
    const { records } = viewData

    set(addRecordAtom, { records })
    const recordIds = records.map(record => record.id)

    if (!disableFetchCount) {
        const rowTotal = await srv.getViewCount(payload)
        set(setViewRecordAtom, {
            identifier: { viewId, scope }, data: draft => {
                draft.recordIds = recordIds
                draft.currentPage = pagination.currentPage ?? 1
                draft.search = search
                draft.rowTotal = rowTotal
            }
        })
        return viewData
    }

    set(setViewRecordAtom, {
        identifier: { viewId, scope }, data: draft => {
            draft.recordIds = recordIds
            draft.currentPage = pagination.currentPage ?? 1
            draft.search = search
        }
    })

    return viewData
})

/** 加载视图数据 */
export const loadViewRecordsAtom = atom(null, async (get, set, payload: FetchViewRecordsAtomPayload) => {
    const appId = get(currentAppIdAtom)
    const envId = get(currentEnvIdAtom)
    const { scope, viewId, isSyncComponent, pagination, search, params, pageId, abort } = payload
    const viewData = await srv.getViewByBlock({ appId, envId, viewId, isSyncComponent, pagination, params, search, abort })
    if (!viewData) {
        return []
    }
    const { records } = viewData
    set(addRecordAtom, { records })
    const recordIds = records.map(record => record.id)
    set(setViewRecordAtom, {
        identifier: { viewId, scope }, data: draft => {
            recordIds.forEach(id => {
                draft.recordIds.push(id)
            })
            draft.currentPage = pagination.currentPage ?? 1
            draft.search = search
        }
    })
    return records
})

export const updateViewSearchAtom = atom(null, (_, set, payload: updateViewSearchAtomPayload) => {
    const { viewId, scope, search } = payload
    set(setViewRecordAtom, {
        identifier: { viewId, scope }, data: draft => {
            draft.search = search
        }
    })
})

/** 获取图表数据 */
export const fetchChartBlockAtom = atom(null, async (_, set, payload: FetchChartBlockPayload) => {
    set(blockUpdateListAtom, [])
    const data = await srv.getDsGraphView(payload)
    return data.chart
})

// 获取文本 成员筛选器 options
export const handleFetchFilterOptionsAtom = atom(null, async (_, set, payload: HandleFetchFilterOptionsPayload) => {
    const data = await srv.getRelativeOptions(payload)
    set(filterBlockOptionsAtom, draft => {
        draft[`${payload.blockId}-${payload.filterItemId}`] = data
    })
    return data
})

// 获取级联筛选器 options
export const handleFetchFilterCascadeOptionsAtom = atom(null, async (get, set, payload: HandleFetchFilterCascadeOptionsPayload) => {
    const appId = get(currentAppIdAtom)
    const envId = get(currentEnvIdAtom)
    const { dsId, sortFieldPointer = '', fieldPointer, parentFieldPointer, blockId, filterItemId, pageSize, showFieldPointer } = payload
    const res = await srv.getDs({
        appId,
        envId,
        dsId,
        pagination: { currentPage: 1, pageSize },
        sorts: [{ id: 'cascadeSortId', order: 'ASC', fieldId: sortFieldPointer }]
    })
    // const a = res?.records?.filter(record => record?.content?.['ID']?.value === '002')
    const usedRecords = res?.records?.filter(record => record?.content?.[fieldPointer]?.value)

    const options =
        usedRecords
            ?.filter(record => !record?.content?.[parentFieldPointer]?.value)
            .map(record => getCascadeOption({ parentFieldPointer, fieldPointer, showFieldPointer, record, records: usedRecords }))
            .filter(item => item.value) ?? []
    set(filterBlockOptionsAtom, draft => {
        draft[`${blockId}-${filterItemId}`] = options
    })
    return options
})

/** 数据查看器创建视图数据 */
export const createViewRecordInDataDrawerAtom = atom(null, async (get, set, payload: CreateViewRecordInDataDrawerAtomPayload) => {
    const { viewId, scope, recordId, appId, envId, dsId, content, beforeId } = payload
    const dataSourceAtom = dataSourceAtomFamily({ appId, envId, dsId })
    const newRecordId = recordId || nanoid(32)
    const dataSource = get(dataSourceAtom)
    if (!dataSource) {
        return
    }
    const { schema } = dataSource
    // createRecordAction(newRecordId, appId, dsId, beforeId, set)

    // const record = await srv.createRecord(appId, newRecordId, dsId, beforeId)
    const rContent: Record<string, FieldValue> = Object.fromEntries(
        Object.entries(schema).map(([fieldId, config]) => {
            return [
                fieldId,
                {
                    value: ''
                }
            ]
        })
    )
    const newRecord = { content: rContent, appId, envId, dsId, id: newRecordId }
    set(addRecordAtom, {
        records: [newRecord]
    })
    set(setRecordAtom, {
        envId,
        recordId: newRecordId,
        dsId,
        record: newRecord
    })
    set(setViewRecordAtom, {
        identifier: { viewId, scope }, data: draft => {
            draft.recordIds.unshift(newRecordId)
            if (draft.rowTotal) {
                draft.rowTotal += 1
            } else {
                draft.rowTotal = 1
            }
        }
    })
    const record = await srv.createRecord({ appId, envId, id: newRecordId, dsId, content, beforeId })
    if (!record) {
        set(deleteRecordAtom, {
            envId,
            dsId,
            mode: SelectedMode.CURRENT_PAGE,
            recordIds: [newRecordId]
        })
        set(setViewRecordAtom, {
            identifier: {viewId, scope}, data: draft => {
                draft.recordIds.unshift(newRecordId)
                if (draft.rowTotal || draft.rowTotal === 0) {
                    draft.rowTotal -= 1
                }
            }
        })
        return
    }
    set(recordPoolAtom, draft => {
        const recordsIndex = draft.reduce<number[]>((prev, cur, index) => {
            if (cur.appId === appId && cur.envId === envId && cur.dsId === dsId && getMainTableRecordId(cur.id) === record.id) {
                prev.push(index)
            }
            return prev
        }, [])
        if (recordsIndex.length === 0) {
            return
        }
        recordsIndex.forEach(i => {
            Object.keys(record.content).forEach(k => {
                draft[i].content[k] = record.content[k]
            })
        })
    })
    return newRecordId
})
