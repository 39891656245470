
import { CollectEventWriteType } from '@lighthouse/core'

import type { EventValidator, RecordValidator, SubProcessValidator } from '../types'
// update record action
const updateRecordActionValidator: EventValidator<RecordValidator> = {
    writeType: CollectEventWriteType.RECORD,
    validators: [
        // 记录
        event => {
            return !!event.record && !!event.record.dataSourceId && event.record.fields && event.record.fields.length > 0
        }
    ]
}

const subProcessActionValidator: EventValidator<SubProcessValidator> = {
    writeType: CollectEventWriteType.WORKFLOW,
    validators: [
        event => {
            return !!event.workflow?.subProcessId
        }
    ]
}

export const eventValidators = [
    updateRecordActionValidator,
    subProcessActionValidator
]
