import { Empty } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { type QrBarcodeBlockAbstract, QrBarcodeEnum } from '@lighthouse/core'
import { BarcodeBox, QRCodeBox } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'

const emptyApplicationImg = getAssetUrl('empty', 'no_content.svg')
interface QrBarcodeBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: QrBarcodeBlockAbstract
    code: string
}

const SCxEmptyImg = styled.img`
    max-width: 140px;
    width: 100%;
`
const SCxContainer = styled(AnimationDecorators)`
    width: 100%;
`

const QrBarcodeBlock: React.FC<QrBarcodeBlockProps> = ({ blockData, code, ...rest }) => {
    const { id, config } = blockData
    const { codeType, barWidth, breakPoint } = config

    const contentEle = useMemo(() => {
        if (!code) {
            return (
                <Empty
                    style={{ margin: '32px 0' }}
                    icon={<SCxEmptyImg src={emptyApplicationImg} alt="" />}
                    description="请在「右侧栏>配置」中填入条码内容"
                />
            )
        }
        if (codeType === QrBarcodeEnum.BARCODE) {
            return <BarcodeBox code={code} barWidth={barWidth} height={80} {...rest} />
        }
        return <QRCodeBox code={code} {...rest} />
    }, [code, codeType, rest, barWidth])

    return (
        <SCxContainer blockId={id} animation={breakPoint?.animation}>
            {contentEle}
        </SCxContainer>
    )
}

export default QrBarcodeBlock
