import { Indicator, Popover } from '@byecode/ui'
import type { DataSourceAbstract, Field, TableColumns, ViewFieldProps } from '@lighthouse/core'
import { filter, length } from 'rambda'
import React, { useMemo, useState } from 'react'

import { useLanguageContext } from '../../contexts'
import { useAppThemeContext } from '../ApplicationContainer'
import type { InsertDirection } from '../Table/types'
import { ViewFieldDisplayConfigure } from '../ViewFieldDisplayConfigure'
import SettingButton from './SettingButton'
import * as SC from './styles'

export interface DisplayProps {
    primaryField: string
    columns?: ViewFieldProps[]
    dataSource?: DataSourceAbstract
    configurable?: boolean
    isMobile?: boolean
    label?: string | boolean
    popoverHeight?: number
    dataSourceList?: DataSourceAbstract[]
    disableIconColor?: boolean
    onChangeDisplay?: (data?: TableColumns) => void
    onCreateField?: (data: Field, sourceId: string, direction: InsertDirection) => Promise<boolean>
    onUpdateField?: (fieldId: string, data: Field) => Promise<boolean>
    onDeleteField?: (field: Field) => Promise<boolean>
}

export const Display: React.FC<DisplayProps> = ({
    primaryField,
    columns = [],
    dataSource,
    configurable,
    isMobile,
    label: propLabel,
    dataSourceList,
    disableIconColor,
    onChangeDisplay,
    onCreateField,
    onUpdateField,
    onDeleteField
}) => {
    const { convertTextByLanguage } = useLanguageContext()
    const label = propLabel ?? convertTextByLanguage('field')
    const [open, setOpen] = useState(false)
    const theme = useAppThemeContext()

    const displayText = useMemo(() => length(filter(({ visible }) => !visible, columns)), [columns])

    const isActive = useMemo(() => displayText > 0 && displayText < columns.length, [columns.length, displayText])

    const settingBoxContent = useMemo(() => {
        const btn = <SettingButton isActive={isActive} color={theme.mainColor} icon="EyeOutline" size={isMobile ? 20 : 16} />
        if (!isMobile) {
            if (label) {
                return (
                    <>
                        {btn}
                        <Indicator
                            disabled={!isActive}
                            color={theme.mainColor}
                            label={String(displayText)}
                            size={16}
                            radius={16}
                            withBorder
                        >
                            <SC.SettingText>{label}</SC.SettingText>
                        </Indicator>
                    </>
                )
            }
            return (
                <Indicator disabled={!isActive} color={theme.mainColor} label={String(displayText)} size={16} radius={16} withBorder>
                    {btn}
                </Indicator>
            )
        }
        return btn
    }, [displayText, isActive, isMobile, label, theme.mainColor])

    return (
        <Popover opened={open} onChange={setOpen} position="bottom-start" withinPortal width={224}>
            <Popover.Target>
                <SC.SettingBox isOpen={open} isActive={isActive} mainColor={theme.mainColor} tintMainColor={theme.tintMainColor}>
                    {settingBoxContent}
                </SC.SettingBox>
            </Popover.Target>
            <Popover.Dropdown style={{ padding: 0 }}>
                <ViewFieldDisplayConfigure
                    columns={columns}
                    dataSource={dataSource}
                    color={theme.mainColor}
                    configurable={configurable}
                    dataSourceList={dataSourceList}
                    disabledShowInnerType
                    disableIconColor={disableIconColor}
                    onChange={onChangeDisplay}
                    onUpdateField={onUpdateField}
                    onCreateField={onCreateField}
                    onDeleteField={onDeleteField}
                />
            </Popover.Dropdown>
        </Popover>
    )
}
