import { createStyles } from '../../theme//createStyles'
import { css } from '../../theme/stitches.config'
import type { ByecodeSize } from '../../types'

export type SwitchStylesParams = {
    size: ByecodeSize
    disabled?: boolean
    checked?: boolean
    color?: string
}

const trackSizeStyles = {
    xs: {
        width: 20,
        height: 12
    },
    sm: {
        width: 28,
        height: 16
    },
    md: {
        width: 36,
        height: 20
    },
    lg: {
        width: 48,
        height: 28
    }
}

const thumbSizeStyles: Record<ByecodeSize, { width: number }> = {
    xs: {
        width: 8
    },
    sm: {
        width: 12
    },
    md: {
        width: 16
    },
    lg: {
        width: 24
    }
}



export const useStyles = createStyles(({ size, disabled, checked, color }: SwitchStylesParams) => ({
    wrapper: css({
        position: 'relative'
    }),

    input: css({
        width: 0,
        height: 0,
        overflow: 'hidden',
        position: 'absolute',
        visibility: 'hidden'
    }),

    track: css({
        display: 'flex',
        padding: 2,
        borderRadius: '36px',
        transition: 'all 0.2s ease-in-out',
        cursor: disabled ? 'not-allowed' : 'pointer',
        opacity: disabled ? 0.5 : 1,
        background: checked ? color ?? '$colorPrimary' : '$colorGray300',
        ...trackSizeStyles[size]
    }),

    thumb: css({
        borderRadius: '50%',
        backgroundColor: '#ffffff',
        transition: 'all 0.2s ease-in-out',
        transform: checked ? `translateX(calc(100%))` : 'translateX(0px)',
        ...thumbSizeStyles[size]
    })
}))
