
// 断点

import type { ApplicationPreviewEnum } from "@lighthouse/shared"
import React, { createContext, useContext, useMemo } from "react"

export interface BreakPointContextValue {
    onSyncOne?: (name: string, previewType: ApplicationPreviewEnum) => void
    onSyncAll?: (previewType: ApplicationPreviewEnum) => void
}

export const BreakPointContext = createContext<BreakPointContextValue>({})

export interface BreakPointProviderProps extends BreakPointContextValue {
    children: React.ReactNode
}

export const BreakPointProvider: React.FC<BreakPointProviderProps> = ({ children, onSyncOne, onSyncAll }) => {
    const ctxValue = useMemo(() => ({ onSyncOne, onSyncAll }), [onSyncAll, onSyncOne])
    return <BreakPointContext.Provider value={ctxValue}>{children}</BreakPointContext.Provider>
}

export const useBreakPointContext = () => useContext(BreakPointContext)

BreakPointContext.displayName = 'BreakPointContext'
