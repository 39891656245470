import type { BasePopoverProps } from '@byecode/ui'
import { Button, Image, Popover, Text } from '@byecode/ui'
import { TagIcon } from '@lighthouse/shared'
import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { tooltipDocumentMap } from './constants'
import type { TooltipDocumentType } from './types'

interface PopoverQuestionProps extends Pick<BasePopoverProps, 'position' | 'arrowOffset'> {
    type: TooltipDocumentType
    mainAxis?: number
    renderTarget: (iconEle: React.ReactNode, ref: React.RefObject<HTMLDivElement>) => React.ReactNode
}

const SCxDropDown = styled.div`
    width: 100%;
    padding: 12px;
    color: var(---color-white);
    display: flex;
    flex-direction: column;
    gap: 8px;
`

let timer: NodeJS.Timeout

export const PopoverQuestion: React.FunctionComponent<PopoverQuestionProps> = ({
    type,
    position = 'left-start',
    mainAxis,
    arrowOffset = { top: 12 },
    renderTarget
}) => {
    const data = tooltipDocumentMap[type]
    const ref = useRef(null)
    const [opened, setOpened] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)
    const isEnterDropDownRef = useRef(false)

    const popoverMainAxis = useMemo(() => {
        if (mainAxis) {
            return mainAxis
        }
        return 32
    }, [mainAxis])

    return (
        <>
            <Popover
                trigger="hover"
                arrowColor="var(--color-black)"
                withArrow
                arrowOffset={arrowOffset}
                opened={opened}
                withinPortal
                position={position}
                width={206}
                onChange={setOpened}
                offsetOptions={{
                    mainAxis: popoverMainAxis
                }}
            >
                <Popover.AnchorEl anchorEl={containerRef.current} />
                {renderTarget(
                    <TagIcon
                        iconSize={16}
                        size={16}
                        icon="Question"
                        hoverColor="var(--color-black)"
                        iconColor="var(--color-gray-400)"
                        enableHover
                        onMouseEnter={() => {
                            clearTimeout(timer)
                            setOpened(true)
                        }}
                        onPointerLeave={() => {
                            timer = setTimeout(() => {
                                if (!isEnterDropDownRef.current) {
                                    setOpened(false)
                                }
                            }, 300)
                        }}
                    />,
                    containerRef
                )}
                <Popover.Dropdown
                    compact
                    styles={{
                        dropdown: {
                            backgroundColor: 'var(--color-black)',
                            borderStyle: 'none'
                        }
                    }}
                >
                    <SCxDropDown
                        onPointerEnter={() => {
                            isEnterDropDownRef.current = true
                        }}
                        onPointerLeave={e => {
                            setOpened(false)
                            isEnterDropDownRef.current = false
                        }}
                    >
                        {data.file && <Image src={data.file} radius={4} height={98} />}
                        <Text size={14} whiteSpace="pre-wrap" lineHeight="22px" color="var(--color-white)">
                            {data.title}
                        </Text>
                        {data.link && (
                            <Button
                                style={{ background: '#FFFFFF38', color: 'var(--color-white)', borderColor: 'transparent' }}
                                block
                                onClick={() => window.open(data.link, '_target')}
                            >
                                {data.linkText}
                            </Button>
                        )}
                    </SCxDropDown>
                </Popover.Dropdown>
            </Popover>
        </>
    )
}
