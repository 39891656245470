import 'xgplayer/dist/index.min.css'

import type { VideoBlockAbstract } from '@lighthouse/core'
import {
    getImageFullUrlInApplication,
    PAGE_SCROLL_CONTAINER_HOST,
    useApplicationContext,
    VIDEO_RATIO_OPTIONS,
    VideoPlayer} from '@lighthouse/shared'
import { find } from 'rambda'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'
import { useEnterVisibleArea } from '../../hooks/useEnterVisibleArea'

interface VideoBlockProps {
    blockData: VideoBlockAbstract
    value: string
    cover?: string
    disabled?: boolean
}

const SCxContainer = styled(AnimationDecorators)<Pick<React.CSSProperties, 'borderRadius'>>`
    /* padding: var(--block-padding); */
    height: auto;
    width: 100%;
    overflow: hidden;
    > div {
        border-radius: ${({ borderRadius }) => borderRadius};
    }
`

const VideoBlock: React.FunctionComponent<VideoBlockProps> = ({ blockData, value, cover }) => {
    const { config, id } = blockData
    const { isMute, isLoop, isUsedVideoControl, radius, ratio, fitType, isAuto,breakPoint } = config

    const { appId } = useApplicationContext()

    const { ref, isIn } = useEnterVisibleArea(PAGE_SCROLL_CONTAINER_HOST)

    const alginItem = useMemo(() => find(item => item.value === ratio, VIDEO_RATIO_OPTIONS), [ratio])

    return (
        <SCxContainer
            ref={ref}
            borderRadius={`${radius}px`}
            blockId={id}
            animation={breakPoint.animation}
            data-stop-action-propagation={isUsedVideoControl ? true : undefined}
        >
            <VideoPlayer
                ratio={alginItem?.label}
                source={value}
                isMute={isMute}
                isLoop={isLoop}
                cover={getImageFullUrlInApplication(appId, cover)}
                isController={isUsedVideoControl}
                fitType={fitType}
                autoplay={isAuto && isIn}
            />
        </SCxContainer>
    )
}

export default VideoBlock
