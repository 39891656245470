import type {
    AppUser,
    BlockAbstract,
    BlockRuntimeState,
    ContainerBlockAbstract,
    DataSourceAbstract,
    FieldADTValue,
    FieldBlockWithDsId,
    FilterFormType,
    FormContainerBlockAbstract,
    Sorter
} from '@lighthouse/core'
import {
    type Field,
    type FieldCellValue,
    type FieldInputADTValue,
    type FieldInputConfigProtocol,
    type FieldInputType,
    type FieldType,
    type NumberValue,
    type RecordLikeProtocol,
    AppUserStatus,
    BlockType,
    VariableType
} from '@lighthouse/core'
import { isValid, lightFormat, parse } from 'date-fns'
import { filter, map } from 'rambda'

import { format2LunarDate } from '../components/FieldInput/BaseField/DateField/help'
import { isEmptyRichTextValue } from '../components/RichText/helper'
import { getSystemVariableValue } from '../components/Variable'
import { CURRENT_USER, defaultFormat, numberReg, silentInputTypes, USER_DATASOURCE } from '../constants'
import type { AppDepartment, AppRole } from '../types'
import { findBlockById, findParentBlockByType, findParentSyncContainerBlock, getCurrentBlockChildren, isContainerBlock } from './block'
import { fieldConvertValue, isDateValue, isIdsValue, isNumberValue, isRichTextValue, isTextValue } from './helper'

type ModuleBlockNode = {
    id: string
    children?: ModuleBlockNode[]
}

export const isEmptyFieldInputValue = function (fieldInputValue: FieldInputADTValue, fieldType?: FieldType): boolean {
    const { type, value } = fieldInputValue ?? {}
    switch (type) {
        case 'number':
        case 'text':
        case 'url':
        case 'email':
        case 'slider':
        case 'date': {
            return value === ''
        }
        case 'phoneNumber': {
            if (value) {
                const reg = /^\(+\d*\)$/u
                return reg.test(value)
            }
            return value === ''
        }
        case 'relativeSelect': {
            return (value ?? '').toString()?.length === 0
        }
        case 'cascade': {
            return (value ?? '').toString()?.length === 0
        }
        // case 'person':
        // case 'select':
        case 'person':
        case 'file': {
            return value?.length === 0
        }
        case 'notes': {
            return isEmptyRichTextValue(value)
        }
        case 'checkbox': {
            return !value
        }
        default: {
            return true
        }
    }
}

export const getEmptyFieldInputValue = function (inputType: FieldInputType, fieldType?: FieldType): FieldCellValue {
    if (!fieldType) {
        return ''
    }
    switch (inputType) {
        case 'text':
        case 'url':
        case 'phoneNumber':
        case 'email':
        case 'number':
        case 'slider':
        case 'cascade': {
            return fieldType === 'department' || fieldType === 'parentDepartment' || fieldType === 'role' ? [] : ''
        }
        case 'date': {
            return ''
        }
        case 'relativeSelect': {
            return fieldType === 'select' || fieldType === 'singleSelect' || fieldType === 'role' ? [] : ''
        }
        // case 'select':
        case 'person':
        case 'file': {
            return []
        }
        case 'notes': {
            return ''
        }
        case 'checkbox': {
            return false
        }
        default: {
            return ''
        }
    }
}

export const getVariableDefaultValue = async function (params: {
    record?: RecordLikeProtocol
    prevRecord?: RecordLikeProtocol
    userRecord?: RecordLikeProtocol
    config: FieldInputConfigProtocol
    dataSourceList?: DataSourceAbstract[]
    currentPageUrl?: string
    fieldType?: FieldType
    onFetchRecord?: (params: {
        dsId: string
        filter?: FilterFormType
        sorts?: Sorter[]
        currentRecordId?: string
        parentRecordId?: string
    }) => Promise<RecordLikeProtocol | undefined>
}): Promise<[FieldCellValue | undefined, Field | undefined]> {
    const { record, config, dataSourceList, fieldType, userRecord, prevRecord, currentPageUrl, onFetchRecord } = params
    const { initialValue: defaultValue, inputType } = config
    if (!defaultValue) {
        return [undefined, undefined]
    }
    switch (defaultValue.type) {
        case VariableType.VALUE: {
            return [defaultValue.valueVariable?.value, undefined]
        }
        case VariableType.VARIABLE: {
            const fieldId = defaultValue?.fieldVariable?.fieldId || ''
            const dsId = defaultValue?.fieldVariable?.dsId || ''
            const ds = dataSourceList?.find(item => item.id === dsId)
            if (!ds) {
                return [undefined, undefined]
            }
            const fieldValue = record?.content?.[fieldId]
            return [fieldValue?.value, ds?.schema?.[fieldId]]
        }
        case VariableType.USER: {
            const fieldId = defaultValue.userVariable?.fieldId ?? ''
            const fieldValue = userRecord?.content?.[fieldId]
            if (inputType === 'relativeSelect' && fieldType !== 'select') {
                // const isTransform =
                //     inputType === 'relativeSelect' &&
                //     config.relativeSelect?.relativeFieldPointer === fieldId &&
                //     config.relativeSelect.relativePointer === USER_DATASOURCE
                // if (isTransform) {
                return [fieldValue?.value, undefined]
                // }
                // return [`${CURRENT_USER.userId}-${fieldId}`, undefined]
            }
            return [fieldValue?.value, undefined]
        }
        case VariableType.FIELD_ID: {
            const fieldId = defaultValue.fieldIdVariable?.fieldId ?? ''
            if (inputType === 'relativeSelect' && fieldType !== 'select') {
                return [`${CURRENT_USER.userId}-${fieldId}`, undefined]
            }
            const fieldValue = userRecord?.content?.[fieldId]
            return [fieldValue?.value, undefined]
        }
        case VariableType.SYSTEM: {
            return [getSystemVariableValue(defaultValue), undefined]
        }

        case VariableType.PAGE: {
            const fieldId = defaultValue?.pageVariable?.fieldId || ''
            const dsId = defaultValue?.pageVariable?.dsId || ''
            const ds = dataSourceList?.find(item => item.id === dsId)
            if (!ds) {
                return [undefined, undefined]
            }
            const usedRecord = defaultValue?.pageVariable?.type === 'page' ? record : prevRecord
            const fieldValue = usedRecord?.content?.[fieldId]
            return [fieldValue?.value, ds?.schema?.[fieldId]]
        }
        case VariableType.PAGE_LINK: {
            return [currentPageUrl, undefined]
        }
        case VariableType.SELECT_DATASOURCE: {
            const { dsId = '', filter, fieldId = '', sorts } = defaultValue?.selectDataSourceVariable ?? {}
            const ds = dataSourceList?.find(item => item.id === dsId)
            if (!ds) {
                return [undefined, undefined]
            }
            const resultRecord = await onFetchRecord?.({ dsId, filter, sorts, currentRecordId: record?.id, parentRecordId: prevRecord?.id })
            const fieldValue = resultRecord?.content?.[fieldId]
            return [fieldValue?.value, ds?.schema?.[fieldId]]
        }
        default: {
            return [undefined, undefined]
        }
    }
}

export const getFieldInputInitialValue = async (params: {
    config: FieldInputConfigProtocol
    fieldType?: FieldType
    record?: RecordLikeProtocol
    userRecord?: RecordLikeProtocol
    currentUser?: AppUser
    prevRecord?: RecordLikeProtocol
    currentPageUrl?: string
    dataSourceList?: DataSourceAbstract[]
    personOptions?: AppUser[]
    roleOptions?: AppRole[]
    departmentOptions?: AppDepartment[]
    onFetchRecord?: (params: {
        dsId: string
        filter?: FilterFormType
        sorts?: Sorter[]
        currentRecordId?: string
        parentRecordId?: string
    }) => Promise<RecordLikeProtocol | undefined>
}): Promise<FieldCellValue> => {
    const {
        config,
        fieldType,
        record,
        currentUser,
        dataSourceList,
        prevRecord,
        userRecord,
        personOptions,
        roleOptions,
        departmentOptions,
        currentPageUrl,
        onFetchRecord
    } = params
    const { initialValue, inputType } = config
    // 如果是普通默认值，则返回默认值
    if (initialValue) {
        const [cellValue, field] = await getVariableDefaultValue({
            config,
            record,
            userRecord,
            prevRecord,
            fieldType,
            currentPageUrl,
            dataSourceList,
            onFetchRecord
        })

        switch (inputType) {
            case 'text':
            case 'url':
            case 'phoneNumber':
            case 'cascade': {
                if (fieldType === 'department' || fieldType === 'parentDepartment') {
                    return isIdsValue(cellValue) ? cellValue : []
                }
                return isTextValue(cellValue) ? cellValue : ''
            }
            case 'email': {
                const convertValue = field
                    ? fieldConvertValue({ ...field, value: cellValue } as FieldADTValue, { personOptions, roleOptions, departmentOptions })
                    : cellValue
                return isNumberValue(convertValue) ? convertValue : ''
            }
            case 'slider':
            case 'number': {
                const numberValue = isNumberValue(cellValue) ? cellValue : ''
                return numberReg.test(numberValue.toString()) ? numberValue : ''
            }
            case 'relativeSelect': {
                if (fieldType === 'select' || fieldType === 'singleSelect' || fieldType === 'role') {
                    return isIdsValue(cellValue) ? cellValue : []
                }
                return isTextValue(cellValue) ? cellValue : ''
            }
            case 'date': {
                if (fieldType === 'text') {
                    if (!isDateValue(cellValue)) {
                        return ''
                    }

                    const date = new Date(cellValue)
                    if (!isValid(date)) {
                        return ''
                    }
                    const format = config.date?.dateFormat || defaultFormat
                    const splitResult = format.split('lunar-')
                    const isLunar = splitResult.length > 1
                    console.log("🚀 ~ isLunar:", isLunar, cellValue)
                    return isLunar ? format2LunarDate(date, format) : lightFormat(date, format)
                }
                return isDateValue(cellValue) ? cellValue : ''
            }
            case 'file': {
                return []
            }
            case 'person': {
                const personValue = isIdsValue(cellValue) ? cellValue : []
                const noIsDepartPerson = personOptions?.filter(person => person.status?.[0] !== AppUserStatus.DEPART)
                const drpRepeatUsers = personValue.includes(currentUser?.userId ?? '')
                    ? personValue.filter(userId => userId !== CURRENT_USER.userId)
                    : personValue
                const finallyUsers = currentUser
                    ? map(userId => (userId === CURRENT_USER.userId ? currentUser.userId : userId), drpRepeatUsers)
                    : filter(userId => userId !== CURRENT_USER.userId, drpRepeatUsers)
                return finallyUsers.filter(userId => noIsDepartPerson?.some(user => user.userId === userId))
            }
            case 'notes': {
                return isRichTextValue(cellValue) ? cellValue : ''
            }
            case 'checkbox': {
                return Boolean(cellValue)
            }
            default: {
                return ''
            }
        }
    }

    return getEmptyFieldInputValue(inputType, fieldType)
}

export const isSilentInputType = function (value?: FieldInputADTValue) {
    return value && silentInputTypes.has(value.type)
}

export function isFieldNumberValue(data?: FieldCellValue): data is NumberValue {
    if (!data && data !== 0) {
        return false
    }
    return typeof data === 'number' || typeof data === 'string'
}

export function getFieldInputTypeByFieldType(fieldType: FieldType): FieldInputType {
    switch (fieldType) {
        case 'number':
        case 'text':
        case 'url':
        case 'phoneNumber':
        case 'notes':
        case 'date':
        case 'checkbox':
        case 'file':
        case 'person':
        case 'email': {
            return fieldType
        }
        case 'role':
        case 'select': {
            return 'relativeSelect'
        }
        case 'department': {
            return 'cascade'
        }

        default: {
            return 'text'
        }
    }
}

export const getModuleBlockList: (nodes: ModuleBlockNode[]) => string[] = nodes => {
    return (
        nodes?.flatMap(node => {
            if (node.children) {
                return getModuleBlockList(node.children)
            }
            return node.id
        }) || []
    )
}

type GetFieldBlockWithDsIdOptions = {
    blocks: BlockAbstract[]
    pageDsId?: string
    syncComponents: ContainerBlockAbstract[]
    blockRuntimeState?: BlockRuntimeState
}
/**
 * 当前页面fieldBlock
 * @date 2023/9/24 - 18:39:43
 */
export const getFieldBlockWithDsId = ({ blocks, pageDsId, syncComponents, blockRuntimeState }: GetFieldBlockWithDsIdOptions) => {
    function getChildNode(tree: BlockAbstract[], scope?: string): FieldBlockWithDsId[] {
        const list: FieldBlockWithDsId[] = []
        for (const block of tree) {
            if (block.type === BlockType.field) {
                const formContainer = findParentBlockByType<FormContainerBlockAbstract>({
                    id: block.id,
                    blocks,
                    syncComponents,
                    blockRuntimeState,
                    filter: block => block.type === BlockType.formContainer
                })
                list.push({
                    ...block,
                    dsId: formContainer?.config?.pointer ?? pageDsId,
                    parentFormContainerId: formContainer?.id,
                    scope
                })
                continue
            }

            const children = getCurrentBlockChildren(block, {
                blockRuntimeState,
                uniqueContainerId: block.synchronousId ? block.id : block.isLeafSynchronous ? `${scope}@${block.id}` : block.id
            })
            const syncComponent = isContainerBlock(block) ? syncComponents.find(v => v.id === block.synchronousId) : undefined
            if (syncComponent) {
                const syncComponentChildren = getCurrentBlockChildren({ ...syncComponent, id: block.id }, { blockRuntimeState })
                if (syncComponentChildren) {
                    list.push(...getChildNode(syncComponentChildren, block.id))
                }
            }
            if (children) {
                list.push(...getChildNode(children, scope))
            }
        }
        return list
    }
    return getChildNode(blocks)

    // return list
}
