import { Flex, Text } from '@byecode/ui'
import { TagIcon } from '@lighthouse/shared';
import React from 'react';

interface AnimationSettingHeaderProps {
    title?: string
    style?: React.CSSProperties
    onClose?: () => void
    onBack?: () =>void
}

export const AnimationSettingHeader: React.FunctionComponent<AnimationSettingHeaderProps> = ({ title, style, onClose, onBack }) => {
    return (
        <Flex alignItems="center" justifyContent="space-between" style={{ height: 40, lineHeight: '40px', marginBottom: 8, ...style }}>
            {onBack && (
                <TagIcon
                    icon="ArrowLeftSmall"
                    size={28}
                    radius={100}
                    iconColor="var(--color-gray-400)"
                    background="var(--color-gray-100)"
                    onClick={onBack}
                />
            )}
            <Text weight={500} size={16}>
                {title}
            </Text>
            <TagIcon
                icon="Close"
                size={28}
                radius={100}
                onClick={onClose}
                iconColor="var(--color-gray-400)"
                background="var(--color-gray-100)"
            />
        </Flex>
    )
}

