import { Toast } from '@byecode/ui';
import type {
    ApplicationSettingThemeColor,
    BackgroundProtocol,
    ButtonAction,
    Conditions,
    DataSourceAbstract,
    EdgeValue,
    EVENT_VARIABLE_TYPE,
    Field,
    FieldCellValue,
    FilterCommonCondition,
    FilterFormType,
    NodeFieldContent,
    RecordLikeProtocol,
    SystemVariable,
    TypeInstanceMap,
    VariableADTvalue,
    VariableFieldADTValue
} from '@lighthouse/core';
import { EVENT_VARIABLE_VALUE, VariableType, WechatPayType } from '@lighthouse/core';
import { getBrowser, isWechatBrowser, nanoid } from '@lighthouse/tools';
import { lightFormat, startOfToday, startOfTomorrow, startOfYesterday } from 'date-fns';
import type { AnyObject } from 'immer/dist/internal';
import JSZip from 'jszip';
import { find } from 'rambda';
import { type CSSProperties } from 'react';

import { type PreviewFile, type TiptapEditorJSONContent, eventTypeName } from '../../components';
import { addresseeFieldType, defaultDateFormat, resourceField, SEND_EMAIL_WIDTH } from '../../constants';
import type {
    ActionFlowNode,
    ActiveDingTalkRobotActionPayload,
    ConditionFlowNode,
    DownloadFileActionConfig,
    EmailParticipant,
    FindSingleRecordActionConfig,
    FlowEdge,
    HandleSubProcessActionConfig,
    IClickActionConfig,
    SendEmailActionConfig,
    SendEmailBody,
    SendMsgDingTalkRobotConfig,
    UpdateRecordActionConfig
} from '../../types';
import {
    ApplicationPreviewEnum, EMAIL_EDIT_TYPE,
    PARAGRAPH_WIDTH_TYPE
} from '../../types';
import type { SettingRecordFields } from '../../types/record';
import { getBackgroundStyle, getBorderStyle, getPaddingStyle } from '../design';
import { getUpstreamRealDsId } from '../flow';
import { getDeviceType } from '../getApplicationPreviewType';
import { getFileNameByUrl, getFileTypeByUrl } from '../helper';
import { getImageFullUrlInApplication } from '../image';
import { resolveFilter } from './filterResolver';

export const waitFor = (ms: number) =>
    new Promise(resolve => {
        setTimeout(resolve, ms)
    })


type FormatPayload = {
    dateFormat?: boolean
    fileFormat?: boolean
}

interface NodesTopToBottomTree {
    node: string
    level: number
    levelIndex: number
    levelCount: number
    data?: FlowEdge
}

export type NodeInfo = {
    data: ActionFlowNode | ConditionFlowNode
    children: NodeInfo[]
}

export type TransformToValueVariablePayload = {
    variable: VariableADTvalue
    extraParams?: AnyObject
    disableResolvedPageData?: boolean
}

export type ResolveFilterItemParams = {
    condition: FilterCommonCondition
    extraParams?: AnyObject
    useInFilterRecordChecker?: boolean
    disableResolvedPageData?: boolean
}

export type ResolveFilterToValueParams = {
    where: 'OR' | 'AND'
    conditions: Conditions
    extraParams?: AnyObject
    useInFilterRecordChecker?: boolean
    disableResolvedPageData?: boolean
}

export type ResolveFilterParams = {
    filter?: FilterFormType
    extraParams?: AnyObject
    useInFilterRecordChecker?: boolean
    disableResolvedPageData?: boolean
}

export const getDefaultAction: () => ButtonAction = () => {
    const actionId = nanoid()
    return { id: actionId, type: 'none', trigger: 'click', data: { none: {} } }
}

const getFlowNodeTreeMap = (edges: FlowEdge[], nodes: (ActionFlowNode | ConditionFlowNode)[]) => {
    const edgedNodes = new Set<string>()
    const nodesWithNoIncomingEdges = new Set<string>()
    const nodesWithNoOutgoingEdges = new Set<string>()

    for (const edge of edges) {
        edgedNodes.add(edge.source)
        edgedNodes.add(edge.target)

        if (nodesWithNoIncomingEdges.has(edge.target)) {
            nodesWithNoIncomingEdges.delete(edge.target)
        } else {
            nodesWithNoOutgoingEdges.add(edge.target)
        }

        if (nodesWithNoOutgoingEdges.has(edge.source)) {
            nodesWithNoOutgoingEdges.delete(edge.source)
        } else {
            nodesWithNoIncomingEdges.add(edge.source)
        }
    }

    const rootNodeId = [...nodesWithNoIncomingEdges][0]

    const nodeTree = new Map<string, { children: string[]; data: FlowEdge }>()
    for (const node of edgedNodes) {
        nodeTree.set(node, {
            children: [],
            data: edges.find(e => e.source === node) as FlowEdge
        })
    }

    for (const edge of edges) {
        nodeTree.get(edge.source)?.children?.push(edge.target)
    }

    const nodeMaps = nodes.reduce<Record<string, ActionFlowNode | ConditionFlowNode>>((prev, cur) => {
        prev[cur.id] = cur
        return prev
    }, {})

    return { rootNodeId, nodeTree, nodeMaps }
}

export const getFlowByNodes = (edges: FlowEdge[], nodes: (ActionFlowNode | ConditionFlowNode)[]) => {
    const { rootNodeId, nodeTree, nodeMaps } = getFlowNodeTreeMap(edges, nodes)
    const nodesTopToBottom: NodesTopToBottomTree[] = []
    const queue = [
        {
            level: 0,
            levelIndex: 0,
            node: rootNodeId,
            levelCount: 1,
            data: nodeTree.get(rootNodeId)?.data
        }
    ]
    while (queue.length > 0) {
        const node = queue.shift()
        const treeNode = node && nodeTree.get(node.node)
        if (!node || !treeNode) {
            continue
        }
        nodesTopToBottom.push(node)
        const { children, data } = treeNode

        for (let index = 0; index < children.length; index++) {
            const child = children[index]
            queue.push({
                level: node.level + 1,
                levelIndex: index,
                levelCount: children.length,
                node: child,
                data
            })
        }
    }

    return nodesTopToBottom.map(item => nodeMaps[item.node]).slice(1, -1)
}

// get flow tree base CONDITION node
export const getFlowTreeByNodes = (edges: FlowEdge[], nodes: (ActionFlowNode | ConditionFlowNode)[]) => {
    const { rootNodeId, nodeTree, nodeMaps } = getFlowNodeTreeMap(edges, nodes)

    const assembling = (nodeId: string): NodeInfo => {
        const treeNode = nodeTree.get(nodeId)
        if (!treeNode?.children || treeNode?.children?.length === 0) {
            return {} as NodeInfo
        }

        const { children, data } = treeNode

        return {
            data: nodeMaps[data.source],
            children: children.map(childId => {
                return assembling(childId)
            })
        }
    }

    return assembling(rootNodeId)
}

const getSystemVariableValue = (variable: SystemVariable) => {
    if (!variable.systemVariable?.value) {
        return ''
    }
    const value = variable.systemVariable?.value

    switch (value) {
        case 'TOMORROW': {
            return startOfTomorrow().valueOf()
        }

        case 'TODAY': {
            return startOfToday().valueOf()
        }
        case 'YESTERDAY': {
            return startOfYesterday().valueOf()
        }
        case 'NOW': {
            return Date.now()
        }
        default: {
            return ''
        }
    }
}

const getValueByInnerType = (innerType: TypeInstanceMap, value: FieldCellValue | undefined) => {
    switch (innerType) {
        case 'TEXT': {
            return Array.isArray(value) ? value.join(',') : value
        }

        case 'NUMBER': {
            return typeof value === 'number' ? value : undefined
        }

        case 'ARRAY': {
            return Array.isArray(value) ? value : String(value).split(',')
        }

        case 'BOOL': {
            return value === undefined ? value : !!value
        }
        default: {
            return value
        }
    }
}



const transformValueByFormat = (appId: string | undefined, field: Field | undefined, value: FieldCellValue | undefined, format: FormatPayload | undefined) => {
    if (format?.dateFormat && field?.innerType === 'DATE') {
        const format = field.type === 'date' ? field.date?.format || defaultDateFormat : defaultDateFormat
        return typeof value === 'number' ? lightFormat(value, format) : value
    }
    if (appId && format?.fileFormat && field && resourceField.has(field.type) && Array.isArray(value)) {
        return value.map(item => {
            return typeof item === 'string' ? getImageFullUrlInApplication(appId, item) : item
        })
    }
    return value
}

export const generateAdtValue = (innerType: TypeInstanceMap, variableData: VariableADTvalue, extraParams?: AnyObject, format?: FormatPayload) => {
    // 从之前数据拿过来的，用于处理数据拍平等问题
    // 2024-01-12 填上游数据转换，出来数据是两层数组的坑
    let needFlat = false

    switch (variableData.type) {
        case VariableType.UPSTREAM: {
            if (!variableData.upstreamVariable) {
                return ''
            }
            const { nodeId = '', fieldId = '' } = variableData.upstreamVariable
            const record = extraParams?.[nodeId]?.record as RecordLikeProtocol
            if (!record || !record.content?.[fieldId]) {
                return ''
            }
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = find(item => item.id === record.dsId, dataSourceList)
            const field = dataSource?.schema[fieldId]

            const { content } = record
            const { value } = content[fieldId]

            const result = transformValueByFormat(extraParams?.appId, field, value, format)
            // //   解决上游数据是数组但被转为字符串的问题
            if (Array.isArray(value)) {
                needFlat = true
            }
            return needFlat ? { needFlat, value: result } : result
        }
        case VariableType.SYSTEM: {
            const systemValue = getSystemVariableValue(variableData)

            if (format?.dateFormat) {
                return typeof systemValue === 'number' ? lightFormat(systemValue, defaultDateFormat) : systemValue
            }
            return systemValue
        }

        case VariableType.VALUE: {
            const value = variableData.valueVariable?.value
            if (Array.isArray(value)) {
                needFlat = true
                if (variableData.valueVariable?.type === 'date' && format?.dateFormat) {
                    return value
                        .map(item => {
                            if (typeof item === 'number') {
                                return lightFormat(item, 'yyyy-MM-dd HH:mm:ss')
                            }
                            return item
                        })
                        .join(',')
                }
                const arr = value
                    .map(item => {
                        if (item === '{currentUserId}') {
                            return extraParams?.clickTriggerNodeParams.currentUserId
                        }
                        return item
                    })
                if (innerType === 'ARRAY') {
                    return { needFlat, value: arr }
                }

                return { needFlat, value: arr.join(',') }
            }


            if (variableData.valueVariable?.type === 'date' && format?.dateFormat) {
                return typeof value === 'number' ? lightFormat(value, defaultDateFormat) : value
            }

            if (value === '{currentUserId}') {
                return extraParams?.clickTriggerNodeParams.currentUserId
            }
            return value
        }

        case VariableType.PAGE: {
            const fieldId = variableData.pageVariable?.fieldId ?? ''
            const pageVariableType = variableData.pageVariable?.type
            let record: RecordLikeProtocol | null = null
            if (pageVariableType === 'page') {
                record = extraParams?.pageRecord as RecordLikeProtocol
            } else {
                record = extraParams?.clickTriggerNodeParams?.prevRecord as RecordLikeProtocol
            }

            if (!record || !record.content[fieldId]) {
                return ''
            }
            let { value } = record.content[fieldId]

            //   解决上游数据是数组但被转为字符串的问题
            // if (Array.isArray(value)) {
            //     needFlat = true
            // }
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = find(item => item.id === record?.dsId, dataSourceList)
            const field = dataSource?.schema[fieldId]
            value = transformValueByFormat(extraParams?.appId, field, value, format)

            const resolveValue = getValueByInnerType(innerType, value)

            if (innerType === 'ARRAY') {
                needFlat = true
                return { needFlat, value: resolveValue }
            }

            return resolveValue
            // return needFlat ? { needFlat, value } : value
        }

        case VariableType.VIEW: {
            const fieldId = variableData.viewVariable?.fieldId ?? ''
            const record = extraParams?.viewRecord as RecordLikeProtocol
            if (!record || !record.content[fieldId]) {
                return ''
            }
            let { value } = record.content[fieldId]
            //   解决上游数据是数组但被转为字符串的问题
            //   解决上游数据是数组但被转为字符串的问题
            // if (Array.isArray(value)) {
            //     needFlat = true
            // }
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = find(item => item.id === record.dsId, dataSourceList)
            const field = dataSource?.schema[fieldId]
            value = transformValueByFormat(extraParams?.appId, field, value, format)

            // return needFlat ? { needFlat, value } : value
            const resolveValue = getValueByInnerType(innerType, value)

            if (innerType === 'ARRAY') {
                needFlat = true
                return { needFlat, value: resolveValue }
            }

            return resolveValue
        }

        case VariableType.USER: {
            const fieldId = variableData.userVariable?.fieldId ?? ''
            const userRecord = extraParams?.userRecord?.record as RecordLikeProtocol
            if (!userRecord || !userRecord.content[fieldId]) {
                return ''
            }
            let { value } = userRecord.content[fieldId]
            if (Array.isArray(value)) {
                needFlat = true
            }
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = find(item => item.id === userRecord.dsId, dataSourceList)
            const field = dataSource?.schema[fieldId]
            value = transformValueByFormat(extraParams?.appId, field, value, format)


            // return needFlat ? { needFlat, value } : value
            const resolveValue = getValueByInnerType(innerType, value)

            if (innerType === 'ARRAY') {
                needFlat = true
                return { needFlat, value: resolveValue }
            }

            return resolveValue
        }

        case VariableType.FORM: {
            const fieldId = variableData.formVariable?.fieldId ?? ''
            const formRecord = extraParams?.formRecord as RecordLikeProtocol
            if (!formRecord || !formRecord.content[fieldId]) {
                return ''
            }
            let { value } = formRecord.content[fieldId]
            // if (Array.isArray(value)) {
            //     needFlat = true
            // }
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = find(item => item.id === formRecord.dsId, dataSourceList)
            const field = dataSource?.schema[fieldId]
            value = transformValueByFormat(extraParams?.appId, field, value, format)


            // return needFlat ? { needFlat, value } : value
            const resolveValue = getValueByInnerType(innerType, value)

            if (innerType === 'ARRAY') {
                needFlat = true
                return { needFlat, value: resolveValue }
            }

            return resolveValue
        }
        case VariableType.PAGE_LINK: {
            const value = variableData.pageLinkVariable?.value
            return value === 'CURRENT_PAGE' ? extraParams?.getCurrentPageLink?.() : ''
        }
        case VariableType.INPUT: {
            const pageStackFormState = extraParams?.pageStackFormState
            const { inputVariable } = variableData
            const { blockId = '' } = inputVariable ?? {}
            if (!pageStackFormState) {
                return ''
            }
            const formState = pageStackFormState?.[blockId]
            if (!formState) {
                return ''
            }
            const formStateValue = formState.value
            if (formState.fieldType === 'date' && format?.dateFormat) {
                return typeof formStateValue === 'number' ? lightFormat(formStateValue, defaultDateFormat) : formStateValue
            }

            if (innerType === 'ARRAY') {
                needFlat = true
                return { needFlat, value: formStateValue }
            }

            return formStateValue
        }
        case VariableType.EVENT: {
            const { eventVariable } = variableData
            // 'PAGE_NAME' = 'PAGE_NAME',
            // 'BLOCK_NAME' = 'BLOCK_NAME',
            // 'EVENT_TYPE' = 'EVENT_TYPE',
            // 'CLIENT_TYPE' = 'CLIENT_TYPE',
            // 'EVENT_NAME' = 'EVENT_NAME',
            if (eventVariable?.value === EVENT_VARIABLE_VALUE.PAGE_NAME) {
                return extraParams?.pageName || ''
            }
            if (eventVariable?.value === EVENT_VARIABLE_VALUE.BLOCK_NAME) {
                return extraParams?.blockName || ''
            }
            if (eventVariable?.value === EVENT_VARIABLE_VALUE.EVENT_TYPE) {
                const eventType = extraParams?.event?.eventType as EVENT_VARIABLE_TYPE | undefined
                return eventType ? eventTypeName?.[eventType] || '' : ''
            }
            if (eventVariable?.value === EVENT_VARIABLE_VALUE.CLIENT_TYPE) {
                const { isMobile } = getBrowser().parseUserAgent()
                return isMobile ? 'mobile' : 'desktop'
            }
            if (eventVariable?.value === EVENT_VARIABLE_VALUE.EVENT_NAME) {
                return extraParams?.event?.eventName || ''
            }
            return ''
        }
        default: {
            return ''
        }
    }
}


const generateSingleVariableValue = (innerType: TypeInstanceMap, c: TiptapEditorJSONContent, extraParams?: AnyObject, format?: FormatPayload) => {
    // const c = jsonContent.content?.[0]
    // 2024-01-12 填上游数据转换，出来数据是两层数组的坑
    let needFlat = false

    const variableValue = c
        ? c.content?.map(item => {
            if (item.type === 'text') {
                return item.text ?? ''
            }

            if (item.type === 'variable') {
                if (!item?.attrs?.value) {
                    return ''
                }
                const variableData = item.attrs.value as VariableADTvalue

                //   针对传入 valueVariable 的数据进行处理，如果是 select、checkbox、person 类型的数据，需要返回数组
                // if (
                //     variableData.type === 'VALUE' &&
                //     variableData.valueVariable?.type &&
                //     ['select', 'person', 'department', 'role'].includes(variableData.valueVariable?.type)
                // ) {
                //     isArray = true
                // }
                const res = generateAdtValue(innerType, variableData, extraParams, format)

                if (res?.needFlat) {
                    needFlat = true
                    return res.value
                }
                return res
            }
            return ''
        }) || ['']
        : ['']
    return innerType === 'ARRAY' ? (needFlat ? variableValue?.flat().filter(Boolean) : variableValue?.filter(Boolean)) : variableValue?.join('') || ''
}

const generatePersonVariableValue = (fieldId: string, record: RecordLikeProtocol, dataSourceList: DataSourceAbstract[]) => {
    const { dsId, content } = record
    const ds = find(item => item.id === dsId, dataSourceList)
    if (!ds) {
        return undefined
    }
    const { schema } = ds
    const field = schema[fieldId]
    if (!field || !addresseeFieldType.has(field.type)) {
        return undefined
    }
    const cellValue = content[fieldId].value
    if (!cellValue) {
        return undefined
    }
    return {
        type: VariableType.VALUE,
        valueVariable: {
            type: field.type,
            value: cellValue
        }
    } as VariableADTvalue
}

export const resolvedUpstreamVariableValue = (params: {
    data: VariableADTvalue[]
    extraParams?: AnyObject
}): VariableADTvalue[] => {
    const { data, extraParams } = params
    const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList || []
    return data.reduce<VariableADTvalue[]>((prev, cur) => {
        if (cur.type === VariableType.UPSTREAM) {
            const nodeId = cur.upstreamVariable?.nodeId
            const fieldId = cur.upstreamVariable?.nodeId
            if (!nodeId || !fieldId) {
                return prev
            }
            const record: RecordLikeProtocol | undefined = extraParams?.[nodeId]?.record
            if (!record) {
                return prev
            }
            const v = generatePersonVariableValue(fieldId, record, dataSourceList)
            if (!v) {
                return prev
            }
            prev.push(v)
        }
        if (cur.type === VariableType.USER) {
            const fieldId = cur.userVariable?.fieldId
            const record = extraParams?.userRecord
            if (!record || !fieldId) {
                return prev
            }
            const v = generatePersonVariableValue(fieldId, record, dataSourceList)
            if (!v) {
                return prev
            }
            prev.push(v)
        }
        prev.push(cur)
        return prev
    }, [])
}

export const generateVariableValue = (params: {
    innerType: TypeInstanceMap
    jsonContent: TiptapEditorJSONContent
    extraParams?: AnyObject
    isRichText?: boolean
    format?: FormatPayload
}): FieldCellValue => {
    const { innerType, jsonContent, extraParams, isRichText, format } = params
    if (!isRichText) {
        const c = jsonContent.content?.[0]

        return c ? generateSingleVariableValue(innerType, c, extraParams, format) : ''
    }

    return jsonContent.content?.map(c => generateSingleVariableValue(innerType, c, extraParams, format)).join('') ?? ''
}

export const generateFieldVariableValue = (innerType: TypeInstanceMap, field: NodeFieldContent, extraParams?: AnyObject) => {
    const { fieldId, value } = field

    const v = generateVariableValue({ innerType, jsonContent: value, extraParams: { ...extraParams, sourceFieldId: fieldId } })

    if (innerType === 'BOOL') {
        return {
            fieldId,
            value: v === 'true'
        }
    }
    return {
        fieldId,
        value: v
    }
}



export const getCreateRecordVariableValue = (config: SettingRecordFields, extraParams?: AnyObject) => {
    const { fields, dataSourceId } = config
    const dataSource = find(item => item.id === dataSourceId, extraParams?.dataSourceList as DataSourceAbstract[])

    return fields.map(field => generateFieldVariableValue(dataSource?.schema[field.fieldId]?.innerType || 'TEXT', field, extraParams))
}

export const getUpdateRecordVariableValue = (dataSourceId: string, config: UpdateRecordActionConfig, extraParams?: AnyObject) => {
    const { fields, selectType, nodeId } = config
    const dataSource = find(item => item.id === dataSourceId, extraParams?.dataSourceList as DataSourceAbstract[])
    const fieldsParams = fields.map(field => generateFieldVariableValue(dataSource?.schema[field.fieldId]?.innerType || 'TEXT', field, extraParams))

    // 暂时这样处理，去掉了选择指定记录的逻辑，目前这块逻辑只有选择上游节点
    const recordId = selectType === 'UPSTREAM' ? extraParams?.[nodeId]?.record?.id : ''

    return {
        recordId,
        fields: fieldsParams
    }
}

export const getFindSingleRecordVariableValue = (config: FindSingleRecordActionConfig, extraParams?: AnyObject) => {
    const { dataSourceId, sort, filter } = config
    const { currentAppId, currentEnvId } = extraParams?.clickTriggerNodeParams || {}
    const resolvedFilter = resolveFilter({ filter, extraParams })

    // const pageId = extraParams?.getCurrentPageDeps()?.pageId ?? ''

    return {
        appId: currentAppId,
        envId: currentEnvId,
        dsId: dataSourceId,
        sort,
        filter: resolvedFilter
        // pageId
    }
}

export const getHandleSubProcessVariableValue = (config: HandleSubProcessActionConfig, extraParams?: AnyObject) => {
    const { args } = config
    return args?.map(item => ({
        ...item,
        value: item.value ? generateVariableValue({ innerType: item.innerType, jsonContent: item.value, extraParams }) : undefined
    }))
}

export const getActiveAddIClickUserVariableValue = (config: IClickActionConfig, extraParams?: AnyObject) => {
    const { iClickId, name, mobile, email } = config

    if (!name || !mobile || !email) {
        return
    }

    const nameVariableValues = generateVariableValue({ innerType: 'TEXT', jsonContent: name, extraParams })
    const mobileVariableValues = generateVariableValue({ innerType: 'TEXT', jsonContent: mobile, extraParams })
    const emailVariableValues = generateVariableValue({ innerType: 'TEXT', jsonContent: email, extraParams })

    const nameString = Array.isArray(nameVariableValues) ? '' : String(nameVariableValues)
    const mobileString = Array.isArray(mobileVariableValues) ? '' : String(mobileVariableValues)
    const emailString = Array.isArray(emailVariableValues) ? '' : String(emailVariableValues)

    return {
        iClickId,
        name: nameString,
        mobile: mobileString,
        email: emailString
        // pageId: extraParams?.getCurrentPageDeps()?.pageId ?? ''
    }
}

export const getEmails = (emailParticipants: EmailParticipant[], extraParams?: AnyObject) => {
    return emailParticipants.map(item => {

        switch (item.type) {
            case 'COMMITTER': {
                return ''
            }
            case 'USER_IDENTIFIER': {
                // TODO: 暂时没有哦，后续再处理，有邮箱字段了再说
                // return identifier
                return ''
            }
            case 'MANUAL_INPUT': {
                return item?.input ?? ''
            }
            case 'UPSTREAM': {
                const { ref } = item
                if (!ref) {
                    return ''
                }
                const { nodeId, fieldId } = ref
                const record = extraParams?.[nodeId]?.record
                if (!record || !record.content[fieldId]) {
                    return ''
                }
                return record.content[fieldId].value as string
            }
            default: {
                return ''
            }
        }
    })
}

export const getDingTalkRobotVariableValue = (
    config: SendMsgDingTalkRobotConfig,
    extraParams?: AnyObject
): ActiveDingTalkRobotActionPayload['config'] => {
    const { msgtype, at, text, link, markdown, id } = config

    switch (msgtype) {
        case 'link': {
            const linkTitleValue = link ? generateVariableValue({ innerType: 'TEXT', jsonContent: link.editorTitle, extraParams }) : ''
            const linkTextValue = link ? generateVariableValue({ innerType: 'TEXT', jsonContent: link.editorText, extraParams }) : ''
            const linkMessageValue = link ? generateVariableValue({ innerType: 'TEXT', jsonContent: link?.editorMessageUrl, extraParams }) : ''
            const linkValue = {
                title: Array.isArray(linkTitleValue) ? '' : String(linkTitleValue),
                text: Array.isArray(linkTextValue) ? '' : String(linkTextValue),
                picUrl: link ? link.picUrl : '',
                messageUrl: Array.isArray(linkMessageValue) ? '' : String(linkMessageValue)
            }
            return {
                id,
                msgtype,
                at,
                link: linkValue
            }
        }
        case 'markdown': {
            const markdownTextValue = markdown ? generateVariableValue({ innerType: 'TEXT', jsonContent: markdown.editorText, extraParams }) : ''
            return {
                id,
                msgtype,
                text: { content: Array.isArray(markdownTextValue) ? '' : String(markdownTextValue) }
            }
        }

        default: {
            const textContentValue = text ? generateVariableValue({ innerType: 'TEXT', jsonContent: text.editorContent, extraParams }) : ''
            return {
                id,
                msgtype,
                text: { content: Array.isArray(textContentValue) ? '' : String(textContentValue) },
                at
            }
        }
    }
}

type DownloadFileInfo = {
    fieldName: string
    files: PreviewFile[]
}

/**
 * 下载动作与动作流解析附件变量
 * @param config 下载动作配置
 * @param extraParams
 * @returns
 */
export const resolveDownloadFileUrls = (config: DownloadFileActionConfig, extraParams?: AnyObject): DownloadFileInfo | undefined => {
    const { fileUrl } = config

    if (!fileUrl) {
        return
    }
    const appId = extraParams?.appId
    const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList

    switch (fileUrl.type) {
        case VariableType.UPSTREAM: {
            const { upstreamVariable } = fileUrl

            if (!upstreamVariable || !upstreamVariable.nodeId || !upstreamVariable.fieldId) {
                return
            }

            const { nodeId, fieldId } = upstreamVariable

            const record = extraParams?.[nodeId]?.record

            const dsId = getUpstreamRealDsId(nodeId, extraParams?.nodes)

            const dataSource = dataSourceList?.find(({ id }) => id === dsId)
            const fieldName = dataSource?.schema[fieldId]?.name ?? ''
            const fieldValue = record?.content?.[fieldId]?.value
            if (!Array.isArray(fieldValue)) {
                return
            }
            const files = fieldValue.map(url => {
                const fileName = getFileNameByUrl(url) || ''
                const fullUrl = getImageFullUrlInApplication(appId, url)
                return {
                    name: fileName,
                    type: getFileTypeByUrl(fileName),
                    url: fullUrl
                }
            })
            return { fieldName, files }
        }
        case VariableType.USER: {
            const { userVariable } = fileUrl

            if (!userVariable || !userVariable.fieldId) {
                return
            }

            const { fieldId } = userVariable

            const userRecord = extraParams?.userRecord?.record

            const fieldValue = userRecord?.content?.[fieldId]?.value
            if (Array.isArray(fieldValue)) {
                const files = fieldValue.map(url => {
                    const fileName = getFileNameByUrl(url) || ''
                    const fullUrl = getImageFullUrlInApplication(appId, url)
                    return {
                        name: fileName,
                        type: getFileTypeByUrl(fileName),
                        url: fullUrl
                    }
                })
                return { fieldName: '头像', files }
            }
            return
        }
        case VariableType.VIEW: {
            const { viewVariable } = fileUrl

            if (!viewVariable || !viewVariable.fieldId) {
                return
            }

            const { fieldId } = viewVariable

            const viewRecord = extraParams?.viewRecord

            const fieldValue = viewRecord?.content?.[fieldId]?.value
            if (Array.isArray(fieldValue)) {
                const files = fieldValue.map(url => {
                    const fileName = getFileNameByUrl(url) || ''
                    const fullUrl = getImageFullUrlInApplication(appId, url)
                    return {
                        name: fileName,
                        type: getFileTypeByUrl(fileName),
                        url: fullUrl
                    }
                })
                return { fieldName: '头像', files }
            }
            return
        }
        case VariableType.VALUE: {
            const { valueVariable } = fileUrl

            if (!valueVariable) {
                return
            }

            const { type, value } = valueVariable

            if (type !== 'file') {
                return undefined
            }
            const files = value.map(url => {
                const fileName = getFileNameByUrl(url) || ''
                return {
                    name: fileName,
                    type: getFileTypeByUrl(fileName),
                    url
                }
            })
            return { fieldName: '', files }
        }
        case VariableType.PAGE: {
            const { pageVariable } = fileUrl
            const { type, fieldId = '', dsId } = pageVariable ?? {}
            let record = null
            if (type === 'page') {
                record = extraParams?.pageRecord
            } else {
                record = extraParams?.clickTriggerNodeParams?.prevRecord
            }

            const dataSource = dataSourceList?.find(({ id }) => id === dsId)
            const fieldName = dataSource?.schema[fieldId]?.name ?? ''

            const fieldValue = record?.content?.[fieldId]?.value
            if (!Array.isArray(fieldValue)) {
                return
            }
            const files = fieldValue.map(url => {
                const fileName = getFileNameByUrl(url) || ''
                const fullUrl = getImageFullUrlInApplication(appId, url)
                return {
                    name: fileName,
                    type: getFileTypeByUrl(fileName),
                    url: fullUrl
                }
            })
            return { fieldName, files }
        }
        default:
    }
}

export const downloadSingleFileWithUrl = (params: { fileName?: string; url: string }) => {
    const { fileName = '', url } = params
    try {
        // 创建一个隐藏的<a>元素，并设置其属性
        const downloadLink = document.createElement('a')
        // downloadLink.href = window.URL.createObjectURL(blob)
        downloadLink.href = url
        downloadLink.download = fileName

        // 触发点击事件以下载文件
        downloadLink.click()
        downloadLink.remove()

    } catch (error) {
        console.error('Error downloading file:', error)
    }
}

export const downloadFileWithUrl = async (params: { fileName?: string; url: string }) => {
    const { fileName = '', url } = params

    try {
        const response = await fetch(url, { cache: 'no-cache' })
        const blob = await response.blob()

        // 创建一个隐藏的<a>元素，并设置其属性
        const downloadLink = document.createElement('a')
        downloadLink.href = window.URL.createObjectURL(blob)
        downloadLink.download = fileName

        // 触发点击事件以下载文件
        downloadLink.click()
        downloadLink.remove()

        // 释放URL对象
        window.URL.revokeObjectURL(downloadLink.href)
    } catch (error) {
        console.error('Error downloading file:', error)
    }
}

/**
 * 专用于下载动作、动作流中附件内容
 * @param downloadFileUrl
 */
export const downloadFile = async (downloadFileParams: DownloadFileInfo) => {
    const { fieldName, files } = downloadFileParams
    Toast.info('开始下载文件...')
    // 单个文件直接下载
    if (files?.length === 1) {
        downloadSingleFileWithUrl({ url: files[0].url, fileName: files[0].name })
    }

    if (files?.length > 1) {
        const zip = new JSZip()
        const folder = zip.folder(fieldName)
        const withBlobFiles = await Promise.all(
            files.map(async file => ({
                name: file.name,
                blob: await fetch(file.url, { cache: 'no-cache' }).then(response => response.blob())
            }))
        )

        for (const withBlobFile of withBlobFiles) {
            folder?.file(withBlobFile.name, withBlobFile.blob)
        }

        const content = await zip.generateAsync({ type: 'blob' })

        const url = URL.createObjectURL(content)
        const fileName = `${fieldName}.zip`

        await downloadFileWithUrl({ fileName, url })
    }
}

/**
 *  变量解析
 * @param variable 变量
 * @param extraParams
 * @returns
 */
export const resolveVariable = (variable: VariableADTvalue, extraParams?: AnyObject): FieldCellValue | undefined => {
    if (!variable) {
        return
    }

    switch (variable.type) {
        case VariableType.UPSTREAM: {
            const { upstreamVariable } = variable

            if (!upstreamVariable || !upstreamVariable.nodeId || !upstreamVariable.fieldId) {
                return
            }

            const { nodeId, fieldId } = upstreamVariable

            const record = extraParams?.[nodeId]?.record
            return record?.content?.[fieldId]?.value
        }
        case VariableType.USER: {
            const { userVariable } = variable

            if (!userVariable || !userVariable.fieldId) {
                return
            }

            const { fieldId } = userVariable

            const userRecord = extraParams?.userRecord?.record

            return userRecord?.content?.[fieldId]?.value
        }
        case VariableType.VALUE: {
            const { valueVariable } = variable

            if (!valueVariable) {
                return
            }

            const { type, value } = valueVariable

            return value
        }
        case VariableType.PAGE: {
            const { pageVariable } = variable
            const { type, fieldId = '', dsId } = pageVariable ?? {}
            let record = null
            if (type === 'page') {
                record = extraParams?.pageRecord
            } else {
                record = extraParams?.clickTriggerNodeParams?.prevRecord
            }

            return record?.content?.[fieldId]?.value
        }
        case VariableType.VIEW: {
            const { viewVariable } = variable
            const { fieldId = '' } = viewVariable ?? {}
            const record = extraParams?.viewRecord
            return record?.content?.[fieldId]?.value
        }
        case VariableType.PAGE_LINK: {
            const value = variable.pageLinkVariable?.value
            return value === 'CURRENT_PAGE' ? extraParams?.getCurrentPageLink?.() : ''
        }
        default:
    }
}

/**
 * ------------------  runner action engine  ------------------
 */
export const getWechatPayType = function () {
    const isMobile = getDeviceType() === ApplicationPreviewEnum.mobile
    const isWechat = isWechatBrowser()
    if (isMobile) {
        if (isWechat) {
            return WechatPayType.JSAPI
        }
        return WechatPayType.H5
    }
    return WechatPayType.NATIVE
}

export const getSearchParams = function (params: Record<string, string | number | boolean>) {
    return Object.entries(params).reduce((pre, [k, v]) => (v === undefined ? pre : `${pre}${pre === '' ? '' : '&'}${k}=${v}`), '')
}



export const generatorEmailStyle = (backgroundConfig: BackgroundProtocol, paddingConfig: EdgeValue, gap: number | undefined, palettes: ApplicationSettingThemeColor[]) => {

    const background = getBackgroundStyle(undefined, backgroundConfig, palettes)
    const padding = getPaddingStyle(paddingConfig)
    const flex: CSSProperties = { display: 'flex', flexDirection: 'column', alignItems: 'center', gap: `${gap ?? 0}px` }
    const style: CSSProperties = {
        ...background,
        ...padding,
        ...flex,
    }
    return style
}


export const generatorEmailBodyStyle = (body: SendEmailBody, palettes: ApplicationSettingThemeColor[]) => {
    const { background: backgroundConfig, padding: paddingConfig, width: widthSize = PARAGRAPH_WIDTH_TYPE.NORMAL, border: borderConfig } = body
    const background = getBackgroundStyle(undefined, backgroundConfig, palettes)
    const padding = getPaddingStyle(paddingConfig)
    const width: CSSProperties = widthSize === PARAGRAPH_WIDTH_TYPE.FULL ? {} : { maxWidth: `${SEND_EMAIL_WIDTH[widthSize]}px` }
    const border = getBorderStyle(borderConfig, palettes)
    const size: CSSProperties = { width: '100%', boxSizing: 'border-box' }
    const style: CSSProperties = {
        ...size,
        ...background,
        ...padding,
        ...width,
        ...border,
        // ...flex,
    }
    return style
}

function toKebabCase(key: string): string {
    return key.replace(/([A-Z])/g, '-$1').toLowerCase();
}

export const generatorHtml = (styles: CSSProperties, content?: string) => {
    // 拼接 style 属性
    const divElement = document.createElement('div')
    Object.entries(styles).forEach(([key, value]) => {
        if (typeof value === 'string') {
            const transformKey = toKebabCase(key)
            divElement.style.setProperty(transformKey, value);
        }
    })
    divElement.innerHTML = content || ''
    // 生成最终的 HTML 字符串
    return divElement.outerHTML
}

// 正则表达式匹配 <variable> 标签及其内容
const outputStr = (inputStr: string, excParams: AnyObject) => {
    return inputStr.replace(
        /<variable\s+data-value="([^"]*)"><\/variable>/g,
        (_, dataValue) => {
            // 替换 HTML 实体
            const jsonString = dataValue.replaceAll('&quot;', '"');
            // 解析 JSON 并转换为字符串
            const data = JSON.parse(jsonString);
            const d = generateAdtValue('TEXT', data, excParams, { dateFormat: true, fileFormat: true })
            return d?.needFlat ? d.value?.join(',') || '' : d
        }
    );
}

type GenerateHtmlBySendEmailPayload = {
    config: SendEmailActionConfig
    excParams: AnyObject
}

export const generateHtmlBySendEmail = (payload: GenerateHtmlBySendEmailPayload) => {

    const { config, excParams } = payload
    const { palettes } = excParams
    const { body, background, padding } = config
    const { paragraph, editType, gap } = body
    const emailBodyStyle = generatorEmailBodyStyle(body, palettes)

    const bodyInnerHtml = paragraph.reduce((prev, cur) => {
        if (editType === EMAIL_EDIT_TYPE.HTML) {
            // const resultBodyHtml = generatorHtml(emailBodyStyle, cur.targetHtml || '')
            const resultParagraphHtml = cur.targetHtml || ''
            prev += resultParagraphHtml
            return prev
        }
        const resultParagraphHtml = cur.targetRichtext || ''
        prev += resultParagraphHtml
        return prev
    }, '')
    const result = outputStr(bodyInnerHtml, excParams)
    const bodyHtml = generatorHtml(emailBodyStyle, result)
    const emailStyle = generatorEmailStyle(background, padding, gap, palettes)
    const html = generatorHtml(emailStyle, bodyHtml)

    return html.replace(/>(.*?)</g, (match) => {
        // 在匹配的内容中移除所有&nbsp;
        return match.replace(/&nbsp;+/g, '');
    })
}

