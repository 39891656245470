import { Flex } from '@byecode/ui'
import type { DataSourceAbstract } from '@lighthouse/core'
import { type Field, type VariableADTvalue, VariableType } from '@lighthouse/core'
import cls from 'classnames'
import React, { useMemo } from 'react'

import type { VariableOptions, VariableTree } from '../../types'
import { VariableTag } from '../../VariableTag'
import { VariablePreview } from '../Preview'
import * as SC from './styles'

interface VariableInputProps {
    value?: VariableADTvalue
    placeholder?: string
    field?: Field
    width?: string
    options?: VariableOptions
    disabled?: boolean
    userDsOption?: VariableTree
    dataSourceOption?: VariableTree
    inputOption?: VariableTree
    formOption?: VariableTree
    eventOption?: VariableTree
    filterOptions?: VariableTree[]
    viewOption?: VariableTree
    opened?: boolean
    filePercent?: number
    dataSourceList?: DataSourceAbstract[]
    onChange: (value: VariableADTvalue) => void
    onChangeOpen: () => void
    onOpenSelectDataSourceModal: () => void
    onCancelUpload: () =>void
}

export const VariableInput: React.FunctionComponent<VariableInputProps> = ({
    value: data,
    placeholder,
    field,
    options,
    disabled,
    width = '180px',
    userDsOption,
    inputOption,
    viewOption,
    formOption,
    eventOption,
    filterOptions,
    dataSourceOption,
    opened,
    filePercent,
    dataSourceList,
    onCancelUpload,
    onOpenSelectDataSourceModal,
    onChangeOpen,
    onChange
}) => {
    const variableEle = useMemo(() => {
        if (!data || data?.type === VariableType.VALUE) {
            return (
                <VariablePreview
                    width={width}
                    placeholder={placeholder}
                    value={data}
                    disabled={disabled}
                    field={field}
                    opened={opened}
                    filePercent={filePercent}
                    onChangeOpen={onChangeOpen}
                    onChange={onChange}
                    onCancelUpload={onCancelUpload}
                />
            )
        }
        return (
            <>
                <SC.TagContainer onClick={() => onChangeOpen?.()}>
                    <SC.TagWrapper>
                        <VariableTag
                            value={data}
                            inputOption={inputOption}
                            options={options}
                            userDsOption={userDsOption}
                            filterOptions={filterOptions}
                            viewOption={viewOption}
                            formOption={formOption}
                            eventOption={eventOption}
                            dataSourceOption={dataSourceOption}
                            dataSourceList={dataSourceList}
                            style={{
                                lineHeight: '24px'
                            }}
                            onOpenSelectDataSourceModal={onOpenSelectDataSourceModal}
                        />
                    </SC.TagWrapper>
                    <Flex justifyContent="flex-end" alignItems="center" style={{ height: '100%' }}>
                        <SC.Icon
                            type="CloseCircle"
                            onClick={e => {
                                e.stopPropagation()
                                !disabled && onChange({ type: VariableType.VALUE })
                            }}
                        />
                        <SC.Icon type="ArrowDownSmall" />
                    </Flex>
                </SC.TagContainer>
            </>
        )
    }, [
        data,
        inputOption,
        options,
        userDsOption,
        filterOptions,
        viewOption,
        formOption,
        eventOption,
        dataSourceOption,
        dataSourceList,
        onCancelUpload,
        onOpenSelectDataSourceModal,
        width,
        placeholder,
        disabled,
        field,
        opened,
        filePercent,
        onChangeOpen,
        onChange
    ])

    return (
        <SC.Container>
            <SC.Wrapper width={width} className={cls({ variableDisabled: disabled })}>
                {variableEle}
            </SC.Wrapper>
        </SC.Container>
    )
}
