import { backgroundTransitionOnClick, flex, pointer, tinyButtons } from '@byecode/ui'
import styled, { css } from 'styled-components'

import { Icon, Text } from '../styles'

export const Container = styled.div<{ disableEvent?: boolean; isMobile?: boolean }>`
    width: 100%;
    /* margin: 0 auto; */
    background-color: var(--color-white);
    pointer-events: ${({ disableEvent }) => (disableEvent ? 'none' : 'auto')};
    flex-shrink: 0;
    flex-basis: content;
    position: relative;
    z-index: 5;
    border-radius: 0 0 48px 48px;

    ${({ isMobile }) =>
        isMobile &&
        css`
            z-index: 1;
            border-radius: 0;
        `}
`
export const Wrapper = styled.div<{ selected?: boolean; isActive?: boolean }>`
    max-height: 108px;
    overflow-y: auto;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    gap: 12px;
    border-top: 1px solid var(--color-gray-200);
    ${tinyButtons}

    &.simulationMobile {
        border: ${({ selected }) => (selected ? '3px solid var(--color-purple-500)' : '3px solid transparent')};
        border-top: ${({ selected, isActive }) => (!selected || isActive ? '1px solid var(--color-gray-200)' : undefined)};
    }
`

export const RowContain = styled.div`
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
    flex: 1;
    ${flex}

    &.row_tab_0 {
        padding: 0 15px;
        justify-content: flex-start;

        gap: 8px;
        > * {
            width: calc((100% - (8px * 5)) / 6);
        }
    }

    &.row_tab_1 {
        justify-content: center;
    }

    &.row_tab_2 {
        padding: 0 42px;
        justify-content: space-around;
        gap: 8px;
        > * {
            flex: 1;
        }
    }

    &.row_tab_3 {
        padding: 0 42px;
        justify-content: space-between;
        gap: 8px;
        > * {
            flex: 1;
        }
    }

    &.row_tab_4 {
        padding: 0 24px;
        justify-content: space-between;

        gap: 8px;
        > * {
            flex: 1;
        }
    }

    &.row_tab_5 {
        padding: 0 15px;
        justify-content: space-between;

        gap: 8px;
        > * {
            flex: 1;
        }
    }
    &.row_tab_6 {
        padding: 0 15px;
        justify-content: space-between;

        gap: 8px;
        > * {
            flex: 1;
        }
    }
`
export const Box = styled.div`
    width: 16%;
    height: 40px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    overflow: hidden;
    ${pointer}
    >${Icon} {
        margin: 3px 0;
    }
    ${flex}
`

export const BoxFooter = styled.div`
    width: 100%;
    align-items: center;
    justify-content: center;
    ${Icon} {
        margin-right: 2px;
    }
    ${flex}
`

export const Item = styled.div<{ level: number }>`
    ${flex}
    padding: 12px 16px;
    justify-content: space-between;
    align-items: center;
    padding-left: ${({ level }) => `${level * 24 + 16}px`};
    color: var(--color-black);
    background-color: var(--color-white);

    &.active {
        background: var(--color-gray-100);
    }

    @media (any-hover: hover) {
        ${backgroundTransitionOnClick}
    }
`

export const LeftFill = styled.div`
    ${flex}
    align-items: center;
    flex: 1;
    overflow: hidden;
    gap: 8px;
`
export const DrawerContain = styled.div`
    width: 100%;
    padding: 12px 0;
    max-height: 374px;
    overflow-y: auto;
    background-color: var(--color-white);
    border-radius: 12px 12px 0 0;
    ${tinyButtons}
`

export const IphoneSafeArea = styled.div`
    height: 40px;
    width: 100%;
    position: relative;

    ::before {
        position: absolute;
        z-index: 0;
        left: calc(50% - 67.5px);
        bottom: 18px;
        width: 135px;
        height: 5px;
        content: '';
        background-color: var(--color-black);
        border-radius: 4px;
    }
`
