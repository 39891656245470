/* eslint-disable prefer-destructuring */
import { Divider, Modal, tinyButtons, Toast } from '@byecode/ui'
import type { DragEndEvent, DragMoveEvent, DragStartEvent, Modifier } from '@dnd-kit/core'
import { closestCenter, DndContext, DragOverlay, MeasuringStrategy, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { getBlockIcon, getBlockName } from '@lighthouse/block'
import type {
    BlockAbstract,
    BlockRuntimeState,
    ContainerBlockAbstract,
    DataSourceAbstract,
    PageType,
    ViewBlockAbstract
} from '@lighthouse/core'
import { BlockType, PAGE_TYPE } from '@lighthouse/core'
import type { ApplicationPreviewEnum, CustomViewVisibleData, NodeIdWithScope } from '@lighthouse/shared'
import {
    deepClearSyncBlock,
    findAllParentBlocksByType,
    findBlockById,
    findNewSelectParentAndChildren,
    findNormalOrSyncBlock,
    getBlockChildren,
    getCurrentBlockChildren,
    hasChildrenBlock,
    initBlockRuntimeState,
    isContainerBlock,
    isCustomViewBlock,
    isFloatBoxBlock,
    isFormContainerBlock,
    PAGE_LAYER_ID,
    transformBlock2FlowLayoutNode,
    useAtomAction,
    useAtomData
} from '@lighthouse/shared'
import produce, { current, original } from 'immer'
import { useAtomCallback } from 'jotai/utils'
import { clone, find, reduce } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { pageStackOfFloatBlockAtom, syncComponentsAtom, transformSyncComponentsAtom } from '@/atoms/application/state'
import { createBlockAtom, removeBlockAtom } from '@/atoms/page/action'
import { blocksAtom, lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageStackAtom } from '@/atoms/page/state'
import { AsideType } from '@/atoms/page/types'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { PageFieldBlocksProvider } from '@/contexts/PageContext'
import { useNodeToolbarActions } from '@/hooks/layoutEngine/useNodeToolbarActions'
import { copyBlock, includeNotAllowedBlock, isCustomChildren, isFormContainerChildren, removeBlockById } from '@/hooks/layoutEngine/utils'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { useBlockActions } from '@/hooks/useBlock'
import { useDataSourceList } from '@/hooks/useDataSource'
import { useFieldBlocksWithDsId } from '@/hooks/useFieldBlocksWithDsId'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import * as srv from '@/services'
import { NODE_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { PageSelector } from './PageSelector'
import { Item, SortableItem } from './SortableItem'
import type { FlattedNode } from './types'

const Root = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`

const ListWrapper = styled.div`
    display: flex;
    flex: 1;
    padding: 4px 12px 32px;
    overflow: auto;
    /* background-color: var(--color-gray-100); */
`

const List = styled.ul`
    flex: 1;
    list-style: none;
    display: block;
    ${tinyButtons}
    border-radius: 5px;
`

const PAGE_ICON_MAP: Record<PageType, string> = {
    [PAGE_TYPE.creator]: 'LayerFormPage',
    [PAGE_TYPE.default]: 'PageBroadContent',
    [PAGE_TYPE.document]: 'LayerDetailPage',
    [PAGE_TYPE.edit]: 'LayerEditPage'
}

function getNodeIdWithScope(value: string): NodeIdWithScope {
    if (value.includes('@')) {
        const [scope, id] = value.split('@')
        return { scope, id }
    }

    return { id: value }
}

function getCustomViewData(block: ViewBlockAbstract, dataSourceList: DataSourceAbstract[]) {
    const dataSource = find(ds => ds.id === block.config.pointer, dataSourceList)
    if (!dataSource) {
        return
    }
    return {
        name: block.title,
        dataSource
    }
}

type FlattenParams = {
    scope?: string
    blockRuntimeState?: BlockRuntimeState
    indent: number
    parentId?: string
    collapsedNodes: NodeIdWithScope[]
    dataSourceList: DataSourceAbstract[]
    previewType: ApplicationPreviewEnum
    customViewData?: CustomViewVisibleData
}
function flattenBlocks(blocks: BlockAbstract[], syncComponents: ContainerBlockAbstract[], option: FlattenParams): FlattedNode[] {
    const { scope, blockRuntimeState, indent, parentId, collapsedNodes, dataSourceList, previewType, customViewData } = option
    return blocks.reduce<FlattedNode[]>((total, current) => {
        const collapsed = collapsedNodes.some(item => item.id === current.id && item.scope === scope)

        const isContainer = isContainerBlock(current)
        const isFormContainer = isFormContainerBlock(current)
        const isFloatBox = isFloatBoxBlock(current)
        const isCustomView = isCustomViewBlock(current)
        const collapsible = isContainer || isFormContainer || isCustomView || isFloatBox

        const currentCustomViewData: CustomViewVisibleData | undefined = isCustomView
            ? getCustomViewData(current, dataSourceList)
            : undefined

        let title: string | undefined
        let children: BlockAbstract[] | undefined
        if (current.synchronousId) {
            const block = syncComponents.find(item => item.id === current.synchronousId)
            if (block) {
                title = block.title
                children = getCurrentBlockChildren(block, { blockRuntimeState, uniqueContainerId: current.id })
            }
        } else {
            title = current.title
            children = getCurrentBlockChildren(current, {
                blockRuntimeState,
                uniqueContainerId: scope ? `${scope}@${current.id}` : current.id
            })
        }

        const visibleMode = current.config.breakPoint?.visibility?.visible
        return [
            ...total,
            {
                id: current.id,
                scope,
                name: title || getBlockName(current),
                icon: current.synchronousId ? 'SyncComponent' : getBlockIcon(current, previewType),
                indent,
                parentId,
                collapsible,
                collapsed,
                block: current,
                customViewData,
                description: isFormContainer ? dataSourceList.find(item => item.id === current.config.pointer)?.name : undefined,
                visibleMode
            },
            ...(collapsed
                ? []
                : flattenBlocks(children || [], syncComponents, {
                      scope: scope || (current.synchronousId ? current.id : undefined),
                      blockRuntimeState,
                      indent: indent + 1,
                      parentId: current.id,
                      collapsedNodes,
                      dataSourceList,
                      previewType,
                      customViewData: currentCustomViewData || customViewData
                  }))
        ]
    }, [])
}

function buildPageBlocksTree(nodes: FlattedNode[], blockRuntimeState: BlockRuntimeState | undefined): BlockAbstract[] {
    const tree: BlockAbstract[] = []
    const map: Record<string, BlockAbstract> = {}

    nodes.forEach(node => {
        if (!node.block) {
            return
        }

        const block = clone(node.block)

        if (isContainerBlock(block)) {
            const views = block.children
            views.find(view => view.id === blockRuntimeState?.container?.[block.id]?.currentView)?.children.splice(0)
            map[block.id] = {
                ...block,
                children: views
            }

            return
        }

        if (hasChildrenBlock(block)) {
            map[block.id] = {
                ...block,
                children: []
            }
            return
        }

        map[block.id] = block
    })

    nodes.forEach(node => {
        if (!node.parentId) {
            tree.push(map[node.id])
            return
        }

        if (node.parentId && map[node.parentId]) {
            const data = map[node.id]

            const parent = map[node.parentId]
            if (isContainerBlock(parent)) {
                const view = parent.children.find(item => item.id === blockRuntimeState?.container?.[parent.id]?.currentView)
                if (!view) {
                    return
                }
                view.children.push(data)
                return
            }

            if (hasChildrenBlock(parent)) {
                parent.children?.push(data)
            }
        }
    })

    return tree
}

function getParentId(indent: number, overIndex: number, items: FlattedNode[], prevItem?: FlattedNode): NodeIdWithScope | undefined {
    if (indent === 1 || !prevItem) {
        return
    }
    if (indent === prevItem.indent) {
        return prevItem.parentId
            ? { id: prevItem.parentId, scope: prevItem.scope === prevItem.parentId ? undefined : prevItem.scope }
            : undefined
    }
    if (indent > prevItem.indent) {
        return { id: prevItem.id, scope: prevItem.scope }
    }
    const less = items
        .slice(0, overIndex)
        .reverse()
        .find(item => item.indent === indent)

    return less?.parentId ? { id: less.parentId, scope: less.scope === less.parentId ? undefined : less.scope } : undefined
}

/** 检查是否包含禁止的block */
function checkNotAllowed({
    fromBlock,
    parentBlock,
    blocks,
    pageType
}: {
    fromBlock: BlockAbstract
    parentBlock: BlockAbstract
    pageType: PageType
    blocks: BlockAbstract[]
}) {
    // 表单容器、视图及图表block禁止插入到自定义视图内
    if (
        includeNotAllowedBlock(fromBlock, [BlockType.formContainer, BlockType.view, BlockType.chart]) &&
        (isCustomViewBlock(parentBlock) || isCustomChildren(parentBlock.id, blocks))
    ) {
        Toast.error('视图、图表、表单容器，不允许拖入自定义视图')
        return false
    }
    // 表单容器、视图及图表block禁止插入到弹出窗口内
    if (includeNotAllowedBlock(fromBlock, [BlockType.formContainer, BlockType.view, BlockType.chart]) && isFloatBoxBlock(parentBlock)) {
        Toast.error('视图、图表、表单容器，不允许拖入弹出窗口')
        return false
    }

    // 子表单只能拖入到表单页面的表单容器中
    if (
        includeNotAllowedBlock(fromBlock, [BlockType.subForm]) &&
        (pageType !== PAGE_TYPE.creator || !isFormContainerBlock(parentBlock) || !isFormContainerChildren(parentBlock.id, blocks))
    ) {
        Toast.error('子表单只能拖入到表单页面的表单容器中')
        return false
    }

    return true
}

const measuring = {
    droppable: {
        strategy: MeasuringStrategy.Always
    }
}
const adjustTranslate: Modifier = ({ transform }) => {
    return {
        ...transform,
        y: transform.y - 25
    }
}

export const PageLayers: React.FC = () => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const selectedNodeRef = useRef<NodeIdWithScope | undefined>()
    const scrollRef = useRef<HTMLDivElement>(null)
    const [pageId, stackId, rootPageId, blockRuntimeState, state] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.stackId || '', s?.rootPageId || '', s?.blockRuntimeState, s?.state] as const, [])
    )
    const { asideType = AsideType.PAGE, selectedNodes = [] } = state ?? {}
    const selectedNode = useMemo(() => (selectedNodes.length === 1 ? selectedNodes[0] : undefined), [selectedNodes])

    const [pageType, pageName, pageDsId] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => [s?.type || PAGE_TYPE.default, s?.name || '', s?.dsId || ''] as const, [])
    )

    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const dataSourceList = useDataSourceList(appId, envId)
    const previewType = usePreviewType()

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(transformSyncComponentsAtom)

    const { run: setPageBlocks } = useAtomAction(blocksAtom)
    const { run: setPageStackOfFloatBlock } = useAtomAction(pageStackOfFloatBlockAtom)
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const [collapsedNodes, setCollapsedNodes] = useState<NodeIdWithScope[]>([])
    const [activeNode, setActiveNode] = useState<NodeIdWithScope>()

    const { fieldBlocksWithDsId } = useFieldBlocksWithDsId()

    useEffect(() => {
        if (selectedNode && selectedNodeRef.current !== selectedNode) {
            selectedNodeRef.current = selectedNode
            const layerDom = document.querySelector(
                `li[data-layer-id="${selectedNode.id}"]${selectedNode.scope ? `[data-layer-scope="${selectedNode.scope}"]` : ''}`
            )
            if (!layerDom) {
                return
            }
            const label = layerDom.querySelector('label')
            label?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' })
        }
    }, [selectedNode])

    const flattedPageNodes = useMemo(() => {
        const collapsed = collapsedNodes.some(item => item.id === pageId && !item.scope)
        return collapsed
            ? []
            : flattenBlocks(pageBlocks, syncComponents, {
                  blockRuntimeState,
                  indent: 1,
                  collapsedNodes: activeNode ? [...collapsedNodes, activeNode] : collapsedNodes,
                  previewType,
                  dataSourceList
              })
    }, [activeNode, blockRuntimeState, collapsedNodes, dataSourceList, pageBlocks, pageId, previewType, syncComponents])

    const items = useMemo(() => flattedPageNodes.map(item => (item.scope ? `${item.scope}@${item.id}` : item.id)), [flattedPageNodes])

    const activeFlattedNode = useMemo(
        () => activeNode && flattedPageNodes.find(item => item.id === activeNode.id && item.scope === activeNode.scope),
        [activeNode, flattedPageNodes]
    )

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 4 }
        })
    )
    const [overInfo, setOverInfo] = useState<{ indent: number; parentId?: NodeIdWithScope } | null>(null)

    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveNode(getNodeIdWithScope(e.active.id.toString()))
            // setPageStack(draft => {
            //     const stack = equalPageStack({ rootPageId, stackId })(draft)
            //     if (stack) {
            //         stack.state.asideType = AsideType.BLOCK
            //         stack.state.selectedNodes = [e.active.id] as string[]
            //     }
            // })
        }
    }

    const onDragMove = ({ delta, active, over }: DragMoveEvent) => {
        if (over) {
            const activeIndex = items.indexOf(active.id as string)
            const overIndex = items.indexOf(over.id as string)
            const activeItem = flattedPageNodes[activeIndex]
            const newItems = arrayMove(flattedPageNodes, activeIndex, overIndex)
            const prevItem = newItems[overIndex - 1]
            const nextItem = newItems[overIndex + 1]
            const currentIndent = activeItem.indent + Math.round(delta.x / 20)

            const max = prevItem ? (prevItem.collapsible ? prevItem.indent + 1 : prevItem.indent) : 1
            const min = nextItem ? nextItem.indent : 1
            const indent = Math.max(min, Math.min(max, currentIndent))
            const parentId = getParentId(indent, overIndex, newItems, prevItem)

            if (activeIndex === overIndex && indent === activeItem.indent) {
                setOverInfo(null)

                return
            }

            setOverInfo({ indent, parentId })
        } else {
            setOverInfo(null)
        }
    }

    // 1. 从同步组件内移入到普通组件
    // 2. 从同步组件内移入到同步组件
    // 2-1. 不同作用域下的操作非法
    // 3. 从普通组件移入到普通组件
    // 4. 从普通组件移入到同步组件
    const onDragEnd = useAtomCallback(async (get, set, { over, active }: DragEndEvent) => {
        reset()

        if (over && overInfo) {
            const activeNode = getNodeIdWithScope(active.id.toString())
            const overNode = getNodeIdWithScope(over.id.toString())
            const { indent, parentId } = overInfo
            const flattenNodes = flattenBlocks(pageBlocks, syncComponents, {
                blockRuntimeState,
                indent: 1,
                collapsedNodes: [],
                previewType,
                dataSourceList
            })
            const activeIndex = flattenNodes.findIndex(item => item.id === activeNode.id && item.scope === activeNode.scope)
            const overIndex = flattenNodes.findIndex(item => item.id === overNode.id && item.scope === overNode.scope)

            const fromNode = flattenNodes[activeIndex]
            const toNode = flattenNodes[overIndex]
            const fromBlock = fromNode.block
            if (!fromBlock) {
                return
            }

            const parentNode = parentId ? flattenNodes.find(item => item.id === parentId.id && item.scope === parentId.scope) : undefined
            const scope = parentNode?.block?.synchronousId ? parentNode.id : parentNode?.scope
            const isMoveToSync = !!parentNode && (!!parentNode.block?.isLeafSynchronous || !!parentNode.block?.synchronousId)

            if (
                parentNode?.block &&
                !checkNotAllowed({
                    fromBlock,
                    parentBlock: parentNode.block,
                    pageType,
                    blocks: pageBlocks
                })
            ) {
                return
            }

            if (fromBlock.synchronousId && parentNode?.block && (parentNode.block.synchronousId || parentNode.block.isLeafSynchronous)) {
                Toast.error('同步组件不能拖入至同步组件')
                return
            }

            if (fromBlock.isLeafSynchronous && parentNode?.block) {
                if (parentNode.block.synchronousId && parentNode.block.id !== fromNode.scope) {
                    Toast.error('同步组件不能拖入至同步组件')
                    return
                }

                if (parentNode.block.isLeafSynchronous && parentNode.scope !== fromNode.scope) {
                    Toast.error('同步组件不能拖入至同步组件')
                    return
                }
            }

            const createBlockUndoRedoInfo: {
                createdBlock?: BlockAbstract[]
                prevBlocks?: BlockAbstract[]
                nextBlocks?: BlockAbstract[]
            } = {}
            const removeBlockUndoRedoInfo: {
                removedBlocks?: BlockAbstract[]
                prevBlocks?: BlockAbstract[]
                nextBlocks?: BlockAbstract[]
            } = {}
            const blocksUndoRedoInfo: {
                prevBlocks?: BlockAbstract[]
                nextBlocks?: BlockAbstract[]
                createdSyncComponent?: BlockAbstract
                prevSyncComponents?: ContainerBlockAbstract[]
                nextSyncComponents?: ContainerBlockAbstract[]
            } = {}
            set(syncComponentsAtom, draft => {
                blocksUndoRedoInfo.prevSyncComponents = original(draft)
                // 同步组件拖起
                if (fromBlock.isLeafSynchronous) {
                    const removed = removeBlockById(fromBlock.id)(draft)

                    // 如果是拖入到普通组件中，则创建block，并清除同步标识
                    if (!parentNode?.block?.isLeafSynchronous && !parentNode?.block?.synchronousId && removed) {
                        createBlockUndoRedoInfo.createdBlock = copyBlock(removed.map(current))
                        createBlockUndoRedoInfo.createdBlock.forEach(deepClearSyncBlock)
                        blocksUndoRedoInfo.nextSyncComponents = current(draft)
                        return
                    }
                } else if (!fromBlock.synchronousId && (parentNode?.block?.synchronousId || parentNode?.block?.isLeafSynchronous)) {
                    removeBlockUndoRedoInfo.removedBlocks = [clone(fromBlock)]
                }

                if (parentNode?.block) {
                    const { id, block, scope } = parentNode

                    // 拖入到同步组件中
                    if (block.synchronousId) {
                        const draftParent = findBlockById(block.synchronousId, draft)
                        if (!draftParent) {
                            return
                        }

                        const children = getCurrentBlockChildren(draftParent, {
                            blockRuntimeState,
                            uniqueContainerId: id
                        })
                        if (!children) {
                            return
                        }

                        const index = toNode.id === id ? 0 : (original(children) || []).findIndex(item => item.id === toNode.id)
                        const newBlock = clone({ ...fromBlock, isLeafSynchronous: true })
                        if (index === -1) {
                            children.push(newBlock)
                        } else {
                            children.splice(index, 0, newBlock)
                        }

                        blocksUndoRedoInfo.createdSyncComponent = fromBlock.isLeafSynchronous ? undefined : newBlock
                        blocksUndoRedoInfo.nextSyncComponents = current(draft)
                    } else if (block.isLeafSynchronous) {
                        const draftParent = findBlockById(id, draft)
                        if (!draftParent) {
                            return
                        }

                        const children = getCurrentBlockChildren(draftParent, {
                            blockRuntimeState,
                            uniqueContainerId: `${scope}@${id}`
                        })
                        if (!children) {
                            return
                        }

                        const index = toNode.id === id ? 0 : (original(children) || []).findIndex(item => item.id === toNode.id)

                        const newBlock = clone({ ...fromBlock, isLeafSynchronous: true })
                        if (index === -1) {
                            children.push(newBlock)
                        } else {
                            children.splice(index, 0, newBlock)
                        }

                        blocksUndoRedoInfo.createdSyncComponent = fromBlock.isLeafSynchronous ? undefined : newBlock
                        blocksUndoRedoInfo.nextSyncComponents = current(draft)
                    }
                }
            })

            const sortedNodes = arrayMove(
                produce(flattenNodes, draft => {
                    const draftNode = draft[activeIndex]
                    if (!draftNode.block) {
                        return
                    }
                    draftNode.parentId = parentId?.id
                    draftNode.indent = indent
                    if (isMoveToSync) {
                        draftNode.scope = parentNode?.scope ?? parentNode?.block?.synchronousId
                        draftNode.block.isLeafSynchronous = true
                    } else {
                        draftNode.scope = undefined
                        draftNode.block.isLeafSynchronous = undefined
                    }
                }),
                activeIndex,
                overIndex
            )
            // 过滤掉同步组件下的元素
            const filterSyncNodes = sortedNodes.filter(item => !item.block?.isLeafSynchronous)

            const newBlocks = buildPageBlocksTree(filterSyncNodes, blockRuntimeState)
            if (createBlockUndoRedoInfo.createdBlock) {
                const oldBlock = findBlockById(fromBlock.id, newBlocks)
                if (oldBlock) {
                    Object.entries(createBlockUndoRedoInfo.createdBlock[0]).forEach(([k, v]) => {
                        Reflect.set(oldBlock, k, v)
                    })
                }
            }

            setPageBlocks(draft => {
                const draftBlocks = draft[pageId]
                if (!draftBlocks) {
                    return
                }
                blocksUndoRedoInfo.prevBlocks = original(draftBlocks)
                createBlockUndoRedoInfo.prevBlocks = original(draftBlocks)
                removeBlockUndoRedoInfo.prevBlocks = original(draftBlocks)
                draft[pageId] = newBlocks

                if (
                    !fromBlock.isLeafSynchronous &&
                    (!parentNode || (!parentNode.block?.synchronousId && !parentNode.block?.isLeafSynchronous))
                ) {
                    blocksUndoRedoInfo.nextBlocks = newBlocks
                }
                createBlockUndoRedoInfo.nextBlocks = newBlocks
                removeBlockUndoRedoInfo.nextBlocks = newBlocks
                // scroll2FlowNode(active.id as string)
            })

            if (blocksUndoRedoInfo.nextSyncComponents) {
                set(pageStackAtom, draft => {
                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                    if (stack) {
                        initBlockRuntimeState(stack, {
                            blocks: get(pageBlocksAtom(pageId)),
                            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                            syncComponents: blocksUndoRedoInfo.nextSyncComponents!
                        })
                        stack.state.selectedNodes = [{ id: fromBlock.id, scope }]
                    }
                })
                if (blocksUndoRedoInfo.createdSyncComponent) {
                    srv.createSyncBlock({
                        createBlocks: [blocksUndoRedoInfo.createdSyncComponent],
                        blocks: blocksUndoRedoInfo.nextSyncComponents
                    })
                } else {
                    srv.updateSyncComponents(blocksUndoRedoInfo.nextSyncComponents)
                }
            }

            if (createBlockUndoRedoInfo.createdBlock && createBlockUndoRedoInfo.nextBlocks) {
                await set(createBlockAtom, {
                    rootPageId,
                    pageId,
                    stackId,
                    createdBlocks: createBlockUndoRedoInfo.createdBlock,
                    blocks: createBlockUndoRedoInfo.nextBlocks,
                    scope
                })
            }

            if (removeBlockUndoRedoInfo.removedBlocks && removeBlockUndoRedoInfo.nextBlocks) {
                await set(removeBlockAtom, {
                    rootPageId,
                    pageId,
                    stackId,
                    removedBlocks: removeBlockUndoRedoInfo.removedBlocks,
                    blocks: removeBlockUndoRedoInfo.nextBlocks,
                    clearSelected: false
                })
            }

            if (blocksUndoRedoInfo.nextBlocks) {
                srv.updateBlock({ pageId, allBlocks: blocksUndoRedoInfo.nextBlocks })
            }

            pageUndoRedoController.add({
                action: NODE_UPDATE_ACTION,
                payload: {
                    created: createBlockUndoRedoInfo.createdBlock &&
                        createBlockUndoRedoInfo.prevBlocks &&
                        createBlockUndoRedoInfo.nextBlocks && {
                            createdBlocks: createBlockUndoRedoInfo.createdBlock,
                            prevBlocks: createBlockUndoRedoInfo.prevBlocks,
                            nextBlocks: createBlockUndoRedoInfo.nextBlocks
                        },
                    removed: removeBlockUndoRedoInfo.removedBlocks &&
                        removeBlockUndoRedoInfo.prevBlocks &&
                        removeBlockUndoRedoInfo.nextBlocks && {
                            removedBlocks: removeBlockUndoRedoInfo.removedBlocks,
                            prevBlocks: removeBlockUndoRedoInfo.prevBlocks,
                            nextBlocks: removeBlockUndoRedoInfo.nextBlocks
                        },
                    prev: { blocks: blocksUndoRedoInfo.prevBlocks, syncComponents: blocksUndoRedoInfo.prevSyncComponents },
                    next: { blocks: blocksUndoRedoInfo.nextBlocks, syncComponents: blocksUndoRedoInfo.nextSyncComponents }
                }
            })
        }
    })

    const onDragCancel = () => {
        reset()
    }

    const reset = () => {
        setActiveNode(undefined)
        setOverInfo(null)
    }

    const actions = useNodeToolbarActions({ pageId, rootPageId, stackId })

    const { onUpdateBlock } = useBlockActions(pageId, stackId)

    // 是否是选中的节点后代
    const isSelectedChildren = (id: string) => {
        if (selectedNodes.length === 0) {
            return false
        }

        const isLeaf = (children: BlockAbstract[]): boolean => {
            for (const node of children) {
                if (node.id === id) {
                    return true
                }

                const nestChildren = getBlockChildren(node)
                if (nestChildren && isLeaf(nestChildren)) {
                    return true
                }
            }

            return false
        }

        return selectedNodes.some(item => {
            const selectedBlock = findNormalOrSyncBlock(item, pageBlocks, syncComponents)
            if (!selectedBlock) {
                return false
            }

            const children = getBlockChildren(selectedBlock)
            if (!children) {
                return false
            }

            return isLeaf(children)
        })
    }

    return (
        <PageFieldBlocksProvider value={fieldBlocksWithDsId}>
            <Root>
                <PageSelector appId={appId} pageId={pageId} />

                <Divider my={8} />

                <ListWrapper tabIndex={-1} id={PAGE_LAYER_ID} ref={scrollRef}>
                    <List data-ignore-click-away>
                        <Item
                            data={{
                                id: 'page',
                                indent: 0,
                                name: pageName,
                                icon: PAGE_ICON_MAP[pageType],
                                description: dataSourceList.find(item => item.id === pageDsId)?.name || '',
                                collapsed: collapsedNodes.some(item => item.id === pageId && !item.scope),
                                collapsible: true
                            }}
                            onCollapseChange={v => {
                                setCollapsedNodes(s => {
                                    if (v) {
                                        return [...s, { id: pageId }]
                                    }
                                    return s.filter(node => node.id !== pageId)
                                })
                            }}
                            isSelected={asideType === AsideType.PAGE && selectedNodes.length === 0}
                            onClick={() => {
                                setPageStack(draft => {
                                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                                    if (stack) {
                                        stack.state.asideType = AsideType.PAGE
                                        stack.state.selectedNodes = undefined
                                    }
                                })
                            }}
                        />
                        <DndContext
                            sensors={sensors}
                            measuring={measuring}
                            collisionDetection={closestCenter}
                            onDragStart={onDragStart}
                            onDragMove={onDragMove}
                            onDragEnd={onDragEnd}
                            onDragCancel={onDragCancel}
                        >
                            <SortableContext disabled={disabledWithVersion} items={items} strategy={verticalListSortingStrategy}>
                                {flattedPageNodes.map(item => (
                                    <SortableItem
                                        key={item.scope ? `${item.scope}@${item.id}` : item.id}
                                        disabled={item.block?.type === BlockType.floatBox}
                                        isSelected={selectedNodes.some(node => node.id === item.id && node.scope === item.scope)}
                                        isSelectedChildren={isSelectedChildren(item.id)}
                                        data={{
                                            ...item,
                                            indent:
                                                activeNode && activeNode.id === item.id && activeNode.scope === item.scope && overInfo
                                                    ? overInfo.indent
                                                    : item.indent
                                        }}
                                        onNameChange={v =>
                                            item.block &&
                                            onUpdateBlock({
                                                nextBlock: produce(item.block, draft => void (draft.title = v)),
                                                prevBlock: item.block
                                            })
                                        }
                                        onCollapseChange={v => {
                                            setCollapsedNodes(s => {
                                                if (v) {
                                                    return [...s, { id: item.id, scope: item.scope }]
                                                }
                                                return s.filter(node => !(node.id === item.id && node.scope === item.scope))
                                            })
                                        }}
                                        onDeleteItem={async () => {
                                            if (item.collapsible) {
                                                const isConfirm = await Modal.confirm({
                                                    title: '确认删除',
                                                    content: '删除后，该节点之后的其他节点也将一并删除，且不可恢复，请谨慎操作！'
                                                })
                                                if (!isConfirm) {
                                                    return
                                                }
                                            }
                                            actions.onRemove([{ id: item.id, scope: item.scope }])
                                        }}
                                        onClick={e => {
                                            const isShift = e.shiftKey

                                            // 多选
                                            if (isShift) {
                                                window.getSelection()?.removeAllRanges()

                                                setPageStack(draft => {
                                                    const stack = equalPageStack({ rootPageId, stackId })(draft)
                                                    if (stack) {
                                                        const currentSelectedNodes = stack.state.selectedNodes
                                                            ? current(stack.state.selectedNodes)
                                                            : []
                                                        let newSelectedNodes: NodeIdWithScope[] = []

                                                        if (
                                                            currentSelectedNodes.some(
                                                                node => node.id === item.id && node.scope === item.scope
                                                            )
                                                        ) {
                                                            newSelectedNodes = currentSelectedNodes.filter(
                                                                n => n.id !== item.id || n.scope !== item.scope
                                                            )
                                                        } else {
                                                            const flowNodes = transformBlock2FlowLayoutNode({
                                                                blocks: pageBlocks,
                                                                syncComponents,
                                                                blockRuntimeState,
                                                                previewType
                                                            })
                                                            const { parent, children } = findNewSelectParentAndChildren(
                                                                { id: item.id, scope: item.scope },
                                                                currentSelectedNodes,
                                                                flowNodes
                                                            )

                                                            newSelectedNodes = [...currentSelectedNodes]
                                                            // 如果有父级选中了，则取消选中父级
                                                            if (parent) {
                                                                newSelectedNodes = currentSelectedNodes.filter(
                                                                    item => item.scope !== parent.scope || item.id !== parent.id
                                                                )
                                                            }

                                                            // 如果有子级选中了，则取消选中子级
                                                            if (children.length > 0) {
                                                                newSelectedNodes = currentSelectedNodes.filter(
                                                                    item =>
                                                                        !children.some(
                                                                            child => child.scope === item.scope && child.id === item.id
                                                                        )
                                                                )
                                                            }

                                                            newSelectedNodes = [...newSelectedNodes, { id: item.id, scope: item.scope }]
                                                        }

                                                        stack.state.asideType =
                                                            newSelectedNodes.length === 1 ? AsideType.BLOCK : AsideType.PAGE
                                                        stack.state.selectedNodes = newSelectedNodes
                                                    }
                                                })

                                                return
                                            }

                                            // 依次打开上级浮层
                                            const floatBoxParentList = findAllParentBlocksByType({
                                                id: item?.id,
                                                blocks: pageBlocks,
                                                syncComponents,
                                                blockRuntimeState,
                                                filter: block => block.type === BlockType.floatBox
                                            })

                                            const parentFloatBlocks = (
                                                item.block?.type === BlockType.floatBox
                                                    ? [...floatBoxParentList, item.block]
                                                    : floatBoxParentList
                                            ).toReversed()
                                            const floatBoxNodeList = reduce<FlattedNode, FlattedNode[]>(
                                                (p, c) => {
                                                    const isExit = parentFloatBlocks
                                                        .toReversed()
                                                        .some(v => v.id === c.id && (c.scope ? c.scope === item.scope : true))
                                                    return isExit ? [...p, c] : p
                                                },
                                                [],
                                                flattedPageNodes
                                            )
                                            if (floatBoxNodeList.length > 0) {
                                                setPageStackOfFloatBlock(
                                                    floatBoxNodeList.map(node => ({
                                                        blockId: node.id,
                                                        stackId,
                                                        scope: node.scope,
                                                        open: true
                                                    }))
                                                )
                                            }

                                            // 选中block
                                            selectedNodeRef.current = { id: item.id, scope: item.scope }
                                            setPageStack(draft => {
                                                const stack = equalPageStack({ rootPageId, stackId })(draft)
                                                if (stack) {
                                                    stack.state.asideType = AsideType.BLOCK
                                                    stack.state.selectedNodes = [{ id: item.id, scope: item.scope }]
                                                }
                                                // scroll2FlowNode(item.id)
                                            })
                                        }}
                                    />
                                ))}

                                {createPortal(
                                    <DragOverlay modifiers={[adjustTranslate]} style={{ opacity: 0.8 }}>
                                        {activeFlattedNode && <Item key="overlay" data={activeFlattedNode} isOverlay />}
                                    </DragOverlay>,
                                    document.body
                                )}
                            </SortableContext>
                        </DndContext>
                    </List>
                </ListWrapper>
            </Root>
        </PageFieldBlocksProvider>
    )
}
