import type { CascadeOption } from '@byecode/ui'
import type {
    AggregationValue,
    AppUser,
    BoolValue,
    Brand,
    DataSourceAbstract,
    DataSourceBase,
    DateValue,
    Field,
    FieldADTValue,
    FieldCellValue,
    FieldType,
    FileType,
    FileValue,
    FormulaValue,
    NumberValue,
    RecordLikeProtocol,
    RichTextContentProtocol,
    SchemaProtocol,
    SelectValue,
    SimpleTextValue,
    TableColumns,
    TextValue,
    VariableADTvalue,
    VariableFieldADTValue,
    VideoSourceProtocol,
    ViewField
} from '@lighthouse/core'
import { DataSourceType, VariableType } from '@lighthouse/core'
import { queryParamUrl } from '@lighthouse/tools'
import type { JSONContent } from '@tiptap/core'
import { addDays, formatDistanceToNowStrict, lightFormat } from 'date-fns'
import zhCN from 'date-fns/locale/zh-CN/index'
import { find, isEmpty } from 'rambda'

import type { CascadeSelectOption } from '../components'
import { AggregationInnerTypeToFieldType } from '../components'
import { generateText } from '../components/RichText/helper'
import { aliyunVideoReg, BRAND_INFO_MAP, FILE_EXTENSION_TO_TYPE, fileSuffixRegex, PERSON_ID, PERSON_MAP_NAME } from '../constants'
import { PRESET_PALETTES } from '../constants/color'
import type { AppDepartment, AppRole, CacheValueWidthExpires } from '../types'
import { getRealFieldByInnerType } from './field'

/**
 * 用于实现细化类型
 */
const M = Symbol('https://www.thenewwiki.com/wiki/Refinement_type')

type OptionValue = { value: string }

type DefaultViewField = Partial<ViewField> & {
    fieldId: string
    title: string
    customTitle?: string
    type: FieldType
}

type getViewColumnsParams = {
    blockId?: string
    tableProps: TableColumns
    value?: ViewField[]
    schema: SchemaProtocol['schema']
    disabledFieldName?: boolean
    tableColumn?: Record<string, number>
}

type ThumbnailImageOptions = {
    width?: number
    height?: number
    quality?: number
}

// type DataSourceOptionConfig = {
//     limit?: DataSourceType[]
//     iconRenderString?: boolean
// }

const OSSThumbnailMap: Record<string, string> = {
    width: 'w',
    height: 'h',
    quality: 'q'
}

export type Nominal<Name extends string, Type> = Type & {
    readonly [M]: [Name]
}

/**
 * 将基础类型转换为细化类型
 */
export const make = <T>(value: Omit<T, typeof M>) => value as T

export function isTextValue(data?: FieldCellValue | null): data is TextValue {
    if (!data) {
        return false
    }
    return typeof data === 'string'
}

export function isStringArray(value: unknown): value is string[] {
    return Array.isArray(value) && value.every(item => typeof item === 'string')
}

export function isArrayCellValue(data?: FieldCellValue): data is FieldCellValue[] {
    if (!data) {
        return false
    }
    return Array.isArray(data)
}

export function getOSSThumbnailImageUrl(url: string, option?: ThumbnailImageOptions) {
    const fileType = getFileOriginTypeByUrl(url)
    const isNotSupportThumbnail = fileType === 'svg' || fileType === 'gif'
    // svg，gif 不需要处理缩略逻辑
    if (isNotSupportThumbnail) {
        return url
    }
    if (!option || isEmpty(option)) {
        return url
    }
    const defaultUrl = url.includes('?') ? `${url}&x-oss-process=image/resize,m_lfit` : `${url}?x-oss-process=image/resize,m_lfit`
    return Object.entries(option).reduce<string>((prev, [key, value]) => {
        if (!OSSThumbnailMap[key]) {
            return prev
        }
        prev += `,${OSSThumbnailMap[key]}_${value}`
        return prev
    }, defaultUrl)
}

export function isNumberValue(data?: FieldCellValue): data is NumberValue {
    if (!data && data !== 0 && !Number(data)) {
        return false
    }
    return typeof data === 'number' || typeof data === 'string'
}

export function isDateValue(data?: FieldCellValue): data is DateValue {
    return typeof data === 'number' && !Number.isNaN(data)
}

export function isSimpleValue(data?: FieldCellValue): data is SimpleTextValue | SimpleTextValue[] {
    if (!data) {
        return false
    }
    if (Array.isArray(data)) {
        return data.length > 0 && typeof data[0] === 'string'
    }
    return typeof data === 'string'
}

export function isFormulaValue(data?: FieldCellValue): data is FormulaValue {
    return Array.isArray(data) || typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean'
}

export function isAggregationValue(data?: FieldCellValue): data is AggregationValue {
    return Array.isArray(data) || typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean'
}

export function isNumberListValue(data?: FieldCellValue): data is NumberValue | NumberValue[] {
    if (!data && data !== 0) {
        return false
    }
    if (Array.isArray(data)) {
        return data.length > 0 && (typeof data[0] === 'string' || typeof data[0] === 'number')
    }
    // return typeof data === 'number' || Array.isArray(data)
    return typeof data === 'number' || typeof data === 'string'
}

export function isDateListValue(data?: FieldCellValue): data is DateValue | DateValue[] {
    if (!data && data !== 0) {
        return false
    }

    if (Array.isArray(data)) {
        return data.length > 0 && typeof data[0] === 'number'
    }

    return typeof data === 'number' || typeof data === 'string'
}

export function isCheckboxValue(data?: FieldCellValue): data is BoolValue {
    if (Array.isArray(data)) {
        return data.length > 0 && typeof data[0] === 'boolean'
    }
    return typeof data === 'boolean'
}

export function isIdsValue(data?: FieldCellValue | null): data is SelectValue {
    if (!data) {
        return false
    }
    return !!(Array.isArray(data) && data.length > 0 && typeof data[0] === 'string')
}

export function isFileValue(data?: FieldCellValue | null): data is FileValue {
    if (!data) {
        return false
    }
    return !!(Array.isArray(data) && data.length > 0 && typeof data[0] === 'string')
}

export function isRichTextValue(data?: FieldCellValue | null): data is RichTextContentProtocol {
    if (typeof data === 'object') {
        return !!data && !isEmpty(data)
    }
    return !!data
}

export function isIntValue(value?: unknown): value is number {
    return Number.isInteger(value)
}

export function isIntVariableValue(data?: VariableADTvalue): boolean {
    if (!data) {
        return false
    }
    if (data.type === VariableType.VALUE) {
        if (!data.valueVariable?.value && data.valueVariable?.value !== 0) {
            return false
        }
        const val = Number(data.valueVariable?.value)
        return Number.isInteger(val)
    }
    return true
}

// export function compareSizeVariableValue(firstValue: VariableADTvalue, secondValue: VariableADTvalue ): boolean {
//     if(!firstValue || !secondValue){
//         return false
//     }
//     if(firstValue.type === VariableType.VALUE && secondValue.type === VariableType.VALUE && isIntVariableValue(firstValue) && isIntVariableValue(secondValue)){
//         const firstVal = firstValue.valueVariable?.value as number
//         const secondVal = firstValue.valueVariable?.value  as number
//         return firstVal <= secondVal
//     }
//     return true
// }

export const getTableIcon = (dataSource?: Pick<DataSourceBase, 'type' | 'sync'>) => {
    if (!dataSource) {
        return 'BigTable'
    }
    const { type, sync } = dataSource
    switch (type) {
        // case DataSourceType.bigDataSource: {
        //     if (sync) {
        //         return 'SynDatabase'
        //     }
        //     return 'DataSource'
        // }
        case DataSourceType.systemDataSource: {
            return 'UserTable'
        }
        // case DataSourceType.oldDataSource: {
        //     if (sync) {
        //         return 'SyncTable'
        //     }
        //     return 'BlockTable'
        // }
        case DataSourceType.dataSource: {
            if (sync) {
                return 'SyncNewTable'
            }
            return 'NewTable'
        }
        case DataSourceType.joinDataSource: {
            return 'RelationQuery'
        }
        case DataSourceType.aggregateDataSource: {
            if (sync) {
                return 'AggregateTableSync'
            }
            return 'AggregateTable'
        }

        default: {
            return 'BigTable'
        }
    }
}

export const getTableIconColor = (dataSource?: DataSourceBase) => {
    if (!dataSource) {
        return 'BigTable'
    }
    return 'var(--color-gray-400)'
    // const { type, sync } = dataSource
    // switch (type) {
    //     case DataSourceType.dataSource: {
    //         return 'var(--color-gray-500)'
    //     }
    //     case DataSourceType.systemDataSource: {
    //         return 'var(--color-purple-500)'
    //     }
    //     case DataSourceType.joinDataSource: {
    //         return 'var(--color-green-500)'
    //     }
    //     case DataSourceType.aggregateDataSource: {
    //         return 'var(--color-purple-500)'
    //     }

    //     default: {
    //         return 'BigTable'
    //     }
    // }
}

export const getDsColumns = (dataSource: DataSourceAbstract) => {
    const { schema, viewOptions: { tableProps } } = dataSource
    const newTableProps = tableProps.reduce<Field[]>((list, cur) => {
        const field = schema[cur.id]
        if (field) {
            list.push(field)
        }
        return list
    }, [])

    return Object.values(schema).reduce((prev, cur) => {
        const field = find(item => item.id === cur.id, prev)
        if (!field) {
            prev.push(cur)
        }
        return prev
    }, newTableProps)
}

export const getOriginallyViewColumns = (params: getViewColumnsParams) => {
    const { blockId, tableProps, value, schema, tableColumn, disabledFieldName } = params
    if (isEmpty(value) || !value) {
        return tableProps
            .reduce<[ViewField[], ViewField[]]>(
                (prev, cur) => {
                    const { id, visible } = cur
                    const field = schema?.[id]
                    if (field) {
                        const width = blockId && tableColumn ? Number(tableColumn?.[id] || 160) : 160
                        prev[visible ? 0 : 1].push(
                            getDefaultViewField({
                                ...field,
                                fieldId: id,
                                title: disabledFieldName ? '' : field.name,
                                type: field.type,
                                width,
                                visible
                            } as DefaultViewField)
                        )
                    }
                    return prev
                },
                [[], []]
            )
            .flat()
    }
    return value
        .reduce<[ViewField[], ViewField[]]>(
            (prev, cur) => {
                const { fieldId, title, visible } = cur
                const field = schema?.[fieldId]
                const width = Number(tableColumn?.[fieldId] || 160)
                if (field) {
                    prev[visible ? 0 : 1].push(
                        // 2023-09-27 18:48:12 视图需要去掉自己的配置，后面重构下
                        getDefaultViewField({
                            ...cur,
                            title: disabledFieldName ? title : (title || field.name),
                            width,
                            ...field
                        } as DefaultViewField)
                    )
                }
                return prev
            },
            [[], []]
        )
        .flat()
}

export const getViewColumns = (params: getViewColumnsParams) => {
    const { schema, tableColumn, disabledFieldName } = params
    const initList: ViewField[] = getOriginallyViewColumns(params)
    return Object.values(schema).reduce<ViewField[]>((prev, cur) => {
        const field = find(item => item.fieldId === cur.id, prev || [])
        if (!field) {
            prev.push(
                getDefaultViewField({
                    ...cur,
                    fieldId: cur.id,
                    title: disabledFieldName ? '' : cur.name,
                    type: cur.type,
                    width: tableColumn?.[cur.id] || 160
                } as DefaultViewField)
            )
        }
        return prev
    }, initList)
}

export const getDefaultViewField: (params: DefaultViewField) => ViewField = params => {
    const { fieldId, type, width = 160, title, visible = false, ...rest } = params
    const baseField = {
        type,
        title,
        fieldId,
        visible,
        width,
        required: false,
        ...rest
    }
    switch (baseField.type) {
        case 'id':
        case 'url':
        case 'phoneNumber':
        case 'email':
        case 'file':
        case 'video':
        case 'person':
        case 'select':
        case 'notes':
        case 'text':
        case 'formula':
        case 'department':
        case 'role': {
            return baseField as ViewField
        }
        case 'number': {
            return {
                number: {
                    accuracy: 2,
                    prefix: '',
                    suffix: ''
                },
                ...baseField
            } as ViewField
        }
        case 'date': {
            return {
                date: {
                    format: 'yyyy/MM/dd HH:mm'
                },
                ...baseField
            } as ViewField
        }
        case 'checkbox': {
            return {
                checkbox: {
                    icon: '',
                    color: ''
                },
                ...baseField
            } as ViewField
        }

        default: {
            return baseField as ViewField
        }
    }
}

export const getFileNameByUrl = (filePath: string) => {
    // 使用正则表达式匹配文件名部分
    if (!filePath.includes('/')) {
        return filePath.split('?')[0]
    }
    const matcher = /[/\\]([^/?\\]+)(?=\?|$)/
    const match = matcher.exec(filePath)
    // 如果匹配成功，返回捕获的文件名，否则返回undefined
    return match ? match[1] : undefined
}

export const getFileSizeByUrl = (filePath: string) => {
    return Number(queryParamUrl(filePath, 'size'))
}

export const getFileTypeByFileName = (fileName: string, fieldId?: string): FileType => {

    const lowerCaseHostFileName = fileName.toLocaleLowerCase()
    const fileType = lowerCaseHostFileName.split('.')?.[1]
    if (!fileType) {
        return 'other'
    }
    return FILE_EXTENSION_TO_TYPE[fileType]
}

/**
 *
 * @param hostFileType
 * @returns
 */
export const getFileOriginTypeByUrl = (hostFileType: string): string | undefined => {
    const lowerCaseHostFileType = hostFileType.toLocaleLowerCase()
    return fileSuffixRegex.exec(lowerCaseHostFileType)?.[1]
}

/**
 *
 * @param hostFileType
 * @returns
 */
export const getFileTypeByUrl = (fileUrl: string): FileType => {
    if (fileUrl.includes('.qlogo.')) {
        return 'image'
    }
    const lowerCaseHostFileType = fileUrl.toLocaleLowerCase()
    const extension = fileSuffixRegex.exec(lowerCaseHostFileType)?.[1]
    if (!extension) {
        return 'other'
    }
    const type = FILE_EXTENSION_TO_TYPE[extension]
    if (!type) {
        return 'other'
    }
    return type
}

export const DATE_FORMAT = 'yyyy/MM/dd HH:mm:ss'
// export function getFormulaType(innerType: TypeInstanceMap): string {
//     return innerType
// }

export const scientificNotationToString = (num?: number | string) => {
    if (Number.isNaN(Number(num))) {
        return ''
    }
    if (!num && num !== 0) {
        return ''
    }
    return Number(num).toLocaleString('fullwide', { useGrouping: false, maximumFractionDigits: 8 })
}

export function getColorIndex(str: string) {
    const pattern = /\d+/g
    const result = str.match(pattern)
    const numStr =
        result?.reduce((prev, cur) => {
            prev += cur
            return prev
        }, '') || '0'
    const num = Number(numStr[numStr.length - 1]) || 0

    if (num === 4) {
        return Number(10)
    }
    if (num === 5) {
        return Number(11)
    }
    return Number(num)
}

export const getColorById = (id: string) => {
    const colorList = Object.entries(PRESET_PALETTES)
    const index = getColorIndex(id ?? '') ?? 1
    return colorList[index]?.[1] || ''
}

// 用于视图转换显示的值

export interface FieldConvertValueOption {
    /** 使用文件地址, 默认使用文件名 */
    useFileUrl?: boolean
    /** 成员配置 */
    personOptions?: AppUser[]
    /** 角色配置 */
    roleOptions?: AppRole[]
    /** 部门配置 */
    departmentOptions?: AppDepartment[]
    /** 数字配置 */
    isReallyNumber?: boolean
    /** 指定日期格式 */
    format?: string
}
// eslint-disable-next-line sonarjs/cognitive-complexity
export function fieldConvertValue(fieldValue: FieldADTValue, option?: FieldConvertValueOption): string {
    const { useFileUrl, personOptions, roleOptions, departmentOptions, isReallyNumber } = option || {}

    switch (fieldValue.type) {
        case 'number': {
            const {
                number: { suffix = '', prefix = '', accuracy },
                value
            } = fieldValue
            if (Array.isArray(value)) {
                return value
                    .map(item => {
                        if (item !== 0 && !item) {
                            return ''
                        }
                        const str = scientificNotationToString(item)
                        const reTainNum = accuracy ? Number(str).toFixed(accuracy) : str
                        return isReallyNumber ? reTainNum : `${prefix} ${reTainNum} ${suffix}`.trim()
                    })
                    .join(',')
            }

            if (value !== 0 && !value) {
                return ''
            }
            const str = scientificNotationToString(value)
            const reTainNum = accuracy ? Number(str).toFixed(accuracy) : str
            return isReallyNumber ? reTainNum : `${prefix} ${reTainNum} ${suffix}`.trim()
        }
        case 'notes': {
            const { value: notesValue } = fieldValue

            if (isEmpty(notesValue) || Array.isArray(notesValue)) {
                return ''
            }
            return typeof notesValue === 'string' ? notesValue : generateText(notesValue)
        }
        case 'textGeneration':
        case 'text':
        case 'id':
        case 'email':
        case 'phoneNumber':
        case 'url': {
            if (Array.isArray(fieldValue.value)) {
                return fieldValue.value.join(',')
            }
            return fieldValue.value || ''
        }
        case 'person': {
            const { value } = fieldValue
            if (!Array.isArray(value)) {
                return ''
            }
            const ensuredPersonVal = value || []
            if (!personOptions) {
                return ensuredPersonVal.join(',')
            }

            const personVal = ensuredPersonVal.map(id => {
                if (PERSON_ID.has(id)) {
                    return PERSON_MAP_NAME[id]
                }
                return find(person => person.userId === id, personOptions)?.username ?? '匿名'
            })
            return personVal.join(',')
        }
        case 'role': {
            const { value } = fieldValue
            if (!Array.isArray(value)) {
                return ''
            }
            const ensuredRoleVal = value || []
            if (!roleOptions) {
                return ensuredRoleVal.join(',')
            }
            const roleVal = ensuredRoleVal
                .map(id => {
                    if (PERSON_ID.has(id)) {
                        return PERSON_MAP_NAME[id]
                    }
                    return find(role => role.id === id, roleOptions)?.name
                })
                .filter(Boolean)
            return roleVal.join(',')
        }
        case 'parentDepartment':
        case 'department': {
            const { value } = fieldValue
            if (!Array.isArray(value)) {
                return ''
            }
            const ensuredDepartmentVal = value || []
            if (!departmentOptions) {
                return ensuredDepartmentVal.join(',')
            }
            const departmentVal = ensuredDepartmentVal
                .map(id => {
                    if (PERSON_ID.has(id)) {
                        return PERSON_MAP_NAME[id]
                    }
                    return find(role => role.id === id, departmentOptions)?.name
                })
                .filter(Boolean)
            return departmentVal.join(',')
        }
        case 'selectGenerationByText':
        case 'singleSelect':
        case 'select': {
            const { value } = fieldValue
            if (!Array.isArray(value)) {
                return ''
            }
            const arr = value || []
            return arr?.join(',')
        }
        case 'date': {
            const {
                date: { format = DATE_FORMAT },
                value
            } = fieldValue
            if (Array.isArray(value)) {
                return value
                    .map(item => (item ? lightFormat(Number(item), format) : ''))
                    .filter(Boolean)
                    .join(',')
            }
            return value ? lightFormat(Number(value), format) : ''
        }
        case 'video':
        case 'file': {
            const { value: files = [] } = fieldValue
            if (isEmpty(files) || !Array.isArray(files)) {
                return ''
            }
            if (useFileUrl) {
                return files.join(',')
            }
            return files
                .map(item => getFileNameByUrl(item))
                .filter(Boolean)
                .join(',')
        }
        case 'formula': {
            const { value, ...field } = fieldValue
            const realField = getRealFieldByInnerType(field, AggregationInnerTypeToFieldType)
            const realFieldValue = {
                ...realField,
                value
            } as FieldADTValue
            if (realFieldValue.type === 'formula') {
                return String(realFieldValue.value)
            }
            return fieldConvertValue(realFieldValue, { personOptions, roleOptions, departmentOptions })
        }

        case 'checkbox': {
            const { value } = fieldValue
            if (Array.isArray(value)) {
                return value.map(item => (item ? 'true' : 'false')).join(',')
            }
            return value ? 'true' : 'false'
        }

        case 'aggregation': {
            const { value, innerType } = fieldValue
            if (Array.isArray(value)) {
                return value.join(',')
            }
            if (innerType === 'DATE') {
                return typeof value === 'number' ? lightFormat(value, DATE_FORMAT) : ''
            }
            if (innerType === 'NUMBER') {
                return typeof value === 'number' ? String(value) : ''
            }
            return value ? String(value) : ''
        }

        default: {
            return ''
        }
    }
}

/**
 * 变量选择器field变量解析
 * @param fieldValue
 * @returns {string}
 */
// eslint-disable-next-line sonarjs/cognitive-complexity
export function variableFieldConvertValue(fieldValue: VariableFieldADTValue, option?: FieldConvertValueOption) {
    const { useFileUrl, personOptions, roleOptions, departmentOptions } = option ?? {}

    switch (fieldValue.type) {
        case 'number': {
            const { value } = fieldValue

            if (value !== 0 && !value) {
                return ''
            }
            return scientificNotationToString(value)
        }
        case 'textGeneration':
        case 'text':
        case 'id':
        case 'email':
        case 'phoneNumber':
        case 'url': {
            if (Array.isArray(fieldValue.value)) {
                return fieldValue.value.join(',')
            }
            return fieldValue.value || ''
        }
        case 'person': {
            const { value } = fieldValue
            if (!Array.isArray(value)) {
                return ''
            }
            const ensuredPersonVal = value || []
            if (!personOptions) {
                return ensuredPersonVal.join(',')
            }

            const personVal = ensuredPersonVal.map(id => {
                if (PERSON_ID.has(id)) {
                    return PERSON_MAP_NAME[id]
                }
                return find(person => person.userId === id, personOptions)?.username ?? '匿名'
            })
            return personVal.join(',')
        }
        case 'role': {
            const { value } = fieldValue
            if (!Array.isArray(value)) {
                return ''
            }
            const ensuredRoleVal = value || []
            if (!roleOptions) {
                return ensuredRoleVal.join(',')
            }
            const roleVal = ensuredRoleVal
                .map(id => {
                    if (PERSON_ID.has(id)) {
                        return PERSON_MAP_NAME[id]
                    }
                    return find(role => role.id === id, roleOptions)?.name
                })
                .filter(Boolean)
            return roleVal.join(',')
        }
        case 'parentDepartment':
        case 'department': {
            const { value } = fieldValue
            if (!Array.isArray(value)) {
                return ''
            }
            const ensuredDepartmentVal = value || []
            if (!departmentOptions) {
                return ensuredDepartmentVal.join(',')
            }
            const departmentVal = ensuredDepartmentVal
                .map(id => {
                    if (PERSON_ID.has(id)) {
                        return PERSON_MAP_NAME[id]
                    }
                    return find(role => role.id === id, departmentOptions)?.name
                })
                .filter(Boolean)
            return departmentVal.join(',')
        }
        case 'selectGenerationByText':
        case 'singleSelect':
        case 'select': {
            const { value } = fieldValue
            if (!Array.isArray(value)) {
                return ''
            }
            const arr = value || []
            return arr.join(',')
        }
        case 'date': {
            const { value } = fieldValue
            const format = 'yyyy-MM-dd HH:mm:ss'
            if (Array.isArray(value)) {
                return value
                    .map(item => (item ? lightFormat(Number(item), format) : ''))
                    .filter(Boolean)
                    .join(',')
            }
            return value ? lightFormat(Number(value), format) : ''
        }
        case 'video':
        case 'file': {
            const { value: files = [] } = fieldValue
            if (isEmpty(files) || !Array.isArray(files)) {
                return ''
            }
            if (useFileUrl) {
                return files.join(',')
            }
            return files
                .map(item => getFileNameByUrl(item))
                .filter(Boolean)
                .join(',')
        }

        case 'checkbox': {
            const { value } = fieldValue
            if (Array.isArray(value)) {
                return value.map(item => (item ? 'true' : 'false')).join(',')
            }
            return value ? 'true' : 'false'
        }
        case 'aggregation': {
            const { value } = fieldValue
            if (Array.isArray(value)) {
                return value.join(',')
            }
            return value ? String(value) : ''
        }

        default: {
            return ''
        }
    }
}

export const getTreeOptionLastLevel = (options: CascadeSelectOption[], level = 0): number | undefined => {
    const option = options.find(item => !!item)
    if (!option) {
        return
    }
    if (option.children) {
        return getTreeOptionLastLevel(option.children, level + 1)
    }

    return level
}

// export function addOption(itemData: RecordLikeProtocol, i: number, options: CascadeSelectOption[], level: string[]) {
//     if (i + 1 > level.length) {
//         return undefined
//     }
//     const field = itemData.content?.[level[i]]
//     if (!field) {
//         return options
//     }
//     const title = (field as OptionValue)?.value || '-'
//     const findItem = find(option => option.label === title, options || [])
//     if (findItem) {
//         const childrenData = addOption(itemData, i + 1, findItem.children || [], level)
//         if (childrenData) {
//             findItem.children = childrenData
//         }
//         return options
//     }
//     const data: CascadeSelectOption = {
//         value: itemData.id,
//         label: title,
//         level: i + 1
//     }
//     const childrenData = addOption(itemData, i + 1, data.children || [], level)
//     if (childrenData) {
//         data.children = childrenData
//     }
//     options?.push(data)
//     return options
// }

export const getPreviewValue = (expressions: JSONContent[] | undefined) => {
    if (!expressions) {
        return ''
    }
    return expressions.reduce((prev, cur) => {
        const name: string = cur?.attrs?.name
        if (cur.type === 'field' && name) {
            prev += `${name}`
        }
        if (cur.type === 'text') {
            prev += cur.text
        }
        if (cur.content) {
            prev += getPreviewValue(cur.content)
        }
        return prev
    }, '')
}

export function timeDistance(date: number) {
    return Date.now() - date > 60_000
        ? formatDistanceToNowStrict(date, {
            addSuffix: true,
            locale: zhCN
        })
        : '刚刚'
}

export function getFileSizeToMB(size: number) {
    return size / 1024 / 1024
}

export function getJumpUrl(url: string) {
    const urlReg = new RegExp(/^(http(s?):\/\/)/g)
    if (urlReg.test(url)) {
        return url
    }
    return `http://${url}`
}

export function getBrand(): Brand {
    if (window.location.host.includes('iclick')) {
        return 'I_CLICK'
    }
    return 'BYE_CODE'
}

export function getBrandInfo() {
    return BRAND_INFO_MAP[getBrand()]
}

export function getMainTableRecordId(recordId?: string) {
    if (!recordId) {
        return ''
    }
    return recordId?.includes('__') ? recordId.split('__')[0] : recordId
}

interface IpInfo {
    status: string // IP信息获取状态
    country: string // 国家
    countryCode: string // 国家代码
    region: string // 地区代码
    regionName: string // 地区名称
    city: string // 城市
    zip: string // 邮政编码
    lat: number // 纬度
    lon: number // 经度
    timezone: string // 时区
    isp: string // 互联网服务提供商
    org: string // 组织
    as: string // 自治系统
    query: string // 查询的IP地址
}

export async function getIpInfo(): Promise<IpInfo> {
    const res = await fetch('http://ip-api.com/json/?lang=zh-CN')
    return res.json()
}

export function getVideoSource(source: string): VideoSourceProtocol {
    // https://v.youku.com/v_show/id_XNjM3NjY3MzYwNA==.html?spm=a2hja.14919748_WEBHOME_NEW.app.5~5!2~5!9~A&scm=20140719.manual.29071.video_XNjM3NjY3MzYwNA==_预览
    const isYouku = source.includes('v.youku.com')
    // https://www.bilibili.com/video/BV1Cw411u7v6/?share_source=copy_web&vd_source=4aeb2eba6a127e74aea4dcd3d371ee27
    const isBili = source.includes('bilibili.com')
    // https://youtu.be/me2hM26rktw?si=2gDpLf6HIIjtCQdG
    const isYoutube = source.includes('youtu.be') || source.includes('youtube.com')
    // https://byecode-userdata-prod.oss-cn-shanghai.aliyuncs.com/userdata/1b4af5d3d37242dea7d7665a31884366/91f08aa9e3c148f2b85908208ccd58ab/n4AhVM8x66014bfahJQR78iTJqRlKqJ8/15e0133bbd7440e583800a0abfe359b8/1231371504-1-16.mp4?size=2483490
    // https://psi-lighthouse.oss-cn-shanghai.aliyuncs.com/userdata/4eb4d5f3ce044cdfa4b074b535ae8688/9fa450a5fa7740fb898cda324645740e/MMYMWNWdL661e3f2e2OU6x9F3AtOThr9/4b336e48b47b4d30be0ff69d3319388e/1231371504-1-16.mp4?size=2483490
    const isByecode = source.includes('lighthouse') || source.includes('byecode-userdata')
    // 10a4f660fbd071ee80294531858c0102/1231371504-1-16.mp4?size=2483490
    const isAliyun = aliyunVideoReg.test(source)
    if (isYouku) {
        return 'youku'
    }
    if (isBili) {
        return 'bilibili'
    }
    if (isByecode) {
        return 'byecode'
    }
    if (isYoutube) {
        return 'youtube'
    }
    if (isAliyun) {
        return 'aliyun'
    }
    // 10a4f660fbd071ee80294531858c0102/1231371504-1-16.mp4?size=2483490
    return 'other'
}

export function getWindowUrl() {
    return window.location.toString()
}

export function checkIsInPwa() {
    return (
        window.matchMedia('(display-mode: standalone)').matches ||
        // @ts-expect-error navigator has standalone property
        window.navigator.standalone ||
        document.referrer.includes('android-app://')
    )
}

export function getCascadeOption(params: {
    fieldPointer: string
    showFieldPointer: string
    parentFieldPointer: string
    record: RecordLikeProtocol
    records: RecordLikeProtocol[]
    disableId?: string
}): CascadeOption {
    const { fieldPointer, parentFieldPointer, record, records, showFieldPointer, disableId } = params
    const idCellValue = record?.content?.[fieldPointer]?.value ?? ''
    const labelValue = record?.content?.[showFieldPointer]?.value ?? ''
    const label = isTextValue(labelValue) ? labelValue : ''
    const value = isTextValue(idCellValue) ? idCellValue : ''
    const childList =
        records.filter(r => {
            const cellValue = r?.content?.[parentFieldPointer]?.value
            return isStringArray(cellValue) ? cellValue[0] === value : cellValue === value
        }) ?? []
    return {
        label,
        value,
        disabled: value === disableId,
        children: childList.map(childRecord =>
            getCascadeOption({
                fieldPointer,
                parentFieldPointer,
                showFieldPointer,
                records,
                record: childRecord,
                disableId: value === disableId ? childRecord?.content?.[fieldPointer]?.value?.toString() : disableId
            })
        )
    }
}

// 获取7天后的时间戳
export function getTimestampAboutWeekAfter() {
    const currentDate = new Date() // 获取当前时间
    const futureDate = addDays(currentDate, 7) // 当前时间加7天
    return futureDate.getTime()
}

/**
 * 从数据中获取值并更新过期时间
 * @export
 * @template T
 * @param {string} key
 * @param {T} defaultData
 * @return {*}  {T}
 */
export function getValueUpdateExpires<T>(data: CacheValueWidthExpires<T>): CacheValueWidthExpires<T> {
    data.expires = getTimestampAboutWeekAfter()
    return data
}
