import { IconFont, Select } from '@byecode/ui'
import styled from 'styled-components'

export const Description = styled.div`
    margin-top: 6px;
    color: var(--color-gray-400);
    font-size: var(--font-size-sm);
    font-weight: 400;
    line-height: 18px;
`

export const Text = styled.span<{ size?: number; color?: string }>`
    font-size: ${({ size }) => size ?? 14}px;
    color: ${({ color }) => color ?? 'var(--color-black)'};
`

export const Item = styled.div`
    width: 100%;
    /* padding: 12px 16px 0 16px; */

    .mantine-Input-wrapper {
        margin-top: 12px;
    }
`

export const Icon = styled(IconFont)<{ size?: number }>`
    font-size: ${({ size }) => size ?? '16'}px;
`

export const SelectItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 16px; */
`
export const SelectContainer = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-end;
`
export const LeftFill = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`
export const RightFill = styled.div`
    align-items: center;
    justify-content: flex-end;
`

export const ManTinSelect = styled(Select)`
    [data-selected] {
        background-color: var(--color-gray-200);
    }
    [data-hovered] {
        background-color: var(--color-gray-200) !important;
    }
`
