import React, { useMemo } from 'react'
import styled from 'styled-components'

interface YoukuPlayerProps {
    source?: string
    autoplay?: boolean
}

const regex = /id_([A-Za-z0-9=]+)\.html/u

const SCxIframe = styled.iframe`
    pointer-events: auto;
    border: none;
    width: 100%;
    height: 100%;
`

export const YoukuPlayer: React.FunctionComponent<YoukuPlayerProps> = ({ source, autoplay }) => {
    const videoId = useMemo(() => {
        const match = source?.match(regex)
        return match ? match[1] : ''
    }, [source])

    return (
        // <iframe height="498" width="510" src='https://player.youku.com/embed/XNTE3MTQwMzY3Ng==' frameBorder="0" allowFullScreen />
        <SCxIframe
            sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
            allowFullScreen
            src={`https://player.youku.com/embed/${videoId}`}
            title=""
            // data-spm-anchor-id="a2q1e.22907394.0.i1.22adB9Y6B9Y6K8"
            // data-spm-act-id="a2q1e.22907394.0.i1.22adB9Y6B9Y6K8"
        >
            没有找到对应视频
        </SCxIframe>
    )
}
