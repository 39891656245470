import { Flex, IconFont, Menu, Popover, Text, tinyButtons } from '@byecode/ui'
import type { DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import { restrictToParentElement, restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { arrayMove, SortableContext, useSortable, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import type { ApplicationSettingTheme, ApplicationSettingThemeColor } from '@lighthouse/core'
import { PopoverDropdownWithClose, useAtomAction, useAtomData } from '@lighthouse/shared'
import { mergeRefs, nanoid, stopPropagation } from '@lighthouse/tools'
import chroma from 'chroma-js'
import classNames from 'classnames'
import produce from 'immer'
import type { CSSProperties } from 'react'
import React, { forwardRef, useCallback, useImperativeHandle, useRef, useState } from 'react'
import styled, { css } from 'styled-components'

import { updateAppVersionConfigAtom } from '@/atoms/application/action'
import { applicationSettingAtom } from '@/atoms/application/state'
import { ColorAdvanceConfigure } from '@/components/ColorAdvanceConfigure'

import * as SC from '../styles'

const StyledScroller = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    max-height: 600px;
    overflow: hidden auto;
    ${tinyButtons}
`

const StyledColorItem = styled.div<{ isDragging: boolean; isSorting: boolean }>`
    height: 36px;
    display: flex;
    gap: 8px;
    align-items: center;
    border-radius: 8px;
    padding: 8px;

    ${({ isDragging, isSorting }) => {
        if (isSorting) {
            if (isDragging) {
                return css`
                    background-color: var(--color-gray-100);
                `
            }
            return
        }

        return css`
            &:hover,
            &.active {
                background-color: var(--color-gray-100);
            }
        `
    }}
`
const StyledColorPreview = styled.div.withConfig<{ color: string }>({ shouldForwardProp: p => p !== 'color' })`
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: ${p => p.color};
    ${p =>
        chroma(p.color).luminance() > 0.8 &&
        css`
            border: 1px solid var(--color-gray-200);
        `}
`
const StyledInput = styled.input`
    appearance: none;
    width: 100%;
    height: 16px;
    line-height: 16px;
    flex: 1;
    margin: 0;
    padding: 0;
    font-size: 14px;
`

type ActionMethods = {
    rename: () => void
}

interface ColorItemProps extends React.ComponentPropsWithoutRef<'div'> {
    data: ApplicationSettingThemeColor
    onUpdateThemeColor: (value: ApplicationSettingThemeColor) => void
    isPrimary?: boolean
    actionRef?: React.MutableRefObject<ActionMethods | undefined>
}

const ColorItem = forwardRef<HTMLDivElement, ColorItemProps>(
    ({ isPrimary, data, className, actionRef, onUpdateThemeColor, ...rest }, ref) => {
        const { setNodeRef, listeners, isDragging, isSorting, transform, transition } = useSortable({
            id: data.id
        })

        const [isEditMode, setIsEditMode] = useState(false)

        const onEditorBlur = (e: React.FocusEvent<HTMLInputElement>) => {
            const { value } = e.currentTarget
            if (value === data.label) {
                return
            }
            onUpdateThemeColor({ ...data, label: value })

            setIsEditMode(false)
        }

        const onEditorKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                const { value } = e.currentTarget
                e.currentTarget.blur()

                if (value === data.label) {
                    return
                }
                onUpdateThemeColor({ ...data, label: value })
            }
        }

        useImperativeHandle(
            actionRef,
            () => {
                return {
                    rename: () => setIsEditMode(true)
                }
            },
            []
        )

        const style: CSSProperties = {
            transform: CSS.Transform.toString(transform),
            transition
        }

        return (
            <StyledColorItem
                isDragging={isDragging}
                isSorting={isSorting}
                ref={mergeRefs([ref, setNodeRef])}
                className={classNames(className, { active: isEditMode })}
                {...rest}
                role="button"
                onMouseDown={e => {
                    rest.onMouseDown?.(e)
                    listeners?.onMouseDown(e)
                }}
                onDoubleClick={() => setIsEditMode(true)}
                style={style}
            >
                <StyledColorPreview color={data.value} />
                {isEditMode ? (
                    <StyledInput
                        autoFocus
                        defaultValue={data.label}
                        onBlur={onEditorBlur}
                        onKeyDown={onEditorKeyDown}
                        onFocus={e => e.currentTarget.select()}
                    />
                ) : (
                    <Text size={14} lineHeight="16px" styles={{ root: { flex: 1, height: 16 } }}>
                        {data.label}
                    </Text>
                )}
                {isPrimary && <IconFont type="ThemeColor" size={16} />}
            </StyledColorItem>
        )
    }
)

interface WithMenuItemProps extends Omit<ColorItemProps, 'actionRef'> {
    onDelete: (id: string) => void
    onUpdatePrimaryPointer: (id: string) => void
}
const WithMenuItem = forwardRef<HTMLDivElement, WithMenuItemProps>((props, ref) => {
    const { onDelete, className, onUpdatePrimaryPointer, ...rest } = props
    const { isPrimary, data } = rest

    const actionRef = useRef<ActionMethods>()

    const [opened, setOpened] = useState(false)

    return (
        <Menu
            trigger="contextMenu"
            closeOnItemClick
            returnFocus={false}
            position="right-start"
            width={166}
            opened={opened}
            onChange={setOpened}
        >
            <Menu.Target ref={ref}>
                <ColorItem {...rest} className={classNames(className, { active: opened })} actionRef={actionRef} />
            </Menu.Target>
            <Menu.Dropdown>
                <Menu.Item
                    disabled={isPrimary}
                    icon={<IconFont type="ThemeColor" size={16} />}
                    onClick={() => onUpdatePrimaryPointer(data.id)}
                >
                    {isPrimary ? '已设为主题色' : '设为主题色'}
                </Menu.Item>
                <Menu.Item
                    icon={<IconFont type="NotePencil" size={16} color="var(--color-gray-400)" />}
                    onClick={() => {
                        actionRef.current?.rename()
                    }}
                >
                    重命名
                </Menu.Item>
                {isPrimary ? null : (
                    <Menu.Item icon={<IconFont type="Trash" size={16} color="var(--color-gray-400)" />} onClick={() => onDelete(data.id)}>
                        删除
                    </Menu.Item>
                )}
            </Menu.Dropdown>
        </Menu>
    )
})

interface WithPopoverItemProps extends WithMenuItemProps {}
const WithPopoverItem = forwardRef<HTMLDivElement, WithPopoverItemProps>((props, ref) => {
    const { className, ...rest } = props

    const [open, setOpen] = useState(false)

    return (
        <Popover
            opened={open}
            onChange={setOpen}
            enableClickToggle={false}
            withinPortal
            width={300}
            returnFocus={false}
            offsetOptions={12}
            position="right-start"
        >
            <Popover.Target ref={ref}>
                <WithMenuItem className={classNames(className, { active: open })} {...rest} onContextMenu={() => setOpen(false)} />
            </Popover.Target>
            <PopoverDropdownWithClose title={props.data.label}>
                <ColorAdvanceConfigure
                    value={props.data}
                    onChange={v => {
                        props.onUpdateThemeColor({ ...v, id: props.data.id })
                    }}
                />
            </PopoverDropdownWithClose>
        </Popover>
    )
})

const modifiers = [restrictToVerticalAxis, restrictToParentElement]

export const ColorCategory = () => {
    const theme = useAtomData(
        applicationSettingAtom,
        useCallback(s => s.theme, [])
    )

    const { run: updateAppVersionConfig } = useAtomAction(updateAppVersionConfigAtom)

    const onUpdate = useCallback(
        (values: ApplicationSettingTheme) => {
            updateAppVersionConfig({
                config: {
                    theme: values
                }
            })
        },
        [updateAppVersionConfig]
    )

    const sensors = useSensors(
        useSensor(MouseSensor, {
            activationConstraint: { distance: 8 }
        })
    )

    const [activeId, setActiveId] = useState<string | null>(null)

    const onDragStart = (e: DragStartEvent) => {
        if (e.active) {
            setActiveId(e.active.id as string)
        }
    }

    const onDragEnd = ({ over }: DragEndEvent) => {
        if (over && activeId) {
            const overIndex = theme.palettes.findIndex(item => item.id === (over.id as string))
            const activeIndex = theme.palettes.findIndex(item => item.id === activeId)
            if (activeIndex !== overIndex) {
                const newPalettes = arrayMove(theme.palettes, activeIndex, overIndex)
                onUpdate({ ...theme, palettes: newPalettes })
            }
        }

        setActiveId(null)
    }

    const [createPopoverOpened, setCreatePopoverOpened] = useState(false)

    // 创建颜色主题
    const onCreate = useCallback(
        (values: Omit<ApplicationSettingThemeColor, 'id'>) => {
            setCreatePopoverOpened(false)
            onUpdate({ ...theme, palettes: [...theme.palettes, { ...values, id: nanoid() }] })
        },
        [onUpdate, theme]
    )

    // 删除颜色主题
    const onDelete = useCallback(
        (id: string) => {
            onUpdate({ ...theme, palettes: theme.palettes.filter(item => item.id !== id) })
        },
        [onUpdate, theme]
    )

    return (
        <SC.CategoryGroup
            label="颜色"
            renderRightIcon={() => (
                <Popover
                    width={300}
                    withinPortal
                    position="right-start"
                    offsetOptions={16}
                    opened={createPopoverOpened}
                    onChange={setCreatePopoverOpened}
                >
                    <Popover.Target>
                        <SC.CreatorButton onClick={stopPropagation}>
                            <IconFont type="Add2" size={16} color="var(--color-main)" />
                        </SC.CreatorButton>
                    </Popover.Target>
                    <PopoverDropdownWithClose title="填充" onClick={stopPropagation}>
                        <ColorAdvanceConfigure isCreateMode onCreate={onCreate} />
                    </PopoverDropdownWithClose>
                </Popover>
            )}
        >
            <StyledScroller>
                <DndContext
                    sensors={sensors}
                    modifiers={modifiers}
                    onDragStart={onDragStart}
                    onDragEnd={onDragEnd}
                    onDragCancel={() => setActiveId(null)}
                >
                    <SortableContext items={theme.palettes} strategy={verticalListSortingStrategy}>
                        {theme.palettes.map((item, index) => (
                            <WithPopoverItem
                                key={item.id}
                                data={item}
                                isPrimary={item.id === theme.primaryPointer}
                                onDelete={onDelete}
                                onUpdateThemeColor={v => {
                                    onUpdate(
                                        produce(theme, draft => {
                                            draft.palettes[index] = v
                                        })
                                    )
                                }}
                                onUpdatePrimaryPointer={id => {
                                    onUpdate(
                                        produce(theme, draft => {
                                            draft.primaryPointer = id
                                        })
                                    )
                                }}
                            />
                        ))}
                    </SortableContext>
                </DndContext>
            </StyledScroller>
        </SC.CategoryGroup>
    )
}
