import { findBlockById, initBlockRuntimeState } from '@lighthouse/shared'
import produce from 'immer'
import { useAtomCallback } from 'jotai/utils'
import { useCallback } from 'react'

import { syncComponentsAtom } from '@/atoms/application/state'
import { createBlockAtom, removeBlockAtom } from '@/atoms/page/action'
import { blocksAtom, pageBlocksAtom, pageListAtom, pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import * as srv from '@/services'

import type { PageUndoRedoPayload } from './controller'
import { BLOCK_UPDATE_ACTION, NODE_UPDATE_ACTION, PAGE_CONFIG_UPDATE_ACTION, pageUndoRedoController } from './controller'

type Params = {
    rootPageId: string
    pageId: string
    stackId: string
}

/**
 * 页面block及node操作的撤销重做具体处理逻辑
 *
 * @returns {*}
 */
export const usePageUndoRedoHelper = ({ rootPageId, pageId, stackId }: Params) => {
    const undoHandler = useAtomCallback(async (get, set, data: PageUndoRedoPayload) => {
        switch (data.action) {
            case BLOCK_UPDATE_ACTION: {
                const isSyncComponent = !!data.payload.prev.isLeafSynchronous || !!data.payload.prev.isMasterSynchronous
                if (isSyncComponent) {
                    const newSyncComponents =
                        data.payload.syncComponents?.prev ||
                        produce(get(syncComponentsAtom), draft => {
                            const draftBlock = findBlockById(data.payload.prev.id, draft)
                            if (!draftBlock) {
                                return
                            }
                            Object.entries(data.payload.prev).forEach(([k, v]) => {
                                Reflect.set(draftBlock, k, v)
                            })
                        })

                    await srv.updateSyncComponents(newSyncComponents)
                    set(syncComponentsAtom, newSyncComponents)
                } else {
                    await srv.updateBlock({ pageId, updatedBlocks: [data.payload.prev], allBlocks: data.payload.blocks?.prev })

                    set(blocksAtom, draft => {
                        const draftBlocks = draft[pageId]
                        if (!draftBlocks) {
                            return
                        }

                        const draftBlock = findBlockById(data.payload.prev.id, draftBlocks)
                        if (!draftBlock) {
                            return
                        }
                        Object.entries(data.payload.prev).forEach(([k, v]) => {
                            Reflect.set(draftBlock, k, v)
                        })
                    })
                }

                return true
            }

            case NODE_UPDATE_ACTION: {
                const { created, removed, prev } = data.payload

                if (created) {
                    const { createdBlocks, prevBlocks, prevSyncComponents } = created
                    if (createdBlocks && prevBlocks) {
                        set(blocksAtom, draft => {
                            draft[pageId] = prevBlocks
                        })
                        await set(removeBlockAtom, {
                            rootPageId,
                            stackId,
                            pageId,
                            blocks: prevBlocks,
                            removedBlocks: createdBlocks,
                            clearSelected: false
                        })
                    }

                    if (prevSyncComponents) {
                        set(syncComponentsAtom, prevSyncComponents)
                        set(pageStackAtom, draftStack => {
                            const stack = equalPageStack({ rootPageId, stackId })(draftStack)
                            if (stack) {
                                initBlockRuntimeState(stack, {
                                    blocks: get(pageBlocksAtom(pageId)),
                                    syncComponents: prevSyncComponents
                                })
                            }
                        })
                        await srv.updateSyncComponents(prevSyncComponents)
                    }
                }

                if (removed) {
                    const { removedBlocks, prevBlocks, prevSyncComponents } = removed
                    if (removedBlocks && prevBlocks) {
                        set(blocksAtom, draft => {
                            draft[pageId] = prevBlocks
                        })
                        await set(createBlockAtom, {
                            rootPageId,
                            stackId,
                            pageId,
                            blocks: prevBlocks,
                            createdBlocks: removedBlocks,
                            autoSelect: false
                        })
                    }

                    if (prevSyncComponents) {
                        set(syncComponentsAtom, prevSyncComponents)
                        await srv.updateSyncComponents(prevSyncComponents)
                    }
                }

                if (prev?.blocks) {
                    set(blocksAtom, draft => void (draft[pageId] = prev.blocks))
                    await srv.updateBlock({ pageId, allBlocks: prev.blocks })
                }
                if (prev?.syncComponents) {
                    set(syncComponentsAtom, prev.syncComponents)
                    await srv.updateSyncComponents(prev.syncComponents)
                }

                return true
            }

            case PAGE_CONFIG_UPDATE_ACTION: {
                set(pageListAtom, draft => {
                    const index = draft.findIndex(item => item.id === pageId)
                    if (index === -1) {
                        return
                    }

                    draft[index] = data.payload.prev
                })

                return true
            }

            default: {
                break
            }
        }
    })

    const redoHandler = useAtomCallback(async (get, set, data: PageUndoRedoPayload) => {
        switch (data.action) {
            case BLOCK_UPDATE_ACTION: {
                const isSyncComponent = !!data.payload.next.isLeafSynchronous || !!data.payload.next.isMasterSynchronous
                if (isSyncComponent) {
                    const newSyncComponents =
                        data.payload.syncComponents?.next ||
                        produce(get(syncComponentsAtom), draft => {
                            const draftBlock = findBlockById(data.payload.next.id, draft)
                            if (!draftBlock) {
                                return
                            }
                            Object.entries(data.payload.next).forEach(([k, v]) => {
                                Reflect.set(draftBlock, k, v)
                            })
                        })

                    await srv.updateSyncComponents(newSyncComponents)
                    set(syncComponentsAtom, newSyncComponents)
                } else {
                    await srv.updateBlock({ pageId, updatedBlocks: [data.payload.next], allBlocks: data.payload.blocks?.next })

                    set(blocksAtom, draft => {
                        const draftBlocks = draft[pageId]
                        if (!draftBlocks) {
                            return
                        }

                        const draftBlock = findBlockById(data.payload.next.id, draftBlocks)
                        if (!draftBlock) {
                            return
                        }
                        Object.entries(data.payload.next).forEach(([k, v]) => {
                            Reflect.set(draftBlock, k, v)
                        })
                    })
                }

                return true
            }

            case NODE_UPDATE_ACTION: {
                const { created, removed, next } = data.payload

                if (created) {
                    const { createdBlocks, nextBlocks, createdSyncComponents, nextSyncComponents } = created
                    if (createdBlocks && nextBlocks) {
                        set(blocksAtom, draft => {
                            draft[pageId] = nextBlocks
                        })
                        await set(createBlockAtom, {
                            createdBlocks,
                            blocks: nextBlocks,
                            rootPageId,
                            pageId,
                            stackId,
                            autoSelect: false
                        })
                    }

                    if (createdSyncComponents && nextSyncComponents) {
                        set(syncComponentsAtom, nextSyncComponents)
                        set(pageStackAtom, draftStack => {
                            const stack = equalPageStack({ rootPageId, stackId })(draftStack)
                            if (stack) {
                                initBlockRuntimeState(stack, {
                                    blocks: get(pageBlocksAtom(pageId)),
                                    syncComponents: nextSyncComponents
                                })
                            }
                        })
                        await srv.createSyncBlock({ createBlocks: createdSyncComponents, blocks: nextSyncComponents })
                    }
                }

                if (removed) {
                    const { removedBlocks, nextBlocks, removedSyncComponents, nextSyncComponents } = removed
                    if (removedBlocks && nextBlocks) {
                        set(blocksAtom, draft => {
                            draft[pageId] = nextBlocks
                        })
                        await set(removeBlockAtom, {
                            removedBlocks,
                            blocks: nextBlocks,
                            rootPageId,
                            pageId,
                            stackId,
                            clearSelected: false
                        })
                    }

                    if (removedSyncComponents && nextSyncComponents) {
                        set(syncComponentsAtom, nextSyncComponents)
                        await srv.updateSyncComponents(nextSyncComponents)
                    }
                }

                if (next?.blocks) {
                    set(blocksAtom, draft => void (draft[pageId] = next.blocks))
                    await srv.updateBlock({ pageId, allBlocks: next.blocks })
                }
                if (next?.syncComponents) {
                    set(syncComponentsAtom, next.syncComponents)
                    set(pageStackAtom, draftStack => {
                        const stack = equalPageStack({ rootPageId, stackId })(draftStack)
                        if (stack) {
                            initBlockRuntimeState(stack, {
                                blocks: get(pageBlocksAtom(pageId)),
                                syncComponents: next.syncComponents || []
                            })
                        }
                    })
                    await srv.updateSyncComponents(next.syncComponents)
                }

                return true
            }

            case PAGE_CONFIG_UPDATE_ACTION: {
                set(pageListAtom, draft => {
                    const index = draft.findIndex(item => item.id === pageId)
                    if (index === -1) {
                        return
                    }

                    draft[index] = data.payload.next
                })
                return true
            }

            default: {
                break
            }
        }
    })

    const undo = useCallback(async () => {
        const res = pageUndoRedoController.undo()
        if (res) {
            for (let i = res.length - 1; i >= 0; i--) {
                // eslint-disable-next-line no-await-in-loop
                await undoHandler(res[i])
            }
        }
    }, [undoHandler])

    const redo = useCallback(async () => {
        const res = pageUndoRedoController.redo()
        if (res) {
            for (const re of res) {
                // eslint-disable-next-line no-await-in-loop
                await redoHandler(re)
            }
        }
    }, [redoHandler])

    return { undo, redo }
}
