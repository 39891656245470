import { Divider } from '@byecode/ui'
import type { UserCenterBlockConfig } from '@lighthouse/core'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { getBlockAndPageDesignLimit, PositionSetting, SizeSetting, StyleSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'

interface UserCenterSettingProps {}

export const UserCenterSetting: React.FunctionComponent<UserCenterSettingProps> = props => {
    const { control, watch } = useFormContext<UserCenterBlockConfig>()
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const designProps = getBlockAndPageDesignLimit('userCenter')

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <StyleSetting fillLabel="文字/图标颜色" {...designProps.design} />
                </>
            )}
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <VisibilitySetting />
                </>
            )}
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}
        </>
    )
}
