import { off, on } from '@lighthouse/tools'
import { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'

import { DATASOURCE_CELL_DATA_KEY, DATASOURCE_CELL_FIELD_DATA_KEY, DATASOURCE_FIELD_DATA_KEY } from '../../constants'
import selectionState from './store/selection'

interface UseTableSelectionParams {
    enable?: boolean
    enableCell?: boolean
    cellDataKey?: string
    fieldDataKey?: string
    cellFieldDataKey?: string
    scrollRef: React.RefObject<HTMLDivElement>
    enableField?: boolean
    isExcludeIgnore?: boolean
}

// interface UseTableSelectionReturn {
//     ref: Ref<HTMLDivElement>
//     // scrollContainerRef: RefObject<HTMLDivElement>
// }

type Direction = 'up' | 'down' | 'left' | 'right'

export const clearActiveCell = (dataKey: string, dataField: string) => {
    if (selectionState.state.cellCheckedId) {

        const [blockId, _, fieldId] = getIdByCellDataKey(selectionState.state.cellCheckedId)
        const fieldCheckId = `${blockId}&${fieldId}`
        const cellDoms = document.querySelectorAll(`[${dataKey}='${selectionState.state.cellCheckedId}']`)
        selectionState.state.cellCheckedId = ''
        cellDoms.forEach(cellDom => {
            cellDom.classList.remove('cell-active')
            // checkedDom.classList.remove('field-active')
        })
        const fieldDoms = document.querySelectorAll(`[${dataField}='${fieldCheckId}']`)
        fieldDoms.forEach(fieldDom => {
            fieldDom.classList.remove('cell-active')
        })
        // if (selectionState.state.fieldCheckId) {
        //     selectionState.state.fieldCheckId = ''
        // }
    }
}
export const clearActiveField = (dataKey: string, dataCellField: string) => {
    if (selectionState.state.fieldCheckId) {
        const checkedDoms = document.querySelectorAll(`[${dataKey}='${selectionState.state.fieldCheckId}']`)
        const cellCheckedDoms = document.querySelectorAll(`[${dataCellField}='${selectionState.state.fieldCheckId}']`)
        selectionState.state.fieldCheckId = ''
        checkedDoms.forEach(checkedDom => {
            // checkedDom.classList.remove('cell-active')
            checkedDom.classList.remove('field-active')
        })
        cellCheckedDoms.forEach(checkedDom => {
            // checkedDom.classList.remove('cell-active')
            checkedDom.classList.remove('field-active')
        })
        // if (selectionState.state.cellCheckedId) {
        //     selectionState.state.cellCheckedId = ''
        // }
    }
}

export const getIdByFieldKey = (originDataKey: string | null): [string, string] => {
    if (!originDataKey) {
        return ['', '']
    }

    // const reg = /(.+)&(.+)&(.+)/giu
    const [blockId = '', fieldId = ''] = originDataKey.split('&')

    return [blockId, fieldId]
}

export const getIdByCellDataKey = (originDataKey: string | null): [string, string, string] => {
    if (!originDataKey) {
        return ['', '', '']
    }

    // const reg = /(.+)&(.+)&(.+)/giu
    const [blockId = '', recordId = '', fieldId = ''] = originDataKey.split('&')

    return [blockId, recordId, fieldId]
}

const getNeighborRecordById = (currentRecordSelectorId: string, direction: 'prev' | 'next', cellDataKey: string): string | undefined => {
    const currentRecordKey = `[${cellDataKey}='${currentRecordSelectorId}']`
    const recordBlockDom = document.querySelector(currentRecordKey)
    const neighborRecordDom = direction === 'prev' ? recordBlockDom?.previousElementSibling : recordBlockDom?.nextElementSibling
    if (neighborRecordDom) {
        const targetDataKey = neighborRecordDom.getAttribute(cellDataKey)
        const [_, currentRecordId, ___] = getIdByCellDataKey(targetDataKey)
        return currentRecordId
    }
}

export const clearDOMSelection = () => {
    selectionState.state.selectedItems = new Set()
}

export const getNeighborCellDom = (params: {
    containDom: HTMLDivElement | null
    currentDataKey: string
    direction: 'up' | 'down'
    cellDataKey: string
}): Element | null => {
    const { containDom, currentDataKey, direction, cellDataKey } = params
    const [currentBlockId, currentRecordId, currentCellId] = getIdByCellDataKey(currentDataKey)
    const recordDirection = direction === 'up' ? 'prev' : 'next'
    const neighborRecordId = getNeighborRecordById(`${currentBlockId}&${currentRecordId}`, recordDirection, cellDataKey) ?? ''
    const neighborCellDataKey = `[${cellDataKey}='${currentBlockId}&${neighborRecordId}&${currentCellId}']`
    return containDom?.querySelector(neighborCellDataKey) ?? null
}

export const seekActiveCellDomFromTriggerElement = (triggerEl: Element | null, dataKey: string, root: HTMLDivElement | null) => {
    let evTarget = triggerEl
    let dom = null
    while (evTarget) {
        if (evTarget === root) {
            break
        }
        if (evTarget.getAttribute(dataKey)) {
            dom = evTarget
            break
        }
        evTarget = evTarget.parentElement
    }
    return dom
}

export const activeFieldAffectCell = (el: HTMLDivElement | null, checkedId: string, fieldDataKey: string, cellFieldDataKey: string) => {
    if (!el) {
        return
    }
    const fDoms = el.querySelectorAll(`[${fieldDataKey}='${checkedId}']`)
    fDoms.forEach(cellDom => {
        cellDom.classList.add('field-active')
    })
    const cDoms = el.querySelectorAll(`[${cellFieldDataKey}='${checkedId}']`)
    cDoms.forEach(cellDom => {
        cellDom.classList.add('field-active')
    })
}

export const activeCellAffectField = (params: {
    el: HTMLElement | null
    checkedId: string
    fieldCheckId: string
    cellDataKey: string
    fieldDataKey: string
}) => {
    const { el, checkedId, fieldCheckId, cellDataKey, fieldDataKey } = params
    if (!el) {
        return
    }

    const fDoms = el.querySelectorAll(`[${fieldDataKey}='${fieldCheckId}']`)
    fDoms.forEach(cellDom => {
        if (!cellDom.className.includes('cell-active')) {
            cellDom.classList.add('cell-active')
        }
    })
    const cDoms = el.querySelectorAll(`[${cellDataKey}='${checkedId}']`)

    cDoms.forEach(cellDom => {
        cellDom.classList.add('cell-active')
    })
}

export const scrollToCell = (dom: HTMLElement, scrollElement: HTMLDivElement | null, direction: Direction) => {
    if (!scrollElement) {
        return
    }
    const scrollContainer = scrollElement
    // const scrollContainer = container.querySelector('[data-radix-scroll-area-viewport]')
    if (!scrollContainer) {
        return
    }
    const scrollContainerTop = scrollContainer.scrollTop
    const scrollContainerLeft = scrollContainer.scrollLeft
    const { left: domLeft, top: domTop, width: domWidth, y: domY } = dom.getBoundingClientRect()
    const { offsetTop, offsetLeft } = dom

    const {
        left: containerLeft,
        top: containerTop,
        height: containerHeight,
        width: containerWidth
    } = scrollContainer.getBoundingClientRect()
    // const scrollLeft = domLeft - containerLeft - containerWidth + domWidth
    // const scrollTop = domTop - containerTop + containerHeight + 38

    // const isScrollTop = scrollContainerTop + containerHeight > domTop - containerTop && scrollContainerTop < domTop - containerTop
    // const isScrollLeft = scrollContainerLeft + containerWidth > domLeft - containerLeft && scrollContainerLeft < domLeft - containerLeft
    // scrollContainer.scrollBy({ left: scrollLeft, top: scrollTop })
    switch (direction) {
        case 'up': {
            const domOffsetTop = domTop - containerTop
            if (domOffsetTop > 40 && domOffsetTop < containerHeight) {
                return
            }
            const scrollTop = scrollContainerTop + domOffsetTop - 40
            scrollContainer.scrollTo({ top: scrollTop })
            return
        }
        case 'down': {
            const domOffsetTop = domTop - containerTop
            if (domOffsetTop < containerHeight - 40 && domOffsetTop > 0) {
                return
            }
            const scrollTop = scrollContainerTop + domOffsetTop - containerHeight + 40
            scrollContainer.scrollTo({ top: scrollTop })
            return
        }
        case 'left': {
            const domOffsetLeft = domLeft - containerLeft
            if (domOffsetLeft > 62 && domOffsetLeft < containerWidth) {
                return
            }
            const scrollLeft = scrollContainerLeft + domOffsetLeft - 62
            scrollContainer.scrollTo({ left: scrollLeft })
            return
        }
        default: {
            const domOffsetLeft = domLeft - containerLeft
            if (domOffsetLeft < containerWidth - domWidth && domOffsetLeft > 0) {
                return
            }
            const scrollLeft = scrollContainerLeft + domOffsetLeft - containerWidth + domWidth
            scrollContainer.scrollTo({ left: scrollLeft })
        }
    }
}

export const highlightActiveCell = (params: {
    target: Element | null
    cellDataKey: string
    fieldDataKey: string
    cellFieldDataKey: string
    // root: RefObject<HTMLDivElement> | undefined,
    scrollElement: HTMLDivElement | null
    direction: Direction | undefined
    autoScroll: boolean
}) => {
    const { target, cellDataKey, fieldDataKey, cellFieldDataKey, scrollElement, direction, autoScroll = false } = params
    const dom = seekActiveCellDomFromTriggerElement(target, cellDataKey, scrollElement)
    if (!dom) {
        clearActiveCell(cellDataKey, fieldDataKey)
        clearActiveField(fieldDataKey, cellFieldDataKey)
        return
    }

    const checkedId = dom.getAttribute(cellDataKey)
    const el = scrollElement
    if (checkedId) {
        clearActiveCell(cellDataKey, fieldDataKey)
        clearActiveField(fieldDataKey, cellFieldDataKey)
        const [blockId, _, fieldId] = getIdByCellDataKey(checkedId)
        const dataField = `${blockId}&${fieldId}`
        selectionState.state.cellCheckedId = checkedId
        activeCellAffectField({ el, checkedId, fieldCheckId: dataField, cellDataKey, fieldDataKey })
        if (autoScroll && direction) {
            scrollToCell(dom as HTMLElement, scrollElement, direction)
        }
    }
}

export const highlightActiveField = (params: {
    target: HTMLElement | null
    cellDataKey: string
    fieldDataKey: string
    cellFieldDataKey: string
    scrollElement: HTMLDivElement | null
}) => {
    const { target, cellDataKey, fieldDataKey, cellFieldDataKey, scrollElement } = params
    const dom = seekActiveCellDomFromTriggerElement(target, fieldDataKey, scrollElement)
    if (!dom) {
        return
    }
    const el = scrollElement
    const checkedId = dom.getAttribute(fieldDataKey)
    if (checkedId) {
        clearActiveCell(cellDataKey, fieldDataKey)
        clearActiveField(fieldDataKey, cellFieldDataKey)
        activeFieldAffectCell(el, checkedId, fieldDataKey, cellFieldDataKey)
        selectionState.state.fieldCheckId = checkedId ?? ''
    }
}

export const cellActiveKeydownHandler = (params: {
    sel: (currentDom: Element) => Element | null
    cellDataKey: string
    fieldDataKey: string
    cellFieldDataKey: string
    scrollElement: HTMLDivElement | null
    direction: Direction
    enable?: boolean
}) => {
    const { sel, cellDataKey, fieldDataKey, cellFieldDataKey, scrollElement, direction, enable = true } = params
    if (!enable) {
        return
    }
    clearDOMSelection()
    const { cellCheckedId } = selectionState.state
    const cDom = document.querySelector(`[${cellDataKey}='${cellCheckedId}']`)
    if (!cDom) {
        return
    }

    const activeDom = sel(cDom)
    if (!activeDom) {
        return
    }
    // const viewDom =
    const isActivatableEl = activeDom?.getAttribute(cellDataKey)
    const isInContainer = scrollElement?.contains(activeDom)
    if (isActivatableEl && isInContainer) {
        highlightActiveCell({
            target: activeDom,
            cellDataKey,
            fieldDataKey,
            cellFieldDataKey,
            scrollElement,
            direction,
            autoScroll: true
        })
    }
}

export const useTableSelection = (params: UseTableSelectionParams) => {
    const {
        enable = true,
        enableCell = true,
        cellDataKey = DATASOURCE_CELL_DATA_KEY,
        fieldDataKey = DATASOURCE_FIELD_DATA_KEY,
        cellFieldDataKey = DATASOURCE_CELL_FIELD_DATA_KEY,
        enableField = true,
        scrollRef,
        isExcludeIgnore
    } = params

    const onSelectCell = (ev: Event) => {
        if (!enableCell) {
            return
        }
        clearDOMSelection()
        const el = ev.target as HTMLElement
        highlightActiveCell({
            target: el,
            cellDataKey,
            fieldDataKey,
            cellFieldDataKey,
            scrollElement: scrollRef.current,
            direction: undefined,
            autoScroll: false
        })
    }

    const onClickCell = (ev: Event) => {
        const IGNORE_TYPE = 'data-ignore-click-away'
        if (!isExcludeIgnore && ev.composedPath().some(e => e instanceof Element && e.hasAttribute(IGNORE_TYPE))) {
            selectionState.state.cellCheckedId = ''
            return
        }
        onSelectCell(ev)
    }

    const onSelectField = (el: HTMLElement) => {
        if (!enableField) {
            selectionState.state.fieldCheckId = ''
            return
        }
        highlightActiveField({
            target: el,
            cellDataKey,
            fieldDataKey,
            cellFieldDataKey,
            scrollElement: scrollRef.current
        })
    }

    const onClickField = (ev: Event) => {
        onSelectField(ev.target as HTMLElement)
    }

    const onMouseDown = (ev: Event) => {
        onClickCell(ev)
        onClickField(ev)
    }

    const activeHotKeys = 'left, Shift+tab, right, tab, up, down'
    const selectHotKeys = 'Shift+left, Shift+right, Shift+up, Shift+down'
    const metaHotKey = 'Escape'

    const hotKeys = `${activeHotKeys}`

    useHotkeys(hotKeys, (event, handler) => {
        event.preventDefault()
        switch (handler.key) {
            case 'left':
            case 'Shift+tab': {
                cellActiveKeydownHandler({
                    sel: dom => dom.previousElementSibling,
                    cellDataKey,
                    fieldDataKey,
                    cellFieldDataKey,
                    scrollElement: scrollRef.current,
                    direction: 'left',
                    enable
                })
                break
            }
            case 'right':
            case 'tab': {
                cellActiveKeydownHandler({
                    sel: dom => dom.nextElementSibling,
                    cellDataKey,
                    fieldDataKey,
                    cellFieldDataKey,
                    scrollElement: scrollRef.current,
                    direction: 'right',
                    enable
                })
                break
            }
            case 'up': {
                cellActiveKeydownHandler({
                    sel: dom => {
                        const currentDataKeyAttr = dom.getAttribute(cellDataKey)
                        return currentDataKeyAttr
                            ? getNeighborCellDom({
                                containDom: scrollRef.current,
                                currentDataKey: currentDataKeyAttr,
                                direction: 'up',
                                cellDataKey
                            })
                            : null
                    },
                    cellDataKey,
                    fieldDataKey,
                    cellFieldDataKey,
                    scrollElement: scrollRef.current,
                    direction: 'up',
                    enable
                })
                break
            }
            case 'down': {
                cellActiveKeydownHandler({
                    sel: dom => {
                        const currentDataKeyAttr = dom.getAttribute(cellDataKey)
                        return currentDataKeyAttr
                            ? getNeighborCellDom({
                                containDom: scrollRef.current,
                                currentDataKey: currentDataKeyAttr,
                                direction: 'down',
                                cellDataKey
                            })
                            : null
                    },
                    cellDataKey,
                    fieldDataKey,
                    cellFieldDataKey,
                    scrollElement: scrollRef.current,
                    direction: 'down',
                    enable
                })
                break
            }
            default: {
                break
            }
        }
    })

    /* eslint-disable react-hooks/exhaustive-deps */
    useEffect(() => {
        if (scrollRef.current) {
            on(scrollRef.current, 'mousedown', onMouseDown)
            return () => {
                off(scrollRef.current, 'mousedown', onMouseDown)
            }
        }
    }, [scrollRef.current])

    return { onSelectField, onSelectCell, clearActiveCell }
}
