import { Flex, Input, Select, Text } from '@byecode/ui'
import { CollectEventWriteType, DataSourceType, EVENT_VARIABLE_TYPE } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable, PrevPageDatasourceForVariable } from '@lighthouse/shared'
import { COLLECT_WAY_CONTROL, ListItem4ByecodeUi, SelectDataSource, useSharedConfigDisabledWithVersion } from '@lighthouse/shared'
import React, { useEffect, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import FormSegmentedControl from '../BlockSettings/Common/FormSegmentedControl'
import { RecordConfigure } from './RecordConfigure'
import * as SC from './styles'
import { WorkflowConfigure } from './WorkflowConfigure'

const SCxListItemBaseInfo = styled.div`
    gap: 0;
    padding: 0 16px;
`

interface CollectionEventConfigureProps {
    // prefixName?: string
    disabled?: boolean
    style?: React.CSSProperties
    eventTypes?: EVENT_VARIABLE_TYPE[]
    curr?: CurrPageDatasourceForVariable
    prev?: PrevPageDatasourceForVariable
}

const triggerTypeByEventType = [
    {
        label: '访问页面事件',
        value: EVENT_VARIABLE_TYPE.PAGE_VISIT
    },
    {
        label: '按钮点击事件',
        value: EVENT_VARIABLE_TYPE.BUTTON_CLICK
    },
    // {
    //     label: '微信分享事件',
    //     value: EVENT_VARIABLE_TYPE.WECHAT_SHARE
    // }
]

export const CollectionEventConfigure: React.FunctionComponent<CollectionEventConfigureProps> = ({
    disabled,
    style,
    eventTypes,
    curr,
    prev
}) => {
    const { control } = useFormContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const writeType = useWatch({ control, name: 'writeType' })
    const triggerType = useWatch({ control, name: 'triggerType' })
    const writeDataSourceId = useWatch({ control, name: 'record.dataSourceId' })
    const dataSourceList = useDataSourceList(appId, envId)
    const collectTargetOptions = useMemo(() => {
        return triggerTypeByEventType.filter(option => eventTypes?.includes(option.value))
    }, [eventTypes])


    return (
        <SCxListItemBaseInfo style={style}>
            <ListItem4ByecodeUi
                alignItems="center"
                justifyContent="space-between"
                compact
                styles={{
                    root: {
                        gap: 0
                    }
                }}
            >
                <Text color="var(--color-gray-600)">标题</Text>
                <SC.RightFill>
                    <Controller
                        control={control}
                        name="title"
                        render={({ field }) => {
                            return <Input style={{ width: 180 }} {...field} disabled={disabled} />
                        }}
                    />
                </SC.RightFill>
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi alignItems="center" compact justifyContent="space-between">
                <Text>事件类型</Text>
                <SC.RightFill>
                    <Controller
                        name="triggerType"
                        control={control}
                        render={({ field }) => (
                            <Select style={{ width: 180 }} disabled={disabled} {...field} options={collectTargetOptions} />
                        )}
                    />
                </SC.RightFill>
            </ListItem4ByecodeUi>

            <Controller
                name="writeType"
                control={control}
                render={({ field }) => (
                    <FormSegmentedControl
                        label="采集方式"
                        compact
                        disabled={disabled}
                        data={COLLECT_WAY_CONTROL}
                        value={field.value}
                        size="sm"
                        onChange={field.onChange}
                    />
                )}
            />

            {writeType === CollectEventWriteType.RECORD && (
                <ListItem4ByecodeUi alignItems="center" compact justifyContent="space-between">
                    <Flex alignItems="center">
                        <Text>选择数据表</Text>
                        <Text color="red" style={{ paddingLeft: 4 }}>
                            *
                        </Text>
                    </Flex>
                    <SC.RightFill>
                        <Controller
                            name="record.dataSourceId"
                            control={control}
                            render={({ field }) => (
                                <SelectDataSource
                                    withinPortal
                                    size="md"
                                    disabled={disabledWithVersion}
                                    position="bottom-end"
                                    dataSourceList={dataSourceList.filter(v => v.type !== DataSourceType.aggregateDataSource)}
                                    {...field}
                                />
                            )}
                        />
                    </SC.RightFill>
                </ListItem4ByecodeUi>
            )}

            {writeType === CollectEventWriteType.WORKFLOW ? (
                <WorkflowConfigure prefixName="workflow" eventType={triggerType} curr={curr} prev={prev} />
            ) : (
                <RecordConfigure prefixName="record" dataSourceId={writeDataSourceId} eventType={triggerType} curr={curr} prev={prev} />
            )}
        </SCxListItemBaseInfo>
    )
}
