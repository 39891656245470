import { Enum } from '@lighthouse/tools'

import type { BackgroundNoImageProtocol, BaseBreakPointProtocol, Direction } from '../../protocols'
import { BlockType } from '../Basic'
import { BaseBlock } from './BaseBlock'

export const DIVIDER_STYLE = Enum('DASHED', 'SOLID')
export type DividerStyle = Enum<typeof DIVIDER_STYLE>

export interface DividerBlockConfig extends BaseBreakPointProtocol {
    visible: boolean
    direction?: Direction
    style?: DividerStyle
    fill?: BackgroundNoImageProtocol
    size?: number
}

export abstract class DividerBlockAbstract extends BaseBlock {
    type = BlockType.divider

    abstract override config: DividerBlockConfig
}
