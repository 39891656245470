import { Enum } from '@lighthouse/tools'

import type { ButtonConfigure } from '../../protocols'
import type { BaseBreakPointProtocol } from '../../protocols/blocks'
import { BlockType } from '../Basic'
import { BaseBlock } from './BaseBlock'

export type ButtonBlockTriggerEvent = 'hover' | 'click'

export type ButtonBlockHandleEvent =
    | 'none'
    | 'openPage'
    | 'openFormPage'
    | 'openRecordPage'
    | 'openRecordEditPage'
    | 'openLink'
    | 'scrollTo'
    | 'control'
    | 'goBack'
    | 'notification'
    | 'copy'
    | 'createRecord'
    | 'updateRecord'
    | 'deleteRecord'
    | 'shareToWechat'
    | 'downloadFile'
    | 'iClick'
    | 'aliyunMessage'
    | 'call'
    | 'wechatPay'
    | 'wechatTemplateMsg'
    | 'stripe'
    | 'refreshPage'
    | 'closeFloat'

export type ButtonBlockType = 'icon-name' | 'icon' | 'name'

export const ButtonSize = Enum('xs', 'sm', 'md', 'lg', 'xl', 'xxl')
export type ButtonSizeType = Enum<typeof ButtonSize>

// export type ButtonItem = {
//     id: string
//     // iconPosition: ActionItemIconPosition
//     // color?: BackgroundNoImageProtocol
//     // iconColor?: BackgroundNoImageProtocol
//     // backgroundColor?: BackgroundNoImageProtocol
//     // borderColor?: BackgroundNoImageProtocol
//     // fillWay: ActionItemFitType
//     // pattern: ActionItemPattern
//     // name: RichTextContentProtocol
// } & Omit<ButtonProtocols, 'pattern' | 'name'>

export interface ButtonBlockConfigure extends ButtonConfigure {
}

// export type ButtonItem = ButtonBlockConfigure

export interface ButtonBlockConfig extends BaseBreakPointProtocol, ButtonBlockConfigure {}

export abstract class ButtonBlockAbstract extends BaseBlock {
    type = BlockType.button

    abstract override config: ButtonBlockConfig
}
