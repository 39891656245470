import type { ByecodeNumberSize, ByecodeSize } from '@byecode/ui'
import { useUncontrolled } from '@lighthouse/tools'
import type { ChangeEvent, ChangeEventHandler } from 'react'
import React, { forwardRef, useCallback, useId } from 'react'

import * as SC from './styles'

interface InputSwitchProps {
    id?: string

    /** Key of theme.radius or any valid CSS value to set border-radius, "xl" by default */
    radius?: ByecodeNumberSize

    /** Position of label */
    labelPosition?: 'left' | 'right'

    color?: string

    checked?: boolean

    defaultChecked?: boolean

    disabled?: boolean

    progressed?: boolean

    onChange?: ChangeEventHandler<HTMLInputElement>
}

export const InputSwitch = forwardRef<HTMLInputElement, InputSwitchProps>(
    ({ color, checked, disabled, progressed, defaultChecked, onChange, ...rest }, ref) => {
        const [_value, handleChange] = useUncontrolled({ value: checked, defaultValue: defaultChecked ?? false })
        const id = useId()

        const handleCheckedChange = useCallback(
            (ev: ChangeEvent<HTMLInputElement>) => {
                handleChange(ev.target.checked)
                onChange?.(ev)
            },
            [handleChange, onChange]
        )

        return (
            <SC.SwitchWrapper {...rest} ref={ref}>
                <SC.SwitchInput disabled={disabled || progressed} type="checkbox" id={id} checked={_value} onChange={handleCheckedChange} />
                <SC.SwitchTrack checked={_value} disabled={disabled} progressed={progressed} htmlFor={id}>
                    <SC.SwitchThumb checked={_value} />
                </SC.SwitchTrack>
            </SC.SwitchWrapper>
        )
    }
)
