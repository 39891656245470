import { getAssetUrl } from '@lighthouse/assets'
import { BLOCK_NAME_MAP, CHART_BLOCK_NAME_MAP, FIELD_BLOCK_NAME_MAP } from '@lighthouse/block'
import { VIEW_BLOCK_NAME_MAP } from '@lighthouse/shared'

import type { TooltipDocument, TooltipDocumentType } from './types'

export const tooltipDocumentMap: Record<TooltipDocumentType, TooltipDocument> = {
    regular: {
        title: '自定义输入框验证用户的输入格式，比如英文和数字。',
        // file: getAssetUrl('question', 'regular.png'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/gdhtnooxo22fomqd?singleDoc#',
        linkText: '自定义正则大全'
    },
    smsVerification: {
        title: '当前仅支持中国大陆（+86）验证码短信。开启短信验证后，手机未通过验证的表单无法提交。\r\n短信验证将消耗短信条数，当短信条数为0时，表单将无法正常提交，查看帮助文档。'
    },
    recipient: {
        title: '添加收件人的方式：从数据表中选择插入变量'
    },
    cc: {
        title: '打开后每个收件人都可以看到所有收件人和抄送人'
    },
    orderId: {
        title: '注意：请将「自动编号」字段中的编号类型设为「随机编号」，这样能保证订单号的安全性。'
    },
    shopDescribe: {
        title: '不能超过127个字符'
    },
    orderMoney: {
        title: '金额单位为元；货币类型为 CNY：人民币'
    },
    refoundId: {
        title: '格式只能是数字、大小写字母_-*；只能选「自动编号」字段。\r\n注意：请将作为退款单号的「自动编号」中的场景设置为「订单」， 这样能保证订单号的安全性。'
    },
    refoundMoney: {
        title: '金额单位为元；货币类型为 CNY：人民币；不能大于原订单金额'
    },
    currency: {
        title: '三个小写字母组成的ISO货币代码，必须是Stripe支持的货币类型'
    },
    shopName: {
        title: '不能超过 127 个字符；\r\n注意：不可使用特殊字符，如 /，=，& 等'
    },
    shopImg: {
        title: '附件内容只能是图片或图片类型的资源链接'
    },
    'overlay.showOn': {
        title: '为了方便编辑浮层，在管理端弹出时机始终为“点击”。'
    },
    'overlay.dismiss': {
        title: '为了方便编辑浮层，在管理端关闭时机始终为“点击”。'
    },
    panel: {
        title: '一个容器可以添加多个面板，目前支持与控制组件动作配合使用。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zkphot9fsluxcr2t',
        file: getAssetUrl('question', 'panel.png'),
        linkText: '帮助手册'
    },
    visibilityDevice: {
        title: '主要用于内容的适配影响界面展示效果时。可改变组件在不同设备的可见性，达到界面美观的效果',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/qex89ct6wpr3hzto',
        file: getAssetUrl('question', 'visibilityDevice.jpg'),
        linkText: '帮助手册'
    },
    toolOperation: {
        title: '添加的表单页可用于提交信息到数据源中；自定义的按钮可执行按钮组件的所有动作。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/apza80xfqqz1zs99',
        file: getAssetUrl('question', 'toolOperation.jpg'),
        linkText: '帮助手册'
    },
    clickRecord: {
        title: '可添加点击后展开的详情页面以及展开方式；自定义可执行任意动作。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/apza80xfqqz1zs99',
        file: getAssetUrl('question', 'clickRecord.jpg'),
        linkText: '帮助手册'
    },
    actionConfigure: {
        title: '可配置点击组件后的执行任意动作。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/wezggrax39aq5h0y',
        file: getAssetUrl('question', 'actionConfigure.jpg'),
        linkText: '帮助手册'
    },
    appInfo: {
        title: '此处配置将会影响应用在网页中打开的标签栏效果。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/igafrtd7kf2z9ad9',
        file: getAssetUrl('question', 'appInfo.jpg'),
        linkText: '帮助手册'
    },
    tabBar: {
        title: '勾选后在手机端底部显示标签栏。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/pvqay2eh48gmzxsd',
        file: getAssetUrl('question', 'tabBar.jpg'),
        linkText: '帮助手册'
    },
    size: {
        title: '自动布局最强大的功能之一，是它能够控制容器本身及其内部组件的尺寸，两者相互有着非常强的依赖关系。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/el0sh1g93d1s2tf9',
        file: getAssetUrl('question', 'size.png'),
        linkText: '帮助手册'
    },
    layout: {
        title: '可布局应用界面中的各种元素（如文本、图片等）。它决定了页面上各个元素的结构、排列、大小、间距等，从而影响用户体验、视觉效果。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/luqoovdrgac99s4q',
        file: getAssetUrl('question', 'layout.png'),
        linkText: '帮助手册'
    },
    formContainer: {
        title: BLOCK_NAME_MAP['formContainer'],
        describe:
            '用于提交数据表单的必备容器。收集用户信息并将其直接存储在应用的数据源中，需要绑定数据源使用，可添加任意子组件且设计排版。',
        file: getAssetUrl('question', 'formContainer.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ohbdmuerlw4ha63v',
        linkText: '帮助手册'
    },
    custom: {
        title: VIEW_BLOCK_NAME_MAP['custom'],
        describe: '此视图是需要绑定数据源的容器，可以灵活地自定义内容、样式。',
        file: getAssetUrl('question', 'custom.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ia96vey7irgx5iq7',
        linkText: '帮助手册'
    },
    table: {
        title: VIEW_BLOCK_NAME_MAP['table'],
        describe: '表格视图和Excel电子表格类似，可以网格化显示和管理数据。',
        file: getAssetUrl('question', 'table.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/vkshyvt4mp01egeu',
        linkText: '帮助手册'
    },
    list: {
        title: VIEW_BLOCK_NAME_MAP['list'],
        describe: '列表是一种有序的信息展示方式，列出管理任务、事项或其他数据。',
        file: getAssetUrl('question', 'list.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/coxqcnttguh03p1n',
        linkText: '帮助手册'
    },
    gallery: {
        title: VIEW_BLOCK_NAME_MAP['gallery'],
        describe: '数据以卡片的形式平铺展示，卡片中重点突出图片内容，数据里其他描述内容显示在图片下方。',
        file: getAssetUrl('question', 'gallery.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ooxs8gva53tn7hig',
        linkText: '帮助手册'
    },
    kanban: {
        title: VIEW_BLOCK_NAME_MAP['kanban'],
        describe: '看板视图下，记录按照某个状态值进行分组，并以卡片的形式显示在对应的分组下，一个卡片代表一个记录。',
        file: getAssetUrl('question', 'kanban.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lzib2wafu4yv7yfv',
        linkText: '帮助手册'
    },
    advancedTable: {
        title: VIEW_BLOCK_NAME_MAP['advancedTable'],
        describe: '以表格的方式来展示和使用数据，高级表格视图可直接在表格上进行数据编辑。',
        file: getAssetUrl('question', 'advancedTable.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/rwhl3a6gds209u3b',
        linkText: '帮助手册'
    },
    calendar: {
        title: VIEW_BLOCK_NAME_MAP['calendar'],
        describe: '用于管理、查看和时间相关的记录数据。日历组件是一种特殊类型的视图组件，可以根据指定的日期和时间在日历上显示日程。',
        file: getAssetUrl('question', 'calendar.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zhz46fz8coiegcc7',
        linkText: '帮助手册'
    },
    'text-input': {
        title: FIELD_BLOCK_NAME_MAP['text'],
        file: getAssetUrl('question', 'text_input.svg'),
        describe: '表单当中常用的组件，允许用户输入和编辑文本数据。通常用于网页或表单中接收用户填写的文字内容。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/qw2chupigydsd8ai',
        linkText: '帮助手册'
    },
    'checkbox-input': {
        title: FIELD_BLOCK_NAME_MAP['checkbox'],
        describe: '可以通过点击勾选或取消勾选该选项。',
        file: getAssetUrl('question', 'checkbox_input.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/qw2chupigydsd8ai',
        linkText: '帮助手册'
    },
    'email-input': {
        title: FIELD_BLOCK_NAME_MAP['email'],
        describe: '用于接收电子邮件地址输入的输入框组件，确保输入的内容符合电子邮件地址的格式规范。',
        file: getAssetUrl('question', 'email_input.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/ydbfuwq1cgl2vd07',
        linkText: '帮助手册'
    },
    'number-input': {
        title: FIELD_BLOCK_NAME_MAP['number'],
        file: getAssetUrl('question', 'number_input.svg'),
        describe: '用于记录数字类型的数据信息，如金额、年龄等。此字段录入的信息可以进行计算、统计、汇总等。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/evmfmt7g25n89fhy',
        linkText: '帮助手册'
    },
    'url-input': {
        title: FIELD_BLOCK_NAME_MAP['url'],
        describe: '用于接受和处理链接输入框组件，解决应用于各种需要输入和提交链接的场景。',
        file: getAssetUrl('question', 'url_input.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hgwdgds3aihmyl09',
        linkText: '帮助手册'
    },
    'phoneNumber-input': {
        title: FIELD_BLOCK_NAME_MAP['phoneNumber'],
        describe: '用于接收电话号码的输入框组件。确保用户输入的内容符合电话号码的格式规范。',
        file: getAssetUrl('question', 'phoneNumber_input.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zu99ws6690pszyi2',
        linkText: '帮助手册'
    },
    'relativeSelect-input': {
        title: FIELD_BLOCK_NAME_MAP['relativeSelect'],
        file: getAssetUrl('question', 'relativeSelect_input.png'),
        describe: '可以展示多个选项以供选择，选择器组件可以以下拉菜单、平铺或选框的三种展现形式。',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hpex7gz9g216u6f5',
        linkText: '帮助手册'
    },
    'cascade-input': {
        title: FIELD_BLOCK_NAME_MAP['cascade'],
        describe: '可以逐级选择数据表中的数据，在每次做出选择后，级联选择组件会自动更新后续选项的列表。',
        file: getAssetUrl('question', 'cascade_input.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/stwbq9ketwt2c0np',
        linkText: '帮助手册'
    },
    'person-input': {
        title: FIELD_BLOCK_NAME_MAP['person'],
        describe: '可直接获取用户表中的成员，包括成员单选和成员多选。',
        file: getAssetUrl('question', 'person_input.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lzzz647lizp6y8f8',
        linkText: '帮助手册'
    },
    'date-input': {
        title: FIELD_BLOCK_NAME_MAP['date'],
        file: getAssetUrl('question', 'date_input.svg'),
        describe: '用于接收和处理日期输入。确保输入的日期格式正确。  ',
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/nmxrh8gos8vy97mq',
        linkText: '帮助手册'
    },
    'file-input': {
        title: FIELD_BLOCK_NAME_MAP['file'],
        describe: '用于上传文件信息，且支持多种文件类型包括图片、PDF、Excel、Word等。',
        file: getAssetUrl('question', 'file_input.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/mkem5nrnq22m9yd3',
        linkText: '帮助手册'
    },
    'notes-input': {
        title: FIELD_BLOCK_NAME_MAP['notes'],
        describe:
            '用于存储和管理详细文本信息的输入框。在表单中，笔记字段支持编辑功能，可以通过点击输入框展开弹窗进行详细文本编写、插入图片等操作。',
        file: getAssetUrl('question', 'note_input.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/mtwrgw6dkr3gl1mx',
        linkText: '帮助手册'
    },
    'slider-input': {
        title: FIELD_BLOCK_NAME_MAP['slider'],
        describe: '用于录入某类数据的进度或占比等。\r\n可通过滑块、输入数值来选择数值或范围。',
        file: getAssetUrl('question', 'slider_input.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hf4bnhtzdddmlk9a',
        linkText: '帮助手册'
    },
    horizontal: {
        title: '列容器',
        describe: '容器内的组件会横向排列，是构建应用的必备容器。可使用颜色、文本、图片等内容样式丰富你的界面。',
        file: getAssetUrl('question', 'horizontal.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zkphot9fsluxcr2t',
        linkText: '帮助手册'
    },
    vertical: {
        title: '行容器',
        describe: '容器内的组件会纵向排列，是构建应用的必备容器。可使用颜色、文本、图片等内容样式丰富你的界面。',
        file: getAssetUrl('question', 'vertical.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zkphot9fsluxcr2t',
        linkText: '帮助手册'
    },
    text: {
        title: BLOCK_NAME_MAP['text'],
        describe: '文本组件适用于任何标题、正文、说明性文本等，需要调整字体颜色、大小使用，可以满足不同场景下的信息展示需求。',
        file: getAssetUrl('question', 'text.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/sh7kh1ewpg0ag7rb',
        linkText: '帮助手册'
    },
    breadcrumb: {
        title: BLOCK_NAME_MAP['breadcrumb'],
        describe: '用于网站的一种导航元素。通常以文本链的形式在页面顶部，点击可快速返回至上一级页面。',
        file: getAssetUrl('question', 'breadcrumb.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/yhycu8z0cewq3gz1',
        linkText: '帮助手册'
    },
    button: {
        title: BLOCK_NAME_MAP['button'],
        describe:
            '用于引导用户触发对应的执行动作。通过明确的用语告诉用户点击后产生的动作，例如：购买、查看详情等，通过修改按钮的文本、样式、动作达到你想要的效果。',
        file: getAssetUrl('question', 'buttonGroup.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hoquaupxllnronrw',
        linkText: '帮助手册'
    },
    tabs: {
        title: BLOCK_NAME_MAP['tabs'],
        describe: '需要与容器绑定使用。绑定后可通过切换标签栏查看被绑定容器中不同面板的内容。',
        file: getAssetUrl('question', 'tabs.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/bxp9iy5ii8olyve2',
        linkText: '帮助手册'
    },
    filter: {
        title: BLOCK_NAME_MAP['filter'],
        describe: '可同时筛选多个视图、图表的数据。需要选择你想要筛选的字段，并将这些字段与筛选控制器中的筛选项绑定起来使用。',
        file: getAssetUrl('question', 'filter.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zk5l3kkp53bu6vwk',
        linkText: '帮助手册'
    },
    image: {
        title: BLOCK_NAME_MAP['image'],
        describe: '用于静态或轮播展示图像。可使用本地上传或变量数据，并且可以配置点击图片后的动作，仅支持上传最大5MB的JPG、GIF、PNG文件。',
        file: getAssetUrl('question', 'image.jpg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/pk4fio1ogkx7np40',
        linkText: '帮助手册'
    },
    video: {
        title: BLOCK_NAME_MAP['video'],
        describe: '通过输入视频链接嵌入或本地上传来展示视频。',
        file: getAssetUrl('question', 'video.jpg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/zamwdmrqq6amgep4',
        linkText: '帮助手册'
    },
    composite: {
        title: CHART_BLOCK_NAME_MAP['composite'],
        describe:
            '通过图表可视化数据。复合图表支持柱状图，以及折线图的复合显示，支持从多维度，多指标进行数据分析，让数据的显示能更加丰富，数据的分析更加清晰。',
        file: getAssetUrl('question', 'composite.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/eniubqn4zwgelfrh',
        linkText: '帮助手册'
    },
    bar: {
        title: CHART_BLOCK_NAME_MAP['bar'],
        describe: '用于显示某一类别在特定时间段内的数据变化，或多个类别之间的数据比较。',
        file: getAssetUrl('question', 'bar.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/wn000sso366w8vxx',
        linkText: '帮助手册'
    },
    funnel: {
        title: CHART_BLOCK_NAME_MAP['funnel'],
        describe: '由多个梯形从上而下叠加而成。从上到下的项有逻辑上的顺序关系，梯形面积表示某个业务量与上一个环节之间的差异。',
        file: getAssetUrl('question', 'funnel.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/lrhofisvutxgn9hw',
        linkText: '帮助手册'
    },
    striation: {
        title: CHART_BLOCK_NAME_MAP['striation'],
        describe: '与柱状图类似，属于横向柱形图。用来显示某一类别在特定时间段内的数据变化或多个类别之间的数据比较。',
        file: getAssetUrl('question', 'striation.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/dk7tw3krrx334klr',
        linkText: '帮助手册'
    },
    indicator: {
        title: CHART_BLOCK_NAME_MAP['indicator'],
        describe: '对符合条件范围的数据进行统计得到一个数字（求和，平均值，最大值，最小值）， 是数据最直观的体现。',
        file: getAssetUrl('question', 'indicator.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/socyg0bi2zb5ikmx',
        linkText: '帮助手册'
    },
    pie: {
        title: CHART_BLOCK_NAME_MAP['pie'],
        describe: '用一个整圆表示总数，用每个环段的大小表示各项数据占总数的百分比，可以很清楚地表示各项目数据与总数之间的比例关系。',
        file: getAssetUrl('question', 'pie.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hb47wpxx7kp0lh05',
        linkText: '帮助手册'
    },
    line: {
        title: CHART_BLOCK_NAME_MAP['line'],
        describe: '将每项的数值在图中用点标记，然后用线连接起来。不但可以表示出数据的多少，还能清楚地显示数据的变化趋势。 ',
        file: getAssetUrl('question', 'line.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/iiuiait1bohm0vys',
        linkText: '帮助手册'
    },
    collapse: {
        title: BLOCK_NAME_MAP['collapse'],
        describe: '用于在有限的空间内有效地组织和显示大量内容。它通常由多个折叠部分组成，可以点击标题，展开或折叠相关内容区域。',
        file: getAssetUrl('question', 'collapse.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/xmyacwg2kz95rb1i',
        linkText: '帮助手册'
    },
    fieldGroup: {
        title: BLOCK_NAME_MAP['fieldGroup'],
        describe: '常用于编辑页、详情页中，当页面已选择数据表之后，拖拽一个字段组组件，自动获取字段内容并展示信息。',
        file: getAssetUrl('question', 'fieldGroup.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/gpka3tug263143tr',
        linkText: '帮助手册'
    },
    card: {
        title: BLOCK_NAME_MAP['card'],
        describe: '用于向用户展示网站或应用的功能，通过图像和文字的结合来提供直观的信息。',
        file: getAssetUrl('question', 'card.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/eqgzwxxgao2m6fa5',
        linkText: '帮助手册'
    },
    qrBarcode: {
        title: BLOCK_NAME_MAP['qrBarcode'],
        describe:
            '通过输入信息（如数字、文字、网址链接等）来生成条码。条码生成后，用户可以将其嵌入到页面中，可在移动端实现无需编写代码的情况下生成、扫描和管理条形码一体化操作。',
        file: getAssetUrl('question', 'qrBarcode.svg'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hy9ud0qo2fs2svxf',
        linkText: '帮助手册'
    },
    relatedView: {
        title: BLOCK_NAME_MAP['relatedView'],
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    divider: {
        title: BLOCK_NAME_MAP['divider'],
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    iframe: {
        title: BLOCK_NAME_MAP['iframe'],
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    file: {
        title: '',
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    subForm: {
        title: '',
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    userCenter: {
        title: '',
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    icon: {
        title: '',
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    combinationTitle: {
        title: '',
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    highlight: {
        title: '',
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    capsuleButton: {
        title: '胶囊按钮',
        describe: '用于引导用户触发对应的执行动作，此按钮初始为文本和图标的胶囊样式。',
        file: getAssetUrl('question', 'capsuleButton.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hoquaupxllnronrw',
        linkText: '帮助手册'
    },
    iconButton: {
        title: '图标按钮',
        describe: '用于引导用户触发对应的执行动作，此按钮仅有图标展示。',
        file: getAssetUrl('question', 'iconButton.mp4'),
        link: 'https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/hoquaupxllnronrw',
        linkText: '帮助手册'
    },
    floatBox: {
        title: '',
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    },
    onOff: {
        title: '开关按钮',
        describe: '',
        // file: getAssetUrl('question', 'tabBar.jpg'),
        link: '',
        linkText: '帮助手册'
    }
}
