import { Flex, Group, IconFont, Input, Select, Switch } from '@byecode/ui'
import type { DataSourceAbstract, ViewOptions } from '@lighthouse/core'
import { getFieldIcon, getIsAppointField, getViewColumns, ListItem4ByecodeUi, PRESET_PALETTES, SYSTEM_FIELD } from '@lighthouse/shared'
import type { FC } from 'react'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { FieldSelect } from '@/components/FieldSelect'

const colorModeOptions = [
    {
        label: '跟随字段颜色',
        value: 'follow'
    },
    {
        label: '自定义颜色',
        value: 'custom'
    }
]

interface CalendarStyleSettingsProps {
    dataSource: DataSourceAbstract | undefined
}

export const CalendarStyleSettings: FC<CalendarStyleSettingsProps> = ({ dataSource }) => {
    const { control, watch, getValues, register } = useFormContext<ViewOptions & { title?: string }>()
    const [showTitle, scheduleColorMode] = watch(['showTitle', 'scheduleColorMode'])

    const { schema = {}, viewOptions: { tableProps = [] } = {} } = dataSource ?? {}
    const viewFieldSettings = getValues('viewFieldSettings')
    const columns = getViewColumns({
        tableProps,
        value: viewFieldSettings,
        schema
    })

    const options = useMemo(
        () =>
            columns
                .filter(item => item.type === 'select' && !getIsAppointField(item.fieldId, SYSTEM_FIELD))
                .map(item => ({
                    label: item.title,
                    value: item.fieldId,
                    icon: <IconFont type={getFieldIcon(item.fieldId, item.type, item.innerType)} size={16} />
                })),
        [columns]
    )

    return (
        <Group label="基础">
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <div>显示视图标题</div>
                <Controller
                    control={control}
                    name="showTitle"
                    render={({ field: { value, onChange, ...restField } }) => (
                        <Switch checked={value} onChange={e => onChange(e.target.checked)} {...restField} />
                    )}
                />
            </ListItem4ByecodeUi>
            {showTitle ? (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <div>视图标题</div>
                    <Input style={{ width: 180 }} {...register('title')} />
                </ListItem4ByecodeUi>
            ) : null}

            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <div>颜色显示</div>
                <Controller
                    control={control}
                    name="scheduleColorMode"
                    defaultValue="follow"
                    render={({ field }) => (
                        <FieldSelect dsId={dataSource?.id} {...field} options={colorModeOptions} style={{ width: 180 }} />
                    )}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi justifyContent="flex-end" alignItems="center">
                {scheduleColorMode === 'custom' ? (
                    <Controller control={control} name="scheduleColor" render={({ field }) => <ColorPicker {...field} />} />
                ) : (
                    <Controller
                        control={control}
                        name="scheduleColorField"
                        render={({ field }) => (
                            <Select placeholder="请选择" value={field.value} onChange={field.onChange} options={options} style={{ width: 180 }} />
                        )}
                    />
                )}
            </ListItem4ByecodeUi>
        </Group>
    )
}

const ColorItem = styled.div<{ color: string; selected: boolean }>`
    cursor: pointer;
    position: relative;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${({ color }) => color};
    ${({ selected, color }) =>
        selected &&
        css`
            &::after {
                content: '';
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                width: 22px;
                height: 22px;
                border-radius: 50%;
                outline: 1px solid ${color};
            }
        `}
`

interface ColorPickerProps {
    value?: string
    onChange?: (value: string) => void
}

const ColorPicker = ({ value, onChange }: ColorPickerProps) => {
    return (
        <Flex alignItems="center" justifyContent="space-around" styles={{ root: { flex: 1 } }}>
            {Object.values(PRESET_PALETTES).map((color, index) => (
                <ColorItem
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    color={color}
                    selected={color === value}
                    onClick={() => onChange?.(color)}
                    tabIndex={-1}
                    role="radio"
                />
            ))}
        </Flex>
    )
}
