import { Divider, Group } from '@byecode/ui'
import type { Field, TextBlockConfig } from '@lighthouse/core'
import { BACKGROUND_TYPE } from '@lighthouse/core'
import type { RichTextEditorProps } from '@lighthouse/shared'
import {
    DATE_SYSTEM,
    getIsFindUseInRichText,
    getPageDataOptions,
    getSystemOption,
    getUserDatasourceOptions,
    RichTextEditor,
    useAtomData,
    useFindUseObjectContext,
    USER_DATASOURCE
} from '@lighthouse/shared'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled, { css } from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { getBlockAndPageDesignLimit, StyleSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { FontSetting } from '@/components/DesignSetting/FontSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'

const Container = styled.div<{ highlighting?: boolean }>`
    margin: 8px 0;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    .ProseMirror {
        min-height: 120px;
    }

    ${({ highlighting }) =>
        highlighting &&
        css`
            border-color: var(--color-main);
            box-shadow: 0 0 4px 0 var(--color-main);
            overflow: hidden;
        `}
`

const filterField = (field: Field) => !['file', 'video', 'notes'].includes(field.type)
const filterObjectUndefined = <T extends object>(v: undefined | T): v is T => !!v

export const TextBlockSetting: React.FC = () => {
    const designProps = getBlockAndPageDesignLimit('text')
    const { control, setValue, getValues } = useFormContext<
        Omit<TextBlockConfig, 'content'> & { content: TextBlockConfig['content']['value'] }
    >()

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    const findUseObject = useFindUseObjectContext()
    const disabledWithVersion = useIsDisabledWithVersion()

    /** ********************************* 富文本变量 start ********************************** */
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const {
        prev,
        curr: { variableSourceType, datasource },
        dataSourceList
    } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const options = useMemo(() => {
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filterField
            },
            datasource && {
                variableSourceType,
                datasource,
                validateFieldType: filterField
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [datasource, prev.datasource, prev.variableSourceType, variableSourceType])
    const systemOption = useMemo(() => getSystemOption(DATE_SYSTEM), [])
    const userOption = useMemo(
        () =>
            getUserDatasourceOptions({
                dataSource: dataSourceList.find(item => item.id === USER_DATASOURCE),
                validateFieldType: filterField
            }),
        [dataSourceList]
    )
    const { customViewOption } = useVariableCustomViewOption()

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({
            heading: false,
            bulletList: false,
            orderedList: false,
            taskList: false,
            fontSize: false,
            variable: {
                options,
                userOption,
                systemOption,
                viewOption: customViewOption,
                dataSources: dataSourceList
            },
            image: false
        }),
        [customViewOption, dataSourceList, options, systemOption, userOption]
    )
    /** ********************************* 富文本变量 end ********************************** */

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <FontSetting {...designProps.font} />
                    <Divider />
                    <StyleSetting {...designProps.design} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="内容">
                        <Controller
                            control={control}
                            name="content"
                            render={({ field }) => {
                                const isHighLight = getIsFindUseInRichText({
                                    doc: field.value,
                                    findUseObject,
                                    currentDsId: datasource?.id
                                })
                                return (
                                    <Container highlighting={isHighLight}>
                                        <RichTextEditor
                                            disabled={disabledWithVersion}
                                            config={config}
                                            value={field.value}
                                            onChange={field.onChange}
                                            styles={{
                                                editorContent: {
                                                    backgroundColor: 'var(--color-gray-50)'
                                                }
                                            }}
                                        />
                                    </Container>
                                )
                            }}
                        />
                    </Group>
                    <Divider />
                    <VisibilitySetting />
                </>
            )}
            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}
        </>
    )
}
