import { Button } from '@byecode/ui'
import styled from 'styled-components'

export const DataSourceContainer = styled.div`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
`

export const DataSourceWrapper = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
    width: 100%;
`

export const DataSourceContent = styled.div`
    position: relative;
    width: 100%;
    padding: 0 0 42px 0;
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow: hidden;
`

export const LoadingContainer = styled.div`
    width: 100%;
    min-width: 574px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

export const DataSourceHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 2;
    background-color: var(--color-gray-100);
    padding: 8px 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`

export const DataSourceTitle = styled.div`
    font-weight: var(--font-weight-bold);
    font-size: var(--font-size-base);
    color: var(--color-black);
    white-space: nowrap;
`

export const DataSourceBody = styled.div`
    width: 100%;
    padding: 0 24px;
    /* display: flex;
    flex: 1;
    flex-direction: column; */
    /* border-left: 1px solid var(--color-gray-200); */
    /* border-right: 1px solid var(--color-gray-200); */
    border-radius: 8px;
    height: calc(100% - 75px);
`

export const ContentLoading = styled.div<{ height?: number }>`
    height: ${({ height }) => (height ? `${height}px` : '100%')};
    display: flex;
    justify-content: center;
    align-items: center;
`

export const SuspendWrapper = styled.div`
    position: absolute;
    bottom: 44px;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const Handle = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
`

export const RecordDelete = styled(Button)`
    background-color: #fff2f0;
    color: #d88987;
    border-color: #d88987;
    margin-right: 10px;
`

export const DataSourceFooter = styled.div`
    width: 100%;
    height: 42px;
    border-top: 1px solid var(--color-gray-200);
    padding: 0 18px;
    left: 0;
    background-color: var(--color-white);
    position: absolute;
    bottom: 0;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
`

export const TablePageCount = styled.div`
    font-size: 12px;
    height: 14px;
    line-height: 14px;
    color: var(--color-gray-400);
    :hover {
        color: var(--color-black);
    }
`
export const JoinCreateButton = styled(Button)`
    position: relative;
    background-color: var(--color-white);
    font-size: var(--font-size-normal);
    margin-left: 8px;
    /* &:after {
        content: '实验';
        position: absolute;
        top: -12px;
        right: -12px;
        padding: 2px 4px;
        font-size: 10px;
        color: #1570EF;
        border-radius: 3px;
        background: linear-gradient(to right, #D1E9FF, #B1DAFF);
    } */
`
export const EditButton = styled(Button)`
    font-size: var(--font-size-normal);
    margin-left: 8px;
`
