import { Flex, IconFont, Loading, Text, Tooltip } from '@byecode/ui'
import type { Field, FileValue, VariableADTvalue } from '@lighthouse/core'
import { VariableType } from '@lighthouse/core'
import { RingProgress } from '@mantine/core'
import React, { useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { FILE_ICON_MAP } from '../../../../constants'
import { useApplicationContext } from '../../../../contexts'
import { getFileNameByUrl, getFileTypeByUrl, getImageFullUrlInApplication } from '../../../../utils'
import { TagIcon } from '../../../TagIcon'
import { DEFAULT_FILTER_VALUE_VARIABLE } from '../../constant'
import * as SC from './styles'

interface FileInputProps {
    data?: FileValue
    field?: Field
    placeholder?: string
    opened?: boolean
    disabled?: boolean
    filePercent?: number
    onChange: (value: VariableADTvalue) => void
    onChangeOpen: () => void
    onCancelUpload: () => void
}

const SCxItemContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    background-color: var(--color-gray-100);
    padding: 0 4px 0 8px;
    height: 32px;
    overflow: hidden;
    border-radius: 6px;
`

const SCxInput = styled.input`
    flex: 1;
    width: 100%;
    overflow: hidden;

    &::placeholder {
        color: var(--color-gray-400);
    }
`

export const FileInput: React.FunctionComponent<FileInputProps> = ({
    data,
    field,
    placeholder = '粘贴链接',
    opened,
    disabled,
    filePercent,
    onChange,
    onChangeOpen,
    onCancelUpload
}) => {
    const fileUrl = data?.[0] ?? ''
    const name = getFileNameByUrl(fileUrl) || ''
    const isUploading = filePercent !== -1
    const type = getFileTypeByUrl(fileUrl)
    const [state, setState] = useState(fileUrl)
    const { appId } = useApplicationContext()
    const fieldType: 'file' | 'video' = useMemo(
        () => (field && (field?.type === 'file' || field.type === 'video') ? field?.type : 'file'),
        [field]
    )

    useUpdateEffect(() => {
        !fileUrl && setState('')
    }, [fileUrl])

    const content = useMemo(() => {
        if (isUploading) {
            return (
                <Flex gap={8} alignItems="center" style={{ overflow: 'hidden', flex: 1 }}>
                    <Loading outlined />
                    <Text lineClamp={1} size={14} color="var(--color-gray-400)">
                        上传中...{Math.floor((filePercent === 100 ? 99 : filePercent) ?? 0)}%
                    </Text>
                </Flex>
            )
        }

        if (fileUrl) {
            return (
                <Flex gap={8} alignItems="center" style={{ overflow: 'hidden', flex: 1 }}>
                    {type === 'image' ? (
                        <img style={{ objectFit: 'contain', height: 22 }} src={getImageFullUrlInApplication(appId, state)} alt="" />
                    ) : (
                        <IconFont type={FILE_ICON_MAP[type]} />
                    )}
                    <Text lineClamp={1}>{name}</Text>
                </Flex>
            )
        }

        return (
            <SCxInput
                disabled={disabled}
                placeholder={disabled ? '-' : placeholder}
                value={state}
                onChange={e => {
                    setState(e.target.value)
                }}
                onBlur={e => {
                    onChange({
                        type: VariableType.VALUE,
                        valueVariable: {
                            type: fieldType,
                            value: [state]
                        }
                    })
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        onChange({
                            type: VariableType.VALUE,
                            valueVariable: {
                                type: fieldType,
                                value: [state]
                            }
                        })
                    }
                }}
            />
        )
    }, [isUploading, fileUrl, disabled, placeholder, state, filePercent, type, appId, name, onChange, fieldType])

    return (
        <SCxItemContainer>
            {content}
            <SC.IconWrapper>
                {(fileUrl || isUploading) && (
                    <SC.Icon
                        type="CloseCircle"
                        size={16}
                        isAction
                        onClick={e => {
                            e.stopPropagation()
                            if (isUploading) {
                                onCancelUpload()
                                return
                            }
                            setState('')
                            !disabled && onChange(DEFAULT_FILTER_VALUE_VARIABLE)
                        }}
                    />
                )}
                <Tooltip title="选择数据">
                    <TagIcon
                        icon="DotsThreeVertical"
                        size={24}
                        radius={5}
                        isActive={opened}
                        iconSize={16}
                        enableHover
                        hoverBackground="rgba(38, 65, 90, 0.06)"
                        onClick={() => !disabled && onChangeOpen?.()}
                    />
                </Tooltip>
            </SC.IconWrapper>
        </SCxItemContainer>
    )
}
