import type {
    ActionNodeType,
    ConditionNodeType,
    FlowEdge,
    FlowNode,
    FlowNodeButtonClickPayload,
    NodeTypes,
    OtherPlatformOption,
    WorkflowType
} from '@lighthouse/shared'
import {
    actionNodeCreatorCards,
    automationNodeCreatorCards,
    CollapseBox,
    extraNodeCreatorCard,
    subProcessNodeCreatorCards
} from '@lighthouse/shared'
import { Divider } from '@mantine/core'
import React, { useCallback, useMemo } from 'react'
import type { Edge } from 'reactflow'
import { useReactFlow } from 'reactflow'

import {
    addNewActionNodeAfterConditionWithEdges,
    addNewActionNodeBeforeConditionWithEdges,
    addNewActionNodeInnerConditionWithEdges,
    addNewActionNodeWithEdges,
    addNewConditionNodeWithEdges
} from '../utils/addNode'
import { OtherPlatformsNodeCreator } from './OtherPlatformsNodeCreator'
import * as SC from './styles'

interface NodeCreatorProps {
    flowType: WorkflowType
    edge: FlowNodeButtonClickPayload
    nodes: FlowNode[]
    onClose: () => void
    onNodesOrEdgesChange: (nodes: FlowNode[], edges: FlowEdge[], node?: FlowNode) => void
}

const getNewNode = (nodeType: NodeTypes, edge: FlowEdge | Edge, nodes: FlowNode[], edges: FlowEdge[]) => {
    const { source, target, type } = edge
    switch (nodeType) {
        case 'CONDITION': {
            return addNewConditionNodeWithEdges(nodes, edges, source, target, type === 'common')
        }
        case 'WX_ROBOT_ACTION':
        case 'DINGTALK_ROBOT_ACTION':
        case 'UPDATE_RECORD_ACTION':
        case 'CREATE_SINGLE_RECORD_ACTION':
        case 'CREATE_RECORD_ACTION':
        case 'FIND_SINGLE_RECORD_ACTION':
        case 'FIND_RECORD_ACTION':
        case 'OPEN_PAGE':
        case 'OPEN_LINK':
        case 'OPEN_FORM_PAGE':
        case 'OPEN_RECORD_PAGE':
        case 'OPEN_RECORD_EDIT_PAGE':
        case 'GO_BACK':
        case 'CALL':
        case 'NOTIFICATION':
        case 'COPY':
        case 'SEND_EMAIL_ACTION':
        case 'STATION_MESSAGE_ACTION':
        case 'DELETE_RECORD':
        case 'DOWNLOAD_FILE':
        case 'I_CLICK_CONTACT_ACTION':
        case 'ALIYUN_MESSAGE_ACTION':
        case 'WECHAT_PAY':
        case 'WECHAT_TEMPLATE_MSG_ACTION':
        case 'SHARE_TO_WECHAT':
        case 'STRIPE':
        case 'LOOP_ACTION':
        case 'REFRESH_PAGE':
        case 'CLOSE_FLOAT':
        case 'HANDLE_SUB_PROCESS': {
            // 动作流 action 节点
            return addNewActionNodeWithEdges(nodeType, nodes, edges, target)
        }
        default: {
            return { nodes: [], edges: [] }
        }
    }
}

export const NodeCreator: React.FC<NodeCreatorProps> = ({ flowType, edge, nodes, onClose, onNodesOrEdgesChange }) => {
    const { setEdges, setNodes, getNodes, getEdges } = useReactFlow()
    const { edge: edgeData, operator } = edge
    const { id: edgeId, source, target } = edgeData
    const isAutomationOrActionOrSubProcess = useMemo(() => flowType === 'automation' || flowType === 'action' || flowType === 'subProcess', [flowType])

    const isNotActionFlow = flowType === 'automation' || flowType === 'subProcess'

    const extraCreatorCards = useMemo(() => {
        const { options, ...rest } = extraNodeCreatorCard
        const extraCreatorCardOptions = options.reduce<OtherPlatformOption[]>((prev, cur) => {
            if (isNotActionFlow && cur.value === 'STRIPE') {
                return prev
            }
            if (isNotActionFlow && cur.value === 'WECHAT') {
                const optionChildren = cur.children.filter(item => item.value !== 'WECHAT_PAY')
                prev.push({
                    ...cur,
                    children: optionChildren
                })
                return prev
            }

            prev.push(cur)
            return prev
        }, [])

        return {
            ...rest,
            options: extraCreatorCardOptions
        }
    }, [isNotActionFlow])

    const creatorCards = useMemo(() => {
        switch (flowType) {
            case 'automation': {
                return automationNodeCreatorCards
            }
            case 'action': {
                return actionNodeCreatorCards
            }
            case 'subProcess': {
                return subProcessNodeCreatorCards
            }
            default: {
                return []
            }
        }
    }, [flowType])

    const handleAddNode = useCallback(
        (nodeType: NodeTypes) => {
            const nodes = getNodes() as FlowNode[]
            const edges = getEdges() as FlowEdge[]

            let newNodes: FlowNode[] = []
            let newEdges: FlowEdge[] = []

            switch (operator) {
                case 'common': {
                    const { nodes: nNodes, edges: nEdges } = getNewNode(nodeType, edgeData, nodes, edges)
                    newNodes = nNodes
                    newEdges = nEdges
                    break
                }
                case 'addBeforeCondition': {
                    const { nodes: nNodes, edges: nEdges } = addNewActionNodeBeforeConditionWithEdges(nodeType, nodes, edges, source)
                    newNodes = nNodes
                    newEdges = nEdges
                    break
                }
                case 'addCondition': {
                    const { nodes: nNodes, edges: nEdges } = addNewConditionNodeWithEdges(nodes, edges, source, target)
                    newNodes = nNodes
                    newEdges = nEdges
                    break
                }
                case 'addInCondition': {
                    const { nodes: nNodes, edges: nEdges } = addNewActionNodeInnerConditionWithEdges(nodeType, nodes, edges, target, edgeId)
                    newNodes = nNodes
                    newEdges = nEdges
                    break
                }
                case 'addAfterCondition': {
                    const { nodes: nNodes, edges: nEdges } = addNewActionNodeAfterConditionWithEdges(nodeType, nodes, edges, target, edgeId)
                    newNodes = nNodes
                    newEdges = nEdges
                    break
                }
                default: {
                    break
                }
            }

            const selectedNode = newNodes.find(node => node.selected)

            onNodesOrEdgesChange(newNodes, newEdges, selectedNode)

            setEdges(newEdges)
        },
        [edgeData, edgeId, getEdges, getNodes, onNodesOrEdgesChange, operator, setEdges, source, target]
    )

    const nodeCardGroup = creatorCards.map(({ groupId, title, options }, index) => {
        return (
            <CollapseBox key={groupId} label={title}>
                <SC.CreatorCardGroupWrapper>
                    {options.map(option => (
                        <SC.CreatorCardWrapper
                            key={option.value}
                            onClick={() => handleAddNode(option.value as ActionNodeType | ConditionNodeType)}
                        >
                            <SC.CreatorCardIconWrapper>
                                <SC.CreatorCardIcon type={option.icon} fill={option.color} />
                            </SC.CreatorCardIconWrapper>
                            <SC.CreatorCardLabel>{option.label}</SC.CreatorCardLabel>
                        </SC.CreatorCardWrapper>
                    ))}
                </SC.CreatorCardGroupWrapper>
                <Divider color="var(--color-gray-200)" />
            </CollapseBox>
        )
    })

    return (
        <SC.CreatorContainer>
            <SC.CreatorTitleWrapper>
                <SC.CreatorTitle>添加节点</SC.CreatorTitle>
                {/* <SC.CreatorIcon type="Close" onClick={onClose} /> */}
            </SC.CreatorTitleWrapper>
            <Divider color="var(--color-gray-200)" />
            <SC.CreatorContentWrapper>
                {nodeCardGroup}
                {/* 自动化与动作流有额外第三方平台配置 */}
                {isAutomationOrActionOrSubProcess && <OtherPlatformsNodeCreator {...extraCreatorCards} onAddNode={handleAddNode} />}
            </SC.CreatorContentWrapper>
        </SC.CreatorContainer>
    )
}
