import { IconFont } from '@byecode/ui'
import { Button } from '@byecode/ui/components/Button'
import type { DataSourceAbstract, EVENT_VARIABLE_TYPE, Field, RecordLikeProtocol } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable, FlowNode, PrevPageDatasourceForVariable, VariableSource } from '@lighthouse/shared'
import {
    getDefaultValueOptionsByInnerType,
    getEventOption,
    getFieldIcon,
    getFormDatasourceOptions,
    getUserDatasourceOptions,
    getViewOptions,
    NodeFieldPreview,
    pureTextFieldTypes,
    USER_DATASOURCE,
    VariableSourceType
} from '@lighthouse/shared'
import React, { useMemo } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { useFlow } from '@/contexts/FlowContext'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

const SCxFieldItemWrapper = styled.div`
    margin-bottom: 12px;
`

export const SCxHeader = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

const SCxNodeFieldPreviewerWrapper = styled.div`
    position: relative;
    flex: 1;
    margin-top: 2px;
    cursor: pointer;
`

export const SCxLabel = styled.div`
    flex: 1;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    height: 24px;
    overflow: hidden;
`

export const SCxIcon = styled(IconFont)``

export const SCxText = styled.div`
    flex: 1;
    margin-left: 6px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
`

interface FieldItemProps {
    appId: string
    dataSourceList: DataSourceAbstract[]
    prefixName: string
    field: Field
    allRecords?: RecordLikeProtocol[]
    allParentNodes?: FlowNode[]
    placeholder?: string
    autoHeight?: boolean
    disablePageLink?: boolean
    disableFormOption?: boolean
    disableViewOption?: boolean
    disablePageOption?: boolean
    eventType?: EVENT_VARIABLE_TYPE
    curr?: CurrPageDatasourceForVariable
    prev?: PrevPageDatasourceForVariable
    disableInput?: boolean
    onItemDelete?: () => void
}

// const AddVariableButton = React.forwardRef<HTMLDivElement>(({ ...props }, ref) => {
//     return (
//         <SCxAddVariableButton {...props} ref={ref}>
//             <SCxIcon type="Add" fill="var(--color-white)" size={16} />
//         </SCxAddVariableButton>
//     )
// })

export const FieldItem: React.FC<FieldItemProps> = ({
    appId,
    dataSourceList,
    prefixName,
    field,
    allParentNodes,
    placeholder,
    autoHeight = false,
    disablePageLink,
    disableFormOption,
    disableViewOption,
    disablePageOption,
    eventType,
    curr,
    prev,
    disableInput,
    onItemDelete
}) => {
    const { control } = useFormContext()

    const { dsId, isForm, viewType } = useActionAdderDepParams()
    const { type } = useFlow()
    const disabledWithVersion = useIsDisabledWithVersion()

    const isInFlow = !!type
    const isNotAutomation = type !== 'automation'

    const { customViewOption } = useVariableCustomViewOption(validatedField => field.innerType === validatedField.innerType)

    const dataSource = useMemo(() => dataSourceList.find(ds => ds.id === dsId), [dataSourceList, dsId])

    const formOption = useMemo(
        () =>
            !disableFormOption && isForm && !isInFlow
                ? getFormDatasourceOptions({
                      dataSource,
                      validateFieldType: validatedField => validatedField.innerType === field.innerType
                  })
                : undefined,
        [dataSource, disableFormOption, field.innerType, isForm, isInFlow]
    )

    const eventOption = useMemo(() => eventType && getEventOption(eventType, field.innerType), [eventType, field.innerType])

    const viewOption = useMemo(() => {
        if (isInFlow || disableViewOption) {
            return
        }
        return (
            customViewOption ||
            getViewOptions({
                dataSource,
                viewType,
                validateFieldType: validatedField => validatedField.innerType === field.innerType
            })
        )
    }, [isInFlow, disableViewOption, customViewOption, dataSource, viewType, field.innerType])

    const userOption = useMemo(
        () =>
            getUserDatasourceOptions({
                dataSource: dataSourceList.find(item => item.id === USER_DATASOURCE),
                validateFieldType: validatedField => validatedField.innerType === field.innerType
            }),
        [dataSourceList, field]
    )

    const enablePageLinkOption = !disablePageLink && type !== 'automation' && pureTextFieldTypes.has(field.type)

    const options = useMemo(() => {
        // if (!dataSource) {
        //     return
        // }

        return getDefaultValueOptionsByInnerType({
            sources: [
                isInFlow && {
                    sourceType: VariableSourceType.parentNode,
                    parentNodes: allParentNodes,
                    dataSourceList
                },
                isNotAutomation &&
                    prev &&
                    !disablePageOption && {
                        sourceType: VariableSourceType.parentPage,
                        dataSource: prev.datasource,
                        page: prev.page
                    },
                isNotAutomation &&
                    curr &&
                    !disablePageOption && {
                        sourceType: VariableSourceType.page,
                        dataSource: curr.datasource,
                        page: curr.page
                    }
            ].filter(Boolean) as VariableSource[],
            validateInnerType: innerType => field.innerType === innerType
        })
    }, [isInFlow, allParentNodes, dataSourceList, isNotAutomation, prev, curr, disablePageOption, field.innerType])

    return (
        <SCxFieldItemWrapper>
            <SCxHeader>
                <SCxLabel>
                    <SCxIcon type={getFieldIcon(field.id, field.type, field.innerType)} color="var(--color-gray-400)" />
                    <SCxText>{field.name}</SCxText>
                </SCxLabel>
                <Button type="text" onClick={onItemDelete} icon={<SCxIcon type="Trash" color="var(--color-gray-400)" />} />
            </SCxHeader>
            <SCxNodeFieldPreviewerWrapper style={{ height: autoHeight ? 'auto' : 36 }}>
                <Controller
                    name={prefixName}
                    control={control}
                    render={({ field: ctlField }) => {
                        return (
                            <NodeFieldPreview
                                disabled={disabledWithVersion}
                                autoHeight={autoHeight}
                                onChange={ctlField.onChange}
                                value={ctlField.value}
                                placeholder={placeholder}
                                field={field}
                                enablePageLink={enablePageLinkOption}
                                formOption={formOption}
                                eventOption={eventOption}
                                userOption={userOption}
                                options={options}
                                viewOption={viewOption}
                                disableInput={disableInput}
                            />
                        )
                    }}
                />
            </SCxNodeFieldPreviewerWrapper>
        </SCxFieldItemWrapper>
    )
}
