
// view Cache quickFilter

import type { FilterBlockIdentify, FilterBlockValue, FilterCommonCondition, FilterFormType, GroupConfigure, KanbanColumnsSort, Sorter, TableColumns, TableColumnWidth } from "@lighthouse/core"
import { getTimestampAboutWeekAfter } from "@lighthouse/shared"
import { getCachedItem, setCachedItem } from "@lighthouse/tools"
import { atom } from "jotai"
import { atomFamily, atomWithStorage } from "jotai/utils"
import { findIndex } from "rambda"
import shallowEqual from 'shallowequal'

import type { CacheFilterBlockIdentity, CacheFilterBlocksItemIdsIdentity, CacheIdentity, CacheKey, FilterBlockCacheAtom, FilterCacheAtom, GroupCacheAtom, KanbanColumnsSortAtom, QuickFilterCacheAtom, SetFilterBlockCachePayload, SortCacheAtom, TableColumnAtom, TablePropsCacheAtom } from "./types"

const initGroupData = {
    groupByFieldId: '',
    canHiddenTabValue: false,
    groupConfig: []
}

// view Cache filter
export const filterCacheAtom = atomWithStorage<FilterCacheAtom>('filter', getCachedItem('filter', {}))

export const filterCacheAtomFamily = atomFamily((params: CacheIdentity) => {
    const { appId, envId, id } = params
    const key: CacheKey = `${appId}-${envId}-${id}`
    let isInit = false
    return atom(get => {
        const filter = get(filterCacheAtom)

        const currentFilter = filter[key]
        if (!currentFilter) {
            return
        }
        if (!isInit) {
            currentFilter.expires = getTimestampAboutWeekAfter()
            setCachedItem('dsSetting', filter)
            isInit = true
        }
        return currentFilter?.value


    }, (get, set, payload: FilterFormType | undefined) => {
        const allFilter = { ...get(filterCacheAtom) }
        const current = allFilter[key]
        if (current) {
            current.value = payload
            set(filterCacheAtom, allFilter)
            return
        }
        allFilter[key] = {
            expires: getTimestampAboutWeekAfter(),
            value: payload
        }
        set(filterCacheAtom, allFilter)

    })
}, shallowEqual)


// view Cache quickFilter
export const quickFilterCacheAtom = atomWithStorage<QuickFilterCacheAtom>('viewQuickFilter', getCachedItem('viewQuickFilter', {}))

export const quickFilterCacheAtomFamily = atomFamily((params: CacheIdentity) => {
    const { appId, envId, id } = params
    const key: CacheKey = `${appId}-${envId}-${id}`
    let isInit = false
    return atom(get => {
        const quickFilterCache = get(quickFilterCacheAtom)

        const currentDataSourceSetting = quickFilterCache[key]
        if (!currentDataSourceSetting) {
            return []
        }
        if (!isInit) {
            currentDataSourceSetting.expires = getTimestampAboutWeekAfter()
            setCachedItem('viewQuickFilter', quickFilterCache)
            isInit = true
        }
        return currentDataSourceSetting.value

    }, (get, set, payload: FilterCommonCondition[] | undefined) => {
        const newQuickFilterCache = { ...get(quickFilterCacheAtom) }
        const current = newQuickFilterCache[key]
        if (!payload) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete newQuickFilterCache[key]
            set(quickFilterCacheAtom, newQuickFilterCache)
            return
        }
        if (current) {
            current.value = payload
            set(quickFilterCacheAtom, newQuickFilterCache)
            return
        }
        newQuickFilterCache[key] = {
            expires: getTimestampAboutWeekAfter(),
            value: payload
        }
        set(quickFilterCacheAtom, newQuickFilterCache)

    })
}, shallowEqual)

// view Cache tableProps
export const tablePropsCacheAtom = atomWithStorage<TablePropsCacheAtom>('viewTableProps', getCachedItem('viewTableProps', {}))


export const tablePropsCacheAtomFamily = atomFamily((params: CacheIdentity) => {
    const { appId, envId, id } = params
    const key: CacheKey = `${appId}-${envId}-${id}`
    let isInit = false
    return atom(get => {
        const tablePropsCache = get(tablePropsCacheAtom)

        const currentTableProps = tablePropsCache[key]
        if (!currentTableProps) {
            return []
        }
        if (!isInit) {
            currentTableProps.expires = getTimestampAboutWeekAfter()
            setCachedItem('viewTableProps', tablePropsCache)
            isInit = true
        }
        return currentTableProps?.value


    }, (get, set, payload: TableColumns | undefined) => {
        const newTablePropsCache = { ...get(tablePropsCacheAtom) }
        const current = newTablePropsCache[key]
        if (!payload) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete newTablePropsCache[key]
            set(tablePropsCacheAtom, newTablePropsCache)
            return
        }
        if (current) {
            current.value = payload
            set(tablePropsCacheAtom, newTablePropsCache)
            return
        }
        newTablePropsCache[key] = {
            expires: getTimestampAboutWeekAfter(),
            value: payload
        }
        set(tablePropsCacheAtom, newTablePropsCache)

    })
}, shallowEqual)


// Cache sort
export const sortsCacheAtom = atomWithStorage<SortCacheAtom>('viewSort', getCachedItem('viewSort', {}))


export const sortsCacheAtomFamily = atomFamily((params: CacheIdentity) => {
    const { appId, envId, id } = params
    const key: CacheKey = `${appId}-${envId}-${id}`
    let isInit = false
    return atom(get => {
        const sortCache = get(sortsCacheAtom)

        const currentSort = sortCache[key]
        if (!currentSort) {
            return []
        }
        if (!isInit) {
            currentSort.expires = getTimestampAboutWeekAfter()
            setCachedItem('viewSort', sortCache)
            isInit = true
        }
        return currentSort.value


    }, (get, set, payload: Sorter[]) => {
        const newSortCache = { ...get(sortsCacheAtom) }
        const current = newSortCache[key]
        if (current) {
            current.value = payload
            set(sortsCacheAtom, newSortCache)
            return
        }
        newSortCache[key] = {
            expires: getTimestampAboutWeekAfter(),
            value: payload
        }
        set(sortsCacheAtom, newSortCache)

    })
}, shallowEqual)



// view Cache group
export const groupCacheAtom = atomWithStorage<GroupCacheAtom>('viewGroup', getCachedItem('viewGroup', {}))


export const groupCacheAtomFamily = atomFamily((params: CacheIdentity) => {
    const { appId, envId, id } = params
    const key: CacheKey = `${appId}-${envId}-${id}`
    let isInit = false
    return atom(get => {
        const groupCache = get(groupCacheAtom)

        const currentGroup = groupCache[key]
        if (!currentGroup) {
            return initGroupData
        }
        if (!isInit) {
            currentGroup.expires = getTimestampAboutWeekAfter()
            setCachedItem('viewGroup', groupCache)
            isInit = true
        }
        return currentGroup.value


    }, (get, set, payload: GroupConfigure) => {
        const newGroupCache = { ...get(groupCacheAtom) }
        const current = newGroupCache[key]
        if (current) {
            current.value = payload
            set(groupCacheAtom, newGroupCache)
            return
        }
        newGroupCache[key] = {
            expires: getTimestampAboutWeekAfter(),
            value: payload
        }
        set(groupCacheAtom, newGroupCache)

    })
}, shallowEqual)


// view Cache tableColumn
export const tableColumnCacheAtom = atomWithStorage<TableColumnAtom>('viewTableColumn', getCachedItem('viewTableColumn', {}))


export const tableColumnCacheAtomFamily = atomFamily((params: CacheIdentity) => {
    const { appId, envId, id } = params
    const key: CacheKey = `${appId}-${envId}-${id}`
    let isInit = false
    return atom(get => {
        const tableColumnCache = get(tableColumnCacheAtom)

        const currentTableColumn = tableColumnCache[key]
        if (!currentTableColumn) {
            return
        }
        if (!isInit) {
            currentTableColumn.expires = getTimestampAboutWeekAfter()
            setCachedItem('viewTableColumn', tableColumnCache)
            isInit = true
        }
        return currentTableColumn.value


    }, (get, set, payload: TableColumnWidth) => {
        const newTableColumnCache = { ...get(tableColumnCacheAtom) }
        const current = newTableColumnCache[key]
        if (current) {
            current.value = payload
            set(tableColumnCacheAtom, newTableColumnCache)
            return
        }
        newTableColumnCache[key] = {
            expires: getTimestampAboutWeekAfter(),
            value: payload
        }
        set(tableColumnCacheAtom, newTableColumnCache)

    })
}, shallowEqual)



// view Cache kanbanColumnsSort
export const kanbanColumnsSortCacheAtom = atomWithStorage<KanbanColumnsSortAtom>('kanbanColumnsSort', getCachedItem('kanbanColumnsSort', {}))


export const kanbanColumnsSortCacheAtomFamily = atomFamily((params: CacheIdentity) => {
    const { appId, envId, id } = params
    const key: CacheKey = `${appId}-${envId}-${id}`
    let isInit = false
    return atom(get => {
        const kanbanColumnsSortCache = get(kanbanColumnsSortCacheAtom)

        const currentKanbanColumnsSort = kanbanColumnsSortCache[key]
        if (!currentKanbanColumnsSort) {
            return
        }
        if (!isInit) {
            currentKanbanColumnsSort.expires = getTimestampAboutWeekAfter()
            setCachedItem('kanbanColumnsSort', kanbanColumnsSortCache)
            isInit = true
        }
        return currentKanbanColumnsSort.value


    }, (get, set, payload: KanbanColumnsSort | undefined) => {

        const newKanbanColumnsSortCache = { ...get(kanbanColumnsSortCacheAtom) }
        if (!payload) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
            delete newKanbanColumnsSortCache[key]
            set(kanbanColumnsSortCacheAtom, newKanbanColumnsSortCache)
            return
        }
        const current = newKanbanColumnsSortCache[key]
        if (current) {
            current.value = payload
            set(kanbanColumnsSortCacheAtom, newKanbanColumnsSortCache)
            return
        }
        newKanbanColumnsSortCache[key] = {
            expires: getTimestampAboutWeekAfter(),
            value: payload
        }
        set(kanbanColumnsSortCacheAtom, newKanbanColumnsSortCache)

    })
}, shallowEqual)


// Cache filterBlockController
export const filterBlockCacheAtom = atomWithStorage<FilterBlockCacheAtom>('filterController', getCachedItem('filterController', {}))


export const filterBlockCacheAtomFamily = atomFamily((params: CacheFilterBlockIdentity) => {
    const { appId, envId, pageId, recordId, scope, blockId } = params
    const key = [appId, envId, pageId, recordId, scope, blockId].filter(Boolean).join('-') as FilterBlockIdentify
    // recordId ? `${appId}-${envId}-${pageId}-${recordId}-${blockId}` : `${appId}-${envId}-${pageId}-${blockId}`
    let isInit = false
    return atom(get => {
        const filterBlockCache = get(filterBlockCacheAtom)

        const currentFilterBlock = filterBlockCache[key]
        if (!currentFilterBlock) {
            return
        }
        if (!isInit) {
            currentFilterBlock.expires = getTimestampAboutWeekAfter()
            setCachedItem('filterController', filterBlockCache)
            isInit = true
        }
        return currentFilterBlock.value


    }, (get, set, payload: SetFilterBlockCachePayload) => {
        const { itemId, data } = payload
        const newFilterBlockCache = { ...get(filterBlockCacheAtom) }
        if (newFilterBlockCache[key]) {
            newFilterBlockCache[key] = {
                ...newFilterBlockCache[key],
                value: {
                    ...newFilterBlockCache[key].value,
                    [itemId]: data
                }
            }
            set(filterBlockCacheAtom, newFilterBlockCache)
            return
        }
        newFilterBlockCache[key] = {
            expires: getTimestampAboutWeekAfter(),
            value: {
                [itemId]: data
            }
        }
        set(filterBlockCacheAtom, newFilterBlockCache)

    })
}, shallowEqual)



export const filterBlockIdsCacheAtomFamily = atomFamily((params: CacheFilterBlocksItemIdsIdentity) => {
    const { appId, envId, pageId, recordId, scope, filterBlockItemIds } = params
    const isInit: Record<string, boolean> = {}
    return atom(get => {
        const filterBlockCache = get(filterBlockCacheAtom)
        return filterBlockItemIds.reduce<{ blockId: string, data: FilterBlockValue }[]>((prev, filterBlockItem) => {
            const { blockId, itemId } = filterBlockItem
            const key = [appId, envId, pageId, recordId, scope, blockId].filter(Boolean).join('-') as FilterBlockIdentify
            const currentFilterBlockCache = filterBlockCache[key]
            if (!currentFilterBlockCache) {
                return prev
            }
            if (!isInit?.[key]) {
                setCachedItem('filterController', {
                    ...filterBlockCache,
                    [key]: {
                        value: currentFilterBlockCache.value,
                        expires: getTimestampAboutWeekAfter()
                    }
                })
                isInit[key] = true
            }

            const currentFilterBlocksItemData = currentFilterBlockCache.value[itemId]
            const index = findIndex(item => item.blockId === blockId, prev)
            if (index >= 0) {
                prev[index].data[itemId] = currentFilterBlocksItemData
                return prev
            }
            prev.push({
                blockId, data: {
                    [itemId]: currentFilterBlocksItemData
                }
            })
            return prev
        }, [])
    })
}, shallowEqual)
