import { Divider, Flex, Group, IconFont, Popover, SegmentedControl, Select, Slider, Switch, Text } from '@byecode/ui'
import type { DividerBlockAbstract } from '@lighthouse/core'
import { BACKGROUND_TYPE, DIRECTION, DIVIDER_STYLE } from '@lighthouse/core'
import { FilledInput, FillPickerPopoverDropdown, ListItem4ByecodeUi } from '@lighthouse/shared'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { getBlockAndPageDesignLimit, PositionSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'

const DIRECTION_OPTIONS = [
    {
        label: '水平',
        value: DIRECTION.horizontal
    },
    {
        label: '垂直',
        value: DIRECTION.vertical
    }
]

const STYLE_OPTIONS = [
    {
        label: (
            <Flex alignItems="center" gap={4}>
                <IconFont type="LineSolid" />
                <Text>实线</Text>
            </Flex>
        ),
        value: DIVIDER_STYLE.SOLID
    },
    {
        label: (
            <Flex alignItems="center" gap={4}>
                <IconFont type="LineDash" />
                <Text>虚线</Text>
            </Flex>
        ),
        value: DIVIDER_STYLE.DASHED
    }
]

export const DividerBlockSetting: React.FC = () => {
    const { control, watch } = useFormContext<DividerBlockAbstract['config']>()

    const [visible] = watch(['visible'])

    const designProps = getBlockAndPageDesignLimit('divider')
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    const colorSystemMethods = useColorSystemAction()

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                    <Divider />
                    <Group label="样式">
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>显示线条</Text>
                            <Controller
                                control={control}
                                name="visible"
                                render={({ field }) => <Switch checked={field.value} onChange={e => field.onChange(e.target.checked)} />}
                            />
                        </ListItem4ByecodeUi>

                        {visible && (
                            <>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>线条方向</Text>
                                    <Controller
                                        control={control}
                                        name="direction"
                                        defaultValue={DIRECTION.horizontal}
                                        render={({ field }) => (
                                            <SegmentedControl style={{ width: 180 }} fullWidth data={DIRECTION_OPTIONS} {...field} />
                                        )}
                                    />
                                </ListItem4ByecodeUi>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>线条风格</Text>
                                    <Controller
                                        control={control}
                                        name="style"
                                        defaultValue={DIVIDER_STYLE.SOLID}
                                        render={({ field }) => (
                                            <Select
                                                style={{ width: 180 }}
                                                options={STYLE_OPTIONS}
                                                {...field}
                                                customInputValueRender={({ value }) =>
                                                    STYLE_OPTIONS.find(item => item.value === value)?.label || null
                                                }
                                            />
                                        )}
                                    />
                                </ListItem4ByecodeUi>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>线条颜色</Text>
                                    <Controller
                                        control={control}
                                        name="fill"
                                        render={({ field }) => {
                                            return (
                                                <Popover
                                                    width={300}
                                                    withinPortal
                                                    position="left-start"
                                                    autoUpdate={false}
                                                    offsetOptions={168}
                                                >
                                                    <Popover.Target>
                                                        <FilledInput
                                                            style={{ width: 180 }}
                                                            value={field.value}
                                                            onClear={() => {
                                                                field.onChange({ type: BACKGROUND_TYPE.color })
                                                            }}
                                                        />
                                                    </Popover.Target>
                                                    <FillPickerPopoverDropdown
                                                        title="填充"
                                                        value={field.value}
                                                        onChange={field.onChange}
                                                        {...colorSystemMethods}
                                                    />
                                                </Popover>
                                            )
                                        }}
                                    />
                                </ListItem4ByecodeUi>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>线条粗细</Text>
                                    <Controller
                                        control={control}
                                        name="size"
                                        render={({ field }) => <Slider style={{ width: 180 }} min={1} max={20} {...field} />}
                                    />
                                </ListItem4ByecodeUi>
                            </>
                        )}
                    </Group>
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && <VisibilitySetting />}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}
        </>
    )
}
