import { Group, Input, SegmentedControl, Slider } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import type { Field, ImageOptions, PageAbstract } from '@lighthouse/core'
import {
    getPageDataOptions,
    getUserDatasourceOptions,
    ListItemPaddingByecodeUi,
    RATIO_OPTIONS,
    SelectMode,
    useAtomData,
    USER_DATASOURCE,
    VariableSelect
} from '@lighthouse/shared'
import { filterObjectUndefined } from '@lighthouse/tools'
import { Divider, Text } from '@mantine/core'
import { find } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { ActionAdder } from '@/components/ActionAdder'
import { getBlockAndPageDesignLimit, PositionSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import { useVariableCustomViewOption } from '@/hooks/useVariableCustomViewOption'

import { FieldSelect as Select } from '../../FieldSelect'
import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import { SwitchControl } from '../Common/SwitchControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import {
    ALIGN_CONTROL,
    BLOCK_IMAGE_SOURCES,
    BLOCK_SETTINGS_SEGMENTED_ENUM,
    IMAGE_CLICK_OPTIONS,
    IMAGE_FIT_OPTIONS,
    SHAPE_OPTIONS
} from '../constants'
import ImageUploadList from './ImageUploadList'

export const maxFileNum = 10

const SCxStyleContainer = styled.div`
    display: flex;
    flex-direction: column;
    /* gap: 8px; */
`
const fileField: Field = { type: 'file', id: 'file', dsId: '', name: '图片', innerType: 'ARRAY' }

const multipleSvg = getAssetUrl('common', 'multiple_picture.svg')
const singleSvg = getAssetUrl('common', 'single_picture.svg')

export const IMAGE_SHOW_OPTIONS = [
    { label: '单图', value: 'single', icon: multipleSvg },
    { label: '平铺多图', value: 'multiple', icon: singleSvg }
]

export const BlockImageSettings: React.FC<{ allPages: PageAbstract[]; blockId: string }> = ({ blockId, allPages = [] }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const designProps = getBlockAndPageDesignLimit('image')
    const { control, register, watch, setValue } = useFormContext<ImageOptions>()
    const [shape, ratio, variant, sourceType, action, imageClickEvent, autoLoop, showType = 'single'] = watch([
        'shape',
        'ratio',
        'variant',
        'sourceType',
        'action',
        'imageClickEvent',
        'autoLoop',
        'showType'
    ])
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { dataSourceList, curr, prev } = usePageDataSourceForVariableSelector({
        pageId,
        stackId
    })
    const userOption = useMemo(() => {
        const userDataSource = find(item => item.id === USER_DATASOURCE, dataSourceList)
        if (!userDataSource) {
            return
        }
        return getUserDatasourceOptions({
            dataSource: userDataSource,
            validateFieldType: field => field.type === 'file'
        })
    }, [dataSourceList])

    const { customViewOption } = useVariableCustomViewOption(field => field.type === 'file')

    const options = useMemo(() => {
        const filter = (field: Field) => field.type === 'file'
        const configure = [
            prev.datasource && {
                variableSourceType: prev.variableSourceType,
                datasource: prev.datasource,
                validateFieldType: filter
            },
            curr.datasource && {
                variableSourceType: curr.variableSourceType,
                datasource: curr.datasource,
                validateFieldType: filter
            }
        ].filter(filterObjectUndefined)
        return getPageDataOptions(configure)
    }, [curr.datasource, curr.variableSourceType, prev.datasource, prev.variableSourceType])

    // 原图比例仅限于矩形使用且非swiper， 1:1 只有圆形和正方形可使用
    const newRatioOptions = useMemo(() => {
        if (variant === 'swiper') {
            return RATIO_OPTIONS
        }
        return shape === 'rectangle' ? RATIO_OPTIONS.filter(item => !item.disable) : RATIO_OPTIONS
    }, [shape, variant])

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} isInterAction onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <Group label="类型">
                        <Controller
                            name="showType"
                            control={control}
                            render={({ field }) => {
                                return (
                                    <SelectMode
                                        options={IMAGE_SHOW_OPTIONS}
                                        itemWidth="100%"
                                        styles={{
                                            image: {
                                                // padding: '20px 0px 0px 0px',
                                                borderRadius: '8px',
                                                // width: 158,
                                                // height: 68,
                                                outline: 'var(--color-gray-200) solid 1px',
                                                background: 'var(--color-gray-100)'
                                            },
                                            root: {
                                                width: '100%',
                                                gap: '16px',
                                                marginTop: 8
                                            }
                                        }}
                                        value={field.value ?? 'single'}
                                        onChange={val => field.onChange(val)}
                                    />
                                )
                            }}
                        />
                    </Group>
                    <Divider color="var(--color-gray-200)" />
                    <PositionSetting {...designProps.position} />
                    <Divider color="var(--color-gray-200)" />
                    <SizeSetting {...designProps.size} />
                    <Divider color="var(--color-gray-200)" />
                    <Group label="设计">
                        <SCxStyleContainer>
                            {showType === 'multiple' && (
                                <>
                                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                        <Text>间距</Text>
                                        <Controller
                                            control={control}
                                            name="gap"
                                            render={({ field }) => {
                                                return (
                                                    <Slider
                                                        min={0}
                                                        max={100}
                                                        step={1}
                                                        defaultValue={field.value}
                                                        onChangeEnd={field.onChange}
                                                        style={{ width: 180 }}
                                                        inputProps={{
                                                            onFocus: e => {
                                                                e.target.select()
                                                            }
                                                        }}
                                                    />
                                                )
                                            }}
                                        />
                                    </ListItemPaddingByecodeUi>
                                </>
                            )}
                            {showType === 'single' && (
                                <>
                                    {' '}
                                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                        <Text>对齐</Text>
                                        <div style={{ width: 180 }}>
                                            <Controller
                                                name="align"
                                                control={control}
                                                render={({ field }) => (
                                                    <SegmentedControl
                                                        fullWidth
                                                        data={ALIGN_CONTROL}
                                                        value={field.value}
                                                        onChange={v => field.onChange(v)}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </ListItemPaddingByecodeUi>
                                    <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                        <Text>图片适应</Text>
                                        <div style={{ width: 180 }}>
                                            <Controller
                                                name="fitType"
                                                control={control}
                                                render={({ field }) => (
                                                    <SegmentedControl
                                                        data={IMAGE_FIT_OPTIONS}
                                                        fullWidth
                                                        value={field.value}
                                                        onChange={v => field.onChange(v)}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </ListItemPaddingByecodeUi>
                                </>
                            )}
                            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                <Text>形状</Text>
                                <Controller
                                    name="shape"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            style={{
                                                width: 180
                                            }}
                                            searchable={false}
                                            options={SHAPE_OPTIONS}
                                            value={field.value}
                                            onChange={value => {
                                                const equalWidthAndHeight = RATIO_OPTIONS.find(item => item.value === '7')
                                                field.onChange(value)
                                                if (variant === 'swiper') {
                                                    setValue('ratio', value === 'rectangle' ? '4' : equalWidthAndHeight?.value)
                                                    return
                                                }
                                                setValue('ratio', value === 'rectangle' ? '0' : equalWidthAndHeight?.value)
                                            }}
                                        />
                                    )}
                                />
                            </ListItemPaddingByecodeUi>
                            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                <Text>比例</Text>
                                <Controller
                                    name="ratio"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            style={{
                                                width: 180
                                            }}
                                            searchable={false}
                                            disabled={disabledWithVersion || shape !== 'rectangle'}
                                            options={newRatioOptions}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            </ListItemPaddingByecodeUi>
                            {showType === 'single' && (
                                <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>圆角</Text>
                                    <Input
                                        {...register('radius')}
                                        style={{
                                            width: 178
                                        }}
                                        type="number"
                                        disabled={disabledWithVersion || shape === 'round'}
                                        min={0}
                                        placeholder="请输入圆角"
                                    />
                                </ListItemPaddingByecodeUi>
                            )}
                        </SCxStyleContainer>
                    </Group>
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="图片">
                        <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>图片来源</Text>
                            <Controller
                                name="sourceType"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        style={{
                                            width: 180
                                        }}
                                        searchable={false}
                                        options={BLOCK_IMAGE_SOURCES}
                                        value={field.value}
                                        onChange={val => {
                                            // @灿白 要改
                                            // if (val === 'field') {
                                            //     setValue('imageClickEvent', 'preview')
                                            // }
                                            field.onChange(val)
                                        }}
                                    />
                                )}
                            />
                        </ListItemPaddingByecodeUi>
                        {sourceType === 'default' ? (
                            <ImageUploadList
                                blockId={blockId}
                                disabled={disabledWithVersion}
                                disableJump={imageClickEvent !== 'jump'}
                                allPages={allPages}
                            />
                        ) : (
                            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                <Text>选择变量</Text>
                                <Controller
                                    control={control}
                                    name="imageSource"
                                    render={({ field }) => (
                                        <VariableSelect
                                            value={field.value}
                                            options={options}
                                            viewOption={customViewOption}
                                            userOption={userOption}
                                            field={fileField}
                                            placeholder="粘贴图片链接"
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            </ListItemPaddingByecodeUi>
                        )}
                        {sourceType === 'default' && (
                            <Controller
                                name="showTitle"
                                control={control}
                                render={({ field }) => (
                                    <SwitchControl
                                        label="图片标题"
                                        checked={field.value}
                                        onChange={e => {
                                            field.onChange?.(e.currentTarget.checked)
                                        }}
                                    />
                                )}
                            />
                        )}
                        {showType === 'single' && (
                            <>
                                <Controller
                                    name="variant"
                                    control={control}
                                    render={({ field }) => (
                                        <SwitchControl
                                            label="开启轮播"
                                            checked={field.value === 'swiper'}
                                            onChange={e => {
                                                if (e.currentTarget.checked && shape === 'rectangle' && ratio === '0') {
                                                    setValue('ratio', '4')
                                                }
                                                field.onChange?.(e.currentTarget.checked ? 'swiper' : 'default')
                                            }}
                                        />
                                    )}
                                />
                                {variant === 'swiper' && (
                                    <>
                                        <Controller
                                            name="autoLoop"
                                            control={control}
                                            render={({ field }) => (
                                                <SwitchControl
                                                    label="自动播放"
                                                    checked={field.value}
                                                    onChange={e => field.onChange?.(e.currentTarget.checked)}
                                                />
                                            )}
                                        />
                                        {autoLoop && (
                                            <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center">
                                                <Text>停顿时间</Text>
                                                <Input
                                                    {...register('swipeSpeed')}
                                                    style={{
                                                        width: 178
                                                    }}
                                                    type="number"
                                                    min={0}
                                                    suffix={<Text>s</Text>}
                                                />
                                            </ListItemPaddingByecodeUi>
                                        )}
                                    </>
                                )}
                            </>
                        )}
                    </Group>
                    <Divider color="var(--color-gray-200)" />
                    <Group
                        label="动作"
                        styles={{
                            collapse: {
                                padding: '0 8px !important'
                            }
                        }}
                    >
                        <ListItemPaddingByecodeUi justifyContent="space-between" alignItems="center" style={{ margin: '0 8px' }}>
                            <Text>点击后</Text>
                            <div style={{ width: 180 }}>
                                <Controller
                                    name="imageClickEvent"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            options={IMAGE_CLICK_OPTIONS.filter(item => !(sourceType === 'field' && item.value === 'jump'))}
                                            styles={{
                                                root: {
                                                    width: 180
                                                }
                                            }}
                                            searchable={false}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                            </div>
                        </ListItemPaddingByecodeUi>
                        {imageClickEvent === 'custom' && (
                            <>
                                <Text style={{ lineHeight: '40px', padding: '0 8px' }}>自定义动作</Text>
                                <ActionAdder
                                    title=""
                                    showLabel={false}
                                    prefixName=""
                                    events={{
                                        params: [],
                                        handleEvent: 'none',
                                        triggerEvent: 'click'
                                    }}
                                    allPages={allPages}
                                    action={
                                        action ?? {
                                            type: 'none',
                                            params: {},
                                            trigger: 'click'
                                        }
                                    }
                                />
                            </>
                        )}
                    </Group>

                    <Divider color="var(--color-gray-200)" />
                    <VisibilitySetting />
                </>
            )}

            <div key={BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION} hidden={currentTab !== BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION}>
                <AnimationSetting />
            </div>
        </>
    )
}
