import { IconFont } from '@lighthouse/bui'
import type { SubFormBlockAbstract } from '@lighthouse/core'
import { useLanguageContext } from '@lighthouse/shared'
import React, { useCallback } from 'react'

import { RecordIndex } from './RecordIndex'
import { CMxEmpty, CMxEndIndex, CMxFirstIndex, CMxRecord } from './styles'

interface SubFormHeaderProps {
    blockData: SubFormBlockAbstract
    onBlockChange?: (values: SubFormBlockAbstract, origin: SubFormBlockAbstract) => Promise<void> | void
}

export const SubFormHeader: React.FunctionComponent<SubFormHeaderProps> = ({ blockData, onBlockChange }) => {
    const {
        config: { columns },
        config
    } = blockData
    const { convertTextByLanguage } = useLanguageContext()

    return (
        <CMxRecord style={{ borderTop: 'none' }}>
            <CMxFirstIndex style={{ backgroundColor: 'var(--color-gray-50)' }}>
                <IconFont type="PropertyNumberBlock" color="var(--color-gray-400)" size={16} />
            </CMxFirstIndex>
            {columns.map((column, index) => (
                <RecordIndex index={index} key={column.id} column={column} />
            ))}
            {columns.length === 0 && <CMxEmpty />}
            <CMxEndIndex style={{ justifyContent: 'center' }}>{convertTextByLanguage('operation')}</CMxEndIndex>
        </CMxRecord>
    )
}
