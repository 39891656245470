import clsx from 'clsx'
import type { ChangeEvent } from 'react'
import React, { forwardRef, useCallback, useMemo } from 'react'

import { useComponentConfig } from '../../hooks/useComponentConfig'
import { useUncontrolled } from '../../hooks/useUncontrolled'
import { Box } from '../Box'
import { IconFont } from '../IconFont'
import { useStyles } from './styles'
import type { CheckboxProps } from './types'

const defaultProps: Partial<CheckboxProps> = {
    size: 'sm',
    radius: 'xl'
}

/**
 * @description 使用该checkbox， 外部注意不要使用onClick
 * */
export const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>((props, ref) => {
    const {
        classNames,
        styles,
        className,
        unstyled,
        size = 'md',
        checked,
        disabled: _disabled,
        label,
        defaultChecked,
        radius,
        color,
        value,
        indeterminate = false,

        onClickCapture: propsOnClickCapture,
        onChange,
        ...rest
    } = props
    const [_value, handleChange] = useUncontrolled({ value: checked, defaultValue: defaultChecked, finalValue: false })
    const id = useMemo(() => Math.random().toString(36).slice(2, 10), [])
    const { disabled } = useComponentConfig('checkbox', { disabled: _disabled })

    const { classes } = useStyles({ color }, { name: 'checkbox', classNames, styles, unstyled })

    const handleCheckedChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
            handleChange(ev.target.checked)
            onChange?.(ev)
        },
        [handleChange, onChange]
    )

    const onClickCapture = useCallback(
        (e: React.MouseEvent<HTMLInputElement>) => {
            if (e.target instanceof Element && e.target.tagName !== 'INPUT') {
                // e.preventDefault()
                e.stopPropagation()
            }

            propsOnClickCapture?.(e)
        },
        [propsOnClickCapture]
    )
    return (
        <Box
            {...rest}
            ref={ref}
            className={clsx(classes.wrapper, className)}
            data-disabled={disabled}
            data-size={size}
            data-checked={_value || indeterminate}
            onClickCapture={onClickCapture}
        >
            <input
                id={id}
                type="checkbox"
                className={classes.input}
                value={value}
                disabled={disabled}
                checked={_value}
                onChange={handleCheckedChange}
            />
            <Box component="label" htmlFor={id} className={classes.label}>
                <Box className={classes.inner} style={{ borderRadius: radius, marginRight: label ? 8 : 0 }}>
                    <IconFont className={classes.icon} /* size={size} */ type={indeterminate ? 'Reduce' : 'CheckboxTick'} />
                </Box>
                {label}
            </Box>
        </Box>
    )
})
