import { IconFont, Popover, Tooltip } from '@byecode/ui'
import type { ActionIconProps } from '@mantine/core'
import { Text } from '@mantine/core'
import type { PolymorphicComponentProps } from '@mantine/utils'
import type { Editor } from '@tiptap/react'
import classNames from 'classnames'
import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { useLanguageContext } from '../../../contexts'
import { FillPickerCore } from '../../FillPicker'
import { useTipTapContext } from '../Context'
import { BasicActionIcon, CustomActionIcon } from './styles'

const CombineDropdownButton = styled(BasicActionIcon)`
    width: auto;
    min-width: auto;
`
const CombineButtonContainer = styled.div`
    display: flex;
    &:hover {
        ${CustomActionIcon}, ${CombineDropdownButton} {
            background: rgba(56, 56, 56, 0.05);
        }
        ${CombineDropdownButton} {
            border-color: #e4e7ec;
        }
    }
    ${CustomActionIcon} {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        &:hover {
            background: rgba(56, 56, 56, 0.1) !important;
        }
    }
    ${CombineDropdownButton} {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border: none;
        border-left: 0.5px solid transparent;
        &:hover {
            background: rgba(56, 56, 56, 0.1) !important;
        }
    }
`

export const BoldMenu = () => {
    const editor = useTipTapContext()
    const { convertTextByLanguage } = useLanguageContext()
    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>{convertTextByLanguage('bold')}</Text>
                    <Text color="var(--color-gray-400)">⌘+Shift+B</Text>
                </div>
            }
        >
            <CustomActionIcon
                className={classNames({ active: editor?.isActive('bold') })}
                onClick={() => editor?.chain().focus().toggleBold().run()}
            >
                <IconFont type="TextBolder" />
            </CustomActionIcon>
        </Tooltip>
    )
}

export const ItalicMenu = () => {
    const editor = useTipTapContext()
    const { convertTextByLanguage } = useLanguageContext()

    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>{convertTextByLanguage('italic')}</Text>
                    <Text color="var(--color-gray-400)">⌘+Shift+I</Text>
                </div>
            }
        >
            <CustomActionIcon
                className={classNames({ active: editor?.isActive('italic') })}
                onClick={() => editor?.chain().focus().toggleItalic().run()}
            >
                <IconFont type="TextItalic" />
            </CustomActionIcon>
        </Tooltip>
    )
}

export const StrikeMenu = () => {
    const editor = useTipTapContext()
    const { convertTextByLanguage } = useLanguageContext()

    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>{convertTextByLanguage('strikethrough')}</Text>
                    <Text color="var(--color-gray-400)">⌘+Shift+X</Text>
                </div>
            }
        >
            <CustomActionIcon
                className={classNames({ active: editor?.isActive('strike') })}
                onClick={() => editor?.chain().focus().toggleStrike().run()}
            >
                <IconFont type="TextStrikethrough" />
            </CustomActionIcon>
        </Tooltip>
    )
}

export const UnderlineMenu = () => {
    const editor = useTipTapContext()
    const { convertTextByLanguage } = useLanguageContext()

    return (
        <Tooltip
            title={
                <div style={{ textAlign: 'center' }}>
                    <Text>{convertTextByLanguage('underline')}</Text>
                    <Text color="var(--color-gray-400)">⌘+Shift+U</Text>
                </div>
            }
        >
            <CustomActionIcon
                className={classNames({ active: editor?.isActive('underline') })}
                onClick={() => editor?.chain().focus().toggleUnderline().run()}
            >
                <IconFont type="TextUnderline" />
            </CustomActionIcon>
        </Tooltip>
    )
}

interface BgColorButtonProps extends PolymorphicComponentProps<'button', ActionIconProps> {
    editor: Editor | null
}
const BgColorButton = forwardRef<HTMLButtonElement, BgColorButtonProps>(({ editor, ...props }, ref) => {
    const { convertTextByLanguage } = useLanguageContext()
    return (
        <Tooltip title={convertTextByLanguage('textBackgroundColor')}>
            <CustomActionIcon ref={ref} variant="subtle" {...props}>
                <IconFont type="TextBackGroundColor" color={editor?.storage.bgColor} />
            </CustomActionIcon>
        </Tooltip>
    )
})
export const BgColorMenu = () => {
    const editor = useTipTapContext()

    return (
        <CombineButtonContainer>
            <BgColorButton
                editor={editor}
                onClick={() => {
                    const storageBgColor = editor?.storage?.bgColor
                    if (editor?.isActive('highlight') && !storageBgColor) {
                        return editor.chain().focus().unsetHighlight().run()
                    }
                    if (storageBgColor) {
                        editor?.chain().focus().setHighlight({ color: storageBgColor }).run()
                        return
                    }
                    editor?.chain().focus().unsetHighlight().run()
                }}
            />
            <Popover position="bottom" width={300} withinPortal>
                <Popover.Target>
                    <CombineDropdownButton>
                        <IconFont size={10} type="ArrowDownSmallGray" color="var(--color-gray-500)" />
                    </CombineDropdownButton>
                </Popover.Target>
                <Popover.Dropdown style={{ maxHeight: 600, padding: '8px 16px' }}>
                    <FillPickerCore
                        hideLibrary
                        // hideRecommend
                        defaultValue={editor?.storage.bgColor}
                        onChange={value => {
                            if (editor) {
                                editor.storage.bgColor = value
                                if (value) {
                                    editor.chain().focus().setHighlight({ color: value }).run()
                                } else {
                                    editor.chain().focus().unsetHighlight().run()
                                }
                            }
                        }}
                    />
                </Popover.Dropdown>
                {/* <Popover.Dropdown unstyled>
                    <ColorPicker
                        value={editor?.storage.bgColor ?? ''}
                        onChange={value => {
                            if (editor) {
                                editor.storage.bgColor = value
                                if (value) {
                                    editor.chain().focus().setHighlight({ color: value }).run()
                                } else {
                                    editor.chain().focus().unsetHighlight().run()
                                }
                            }
                        }}
                    />
                </Popover.Dropdown> */}
            </Popover>
        </CombineButtonContainer>
    )
}

interface TextColorButtonProps extends PolymorphicComponentProps<'button', ActionIconProps> {
    editor: Editor | null
}
const TextColorButton = forwardRef<HTMLButtonElement, TextColorButtonProps>(({ editor, ...props }, ref) => {
    const { convertTextByLanguage } = useLanguageContext()
    return (
        <Tooltip title={convertTextByLanguage('textColor')}>
            <CustomActionIcon ref={ref} variant="subtle" {...props}>
                <IconFont type="TextColor" style={{ color: editor?.storage.textColor }} />
            </CustomActionIcon>
        </Tooltip>
    )
})
export const TextColorMenu = () => {
    const editor = useTipTapContext()

    return (
        <CombineButtonContainer>
            <TextColorButton
                editor={editor}
                onClick={() => {
                    editor?.chain().focus().setColor(editor.storage.textColor).run()
                }}
            />
            <Popover position="bottom" width={300} withinPortal>
                <Popover.Target>
                    <CombineDropdownButton>
                        <IconFont size={10} type="ArrowDownSmallGray" />
                    </CombineDropdownButton>
                </Popover.Target>
                <Popover.Dropdown style={{ maxHeight: 600, padding: '8px 16px' }}>
                    <FillPickerCore
                        hideLibrary
                        // hideRecommend
                        defaultValue={editor?.storage.textColor}
                        onChange={value => {
                            if (editor) {
                                editor.storage.textColor = value
                                if (value) {
                                    editor.chain().focus().setColor(value).run()
                                } else {
                                    editor.chain().focus().unsetColor().run()
                                }
                            }
                        }}
                    />
                </Popover.Dropdown>
                {/* <Popover.Dropdown unstyled>
                    <ColorPicker
                        noColorMode="default"
                        value={editor?.storage.textColor ?? ''}
                        onChange={value => {
                            if (editor) {
                                editor.storage.textColor = value
                                editor.chain().focus().setColor(value).run()
                            }
                        }}
                    />
                </Popover.Dropdown> */}
            </Popover>
        </CombineButtonContainer>
    )
}
