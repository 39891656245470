import type { Option } from '@byecode/ui'
import { type DataSourceAbstract, DataSourceType } from '@lighthouse/core'
import type { JSONContent } from '@tiptap/core'
import { find } from 'rambda'

import { getNodeInfo } from '../constants'
import type { FlowNode, WeChatPayActionConfig } from '../types'

export const getUpstreamRealDsId = (nodeId: string, allParentNodes: FlowNode[]): string | undefined => {
    const node = allParentNodes.find(n => n.id === nodeId)
    if (!node || !node.data) {
        return undefined
    }
    const nodeData = node.data

    if (nodeData.config && 'dataSourceId' in nodeData.config) {
        return nodeData.config.dataSourceId
    }

    // 专门针对微信支付和退款的情况
    if (nodeData.nodeType === 'WECHAT_PAY') {
        const config = nodeData.config as WeChatPayActionConfig
        const nodeId = (config.operationType === 'PAY' ? config.payConfig?.nodeId : '') ?? ''
        return getUpstreamRealDsId(nodeId, allParentNodes)
    }

    if (nodeData.config && 'nodeId' in nodeData.config) {
        return getUpstreamRealDsId(nodeData.config.nodeId, allParentNodes)
    }
    return undefined
}

// 根据disabledFilter 获取可以使用的上游节点
export const getUpstreamNodeOption = (
    allParentNodes: FlowNode[],
    dataSourceList: DataSourceAbstract[],
    filter?: (node: FlowNode) => boolean
) => {
    return allParentNodes.reduce<Option[]>((prev, cur) => {
        if (!filter?.(cur)) {
            return prev
        }
        if (cur.data.config && 'dataSourceId' in cur.data.config) {
            const { dataSourceId } = cur.data.config
            const dataSource = find(item => item.id === dataSourceId, dataSourceList)
            if (!dataSource || dataSource.type === DataSourceType.aggregateDataSource) {
                return prev
            }
        }
        prev.push({
            label: cur.data.name,
            value: cur.id,
            icon: getNodeInfo(cur.data.nodeType).icon
        })
        return prev
    }, [])
}

export const getNodeDataSourceId: (node: FlowNode, nodes: FlowNode[]) => string = (node, nodes) => {
    if (!node.data || !node.data.config) {
        return ''
    }
    if ('dataSourceId' in node.data.config) {
        return node.data.config.dataSourceId ?? ''
    }
    if ('nodeId' in node.data.config) {
        const { nodeId } = node.data.config
        const parentNodeConfigNode = find(item => item.id === nodeId, nodes)
        if (parentNodeConfigNode) {
            return getNodeDataSourceId(parentNodeConfigNode, nodes)
        }
        return ''
    }
    if (node.data.nodeType === 'WECHAT_PAY') {
        const config = node.data.config as WeChatPayActionConfig
        const nodeId = config.operationType === 'PAY' ? config.payConfig?.nodeId : ''
        const parentNodeConfigNode = find(item => item.id === nodeId, nodes)
        if (parentNodeConfigNode) {
            return getNodeDataSourceId(parentNodeConfigNode, nodes)
        }
        return ''
    }
    return ''
}




export const parseJsonExpression = (json: JSONContent | undefined) => {
    if (!json) {
        return ''
    }
    return (
        json.content?.reduce((prev, cur, index) => {

            if (cur?.content) {
                const text = parseJsonExpression(cur)
                prev += text
                return prev
            }
            if (cur?.type === 'text') {
                prev += cur.text
                return prev
            }
            if (cur?.type === 'variable' && cur.attrs?.value) {
                const value = JSON.stringify(cur.attrs.value)
                const dataValue = value.replaceAll('"', '&quot;');
                prev += `<variable data-value=\"${dataValue}\"></variable>`
            }
            return prev
        }, '') || ''
    )
}
