import { Flex, IconFont, Input, SegmentedControl, Select, Slider, Text } from '@byecode/ui'
import type { TransitionProtocol } from '@lighthouse/core'
import { EaseEnum, TransitionTypeEnum } from '@lighthouse/core'
import { bezierReg, ListItem4ByecodeUi } from '@lighthouse/shared'
import BezierEditor from 'bezier-easing-editor'
import { mergeDeepRight } from 'rambda'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { BASE_ON_OPTIONS, EASE_OPTIONS, TRANSITION_OPTIONS } from '../constants'
import { ElasticCurvePhysicsSVG, ElasticCurveTimeSVG } from './ElasticCurveSVG'
import { getInitTransition } from './util'

interface TransitionSettingProps {
    value: TransitionProtocol
    onChange: (v: TransitionProtocol) => void
}

const SCxContainer = styled.div`
    /* padding: 0 16px; */
`

const SCxTab = styled.div`
    width: 100%;
`

const TransitionSetting: React.FunctionComponent<TransitionSettingProps> = ({ value, onChange }) => {
    const { type, ease, bezier, delay, time, stiffness = 0, damping = 0, mass = 0, basedOn, bounce = 0 } = value
    const handleChange = useCallback(
        (v: Partial<TransitionProtocol>) => {
            onChange(mergeDeepRight(value, v))
        },
        [onChange, value]
    )

    return (
        <SCxContainer>
            <SegmentedControl
                fullWidth
                data={TRANSITION_OPTIONS}
                value={type}
                onChange={v => {
                    const defaultTransition =
                        v === TransitionTypeEnum.ease ? getInitTransition(EaseEnum.linear) : getInitTransition('spring')
                    handleChange({ ...defaultTransition, type: v as TransitionTypeEnum })
                }}
            />
            <SCxTab>
                {type === TransitionTypeEnum.ease && (
                    <>
                        <div style={{ padding: '8px 0', borderRadius: 4 }}>
                            <BezierEditor
                                gridColor="transparent"
                                textStyle={{
                                    display: 'none'
                                }}
                                width={268}
                                height={134}
                                value={bezier?.split(',')}
                                background="var(--color-gray-100)"
                                curveColor="var(--color-gray-400)"
                                handleColor="var(--color-theme-7)"
                            />
                        </div>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>预设</Text>
                            <div style={{ width: 180 }}>
                                <Select
                                    options={EASE_OPTIONS.map(v => ({
                                        ...v,
                                        extra: v.value === ease ? <IconFont size={16} type="Tick" color="var(--color-purple-500)" /> : null
                                    }))}
                                    value={ease}
                                    onChange={v => {
                                        const { bezier, delay, time } = getInitTransition(v as EaseEnum)
                                        handleChange({ ease: v as EaseEnum, bezier, delay, time })
                                    }}
                                />
                            </div>
                        </ListItem4ByecodeUi>
                        {/* <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>贝塞尔曲线</Text>
                            <div style={{ width: 180 }}>
                                <Input
                                    value={bezier}
                                    onChange={e => handleChange({ bezier: e.target.value, ease: EaseEnum.custom })}
                                    onBlur={e => {
                                        if (!bezierReg.test(e.target.value)) {
                                            handleChange({ bezier: getInitTransition(EaseEnum.custom).bezier })
                                        }
                                    }}
                                />
                            </div>
                        </ListItem4ByecodeUi> */}
                    </>
                )}
                {type === TransitionTypeEnum.spring && (
                    <>
                        <div style={{ padding: '8px 0' }}>
                            <div
                                style={{
                                    background: 'var(--color-gray-100)',
                                    borderRadius: 4
                                }}
                            >
                                {basedOn === 'time' ? (
                                    <ElasticCurveTimeSVG bounce={bounce} width={268} height={134} />
                                ) : (
                                    <ElasticCurvePhysicsSVG width={268} height={134} mass={mass} damping={damping} stiffness={stiffness} />
                                )}
                            </div>
                        </div>
                        <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                            <Text>类型</Text>
                            <div style={{ width: 180 }}>
                                <SegmentedControl
                                    fullWidth
                                    data={BASE_ON_OPTIONS}
                                    value={basedOn}
                                    onChange={v => handleChange({ basedOn: v as 'time' | 'physics' })}
                                />
                            </div>
                        </ListItem4ByecodeUi>
                        {basedOn === 'physics' && (
                            <>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>刚度</Text>
                                    <div style={{ width: 180 }}>
                                        <Slider
                                            max={1000}
                                            min={1}
                                            value={stiffness}
                                            inputProps={{
                                                autoSelect: true,
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                            onChange={v => handleChange({ stiffness: v })}
                                        />
                                    </div>
                                </ListItem4ByecodeUi>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>阻尼</Text>
                                    <div style={{ width: 180 }}>
                                        <Slider
                                            max={100}
                                            min={0}
                                            value={damping}
                                            inputProps={{
                                                autoSelect: true,
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                            onChange={v => handleChange({ damping: v })}
                                        />
                                    </div>
                                </ListItem4ByecodeUi>
                                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                    <Text>质量</Text>
                                    <div style={{ width: 180 }}>
                                        <Slider
                                            max={10}
                                            min={0.1}
                                            value={mass}
                                            inputProps={{
                                                autoSelect: true,
                                                onFocus: e => {
                                                    e.target.select()
                                                }
                                            }}
                                            onChange={v => handleChange({ mass: Number(v.toFixed(1)) })}
                                        />
                                    </div>
                                </ListItem4ByecodeUi>
                            </>
                        )}
                    </>
                )}

                {(basedOn === 'time' || type === TransitionTypeEnum.ease) && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>持续时间</Text>
                        <div style={{ width: 180 }}>
                            <Slider
                                max={10}
                                min={0}
                                step={0.1}
                                value={time}
                                inputProps={{
                                    suffix: 's',
                                    onFocus: e => {
                                        e.target.select()
                                    }
                                }}
                                onChange={v => handleChange({ time: Number(v.toFixed(1)) })}
                            />
                        </div>
                    </ListItem4ByecodeUi>
                )}
                {basedOn === 'time' && type === TransitionTypeEnum.spring && (
                    <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                        <Text>弹性</Text>
                        <div style={{ width: 180 }}>
                            <Slider
                                max={10}
                                min={0}
                                step={0.1}
                                value={bounce}
                                inputProps={{
                                    onFocus: e => {
                                        e.target.select()
                                    }
                                }}
                                onChange={v => handleChange({ bounce: Number(v.toFixed(1)) })}
                            />
                        </div>
                    </ListItem4ByecodeUi>
                )}
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <Text>延迟</Text>
                    <div style={{ width: 180 }}>
                        <Slider
                            max={10}
                            min={0}
                            step={0.1}
                            value={delay}
                            inputProps={{
                                suffix: 's',
                                onFocus: e => {
                                    e.target.select()
                                }
                            }}
                            onChange={v => handleChange({ delay: Number(v.toFixed(1)) })}
                        />
                    </div>
                </ListItem4ByecodeUi>
            </SCxTab>
        </SCxContainer>
    )
}

export default TransitionSetting
