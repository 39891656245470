import type { ButtonBlockHandleEvent, ButtonEvents, ButtonHandleEvent, ButtonShowType } from '@lighthouse/core'

import type { ButtonConfig } from './type'

export const buttonActions: Record<ButtonBlockHandleEvent, ButtonConfig[]> = {
    none: [],
    openPage: [
        {
            type: 'select',
            placeHolder: '请选择页面',
            label: '页面',
            name: 'page',
            index: 0
        }
    ],
    openLink: [
        {
            type: 'input',
            placeHolder: 'https://',
            label: '链接',
            name: 'url',
            index: 0
        }
    ],
    scrollTo: [],
    control: [],
    openFormPage: [],
    openRecordPage: [],
    openRecordEditPage: [],
    goBack: [],
    notification: [],
    copy: [],
    call: [],
    createRecord: [
        {
            type: 'select',
            placeHolder: '请选择数据表',
            label: '数据表',
            name: 'datasource',
            index: 0
        },
        {
            type: 'select',
            placeHolder: '请选择视图',
            label: '视图',
            name: 'view',
            index: 1
        }
    ],
    updateRecord: [],
    deleteRecord: [],
    shareToWechat: [],
    downloadFile: [],
    iClick: [],
    aliyunMessage: [],
    wechatPay: [],
    wechatTemplateMsg: [],
    stripe: [],
    refreshPage: [],
    closeFloat: []
}

export const optionActions = [
    {
        section: '打开',
        items: [
            {
                icon: 'ArrowSquareIn',
                name: '打开页面',
                action: 'openPage'
            },
            {
                icon: 'LinkSimple',
                name: '打开链接',
                action: 'openLink'
            }
        ]
    },
    {
        section: '记录',
        items: [
            {
                icon: 'Add',
                name: '创建记录',
                action: 'createRecord'
            },
            {
                icon: 'ArrowCounterClockwise',
                name: '更新记录',
                action: 'updateRecord'
                // disable: true
            },
            {
                icon: 'Trash',
                name: '删除记录',
                action: 'deleteRecord'
                // disable: true
            }
        ]
    }
]

export const data: {
    value: ButtonBlockHandleEvent
    label: string
    icon: string
    group: string
    disable?: boolean
}[] = [
    { value: 'openPage', label: '打开页面', icon: 'ArrowSquareIn', group: '打开' },
    { value: 'openLink', label: '打开链接', icon: 'LinkSimple', group: '打开' }
    // { value: 'createRecord', label: '创建记录', icon: 'Add', group: '记录' },
    // { value: 'updateRecord', label: '更新记录', disable: true, icon: 'ArrowCounterClockwise', group: '记录' },
    // { value: 'deleteRecord', label: '删除记录', disable: true, icon: 'Trash', group: '记录' }
]

export const btnTypeList: { value: ButtonShowType; label: string }[] = [
    {
        value: 'name',
        label: '仅文字'
    },
    {
        value: 'icon',
        label: '仅图标'
    },
    {
        value: 'icon-name',
        label: '图标加文字'
    }
]

export const btnStyleList: { value: string; label: string }[] = [
    {
        value: '0',
        label: '主按钮'
    },
    {
        value: '1',
        label: '次级按钮'
    }
]

export const buttonEventsMap = new Map<ButtonHandleEvent, ButtonEvents>([
    [
        'createRecord',
        {
            handleEvent: 'createRecord',
            params: ['', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'deleteRecord',
        {
            handleEvent: 'deleteRecord',
            params: ['', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'updateRecord',
        {
            handleEvent: 'updateRecord',
            params: ['', ''],
            triggerEvent: 'click'
        }
    ],
    [
        'openLink',
        {
            handleEvent: 'openLink',
            params: [''],
            triggerEvent: 'click'
        }
    ],
    [
        'closeFloat',
        {
            handleEvent: 'closeFloat',
            params: [''],
            triggerEvent: 'click'
        }
    ]
])
