import type { BreadcrumbBlockConfig } from '@lighthouse/core'

export const generateBreadcrumbBlock = (): BreadcrumbBlockConfig => {
    return {
        breakPoint: {
            id: 'desktop',
            name: '桌面端',
            visibility: {
                visible: 'VISIBLE'
            },
            position: {
                type: 'relative'
            },
            size: {
                width: {
                    size: 'fill'
                },
                height: {
                    size: 'auto'
                }
            },
        },
        breakPoints: [
            {
                id: 'mobile',
                name: '移动端'
            }
        ]
    }
}
