import styled, { css } from "styled-components"


export const SwitchWrapper = styled.div`
    position: relative;
`

export const SwitchInput = styled.input`
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
`

export const SwitchTrack = styled.label<{ disabled?: boolean, progressed?: boolean, checked?: boolean }>`
    display: flex;
    width: 100%;
    height: auto;
    padding: 2px;
    border-radius: 1000px;
    transition: all 0.2s ease-in-out;
    background: ${({ checked }) => checked ? 'var(--color-app-main)' : 'var(--color-gray-300)'};
    cursor: pointer;
    ${({ disabled }) => disabled && css`
        cursor: not-allowed;
        opacity: 0.5;
    `}

    ${({ progressed }) => progressed && css`
        cursor: progress;
    `}

`

export const SwitchThumb = styled.div<{ checked?: boolean }>`
    border-radius: 50%;
    background-color: var(--color-white);
    transition: all 0.2s ease-in-out;
    width: 50%;
    aspect-ratio: 1;
    transform: ${({ checked }) => checked ? 'translateX(calc(100%))' : 'translateX(0)'};
`
