import React, { forwardRef } from 'react'
import styled from 'styled-components'

import { getPositionStyle, getSizeStyle } from '../../../utils'
import { useAppContainerContext } from '../../ApplicationContainer'
import { useContainerLayoutContext, useFlowLayoutContext } from '../Context'
import type { FlowLayoutNode } from '../types'

type StyledProps = {
    disabled?: boolean
}

const StyledNode = styled.div<StyledProps>`
    flex-shrink: 0;
    position: relative;

    &[data-highlight='true'] {
        background-color: #5551ff1f;
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }
`

export interface FlowLayoutCustomNodeProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutNode
    disabled?: boolean
    sortable?: boolean
    onDataDrawerVisible?: (val: boolean) => void
}

export const CustomNode = forwardRef<HTMLDivElement, FlowLayoutCustomNodeProps>((props, ref) => {
    const { data, style, disabled: propDisabled, sortable, onDataDrawerVisible, children, ...rest } = props
    const { size, position } = data.data ?? {}
    const { scale = 1 } = useAppContainerContext()
    const parentLayoutCtx = useContainerLayoutContext()

    const { highlight, nodeRender: NodeRender = React.Fragment } = useFlowLayoutContext()

    const mergeStyle: React.CSSProperties = {
        ...getSizeStyle(size, parentLayoutCtx.layout?.align?.direction, sortable, scale),
        ...style,
        ...getPositionStyle(position, parentLayoutCtx.level)
    }

    return (
        <StyledNode
            ref={ref}
            style={mergeStyle}
            data-type="custom"
            data-node-id={data.id}
            data-highlight={!!highlight?.[data.id]?.self}
            {...rest}
        >
            <NodeRender node={data}>{children}</NodeRender>
        </StyledNode>
    )
})
