import type { I18TranslationKey } from '../types'

export const zhTW: Record<I18TranslationKey, string> = {
    bindMobile: '綁定手機號',
    bindEmail: '綁定郵箱',
    firstLoginNeedAccount: '首次微信登錄需綁定{{account}}',
    mobileCode: '手機號',
    noVerificationCodeReceived: '沒有收到驗證碼？',
    noNeedLogin: '暫不支持登錄',
    registerAccount: '註冊賬號',
    mobileLogin: '手機號登錄',
    emailLogin: '郵箱登錄',
    wechatLogin: '微信登錄',
    emailUrl: '郵箱地址',
    noAccount: '還沒有賬號？',
    next: '下一步',
    goLogin: '去登錄',
    alreadyHaveAnAccount: '已有賬號？',
    selectRegisterWay: '選擇註冊方式',
    perfectInfo: '完善資訊',
    verificationCode: '驗證碼',
    sure: '確定',
    yourRole: '您的角色',
    emailFormatIsIllegal: '郵箱格式不合法',
    mobilePhoneNumberFormatIsIllegal: '手機號格式不合法',
    mobileRegister: '手機號註冊',
    emailRegister: '郵箱註冊',
    email: '郵箱',
    shortMessage: '簡訊',
    pleaseInputYourEmail: '請輸入郵箱',
    pleaseInputYourMobile: '請輸入正確的郵箱',
    pleaseInputYourValidEmail: '請輸入手機號',
    pleaseInputYourValidMobile: '請輸入正確的手機號',
    getVerificationCode: '獲取驗證碼',
    register: '註冊',
    notEmpty: '不能為空',
    pleaseFillOut: '請填寫',
    getAgain: '再次獲取',
    loginSuccess: '登入成功',
    confirmMergeAccounts: '確認合併帳號？',
    merge: '合併',
    iAwareRisks: '我已知曉風險',
    deleteWechatAccountWarn: '此操作將刪除微信的帳號，且不可恢復，請謹慎操作！',
    mobileAlreadyRegister: '手機號碼已經註冊',
    cancel: '取消',
    changeBindMobile: '換綁手機號',
    mergeAccount: '合併帳號',
    keepMobileAndDeleteWechat: '保留手機號碼的帳號 刪除微信的帳號',
    keepWechatAndDeleteMobile: '保留微信的帳號 刪除手機號碼的帳號',
    youAccountUnderReview: '您的帳號正在審核中',
    backLoginPage: '返回登入頁',
    MergeWeChatAccountDescription: '將微信和手機號碼註冊的帳號合併為一個帳號請選擇保留的帳號：',
    goHome: '回到主頁',
    accountSetting: '账号设置',
    mobileAlreadyRegisterDesc: '该手机号已被注册为：{{name}} ({{userId}})，若仍想绑定该手机号，请点击「合并账号」。',
    pleaseWeChatLoginInWeChatBrowser: '請在微信瀏覽器中使用微信登入',
    refresh: '刷新',
    expiredPleaseRefresh: '已過期，刷新',
    scanWechatLogin: '使用微信掃碼追蹤公眾號即可登錄',
    resendTime: `{{v}} s後重發`,
    verificationMessage: `我們已向 {{v}} 發送了驗證碼，請查看您的郵箱`,
    verificationSMS: `我們已向 {{v}} 發送了簡訊驗證碼`,
    inputVerificationCode: `輸入{{v}}驗證碼`,
    search: '搜索',
    personNameOrPersonId: '人員名稱、人員編號',
    noFindData: '沒有找到數據',
    noFindPerson: '没有找到人員',
    selected: '只看已選',
    logout: '退出登錄',
    notBind: '無法解綁',
    mobileNumber: '手機號',
    wechat: '微信',
    removeBindAccountDescribe: '{{account}}作為本帳號唯一登錄方式，請先綁定其他帳號作為替代登錄方式。',
    knew: '知道了',
    continueUnbinding: '繼續解綁',
    notEnabledAccountLogin: '未啟用{{account}}登錄功能',
    emailAccount: '郵箱帳號',
    alreadyBind: '已绑定',
    wechatAccount: '微信帳號',
    bindAfterUsedAccountLogin: '綁定後可使用{{account}}登錄',
    bindAccountAfterAccountNotEnable:
        '該{{account}}已經註冊過帳號，請更換{{account}}。若您仍想綁定，請先解除剛剛綁定失敗的{{account}}的原綁定關係後再將其與當前登錄的帳號綁定',
    bindMeetQuestion: '綁定遇到問題',
    changeAccount: '更換{{account}}',
    goBind: '去綁定',
    unbind: '解綁',
    notEnable: '未啟用',
    bindMobileNumber: '綁定手機號',
    bindMobileNumberAfterLoginApp: '綁定後可使用手機號登錄應用',
    enterSmsCode: '輸入簡訊驗證碼',
    confirmUnbinding: '確認解綁',
    unBindNotLoginConfirmDescribe: '解綁後無法再通過{{account}}途徑登錄，確認繼續解綁？',
    alreadyUnbindAccount: '已解除{{account}}綁定',
    clickToUpload: '點擊上傳',
    rename: '重命名',
    delete: '刪除',
    download: '下載',
    view: '查看',
    recordVideo: '錄像',
    photograph: '拍照',
    selectFromGallery: '從手機相冊選擇',
    localFiles: '本地文件',
    more: '更多',
    onlyPicture: '',
    fileExceedsUploadLimit: '不能上傳大於 {{v}}mb 的文件',
    fileExceedsUploadAvatarLimit: '不能上傳大於 {{v}}mb 的頭像',
    unsupportedFormatOnlyImageUploads: '格式錯誤，僅支持上傳圖片',
    unsupportedVideoFormatPleaseUploadMP4file: '視頻格式錯誤，請上傳 MP4 格式的視頻',
    sort: '排序',
    sortEmptyDescribe: '新增排序規則，讓你的資料井然有序',
    sortRule: '排序规则',
    add: '新增',
    group: '分組',
    linesTotal: '共{{ count }}行',
    field: '字段',

    groupBy: '分組依據',
    pleasesSelect: '請選擇',
    xItemsSelected: '已選擇 {{count}} 項',
    print: '列印',
    edit: '編輯',
    kanbanSetting: '看板設置',
    foldingBoard: '摺疊看板',
    hideBoard: '隱藏看板',
    text: '正文',
    firstLevelHeading: '一級標題',
    secondLevelHeading: '二級標題',
    thirdLevelHeading: '三級標題',
    todoList: '待辦清單',
    numberList: '有序列表',
    bulletedList: '無序列表',
    alginCenter: '居中',
    alginLeft: '居左',
    alginRight: '居右',
    clearFormat: '清除格式',
    bold: '加粗',
    strikethrough: '刪除線',
    italic: '斜體',
    underline: '下劃線',
    textColor: '文字顏色',
    textBackgroundColor: '文本背景色',
    hyperlink: '超連結',
    insertCode: '插入代碼',
    uploadImage: '上傳圖片',
    insertCitation: '插入引用',
    divider: '分隔線',
    operation: '操作',
    addOneRow: '新增一行',
    accordingTo: '按',
    pasteLink: '粘貼連結',

    displayedField: '顯示欄位',
    unDisplayedField: '未顯示欄位',
    hideAll: '全部隱藏',
    hideEmptyGroups: '隱藏空白分組',
    noData: '暫無資料',
    pleasesGoDataSourceAdd: '請前往資料源新增',
    export: '導出',
    sureDelete: '確認刪除？',
    deleteRecordWaring: '刪除所選的 {{count}} 筆記錄後無法恢復，請謹慎操作。',
    undo: '撤銷',
    redo: '重做',
    uploadXLSXtips: '點擊或拖曳<bold />到此區域。',
    uploadingXLSXemptyTips: '請確保：檔案中不存在<bold />。',
    uploadFileSizeLimitHowMuch: '檔案大小不可超過10MB。',
    importLimitNumberW: '每次匯入的資料不可超過2萬行。',
    onlyXLSXorCSVofFile: '僅支援.xlsx、.csv格式的檔案。',
    fileNoEmptySheet: '檔案中不能有空白工作表。',
    tableFileNoMergeCell: '檔案中不能存在合併的單元格。 ',
    localImport: '本地導入',
    exportingOfProgress: '正在導出，請勿重複點擊。',
    xlsxCsvFileNumber: '.xlsx、.csv檔案（{{v}}M以內）',
    emptySheetOrMergeCell: '空白工作表和合併的單元格',
    batchImport: '批次導入',
    collapseKanban: '摺疊看板',
    hideKanban: '隱藏看板',
    groupSetting: '分組設定',

    startImport: '開始導入',
    againImport: '重新導入',
    previous: '上一步',
    createNewTable: '建立新的表',
    appendImportTable: '追加匯入到已有表',
    pleasesSelectSheet: '請在 <name /> 中選擇 <number /> Sheet',
    a: '一個',
    reupload: '重新上傳',
    fieldMapping: '欄位對應',
    ofColumn: '列',
    ofField: '欄位',
    mapping: '對應',
    importSuccess: '導入成功',
    showAll: '全部顯示',
    pleasesSetKanbanGrouping: '請設置看板分組依據。',
    exportSettings: '導出設置',
    selectRecords: '選擇記錄',
    exportAllRecords: '導出所有記錄',
    exportSpecifiedRecords: '導出指定記錄',
    exporting: '正在導出',
    aMaximumOfRecordsCanExportedAtATime: '單次最多導出 {{count}} 條記錄',
    printTemplate: '打印模板',
    selectTemplate: '選擇模板',
    exportMethod: '導出方式',
    exportByPage: '分頁導出（所有數據合成一個 PDF 文件，且數據分頁）',
    noExportTemplate: '暫無導出模板',
    exportingPDFforPrinting: '正在導出用於打印的 PDF 文件',
    exportFailedTheExportFileCannotExceedNumber: '導出失敗，導出的文件大小不能超過 {{size}}mb',
    exportFailedOfNumberRecordsExport: '導出失敗，每次最多導出 {{count}} 條記錄。',
    // date
    beforeYesterday: '前天',
    yesterday: '昨天',
    today: '今天',
    tomorrow: '明天',
    afterTomorrow: '後天',

    '0000000': '成功',
    '0011001': '用戶名或密碼錯誤',
    '0011002': '該用戶未註冊',
    '0011004': '應用端應用不存在',
    '0011005': '頁面無訪問許可權',
    '0011009': '用戶不存在',
    '0011011': '驗證碼已失效',

    '0011010': '名稱重複',
    '0011013': '無法啟用工作流，請檢查配置後重試',
    '0011017': '應用已下線',
    '0011018': '域名已存在，請重新輸入',
    '0011019': '用戶已註冊',
    '0011021': '您的帳號正在審核',

    '0011023': '測試賬號連接失敗',
    '0011024': '應用數量達到上限',
    '0011025': '記錄條數達到上限',
    '0011027': '附件容量達到上限',
    '0011029': '應用無權限',
    '0011030': '手機號重複',
    '0011031': '部門名稱重複',
    '0011032': '用戶數量達到上限',
    '0011033': '綁定自有域名的應用達到上限',
    '0011036': '導出超過 500 條',
    '0011037': '導出超過 50MB',
    '0011039': '賬號異常，請聯繫管理員',
    '0011042': '微信公眾號未授權',
    '0011043': '郵箱重複',
    '0011044': '手機號已綁定',
    '0011045': '請在微信瀏覽器或 PC 網頁中打開頁面後再進行支付。',
    '0011047': '訪問流量超出配額',
    '0011048': '簡訊超出配額',
    '0011049': '找不到記錄',
    '0011050': '',
    '0011052': '微信模板消息異常',
    '0011054': '環境存在異常，禁止合併',
    '0011055': '生成環境禁止修改',
    '0011057': '該應用用戶已被其他應用綁定，無法再綁定其他應用',
    '0011058': '私人模板禁止操作',
    '0011059': '微信不可重複綁定',
    '0011060': '兩個環境的數據表不一致，無法切換同步數據表環境',
    /**
     * 动作流相关节点异常 start
     */
    '0011061': '郵件發送異常',
    '0011062': '短信發送異常',
    '0011063': '釘釘機器人異常',
    '0011064': '微信機器人異常',
    '0011065': '該訂單已過期，請重新建立訂單',
    /**
     * 动作流相关节点异常 end
     */
    '0011066': '頁面不存在',
    '0011068': 'UV超出限制',
    '0011069': '應用程式複製失敗，應用程式中存在從其他應用程式同步的表或存在同步用戶',
    '0011070': '應用程式模板不存在',
    '0011073': '驗證碼短信配置未完善',
    '0011074': '無法發佈工作流，請檢查配置後重試',
    '0011076': '',
    '0020000': '頁面不存在或頁面未公開！',
    '0030000': '過濾異常，無需拋出'
}
