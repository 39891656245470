import type { ActionFlowNode, SendEmailActionConfig } from '@lighthouse/shared'
import { generateHtmlBySendEmail, generateVariableValue, getEmails } from '@lighthouse/shared'
import type { AnyObject } from 'immer/dist/internal'

import * as srv from '@/services'

export const handleSendEmail = async (node: ActionFlowNode<SendEmailActionConfig>, excParams: AnyObject) => {
    const {
        data: { config }
    } = node

    if (!config) {
        return excParams
    }
    const { senderAccountId, recipients, cc, title, enableCc } = config
    const titleValue = generateVariableValue({ innerType: 'TEXT', jsonContent: title, extraParams: excParams })

    const html = generateHtmlBySendEmail({
        config,
        excParams
    })

    const recipientsValue = getEmails(recipients, excParams)
    const ccValue = enableCc ? getEmails(cc, excParams) : []

    await srv.activeSendEmailAction({
        senderAccountId,
        recipients: recipientsValue,
        cc: ccValue,
        title: Array.isArray(titleValue) ? '' : String(titleValue),
        content: html
    })

    return {
        senderAccountId,
        title: titleValue,
        content: '',
        recipients: recipientsValue,
        cc: ccValue
    }
}
