import { IconFont, Input, itemBackgroundTransitionOnClick, Popover } from '@byecode/ui'
import type { RectSize, RectSizeUnit } from '@lighthouse/core'
import { useAtomData } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React, { useCallback, useMemo, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { usePreviewType } from '@/hooks/useApplication'
import { transform2Integer, transform2Number } from '@/utils/number'

import { SizeIcon } from './SizeIcon'
import { convertSize } from './utils'

type MinMaxSizeInputType = 'minWidth' | 'maxWidth' | 'minHeight' | 'maxHeight'

type MinMaxSizeInputValue = {
    size?: RectSize
    unit?: RectSizeUnit
}

interface MinMaxSizeInputProps {
    type: MinMaxSizeInputType
    value?: MinMaxSizeInputValue
    disable?: boolean
    onChange: (value: MinMaxSizeInputValue) => void
    onRemove: () => void
}

type RectSizeOption = RectSize | RectSizeUnit

const SCxRectMinMaxSizeInputWrapper = styled.div`
    display: flex;
    width: 180px;
`

const SCxInput = styled(Input)``

const SCxDropdownButton = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    background-color: var(--color-gray-100);
    border-left: 1px solid var(--color-gray-200);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    cursor: pointer;

    ${itemBackgroundTransitionOnClick}

    &:hover {
        background-color: var(--color-gray-200);
    }
`

const SCxRemoveButton = styled.div`
    display: flex;
    width: 32px;
    height: 32px;
    margin-left: 8px;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    cursor: pointer;

    ${itemBackgroundTransitionOnClick}

    &:hover {
        background-color: var(--color-gray-200);
    }
`

const SCxListItem = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 8px 16px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

export const RectMinMaxSizeInput: React.FC<MinMaxSizeInputProps> = ({ type, value, disable, onChange, onRemove }) => {
    const previewType = usePreviewType()
    const [moveOpen, setMoveOpen] = useState(false)
    const [dropdownOpened, setDropdownOpened] = useState(false)
    const init = useMemo(() => value ?? { size: undefined, unit: undefined }, [value])

    const [v, setV] = useImmer<MinMaxSizeInputValue>(init)
    const lowerCaseType = type.toLowerCase()
    const inputIconType = useMemo(() => {
        if (lowerCaseType.includes('width')) {
            return 'Width'
        }
        if (lowerCaseType.includes('height')) {
            return 'Height'
        }
    }, [lowerCaseType])
    const dropdownOptions = useMemo(() => {
        return [
            { label: '固定值', value: 'px' },
            { label: '百分比', value: '%' }
        ]
    }, [])

    const blockId = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => s?.state.selectedNodes?.[0], [])
    )

    useUpdateEffect(() => {
        setV(init)
    }, [value])

    const handleMoveChange = useCallback(
        (v: number) => {
            const num = transform2Integer(v)
            const val: MinMaxSizeInputValue = {
                size: num,
                unit: 'px'
            }
            setV(val)
            if (isDeepEqual(val, value)) {
                return
            }
            onChange(val)
        },
        [onChange, setV, value]
    )

    const handleChange = useCallback(
        (ev: React.ChangeEvent<HTMLInputElement>) => {
            const val = ev.target.value
            const size = val ? transform2Number(val) : 0
            setV(draft => {
                draft.size = size as RectSize
            })
        },
        [setV]
    )

    const handleItemClick = useCallback(
        (sizeOrUnit: 'px' | '%') => {
            if (!blockId) {
                return
            }
            const size = convertSize(blockId, type, sizeOrUnit, previewType) as RectSize
            const val: MinMaxSizeInputValue = {
                size,
                unit: sizeOrUnit
            }
            setV(val)
            setDropdownOpened(false)
            if (isDeepEqual(val, value)) {
                return
            }
            onChange(val)
        },
        [blockId, onChange, previewType, setV, type, value]
    )

    const handleBlur = useCallback(
        (ev: React.FocusEvent<HTMLInputElement>) => {
            const v = ev.target.value
            const number = transform2Number(Number(v).toFixed(2))
            const validNumber = Number.isNaN(number) ? undefined : number
            const val: MinMaxSizeInputValue = {
                size: validNumber,
                unit: value?.unit
            }
            setV(val)
            if (isDeepEqual(val, value)) {
                return
            }
            onChange(val)
        },
        [onChange, setV, value]
    )

    const resizeStyle = useMemo(
        () =>
            moveOpen
                ? {
                      borderColor: 'var(--color-main)',
                      backgroundColor: 'var(--color-gray-200)'
                  }
                : {},
        [moveOpen]
    )

    return (
        <SCxRectMinMaxSizeInputWrapper>
            <SCxInput
                value={v?.size}
                prefix={
                    inputIconType && (
                        <SizeIcon
                            disable={disable}
                            blockStyleSize={{
                                blockId,
                                sizeInputType: type
                            }}
                            type={inputIconType}
                            onChangeMove={setMoveOpen}
                            onChange={handleMoveChange}
                        />
                    )
                }
                onFocus={e => e.currentTarget.select()}
                suffix={value?.unit}
                radius={0}
                styles={{
                    wrapper: { borderTopRightRadius: '0!important', borderBottomRightRadius: '0!important', ...resizeStyle },
                    suffix: { fontSize: 12 },
                    prefix: {
                        pointerEvents: 'auto',
                        cursor: 'ew-resize'
                    }
                }}
                type="number"
                readOnly={disable}
                disabled={disable}
                onChange={handleChange}
                onKeyDownCapture={ev => {
                    if (ev.key === 'Enter') {
                        ev.currentTarget.blur()
                    }
                }}
                onBlur={handleBlur}
            />
            <Popover opened={dropdownOpened} width={180} position="bottom-end" disabled={disable} onChange={setDropdownOpened}>
                <Popover.Target>
                    <SCxDropdownButton>
                        <IconFont type="ArrowDownSmall" color="var(--color-gray-400)" />
                    </SCxDropdownButton>
                </Popover.Target>
                <Popover.Dropdown>
                    {dropdownOptions.map(option => (
                        <SCxListItem key={option.value} onClick={() => handleItemClick(option.value as 'px' | '%')}>
                            {option.label}
                            {(value?.unit === option.value || value?.size === option.value) && (
                                <IconFont type="Tick" color="var(--color-main)" />
                            )}
                        </SCxListItem>
                    ))}
                </Popover.Dropdown>
            </Popover>
            <SCxRemoveButton onClick={onRemove}>
                <IconFont type="Reduce" color="var(--color-gray-400)" />
            </SCxRemoveButton>
        </SCxRectMinMaxSizeInputWrapper>
    )
}
