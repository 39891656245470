import type {
    DataSourceAbstract,
    FieldBlockWithDsId,
    FieldType,
    FilterableProtocol,
    FilterBlockAbstract,
    FilterMode,
    TypeInstanceMap,
    ViewField,
    ViewType
} from '@lighthouse/core'
import React from 'react'
import styled from 'styled-components'

import type { FlowNode, VariableSource } from '../../types'
import type { VariableTree } from '../Variable'
import { FilterContent } from './FilterContent'

interface ViewFieldFilterConfigureProps {
    primaryField?: string
    dataSource?: DataSourceAbstract
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
    dataSourceList: DataSourceAbstract[]
    sources?: VariableSource[]
    formDsId?: string
    columns: ViewField[]
    filter?: FilterableProtocol['filter']
    noSettingInnerType?: Set<TypeInstanceMap>
    fieldMode?: FilterMode
    paramsMode?: FilterMode
    enableCurrentUser?: boolean
    viewDsId?: string
    viewType?: ViewType
    maxHeight?: number
    filterBlocks?: FilterBlockAbstract[]
    enableFilterVariable?: boolean
    disabled?: boolean
    onFilter?: (val: FilterableProtocol['filter']) => void
    onMouseEnter?: (blockId: string) => void
    onMouseLeave?: () => void
}

const PropertySettingWrapper = styled.div<{ maxHeight?: number }>`
    padding: 8px 0;
    font-size: 14px;
    width: auto;
    overflow-y: auto;
    max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : 'auto')};
`

export const ViewFieldFilterConfigure: React.FC<ViewFieldFilterConfigureProps> = ({
    primaryField,
    dataSource,
    fieldBlocksWithDsId,
    sources,
    dataSourceList,
    columns,
    filter,
    noSettingInnerType,
    formDsId,
    fieldMode,
    paramsMode,
    enableCurrentUser,
    viewDsId,
    viewType,
    maxHeight,
    filterBlocks,
    enableFilterVariable,
    disabled,
    onFilter,
    onMouseEnter,
    onMouseLeave
}) => {
    return (
        <PropertySettingWrapper maxHeight={maxHeight}>
            <FilterContent
                filter={filter}
                dataSource={dataSource}
                fieldBlocksWithDsId={fieldBlocksWithDsId}
                dataSourceList={dataSourceList}
                columns={columns}
                sources={sources}
                primaryField={primaryField}
                noSettingInnerType={noSettingInnerType}
                fieldMode={fieldMode}
                paramsMode={paramsMode}
                enableCurrentUser={enableCurrentUser}
                formDsId={formDsId}
                viewDsId={viewDsId}
                viewType={viewType}
                filterBlocks={filterBlocks}
                enableFilterVariable={enableFilterVariable}
                disabled={disabled}
                onFilter={onFilter}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            />
        </PropertySettingWrapper>
    )
}
