import type { RecordLikeProtocol, VariableADTvalue } from '@lighthouse/core'
import React from 'react'

import type { ContainerHighlight, FlowLayoutNode, LayoutProperty, NodeIdWithScope, NodeRenderProps } from './types'

// 栅格基础属性
export interface FlowLayoutContextValue extends Partial<Omit<LayoutProperty, 'viewLayout'>> {
    rootElement?: HTMLDivElement | null
    data: FlowLayoutNode[]
    selectedNodes?: NodeIdWithScope[]
    disabled?: boolean
    parseBackgroundVariableImage?: (value: VariableADTvalue, record?: RecordLikeProtocol) => string
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
    highlight?: Record<string, ContainerHighlight | undefined>
    scale?: number
}
export const DEFAULT_LAYOUT_CONTEXT_VALUE: FlowLayoutContextValue = {
    data: [],
    disabled: false
}
const FlowLayoutContext = React.createContext(DEFAULT_LAYOUT_CONTEXT_VALUE)
export const FlowLayoutProvider = FlowLayoutContext.Provider
export const useFlowLayoutContext = () => React.useContext(FlowLayoutContext)

// 容器属性
export interface ContainerLayoutContextValue extends Partial<LayoutProperty> {
    parentId?: string
    level: number
}
export const DEFAULT_CONTAINER_CONTEXT: ContainerLayoutContextValue = {
    level: 0,
    size: {
        width: {
            size: 'fill'
        },
        height: {
            size: 'auto'
        }
    },
    layout: {
        align: {
            direction: 'vertical'
        }
    }
}
const ContainerLayoutContext = React.createContext(DEFAULT_CONTAINER_CONTEXT)
export const ContainerLayoutProvider = ContainerLayoutContext.Provider
export const useContainerLayoutContext = () => React.useContext(ContainerLayoutContext)
