import { Button, Flex, IconFont, Menu, pointer, Text, tinyButtons, Tooltip, usePopoverHeight } from '@byecode/ui'
import type { FieldInputADTValue, PhoneNumberConfig, SimpleTextValue } from '@lighthouse/core'
import { getPasteSmsCode } from '@lighthouse/tools'
import { reduce } from 'rambda'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import { COUNTRY_LIST, FieldIconTypeMap, mobileRegex } from '../../../constants'
import { useSmsSendCode } from '../../../hooks'
import BaseInput from '../BaseInput'
import { fieldLabelMap, popoverMaxHeight } from '../constant'
import type { FieldBaseProps } from '../types'

interface PhoneNumberFieldProps extends FieldBaseProps {
    value: SimpleTextValue
    phoneNumberConfig: PhoneNumberConfig
}

const SCxIconWrapper = styled.div`
    display: flex;
    align-items: center;
    ${pointer}
`

const SCxContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 6px;
`

const SCxListContainer = styled.div`
    max-height: 600px;
    width: 100%;
    height: 100%;
    overflow: hidden auto;
    ${tinyButtons}
`

const SCxCodeInput = styled(BaseInput)`
    border-radius: 6px;
    background-color: var(--color-white);
    box-shadow: 0 0 0 1px var(--color-gray-200);

    &:focus-within {
        box-shadow: 0 0 0 2px var(--color-app-main);
    }
`

type Country = {
    short: string
    name: string
    en: string
    tel: string
    flag: string
}

const PhoneNumberField: React.FunctionComponent<PhoneNumberFieldProps> = props => {
    const {
        onCellChange,
        onSaveCellChange,
        onFetchSmsCode,
        onChangeSmsCode,
        value,
        config: { size, placeholder, inputType },
        phoneNumberConfig,
        readOnly
    } = props
    const { phoneNumber } = phoneNumberConfig ?? {}
    const { isUsedAreaCode, areaCode: defaultAreaCode, selectAreaCode, isOpened } = phoneNumber ?? {}
    const [text, setText] = useState(value)
    const inputRef = useRef<HTMLTextAreaElement>(null)
    const [isHover, setIsHover] = useState(false)
    const [isFocus, setIsFocus] = useState(false)
    const [opened, setOpened] = useState(false)

    const [areaCode, setAreaCode] = useState(defaultAreaCode)
    const [code, setCode] = useState('')

    const handleGetCodeHandle = useCallback(
        async (resolve?: () => void, reject?: () => void) => {
            const result = await onFetchSmsCode?.(text)
            return result ? resolve?.() : reject?.()
        },
        [onFetchSmsCode, text]
    )
    const { getPhoneCode, reset, state: codeState } = useSmsSendCode(handleGetCodeHandle)

    const isShowSmsCode = isOpened && onFetchSmsCode

    const disabledSendSmsCodeBtn = codeState.codeStep > 0 || codeState.disable || readOnly || !mobileRegex.test(text)
    const countryList = useMemo(() => {
        return reduce<string, Country[]>(
            (pre, short) => {
                const option = COUNTRY_LIST.find(item => item.short === short)
                return option ? [...pre, { ...option, tel: `(+${option?.tel})` }] : pre
            },
            [],
            selectAreaCode ?? []
        )
    }, [selectAreaCode])

    const countryOptions = useMemo(() => {
        return (
            selectAreaCode?.map(short => {
                const country = countryList.find(item => item?.short === short)
                return {
                    label: country?.en,
                    value: country?.short ?? '',
                    tel: country?.tel ?? '',
                    icon: <img src={country?.flag} alt="" width={16} />,
                    extra: (
                        <Text color="var(--color-gray-400)" style={{ overflow: 'unset' }}>
                            {country?.tel}
                        </Text>
                    )
                }
            }) ?? []
        )
    }, [countryList, selectAreaCode])

    const [areaTelCodeValue, phoneValue] = useMemo(() => {
        const area = countryList.find(country => country?.short === areaCode)
        const countryTel = countryList.find(country => country?.tel && text.startsWith(country?.tel))
        if (!isUsedAreaCode) {
            return ['', text]
        }
        if (countryTel) {
            return [area?.tel ?? '', text.slice(countryTel.tel.length)]
        }
        return [area?.tel ?? '', text]
    }, [areaCode, countryList, isUsedAreaCode, text])

    const currentCountryOption = useMemo(
        () => countryOptions.find(item => item.tel === areaTelCodeValue),
        [areaTelCodeValue, countryOptions]
    )

    const { ref, height: maxHeight } = usePopoverHeight(opened, popoverMaxHeight)

    useUpdateEffect(() => {
        setText(value)
        if (value === '') {
            reset()
            setCode('')
        }
    }, [value])

    useUpdateEffect(() => {
        setAreaCode(defaultAreaCode)
    }, [defaultAreaCode])

    const handleSmsCode = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            setCode(v => {
                const smsCode = getPasteSmsCode(v, e.target.value)
                onChangeSmsCode?.(smsCode)
                return smsCode
            })
        },
        [onChangeSmsCode]
    )

    const handleInputChange = useCallback(
        (ev: React.ChangeEvent<HTMLTextAreaElement>) => {
            const { value } = ev.target
            const newText = value === '' ? '' : areaTelCodeValue + value
            setText(newText)
            onSaveCellChange?.({ type: inputType, value: newText } as FieldInputADTValue)
        },
        [areaTelCodeValue, inputType, onSaveCellChange]
    )

    const handleInputBlur = useCallback(() => {
        const newText = phoneValue === '' ? '' : areaTelCodeValue + phoneValue
        setIsFocus(false)
        onCellChange?.({ type: inputType, value: newText } as FieldInputADTValue)
    }, [areaTelCodeValue, inputType, onCellChange, phoneValue])

    const handleAreaChange = useCallback(
        (short: string) => {
            const areaTelCode = countryList.find(country => country?.short === short)
            setAreaCode(areaTelCode?.short)
            setOpened(false)
            const newText = (areaTelCode?.tel ?? '') + phoneValue
            setText(newText)
            onCellChange?.({ type: inputType, value: newText } as FieldInputADTValue)
        },
        [countryList, inputType, onCellChange, phoneValue]
    )

    const href = useMemo(() => {
        if (!text) {
            return ''
        }
        return `tel:${text}`
    }, [text])

    const isShowRightSection = useMemo(() => (isHover && !readOnly) || isFocus, [isFocus, isHover, readOnly])

    const handleNavigate = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()
            if (!href) {
                return
            }
            window.location.href = href
        },
        [href]
    )

    return (
        <SCxContainer>
            <BaseInput
                value={phoneValue}
                size={size}
                placeholder={placeholder}
                data-field-border={opened || isFocus}
                onChange={handleInputChange}
                onFocus={() => setIsFocus(true)}
                onMouseEnter={() => {
                    !readOnly && setIsHover(true)
                }}
                onMouseLeave={() => {
                    setIsHover(false)
                }}
                onBlur={handleInputBlur}
                readOnly={readOnly}
                ref={inputRef}
                isWrap={false}
                leftSection={
                    isUsedAreaCode ? (
                        <Menu opened={opened} closeOnItemClick width={200} withinPortal onChange={setOpened}>
                            <Menu.Target>
                                <div>
                                    <Flex style={{ marginRight: 8 }} ref={ref} gap={4} alignItems="center" justifyContent="flex-end">
                                        {currentCountryOption?.icon}
                                        {currentCountryOption ? (
                                            <Text color="var(--color-black)">{areaTelCodeValue}</Text>
                                        ) : (
                                            <Text color="var(--color-gray-300)">请选择</Text>
                                        )}
                                        <IconFont type="CareDown" color="var(--color-gray-400)" size={16} />
                                    </Flex>
                                </div>
                            </Menu.Target>
                            <Menu.Dropdown>
                                <SCxListContainer style={{ maxHeight }}>
                                    {countryOptions.map(option => (
                                        <Menu.Item
                                            key={option.value}
                                            icon={option.icon}
                                            onClick={() => handleAreaChange(option.value)}
                                            rightSection={
                                                option.value === areaTelCodeValue && (
                                                    <IconFont size={16} type="Tick" color="var(--color-app-main)" />
                                                )
                                            }
                                        >
                                            <Flex gap={4}>
                                                <Text lineClamp={1}> {option.label}</Text>
                                                {option.extra}
                                            </Flex>
                                        </Menu.Item>
                                    ))}
                                </SCxListContainer>
                            </Menu.Dropdown>
                        </Menu>
                    ) : null
                }
                rightSection={
                isShowRightSection &&   (
                        <div
                            onMouseEnter={() => {
                                !readOnly && setIsHover(true)
                            }}
                            onMouseLeave={() => {
                                setIsHover(false)
                            }}
                            style={{display: 'flex', alignItems: 'center'}}
                        >
                            <Tooltip offset={16} title={fieldLabelMap.get(inputType)}>
                                <SCxIconWrapper onClick={handleNavigate}>
                                    <IconFont color="var(--color-gray-400)" type={FieldIconTypeMap[inputType]} />
                                </SCxIconWrapper>
                            </Tooltip>
                        </div>
                    )
                }
            />
            {isShowSmsCode && (
                <Flex gap={8} alignItems="center">
                    <SCxCodeInput
                        placeholder="短信验证码"
                        type="number"
                        readOnly={readOnly}
                        value={code}
                        maxLength={6}
                        onChange={handleSmsCode}
                        autoComplete="one-time-code"
                    />
                    <Button
                        disabled={disabledSendSmsCodeBtn}
                        style={{ flexShrink: 0, height: 40, minWidth: 120, whiteSpace: 'nowrap' }}
                        onClick={getPhoneCode}
                    >
                        {codeState.codeText}
                    </Button>
                </Flex>
            )}
        </SCxContainer>
    )
}

export default PhoneNumberField
