import type { VariableADTvalue } from '@lighthouse/core'
import { type AppUser, type DTSelectItem, type Field, VariableType } from '@lighthouse/core'
import type { Node as ProseMirrorNode } from '@tiptap/pm/model'
import type { Editor, NodeViewProps } from '@tiptap/react'
import { NodeViewContent, NodeViewWrapper } from '@tiptap/react'
import React from 'react'
import styled from 'styled-components'

import { useSharedConfigDisabledWithVersion } from '../../../../contexts'
import type { VariableTree } from '../../../'
import { type VariableOptions } from '../../../'
import { VariableTag } from '../../../Variable/VariableTag'
import { useNodeFieldEditor } from '../../EditorProvider'
// import type { VariablePopoverSchema } from '../../../VariablePicker'
// import { getSchemaOption, VariableType } from '../../../VariablePicker'


const SCxVariableNode = styled(NodeViewContent)`
    margin: 0 4px;
    /* vertical-align: middle; */
`

export interface DataQuoteOptions {
    options: VariableOptions
    // nodeData?: VariableOptions
    // renderTooltip?: (node: ProseMirrorNode) => React.ReactNode
    personOptions?: AppUser[]
    userOption?: VariableTree
    formOption?: VariableTree
    viewOption?: VariableTree
    eventOption?: VariableTree
    dataSourceOption?: VariableTree
    selectOptions?: DTSelectItem[]
    onNodeClick?: () => void
}

interface DataQuoteComponentProps {
    children?: React.ReactNode
    node: ProseMirrorNode
    extension: { options: DataQuoteOptions }
    deleteNode: () => void
}

export interface DateQuoteOptions {
    field: Field
    onNodeClick?: () => void
}
interface DateQuoteComponentProps {
    children?: React.ReactNode
    node: ProseMirrorNode
    extension: { options: DateQuoteOptions }
    deleteNode: () => void
}

const commonStyle: React.CSSProperties = {
    display: 'inline-flex',
    alignItems: 'center'
    // position: 'relative',
    // top: '-1px'
}

const isCursorInCustomNode = (node: NodeViewProps['node'], state: Editor['state']) => {
    const { selection } = state

    // @ts-expect-error - ProseMirror types are not up-to-date
    return node === selection?.node
}

export const VariableComponent: React.FC<DataQuoteComponentProps> = ({ children, node, extension, deleteNode }) => {
    const { options: extensionOptions } = extension
    const { options, userOption, viewOption, formOption, eventOption, dataSourceOption } = extensionOptions
    const disabledWithVersion = useSharedConfigDisabledWithVersion()
    const variableData = node.attrs.value as VariableADTvalue
    const editor = useNodeFieldEditor()
    const isSelected = editor?.state && isCursorInCustomNode(node, editor?.state)

    if (!variableData) {
        return null
    }

    if (
        [
            VariableType.FIELD_ID,
            VariableType.PAGE,
            VariableType.VIEW,
            VariableType.USER,
            VariableType.VARIABLE,
            VariableType.UPSTREAM,
            VariableType.ARG_VARIABLE,
            VariableType.SYSTEM,
            VariableType.PAGE_LINK,
            VariableType.FORM,
            VariableType.EVENT
        ].includes(variableData.type)
    ) {
        return (
            <NodeViewWrapper as="span" style={{ fontWeight: 'normal' }}>
                <SCxVariableNode as="span" contentEditable={false}>
                    <VariableTag
                        isSelected={isSelected}
                        withClose={!disabledWithVersion}
                        onClose={deleteNode}
                        dataSourceOption={dataSourceOption}
                        viewOption={viewOption}
                        formOption={formOption}
                        eventOption={eventOption}
                        value={variableData}
                        options={options}
                        userDsOption={userOption}
                    />
                </SCxVariableNode>
            </NodeViewWrapper>
        )
    }
    return null
}
