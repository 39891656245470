import type { ButtonAction, ButtonActionType } from "@lighthouse/core";
import { type VariableADTvalue, RecordOpenType, VariableType } from "@lighthouse/core";
import { nanoid } from "@lighthouse/tools";


export const getButtonActionsMap = () => {
    const id = nanoid()
    return new Map<ButtonActionType, ButtonAction>([[
        'none',
        {
            id,
            type: 'none',
            trigger: 'click',
            data: {
                none: {}
            }
        }
    ],
    [
        'createRecord',
        {
            id,
            type: 'createRecord',
            trigger: 'click',
            data: {
                createRecord: {
                    dataSourceId: '',
                    fields: []
                }
            }
        }
    ],
    [
        'deleteRecord',
        {
            id,
            type: 'deleteRecord',
            trigger: 'click',
            data: {
                deleteRecord: {}
            }
        }
    ],
    [
        'updateRecord',
        {
            id,
            type: 'updateRecord',
            trigger: 'click',
            data: {
                updateRecord: {
                    dataSourceId: '',
                    selectType: 'CURRENT_RECORD',
                    nodeId: '',
                    fields: []
                }
            }
        }
    ],
    [
        'openLink',
        {
            id,
            type: 'openLink',
            trigger: 'click',
            data: {
                openLink: {
                    link: {},
                    target: '_blank'
                }
            }
        }
    ],
    [
        'scrollTo',
        {
            id,
            type: 'scrollTo',
            trigger: 'click',
            data: {
                scrollTo: {
                    scrollToElementId: ''
                }
            }
        }
    ],
    [
        'control',
        {
            id,
            type: 'control',
            trigger: 'click',
            data: {
                control: {
                    controlElementId: '',
                    controlElementType: 'view'
                }
            }
        }
    ],
    [
        'openPage',
        {
            id,
            type: 'openPage',
            trigger: 'click',
            data: {
                openPage: {
                    openPageUrl: '',
                    pageOpenType: RecordOpenType.modal
                }
            }
        }
    ],
    [
        'openFormPage',
        {
            id,
            type: 'openFormPage',
            trigger: 'click',
            data: {

                openFormPage: {
                    formPageId: '',
                    dataSourceId: '',
                    pageOpenType: RecordOpenType.modal
                }
            }
        }
    ],
    [
        'openRecordPage',
        {
            id,
            type: 'openRecordPage',
            trigger: 'click',
            data: {

                openRecordPage: {
                    dataSourceId: '',
                    filter: {
                        expression: {
                            conditions: []
                        }
                    },
                    recordPageId: '',
                    pageOpenType: RecordOpenType.modal,
                    selectedRecordType: 'CURRENT_RECORD'
                }
            }
        }
    ],
    [
        'openRecordEditPage',
        {
            id,
            type: 'openRecordEditPage',
            trigger: 'click',
            data: {
                openRecordEditPage: {
                    dataSourceId: '',
                    filter: {
                        expression: {
                            conditions: []
                        }
                    },
                    recordEditPageId: '',
                    pageOpenType: RecordOpenType.modal,
                    selectedRecordType: 'CURRENT_RECORD'
                }
            }
        }
    ],
    [
        'goBack',
        {
            id,
            type: 'goBack',
            trigger: 'click',
            data: {
                goBack: {}
            }
        }
    ],
    [
        'notification',
        {
            id,
            type: 'notification',
            trigger: 'click',
            data: {
                notification: {
                    notificationContent: {},
                    notificationStatus: 'INFO'
                }
            }
        }
    ],
    [
        'copy',
        {
            id,
            type: 'copy',
            trigger: 'click',
            data: {
                copy: {
                    copyText: {}
                }
            }
        }
    ],
    [
        'shareToWechat',
        {
            id,
            type: 'shareToWechat',
            trigger: 'click',
            data: {
                shareToWechat: {
                    title: '',
                    desc: '',
                    imgUrl: ''
                }
            }
        }
    ],
    [
        'downloadFile',
        {
            id,
            type: 'downloadFile',
            trigger: 'click',
            data: {
                downloadFile: {
                    fileUrl: {
                        type: VariableType.VALUE
                    }
                }
            }
        }
    ],
    [
        'iClick',
        {
            id,
            type: 'iClick',
            trigger: 'click',
            data: {
                iClick: {
                    email: null,
                    name: null,
                    mobile: null,
                    iClickId: ''
                }
            }
        }
    ],
    [
        'wechatPay',
        {
            id,
            type: 'wechatPay',
            trigger: 'click',
            data: {
                wechatPay: {
                    operationType: 'PAY',
                    payConfig: {
                        descFieldId: '',
                        autoRedirectAfterPayment: true,
                        /** 订单金额 */
                        amountFieldId: '',
                        succeedFieldSettings: [],
                        callbackWorkflow: {
                            workflowId: '',
                            workflowArgs: []
                        }
                        // failedFieldSettings: []
                    }
                    // refundConfig: {
                    //     // 订单id对应的字段id
                    //     outTradeNoFieldId: '',
                    //     refundAmountFieldId: '',
                    //     reasonFieldId: '',
                    //     succeedFieldSettings: [],
                    //     // failedFieldSettings: []
                    // }
                }
            }
        }
    ],
    [
        'call',
        {
            id,
            type: 'call',
            trigger: 'click',
            data: {
                call: {
                    phone: {
                        type: 'VALUE'
                    } as VariableADTvalue
                }
            }
        }
    ],
    [
        'wechatTemplateMsg',
        {
            id,
            type: 'wechatTemplateMsg',
            trigger: 'click',
            data: {
                wechatTemplateMsg: {
                    personList: [],
                    templateId: '',
                    values: [],
                    url: {}
                }
            }
        }
    ],
    [
        'stripe',
        {
            id,
            type: 'stripe',
            trigger: 'click',
            data: {
                stripe: {
                    operationType: 'PAY',
                    payConfig: {
                        autoRedirectAfterPayment: true,
                        productPictureFieldId: '',
                        currency: '',
                        descFieldId: '',
                        productNameFieldId: '',
                        amountFieldId: '',
                        succeedFieldSettings: [],
                        callbackWorkflow: {
                            workflowId: '',
                            workflowArgs: []
                        }
                        // failedFieldSettings: []
                    }
                }
            }
        }
    ],
    [
        'refreshPage',
        {
            id,
            type: 'refreshPage',
            trigger: 'click',
            data: {
                refreshPage: {}
            }
        }
    ],
    [
        'aliyunMessage',
        {
            id,
            type: 'aliyunMessage',
            trigger: 'click',
            data: {
                aliyunMessage: {
                    id: '',
                    mobileList: {
                        type: 'VALUE'
                    } as VariableADTvalue,
                    signatureId: '',
                    templateId: '',
                    values: []
                }
            }
        }
    ],
    [
        'closeFloat',
        {
            id,
            type: 'closeFloat',
            trigger: 'click',
            data: {
                closeFloat: {}
            }
        }
    ]])
}
