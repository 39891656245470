import { getAssetUrl } from '@lighthouse/assets'
import { IconFont } from '@lighthouse/bui'
import type { FieldInputType, FieldType } from '@lighthouse/core'
import React from 'react'

const displayListSvg = getAssetUrl('common', 'display_list.svg')
const displayTableSvg = getAssetUrl('common', 'display_table.svg')

export const VIEW_DATASOURCE_OPTIONS = [
    { label: '以表格方式显示', value: 'table', icon: displayTableSvg },
    { label: '以列表方式显示', value: 'list', icon: displayListSvg }
]

export const READONLY_FIELDS: FieldType[] = ['id', 'textGeneration', 'selectGenerationByText', 'aggregation']

export const SHOW_MODE_OPTIONS = [
    { label: '下拉框', value: 'input' },
    { label: '平铺', value: 'tag' },
    { label: '选框', value: 'checkbox' }
]

export const DIRECTION_OPTIONS = [
    { label: '横向', value: 'horizontal' },
    { label: '纵向', value: 'vertical' }
]

export const DATE_RANGE_OPTIONS = [
    { label: '所有时间', value: 'ALL' },
    { label: '今天及以前', value: 'BEFORE_TODAY' },
    { label: '今天及以后', value: 'AFTER_TODAY' },
    { label: '多少天以后', value: 'AFTER_CUSTOM' }
]

export const scannerOptions = [
    { label: '条形码', value: 'barCode' },
    { label: '二维码', value: 'qrCode' }
]

export const imageSourceOptions = [
    { label: '禁止手动输入', value: 'input' },
    { label: '禁用相册', value: 'album' }
]

export const FieldInputDescMap: Record<FieldInputType, string> = {
    text: '只可选「文本类」字段',
    number: '只可选「数值」字段',
    email: '只可选「文本类」字段',
    phoneNumber: '只可选「文本类」字段',
    url: '只可选「文本类」字段',
    // select: '选项',
    date: '只可选「日期」字段',
    file: '只可选「附件」字段',
    notes: '只可选「笔记」字段',
    relativeSelect: '只可选「选项」或「文本类」字段',
    person: ' 只可选「人员」字段',
    checkbox: '只可选「复选框」字段',
    slider: '只可选「数值」字段',
    cascade: '只可选「文本类」字段'
}

export const inputHeightOptions = [
    { icon: <IconFont type="InputHeightS" color="var(--color-gray-400)" />, value: 'small' },
    { icon: <IconFont type="InputHeightM" color="var(--color-gray-400)" />, value: 'middle' },
    { icon: <IconFont type="InputHeightL" color="var(--color-gray-400)" />, value: 'large' }
]

export const DISABLED_SYSTEM_FIELD = new Set<string>([
    'WECHAT_OPENID',
    'WECHAT_ID',
    'createdTime',
    'updatedTime',
    'updatedBy',
    'createdBy',
    'ID'
])

export const relativeSelectFieldList = new Set<FieldType>(['select', 'singleSelect'])
