import { Flex, SegmentedControl, Select } from '@byecode/ui'
import type { PaginationProtocol, ViewBreakPointProtocol } from '@lighthouse/core'
import { ARRANGEMENT, CUSTOM_VIEW_LAYOUT } from '@lighthouse/core'
import { Group4ByecodeUi, ListItem4ByecodeUi } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { GapSizeInput } from '@/components/DesignSetting/AutoLayoutSetting/GapSizeInput'
import { PaddingInput } from '@/components/DesignSetting/AutoLayoutSetting/PaddingInput'
import { useSetExtendsKeys } from '@/components/DesignSetting/hooks/useMouseEventDistance'
import { InheritLabel } from '@/components/InheritLabel'

import { BLOCK_SIZE_OPTIONS, DIRECTION_OPTIONS, LAYOUT_CONTROL } from '../../../constants'

const CUSTOM_VIEW_RECORD_SIZE = BLOCK_SIZE_OPTIONS.filter(item => item.value !== 'xs')

const COLS_OPTIONS = Array.from({ length: 6 }).map((_, i) => ({
    label: `${i + 1} 列`,
    value: (i + 1).toString()
}))

export const View: React.FC = () => {
    const { control, getValues } = useFormContext<ViewBreakPointProtocol & PaginationProtocol>()
    const { handleSetBreakKey } = useSetExtendsKeys()
    const [layout, arrangement] = getValues(['breakPoint.viewLayout.layout', 'breakPoint.viewLayout.arrangement'])
    return (
        <Group4ByecodeUi label="视图布局">
            {layout === CUSTOM_VIEW_LAYOUT.grid && (
                <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                    <InheritLabel label="方向" name="breakPoint.viewLayout.direction" />
                    <Controller
                        control={control}
                        name="breakPoint.viewLayout.direction"
                        render={({ field }) => (
                            <SegmentedControl
                                style={{ width: 180 }}
                                fullWidth
                                data={DIRECTION_OPTIONS}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey('breakPoint.viewLayout.direction')
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <InheritLabel label="排列方式" name="breakPoint.viewLayout.arrangement" />
                <Controller
                    control={control}
                    name="breakPoint.viewLayout.arrangement"
                    render={({ field }) => (
                        <SegmentedControl
                            style={{ width: 180 }}
                            fullWidth
                            data={LAYOUT_CONTROL}
                            value={field.value}
                            onChange={val => {
                                field.onChange(val)
                                handleSetBreakKey('breakPoint.viewLayout.arrangement')
                            }}
                        />
                    )}
                />
            </ListItem4ByecodeUi>

            {arrangement === ARRANGEMENT.fixed ? (
                <ListItem4ByecodeUi key="cols" enablePadding justifyContent="space-between" alignItems="center">
                    <InheritLabel label="每行显示" name="breakPoint.viewLayout.cols" />
                    <Controller
                        control={control}
                        name="breakPoint.viewLayout.cols"
                        shouldUnregister={false}
                        render={({ field: { value, onChange } }) => (
                            <Select
                                value={value?.toString()}
                                style={{ width: 180 }}
                                searchable={false}
                                options={COLS_OPTIONS}
                                onChange={val => {
                                    onChange(Number(val))
                                    handleSetBreakKey('breakPoint.viewLayout.cols')
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            ) : (
                <ListItem4ByecodeUi key="size" enablePadding justifyContent="space-between" alignItems="center">
                    <InheritLabel label="尺寸" name="breakPoint.viewLayout.size" />
                    <Controller
                        control={control}
                        name="breakPoint.viewLayout.size"
                        shouldUnregister={false}
                        render={({ field }) => (
                            <SegmentedControl
                                size="md"
                                fullWidth
                                data={CUSTOM_VIEW_RECORD_SIZE}
                                style={{ width: 180 }}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey('breakPoint.viewLayout.size')
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}

            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="center">
                <InheritLabel label="间距" name="breakPoint.viewLayout.gap" />
                <Controller
                    name="breakPoint.viewLayout.gap"
                    control={control}
                    render={({ field }) => (
                        <GapSizeInput
                            value={field.value}
                            onChange={val => {
                                field.onChange(val)
                                handleSetBreakKey('breakPoint.viewLayout.gap')
                            }}
                            disableHighlight
                        />
                        // <Slider style={{ width: 180 }} min={0} max={12} step={0.125} value={field.value} onChange={field.onChange} />
                    )}
                />
            </ListItem4ByecodeUi>
            <ListItem4ByecodeUi enablePadding justifyContent="space-between" alignItems="flex-start">
                <Flex alignItems="center" style={{ height: 32 }}>
                    <InheritLabel label="边距" name="breakPoint.viewLayout.viewPadding" />
                </Flex>
                <Controller
                    name="breakPoint.viewLayout.viewPadding"
                    control={control}
                    render={({ field }) => (
                        <PaddingInput
                            value={field.value}
                            onChange={val => {
                                field.onChange(val)
                                handleSetBreakKey('breakPoint.viewLayout.viewPadding')
                            }}
                            disableHighlight
                        />
                    )}
                />
            </ListItem4ByecodeUi>
        </Group4ByecodeUi>
    )
}
