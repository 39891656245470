import type { ApplicationAbstract, BlockAbstract, RectSizeUnit } from '@lighthouse/core'
import { type ApplicationPreviewEnum, findNodeDom, PAGE_CONTAINER_HOST } from '@lighthouse/shared'

import type { NodeIdWithScope } from '@/atoms/page/types'
import { getApplicationScale, parseValue } from '@/utils'

export type SizeInputType = 'width' | 'height' | 'minWidth' | 'maxWidth' | 'minHeight' | 'maxHeight' | 'gap'

export const getSizeBaseType = (type: SizeInputType) => {
    const lowerCaseType = type.toLowerCase()
    return lowerCaseType.includes('width') ? 'width' : 'height'
}

export const convertSize = (
    node: NodeIdWithScope,
    type: SizeInputType,
    toUnit: RectSizeUnit,
    previewType: ApplicationPreviewEnum,
    parentBlock?: BlockAbstract
) => {
    const dom = findNodeDom(node)

    const parentDom =
        parentBlock &&
        findNodeDom({
            id: parentBlock.isMasterSynchronous ? node.scope || '' : parentBlock.id,
            scope: parentBlock.isLeafSynchronous ? node.scope : undefined
        })

    const parentNodeRect = parentDom?.getBoundingClientRect()
    const rootNode = document.querySelector<HTMLDivElement>('[data-layout-node="root"]')
    const nodeRect = dom?.getBoundingClientRect()
    const scale = getApplicationScale(previewType)
    const t = getSizeBaseType(type)
    const nodeSize = nodeRect?.[t] ? nodeRect[t] / scale : undefined
    if (toUnit === 'px') {
        return nodeSize ? Number(nodeSize.toFixed(2)) : 0
    }
    if (!rootNode) {
        return 0
    }
    if (toUnit === '%') {
        if (!parentDom) {
            const rootNodeRect = rootNode.getBoundingClientRect()
            const rootNodePaddingHorizontal = parseValue(rootNode.style.paddingLeft) + parseValue(rootNode.style.paddingRight)
            const rootNodePaddingVertical = parseValue(rootNode.style.paddingTop) + parseValue(rootNode.style.paddingBottom)
            const rootNodePadding = t === 'width' ? rootNodePaddingHorizontal : rootNodePaddingVertical
            const size = rootNodeRect?.[t]
            return size && nodeSize ? Number(((nodeSize / (size - rootNodePadding)) * 100).toFixed(2)) : 0
        }
        const parentNodeSize = parentNodeRect?.[t] ? parentNodeRect[t] / scale : undefined
        return nodeSize && parentNodeSize ? Number(((nodeSize / parentNodeSize) * 100).toFixed(2)) : 0
    }
    const pageContainer = document.querySelector<HTMLDivElement>(PAGE_CONTAINER_HOST)
    if (!pageContainer) {
        return 0
    }
    if (toUnit === 'vw') {
        const pageContainerRect = pageContainer.getBoundingClientRect()
        return nodeSize ? Number(((nodeSize / pageContainerRect.width) * 100).toFixed(2)) : 100
    }
    if (toUnit === 'vh') {
        const pageContainerRect = pageContainer.getBoundingClientRect()
        return nodeSize ? Number(((nodeSize / pageContainerRect.height) * 100).toFixed(2)) : 100
    }
}
