import { Divider } from '@byecode/ui'
import type {
    ApplicationAbstract,
    ApplicationSettingNavbar,
    ApplicationSettingNavbarButton,
    ApplicationSettingTheme,
    AppUser,
    ButtonEvents,
    NavigationBaseItem
} from '@lighthouse/core'
import { ButtonPattern, NavigationShowMode } from '@lighthouse/core'
import { Drawer } from '@mantine/core'
import { useElementSize } from '@mantine/hooks'
import { filter, splitWhen } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { useImmer } from 'use-immer'

import { useApplicationContext } from '../../../contexts'
import type { MessageEvents } from '../../../types'
import type { LoginAuthType } from '../../AccountPage'
import { excludePage } from '../ApplicationHeader/constant'
import ApplicationLogo from '../ApplicationLogo'
import { ApplicationNavBarButton } from '../ApplicationNavBarButton'
import { SelectItem } from '../ApplicationTabBar'
import { useAppNavbarStylesContext } from '../AppNavbarStylesContext'
import { defaultConfig, PADDING } from '../constant'
import * as CM from '../styles'
import type { ApplicationContainerPreviewMode } from '../type'
import { UserCenter } from '../UserCenter'
import * as SC from './styles'

interface ApplicationHeaderMobileProps {
    userData?: AppUser
    application?: ApplicationAbstract
    navbar: ApplicationSettingNavbar
    theme?: ApplicationSettingTheme
    style?: React.CSSProperties
    activePage?: string
    selected?: boolean
    disableEvent?: boolean
    previewMode: ApplicationContainerPreviewMode
    messageEvents?: MessageEvents
    notificationBox?: React.ReactNode
    languageBox?: React.ReactNode

    onLogout?: () => void
    onCommonCallBack?: (params: ButtonEvents) => void
    onToLink?: (navigation?: NavigationBaseItem) => void
    onSelect?: (selected: boolean) => void
    onUpdateBindAccount?: (isBind: boolean, type: LoginAuthType) => Promise<boolean>
}

const ApplicationHeaderMobile: React.FC<ApplicationHeaderMobileProps> = ({
    userData,
    application,
    navbar = defaultConfig.navbar,
    selected = false,
    style: headerStyle,
    theme,
    disableEvent,
    messageEvents,
    notificationBox,
    previewMode,
    onUpdateBindAccount,
    languageBox,
    onLogout,
    onCommonCallBack,
    onToLink,
    onSelect
}) => {
    const { pageTarget } = useApplicationContext()
    const { linkList, buttonList, logo, showMode = 0, isShow, backgroundOverlay, backgroundBlur } = navbar

    const leftBtnList = useMemo(
        () => ({ child: linkList.child.filter(linkId => linkList.list[linkId].isAccess), list: linkList.list }),
        [linkList.child, linkList.list]
    )

    const rightBtnList = useMemo(
        () =>
            filter(
                ({ events: { handleEvent, params } }) =>
                    !(handleEvent === 'openPage' && excludePage.includes(params?.[0]) && !!userData?.userId),
                buttonList
            ),
        [buttonList, userData?.userId]
    )

    const [state, setState] = useImmer<{
        splitIndex: number
        opened: boolean
        showName: boolean
        loading: boolean
        rightBtnSizeList: Record<string, { width: number; height: number; id: string }>
    }>({
        opened: false,
        loading: true,
        splitIndex: rightBtnList.length,
        showName: true,
        rightBtnSizeList: {}
    })

    const { ref: navbarRef, width: navWid, height: navHei } = useElementSize<HTMLDivElement>()
    const { ref: leftBtnGroupRef, width: leftWid, height: leftHei } = useElementSize<HTMLDivElement>()

    const [showList, hideList] = useMemo(() => {
        const splitNodeId = rightBtnList[state.splitIndex]?.id
        return splitWhen<ApplicationSettingNavbarButton, ApplicationSettingNavbarButton>(draft => draft.id === splitNodeId, rightBtnList)
    }, [rightBtnList, state.splitIndex])

    const isFold = useMemo(() => hideList.length > 0, [hideList.length])

    const navbarStyles = useAppNavbarStylesContext()

    const style: React.CSSProperties | undefined = useMemo(() => {
        return {
            ...headerStyle,
            backgroundColor: navbarStyles?.backgroundColor,
            color: navbarStyles?.color
        }
    }, [headerStyle, navbarStyles?.backgroundColor, navbarStyles?.color])

    const [leftSize, navSize] = useMemo(() => [leftWid, navWid - PADDING], [leftWid, navWid])

    useEffect(() => {
        if (rightBtnList.length > 0) {
            setState(state => {
                state.showName = true
                state.loading = true
            })
        }
    }, [rightBtnList.length, setState])

    useEffect(() => {
        let currentBtnSize = leftSize
        if (rightBtnList.some(({ id }) => !state.rightBtnSizeList[id]?.width) || navSize <= 0) {
            setState(state => {
                state.splitIndex = rightBtnList.length
                state.showName = true
                state.loading = true
            })
            return
        }
        const rightBtnListCount = rightBtnList.reduce((preVal, { id }) => preVal + state.rightBtnSizeList[id]?.width + 24 + 6, 0)

        // 隐藏名称重新计算
        if (state.showName && rightBtnListCount + 28 + currentBtnSize > navSize) {
            setState(state => {
                state.splitIndex = rightBtnList.length
                state.showName = false
            })
        }

        // 当渲染后，当前按钮大小加链接大小依然小于导航栏大小，且导航栏渲染完毕
        for (const [i, element] of rightBtnList.entries()) {
            const nodeId = element.id
            const { width = 0, height = 0, id = '' } = state.rightBtnSizeList[nodeId] ?? {}
            const eleSize = showMode === NavigationShowMode.horizontal ? width + 24 + 6 : height + 12
            // const eleMargin = NavigationShowMode.horizontal ? 12 : 0
            currentBtnSize += eleSize
            // 当前导航栏大小减去一个导航链接大小
            if (navSize < currentBtnSize) {
                const newSplitIndex = i - 1
                setState(state => {
                    state.splitIndex = newSplitIndex < 0 ? 0 : newSplitIndex
                    state.loading = false
                })
                return
            }
        }
        setState(state => {
            // state.showName = true
            state.loading = false
            state.splitIndex = rightBtnList.length
        })
    }, [leftSize, navSize, rightBtnList, setState, showMode, state.rightBtnSizeList, state.showName])

    const handleChangeSize = useCallback(
        (params: { width: number; height: number; id: string }) => {
            const { id } = params
            setState(draft => {
                draft.rightBtnSizeList[id] = params
                // draft.showName = true
            })
        },
        [setState]
    )

    const handleCommonCallBack = useCallback(
        (events: ButtonEvents) => {
            setState(state => {
                state.opened = false
            })
            onCommonCallBack?.(events)
        },
        [onCommonCallBack, setState]
    )

    const renderButtonList = useMemo(
        () => (
            <>
                {!state.loading &&
                    showList.map(item => {
                        const {
                            id,
                            events: { handleEvent, params },
                            events,
                            name,
                            icon,
                            showType,
                            pattern = ButtonPattern.primary
                        } = item
                        const isHideBtn = handleEvent === 'openPage' && excludePage.includes(params?.[0]) && !!userData?.userId
                        return (
                            !isHideBtn && (
                                <ApplicationNavBarButton
                                    key={id}
                                    name={state.showName ? name : ''}
                                    icon={icon}
                                    type={state.showName ? pattern : 'onlyIcon'}
                                    onlyShowIcon={!state.showName}
                                    showType={showType}
                                    theme={theme}
                                    iconSize={state.showName ? 16 : 20}
                                    onClick={() => handleCommonCallBack(events)}
                                />
                            )
                        )
                    })}
            </>
        ),
        [handleCommonCallBack, showList, state.loading, state.showName, theme, userData?.userId]
    )

    const showButtonList = useMemo(
        () =>
            rightBtnList.filter(item => {
                const {
                    events: { handleEvent, params }
                } = item
                return !(handleEvent === 'openPage' && excludePage.includes(params?.[0]) && !!userData?.userId)
            }),
        [rightBtnList, userData?.userId]
    )

    const renderDrawerButtonList = useMemo(
        () => (
            <>
                {showButtonList.map(item => {
                    const { id, events, name, icon, showType, pattern = ButtonPattern.primary } = item
                    return (
                        <ApplicationNavBarButton
                            key={id}
                            name={name}
                            icon={icon}
                            size="large"
                            showType={showType}
                            type={pattern}
                            onClick={() => handleCommonCallBack(events)}
                        />
                    )
                })}
            </>
        ),
        [handleCommonCallBack, showButtonList]
    )

    const renderEle = useMemo(
        () => (
            <>
                {rightBtnList.map(item => {
                    const {
                        id,
                        events: { handleEvent, params },
                        events,
                        name,
                        icon,
                        showType,
                        pattern = ButtonPattern.primary
                    } = item
                    const isHideBtn = handleEvent === 'openPage' && excludePage.includes(params?.[0]) && !!userData?.userId
                    return (
                        !isHideBtn && (
                            <ApplicationNavBarButton
                                key={id}
                                name={state.showName ? name : ''}
                                icon={icon}
                                showType={showType}
                                onlyShowIcon={!state.showName}
                                type={state.showName ? pattern : 'onlyIcon'}
                                iconSize={state.showName ? 16 : 20}
                                onChangeSize={size => handleChangeSize({ ...size, id })}
                            />
                        )
                    )
                })}
            </>
        ),
        [handleChangeSize, rightBtnList, state.showName, userData?.userId]
    )

    const toolEle = useMemo(() => {
        if (!userData?.userId) {
            return languageBox
        }
        return (
            <>
                <UserCenter
                    isMobile
                    onLogout={onLogout}
                    avatarProps={{ size: 28 }}
                    userData={userData}
                    onUpdateBindAccount={onUpdateBindAccount}
                />
                {languageBox}
                {notificationBox}
            </>
        )
    }, [languageBox, notificationBox, onLogout, userData, onUpdateBindAccount])

    return (
        <>
            {/* {previewMode === 'mobile' && <CM.PlaceholderDom style={{ height: 66 }} />} */}
            <SC.Contain style={style} disableEvent={disableEvent} onClick={() => onSelect?.(true)} selected={selected} ref={navbarRef}>
                <SC.LeftFill ref={leftBtnGroupRef}>
                    <ApplicationLogo
                        appIcon={application?.icon}
                        appName={application?.name}
                        showMode={NavigationShowMode.horizontal}
                        logo={logo}
                        onToLink={onToLink}
                    />
                </SC.LeftFill>
                <SC.RightFill>
                    {isShow && renderButtonList}
                    {/* 隐藏显示导航栏右侧导航栏按钮 */}
                    {isShow && <div style={{ position: 'absolute', visibility: 'hidden', display: 'flex' }}>{renderEle}</div>}
                    {toolEle}
                    {((isFold && hideList.length > 0) || !isShow) && (
                        <CM.Icon
                            size={22}
                            onClick={() =>
                                setState(state => {
                                    state.opened = !state.opened
                                })
                            }
                            color={navbarStyles?.color}
                            type={state.opened ? 'Close' : 'PickOn'}
                        />
                    )}
                </SC.RightFill>
                <Drawer
                    opened={state.opened}
                    styles={{
                        root: {
                            position: 'absolute',
                            top: 58
                        },
                        drawer: {
                            position: 'absolute',
                            boxShadow: 'none'
                        },
                        body: {
                            height: '100%'
                        }
                    }}
                    target={pageTarget}
                    withCloseButton={false}
                    position="right"
                    size="85%"
                    zIndex={200}
                    onClose={() =>
                        setState(state => {
                            state.opened = false
                        })
                    }
                >
                    <SC.DrawerContain>
                        {(leftBtnList.child ?? [])?.map(nodeId => (
                            <SelectItem
                                key={nodeId}
                                id={nodeId}
                                data={leftBtnList.list}
                                level={0}
                                onCommonCallBack={handleCommonCallBack}
                            />
                        ))}
                        {showButtonList.length > 0 && leftBtnList.child.length > 0 && (
                            <Divider
                                style={{
                                    margin: '8px 16px'
                                }}
                            />
                        )}
                        <SC.ButtonList>{renderDrawerButtonList}</SC.ButtonList>
                        {userData?.userId && (
                            <>
                                <Divider
                                    style={{
                                        margin: '8px 16px'
                                    }}
                                />
                                <div
                                    style={{
                                        margin: '0 16px'
                                    }}
                                >
                                    <CM.Text
                                        onClick={() => {
                                            setState(draft => {
                                                draft.opened = false
                                            })
                                            onLogout?.()
                                        }}
                                    >
                                        退出登录
                                    </CM.Text>
                                </div>
                            </>
                        )}
                    </SC.DrawerContain>
                </Drawer>
            </SC.Contain>
        </>
    )
}

export default ApplicationHeaderMobile
