import { Empty, Loading, Modal, Toast } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { AnimationDecorators, useContainerBlockContext } from '@lighthouse/block'
import type {
    ButtonAction,
    FieldADTValue,
    FilterCommonCondition,
    GroupConfigure,
    GroupRecordCount,
    GroupTab,
    RecordLikeProtocol,
    Sorter,
    TableColumns,
    TableColumnWidth,
    VariableFieldADTValue,
    ViewBlockAbstract
} from '@lighthouse/core'
import { RecordOpenType, SelectedMode, VariableType } from '@lighthouse/core'
import type { FlowLayoutNode, ViewAppendParams, ViewPrintWithTemplateParams } from '@lighthouse/shared'
import {
    ALL_GROUP,
    CenteredWrapper,
    EMPTY_COLUMN_GROUP,
    getGroupAllVisibleOptions,
    getGroupVisibleWithLabelOptions,
    getMainTableRecordId,
    getPaddingStyle,
    getQuickFilterRule,
    getRefetchViewPageSize,
    getWithScopeId,
    mergeGroupVisibleOptions,
    pageStackPubSub,
    scroll2EndLeftOrRight,
    scroll2ViewItem,
    useApplicationContext,
    useAtomAction,
    useAtomAsyncAction,
    useAtomData,
    useHandleAbortPrevious,
    useLanguageContext,
    useRegisterBlockListener,
    ViewBlockTool
} from '@lighthouse/shared'
import { nanoid, useBreakpoint } from '@lighthouse/tools'
import { useAtomValue } from 'jotai'
import { isEmpty } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { useImmer } from 'use-immer'

import { fetchViewRecordsAtom, loadViewRecordsAtom, updateViewSearchAtom } from '@/atoms/blockRecordsDict/action'
import { addAiFieldStatusAtom, deleteRecordAtom, deleteViewRecordAtom, updateCellAtom, updateRecordAtom } from '@/atoms/dataSource/action'
import { aiFieldStatusListAtom } from '@/atoms/dataSource/state'
import { openPageStackAtom } from '@/atoms/page/action'
import { pageStackAtom, pageStackAtomFamily } from '@/atoms/page/state'
import { stackFactory } from '@/atoms/page/utils'
import {
    groupCacheAtomFamily,
    kanbanColumnsSortCacheAtomFamily,
    quickFilterCacheAtomFamily,
    sortsCacheAtomFamily,
    tableColumnCacheAtomFamily,
    tablePropsCacheAtomFamily
} from '@/atoms/storage/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { useCurrentPageContext, useCurrentStackIdContext, useRootPageContext } from '@/contexts/PageContext'
import { useActionTrigger } from '@/hooks/useActionTrigger'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { useViewIndependentData } from '@/hooks/useBlock'
import { useDataSource, useDataSourceList } from '@/hooks/useDataSource'
import { useRichTextToTitle } from '@/hooks/useRichTextToTitle'
import * as srv from '@/services'

import { useViewBlockConfig } from './hook'
import type { FetchViewDataPayload, GetGroupRecordCountPayload } from './types'
import { ViewBlockRender } from './ViewBlockRender'

const EmptyImage = styled.img`
    width: 140px;
    height: 144px;
`

const SCxViewContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1px;
    // margin: var(--block-padding);
`

interface ViewBlockControllerProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: ViewBlockAbstract
    node: FlowLayoutNode
}

const ViewBlockController: React.FC<ViewBlockControllerProps> = ({ blockData, node, children, ...rest }) => {
    const firstRef = useRef(true)
    const viewIdRef = useRef('')

    const envId = useCurrentEnvId()
    const { id, title, config, } = blockData
    const {
        pointer,
        viewType,
        creatingConfig,
        viewingConfig,
        editingConfig,
        quickFilter,
        groupConfigure,
        canGroup,
        canSort,
        canSearch,
        triggerWorkflow,
        importMode,
        repeat,
        compareFields,
        sorts,
        pagination,
        canPaginate,
        breakPoint: baseBreakpoint
    } = config
    const { groupByFieldId = '', groupConfig, canHiddenTabValue } = groupConfigure || {}
    const noPagination = viewType === 'kanban' || viewType === 'calendar' || !canPaginate
    const { rootPageId } = useRootPageContext()
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const { scope } = useContainerBlockContext()

    const withScopeId = getWithScopeId(id, scope)

    const selectState = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(
            s => {
                if (!s) {
                    return
                }
                const isSelf = id === s.blockRuntimeState.view?.viewId && s.blockRuntimeState.view?.scope === scope
                if (!isSelf) {
                    return
                }
                return s.blockRuntimeState.view
                // return s.state.blockRuntimeState.tabs?.[blockData.id].currentTab
            },
            [id, scope]
        )
    )
    const { selectedIds, selectedMode } = useMemo(() => {
        if (!selectState) {
            return { selectedIds: [] as string[], selectedMode: undefined }
        }
        return selectState
    }, [selectState])

    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const previewType = usePreviewType()
    // const [selectedRecords, setSelectedRecords] = useState<string[]>([])
    // const [selectedMode, setSelectedMode] = useState<SelectedMode | undefined>(undefined)

    const recordPageId = viewingConfig?.page ?? ''
    const formPageId = creatingConfig?.page ?? ''
    const recordEditPageId = editingConfig?.page ?? ''
    const { handleActionTrigger } = useActionTrigger()
    const appId = useCurrentAppID()

    const { personOptions } = useApplicationContext()
    const dataSourceList = useDataSourceList(appId, envId)
    const dataSource = useDataSource(appId, envId, pointer)
    const viewIndependentData = useViewIndependentData(withScopeId)
    const { records, currentPage = 1, rowTotal, search = '' } = viewIndependentData || {}

    const viewIndependentDataOmitRecord = useMemo(
        () => ({
            currentPage,
            rowTotal,
            search
        }),
        [currentPage, rowTotal, search]
    )

    const searchVal = useMemo(() => (canSearch ? search || '' : ''), [canSearch, search])

    const { convertTextByLanguage } = useLanguageContext()

    const quickFiltersCache = useAtomValue(quickFilterCacheAtomFamily({ appId, envId, id: withScopeId }))

    const tablePropsCache = useAtomValue(tablePropsCacheAtomFamily({ appId, envId, id: withScopeId }))

    const sortCache = useAtomValue(sortsCacheAtomFamily({ appId, envId, id: withScopeId }))

    const groupCache = useAtomValue(groupCacheAtomFamily({ appId, envId, id: withScopeId }))

    const tableColumnCache = useAtomValue(tableColumnCacheAtomFamily({ appId, envId, id: withScopeId }))

    const kanbanColumnsSortCache = useAtomValue(kanbanColumnsSortCacheAtomFamily({ appId, envId, id: withScopeId }))

    const { handleRenderTitle } = useRichTextToTitle()
    const { loading, run: fetchViewRecords } = useAtomAsyncAction(fetchViewRecordsAtom)
    const { run: updateViewSearch } = useAtomAction(updateViewSearchAtom)
    const { run: deleteRecord } = useAtomAction(deleteRecordAtom)
    const { run: deleteViewRecord } = useAtomAction(deleteViewRecordAtom)
    const { run: updateCell } = useAtomAction(updateCellAtom)
    const { run: updateRecord } = useAtomAsyncAction(updateRecordAtom)
    const { run: addAiFieldStatus } = useAtomAction(addAiFieldStatusAtom)

    const { run: setQuickFilterCache } = useAtomAction(quickFilterCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setSortsCache } = useAtomAction(sortsCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setTablePropsCache } = useAtomAction(tablePropsCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setGroupCache } = useAtomAction(groupCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setTableColumnCache } = useAtomAction(tableColumnCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { run: setKanbanColumnsSortCache } = useAtomAction(kanbanColumnsSortCacheAtomFamily({ appId, envId, id: withScopeId }))
    const { mutation } = useHandleAbortPrevious(fetchViewRecords)
    const { mutation: getGroupRecordCountMutation } = useHandleAbortPrevious(srv.getGroupRecordCount)
    const { ref, width: blockWidth, breakPoint } = useBreakpoint([loading])

    const groupByField = useMemo(() => {
        if (!dataSource) {
            return
        }
        const { schema } = dataSource
        const field = schema[groupByFieldId]
        if (!field) {
            return
        }
        return field
    }, [dataSource, groupByFieldId])

    const initGroupConfigData = useMemo(() => {
        if (groupConfig) {
            if (!groupByField) {
                return []
            }
            return mergeGroupVisibleOptions(
                getGroupVisibleWithLabelOptions(groupByField, groupConfig, {
                    personOptions
                }),
                getGroupAllVisibleOptions(groupByField, { personOptions }, true)
            )
        }
        return []
    }, [groupByField, groupConfig, personOptions])

    const initGroupData = useMemo(
        () => ({
            groupByFieldId: groupByField?.id || '',
            canHiddenTabValue,
            groupConfig: initGroupConfigData
        }),
        [canHiddenTabValue, groupByField, initGroupConfigData]
    )

    const initGroup: GroupConfigure = useMemo(() => {
        if (canGroup) {
            return groupCache || initGroupData
        }
        return initGroupData
    }, [canGroup, groupCache, initGroupData])

    const initTabList = useMemo(() => {
        if (!initGroup?.groupConfig) {
            return []
        }
        return initGroup.groupConfig
            ?.filter(v => v.visible)
            .map(item => ({
                value: item.value,
                label: item.label,
                count: undefined
            }))
    }, [initGroup.groupConfig])

    const [tabValue, setTabValue] = useState('')
    const [tabCountList, setTabCountList] = useImmer<GroupRecordCount[]>([])
    const [tabList, setTabList] = useState<GroupTab[]>([])
    const quickFiltersRule = useMemo(() => {
        if (!dataSource) {
            return []
        }
        return getQuickFilterRule({ quickFiltersCache, quickFilter, dataSource, personOptions })
    }, [dataSource, personOptions, quickFilter, quickFiltersCache])

    const pageSize = useMemo(() => getRefetchViewPageSize(viewType, pagination.pageSize), [pagination.pageSize, viewType])

    const getFilterByTab = useCallback<(tab: string) => FilterCommonCondition[]>(
        (tab: string) => {
            if (!tab || tab === ALL_GROUP || !initGroup) {
                return []
            }
            if (tab === EMPTY_COLUMN_GROUP) {
                return [
                    {
                        idVariable: { type: VariableType.FIELD_ID, fieldIdVariable: { fieldId: initGroup.groupByFieldId } },
                        operator: 'isEmpty',
                        paramList: [{ type: VariableType.VALUE }]
                    }
                ]
            }
            const field = dataSource?.schema?.[initGroup.groupByFieldId]
            if (!field) {
                return []
            }
            return [
                {
                    idVariable: { type: VariableType.FIELD_ID, fieldIdVariable: { fieldId: initGroup.groupByFieldId } },
                    operator: 'contains',
                    paramList: [{ type: VariableType.VALUE, valueVariable: { type: field.type, value: [tab] } as VariableFieldADTValue }]
                }
            ]
        },
        [dataSource?.schema, initGroup]
    )
    // const pageNum = useMemo(() => getRefetchViewCurrentPage(viewType, pagination?.currentPage || 1), [pagination.currentPage, viewType])
    const fetchViewData = useCallback(
        async (params: FetchViewDataPayload) => {
            const { quickFilters, linkFilter, filter, sorts, tab, search, pageNum } = params
            const quickFiltersWidthTab: FilterCommonCondition[] = [...quickFilters, ...getFilterByTab(tab)]
            if (pointer) {
                await mutation({
                    pageId,
                    viewId: id,
                    scope,
                    isSyncComponent: !!scope,
                    pagination: { currentPage: pageNum, pageSize },
                    params: { quickFilters: quickFiltersWidthTab, sorts, linkFilter, filter },
                    search,
                    disableFetchCount: noPagination
                })
            }
        },
        [getFilterByTab, pointer, mutation, pageId, id, scope, pageSize, noPagination]
    )

    const getGroupRecordCount = useCallback(
        async (params: GetGroupRecordCountPayload) => {
            const { groupConfigure, quickFilters, search, linkFilter, filter } = params
            if (initGroup?.groupByFieldId) {
                const data = await getGroupRecordCountMutation({
                    appId,
                    viewId: id,
                    isSyncComponent: !!scope,
                    fieldId: groupConfigure?.groupByFieldId || '',
                    quickFilters,
                    linkFilter,
                    filter,
                    search
                })
                if (data) {
                    setTabCountList(data)
                }
                return
            }

            if (!isEmpty(tabCountList)) {
                setTabCountList([])
            }
            // await fetchViewData(quickFilters, sortCache, tabValue, search, pageNum)
        },
        [initGroup?.groupByFieldId, tabCountList, getGroupRecordCountMutation, appId, id, scope, setTabCountList]
    )

    const handleAction = useCallback(
        async (action: ButtonAction, record?: RecordLikeProtocol) => {
            // eslint-disable-next-line no-return-await
            return await handleActionTrigger(action, { viewRecord: record })
        },
        [handleActionTrigger]
    )

    const { extraLoading, resolvedLinkFilter, resolvedFilter, reloadData } = useViewBlockConfig({
        appId,
        envId,
        blockData,
        groupConfigure: initGroup,
        quickFiltersRule,
        searchVal,
        sortCache,
        tabValue,
        initTabList,
        tabCountList,
        tabList,
        personOptions,
        pageId,
        pageSize,
        dataSourceList,
        pageNum: viewIndependentDataOmitRecord.currentPage,
        setQuickFilterCache,
        setTabList,
        setTabValue,
        getGroupRecordCount,
        fetchViewData
    })

    const quickFilterData = useMemo(() => {
        return {
            mode: quickFilter?.mode || 'normal',
            rules: quickFiltersRule
        }
    }, [quickFilter?.mode, quickFiltersRule])

    const handleSearch = useCallback(
        (val: string) => {
            updateViewSearch({ scope, viewId: id, search: val })
            reloadData({
                filter: resolvedFilter,
                groupConfigure: initGroup,
                quickFilters: quickFiltersRule,
                search: val,
                pageNum: 1
            })
        },
        [id, initGroup, quickFiltersRule, reloadData, resolvedFilter, scope, updateViewSearch]
    )

    const handleTabChange = useCallback(
        (val: string) => {
            setTabValue(val)
            fetchViewData({
                filter: resolvedFilter,
                quickFilters: quickFiltersRule,
                linkFilter: resolvedLinkFilter,
                sorts: sortCache,
                tab: val,
                search: searchVal,
                pageNum: 1
            })
        },
        [fetchViewData, quickFiltersRule, resolvedFilter, resolvedLinkFilter, searchVal, sortCache]
    )

    const handleChangePageNum = useCallback(
        (pageNum: number) => {
            fetchViewData({
                filter: resolvedFilter,
                quickFilters: quickFiltersRule,
                linkFilter: resolvedLinkFilter,
                sorts: sortCache,
                tab: tabValue,
                search: searchVal,
                pageNum
            })
        },
        [fetchViewData, quickFiltersRule, resolvedFilter, resolvedLinkFilter, searchVal, sortCache, tabValue]
    )

    const { run: loadMoreRecords } = useAtomAsyncAction(loadViewRecordsAtom)
    const handleLoadMoreRecords = useCallback(
        (pageNum: number) => {
            const quickFiltersWidthTab: FilterCommonCondition[] = [...quickFiltersRule, ...getFilterByTab(tabValue)]
            return loadMoreRecords({
                pageId,
                scope,
                viewId: id,
                isSyncComponent: !!scope,
                pagination: { currentPage: pageNum, pageSize },
                params: { filter: resolvedFilter, quickFilters: quickFiltersWidthTab, linkFilter: resolvedLinkFilter, sorts },
                search
            })
        },
        [
            getFilterByTab,
            id,
            loadMoreRecords,
            pageId,
            pageSize,
            quickFiltersRule,
            resolvedFilter,
            resolvedLinkFilter,
            scope,
            search,
            sorts,
            tabValue
        ]
    )

    const handleRecordDelete = useCallback(
        async (dsId: string, ids: string[], selectedMode?: SelectedMode) => {
            const isConfirm = await Modal.confirm({
                title: convertTextByLanguage('sureDelete'),
                content: convertTextByLanguage('deleteRecordWaring', {
                    count: selectedMode === 'ALL' ? viewIndependentDataOmitRecord.rowTotal : ids.length
                }),
                cancelText: convertTextByLanguage('cancel'),
                okText: convertTextByLanguage('delete'),
                okStatus: 'error'
            })?.then(res => res)
            if (isConfirm) {
                const isDelete =
                    // eslint-disable-next-line no-negated-condition
                    selectedMode !== 'ALL'
                        ? await deleteRecord({ envId, dsId, recordIds: ids, mode: selectedMode })
                        : await deleteViewRecord({
                              envId,
                              viewId: id,
                              isSyncComponent: !!scope,
                              dsId,
                              recordIds: ids,
                              mode: selectedMode,
                              search: searchVal,
                              linkFilter: resolvedLinkFilter,
                              quickFilters: quickFilterData?.rules ?? []
                          })
                if (isDelete) {
                    reloadData({
                        filter: resolvedFilter,
                        groupConfigure: initGroup,
                        quickFilters: quickFiltersRule,
                        search: searchVal,
                        pageNum: 1
                    })
                }
                return isDelete
            }
            return false
        },
        [
            convertTextByLanguage,
            deleteRecord,
            deleteViewRecord,
            envId,
            id,
            initGroup,
            viewIndependentDataOmitRecord.rowTotal,
            quickFilterData?.rules,
            quickFiltersRule,
            reloadData,
            resolvedFilter,
            resolvedLinkFilter,
            scope,
            searchVal
        ]
    )

    const handleViewRefresh = useCallback(() => {
        if (
            (viewType === 'custom' && baseBreakpoint.viewLayout?.direction === 'horizontal') ||
            (viewType === 'gallery' && baseBreakpoint.viewLayout?.direction === 'horizontal')
        ) {
            fetchViewData({
                filter: resolvedFilter,
                quickFilters: quickFiltersRule,
                linkFilter: resolvedLinkFilter,
                sorts: sortCache,
                tab: tabValue,
                search: searchVal,
                pageNum: 1
            })
            return
        }
        fetchViewData({
            filter: resolvedFilter,
            quickFilters: quickFiltersRule,
            linkFilter: resolvedLinkFilter,
            sorts: sortCache,
            tab: tabValue,
            search: searchVal,
            pageNum: 1
        })
    }, [
        baseBreakpoint.viewLayout?.direction,
        fetchViewData,
        quickFiltersRule,
        resolvedFilter,
        resolvedLinkFilter,
        searchVal,
        sortCache,
        tabValue,
        viewType
    ])

    useRegisterBlockListener(withScopeId, 'view', {
        refresh: handleViewRefresh,
        horizontalScrollTo: payload => {
            const to = payload?.horizontalScrollTo
            const horizontalScrollDirection = payload?.horizontalScrollDirection
            const viewContainer = ref.current
            const items = viewContainer?.querySelectorAll('[data-view-item]')
            const viewItemsWrapper = items?.[0]?.parentElement
            if (!to || !items || !viewItemsWrapper) {
                return
            }
            const viewContentContainer = viewItemsWrapper?.parentElement
            const currentScrollLeft = viewItemsWrapper?.parentElement?.scrollLeft ?? 0
            const itemOffsetWidth = items[1].getBoundingClientRect().left - items[0].getBoundingClientRect().left
            const currentIndex = Math.round(currentScrollLeft / itemOffsetWidth)

            let newIndex

            if (!Number.isNaN(Number(to))) {
                newIndex = horizontalScrollDirection === 'left' ? currentIndex - Number(to) : currentIndex + Number(to)
            }

            if (to === 'end') {
                viewContentContainer &&
                    (horizontalScrollDirection === 'left'
                        ? scroll2EndLeftOrRight(viewContentContainer, { left: 0, behavior: 'smooth' })
                        : scroll2EndLeftOrRight(viewContentContainer, { left: viewItemsWrapper.clientWidth, behavior: 'smooth' }))
            }

            if (newIndex === undefined) {
                return
            }
            scroll2ViewItem({ id, scope }, newIndex, { block: 'nearest', inline: 'nearest' })
        }
    })

    const { run: openPageStack } = useAtomAction(openPageStackAtom)
    // 嵌套页面交互的点击事件
    const handleRecordClick = useCallback(
        (recordId: string) => {
            if (!recordPageId) {
                return
            }
            // 解决连接表问题
            const rId = getMainTableRecordId(recordId)

            openPageStack(
                stackFactory({
                    pageId: recordPageId,
                    rootPageId,
                    stackDisplayType: viewingConfig?.openType || RecordOpenType.page,
                    appId,
                    dsId: pointer,
                    viewId: id,
                    recordId: rId
                })
            )
        },
        [appId, id, pointer, openPageStack, recordPageId, rootPageId, viewingConfig?.openType]
    )

    const handleRecordEditClick = useCallback(
        (recordId: string) => {
            if (!recordEditPageId) {
                return
            }
            // 解决连接表问题
            const rId = getMainTableRecordId(recordId)
            openPageStack(
                stackFactory({
                    pageId: recordEditPageId,
                    rootPageId,
                    stackDisplayType: editingConfig?.openType || RecordOpenType.page,
                    appId,
                    dsId: pointer,
                    viewId: id,
                    recordId: rId
                })
            )
        },
        [appId, editingConfig?.openType, id, pointer, openPageStack, recordEditPageId, rootPageId]
    )

    const handleRecordAdd = useCallback(
        (initialRecordValue?: Record<string, string | number>) => {
            if (!formPageId) {
                return
            }
            openPageStack(
                stackFactory({
                    pageId: formPageId,
                    rootPageId,
                    stackDisplayType: creatingConfig?.openType || RecordOpenType.page,
                    appId,
                    dsId: pointer,
                    viewId: id,
                    initialRecordValue
                })
            )
        },
        [appId, creatingConfig?.openType, formPageId, id, pointer, openPageStack, rootPageId]
    )

    useEffect(() => {
        if (!pointer) {
            return
        }
        const { subscribeId, unSubscribe } = pageStackPubSub.subscribe(`${pointer}-ADD`, (record?: RecordLikeProtocol) => {
            handleViewRefresh()
        })
        return () => unSubscribe(subscribeId)
    }, [handleViewRefresh, pointer])

    useEffect(() => {
        if (!pointer) {
            return
        }
        const { subscribeId, unSubscribe } = pageStackPubSub.subscribe(`${pointer}-UPDATE`, () => {
            reloadData({
                filter: resolvedFilter,
                groupConfigure: initGroup,
                quickFilters: quickFiltersRule,
                search: searchVal,
                pageNum: 1
            })
        })
        return () => unSubscribe(subscribeId)
    }, [reloadData, pointer, quickFiltersRule, searchVal, initGroup, resolvedFilter])

    const handleValueChange = useCallback(
        (recordId: string, fieldValue: FieldADTValue) => {
            return updateCell({ envId, dsId: pointer, recordId, value: fieldValue, fieldId: fieldValue.id })
        },
        [envId, pointer, updateCell]
    )

    const handleCellUpdate = useCallback(
        (recordId: string, fieldValue: FieldADTValue) =>
            srv.updateCell({
                envId,
                dsId: pointer,
                id: recordId,
                fieldId: fieldValue.id,
                content: fieldValue
            }),
        [envId, pointer]
    )

    const handleRecordUpdate = useCallback(
        (recordId: string, content: RecordLikeProtocol['content']) => {
            return updateRecord({ envId, dsId: pointer, id: recordId, content })
        },
        [envId, pointer, updateRecord]
    )

    const handleQuickFilter = useCallback(
        (data: FilterCommonCondition[]) => {
            setQuickFilterCache(data)
            reloadData({
                groupConfigure: initGroup,
                filter: resolvedFilter,
                quickFilters: data,
                search: searchVal,
                pageNum: 1
            })
        },
        [initGroup, reloadData, resolvedFilter, searchVal, setQuickFilterCache]
    )

    const handleSorterChange = useCallback(
        (data?: Sorter[]) => {
            if (!data) {
                return
            }
            setSortsCache(data)
            fetchViewData({
                filter: resolvedFilter,
                quickFilters: quickFiltersRule,
                sorts: data || [],
                tab: tabValue,
                search: searchVal,
                pageNum: 1
            })
        },
        [fetchViewData, quickFiltersRule, resolvedFilter, searchVal, setSortsCache, tabValue]
    )

    const handleDisplayChange = useCallback(
        (data?: TableColumns) => {
            if (!data) {
                return
            }
            setTablePropsCache(data)
        },
        [setTablePropsCache]
    )

    const handleGroupChange = useCallback(
        (data: GroupConfigure) => {
            setGroupCache(data)
        },
        [setGroupCache]
    )

    const handleAppend = useCallback(
        async (params: ViewAppendParams) => {
            const isAppend = await srv.appendDataToDataSource({
                ...params,
                sheetDto: {
                    ...params.sheetDto,
                    envId,
                    triggerWorkflow,
                    repeat,
                    compareFields,
                    importMode
                }
            })
            isAppend && Toast.success(convertTextByLanguage('importSuccess'))
            // await fetchViewData({
            //     quickFilters: quickFiltersRule,
            //     linkFilter: resolvedLinkFilter,
            //     sorts: sortCache,
            //     tab: tabValue,
            //     search: searchVal
            // })
            reloadData({
                filter: resolvedFilter,
                groupConfigure: initGroup,
                quickFilters: quickFiltersRule,
                search: searchVal,
                pageNum: currentPage ?? 1
            })
            return isAppend
        },
        [
            envId,
            triggerWorkflow,
            repeat,
            compareFields,
            importMode,
            convertTextByLanguage,
            reloadData,
            resolvedFilter,
            initGroup,
            quickFiltersRule,
            searchVal,
            currentPage
        ]
    )

    const handleAiGeneration = useCallback(
        async (recordId: string, fieldId: string) => {
            const id = nanoid()
            const isSuccess = await srv.aiGenerate({ dsId: pointer, recordId, fieldId })
            if (isSuccess) {
                addAiFieldStatus({
                    id,
                    dataSourceId: pointer,
                    recordId,
                    fieldId,
                    state: 'STARTED'
                })
            }
            return isSuccess
        },
        [addAiFieldStatus, pointer]
    )

    const handleSelectModeChange = useCallback(
        (val?: SelectedMode) => {
            const selectedIds = val ? records?.map(item => item.id) || [] : []
            setPageStack(draft => {
                const pageStack = equalPageStack({ rootPageId, stackId })(draft)
                if (!pageStack) {
                    return
                }

                pageStack.blockRuntimeState.view = {
                    viewId: id,
                    scope,
                    selectedMode: val,
                    selectedIds
                }
            })
        },
        [id, records, rootPageId, scope, setPageStack, stackId]
    )

    const handleTableColumnWidthChange = useCallback(
        (val: TableColumnWidth) => {
            const column = {
                ...tableColumnCache,
                ...val
            }
            setTableColumnCache(column)
        },
        [setTableColumnCache, tableColumnCache]
    )

    const handleSelectedRecords = useCallback(
        (ids: string[]) => {
            setPageStack(draft => {
                const pageStack = equalPageStack({ rootPageId, stackId })(draft)
                if (!pageStack) {
                    return
                }

                pageStack.blockRuntimeState.view = {
                    viewId: id,
                    scope,
                    selectedMode: SelectedMode.CURRENT_PAGE,
                    selectedIds: ids
                }
            })
        },
        [rootPageId, scope, setPageStack, stackId, id]
    )

    // const handlePrintByTemplate = useCallback(
    //     async (params: ViewPrintWithTemplateParams) => {
    //         Toast.warning('正在导出, 请勿重复点击')
    //         const res = await srv.printByTemplate({ ...params, linkFilter: resolvedLinkFilter })
    //         const { data, headers } = res
    //         const fileName = headers?.['content-disposition']?.replace?.("attachment;filename*=utf-8''", '') ?? '导出文件.pdf'
    //         const dom = document.createElement('a')
    //         const url = window.URL.createObjectURL(data)
    //         dom.href = url
    //         dom.download = decodeURI(fileName)
    //         dom.style.display = 'none'
    //         document.body.append(dom)
    //         dom.click()
    //         dom.remove()
    //         window.URL.revokeObjectURL(url)
    //     },
    //     [resolvedLinkFilter]
    // )

    // const handleExport = useCallback(
    //     async (params: ViewExportParams) => {
    //         Toast.warning('正在导出, 请勿重复点击')
    //         const recordIds = [...new Set(params.recordIds?.map(id => getMainTableRecordId(id)))]
    //         const res = await srv.exportView({
    //             ...params,
    //             recordIds,
    //             linkFilter: resolvedLinkFilter
    //         })
    //         const { data, headers } = res
    //         const fileName = headers?.['content-disposition']?.replace?.("attachment;filename*=utf-8''", '') ?? `${title}.xlsx`
    //         const dom = document.createElement('a')
    //         const url = window.URL.createObjectURL(data)
    //         dom.href = url
    //         dom.download = decodeURI(fileName)
    //         dom.style.display = 'none'
    //         document.body.append(dom)
    //         dom.click()
    //         dom.remove()
    //         window.URL.revokeObjectURL(url)
    //     },
    //     [resolvedLinkFilter, title]
    // )

    const viewPagination = useMemo(
        () => ({
            currentPage,
            pageSize,
            rowTotal
        }),
        [currentPage, pageSize, rowTotal]
    )

    const { pageTarget } = useApplicationContext()
    return useMemo(() => {
        if (!pointer || !dataSource) {
            const emptyImageUrl = getAssetUrl('empty', 'no_datasource.svg')
            return (
                <CenteredWrapper style={{ width: '100%', height: '100%', overflow: baseBreakpoint.size.overflow }}>
                    <Empty
                        style={{ margin: '32px 0' }}
                        icon={<EmptyImage src={emptyImageUrl} alt="请在「右侧栏>配置」中选择数据来源" />}
                        description="请在「右侧栏>配置」中选择数据来源"
                    />
                </CenteredWrapper>
            )
        }

        if ((loading && firstRef.current) || extraLoading || !records) {
            firstRef.current = false
            viewIdRef.current = id
            return (
                <CenteredWrapper>
                    <Loading />
                </CenteredWrapper>
            )
        }

        if (viewIdRef.current !== id && !(initGroup.groupByFieldId && tabList.length === 0)) {
            return (
                <CenteredWrapper>
                    <Loading />
                </CenteredWrapper>
            )
        }

        viewIdRef.current = id
        firstRef.current = false
        const paddingStyle = viewType === 'custom' ? getPaddingStyle(baseBreakpoint.viewLayout?.viewPadding) : {}
        return (
            <AnimationDecorators
                blockId={id}
                data-block-type={viewType}
                animation={baseBreakpoint.animation}
                {...rest}
                style={{ width: '100%', height: '100%', ...paddingStyle, overflow: baseBreakpoint.size.overflow, ...rest.style }}
            >
                <SCxViewContainer ref={ref}>
                    {breakPoint ? (
                        <ViewBlockTool
                            appId={appId}
                            envId={envId}
                            scope={scope}
                            dataSource={dataSource}
                            dataSourceList={dataSourceList}
                            tabList={tabList}
                            tabValue={tabValue}
                            blockData={blockData}
                            quickFilterData={quickFilterData}
                            viewIndependentDataOmitRecord={viewIndependentDataOmitRecord}
                            // selectedRecords={selectedIds}
                            // exportTemplateList={exportTemplateList}
                            // selectedMode={selectedMode}
                            sortCache={sortCache}
                            groupCache={initGroup}
                            tablePropsCache={tablePropsCache}
                            cachedKanbanSort={kanbanColumnsSortCache}
                            previewType={previewType}
                            onTabChange={handleTabChange}
                            onRecordAdd={handleRecordAdd}
                            onSearch={handleSearch}
                            // onSelectModeChange={handleSelectModeChange}
                            onFilter={handleQuickFilter}
                            // onRecordDelete={handleRecordDelete}
                            onChangeSorter={handleSorterChange}
                            onChangeDisplay={handleDisplayChange}
                            onChangeGroup={handleGroupChange}
                            onAppend={handleAppend}
                            // onExport={handleExport}
                            // onPrint={handlePrintByTemplate}
                            onToolbarActionTrigger={handleAction}
                            onChangePageNum={handleChangePageNum}
                            onRenderButtonTitle={handleRenderTitle}
                            onChangeCachedKanbanSort={setKanbanColumnsSortCache}
                        >
                            <ViewBlockRender
                                envId={envId}
                                dataSource={dataSource}
                                blockData={blockData}
                                dataSourceList={dataSourceList}
                                records={records}
                                pagination={viewPagination}
                                previewType={previewType}
                                aiFieldStatusListAtom={aiFieldStatusListAtom}
                                selectedRecords={selectedIds}
                                blockWidth={blockWidth}
                                breakPoint={breakPoint}
                                tablePropsCache={tablePropsCache}
                                tableColumnCache={tableColumnCache}
                                cachedKanbanSort={kanbanColumnsSortCache}
                                onSelectedRecords={handleSelectedRecords}
                                onRecordClick={handleRecordClick}
                                onRecordEdit={handleRecordEditClick}
                                onRecordAdd={handleRecordAdd}
                                onRecordDelete={handleRecordDelete}
                                onAiGeneration={handleAiGeneration}
                                onRecordOperatorActionTrigger={handleAction}
                                onRecordClickedActionTrigger={handleAction}
                                onCellChange={handleValueChange}
                                onCellUpdate={handleCellUpdate}
                                onUpdateRecord={handleRecordUpdate}
                                onRenderButtonTitle={handleRenderTitle}
                                pageTarget={pageTarget}
                                node={node}
                                onLoadMoreData={handleLoadMoreRecords}
                                onSelectModeChange={handleSelectModeChange}
                                onTableColumnWidthChange={handleTableColumnWidthChange}
                                onChangeCachedKanbanSort={setKanbanColumnsSortCache}
                            />
                        </ViewBlockTool>
                    ) : null}
                </SCxViewContainer>
                {children}
            </AnimationDecorators>
        )
    }, [
        appId,
        baseBreakpoint.size.overflow,
        baseBreakpoint.viewLayout?.viewPadding,
        blockData,
        blockWidth,
        breakPoint,
        children,
        dataSource,
        dataSourceList,
        envId,
        extraLoading,
        baseBreakpoint.animation,
        handleAction,
        handleAiGeneration,
        handleAppend,
        handleCellUpdate,
        handleChangePageNum,
        handleDisplayChange,
        handleGroupChange,
        handleLoadMoreRecords,
        handleQuickFilter,
        handleRecordAdd,
        handleRecordClick,
        handleRecordDelete,
        handleRecordEditClick,
        handleRecordUpdate,
        handleRenderTitle,
        handleSearch,
        handleSelectModeChange,
        handleSelectedRecords,
        handleSorterChange,
        handleTabChange,
        handleTableColumnWidthChange,
        handleValueChange,
        id,
        initGroup,
        kanbanColumnsSortCache,
        loading,
        node,
        pageTarget,
        pointer,
        previewType,
        quickFilterData,
        records,
        ref,
        rest,
        scope,
        selectedIds,
        setKanbanColumnsSortCache,
        sortCache,
        tabList,
        tabValue,
        tableColumnCache,
        tablePropsCache,
        viewIndependentDataOmitRecord,
        viewPagination,
        viewType
    ])
}

export default ViewBlockController
