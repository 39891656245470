import type { DataSourceAbstract, EVENT_VARIABLE_TYPE, Field, TypeInstanceMap } from '@lighthouse/core'
import type {
    CurrPageDatasourceForVariable,
    FlowNode,
    GetPageDataOptionsParams,
    NodeTypes,
    PrevPageDatasourceForVariable,
    VariableOptions
} from '@lighthouse/shared'
import {
    defaultFormat,
    getDefaultDataSourceOptions,
    getEventOption,
    getPageDataOptions,
    getUpstreamNodeOptionByInnerType,
    getUserDatasourceOptionsByInnerType,
    innerTypeNameMap,
    innerTypeToFieldType,
    NodeFieldPreview,
    placeholderFromInnerType,
    USER_DATASOURCE,
    VariableSourceType
} from '@lighthouse/shared'
import type { JSONContent } from '@tiptap/core'
import React, { useMemo } from 'react'

import { useFlow } from '@/contexts/FlowContext'
import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

export type CurrentNodeFields = {
    name: string
    nodeType: NodeTypes
    fields: Field[]
}

interface SettingSubProcessParamProps {
    value?: JSONContent
    dataSource?: DataSourceAbstract
    innerType: TypeInstanceMap
    allParentNodes?: FlowNode[]
    parentNodes?: FlowNode[]
    dataSourceList: DataSourceAbstract[]
    eventType?: EVENT_VARIABLE_TYPE
    curr?: CurrPageDatasourceForVariable
    prev?: PrevPageDatasourceForVariable
    onChange: (val: JSONContent) => void
}

export const SettingSubProcessParam: React.FC<SettingSubProcessParamProps> = ({
    value,
    dataSource,
    innerType: paramInnerType,
    allParentNodes,
    parentNodes,
    dataSourceList,
    eventType,
    curr,
    prev,
    onChange
}) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const { type } = useFlow()

    const isInFlow = !!type
    const virtualField: Field | undefined = useMemo(() => {
        if (paramInnerType === 'NULL') {
            return
        }
        const extraConfig =
            paramInnerType === 'DATE'
                ? {
                      date: {
                          format: defaultFormat
                      }
                  }
                : {}
        return {
            id: '',
            name: innerTypeNameMap[paramInnerType],
            type: innerTypeToFieldType[paramInnerType],
            dsId: '',
            innerType: paramInnerType,
            ...extraConfig
        } as Field
    }, [paramInnerType])

    const userOption = useMemo(() => {
        const userDataSource = dataSourceList.find(item => item.id === USER_DATASOURCE)
        return getUserDatasourceOptionsByInnerType({
            dataSource: userDataSource,
            validateFieldType: (innerType: TypeInstanceMap) => {
                return innerType === paramInnerType
            }
        })
    }, [dataSourceList, paramInnerType])

    const dataSourceOption = useMemo(() => {
        if (!dataSource) {
            return
        }
        return getDefaultDataSourceOptions(dataSource, innerType => innerType === paramInnerType)
    }, [dataSource, paramInnerType])

    const pageData: GetPageDataOptionsParams[] = useMemo(() => {
        if (isInFlow) {
            return []
        }
        const currentPage: GetPageDataOptionsParams | undefined = curr &&
            curr.datasource && {
                variableSourceType: VariableSourceType.page,
                datasource: curr.datasource,
                validateFieldType: field => field.innerType === paramInnerType
            }
        const prevPage: GetPageDataOptionsParams | undefined = prev &&
            prev.datasource && {
                variableSourceType: VariableSourceType.parentPage,
                datasource: prev.datasource,
                validateFieldType: field => field.innerType === paramInnerType
            }
        return [currentPage, prevPage].filter(Boolean) as GetPageDataOptionsParams[]
    }, [curr, isInFlow, paramInnerType, prev])

    const variableOptions = useMemo(() => {
        return [
            isInFlow &&
                parentNodes &&
                allParentNodes &&
                getUpstreamNodeOptionByInnerType({
                    dataSourceList,
                    parentNodes,
                    allParentNodes,
                    validateInnerType: innerType => innerType === paramInnerType
                }),
            ...getPageDataOptions(pageData)
        ].filter(Boolean) as VariableOptions
    }, [allParentNodes, dataSourceList, isInFlow, pageData, paramInnerType, parentNodes])

    const placeholder = placeholderFromInnerType[virtualField?.innerType || 'TEXT']

    const eventOption = useMemo(() => eventType && getEventOption(eventType, paramInnerType), [eventType, paramInnerType])

    return (
        <NodeFieldPreview
            disabled={disabledWithVersion}
            autoHeight
            onChange={onChange}
            value={value}
            placeholder={placeholder}
            field={virtualField}
            dataSourceOption={dataSourceOption}
            userOption={userOption}
            options={variableOptions}
            eventOption={eventOption}
        />
    )
}
