import type { GroupProps } from '@byecode/ui'
import { Divider, Flex, Group, Input } from '@byecode/ui'
import type { ContainerBreakPointConfigProtocol, PageAbstract, PageShareConfig, PartialExcludesIdAndName } from '@lighthouse/core'
import { DataSourceType, EVENT_VARIABLE_TYPE, PAGE_TYPE } from '@lighthouse/core'
import type { CurrPageDatasourceForVariable } from '@lighthouse/shared'
import {
    ApplicationPreviewEnum,
    deepMerge,
    getMainTableRecordId,
    getPageWithMergedBreakPoint,
    ListItem4ByecodeUi,
    SelectDataSource,
    useAtomAction,
    useAtomData,
    useSharedConfigDisabledWithVersion,
    VariableSourceType
} from '@lighthouse/shared'
import { deepOmitNil } from '@lighthouse/tools'
import fastEqual from 'fast-deep-equal'
import { clone, findIndex } from 'rambda'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useLatest } from 'react-use'
import { debounce } from 'throttle-debounce'

import { updateLocalPageAtom, updateRemotePageAtom } from '@/atoms/page/action'
import { lastPageOfStackAtom } from '@/atoms/page/state'
import { BreakPointProvider } from '@/contexts/BreakPointContext'
import { useCurrentAppID, useCurrentEnvId, usePreviewType } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'
import { PAGE_CONFIG_UPDATE_ACTION, pageUndoRedoController } from '@/utils/undoRedo/page/controller'

import { BlockConfigureSegmentedControl } from '../BlockSettings/Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../BlockSettings/constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../BlockSettings/constants'
import { CollectionEvent } from '../CollectionEvent'
import { AutoLayoutSetting, getBlockAndPageDesignLimit, SizeSetting, StyleSetting } from '../DesignSetting'
import NavbarVisibility from './NavbarVisibility'
import { PageShare } from './Share'
import { PageVisibility } from './Visibility'

interface PageSettingProps {
    pageData: PageAbstract
    mode?: GroupProps['mode']
}

const pageEventTypes = [EVENT_VARIABLE_TYPE.PAGE_VISIT]

const PageSetting: React.FC<PageSettingProps> = ({ pageData, mode }) => {
    const previewType = usePreviewType()
    const latestPage = useLatest(pageData)
    const mergePageContent = getPageWithMergedBreakPoint(previewType, pageData)
    const latestMergePage = useLatest(mergePageContent)
    const { id, type, shareConfig, breakPoint, breakPoints, name, dsId, collectEvents } = mergePageContent
    const [activeTab, setActiveTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)
    const { run: updateLocalPage } = useAtomAction(updateLocalPageAtom)
    const { run: updateRemotePage } = useAtomAction(updateRemotePageAtom)
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    // 获取数据源
    const dataSourceList = useDataSourceList(appId, envId)
    const isRecordPage = type === PAGE_TYPE.document || type === PAGE_TYPE.edit
    const [pageDsId, pageRecordId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.dsId, s?.recordId], [])
    )
    const pageDatasource = useMemo(
        () => (isRecordPage ? dataSourceList.find(item => item.id === pageDsId) : undefined),
        [dataSourceList, isRecordPage, pageDsId]
    )
    const curr: CurrPageDatasourceForVariable = useMemo(() => {
        const recordId = isRecordPage ? pageRecordId : undefined
        return {
            variableSourceType: VariableSourceType.page,
            page: latestMergePage.current,
            recordId: getMainTableRecordId(recordId),
            datasource: pageDatasource
        }
    }, [isRecordPage, latestMergePage, pageDatasource, pageRecordId])

    const disabledWithVersion = useSharedConfigDisabledWithVersion()

    const debounceUpdateRemotePage = useMemo(
        () =>
            debounce(
                500,
                async (origin: PageAbstract, current: PageAbstract) => {
                    pageUndoRedoController.add({ action: PAGE_CONFIG_UPDATE_ACTION, payload: { prev: origin, next: current } })
                    await updateRemotePage(current)
                }
                // { atBegin: true }
            ),
        [updateRemotePage]
    )

    const updatePage = useCallback(
        (current: PageAbstract) => {
            updateLocalPage(current)
            debounceUpdateRemotePage(pageData, current)
        },
        [debounceUpdateRemotePage, pageData, updateLocalPage]
    )

    const handleSyncOne = useCallback(
        (name: string, previewType: ApplicationPreviewEnum) => {
            if (previewType === ApplicationPreviewEnum.desktop) {
                return
            }
            const page = clone(latestPage.current)
            const mergeBreakPoint = latestMergePage.current.breakPoint
            const target = deepMerge(page.breakPoint, mergeBreakPoint, [name])
            const index = findIndex(item => item?.id === previewType, page.breakPoints)
            if (index >= 0) {
                page.breakPoints[index].breakKeys = page.breakPoints[index].breakKeys?.filter(key => key !== name)
            }
            page.breakPoint = target
            updatePage?.(page)
        },
        [latestMergePage, latestPage, updatePage]
    )

    const handleSyncAll = useCallback(
        (previewType: ApplicationPreviewEnum) => {
            if (previewType === ApplicationPreviewEnum.desktop) {
                return
            }
            const page = clone(latestPage.current)
            page.breakPoint = latestMergePage.current.breakPoint
            const index = findIndex(item => item?.id === previewType, page.breakPoints)
            if (index >= 0) {
                page.breakPoints[index].breakKeys = []
            }
            updatePage?.(page)
        },
        [latestMergePage, latestPage, updatePage]
    )

    const selectDtaSourceList = useMemo(() => {
        return dataSourceList.filter(cur => {
            return !(cur.type === DataSourceType.aggregateDataSource)
        })
    }, [dataSourceList])

    const designProps = getBlockAndPageDesignLimit('page')

    const methods = useForm({
        values: { shareConfig, breakPoint, name, dsId, collectEvents }
    })

    const { control } = methods

    // useUpdateEffect(() => {
    //     methods.reset({ shareConfig, breakPoint, name, dsId, collectEvents })
    // }, [previewType, breakPoint.breakKeys?.length])

    useEffect(() => {
        const subscribe = methods.watch((values, info) => {
            if (fastEqual(deepOmitNil(latestMergePage.current), deepOmitNil(values))) {
                return
            }
            const { shareConfig, breakPoint, ...rest } = values
            const validShareConfig = shareConfig as PageShareConfig
            const validBreakPoint = clone(breakPoint) as ContainerBreakPointConfigProtocol
            const page = clone({ ...pageData, ...rest, id, shareConfig: validShareConfig, breakPoint: validBreakPoint, breakPoints })
            if (previewType !== ApplicationPreviewEnum.desktop && info.name?.includes('breakPoint')) {
                page.breakPoint = pageData.breakPoint
                const index = findIndex(item => item?.id === previewType, page.breakPoints)
                if (index >= 0) {
                    page.breakPoints[index] = {
                        ...validBreakPoint,
                        id: page.breakPoints[index]?.id,
                        name: page.breakPoints[index]?.name
                    } as PartialExcludesIdAndName<ContainerBreakPointConfigProtocol>
                }
            }
            updatePage(page as PageAbstract)
        })

        return () => {
            subscribe.unsubscribe()
        }
    }, [updatePage, id, methods, breakPoints, previewType, pageData.breakPoint, pageData, latestMergePage])

    return (
        <BreakPointProvider onSyncOne={handleSyncOne} onSyncAll={handleSyncAll}>
            <FormProvider {...methods}>
                <Flex grow>
                    <BlockConfigureSegmentedControl isInterAction={false} value={activeTab} onChange={setActiveTab} />
                </Flex>
                {activeTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                    <>
                        <NavbarVisibility pageId={id} />
                        <Divider color="var(--color-gray-200)" />
                        <SizeSetting {...designProps.size} />
                        <Divider />
                        <AutoLayoutSetting {...designProps.layout} />
                        <Divider />
                        <StyleSetting {...designProps.design} />
                    </>
                )}
                {activeTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                    <>
                        <Group label="基础">
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <div>数据表</div>
                                <Controller
                                    control={control}
                                    name="dsId"
                                    render={({ field }) => (
                                        <SelectDataSource
                                            withinPortal
                                            size="md"
                                            disabled={disabledWithVersion}
                                            position="bottom-end"
                                            dataSourceList={selectDtaSourceList}
                                            {...field}
                                        />
                                    )}
                                />
                            </ListItem4ByecodeUi>
                            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                                <div>标题</div>
                                <Input
                                    maxLength={30}
                                    {...methods.register('name', {
                                        onChange: v => {
                                            if (!v) {
                                                return '未命名页面'
                                            }
                                        }
                                    })}
                                    styles={{
                                        wrapper: { width: 180 }
                                    }}
                                />
                            </ListItem4ByecodeUi>
                        </Group>

                        <Divider color="var(--color-gray-200)" />

                        <PageShare />

                        <Divider color="var(--color-gray-200)" />

                        <Controller
                            control={control}
                            name="collectEvents"
                            render={({ field }) => (
                                <CollectionEvent curr={curr} eventTypes={pageEventTypes} value={field.value} onChange={field.onChange} />
                            )}
                        />
                        {/* <SeoSearchEngine pageId={id} /> */}
                        <Divider color="var(--color-gray-200)" />

                        <PageVisibility pageId={id} mode={mode} addMemberWithinPortal />
                    </>
                )}
            </FormProvider>
        </BreakPointProvider>
    )
}
export default PageSetting
