import { Button, IconFont } from '@byecode/ui'
import type { GroupConfigure, SchemaProtocol, ViewFieldProps } from '@lighthouse/core'
import { Menu } from '@mantine/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useApplicationContext, useLanguageContext } from '../../contexts'
import { getFieldIcon } from '../../utils'
import { CAN_SELECT_OPTIONS, DataGroupConfigure } from '../DataGroupConfigure'
import { FieldTypeTag } from '../FieldTypeTag'

const SCxIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px;
`

interface KanbanGroupProps {
    schema: SchemaProtocol['schema']
    columns: ViewFieldProps[]
    kanbanGroupConfig?: GroupConfigure
    isMobile?: boolean
    onKanbanGroupConfigChange?: (v: GroupConfigure) => void
}

export const KanbanGroup: React.FC<KanbanGroupProps> = ({ schema, columns, kanbanGroupConfig, isMobile, onKanbanGroupConfigChange }) => {
    const { personOptions } = useApplicationContext()
    const { convertTextByLanguage } = useLanguageContext()
    const options = useMemo(
        () =>
            columns
                .filter(item => CAN_SELECT_OPTIONS.has(item.type))
                .map(item => ({
                    label: item.title,
                    value: item.fieldId,
                    icon: <IconFont fill='var(--color-gray-400)' type={getFieldIcon(item.fieldId, item.type, item.innerType)} size={16} />,
                    extra: <FieldTypeTag type={item.type} innerType={item.innerType} />
                })),
        [columns]
    )

    return (
        <>
            <Menu positionDependencies={[kanbanGroupConfig]}>
                <Menu.Target>
                    {isMobile ? (
                        <SCxIconWrapper>
                            <IconFont type="Columns" size={20} />
                        </SCxIconWrapper>
                    ) : (
                        <Button type="text" style={{ height: 32 }} icon={<IconFont size={16} type="Columns" />}>
                            {convertTextByLanguage('kanbanSetting')}
                        </Button>
                    )}
                </Menu.Target>
                <Menu.Dropdown>
                    <DataGroupConfigure
                        color="var(--color-app-main)"
                        schema={schema}
                        options={options}
                        selectProps={{ disabled: true }}
                        value={kanbanGroupConfig}
                        personOptions={personOptions}
                        onChange={onKanbanGroupConfigChange}
                    />
                </Menu.Dropdown>
            </Menu>
        </>
    )
}
