import { Button, IconFont } from '@byecode/ui'
import { useAtomAction } from '@lighthouse/shared'
import { Modal } from '@mantine/core'
import { find, isEmpty } from 'rambda'
import React, { useCallback, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useSearchParams } from 'react-router-dom'

import { updateUserAtom } from '@/atoms/auth/action'
import { workSpaceAtom, workSpaceListAtom } from '@/atoms/workSpace/state'
import { useUser } from '@/hooks/useUser'
import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import * as srv from '@/services'

import { FormItemRender } from './FormItemRender'
import { formSchema, formStepPersonMeta } from './formSchema'
import { StepIndicator } from './StepIndicator'
import * as SC from './styles'

interface BetaRequestFormProps {}

const buttonStyles: React.CSSProperties = {
    width: 200,
    height: 48,
    justifyContent: 'center'
}

export const BetaRequestForm: React.FC<BetaRequestFormProps> = () => {
    const [opened, setOpened] = useState(true)
    const currentSpaceId = useCurrentSpaceID()
    const { run: updateSpace } = useAtomAction(workSpaceAtom)
    const { run: updateWorkSpaceListAtom } = useAtomAction(workSpaceListAtom)
    const { run: updateUser } = useAtomAction(updateUserAtom)
    const [searchParams] = useSearchParams()
    const [currentStep, setCurrentStep] = useState(0)
    // 获取当前用户信息，用于保存手机号
    const userData = useUser()
    // 渠道
    const from = searchParams.get('from') || undefined
    const form = useForm<Record<string, string | string[]>>()

    const formStepMeta = useMemo(() => {
        if (!from) {
            return formStepPersonMeta
        }
        return formStepPersonMeta.filter(item => item.id !== 'source')
    }, [from])
    // const useType = form.watch('useType')

    const currentStepForm = useMemo(() => {
        return formStepMeta[currentStep]
    }, [currentStep, formStepMeta])

    const currentFormFields = useMemo(() => {
        return currentStepForm.fields?.reduce<string[]>((prev, cur) => {
            if (formSchema[cur]?.name && formSchema[cur]?.config.required) {
                prev.push(formSchema[cur].name)
            }
            return prev
        }, [])
    }, [currentStepForm.fields])
    const isButtonDisabled = form.watch(currentFormFields || []).some(field => !field || isEmpty(field))

    const [isSubmitting, setIsSubmitting] = useState(false)
    // const [isSaved, setIsSaved] = useState(false)

    const stepCount = formStepMeta.length
    const indicatedStepCount = stepCount
    const currentIndicatorStep = currentStep

    const handlePrev = useCallback(() => {
        if (currentStep <= 0) {
            return
        }
        setCurrentStep(s => s - 1)
    }, [currentStep])

    const handleCollectInfo = useCallback(async () => {
        const finalValues = form.getValues()
        const username = String(finalValues.username)
        const apps: string[] = [...((finalValues.apps || []) as string[]), finalValues.customApps as string].filter(Boolean)
        const isCollect = await srv.userInfoCollect({ ...finalValues, apps, from, spaceId: currentSpaceId })
        if (isCollect) {
            updateUser({ ...userData, profileComplete: true, username })
            const newSpaceName = `${username}的空间`
            updateWorkSpaceListAtom(draft => {
                const cur = find(item => item.id === currentSpaceId, draft)
                if (cur) {
                    cur.name = newSpaceName
                }
            })
            updateSpace(draft => {
                draft.name = newSpaceName
            })
        }
        return isCollect
    }, [currentSpaceId, form, from, updateSpace, updateUser, updateWorkSpaceListAtom, userData])

    const handleSubmit = useCallback(async () => {
        if (isSubmitting) {
            return
        }
        // const finalValues = form.getValues()
        setIsSubmitting(true)
        await handleCollectInfo()
        setIsSubmitting(false)
        setOpened(false)
    }, [isSubmitting, handleCollectInfo])

    const handleSkip = useCallback(async () => {
        setOpened(false)
        await handleCollectInfo()
    }, [handleCollectInfo])

    const handleNext = useCallback(async () => {
        if (currentStep === formStepMeta.length - 1) {
            handleSubmit()
            return
        }

        // trigger validate
        const isValid = await form.trigger(currentStepForm.fields)

        if (isValid) {
            setCurrentStep(s => s + 1)
        }
    }, [currentStep, currentStepForm.fields, form, formStepMeta.length, handleSubmit])

    const handleClose = useCallback(() => {
        setOpened(false)
    }, [])

    const isEndStep = useMemo(() => currentStep >= stepCount - 1, [currentStep, stepCount])

    const isLastButOneStep = useMemo(() => currentStep >= stepCount - 2, [currentStep, stepCount])

    const submitStyles = useMemo(() => (isEndStep ? { ...buttonStyles, fontWeight: 500 } : buttonStyles), [isEndStep])

    const stepText = useMemo(() => {
        if (isEndStep) {
            return '开始搭建应用'
        }
        if (isLastButOneStep) {
            return '完成'
        }

        return '下一步'
    }, [isLastButOneStep, isEndStep])

    return (
        <Modal
            styles={{
                modal: {
                    width: 822,
                    overflow: 'hidden'
                }
            }}
            radius={20}
            centered
            padding={0}
            closeOnEscape={false}
            closeOnClickOutside={false}
            withCloseButton={false}
            opened={opened}
            onClose={handleClose}
        >
            <FormProvider {...form}>
                <SC.BetaRequestForm>
                    <SC.BetaRequestStepTitleWrapper>
                        <SC.Header>
                            {currentStep > 0 && (
                                <SC.Back onClick={handlePrev}>
                                    <IconFont type="ArrowLeftSmall" fill="var(--color-gray-400)" size={20} />
                                </SC.Back>
                            )}
                            <StepIndicator stepCount={indicatedStepCount} currentStep={currentIndicatorStep} />
                        </SC.Header>
                        <SC.BetaRequestStepTitleWrapper>
                            <SC.BetaRequestStepTitle>{currentStepForm.stepTitle}</SC.BetaRequestStepTitle>
                            <SC.BetaRequestStepDescription>{currentStepForm.stepDescription}</SC.BetaRequestStepDescription>
                        </SC.BetaRequestStepTitleWrapper>
                        {currentStepForm.fields?.map(fieldName => {
                            const field = formSchema[fieldName]

                            return <FormItemRender key={fieldName} name={field.name} field={field} />
                        })}
                        {currentStepForm.stepList && currentStepForm.stepList.length > 0 && (
                            <SC.BetaRequestStepUl>
                                {currentStepForm.stepList.map(item => (
                                    <SC.BetaRequestStepLi key={item}>
                                        <SC.BetaRequestStepLiDot />
                                        {item}
                                    </SC.BetaRequestStepLi>
                                ))}
                            </SC.BetaRequestStepUl>
                        )}
                        {currentStepForm.stepImage && <SC.BetaRequestStepImage background={currentStepForm.stepImage} />}
                    </SC.BetaRequestStepTitleWrapper>
                    <SC.BetaRequestFormFooter>
                        {isLastButOneStep && <SC.BetaRequestFormSkip onClick={handleSkip}>跳过</SC.BetaRequestFormSkip>}
                        <Button
                            style={submitStyles}
                            disabled={isButtonDisabled}
                            type={isEndStep ? 'default' : 'primary'}
                            size="lg"
                            radius={48}
                            onClick={handleNext}
                            loading={isSubmitting}
                        >
                            {stepText}
                        </Button>
                    </SC.BetaRequestFormFooter>
                </SC.BetaRequestForm>
            </FormProvider>
        </Modal>
    )
}
