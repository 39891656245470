import { Loading } from '@byecode/ui/components/Loading'
import { CenteredWrapper, initBlockRuntimeState, useAtomAction, useAtomData } from '@lighthouse/shared'
import { current } from 'immer'
import React from 'react'
import { useUpdateEffect } from 'react-use'

import { syncComponentsAtom } from '@/atoms/application/state'
import { pageBlocksAtom, pageStackAtom } from '@/atoms/page/state'
import { equalPageStack } from '@/atoms/utils/equalPageStack'
import { PageProvider, PageStackProvider, useRootPageContext } from '@/contexts/PageContext'
import { usePreviewType } from '@/hooks/useApplication'

import { PageContent } from './PageContentV2'

interface PageContainerProps {
    pageId: string
    loading?: boolean
    stackId: string
    refreshPageManually?: () => void
}

export const PageContainer = ({ pageId, stackId, loading, refreshPageManually }: PageContainerProps) => {
    const { rootPageId } = useRootPageContext()
    const previewType = usePreviewType()
    const { run: setPageStack } = useAtomAction(pageStackAtom)

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(syncComponentsAtom)

    // 切换断点时更新runtime state
    useUpdateEffect(() => {
        setPageStack(draft => {
            const stack = equalPageStack({ rootPageId, stackId })(draft)
            if (!stack) {
                return
            }
            initBlockRuntimeState(stack, { blocks: pageBlocks, syncComponents })
        })
    }, [previewType])

    return (
        <PageProvider value={{ pageId, refreshPage: refreshPageManually }}>
            <PageStackProvider value={stackId}>
                {loading ? (
                    <CenteredWrapper style={{ padding: '80px 0' }}>
                        <Loading size={32} />
                    </CenteredWrapper>
                ) : (
                    <PageContent />
                )}
            </PageStackProvider>
        </PageProvider>
    )
}
