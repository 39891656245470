import type { BlockAbstract } from '@lighthouse/core'
import type { NodeRenderProps } from '@lighthouse/shared'
import React, { useCallback } from 'react'

import BlockRenderController from '@/blockControllers'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'
import { useBlockActions } from '@/hooks/useBlock'

export const BlockRender = ({ node, children }: NodeRenderProps) => {
    const { pageId } = useCurrentPageContext()
    const stackId = useCurrentStackIdContext()
    const finalBlock = node.block

    const { onUpdateBlock } = useBlockActions(pageId, stackId)

    const onBlockChange = useCallback(
        (values: BlockAbstract, origin: BlockAbstract) => {
            onUpdateBlock({ prevBlock: origin, nextBlock: values })
        },
        [onUpdateBlock]
    )

    if (!finalBlock) {
        return children as JSX.Element
    }

    return (
        <>
            <BlockRenderController blockData={finalBlock} node={node} onBlockChange={onBlockChange}>
                {children}
            </BlockRenderController>
        </>
    )
}
