import type { GroupProps } from '@byecode/ui'
import { Group, IconFont, Tooltip } from '@byecode/ui'
import * as React from 'react'
import styled from 'styled-components'

interface GroupRequiredProps extends GroupProps {
    required?: boolean
    tooltipLabel?: string
}

const SCxLabel = styled.div`
    display: flex;
    align-items: center;
`

const SCxFieldRequiredTip = styled.span`
    margin: 0 8px 0 6px;
    color: rgb(236, 118, 113);
`

export const GroupRequired: React.FunctionComponent<GroupRequiredProps> = ({
    required,
    tooltipLabel,
    label,
    tooltip,
    ...rest
}) => {
    return (
        <Group
            {...rest}
            label={
                <SCxLabel>
                    <>
                        {label}
                        {required && <SCxFieldRequiredTip>*</SCxFieldRequiredTip>}
                        {tooltip ||
                            (tooltipLabel && (
                                <Tooltip
                                    title={tooltipLabel}
                                    styles={{
                                        tooltip: {
                                            fontSize: 14,
                                            fontWeight: 400,
                                            whiteSpace: 'pre-wrap'
                                        }
                                    }}
                                >
                                    <IconFont size={16} color="var(--color-gray-500)" type="Question" />
                                </Tooltip>
                            ))}
                    </>
                </SCxLabel>
            }
        />
    )
}
