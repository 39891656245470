import { Anchor, Select, Text } from '@byecode/ui'
import type { TypeInstanceMap } from '@lighthouse/core'
import type { RichTextEditorProps, SendEmailParagraph } from '@lighthouse/shared'
import {
    DATE_SYSTEM,
    EMAIL_EDIT_TYPE,
    getSystemOption,
    getUserDatasourceOptionsByInnerType,
    ListItem4ByecodeUi,
    PARAGRAPH_TYPE,
    parseJsonExpression,
    RichTextEditor,
    USER_DATASOURCE
} from '@lighthouse/shared'
import { clone } from 'rambda'
import React, { useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import FormSegmentedControl from '@/components/BlockSettings/Common/FormSegmentedControl'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { useDataSourceList } from '@/hooks/useDataSource'

import { useSendEmailContext } from '../context'
import * as SC from '../styles'

const SCxListItemBaseInfo = styled.div`
    gap: 0;
    padding: 0 16px;
`

const SCxHighLightText = styled.div`
    background-color: var(--color-gray-100);
    padding: 8px;
    line-height: 20px;
    font-size: var(--font-size-sm);
    margin: 4px 0;
    border-radius: 4px;
`

const SCxRequired = styled.span`
    color: var(--color-red-500);
`

const SCxContainer = styled.div<{ show?: boolean }>`
    display: ${({ show }) => (show ? 'block' : 'none')};
    margin: 8px 0;
    border-radius: 8px;
    border: 1px solid var(--color-gray-200);
    .ProseMirror {
        min-height: 120px;
    }
    .ProseMirror > p {
        word-break: break-all;
    }
`

const paragraphTypeOptions = [
    {
        label: '常规',
        value: PARAGRAPH_TYPE.NORMAL
    },
    {
        label: '多行列表',
        value: PARAGRAPH_TYPE.MULTIPLE
    }
]

interface EmailParagraphConfigureProps {
    disabled?: boolean
    style?: React.CSSProperties
}

const validateInnerType = (innerType: TypeInstanceMap) => innerType !== 'RICH_TEXT' && innerType !== 'NULL'

export const EmailParagraphConfigure: React.FunctionComponent<EmailParagraphConfigureProps> = ({ disabled, style }) => {
    const { control, setValue } = useFormContext<SendEmailParagraph>()
    const { options, multiNodeOptions, editType = EMAIL_EDIT_TYPE.RICHTEXT } = useSendEmailContext()
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const paragraphType = useWatch({ control, name: 'type' })

    const dataSourceList = useDataSourceList(appId, envId)

    const systemOption = useMemo(() => getSystemOption(DATE_SYSTEM), [])

    const userOption = useMemo(
        () =>
            getUserDatasourceOptionsByInnerType({
                dataSource: dataSourceList.find(item => item.id === USER_DATASOURCE),
                validateFieldType: validateInnerType
            }),
        [dataSourceList]
    )

    const richtextConfig: RichTextEditorProps['config'] = useMemo(
        () => ({
            heading: false,
            bulletList: false,
            orderedList: false,
            taskList: false,
            fontSize: false,
            codeBlock: false,
            image: false,
            variable: {
                options,
                userOption,
                systemOption,
                dataSources: dataSourceList
            }
        }),
        [dataSourceList, options, systemOption, userOption]
    )

    const htmlConfig: RichTextEditorProps['config'] = useMemo(
        () => ({
            orderedList: false,
            taskList: false,
            fontSize: false,
            codeBlock: false,
            image: false,
            heading: false,
            bulletList: false,
            quote: false,
            basic: false,
            align: false,
            line: false,
            link: false,
            eraser: false,
            variable: {
                options,
                userOption,
                systemOption,
                dataSources: dataSourceList
            }
        }),
        [dataSourceList, options, systemOption, userOption]
    )

    return (
        <SCxListItemBaseInfo style={style}>
            <Controller
                name="type"
                control={control}
                render={({ field }) => (
                    <FormSegmentedControl
                        label="段落类型"
                        compact
                        disabled={disabled}
                        data={paragraphTypeOptions}
                        value={field.value}
                        size="sm"
                        onChange={field.onChange}
                    />
                )}
            />
            {paragraphType === PARAGRAPH_TYPE.MULTIPLE && (
                <>
                    <SCxHighLightText>
                        <Text whiteSpace="normal">
                            从上游节点中选一个输出为多条记录的节点，即可发送多行列表的段落，详见{' '}
                            <Anchor target="_blank" style={{ fontWeight: 600, color: 'var(--color-main)' }} to="https://byecodehelp.yuque.com/org-wiki-byecodehelp-zavfcl/oevich/drqrgvg10iuf8o90?singleDoc#">
                                帮助文档
                            </Anchor>{' '}
                        </Text>
                    </SCxHighLightText>
                    <ListItem4ByecodeUi alignItems="center" compact justifyContent="space-between">
                        <Text>
                            选择上游节点<SCxRequired>*</SCxRequired>
                        </Text>
                        <SC.RightFill>
                            <Controller
                                name="parentNodeId"
                                control={control}
                                render={({ field }) => (
                                    <Select style={{ width: 180 }} disabled={disabled} {...field} options={multiNodeOptions} />
                                )}
                            />
                        </SC.RightFill>
                    </ListItem4ByecodeUi>
                </>
            )}
            <Controller
                control={control}
                name="richtext"
                render={({ field }) => {
                    return (
                        <SCxContainer show={editType === EMAIL_EDIT_TYPE.RICHTEXT}>
                            <RichTextEditor
                                disabled={disabled}
                                config={richtextConfig}
                                value={field.value}
                                onChange={field.onChange}
                                onEditorBlur={({ editor: e }) => {
                                    const html = e.getHTML()
                                    setValue('targetRichtext', html)
                                                }}
                                styles={{
                                    editorContent: {
                                        backgroundColor: 'var(--color-gray-50)',
                                        borderRadius: 6,
                                        maxHeight: '600px',
                                        overflowY: 'auto'
                                    }
                                }}
                            />
                        </SCxContainer>
                    )
                }}
            />
            <Controller
                control={control}
                name="html"
                render={({ field }) => {
                    return (
                        <SCxContainer show={editType === EMAIL_EDIT_TYPE.HTML}>
                            <RichTextEditor
                                disabled={disabled}
                                config={htmlConfig}
                                shrink={false}
                                value={field.value}
                                onChange={(json, html) => {
                                    field.onChange(json)
                                }}
                                onEditorBlur={()=> {
                                    const html = parseJsonExpression(field.value)
                                    setValue('targetHtml', html)
                                }}
                                styles={{
                                    editorContent: {
                                        backgroundColor: 'var(--color-gray-50)',
                                        borderRadius: 6,
                                        maxHeight: '600px',
                                        overflowY: 'auto'
                                    }
                                }}
                            />
                        </SCxContainer>
                    )
                }}
            />
        </SCxListItemBaseInfo>
    )
}
