import type { AnyExtension, Extensions, JSONContent } from '@tiptap/core'
import { Editor, generateJSON as generateJsonDefault, generateText as generateTextDefault } from '@tiptap/core'
import { Color } from '@tiptap/extension-color'
import Highlight from '@tiptap/extension-highlight'
import Link from '@tiptap/extension-link'
import type { PlaceholderOptions } from '@tiptap/extension-placeholder';
import Placeholder from '@tiptap/extension-placeholder'
import TaskItem from '@tiptap/extension-task-item'
import TaskList from '@tiptap/extension-task-list'
import Align from '@tiptap/extension-text-align'
import TextStyle from '@tiptap/extension-text-style'
import UnderLine from '@tiptap/extension-underline'
import StarterKit from '@tiptap/starter-kit'
import { isEmpty } from 'rambda'

import { CustomizeCodeBlock } from './CustomizeCodeBlock'
import { FontSize } from './FontSize'
import { LineHeight } from './LineHeight'
import type { UploadImageOptions } from './UploadImage'
import { UploadImage } from './UploadImage'
import { UploadImagePlaceholder } from './UploadImage/UploadImagePlaceholder'
import type { VariableExtensionOptions } from './VariableBlock'
import { VariableBlock } from './VariableBlock'

interface ExtensionsOptions {
    image?: boolean | Partial<UploadImageOptions>
    variable?: false | Partial<VariableExtensionOptions>
    history?: boolean
    heading?: boolean
    orderedList?: false
    bulletList?: false
    taskList?: boolean
    link?: boolean
    codeBlock?: boolean
    quote?: false
    line?: false
    fontSize?: false
    align?: boolean
    lineHeight?: boolean
    placeholder?: false | string
    underLine?: boolean
}

export function getExtensions(options?: ExtensionsOptions): Extensions {
    const { codeBlock = true, image, align = false, placeholder, link = true, underLine = true } = options ?? {}

    const imageConfig = typeof image === 'boolean' ? {} : image

    return [
        options?.variable !== false && VariableBlock.configure(options?.variable),
        StarterKit.configure({
            codeBlock: false,
            heading: options?.heading && {
                levels: [1, 2, 3]
            },
            bulletList: options?.bulletList,
            orderedList: options?.orderedList,
            blockquote: options?.quote,
            horizontalRule: options?.line,
        }),
        // ListKeymap,
        Placeholder.configure({
            emptyEditorClass: 'editor-placeholder',
            // showOnlyWhenEditable: false,
            placeholder: placeholder || '输入内容'
        }),
        TextStyle.configure({ HTMLAttributes: { style: `line-height: 1.5` } }),
        underLine && UnderLine,
        link && Link,
        ...(typeof options?.image === 'boolean' && !options.image
            ? []
            : [UploadImage.configure({ ...imageConfig }), UploadImagePlaceholder]),
        TaskList,
        TaskItem,
        codeBlock && CustomizeCodeBlock,
        Color,
        Highlight.configure({ multicolor: true }),
        (options?.fontSize ?? true) && FontSize,
        align &&
            Align.configure({
                types: ['heading', 'paragraph', 'image']
            }),
        options?.lineHeight && LineHeight
    ].filter(Boolean) as AnyExtension[]
}

export const generateText = (doc?: JSONContent, options?: ExtensionsOptions) => {
    if (isEmpty(doc) || !doc) {
        return ''
    }
    return generateTextDefault(doc, getExtensions(options))
}

export const generateJson = (html: string, options?: ExtensionsOptions) => generateJsonDefault(html, getExtensions(options))

/**
 * 检查富文本内容是否为空节点内容
 * @param {JSONContent} doc
 * @returns {boolean}
 */
export const isEmptyRichTextValue = (doc: JSONContent, options?: ExtensionsOptions) => {
    if (isEmpty(doc) || !doc) {
        return true
    }

    const editor = new Editor({ extensions: getExtensions(options), content: doc })

    return editor.isEmpty
}
