import type { AutoLayout } from '@lighthouse/core'
import mitt from 'mitt'

import type { CollisionArea, FlowLayoutNode, NodeIdWithScope, Rect } from '../types'

export const DOM_DATA_NODE_SCOPE = 'data-layout-scope'
export const DOM_DATA_NODE_NAME = 'data-layout-node'
export const DOM_DATA_PARENT_NAME = 'data-layout-parent'
export const DOM_DATA_LEVELS_NAME = 'data-layout-level'
export const DOM_DATA_DISABLED = 'data-layout-disabled'
export const DOM_DATA_VIRTUAL = 'data-layout-virtual'
export const DOM_DATA_NO_DRAGGABLE = 'data-layout-no-draggable'
export const DOM_DATA_REAL_PARENT_ID = 'data-layout-real-parent'

export function findNodeDom(node: NodeIdWithScope, target: Element | Document = document) {
    return target.querySelector<HTMLElement>(
        `${node.scope ? `[${DOM_DATA_NODE_SCOPE}="${node.scope}"]` : ''}[${DOM_DATA_NODE_NAME}="${node.id}"]`
    )
}

export function findTriggerDom(dom: HTMLElement | null | undefined, root?: HTMLElement): HTMLElement | null {
    if (!dom || dom === root) {
        return null
    }

    if (dom.hasAttribute(DOM_DATA_NODE_NAME) && !dom.hasAttribute(DOM_DATA_DISABLED) && !dom.hasAttribute(DOM_DATA_VIRTUAL)) {
        return dom
    }

    if (dom.parentElement) {
        return findTriggerDom(dom.parentElement, root)
    }

    return null
}

/**
 * 根据id和tree查找node节点
 * @date 2024/1/8 - 12:07:55
 *
 * @export
 * @param {string} id
 * @returns {(tree: FlowLayoutNode[]) => FlowLayoutNode | undefined}
 */
export function findNodeById(id: string, scope?: string) {
    const recursion = (tree: FlowLayoutNode[]): undefined | FlowLayoutNode => {
        for (const node of tree) {
            if (node.id === id && node.scope === scope) {
                return node
            }

            if (node.type !== 'block' && node.children) {
                const res = recursion(node.children)
                if (res) {
                    return res
                }
            }
        }
    }

    return recursion
}

export function findParentNodeById({ id, scope }: NodeIdWithScope, tree: FlowLayoutNode[]): FlowLayoutNode | undefined {
    let parent: FlowLayoutNode | undefined

    const recursion = (tree: FlowLayoutNode[]) => {
        for (const node of tree) {
            if (parent) {
                return
            }

            if (node.id === id && node.scope === scope) {
                return true
            }

            if (node.type !== 'block' && node.children) {
                const res = recursion(node.children)
                if (res) {
                    if (!parent) {
                        parent = node
                    }

                    return true
                }
            }
        }
    }

    recursion(tree)

    return parent
}

/**
 * 查找新选中的节点的父级和子级，需要取消选中
 * @param newSelectedId
 * @param selectedIds
 * @param tree
 * @returns
 */
export function findNewSelectParentAndChildren(newSelectedNode: NodeIdWithScope, selectedIds: NodeIdWithScope[], tree: FlowLayoutNode[]) {
    let parent: undefined | NodeIdWithScope
    const leafs: NodeIdWithScope[] = []

    function check(children: FlowLayoutNode[]): boolean | void {
        for (const child of children) {
            if (parent) {
                return
            }

            if (newSelectedNode.scope === child.scope && newSelectedNode.id === child.id) {
                if (child.children) {
                    findLeafs(child.children)
                }
                return true
            }

            if (child.children) {
                const res = check(child.children)
                if (res) {
                    if (!parent && selectedIds.some(item => item.scope === child.scope && item.id === child.id)) {
                        parent = { scope: child.scope, id: child.id }
                    }
                    return res
                }
            }
        }
    }

    function findLeafs(children: FlowLayoutNode[]) {
        for (const child of children) {
            if (selectedIds.some(item => item.scope === child.scope && item.id === child.id)) {
                leafs.push({ scope: child.scope, id: child.id })
            }

            if (child.children) {
                findLeafs(child.children)
            }
        }
    }

    check(tree)

    return { parent, children: leafs }
}

export function findChildScroller(el: HTMLElement, deep?: number): HTMLElement | null {
    if (!el) {
        return document.body
    }
    const { overflowX, overflowY } = window.getComputedStyle(el)
    const isScrollable =
        (overflowX !== 'visible' && overflowX !== 'hidden' && el.scrollWidth >= el.clientWidth) ||
        (overflowY !== 'visible' && overflowY !== 'hidden' && el.scrollHeight >= el.clientHeight)

    if (isScrollable) {
        return el
    }

    if (el.hasChildNodes() && (!deep || deep > 0)) {
        for (const element of el.childNodes) {
            const res = findChildScroller(element as HTMLElement, deep ? deep - 1 : deep)
            if (res) {
                return res
            }
        }
    }

    return null
}

type IndicatorRectParams = {
    collisionArea: CollisionArea
    layout?: AutoLayout
    parentLayout?: AutoLayout
    nodeRect: Rect
    nextNodeRect: Rect | undefined
    parentRect: Rect
    rootRect: Rect
    indicatorSize: number
}

export function getIndicatorRect({
    collisionArea,
    layout,
    nodeRect,
    nextNodeRect,
    parentRect,
    rootRect,
    parentLayout,
    indicatorSize
}: IndicatorRectParams) {
    if (collisionArea.position === 'in') {
        const isVertical = layout?.align?.direction === 'vertical'
        const [pl = 0, pt = 0, pr = 0, pb = 0] = layout?.padding ?? []
        if (isVertical) {
            return {
                left: -rootRect.left + nodeRect.left + pl,
                top: -rootRect.top + nodeRect.top + pt,
                width: nodeRect.width - pl - pr,
                height: indicatorSize
            }
        }
        return {
            left: -rootRect.left + nodeRect.left + pl,
            top: -rootRect.top + nodeRect.top + pt,
            width: indicatorSize,
            height: nodeRect.height - pt - pb
        }
    }

    const parentIsVertical = parentLayout?.align?.direction === 'vertical'
    const parentGap = Number(parentLayout?.gap ?? 0)
    const finalGap = nextNodeRect ? (parentIsVertical ? nextNodeRect.top - nodeRect.bottom : nextNodeRect.left - nodeRect.right) : parentGap
    const [pl = 0, pt = 0, pr = 0, pb = 0] = parentLayout?.padding ?? []

    if (parentIsVertical) {
        if (collisionArea.position === 'before') {
            return {
                left: -rootRect.left + parentRect.left + Number(pl),
                // left: -rootRect.left + nodeRect.left,
                top: Math.max(-rootRect.top + parentRect.top, -rootRect.top + nodeRect.top - finalGap / 2 - indicatorSize / 2),
                width: parentRect.width - Number(pl) - Number(pr),
                height: indicatorSize
            }
        } else if (collisionArea.position === 'after') {
            return {
                left: -rootRect.left + parentRect.left + Number(pl),
                // left: -rootRect.left + nodeRect.left,
                top: Math.min(
                    -rootRect.top + parentRect.bottom - indicatorSize,
                    -rootRect.top + nodeRect.top + nodeRect.height + finalGap / 2 - indicatorSize / 2
                ),
                width: parentRect.width - Number(pl) - Number(pr),
                height: indicatorSize
            }
        }
    } else {
        if (collisionArea.position === 'before') {
            return {
                left: Math.max(-rootRect.left + parentRect.left, -rootRect.left + nodeRect.left - finalGap / 2 - indicatorSize / 2),
                top: -rootRect.top + parentRect.top + Number(pt),
                // top: -rootRect.top + nodeRect.top,
                width: indicatorSize,
                height: parentRect.height - Number(pt) - Number(pb)
            }
        } else if (collisionArea.position === 'after') {
            return {
                left: Math.min(
                    -rootRect.left + parentRect.right - indicatorSize,
                    -rootRect.left + nodeRect.right + finalGap / 2 - indicatorSize / 2
                ),
                top: -rootRect.top + parentRect.top + Number(pt),
                // top: -rootRect.top + nodeRect.top,
                width: indicatorSize,
                height: parentRect.height - Number(pt) - Number(pb)
            }
        }
    }
}

type LayoutInteractionEventBusType = {
    updateConfig: Partial<{ data: FlowLayoutNode[]; selectedIds: NodeIdWithScope[]; scale: number }>
}
export const layoutInteractionEventBus = mitt<LayoutInteractionEventBusType>()
