import { Empty, Modal, Radio, RadioGroup, Select, Toast } from '@byecode/ui'
import { getAssetUrl } from '@lighthouse/assets'
import { useAtomAsyncAction } from '@lighthouse/shared'
import { useAtomValue } from 'jotai'
import React, { useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'

import { fetchWorkflowListAtom } from '@/atoms/workflow/action'
import { workflowListAtom } from '@/atoms/workflow/state'
import * as srv from '@/services'

import { getNodesWithPosition } from '../FlowDetail/utils/getNodesPosition'
import { FlowCard } from './components/FlowCard'

export interface FlowListProps {
    appId: string
    onCardClick?: (id: string) => void
}

const SCxAllFlowWrapper = styled.div``

const SCxAllFlowHeader = styled.div`
    position: sticky;
    top: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 12px;
    background-color: var(--color-gray-50);
`

const SCxAllFlowTitle = styled.h2`
    font-size: 20px;
`

const SCxAllFlowOperatorWrapper = styled.div`
    display: flex;
    align-items: center;
`

const SCxAllFlowCardWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`

export const AllFlow: React.FC<FlowListProps> = ({ appId, onCardClick }) => {
    const workflows = useAtomValue(workflowListAtom)
    const { run: updateWorkflow } = useAtomAsyncAction(fetchWorkflowListAtom)
    const [status, setStatus] = useState('all')
    const [type, setType] = useState('all')

    const handleFlowDelete = useCallback(
        async (id: string) => {
            const isConfirm = await Modal.confirm({
                title: '确认删除',
                content: '确认删除工作流？',
                okStatus: 'error'
            })
            if (isConfirm) {
                await srv.deleteFlow(id)

                updateWorkflow()
                Toast.success('删除成功')
            }
        },
        [updateWorkflow]
    )

    const handleFlowDuplicate = useCallback(
        async (id: string) => {
            try {
                await srv.duplicateFlow(id)
                updateWorkflow()
            } catch {
                Toast.error('复制失败，请稍后重试')
            }
        },
        [updateWorkflow]
    )

    const mutateFlowWithoutRevalidate = useCallback(
        (id: string, value: string | boolean, content: 'name' | 'description' | 'enabled') => {
            updateWorkflow()
        },
        [updateWorkflow]
    )

    const handleFlowNameChange = useCallback(
        async (id: string, value: string | boolean, content: 'name' | 'description' | 'enabled' = 'name') => {
            await srv.updateFlow({ id, [content]: value })
            mutateFlowWithoutRevalidate(id, value, content)
        },
        [mutateFlowWithoutRevalidate]
    )

    const handleFlowEnabledChange = useCallback(
        async (id: string, enabled: boolean) => {
            try {
                const res = await srv.enableFlow(id, enabled)
                if (res.success) {
                    mutateFlowWithoutRevalidate(id, enabled, 'enabled')
                    Toast.success(`${enabled ? '启用' : '禁用'}成功`)
                }
            } catch {
                Toast.error(enabled ? '无法启用工作流，请检查配置后重试' : '禁用失败，请稍后重试')
            }
        },
        [mutateFlowWithoutRevalidate]
    )

    const flowsContent = useMemo(() => {
        const currentFlows = workflows
            .filter(flow => {
                switch (status) {
                    case 'all': {
                        return true
                    }
                    case 'enabled': {
                        return flow.enabled
                    }
                    case 'disabled': {
                        return !flow.enabled
                    }
                    default: {
                        return true
                    }
                }
            })
            .filter(flow => {
                switch (type) {
                    case 'all': {
                        return true
                    }
                    case 'automation':
                    case 'action':
                    case 'subProcess': {
                        return flow.type === type
                    }
                    default: {
                        return true
                    }
                }
            })

        if (currentFlows.length === 0) {
            const imgUrl = getAssetUrl('empty', 'no_flow.svg')
            return (
                <Empty
                    styles={{ root: { marginTop: '100px' } }}
                    icon={<img style={{ width: 150, marginBottom: 4 }} src={imgUrl} alt="no flow" />}
                    description="没有流程"
                />
            )
        }
        return (
            <>
                {currentFlows.map(flow => {
                    const { id: flowId, content } = flow
                    const positionedNodes = content ? getNodesWithPosition(content.nodes, content.edges) : []
                    return (
                        <FlowCard
                            key={flowId}
                            {...flow}
                            nodes={positionedNodes}
                            onClick={() => {
                                onCardClick?.(flowId)
                            }}
                            onDuplicate={() => handleFlowDuplicate(flowId)}
                            onDelete={() => handleFlowDelete(flowId)}
                            onNameChange={value => handleFlowNameChange(flowId, value)}
                            onDescriptionChange={value => handleFlowNameChange(flowId, value, 'description')}
                            onEnabledChange={value => handleFlowEnabledChange(flowId, value)}
                        />
                    )
                })}
            </>
        )
    }, [handleFlowDelete, handleFlowDuplicate, handleFlowEnabledChange, handleFlowNameChange, onCardClick, status, type, workflows])

    return (
        <SCxAllFlowWrapper>
            <SCxAllFlowHeader>
                <SCxAllFlowTitle>所有流程</SCxAllFlowTitle>
                <SCxAllFlowOperatorWrapper>
                    <Select
                        value={type}
                        onChange={setType}
                        options={[
                            { label: '全部', value: 'all' },
                            { label: '自动化', value: 'automation' },
                            { label: '动作流', value: 'action' },
                            { label: '子流程', value: 'subProcess' }
                        ]}
                    />
                    <RadioGroup
                        name="type"
                        styles={{
                            root: {
                                marginLeft: '18px',
                                gap: 12
                                // padding: '0 12px',
                                // height: '38px',
                                // border: '1px solid var(--color-gray-200)',
                                // borderRadius: '8px',
                                // backgroundColor: 'var(--color-white)'
                            }
                        }}
                        value={status}
                        onChange={values => {
                            setStatus(values)
                        }}
                    >
                        <Radio size="xs" value="all" label="全部" />
                        <Radio size="xs" value="enabled" label="已启用" />
                        <Radio size="xs" value="disabled" label="未启用" />
                    </RadioGroup>
                </SCxAllFlowOperatorWrapper>
            </SCxAllFlowHeader>
            <SCxAllFlowCardWrapper>
                {/* <FlowCreator onCreate={handleFlowCreate} /> */}
                {flowsContent}
            </SCxAllFlowCardWrapper>
        </SCxAllFlowWrapper>
    )
}
