import { IconFont, Tooltip } from '@byecode/ui'
import { Menu } from '@mantine/core'
import classNames from 'classnames'
import React, { useCallback } from 'react'
import styled from 'styled-components'

import { useLanguageContext } from '../../../contexts'
import type { I18TranslationKey } from '../../../types'
import { useTipTapContext } from '../Context'
import { CustomActionIcon, DropdownActionButton } from './styles'

const ExtendBar = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 4px;
`

const OPTIONS = [
    { name: 'bold', label: 'bold', icon: <IconFont type="TextBolder" />, hotKey: '⌘+Shift+B' },
    { name: 'italic', label: 'italic', icon: <IconFont type="TextItalic" />, hotKey: '⌘+Shift+I' },
    { name: 'strike', label: 'strikethrough', icon: <IconFont type="TextStrikethrough" />, hotKey: '⌘+Shift+X' },
    { name: 'underline', label: 'underline', icon: <IconFont type="TextUnderline" />, hotKey: '⌘+Shift+U' }
]
type Option = typeof OPTIONS[number]

export const TextGroup = () => {
    const editor = useTipTapContext()
   const { convertTextByLanguage } = useLanguageContext()
    const handleClickItem = useCallback(
        (option: Option) => {
            switch (option.name) {
                case 'bold': {
                    editor?.chain().focus().toggleBold().run()
                    break
                }
                case 'italic': {
                    editor?.chain().focus().toggleItalic().run()
                    break
                }
                case 'strike': {
                    editor?.chain().focus().toggleStrike().run()
                    break
                }
                case 'underline': {
                    editor?.chain().focus().toggleUnderline().run()
                    break
                }

                default: {
                    break
                }
            }
        },
        [editor]
    )

    const active = OPTIONS.some(opt => editor?.isActive(opt.name))

    return (
        <Menu withinPortal trigger="hover" shadow="md" position="bottom" middlewares={{ flip: false, shift: true }}>
            <Menu.Target>
                <DropdownActionButton className={classNames({ active })}>
                    <IconFont type="TextAa" />
                    <IconFont size={10} type="ArrowDownSmallGray" color="var(--color-gray-500)" />
                </DropdownActionButton>
            </Menu.Target>
            <Menu.Dropdown p={6} sx={{ borderRadius: 4 }}>
                <ExtendBar>
                    {OPTIONS.map(item => (
                        <Tooltip
                            key={item.name}
                            title={
                                <div style={{ textAlign: 'center' }}>
                                    <div>{convertTextByLanguage(item.label as I18TranslationKey)}</div>
                                    <div style={{ color: 'var(--color-gray-400)' }}>{item.hotKey}</div>
                                </div>
                            }
                            placement="bottom"
                        >
                            <CustomActionIcon
                                className={classNames({
                                    active: editor?.isActive(item.name)
                                })}
                                onClick={() => handleClickItem(item)}
                            >
                                {item.icon}
                            </CustomActionIcon>
                            {/* <MenuItem
                            icon={item.icon}
                            className={classNames({
                                active: editor?.isActive(item.name)
                            })}
                            onClick={() => handleClickItem(item)}
                        >
                            {item.label}
                        </MenuItem> */}
                        </Tooltip>
                    ))}
                </ExtendBar>
            </Menu.Dropdown>
        </Menu>
    )
}
