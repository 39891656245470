import { getFieldBlockWithDsId, useAtomData } from '@lighthouse/shared'
import { clone } from 'rambda'
import { useCallback, useMemo } from 'react'

import { syncComponentsAtom } from '@/atoms/application/state'
import { blocksAtom, lastPageOfStackAtom, pageAtomFamily, pageBlocksAtom, pageStackAtomFamily } from '@/atoms/page/state'

export const useFieldBlocksWithDsId = function () {
    const lastPageMeta = useAtomData(lastPageOfStackAtom)
    const pageId = lastPageMeta?.pageId ?? ''
    const rootPageId = lastPageMeta?.rootPageId ?? ''
    const stackId = lastPageMeta?.stackId ?? ''

    const [pageDsId = ''] = useAtomData(
        pageAtomFamily(pageId),
        useCallback(s => [s?.dsId], [])
    )

    const syncComponents = useAtomData(syncComponentsAtom)

    const pageBlocks = useAtomData(pageBlocksAtom(pageId))

    const blockRuntimeState = useAtomData(
        pageStackAtomFamily({ rootPageId, stackId }),
        useCallback(s => s?.blockRuntimeState, [])
    )

    const fieldBlocksWithDsId = useMemo(
        () => getFieldBlockWithDsId({ blocks: pageBlocks || [], syncComponents: clone(syncComponents), pageDsId, blockRuntimeState }),
        [blockRuntimeState, pageBlocks, syncComponents, pageDsId]
    )
    return useMemo(() => ({ fieldBlocksWithDsId }), [fieldBlocksWithDsId])
}
