import { Divider, Select } from '@byecode/ui'
import type { NodeTypes } from '@lighthouse/shared'
import { type FlowNode, CollapseBox, getUpstreamRealDsId, nodeTypeOptions, singleRecordNodeType, useAtomData } from '@lighthouse/shared'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { lastPageOfStackAtom } from '@/atoms/page/state'
import { useActionAdderDepParams } from '@/hooks/useActionAdderDepParams'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'

import { SettingRecordContent } from '../../../SettingRecordContent'
import * as SC from '../../styles'
import { SelectRecord } from './SelectRecord'

interface UpdateRecordActionConfigureProps {
    prefixName?: string
    allParentNodes: FlowNode[]
    actionTypeSwitcher?: React.ReactNode
}

export const UpdateRecordActionConfigure: React.FC<UpdateRecordActionConfigureProps> = ({
    prefixName = 'config',
    allParentNodes,
    actionTypeSwitcher
}) => {
    const { control } = useFormContext()
    const nodeId = useWatch({ control, name: `${prefixName}.nodeId` })
    const selectType = useWatch({ control, name: `${prefixName}.selectType` })
    const { dsId } = useActionAdderDepParams()
    const [stackId, pageId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.stackId || '', s?.pageId || ''], [])
    )
    const { prev, curr } = usePageDataSourceForVariableSelector({ pageId, stackId })

    const dataSourceId = useMemo(() => {
        if (selectType === 'UPSTREAM') {
            return getUpstreamRealDsId(nodeId, allParentNodes)
        }
        return dsId
    }, [allParentNodes, dsId, nodeId, selectType])

    const allSingleParentNodes = useMemo(
        () => allParentNodes.filter(item => singleRecordNodeType.has(item.data.nodeType)),
        [allParentNodes]
    )

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => {
                                                field.onChange?.(val as NodeTypes)
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SelectRecord allParentNodes={allParentNodes} prefixName={prefixName} />
                </SC.Content>
            </CollapseBox>

            {/* <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
            <CollapseBox label="要更新的记录">
                <SC.Content>
                    <SelectRecord allParentNodes={allParentNodes} prefixName={prefixName} />
                </SC.Content>
            </CollapseBox> */}

            {dataSourceId && (
                <>
                    <Divider color="var(--color-gray-200)" style={{ margin: '12px 0' }} />
                    <CollapseBox label="设置记录内容">
                        <SC.Content>
                            <SettingRecordContent
                                curr={curr}
                                prev={prev}
                                allParentNodes={allSingleParentNodes}
                                dataSourceId={dataSourceId}
                                prefixName={prefixName}
                            />
                        </SC.Content>
                    </CollapseBox>
                </>
            )}
        </SC.Container>
    )
}
