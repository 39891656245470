import type { CustomInputValueRenderProps, Option } from '@byecode/ui'
import { Checkbox, Flex, IconFont, MultiSelect, Select, Tooltip } from '@byecode/ui'
import type { DataSourceAbstract, Field, ViewFieldProps } from '@lighthouse/core'
import cls from 'classnames'
import { find, isEmpty, reduce } from 'rambda'
import React, { useCallback, useEffect, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'
// import useSWR from 'swr'
import { useImmer } from 'use-immer'

// import * as srv from '@/services'
import { NO_EDIT_SYSTEM_FIELD, notImportableFieldTypes, notMatchImportableFieldTypes } from '../../../constants'
import { useLanguageContext } from '../../../contexts'
import { getFieldIcon, getIsAppointField, getPrimaryDataSourceEnableFieldIds } from '../../../utils'
import { FieldIcon } from '../../FieldIcon'
import { FieldTypeTag } from '../../FieldTypeTag'

interface FieldMapping {
    sheetFieldNo: string
    dsFieldId: string
    matchFieldId?: string
}

interface ImportSettingProps {
    appId: string
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    fileName?: string
    sheets?: {
        name: string
        columns: string[]
    }[]
    disableSelectDataSource?: boolean
    userImportSetting?: string[]
    isShowRepeatConfig?: boolean
    viewColumns?: ViewFieldProps[]
}

const SCxImportSettingContainer = styled.div``

const SCxImportSettingTip = styled.p`
    font-size: var(--font-size-normal);
    color: var(--color-gray-400);
    margin-bottom: 10px;
`

const SCxImportFieldMappingContainer = styled.div`
    border: 1px solid var(--color-gray-200);
    border-radius: 6px;
    overflow: hidden;
`

const SCxMappingHeader = styled.div`
    display: flex;
    padding: 8px 0;
    background-color: var(--color-gray-50);
`

const SCxFieldTip = styled.p`
    flex: 1;
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
    font-size: var(--font-size-normal);
    font-weight: var(--font-weight-bold);
`

const SCxFieldMapTo = styled.div`
    min-width: 45px;
    white-space: nowrap;
    text-align: center;
    color: var(--color-gray-400);
    font-size: var(--font-size-normal);
`

const SCxMappingContent = styled.div`
    max-height: 300px;
    overflow-y: auto;
`

const SCxMappingRow = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid var(--color-gray-200);

    &.disabled {
        background-color: var(--color-gray-100);

        &:after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
        }
    }
`

const SCxMappingOriginalSelector = styled.div`
    flex: 1;
    overflow: hidden;
`

const SCxMappingArrow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    align-self: stretch;
    padding: 0 14px;
    color: var(--color-gray-300);
    border-left: 1px solid var(--color-gray-200);
    border-right: 1px solid var(--color-gray-200);

    &.matched {
        color: var(--color-main);
    }
`

const SCxMappingToContainer = styled.div`
    flex: 1;
    /* padding-top: 8px; */
    overflow: hidden;
    margin-left: 8px;
    margin-right: 8px;
    font-size: var(--font-size-normal);
`

const SCxMappingToCommonFieldWrapper = styled.div`
    display: flex;
    flex: 1;
    font-size: var(--font-size-normal);
`

const SCxMappingToLabel = styled.div`
    /* max-width: 180px; */
    /* width: 100%; */
    flex: 1;
    margin-left: 4px;
    font-size: var(--font-size-normal);
    color: var(--color-black);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`

const SCxMappingToErrorTip = styled.div`
    flex: 1;

    font-size: var(--font-size-sm);
    color: var(--color-gray-300);
    text-align: right;
`

const SCxFooter = styled.div`
    display: flex;
    flex-flow: nowrap row;
    justify-content: flex-start;
    font-size: var(--font-size-normal);
    white-space: nowrap;
    margin-top: 16px;
    height: 32px;
`

const SCxSelect = styled(Select)`
    margin: 0 8px;
`

const SCxRepeatText = styled.span`
    line-height: 32px;
`

const SCxPlaceholder = styled.span`
    color: var(--color-gray-400);
`

const SCxFieldContainer = styled.div`
    min-width: 100px;
    height: 32px;
    border: 1px solid transparent;
    padding: 0 8px;
    border-radius: 6px;
    background-color: var(--color-gray-100);
    &:focus {
        color: var(--color-gray-800);
        border-color: var(--color-main);
    }
`

const SCxRepeatLabel = styled.span`
    display: flex;
    align-items: center;
    padding: 6px 16px;
    cursor: pointer;

    &:hover {
        background-color: var(--color-gray-100);
    }
`

const SCxRepeatIcon = styled(IconFont)`
    margin-left: 6px;
    color: var(--color-gray-400);
`

const SCxExtra = styled.div`
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: 4px;
`

const getSheetFieldNo = (fields: string[], field: string): string => {
    const index = fields.indexOf(field)
    return index === -1 ? '' : `${index}`
}

const repeatModeOptions = [
    {
        label: '跳过',
        value: 'skip',
        describe: '根据“依据字段”的内容，如果在工作表中已经存在，则直接跳过这一行， 如果没有作为新数据新记录导入。'
    },
    {
        label: '覆盖',
        value: 'cover',
        describe: '将Excel的数据完全覆盖到工作表，如果识别到工作表中已经有了相同的记录，就更新这行记录，如果没有就作为新记录导入。'
    },
    {
        label: '仅更新，不新增记录',
        value: 'update',
        describe: '导入时只更新已有记录，即使Excel中有些行数据不在工作表，也不导入新的记录。'
    }
]

const RenderLabel: React.FC<CustomInputValueRenderProps> = ({ selectOptions, placeholder }) => {
    const { length } = selectOptions
    //
    return (
        <SCxFieldContainer tabIndex={0}>
            <Flex alignItems="center" justifyContent="space-between" gap="6px" style={{ height: '100%' }}>
                {length > 0 ? (
                    <SCxRepeatText>{length > 0 ? `已选择${length}个字段` : ''}</SCxRepeatText>
                ) : (
                    <SCxPlaceholder>{placeholder}</SCxPlaceholder>
                )}
                <IconFont color="var(--color-gray-400)" size={16} type="ArrowDownSmall" />
            </Flex>
        </SCxFieldContainer>
    )
}

export const ImportSetting: React.FC<ImportSettingProps> = ({
    appId,
    dataSource,
    dataSourceList,
    sheets,
    fileName,
    disableSelectDataSource,
    userImportSetting,
    isShowRepeatConfig,
    viewColumns
}) => {
    const { control, setValue } = useFormContext()
    const { convertTextByLanguage } = useLanguageContext()
    const { sheetName } = useWatch({ control })
    const repeat = useWatch({ control, name: 'repeat' })
    const importMode = useWatch({ control, name: 'importMode' })
    const mainFieldIds = useMemo(() => getPrimaryDataSourceEnableFieldIds(dataSource, dataSourceList), [dataSource, dataSourceList])
    // const [repeatState, setRepeatState] = useImmer<ImportSettingState>({
    //     repeat: false,
    //     mode: 'skip',
    //     type: 'single',
    //     fieldIds: ''
    // })

    // const { repeat, mode, type, fieldIds } = repeatState

    const fields = useMemo(
        () =>
            reduce<[string, Field], { id: string; field: Field }[]>(
                (prev, [id, field]) => {
                    const { type, ...restField } = field
                    const cannotMapping =
                        notImportableFieldTypes.has(type) || getIsAppointField(id, NO_EDIT_SYSTEM_FIELD || !mainFieldIds.has(field.id))
                    const column = viewColumns?.find(viewColumn => viewColumn.fieldId === field.id)
                    if (column) {
                        restField.name = column.title
                    }
                    if (disableSelectDataSource && cannotMapping) {
                        return prev
                    }
                    if (!userImportSetting || isEmpty(userImportSetting) || userImportSetting.includes(id)) {
                        const fieldType = type ?? 'richText'
                        const convertedField = { type: fieldType, ...restField } as Field

                        if (
                            notImportableFieldTypes.has(fieldType) ||
                            getIsAppointField(field.id, NO_EDIT_SYSTEM_FIELD) ||
                            !mainFieldIds.has(field.id)
                        ) {
                            prev.push({ id, field: convertedField })
                        } else {
                            prev.unshift({ id, field: convertedField })
                        }

                        return prev
                    }
                    return prev
                },
                [],
                Object.entries(dataSource.schema)
            ),
        [dataSource.schema, disableSelectDataSource, mainFieldIds, userImportSetting, viewColumns]
    )

    const currentSheet = useMemo(() => sheets && find(x => x.name === sheetName, sheets), [sheetName, sheets])
    // userImportSetting?.includes(id)
    const fieldMappings = useMemo(
        () =>
            fields.map(field => {
                const sheetFields = currentSheet?.columns
                const { id, field: dsField } = field
                const { name: dsFieldName, type: dsFieldType } = dsField

                const matchedSheetFieldNo =
                    notImportableFieldTypes.has(dsFieldType) || getIsAppointField(id, NO_EDIT_SYSTEM_FIELD) || !mainFieldIds.has(id)
                        ? ''
                        : sheetFields
                        ? getSheetFieldNo(sheetFields, dsFieldName)
                        : ''
                return { sheetFieldNo: matchedSheetFieldNo, dsFieldId: id }
            }),
        [currentSheet?.columns, fields, mainFieldIds]
    )

    // notMatchImportableFieldTypes
    const fieldOptions = useMemo(
        () =>
            fields.reduce<Option[]>((list, field) => {
                if (
                    notMatchImportableFieldTypes.has(field.field.type) ||
                    getIsAppointField(field.id, NO_EDIT_SYSTEM_FIELD || !mainFieldIds.has(field.id))
                    // (!viewColumns || !viewColumns?.some(viewColumn => viewColumn.fieldId === field.id))
                ) {
                    return list
                }
                list.push({
                    label: field.field.name,
                    value: field.id,
                    icon: getFieldIcon(field.id, field.field.type, field.field.innerType),
                    extra: <FieldTypeTag type={field.field.type} innerType={field.field.innerType} />
                })
                return list
            }, []),
        [fields, mainFieldIds]
    )

    const [state, update] = useImmer<{ fieldMappings: FieldMapping[] }>({ fieldMappings: [] })

    useEffect(() => {
        update(draft => {
            draft.fieldMappings = fieldMappings
        })
        setValue('fieldMappings', fieldMappings)
    }, [fieldMappings, setValue, update])

    const sheetFieldOptions = useMemo(() => {
        if (!currentSheet) {
            return []
        }

        return currentSheet.columns.map((column, index) => ({ label: column, value: `${index}` }))
    }, [currentSheet])

    const handleFieldSelectChange = useCallback(
        (index: number, field: keyof FieldMapping, no: string) => {
            update(draft => {
                if (draft.fieldMappings[index]) {
                    ;(draft.fieldMappings[index] as FieldMapping)[field] = no
                    setValue('fieldMappings', draft.fieldMappings)
                }
            })
        },
        [setValue, update]
    )

    const mappingContent = useMemo(() => {
        return fields?.map(({ id, field }, index) => {
            const cannotMapping =
                notImportableFieldTypes.has(field.type) || getIsAppointField(field.id, NO_EDIT_SYSTEM_FIELD) || !mainFieldIds.has(id)
            const currentField = state.fieldMappings[index]
            if (!currentField) {
                return null
            }

            const { sheetFieldNo, matchFieldId } = currentField

            const { id: fieldId, type, name, innerType } = field
            return (
                <SCxMappingRow key={id} className={cls({ disabled: cannotMapping })}>
                    <SCxMappingOriginalSelector>
                        {!cannotMapping && (
                            <Select
                                // disabledPortal
                                clearable
                                size="lg"
                                styles={{
                                    root: { backgroundColor: 'transparent', borderRadius: 0 }
                                }}
                                value={sheetFieldNo}
                                options={sheetFieldOptions}
                                placeholder={convertTextByLanguage('pleasesSelect')}
                                onChange={value => handleFieldSelectChange(index, 'sheetFieldNo', value)}
                                // onClear={() => handleFieldSelectChange(index, 'sheetFieldNo', '')}
                            />
                        )}
                    </SCxMappingOriginalSelector>
                    <SCxMappingArrow className={cls({ matched: state.fieldMappings[index]?.sheetFieldNo !== '' })}>→</SCxMappingArrow>
                    <SCxMappingToContainer>
                        <SCxMappingToCommonFieldWrapper>
                            <FieldIcon id={fieldId} type={type} />
                            <Tooltip title={name}>
                                <SCxMappingToLabel>{name}</SCxMappingToLabel>
                            </Tooltip>
                            <SCxExtra>
                                {cannotMapping && <SCxMappingToErrorTip>该字段不支持建立映射</SCxMappingToErrorTip>}
                                <FieldTypeTag type={type} innerType={innerType} />
                            </SCxExtra>
                        </SCxMappingToCommonFieldWrapper>
                    </SCxMappingToContainer>
                </SCxMappingRow>
            )
        })
    }, [convertTextByLanguage, fields, handleFieldSelectChange, mainFieldIds, sheetFieldOptions, state.fieldMappings])

    return (
        <SCxImportSettingContainer>
            <SCxImportSettingTip>{convertTextByLanguage('fieldMapping')}</SCxImportSettingTip>
            <SCxImportFieldMappingContainer>
                <SCxMappingHeader>
                    <SCxFieldTip style={{ textAlign: 'right' }}>
                        {fileName} &gt; {currentSheet?.name} {convertTextByLanguage('ofColumn')}
                    </SCxFieldTip>
                    <SCxFieldMapTo>{convertTextByLanguage('mapping')}</SCxFieldMapTo>
                    <SCxFieldTip style={{ textAlign: 'left' }}>{dataSource?.name} {convertTextByLanguage('ofField')}</SCxFieldTip>
                </SCxMappingHeader>
                <SCxMappingContent>{mappingContent}</SCxMappingContent>
            </SCxImportFieldMappingContainer>
            {isShowRepeatConfig && (
                <SCxFooter>
                    <Controller
                        control={control}
                        name="repeat"
                        render={({ field }) => (
                            <Checkbox
                                size="xs"
                                label={field.value ? '当识别到重复记录时' : '识别重复记录'}
                                onChange={ev => field.onChange(ev.target.checked)}
                                checked={field.value}
                            />
                        )}
                    />

                    {repeat && (
                        <>
                            <Controller
                                control={control}
                                name="importMode"
                                render={({ field }) => (
                                    <SCxSelect
                                        placeholder="请选择"
                                        disabledPortal
                                        options={repeatModeOptions}
                                        value={field.value}
                                        onChange={field.onChange}
                                        optionComponent={({ value, label, describe, ...rest }) => (
                                            <SCxRepeatLabel {...rest}>
                                                {label}
                                                <Tooltip
                                                    withArrow
                                                    styles={{
                                                        tooltip: {
                                                            maxWidth: '200px'
                                                        }
                                                    }}
                                                    title={describe}
                                                >
                                                    <SCxRepeatIcon type="Question" />
                                                </Tooltip>
                                            </SCxRepeatLabel>
                                        )}
                                    />
                                )}
                            />

                            <SCxRepeatText style={{ marginRight: 6 }}>，识别重复依据的字段</SCxRepeatText>
                            <Controller
                                control={control}
                                name="compareFields"
                                render={({ field }) => (
                                    <MultiSelect
                                        dropdownWidth={220}
                                        position="bottom-start"
                                        styles={{ root: { width: 220 } }}
                                        disabledPortal
                                        placeholder="请选择"
                                        options={fieldOptions}
                                        value={field.value}
                                        customInputValueRender={RenderLabel}
                                        onChange={val => field.onChange(val)}
                                    />
                                )}
                            />
                        </>
                    )}
                </SCxFooter>
            )}
        </SCxImportSettingContainer>
    )
}
