import { getAssetUrl } from '@lighthouse/assets'
import { BlockThumbImageMap } from '@lighthouse/block'
import { BlockType } from '@lighthouse/core'

import type {
    BlockTree,
    BlockTreeItem
} from '../type'
import { BlockKind } from '../type'

export const blockText: BlockTreeItem = {
    name: '文本',
    icon: '',
    describeImage: BlockThumbImageMap['text'],
    color: '#667085',
    backGroundColor: 'rgba(102, 112, 133, 0.10)',
    type: BlockType.text
}

export const blockHighlight: BlockTreeItem = {
    type: 'highlight',
    name: '高亮文本',
    icon: 'Widget',
    describeImage: getAssetUrl('blocks', 'highlight_text.svg'),
    backGroundColor: 'var(--color-gray-50)',
    color: '#667085'
}

export const blockCombination:BlockTreeItem = {
    type: 'combinationTitle',
    name: '组合标题',
    icon: 'Widget',
    describeImage: getAssetUrl('blocks', 'preset_title.svg'),
    backGroundColor: 'var(--color-gray-50)',
    color: '#667085'
}

export const blockIcon: BlockTreeItem = {
    name: '图标',
    icon: 'IconBlock',
    describeImage: BlockThumbImageMap['icon'],
    color: '#667085',
    backGroundColor: 'rgba(102, 112, 133, 0.10)',
    type: BlockType.icon
}

export const blockUserCenter: BlockTreeItem = {
    name: '个人中心',
    icon: 'PersonalCenterBlock',
    describeImage: BlockThumbImageMap['userCenter'],
    color: '#667085',
    backGroundColor: 'rgba(102, 112, 133, 0.10)',
    type: BlockType.userCenter
}

export const blockFloat: BlockTreeItem = {
    name: '弹出窗口',
    icon: '',
    describeImage: BlockThumbImageMap['floatBox'],
    color: '#667085',
    backGroundColor: 'rgba(102, 112, 133, 0.10)',
    type: BlockType.floatBox
}


export const allText: BlockTree = {
    id: BlockKind.text,
    name: '通用',
    items: [blockText, blockHighlight, blockCombination, blockIcon, blockUserCenter]
}
