import { PAGE_SCROLL_CONTAINER_HOST, PAGE_SCROLL_PARENT_CONTENT } from '@lighthouse/shared'
import { useEffect, useRef, useState } from 'react'

export function isElementScrollable(element: Element) {
    if (!element) {
        return false
    }

    const hasScrollableContent = element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth

    const overflowYStyle = window.getComputedStyle(element).overflowY
    const overflowXStyle = window.getComputedStyle(element).overflowX

    const isOverflowYScrollable = overflowYStyle === 'auto' || overflowYStyle === 'scroll'
    const isOverflowXScrollable = overflowXStyle === 'auto' || overflowXStyle === 'scroll'

    return (hasScrollableContent && isOverflowYScrollable) || (hasScrollableContent && isOverflowXScrollable)
}

export const useEnterVisibleArea = (areaSelector: string | Element = PAGE_SCROLL_CONTAINER_HOST) => {
    const [isIn, setIsIn] = useState(false)
    const ref = useRef<HTMLDivElement | null>(null)

    const scrollHandlerRef = useRef(() => {
        const top = ref.current?.getBoundingClientRect().top
        if (top && top < window.innerHeight - 300) {
            setIsIn(true)
        }
    })

    useEffect(() => {
        scrollHandlerRef.current()
    }, [])

    useEffect(() => {
        const scrollContainer = typeof areaSelector === 'string' ? document.querySelector(`#${areaSelector}`) : areaSelector
        const selectorScrollContainer =
            scrollContainer && isElementScrollable(scrollContainer)
                ? scrollContainer
                : document.querySelector(`#${PAGE_SCROLL_PARENT_CONTENT}`)

        if (!selectorScrollContainer) {
            return
        }

        selectorScrollContainer.addEventListener('scroll', scrollHandlerRef.current)
        selectorScrollContainer.addEventListener('resize', scrollHandlerRef.current)
    }, [areaSelector])

    return { ref, isIn }
}
