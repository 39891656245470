import type { BackgroundProtocol, Shadow } from './design'

export enum AnimationTypeEnum {
    'hover' = 'hover',
    'layerInView' = 'layerInView',
    'loop' = 'loop',
    'text' = 'text'
}

export enum TransitionTypeEnum {
    'ease' = 'ease',
    'spring' = 'spring'
}

export interface BaseTransitionProtocol {
    time: number
    delay: number
}
export enum EaseEnum {
    linear = 'linear',
    easeIn = 'easeIn',
    easeInOut = 'easeInOut',
    easeOut = 'easeOut',
    backIn = 'backIn',
    backInOut = 'backInOut',
    backOut = 'backOut',
    custom = 'custom'
}

export enum PresetEnum {
    fadeIn = 'fadeIn',
    scaleIn = 'scaleIn',
    scaleInBottom = 'scaleInBottom',
    slideInTop = 'slideInTop',
    slideInLeft = 'slideInLeft',
    slideInRight = 'slideInRight',
    slideInBottom = 'slideInBottom',
    flipHorizontal = 'flipHorizontal',
    flipVertical = 'flipVertical',
    custom = 'custom'
}

export type TransitionProtocol = {
    type: TransitionTypeEnum
    ease?: EaseEnum
    bezier?: string
    basedOn?: 'time' | 'physics'
    bounce?: number
    stiffness?: number
    damping?: number
    mass?: number
    time: number
    delay: number
}

export interface TransformProtocol {
    scale: number
    rotate?: number
    rotate3d?: {
        x: number
        y: number
        z: number
        // angle: number
    }

    rotateType: '2d' | '3d'
    skew: [number, number]
    offset: [number, number]
}

export interface AnimationControlSetting extends TransformProtocol {
    repeatType?: 'loop' | 'mirror'
    opacity: number
    background?: BackgroundProtocol
    shadow?: Shadow
    transition: TransitionProtocol
}

export interface LayerInViewAnimation {
    type: 'layerInView'
    start: 'top' | 'center' | 'bottom'
    replay: boolean
    preset: PresetEnum
    enter?: AnimationControlSetting
    exit?: AnimationControlSetting
}

export interface HoverAnimation {
    type: 'hover'
    effect: AnimationControlSetting
}

export interface LoopAnimation {
    type: 'loop'
    effect: AnimationControlSetting
}

export interface TextAnimation {
    type: 'text'
    start: 'top' | 'center' | 'bottom'
    replay: boolean
    effect: AnimationControlSetting
}

export enum AnimationState {
    Idle = 'IDLE', // 动画未开始
    Running = 'RUNNING', // 动画进行中
    Paused = 'PAUSED', // 动画暂停
    Completed = 'COMPLETED', // 动画已完成
    Canceled = 'CANCELED' // 动画被取消
}

export type AnimationProtocols = LayerInViewAnimation | HoverAnimation | LoopAnimation | TextAnimation
