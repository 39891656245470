import { Divider } from '@byecode/ui'
import { useAtomValue } from 'jotai'
import React, { useMemo, useState } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'
import styled from 'styled-components'

import { getBlockAndPageDesignLimit } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { PositionSetting } from '@/components/DesignSetting/PositionSetting'
import { SizeSetting } from '@/components/DesignSetting/SizeSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'

import { dataSourceAtom } from '../atoms'
import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM } from '../constants'
import { ChartTypeSwitcher } from './ChartTypeSwitcher'
import { Data } from './Data'
import { DefaultDataSource } from './DataSource'
import { Design } from './Design'
import { IndicatorCardConfigurator } from './IndicatorCardConfigurator'

const SCxContainer = styled.div`
    padding: 12px 16px 0 16px;
`

const SCxHeader = styled.div`
    font-weight: 600;
    padding: 6px 0;
`

interface ChartBlockSettingsProps {}

export const ChartBlockSettings: React.FC<ChartBlockSettingsProps> = () => {
    const dataSource = useAtomValue(dataSourceAtom)
    const { control, setValue, getValues } = useFormContext()
    const chartType = useWatch({ control, name: 'chartType' })
    const designProps = getBlockAndPageDesignLimit('chart')
    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    const setting = useMemo(() => {
        if (chartType === 'indicator') {
            return <IndicatorCardConfigurator />
        }

        return (
            <>
                <DefaultDataSource />
                {dataSource && <Data dataSource={dataSource} />}
                <Design />
            </>
        )
    }, [chartType, dataSource])

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <SCxContainer>
                        <SCxHeader>类型</SCxHeader>
                        <Controller
                            control={control}
                            name="chartType"
                            render={({ field }) => {
                                return <ChartTypeSwitcher value={field.value} onChange={field.onChange} />
                            }}
                        />
                    </SCxContainer>
                    <Divider />
                    <PositionSetting {...designProps.position} />
                    <Divider />
                    <SizeSetting {...designProps.size} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    {setting}
                    <Divider />
                    <VisibilitySetting />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}
        </>
    )
}
