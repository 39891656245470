
import type { BackgroundProtocol, BaseBreakPointProtocol, ButtonAction, CheckboxShapeEnum, IconDecorationProtocol } from '../../protocols'
import { BlockType } from '../Basic'
import { BaseBlock } from './BaseBlock'


export enum SwitchTypeEnum {
    SWITCH = 'SWITCH',
    CHECKBOX = 'CHECKBOX',
    CUSTOM = 'CUSTOM'
}

export interface OnOffBlockConfig extends BaseBreakPointProtocol {
    checkedIcon?: {
        icon: IconDecorationProtocol
        color: BackgroundProtocol
    }
    unCheckedIcon?: {
        icon: IconDecorationProtocol
        color: BackgroundProtocol
    }
    defaultChecked?: boolean
    showType?: SwitchTypeEnum
    shape?: CheckboxShapeEnum
    open: {
        action: ButtonAction
    }
    close: {
        action: ButtonAction
    }
}

export abstract class OnOffBlockAbstract extends BaseBlock {
    type = BlockType.onOff

    abstract override config: OnOffBlockConfig
}
