import { Checkbox } from '@byecode/ui'
import { LoginType } from '@lighthouse/core'
import { ErrorMessage, getBrand, getBrandInfo, inviteCodeReg, mobileRegex, useAtomAction, VerificationCodeBtn } from '@lighthouse/shared'
import { Anchor, Divider } from '@mantine/core'
import type { FormEvent } from 'react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { fetchUserAtom, loginAtom } from '@/atoms/auth/action'
import * as srv from '@/services'

import * as SC from '../styles'
import type { RegisterForm, RegisterProps } from './types'

export const Register: React.FC<RegisterProps> = ({ inviteCode, onChangeMode }) => {
    const {
        register,
        handleSubmit,
        trigger,
        clearErrors,
        getValues,
        control,
        formState: { errors }
    } = useForm<RegisterForm>({
        mode: 'onBlur',
        shouldFocusError: false,
        defaultValues: {
            invitationCode: inviteCode || ''
        }
    })
    const [loadingBtn, setLoadingBtn] = useState(false)
    const navigate = useNavigate()
    const branch = getBrand()
    const submitEl = useRef<HTMLInputElement>(null)
    const [searchParams] = useSearchParams()
    const redirect = searchParams.get('redirect')
    // const templateId = useMemo(() => {
    //     const redirect = searchParams.get('redirect')
    //     return redirect?.includes('templateId') ? redirect.replace('templateId-', '') : ''
    // }, [searchParams])

    const { run: login, loading } = useAtomAction(loginAtom)
    const { run: fetchUser, loading: userLoading } = useAtomAction(fetchUserAtom)
    // const params = new URLSearchParams(url.search);

    // 表单提交
    const loginSubmit = useCallback(
        async (data: RegisterForm) => {
            const { phone, verificationCode, invitationCode } = data
            const isLogin = await login({
                authType: LoginType.MOBILE_AND_CODE,
                mobile: phone,
                smsCode: verificationCode,
                invitationCode,
                sourceParty: branch
            })
            if (!isLogin) {
                return
            }
            const user = await fetchUser()
            if (!user?.username) {
                const search = searchParams.toString()
                navigate({ pathname: '/account/perfect', search })
                return
            }
            searchParams.delete('redirect')
            const search = searchParams.toString()
            navigate({ pathname: redirect || '/', search }, { replace: true })
            // templateId ? navigate(`/create/templateApp?templateId=${templateId}`, { replace: true }) : navigate('/', { replace: true })
        },
        [branch, fetchUser, login, navigate, redirect, searchParams]
    )

    // 提交行为
    const loginHandle = (e: React.MouseEvent<HTMLButtonElement>) => {
        return submitEl.current?.click()
    }

    // 跳转到账号密码登录
    const handleChangeLogin = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            onChangeMode('login')
        },
        [onChangeMode]
    )

    // 获取验证码
    const handleSendCodeHandle = useCallback(
        async (resolve: () => void, reject: () => void) => {
            const result = await trigger('phone')
            if (result) {
                setLoadingBtn(true)
                const isSend = await srv.sendAuthCode({ mobile: getValues('phone') })
                setLoadingBtn(false)
                if (isSend) {
                    resolve()
                    return
                }
                reject()
            }
        },
        [getValues, trigger]
    )

    const clearFormError = useCallback(
        (name: 'phone' | 'verificationCode' | 'invitationCode') => {
            clearErrors(name)
        },
        [clearErrors]
    )

    const handleFormSubmit = useCallback(
        (ev: FormEvent) => {
            ev.preventDefault()
            handleSubmit(loginSubmit)()
        },
        [handleSubmit, loginSubmit]
    )

    // 跳转微信登录
    const handleChangeWechat = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        onChangeMode('wechat')
    }

    const handleValidateInviteCode = useCallback(async (val: string) => {
        if (!val) {
            return true
        }
        const isLocalValidate = inviteCodeReg.test(val)
        if (!isLocalValidate) {
            return '邀请码有误'
        }
        const isValidate = await srv.invitationCodeCheck(val)
        if (!isValidate) {
            return '邀请码无效'
        }
        return true
    }, [])

    const clause = useMemo(
        () => (
            <SC.AgreeContent>
                点击同意
                <Anchor size="sm" href={getBrandInfo().account.privacyAgreement} target="_blank">
                    《隐私协议》
                </Anchor>
                和
                <Anchor size="sm" href={getBrandInfo().account.termsOfService} target="_blank">
                    《服务条款》
                </Anchor>
            </SC.AgreeContent>
        ),
        []
    )

    return (
        <SC.FormContainer onSubmit={handleFormSubmit}>
            <SC.InputWrapper>
                <ErrorMessage name="phone" errors={errors}>
                    <SC.AccountInput
                        placeholder="手机号"
                        // size="xl"
                        onFocus={() => {
                            clearFormError('phone')
                        }}
                        {...register('phone', {
                            required: '请输入手机号',
                            pattern: {
                                value: mobileRegex,
                                message: '请输入正确的手机号' // JS only: <p>error message</p> TS only support string
                            }
                        })}
                    />
                </ErrorMessage>
                <SC.CodeInputBox>
                    <ErrorMessage name="verificationCode" errors={errors}>
                        <SC.CodeInput
                            placeholder="验证码"
                            // size="xl"
                            onFocus={() => {
                                clearFormError('verificationCode')
                            }}
                            maxLength={6}
                            {...register('verificationCode', { required: '请输入验证码' })}
                        />
                    </ErrorMessage>
                    <VerificationCodeBtn loading={loadingBtn} style={{ marginLeft: 8 }} onGetCodeHandle={handleSendCodeHandle} />
                </SC.CodeInputBox>
                <ErrorMessage name="invitationCode" errors={errors}>
                    <SC.AccountInput
                        placeholder="邀请码 (非必填)"
                        // size="xl"
                        onFocus={() => {
                            clearFormError('invitationCode')
                        }}
                        {...register('invitationCode', {
                            validate: handleValidateInviteCode
                        })}
                    />
                </ErrorMessage>
                <SC.AccountButton
                    size="lg"
                    block
                    loading={loading || userLoading}
                    type="primary"
                    // color="var(--color-white)"
                    // backgroundColor="var(--color-main)"
                    // hoverBackgroundColor="var(--color-theme-8)"
                    onClick={loginHandle}
                >
                    登录/注册
                </SC.AccountButton>
                {/* <SC.Agree>
                    <Controller
                        name="remember-me"
                        control={control}
                        render={({ field }) => (
                            <Checkbox
                                checked={!!field.value}
                                color="var(--color-main)"
                                label="记住我"
                                size="xs"
                                onChange={event => field.onChange(event.currentTarget.checked ? 'on' : undefined)}
                            />
                        )}
                    />
                </SC.Agree> */}
                <SC.Agree>
                    <ErrorMessage name="agree" errors={errors}>
                        <Controller
                            name="agree"
                            control={control}
                            rules={{ required: '请先同意协议与条款' }}
                            render={({ field }) => (
                                <Checkbox
                                    checked={field.value}
                                    color="var(--color-main)"
                                    label={clause}
                                    size="xs"
                                    onChange={event => field.onChange(event.currentTarget.checked)}
                                />
                            )}
                        />
                    </ErrorMessage>
                </SC.Agree>
                <SC.HideSubmit ref={submitEl} type="submit" />
            </SC.InputWrapper>
            <Divider
                style={{ margin: '32px 0  16px 0', color: 'var(--color-gray-400)' }}
                my="xs"
                label="其他登录方式"
                labelPosition="center"
            />
            <SC.FooterBetween>
                <SC.AccountButton
                    icon={<SC.Icon type="WeChatLogo" size={20} color="var(--color-green-500)" />}
                    block
                    onClick={handleChangeWechat}
                >
                    微信登录
                </SC.AccountButton>
                <SC.AccountButton icon={<SC.Icon type="Key" size={20} color="var(--color-blue-500)" />} block onClick={handleChangeLogin}>
                    账号密码登录
                </SC.AccountButton>
            </SC.FooterBetween>
        </SC.FormContainer>
    )
}
