import type { ClientRect, DragEndEvent, DragStartEvent } from '@dnd-kit/core'
import { closestCenter, DndContext, MouseSensor, useSensor, useSensors } from '@dnd-kit/core'
import type { SortingStrategy } from '@dnd-kit/sortable'
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable'
import type { Transform } from '@dnd-kit/utilities'
import { CSS } from '@dnd-kit/utilities'
import type { ApplicationSettingNavbarButton, ApplicationSettingNavbarLinkList } from '@lighthouse/core'
import { getRandomIcon, nanoid } from '@lighthouse/tools'
import { clone, filter, findIndex, omit } from 'rambda'
import React, { useCallback, useMemo } from 'react'
import { useImmer } from 'use-immer'

import { useDefaultPageList } from '@/hooks/usePage'

import * as CM from '../../styles'
import { getAllChild, getAllNodeHierarchyList, getFlapList, getInitBaseItem, getNodeLevel } from '../help'
import type { NavBarBaseButtonProps } from '../NavBarBaseButton'
import NavBarButton from '../NavBarBaseButton'
import * as SC from './styles'
import type { DragInfo } from './types'

export type NavBarLinkType = 'button' | 'link'
interface NavBarLinkProps {
    disabled?: boolean
    // label: string
    // type: NavBarLinkType
    value: ApplicationSettingNavbarLinkList
    // maxLen?: number

    onChange?: (data: ApplicationSettingNavbarLinkList) => void
}

type LinkItemProps = {
    disabled?: boolean
    dataList: Record<string, ApplicationSettingNavbarButton>
    level: number
    disableDrag?: boolean
    activeId?: string
    dragInfo?: DragInfo
    closeLevel?: number
} & NavBarBaseButtonProps

export const maxLevel = 2

export const LinkItem: React.FC<LinkItemProps> = ({
    disabled,
    dataList,
    value,
    level,
    activeId,
    dragInfo,
    disableDrag = false,
    ...rest
}) => {
    const { id, child = [] } = value

    const isShowChild = useMemo(() => activeId !== id, [activeId, id])

    const { attributes, listeners, setNodeRef, transform, transition, active, over } = useSortable({
        id,
        disabled: disabled || disableDrag
    })

    const style: React.CSSProperties = useMemo(() => {
        const newLevel = dragInfo?.id === id ? dragInfo?.level : level
        return {
            transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
            transition,
            zIndex: 2,
            marginLeft: newLevel * 30
        }
    }, [dragInfo?.id, dragInfo?.level, id, level, transform, transition])

    return (
        <>
            <NavBarButton
                {...rest}
                disabled={disabled}
                disableHandleEvent={child.length > 0}
                ref={setNodeRef}
                style={style}
                handleDrag={{ attributes, listeners }}
                value={value}
                level={level}
                activeId={activeId}
            />
            {/* {isShowChild &&
                child.map(id => (
                    <LinkItem
                        key={id}
                        {...rest}
                        value={dataList[id]}
                        activeId={activeId}
                        dragInfo={dragInfo}
                        dataList={dataList}
                        level={level + 1}
                    />
                ))} */}
        </>
    )
}

const NavBarLink = React.forwardRef<HTMLDivElement, NavBarLinkProps>(({ disabled, value, onChange }, ref) => {
    const { list, child } = value
    const pageList = useDefaultPageList()
    const [{ activeId, closeLevel }, setDragOverlayState] = useImmer({
        activeId: '',
        overId: '',
        closeLevel: 4,
        level: 0
    })
    const sensors = useSensors(useSensor(MouseSensor, { activationConstraint: { distance: 10 } }))

    // const itemList = useMemo(() => Object.entries(list).map(([id, item]) => item), [list])
    const itemList = useMemo(() => getFlapList(value), [value])

    const hierarchyLinkList = useMemo(() => getAllNodeHierarchyList(value), [value])

    // const activeCountLevel = useMemo(() => {
    //     const levelList = getNodeLevelList(dragOverlayState.activeId.toString(), value, [])
    //     return getMaxNodeListLevel(levelList)
    // }, [dragOverlayState.activeId, value])

    const handleCreate = useCallback(
        (parentId: string) => {
            const newId = nanoid(16)
            const linkItem = {
                ...getInitBaseItem(),
                id: newId,
                icon: getRandomIcon(),
                name: `链接 ${Object.keys(list).length}`
            }
            const newList = clone(list)
            if (!parentId) {
                newList[newId] = linkItem
                onChange?.({ child: [...child, newId], list: newList })
                return
            }
            newList[newId] = { ...linkItem, parentId }
            newList[parentId].child?.push(newId)
            onChange?.({ child, list: newList })
        },
        [child, list, onChange]
    )

    const handleDelete = useCallback(
        (id: string) => {
            const parentId = list[id]?.parentId
            const childList = getAllChild([], id, value)
            const deleteIds = [...childList, id]

            if (!parentId) {
                const newList = omit(deleteIds, list)
                const newChild = filter(item => item !== id, child)
                onChange?.({ child: newChild, list: newList })
                return
            }

            const item = clone(list[parentId])

            const newList: Record<string, ApplicationSettingNavbarButton> = omit(deleteIds, list)
            const newChild = filter(item => item !== id, item.child ?? [])

            newList[parentId] = { ...item, child: newChild }

            onChange?.({ child, list: newList })
        },
        [child, list, onChange, value]
    )

    const handleChange = useCallback(
        (value: ApplicationSettingNavbarButton) => {
            const { id } = value
            const newList = clone(list)
            newList[id] = value
            onChange?.({ child, list: newList })
        },
        [child, list, onChange]
    )

    const handleDragStart = useCallback(
        (event: DragStartEvent) => {
            const { active } = event
            setDragOverlayState(draft => {
                draft.activeId = active?.id.toString()
                draft.closeLevel = getNodeLevel(active?.id.toString(), list, 0)
            })
        },
        [list, setDragOverlayState]
    )

    const handleDragEnd = useCallback(
        (event: DragEndEvent) => {
            const { active, over } = event
            const overParentId = value.list[over?.id ?? '']?.parentId ?? ''
            const activeParentId = value.list[active.id].parentId ?? ''
            const activeId = active.id.toString()
            const overId = (over?.id ?? '').toString()

            if (active.id !== over?.id && activeParentId === overParentId) {
                const newData = clone(value)
                if (overParentId === '') {
                    const oldIndex = newData.child.indexOf(activeId)
                    const newIndex = newData.child.indexOf(overId)
                    newData.child = arrayMove(newData.child, oldIndex, newIndex)
                    setDragOverlayState(draft => {
                        draft.activeId = ''
                        draft.closeLevel = 4
                    })
                    return onChange?.(newData)
                }
                // 添加节点
                const newChild = newData.list[overParentId].child ?? []
                const oldIndex = newChild.indexOf(activeId)
                const newIndex = newChild.indexOf(overId)
                newData.list[overParentId].child = arrayMove(newChild, oldIndex, newIndex)
                setDragOverlayState(draft => {
                    draft.activeId = ''
                    draft.closeLevel = 4
                })
                return onChange?.(newData)
            }

            setDragOverlayState(draft => {
                draft.activeId = ''
                draft.closeLevel = 4
            })
        },
        [onChange, setDragOverlayState, value]
    )

    const strategy: SortingStrategy = useCallback(
        (params: {
            activeNodeRect: ClientRect | null
            activeIndex: number
            index: number
            rects: ClientRect[]
            overIndex: number
        }): Transform | null => {
            const { activeNodeRect, activeIndex, index, overIndex, rects } = params
            const { height } = activeNodeRect ?? {}
            const dragIndex = findIndex(item => activeId === item.id, itemList)
            const defaultTransform = { y: 0, scaleX: 1, scaleY: 1, x: 0 }
            if (dragIndex === -1 || dragIndex === index) {
                return null
            }
            // 没有拖拽或发生移动时，返回原状态
            if (activeIndex === overIndex) {
                return defaultTransform
            }
            // 判断非active的item 且相同等级 相同父级的item 进入以下逻辑
            if (
                index !== activeIndex &&
                getNodeLevel(itemList[activeIndex]?.id, list, 0) === getNodeLevel(itemList[index]?.id, list, 0) &&
                itemList[activeIndex]?.parentId === itemList[index]?.parentId
            ) {
                // 当向下拖拽activeItem时，判断当前同级剩余容器顺序小于overItem的向上偏移
                if (overIndex > activeIndex && overIndex >= index) {
                    return {
                        ...defaultTransform,
                        y: -(activeNodeRect?.height ?? 0)
                    }
                }
                // 当向下拖拽activeItem时，判断当前同级剩余容器顺序小于overItem的向上偏移
                if (overIndex < activeIndex && overIndex <= index) {
                    return {
                        ...defaultTransform,
                        y: activeNodeRect?.height ?? 0
                    }
                }
                return defaultTransform
            }

            return null
        },
        [activeId, itemList, list]
    )

    return (
        <SC.Container ref={ref}>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
                <SC.Content>
                    <SortableContext disabled={disabled} items={itemList.map(({ id }) => id)} strategy={strategy}>
                        {itemList.map((item, index) => {
                            return (
                                <LinkItem
                                    key={item.id}
                                    value={item}
                                    disabled={disabled}
                                    dataList={list}
                                    allPages={pageList ?? []}
                                    onDelete={handleDelete}
                                    onCreate={handleCreate}
                                    onChange={handleChange}
                                    removeIcon="BlockLine"
                                    closeLevel={closeLevel}
                                    activeId={activeId}
                                    level={item.level}
                                />
                            )
                        })}
                    </SortableContext>
                </SC.Content>
            </DndContext>
            <SC.Footer onClick={() => !disabled && handleCreate('')}>
                <CM.Icon type="Add" color="var(--color-main)" size={20} />
                <CM.Text color="var(--color-main)">添加</CM.Text>
            </SC.Footer>
        </SC.Container>
    )
})

export default NavBarLink
