import type { Field, ViewBlockAbstract } from '@lighthouse/core';
import { BlockType } from '@lighthouse/core'
import { findParentBlockByType, getViewOptions, useAtomData } from '@lighthouse/shared'
import { useCallback, useMemo } from 'react'

import { syncComponentsAtom } from '@/atoms/application/state';
import { dataSourceAtomFamily } from '@/atoms/dataSource/state'
import { lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'

import { useCurrentAppID, useCurrentEnvId } from './useApplication'

export function useVariableCustomDataSource() {
    const appId = useCurrentAppID()
    const envId = useCurrentEnvId()
    const [pageId, selectNode, blockRuntimeState] = useAtomData(
        lastPageOfStackAtom,
        useCallback(
            s => [s?.pageId ?? '',s?.state.selectedNodes?.[0], s?.blockRuntimeState] as const,
            []
        )
    )
    const blocks = useAtomData(pageBlocksAtom(pageId))
    const syncComponents = useAtomData(syncComponentsAtom)

    const customViewDsId = useMemo(
        () =>
            findParentBlockByType<ViewBlockAbstract>({
                id: selectNode?.id ?? '',
                blocks,
                blockRuntimeState,
                syncComponents,
                filter: block => block.type === BlockType.view && block.config.viewType === 'custom'
            })?.config.pointer || '',
        [blockRuntimeState, blocks, selectNode?.id, syncComponents]
    )

    const customViewDataSource = useAtomData(dataSourceAtomFamily({ appId, envId, dsId: customViewDsId }))

    return { customViewDataSource, customViewDsId }
}

export function useVariableCustomViewOption(filterField?: (field: Field) => boolean) {
    const { customViewDataSource } = useVariableCustomDataSource()
    const customViewOption = useMemo(
        () =>
            customViewDataSource &&
            getViewOptions({ dataSource: customViewDataSource, viewType: 'custom', validateFieldType: filterField }),
        [customViewDataSource, filterField]
    )

    return { customViewOption, customViewDataSource }
}
