import { IconFont } from '@byecode/ui';
import type { DraggableAttributes } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';
import type {
    ActionsProtocol,
    AiFieldStatus,
    AppUser,
    ButtonAction,
    Field,
    FieldADTValue,
    FieldCellValue,
    RecordLikeProtocol,
    RichTextContentProtocol,
    ViewField
} from '@lighthouse/core';
import { AppRole, EMPTY_COLUMN_GROUP, fieldConvertValue, useLanguageContext , ViewFieldValuePreviewer } from '@lighthouse/shared';
import { findParentScroller, mergeRefs } from '@lighthouse/tools';
import type { MenuProps } from '@mantine/core';
import { ActionIcon, Menu } from '@mantine/core';
import type { atomWithImmer } from 'jotai-immer';
import type { FC, HTMLAttributes } from 'react';
import React, { forwardRef, memo, useMemo, useRef, useState } from 'react';

import { SortableRecordItem } from './SortableRecordItem';
import * as SC from './styles';
import type { SortItemsDataWithKey } from './utils';

interface Props extends HTMLAttributes<HTMLDivElement>, ActionsProtocol {
    isOverlay?: boolean
    creatable?: boolean
    clickable?: boolean
    disabled?: boolean
    selectedList?: string[]
    onSelectChange?: (selected: boolean, id: string) => void
    enableHidden?: boolean
    list: SortItemsDataWithKey[]
    blockId: string
    schema: Record<string, Field>
    columns: ViewField[]
    fieldSchema: Field
    groupedFieldValue?: FieldCellValue
    isOverContainer?: boolean
    handleProps?: DraggableAttributes & { ref: (el: HTMLElement | null) => void }
    collapsed?: boolean
    recordEditOpenable: boolean
    recordDeleteAble: boolean
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    handleCollapse?: () => void
    handleHidden?: () => void
    handleClickAdd?: () => void
    onRecordClick?: (id: string) => void
    onRecordEdit: (recordId: string) => void
    onRecordDelete: (dsId: string, ids: string[]) => Promise<boolean>
    onAiGeneration?: (recordId: string, fieldId: string) => Promise<boolean>
    onRecordOperatorActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRecordClickedActionTrigger?: (action: ButtonAction, record?: RecordLikeProtocol) => Promise<boolean | undefined>
    onRenderButtonTitle: (v: RichTextContentProtocol, record?: RecordLikeProtocol) => string
}

export const KanbanBoardColumn = memo(
    forwardRef<HTMLDivElement, Props>(
        (
            {
                blockId,
                isOverlay,
                actions,
                disabled,
                clickable,
                creatable,
                selectedList,
                enableHidden,
                list,
                schema,
                columns,
                fieldSchema,
                groupedFieldValue,
                isOverContainer,
                handleProps,
                children,
                collapsed,
                recordEditOpenable,
                recordDeleteAble,
                aiFieldStatusListAtom,
                handleCollapse,
                handleHidden,
                handleClickAdd,
                onRecordClick,
                onSelectChange,
                onRecordEdit,
                onRecordDelete,
                onAiGeneration,
                onRecordOperatorActionTrigger,
                onRecordClickedActionTrigger,
                onRenderButtonTitle,
                ...rest
            },
            ref
        ) => {
            const rootRef = useRef<HTMLDivElement | null>(null)
            const scrollRef = useRef<HTMLElement | null>(null)
            if (!scrollRef.current && rootRef.current) {
                scrollRef.current = findParentScroller(rootRef.current)
            }

            const [page, setPage] = useState(0)
            const initialPageSize = 10
            const pageSize = 30
            const realRenderList = useMemo(() => {
                return isOverlay ? list.slice(0, initialPageSize) : list.slice(0, initialPageSize + page * pageSize)
            }, [isOverlay, list, page])

            return (
                <SC.DroppableContainerRoot
                    ref={mergeRefs([ref, rootRef])}
                    isOverContainer={isOverContainer}
                    collapsed={collapsed}
                    onClick={collapsed ? handleCollapse : undefined}
                    {...rest}
                >
                    <SC.DroppableContainerHead collapsed={collapsed}>
                        <SC.DroppableContainerHeadTitle collapsed={collapsed} {...(collapsed ? {} : handleProps)}>
                            {!fieldSchema ||
                            fieldConvertValue({ ...fieldSchema, value: groupedFieldValue } as FieldADTValue) === EMPTY_COLUMN_GROUP ? (
                                <SC.DroppableContainerEmptyTitle>为空</SC.DroppableContainerEmptyTitle>
                            ) : (
                                <div style={collapsed ? { writingMode: 'vertical-lr', textOrientation: 'sideways' } : undefined}>
                                    <ViewFieldValuePreviewer
                                        enableEdit={false}
                                        direction={collapsed ? 'vertical' : 'horizontal'}
                                        field={fieldSchema}
                                        disabled
                                        data={groupedFieldValue}
                                    />
                                </div>
                            )}
                            <SC.DroppableContainerHeadCount>{list.length}</SC.DroppableContainerHeadCount>
                        </SC.DroppableContainerHeadTitle>

                        <SC.DroppableContainerHeadActions style={{ display: collapsed ? 'none' : undefined }}>
                            <KanbanSettingMenu enableHidden={enableHidden} handleCollapse={handleCollapse} handleHidden={handleHidden}>
                                <ActionIcon>
                                    <IconFont type="DotsThree" />
                                </ActionIcon>
                            </KanbanSettingMenu>

                            {creatable && (
                                <ActionIcon onClick={handleClickAdd}>
                                    <IconFont type="Add" />
                                </ActionIcon>
                            )}
                        </SC.DroppableContainerHeadActions>
                    </SC.DroppableContainerHead>

                    <SortableContext disabled={disabled} items={list.map(item => item.key)} strategy={verticalListSortingStrategy}>
                        <SC.RecordList style={{ display: collapsed ? 'none' : undefined }}>
                            {realRenderList.map(item => (
                                <SortableRecordItem
                                    key={item.key}
                                    id={item.key}
                                    blockId={blockId}
                                    actions={actions}
                                    selected={selectedList?.includes(item.id)}
                                    onSelectChange={onSelectChange}
                                    schema={schema}
                                    columns={columns}
                                    record={item}
                                    disabled={disabled}
                                    clickable={clickable}
                                    onRecordEdit={onRecordEdit}
                                    recordEditOpenable={recordEditOpenable}
                                    recordDeleteAble={recordDeleteAble}
                                    aiFieldStatusListAtom={aiFieldStatusListAtom}
                                    onRecordClick={onRecordClick}
                                    onRecordDelete={onRecordDelete}
                                    onAiGeneration={onAiGeneration}
                                    onRecordOperatorActionTrigger={onRecordOperatorActionTrigger}
                                    onRecordClickedActionTrigger={onRecordClickedActionTrigger}
                                    onRenderButtonTitle={onRenderButtonTitle}
                                />
                            ))}

                            {realRenderList.length !== list.length && (
                                <SC.LoadMoreData onClick={() => setPage(s => s + 1)}>
                                    加载更多 {Math.min(pageSize, list.length - realRenderList.length)}
                                </SC.LoadMoreData>
                            )}
                        </SC.RecordList>
                    </SortableContext>

                    {creatable && (
                        <SC.DroppableContainerFoot onClick={handleClickAdd} style={{ display: collapsed ? 'none' : undefined }}>
                            <IconFont type="Add" />
                        </SC.DroppableContainerFoot>
                    )}
                </SC.DroppableContainerRoot>
            )
        }
    )
)

interface KanbanSettingMenuProps extends MenuProps {
    enableHidden?: boolean
    handleCollapse?: () => void
    handleHidden?: () => void
    children: React.ReactNode
}
const KanbanSettingMenu: FC<KanbanSettingMenuProps> = ({ enableHidden, handleCollapse, handleHidden, children, ...rest }) => {
    const { convertTextByLanguage } = useLanguageContext()
    return (
        <Menu shadow="md" {...rest}>
            <Menu.Target>{children}</Menu.Target>
            <Menu.Dropdown>
                <Menu.Item icon={<IconFont type="ArrowsInSimple" />} onClick={handleCollapse}>
                    {convertTextByLanguage('collapseKanban')}
                </Menu.Item>
                {enableHidden && (
                    <Menu.Item icon={<IconFont type="Hide" />} onClick={handleHidden}>
                        {convertTextByLanguage('hideKanban')}
                    </Menu.Item>
                )}
            </Menu.Dropdown>
        </Menu>
    )
}
