import { UserCenterBlock } from '@lighthouse/block'
import type { UserCenterBlockAbstract } from '@lighthouse/core'
import { DEVELOPER, useAtomAction } from '@lighthouse/shared'
import React from 'react'

import { appRoleAtom } from '@/atoms/application/state'
import { usePreviewType } from '@/hooks/useApplication'
import { useUser } from '@/hooks/useUser'
import { useAppUser } from '@/hooks/useUserPageList'

interface UserCenterBlockProps {
    blockData: UserCenterBlockAbstract
}

const UserCenterBlockController: React.FC<UserCenterBlockProps> = ({ blockData }) => {
    const user = useAppUser()
    const previewType = usePreviewType()

    const { run: setAppRole } = useAtomAction(appRoleAtom)

    return (
        <UserCenterBlock
            blockData={blockData}
            user={user}
            previewType={previewType}
            onLogout={() => {
                setAppRole(DEVELOPER)
            }}
        />
    )
}

export default UserCenterBlockController
