import { Flex, Group, Text } from '@byecode/ui'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { InheritLabel } from '@/components/InheritLabel'
import { PopoverQuestion } from '@/components/PopoverQuestion'

import { useSetExtendsKeys } from '../hooks/useMouseEventDistance'
import { AlignmentSetting } from './AlignmentSetting'
import { GapSizeInput } from './GapSizeInput'
import { PaddingInput } from './PaddingInput'

export interface AutoLayoutSettingSwitch {
    hideAlign?: boolean
    hidePadding?: boolean
    hideGap?: boolean
    disableDistributedAlignment?: boolean
}

export interface AutoLayoutSettingProps extends AutoLayoutSettingSwitch {
    label?: React.ReactNode
    prefixPath?: string
}

export const AutoLayoutSetting: React.FC<AutoLayoutSettingProps> = ({
    label = '布局',
    prefixPath = 'breakPoint.layout',
    hideAlign,
    hidePadding,
    hideGap,
    disableDistributedAlignment
}) => {
    const { control } = useFormContext()
    const { handleSetBreakKey } = useSetExtendsKeys()

    if (hideAlign && hidePadding) {
        return <></>
    }

    return (
        <Group
            label={
                <PopoverQuestion
                    type="layout"
                    arrowOffset={10}
                    renderTarget={(node, ref) => (
                        <Flex alignItems="center" gap={4} ref={ref}>
                            <Text>{label}</Text>
                            {node}
                        </Flex>
                    )}
                />
            }
        >
            {!hideAlign && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="flex-start">
                    <InheritLabel label="对齐" name={`${prefixPath}.align`} />
                    <AlignmentSetting disableDistributedAlignment={disableDistributedAlignment} onSetBreakKey={handleSetBreakKey} />
                </ListItem4ByecodeUi>
            )}
            {!hideGap && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="间距" name={`${prefixPath}.gap`} />
                    <Controller
                        control={control}
                        name={`${prefixPath}.gap`}
                        render={({ field }) => (
                            <GapSizeInput
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey(`${prefixPath}.gap`)
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}
            {!hidePadding && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="flex-start">
                    <InheritLabel label="边距" name={`${prefixPath}.padding`} />
                    <Controller
                        control={control}
                        name={`${prefixPath}.padding`}
                        render={({ field }) => {
                            return (
                                <PaddingInput
                                    value={field.value}
                                    onChange={val => {
                                        if (isDeepEqual(val, field.value)) {
                                            return
                                        }
                                        field.onChange(val)
                                        handleSetBreakKey(`${prefixPath}.padding`)
                                    }}
                                />
                            )
                        }}
                    />
                </ListItem4ByecodeUi>
            )}
        </Group>
    )
}
