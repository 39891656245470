import { Group, Select, Slider } from '@byecode/ui'
import type { ContainerBlockConfig } from '@lighthouse/core'
import type { NodeIdWithScope } from '@lighthouse/shared'
import { findParentBlockWithScopeById, ListItem4ByecodeUi, useAtomData } from '@lighthouse/shared'
import { getDefaultStore } from 'jotai'
import React, { useCallback, useMemo } from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { syncComponentsAtom } from '@/atoms/application/state'
import { lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { InheritLabel } from '@/components/InheritLabel'
import { usePreviewType } from '@/hooks/useApplication'

import { useSetExtendsKeys } from '../hooks/useMouseEventDistance'
import { PositionInput } from './PositionInput'
import { convertPosition } from './utils'

export const POSITION_OPTIONS = [
    {
        label: '跟随滚动',
        value: 'relative'
    },
    {
        label: '相对视窗固定',
        value: 'fixed'
    },
    {
        label: '吸顶固定',
        value: 'stickyTop'
    },
    {
        label: '吸底固定',
        value: 'stickyBottom'
    },
    {
        label: '绝对定位',
        value: 'absolute'
    }
]

export interface PositionSettingSwitch {
    hideZIndex?: boolean
}

interface PositionSettingProps extends PositionSettingSwitch {}

export const PositionSetting: React.FC<PositionSettingProps> = ({ hideZIndex }) => {
    const previewType = usePreviewType()
    const store = getDefaultStore()
    const { control, setValue, getValues } = useFormContext<ContainerBlockConfig>()
    const [pageId, blockId] = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => [s?.pageId || '', s?.state.selectedNodes?.[0]] as const, [])
    )
    const { handleSetBreakKey } = useSetExtendsKeys()
    const positionType = useWatch({ control, name: 'breakPoint.position.type' })

    const handlePositionType = useCallback(
        (val: string) => {
            switch (val) {
                case 'stickyTop': {
                    setValue('breakPoint.position', {
                        type: 'sticky',
                        location: {
                            vertical: 'top',
                            verticalValue: 0
                        }
                    })
                    handleSetBreakKey('breakPoint.position')
                    return
                }
                case 'stickyBottom': {
                    setValue('breakPoint.position', {
                        type: 'sticky',
                        location: {
                            vertical: 'bottom',
                            verticalValue: 0
                        }
                    })
                    handleSetBreakKey('breakPoint.position')
                    return
                }

                case 'absolute': {
                    if (!blockId) {
                        return
                    }
                    const pageBlocks = store.get(pageBlocksAtom(pageId))
                    const syncComponents = store.get(syncComponentsAtom)
                    const parentBlock = findParentBlockWithScopeById(blockId, pageBlocks, syncComponents)
                    const parentNode: NodeIdWithScope | undefined =
                        parentBlock &&
                        (parentBlock.isMasterSynchronous ? { id: blockId.scope || '' } : { id: parentBlock.id, scope: blockId.scope })

                    const { top, left } = convertPosition(blockId, 'absolute', previewType, parentNode)
                    setValue('breakPoint.position', {
                        type: 'absolute',
                        location: {
                            horizontal: 'left',
                            vertical: 'top',
                            horizontalValue: left,
                            verticalValue: top
                        },
                        zIndex: 1
                    })
                    handleSetBreakKey('breakPoint.position')
                    return
                }

                case 'fixed': {
                    if (!blockId) {
                        return
                    }
                    const { top, left } = convertPosition(blockId, 'fixed', previewType)
                    setValue('breakPoint.position', {
                        type: 'fixed',
                        location: {
                            horizontal: 'left',
                            vertical: 'top',
                            horizontalValue: left,
                            verticalValue: top
                        },
                        zIndex: 1
                    })
                    handleSetBreakKey('breakPoint.position')
                    return
                }
                // relative
                default: {
                    setValue('breakPoint.position.type', 'relative')
                    handleSetBreakKey('breakPoint.position')
                }
            }
        },
        [blockId, handleSetBreakKey, pageId, previewType, setValue, store]
    )

    const getPositionType = useCallback(
        (val: string | undefined) => {
            const positionLocation = getValues('breakPoint.position.location')
            if (val === 'sticky') {
                return positionLocation?.vertical === 'bottom' ? 'stickyBottom' : 'stickyTop'
            }
            return val || 'relative'
        },
        [getValues]
    )

    const location = useMemo(() => {
        if (!positionType || positionType === 'relative') {
            return null
        }
        if (positionType === 'sticky') {
            return (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="吸附位置" name="breakPoint.position" />
                    <Controller
                        control={control}
                        name="breakPoint.position.location.verticalValue"
                        render={({ field }) => (
                            <Slider
                                min={0}
                                max={200}
                                step={1}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey('breakPoint.position')
                                }}
                                style={{ width: 180 }}
                                inputProps={{
                                    onFocus: e => {
                                        e.target.select()
                                    }
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )
        }
        return (
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <InheritLabel label="位置" name="breakPoint.position" />
                <PositionInput />
            </ListItem4ByecodeUi>
        )
    }, [control, handleSetBreakKey, positionType])

    return (
        <Group label="定位">
            <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                <InheritLabel label="类型" name="breakPoint.position" />
                <Controller
                    control={control}
                    name="breakPoint.position.type"
                    render={({ field }) => (
                        <Select
                            options={POSITION_OPTIONS}
                            style={{ width: 180 }}
                            value={getPositionType(field.value)}
                            onChange={handlePositionType}
                        />
                    )}
                />
            </ListItem4ByecodeUi>
            {location}
            {/* {!hideZIndex && isShowZIndex && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="层级" name="breakPoint.position" />
                    <Controller
                        control={control}
                        name="breakPoint.position.zIndex"
                        render={({ field }) => (
                            <SizeInput
                                radius={0}
                                icon="GapHorizontal"
                                type="number"
                                onFocus={e => e.currentTarget.select()}
                                value={field.value}
                                onChange={val => {
                                    field.onChange(val)
                                    handleSetBreakKey('breakPoint.position')
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )} */}
        </Group>
    )
}
