import type {
    ApplicationSettingLanguage,
    DataSourceAbstract,
    FieldBlockAbstract,
    FieldInputADTValue,
    FieldInputValue,
    FieldType,
    RecordLikeProtocol
} from '@lighthouse/core'
import type {
    ApplicationPreviewEnum,
    CascadeParam,
    CheckBoxParam,
    FieldInputError,
    PersonParam,
    PhoneNumberParam,
    RelativeFieldParam,
    UseUploadFileSParameter
} from '@lighthouse/shared'
import { FieldInput } from '@lighthouse/shared'
import type { UploadOptions, UploadyProps } from '@rpldy/uploady'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { AnimationDecorators } from '../../components'

export interface FieldBlockProps
    extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'>,
        RelativeFieldParam,
        CascadeParam,
        PhoneNumberParam,
        PersonParam,
        CheckBoxParam {
    blockData: FieldBlockAbstract
    // field?: Field
    blockType: 'field' | 'form'
    value?: FieldInputValue
    previewType?: ApplicationPreviewEnum
    uploadyOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    videoUploadyOption: Pick<UseUploadFileSParameter, 'info' | 'options'>
    type?: FieldType
    language: string
    dataSource?: DataSourceAbstract
    record?: RecordLikeProtocol
    richTextUploadOptions?: UploadyProps
    error?: FieldInputError

    onChange?: (fieldValue: FieldInputADTValue) => void
    onSaveChange?: (fieldValue: FieldInputADTValue) => void
}

const SCxContainer = styled(AnimationDecorators)`
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    /* padding: 6px 5px 4px 4px; */
`

const FieldBlock: React.FC<FieldBlockProps> = props => {
    const {
        blockData,
        blockType,
        value,
        previewType,
        uploadyOptions,
        videoUploadyOption,
        type,
        language,
        dataSource,
        record,
        richTextUploadOptions,
        error,
        onChange,
        onSaveChange,
        ...rest
    } = props
    const { config, title, id } = blockData
    const { breakPoint } = config
    return (
        <SCxContainer
            data-stop-action-propagation
            blockId={id}
            animation={breakPoint?.animation}
            {...rest}
            style={{ overflow: breakPoint.size.overflow, ...rest.style }}
        >
            <FieldInput
                config={{
                    ...config,
                    richTextUploadOptions,
                    uploadyOptions,
                    videoUploadyOption,
                    title,
                    previewType
                }}
                mode="datasource"
                dataSource={dataSource}
                value={value}
                onChange={onChange}
                onSaveChange={onSaveChange}
                language={language}
                record={record}
                error={error}
                {...rest}
            />
        </SCxContainer>
    )
}

export default FieldBlock
