import { Toast } from '@byecode/ui'
import { ChartBlock, useContainerBlockContext } from '@lighthouse/block'
import type { FilterOption } from '@lighthouse/core'
import { type ChartBlockAbstract } from '@lighthouse/core'
import {
    dataDrawerPubSub,
    filterBlock,
    getFilterBlockItemIdsInFilter,
    pageStackPubSub,
    resolveFilter,
    useAtomAsyncAction,
    useAtomData
} from '@lighthouse/shared'
import { deepOmitNil } from '@lighthouse/tools'
import fastEqual from 'fast-deep-equal'
import { useAtomValue } from 'jotai'
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation } from 'react-router'
import { useMount, useUpdateEffect } from 'react-use'

import { syncComponentsAtom } from '@/atoms/application/state'
import { fetchChartBlockAtom } from '@/atoms/blockRecordsDict/action'
import { filterBlockOptionsAtom } from '@/atoms/blockRecordsDict/state'
import { blockCreatingListAtom, blockUpdateListAtom, pageBlocksAtom } from '@/atoms/page/state'
import { filterBlockIdsCacheAtomFamily } from '@/atoms/storage/state'
import { useCurrentPageContext, useCurrentStackIdContext } from '@/contexts/PageContext'
import { useCurrentAppID, useCurrentEnvId } from '@/hooks/useApplication'
import { usePageDataSourceForVariableSelector } from '@/hooks/usePage'
import * as srv from '@/services'

interface GalleryBlockControllerProps extends React.ComponentPropsWithoutRef<'div'> {
    blockData: ChartBlockAbstract
}

const ChartBlockController: React.FC<GalleryBlockControllerProps> = ({ blockData, ...rest }) => {
    const { id, config, title } = blockData
    const appId = useCurrentAppID()
    const location = useLocation()
    const stackId = useCurrentStackIdContext()
    const { pageId } = useCurrentPageContext()
    const envId = useCurrentEnvId()

    const { scope } = useContainerBlockContext()

    const { pointer, linkFilterController, ruleFilter } = config
    const filterOptions = useAtomData(filterBlockOptionsAtom)
    const { curr, prev } = usePageDataSourceForVariableSelector({ pageId, stackId })
    const { filterBlockItemIds } = useMemo(() => getFilterBlockItemIdsInFilter(linkFilterController), [linkFilterController])
    const filterBlockIds = useMemo(() => filterBlockItemIds.map(item => item.blockId), [filterBlockItemIds])
    // const filterItemIds = useMemo(() => filterBlockItemIds.map(item => item.itemId), [filterBlockItemIds])

    // const filterValue = useAtomValue(filterBlockCacheAtomFamily({ appId, envId,  pageId, recordId: curr.recordId}))
    const filterBlockIdsValue = useAtomValue(
        filterBlockIdsCacheAtomFamily({ appId, envId, pageId, recordId: curr.recordId, scope, filterBlockItemIds })
    )

    const usedFilterOptions = useMemo(() => {
        return filterBlockItemIds.reduce<Record<string, FilterOption[]>>((prev, filterItem) => {
            const id = `${filterItem.blockId}-${filterItem.itemId}`
            const options = filterOptions[id]
            if (options) {
                prev[id] = options
            }
            return prev
        }, {})
    }, [filterBlockItemIds, filterOptions])

    const [localFilterOptions, setLocalFilterOptions] = useState(usedFilterOptions)
    const filterBlocks = useAtomData(
        scope ? syncComponentsAtom : pageBlocksAtom(pageId),
        useCallback(s => filterBlock(s, block => block.type === 'filter' && filterBlockIds.includes(block.id)), [filterBlockIds])
    )

    const hasNotCreatedYet = useAtomData(
        blockCreatingListAtom,
        useCallback(s => s.includes(id), [id])
    )

    const hasNeedUpdateYet = useAtomData(
        blockUpdateListAtom,
        useCallback(s => s.includes(id), [id])
    )

    const { run: fetchChartBlock, loading, value: chartData } = useAtomAsyncAction(fetchChartBlockAtom)

    const isFetch = useMemo(() => {
        return !!pointer
    }, [pointer])

    // const [localFilterBlocks, setLocalFilterBlocks] = useState(filterBlocks)

    // const getFilterBlockValue = useCallback(() => {
    //     setFilterValue(FilterBlockCache.getFilterBlockCacheByBlockIds({appId, pageId, recordId: curr.recordId, blockIds: [...filterBlockIds]}))
    // }, [appId, curr.recordId, filterBlockIds, pageId])

    useUpdateEffect(() => {
        if (fastEqual(localFilterOptions, usedFilterOptions)) {
            return
        }
        setLocalFilterOptions(usedFilterOptions)
    }, [usedFilterOptions])

    // useUpdateEffect(() => {
    //     if (fastEqual(filterBlocks, localFilterBlocks)) {
    //         return
    //     }
    //     getFilterBlockValue()
    //     setLocalFilterBlocks(filterBlocks)
    // }, [curr.recordId, filterBlocks])

    const excludeFilterConfig = useMemo(() => {
        const { ruleFilter, linkFilterController, ...rest } = config
        return deepOmitNil(rest)
    }, [config])

    const resolvedFilter = useMemo(() => {
        return linkFilterController
            ? resolveFilter({
                  filter: linkFilterController,
                  extraParams: {
                      filterBlockParams: {
                          filterValue: filterBlockIdsValue,
                          filterOptions: localFilterOptions,
                          filterBlocks
                      }
                  }
              })
            : {}
    }, [linkFilterController, filterBlockIdsValue, localFilterOptions, filterBlocks])

    const configRef = useRef({
        resolvedFilter,
        ruleFilter,
        config: excludeFilterConfig
    })

    const handleExport = useCallback(async () => {
        // const { id, config, title } = viewBlock
        // const dsId = viewBlock.config.appId
        Toast.warning('正在导出, 请勿重复点击')
        // const recordIds = [...new Set(selectState?.selectedIds?.map(recordId => getMainTableRecordId(recordId)))]
        const res = await srv.exportChart({
            blockId: id,
            isSyncComponent: !!scope,
            currentRecordId: curr.recordId,
            parentRecordId: prev.recordId,
            linkFilter: resolvedFilter
        })
        const { data, headers } = res
        const fileName = headers?.['content-disposition']?.replace?.("attachment;filename*=utf-8''", '') ?? `${title}.xlsx`
        const dom = document.createElement('a')
        const url = window.URL.createObjectURL(data)
        dom.href = url
        dom.download = decodeURI(fileName)
        dom.style.display = 'none'
        document.body.append(dom)
        dom.click()
        dom.remove()
        window.URL.revokeObjectURL(url)
    }, [id, scope, curr.recordId, prev.recordId, resolvedFilter, title])

    // 筛选控制器订阅
    // useEffect(() => {
    //     const clear = FilterBlockCache.subscribe({pageId, id, recordId: curr.recordId, itemIds: filterItemIds, callback: getFilterBlockValue})
    //     return () => clear()
    // }, [curr.recordId, filterItemIds, filterBlockIdsValue, id, pageId])

    useMount(() => {
        if (isFetch) {
            fetchChartBlock({
                blockId: id,
                isSyncComponent: !!scope,
                currentRecordId: curr.recordId,
                parentRecordId: prev.recordId,
                linkFilter: resolvedFilter
            })
        }
    })

    // 订阅页面栈对记录的操作
    useEffect(() => {
        if (!pointer) {
            return
        }
        const { subscribeId, unSubscribe } = pageStackPubSub.subscribe(`${pointer}-ADD`, () => {
            fetchChartBlock({
                blockId: id,
                isSyncComponent: !!scope,
                currentRecordId: curr.recordId,
                parentRecordId: prev.recordId,
                linkFilter: resolvedFilter
            })
        })
        return () => unSubscribe(subscribeId)
    }, [curr.recordId, fetchChartBlock, id, pointer, prev.recordId, resolvedFilter, scope])

    // 订阅数据查看器对记录的操作
    useEffect(() => {
        if (!pointer) {
            return
        }
        const { subscribeId, unSubscribe } = dataDrawerPubSub.subscribe(`${pointer}-UPDATE`, () => {
            fetchChartBlock({
                blockId: id,
                isSyncComponent: !!scope,
                currentRecordId: curr.recordId,
                parentRecordId: prev.recordId,
                linkFilter: resolvedFilter
            })
        })
        return () => unSubscribe(subscribeId)
    }, [curr.recordId, fetchChartBlock, id, pointer, prev.recordId, resolvedFilter, scope])

    // 监听filter
    useUpdateEffect(() => {
        if (fastEqual(configRef.current.resolvedFilter, resolvedFilter) && fastEqual(configRef.current.ruleFilter, ruleFilter)) {
            return
        }
        configRef.current.resolvedFilter = resolvedFilter
        configRef.current.ruleFilter = ruleFilter
        fetchChartBlock({
            blockId: id,
            isSyncComponent: !!scope,
            currentRecordId: curr.recordId,
            parentRecordId: prev.recordId,
            linkFilter: resolvedFilter
        })
    }, [ruleFilter, resolvedFilter])

    // 监听配置
    useUpdateEffect(() => {
        if (fastEqual(configRef.current.config, excludeFilterConfig)) {
            return
        }
        configRef.current.config = excludeFilterConfig
        fetchChartBlock({ blockId: id, currentRecordId: curr.recordId, parentRecordId: prev.recordId, linkFilter: resolvedFilter })
    }, [excludeFilterConfig])

    // 当数据查看器进行增删改时 需要重载
    useUpdateEffect(() => {
        if (hasNeedUpdateYet && isFetch) {
            fetchChartBlock({
                blockId: id,
                isSyncComponent: !!scope,
                currentRecordId: curr.recordId,
                parentRecordId: prev.recordId,
                linkFilter: resolvedFilter
            })
        }
    }, [hasNeedUpdateYet])

    useUpdateEffect(() => {
        if (hasNotCreatedYet || location.pathname.includes('dataSource')) {
            return
        }
        if (isFetch) {
            fetchChartBlock({
                blockId: id,
                isSyncComponent: !!scope,
                currentRecordId: curr.recordId,
                parentRecordId: prev.recordId,
                linkFilter: resolvedFilter
            })
        }
    }, [fetchChartBlock, id, scope, isFetch, hasNotCreatedYet, location.pathname, curr.recordId, prev.recordId, hasNeedUpdateYet])

    return <ChartBlock loading={loading} blockData={blockData} chartData={chartData} onExport={handleExport} {...rest} />
}

export default memo(ChartBlockController, fastEqual)
