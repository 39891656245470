import type {
    Conditions,
    DataSourceAbstract,
    DateFilterConfig,
    DateRangeSystemVariableValue,
    FieldInputType,
    FieldType,
    FilterBlockAbstract,
    FilterBlockItemConfig,
    FilterBlockItemValue,
    FilterBlockValue,
    FilterCommonCondition,
    FilterFormType,
    FilterGroupType,
    FilterOption,
    FilterVariable,
    NumberFilterConfig,
    ResolvedVariableConfig,
    SelectFilterConfig,
    VariableADTvalue,
    VariableFieldADTValue
} from '@lighthouse/core'
import { FilterWay, VariableType } from '@lighthouse/core'
import { filter } from 'rambda'

import { DEFAULT_RESOLVED_VALUE_VARIABLE, FILTER_OPERATOR } from '../../components'
import { defaultFormat, InnerTypeMapByFieldType, innerTypeToFieldTypeMapInCondition, InputTypeToFieldTypeMap } from '../../constants'
import { findBlockById } from '../block'
import { getRealFieldByInnerType } from '../field'
import { getUpstreamRealDsId } from '../flow'
import { generateDataRangeByRangeValue } from './dateRangeGenerator'
import type { ResolveFilterItemParams, ResolveFilterParams, ResolveFilterToValueParams, TransformToValueVariablePayload } from './helper'

interface ConvertFilterBlockConditionsParams {
    filterCondition: FilterCommonCondition
    filterVariable: FilterVariable['filterVariable']
    filterBlocks: FilterBlockAbstract[]
    filterValue: {
        blockId: string
        data: FilterBlockValue
    }[]
    filterOptions: Record<string, FilterOption[]>
}

interface GetFilterItemByBlockIdAndItemIdParams {
    blockId: string
    itemId: string
    filterBlocks: FilterBlockAbstract[]
}

interface AssembleFilterConditionsParams {
    filterCondition: FilterCommonCondition
    filterOptions: Record<string, FilterOption[]>
    filterItemConfig: FilterBlockItemConfig
    filterItemData: FilterBlockItemValue
}

export const validateFilterFieldData = (filter: FilterFormType, dataSource: DataSourceAbstract): FilterFormType => {
    const { expression } = filter
    const { schema } = dataSource
    if (!expression || !expression.conditions) {
        return filter
    }
    const newConditions = expression.conditions.filter(condition => {

        const idVariable = (condition as FilterCommonCondition)?.idVariable
        if (!idVariable) {
            return false
        }
        if (idVariable.type === VariableType.FIELD_ID) {
            if (!idVariable.fieldIdVariable?.fieldId) {
                return false
            }
            const field = schema[idVariable.fieldIdVariable.fieldId]
            if (!field) {
                return false
            }
        }
        return true
    })

    return {
        ...filter,
        expression: {
            ...expression,
            conditions: newConditions
        }
    }
}

/**
 * 通过 blockId 和 itemId 获取筛选项
 * @returns
 */
const getFilterItemByBlockIdAndItemId = ({ blockId, itemId, filterBlocks }: GetFilterItemByBlockIdAndItemIdParams) => {
    const filterBlock = findBlockById<FilterBlockAbstract>(blockId, filterBlocks)
    return filterBlock?.config?.filterItems?.find(item => item.id === itemId)
}

/**
 * 根据筛选控制器选项筛选数据，生成筛选器筛选条件（被包含关系）
 * 文本，统一转为 = 条件，并用 OR 连接
 * 数值，统一转为区间判断，使用 >= 和 <= 条件，项内用 AND 连接，项间使用 OR 链接
 * 日期，统一日期区间，使用 >= 和 <= 条件，项内用 AND 连接，项间使用 OR 链接
 * 人员，统一转为 contains 条件，并用 OR 连接
 * 选项，统一转为 contains 条件，并用 OR 连接
 */
/**
 * 处理文本筛选
 * @param params
 * @returns
 */
const assembleTextFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const { idVariable } = filterCondition
    const values = filterItemData?.value

    return values?.map(value => {
        return {
            idVariable,
            operator: 'contains',
            paramList: [
                {
                    type: VariableType.VALUE,
                    valueVariable: {
                        type: 'text',
                        value
                    }
                }
            ]
        }
    })
}
/**
 * 处理数字筛选
 * @param params
 * @returns
 */
const assembleNumberFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterOptions, filterItemData } = params
    const filterItemConfig = params.filterItemConfig as NumberFilterConfig
    const { idVariable } = filterCondition
    // 拿到数值筛选器的选项
    const numberOptions = filterItemConfig?.numberOptions
    const values = filterItemData?.value
    const realValues = values?.map(value => {
        if (value === 'custom') {
            // TODO: @KeLin 初始化时，customValue 为 undefined ，需要处理
            return filterItemData?.customValue
        }
        return numberOptions?.find(o => o.id === value)?.value
    })

    return realValues?.map(value => {
        if (!value) {
            return undefined
        }
        // 对应于 value 的 [min, max]
        const operators = ['>=', '<=']
        return {
            where: 'AND',
            conditions: operators
                .map(
                    (operator, i) =>
                        value[i] !== undefined && {
                            idVariable,
                            operator,
                            paramList: [
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        type: 'number',
                                        value: value[i]
                                    }
                                }
                            ]
                        }
                )
                .filter(Boolean)
        }
    })
}
/**
 * 处理日期筛选
 * @param params
 * @returns
 */
const assembleDateFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const filterItemConfig = params.filterItemConfig as DateFilterConfig
    // 日期常量
    const { dateVariables } = filterItemConfig
    const { idVariable } = filterCondition
    // 选中数据
    const values = filterItemData?.value
    const realValues = values?.map(value => {
        if (value === 'custom') {
            // TODO: @KeLin 初始化时，customValue 为 undefined ，需要处理
            return filterItemData?.customValue
        }
        return dateVariables?.includes(value) ? generateDataRangeByRangeValue(value as DateRangeSystemVariableValue) : undefined
    })

    return realValues?.map(value => {
        if (!value) {
            return undefined
        }
        // 对应于 value 的 [min, max]
        const operators = ['>=', '<=']
        return {
            where: 'AND',
            conditions: operators
                .map((operator, i) => {
                    const dateValue = value[i]
                    return (
                        dateValue && {
                            idVariable,
                            operator,
                            paramList: [
                                {
                                    type: VariableType.VALUE,
                                    valueVariable: {
                                        type: 'date',
                                        value: dateValue.valueOf()
                                    }
                                }
                            ]
                        }
                    )
                })
                .filter(Boolean)
        }
    })
}
/**
 * 处理人员筛选
 * @param params
 * @returns
 */
const assemblePersonFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const { idVariable } = filterCondition
    const values = filterItemData?.value

    return values?.map(value => {
        return {
            idVariable,
            operator: 'contains',
            paramList: [
                {
                    type: VariableType.VALUE,
                    valueVariable: {
                        type: 'user',
                        value
                    }
                }
            ]
        }
    })
}
/**
 * 处理选项筛选
 * @param params
 * @returns
 */
const assembleSelectFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const filterItemConfig = params.filterItemConfig as SelectFilterConfig
    const { customOptions } = filterItemConfig
    const { idVariable } = filterCondition
    const values = filterItemData?.value

    return values?.map(value => {
        if (!customOptions?.includes(value)) {
            return undefined
        }
        return {
            idVariable,
            operator: 'contains',
            paramList: [
                {
                    type: VariableType.VALUE,
                    valueVariable: {
                        type: 'select',
                        value
                    }
                }
            ]
        }
    })
}


/**
 * 处理布尔筛选
 * @param params
 * @returns
 */
const assembleBoolFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterCondition, filterItemData } = params
    const { idVariable } = filterCondition
    const values = filterItemData?.value

    return values?.map(value => {
        return {
            idVariable,
            operator: '=',
            paramList: [
                {
                    type: VariableType.VALUE,
                    valueVariable: {
                        type: 'boolean',
                        value: value === 'true' ? true : value === 'false' ? false : undefined
                    }
                }
            ]
        }
    })
}

const assembleFilterConditions = (params: AssembleFilterConditionsParams) => {
    const { filterItemConfig, filterItemData } = params

    const { type: cachedFilterWay } = filterItemData
    const { filterWay } = filterItemConfig

    // 处理当前筛选器的类型变动后，不再使用缓存的筛选器类型
    if (cachedFilterWay && cachedFilterWay !== filterWay) {
        return []
    }

    switch (filterWay) {
        case FilterWay.textFilter: {
            return assembleTextFilterConditions(params)
        }
        case FilterWay.numberFilter: {
            return assembleNumberFilterConditions(params)
        }
        case FilterWay.dateFilter: {
            return assembleDateFilterConditions(params)
        }
        case FilterWay.personFilter: {
            return assemblePersonFilterConditions(params)
        }
        case FilterWay.selectFilter: {
            return assembleSelectFilterConditions(params)
        }
        case FilterWay.cascadeFilter: {
            return assembleTextFilterConditions(params)
        }
        case FilterWay.boolFilter: {
            return assembleBoolFilterConditions(params)
        }
        default: {
            return []
        }
    }
}

/**
 * 转换筛选器中的变量，主要针对全局筛选控制器变量
 * @returns
 */
const convertFilterBlockConditions = ({
    filterCondition,
    filterVariable,
    filterBlocks,
    filterValue,
    filterOptions
}: ConvertFilterBlockConditionsParams) => {
    if (!filterVariable) {
        return
    }
    const { blockId, itemId } = filterVariable

    // 如果当前筛选项不存在，直接返回
    if (!blockId || !itemId) {
        return
    }

    const filterItemConfig = getFilterItemByBlockIdAndItemId({ blockId, itemId, filterBlocks })

    // 如果当前筛选项不存在，直接返回
    if (!filterItemConfig) {
        return
    }
    const filterItemData = filterValue.find(item => item.blockId === blockId)?.data[itemId]

    // 如果当前筛选项的值不存在，直接返回
    if (!filterItemData) {
        return
    }

    const conditions = filter(
        Boolean,
        assembleFilterConditions({
            filterCondition,
            filterOptions,
            filterItemConfig,
            filterItemData
        }) as FilterCommonCondition[]
    )

    return {
        where: 'OR',
        conditions
    }
}



export const transformToValueVariable = (payload: TransformToValueVariablePayload): VariableADTvalue => {
    const { variable, extraParams, disableResolvedPageData } = payload
    const { type } = variable
    const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList

    switch (type) {
        case VariableType.UPSTREAM: {
            const { upstreamVariable } = variable

            if (!upstreamVariable || !upstreamVariable.nodeId || !upstreamVariable.fieldId) {
                return DEFAULT_RESOLVED_VALUE_VARIABLE
                // newParamList.push(param)
                // continue
            }

            const { nodeId, fieldId } = upstreamVariable

            const record = extraParams?.[nodeId]?.record

            const fieldValue = record?.content?.[fieldId]?.value

            const dsId = getUpstreamRealDsId(nodeId, extraParams?.nodes)

            const dataSource = dataSourceList?.find(({ id }) => id === dsId)

            const field = dataSource?.schema[fieldId]

            if (!field) {
                return DEFAULT_RESOLVED_VALUE_VARIABLE
            }
            const realFiled = getRealFieldByInnerType(field, innerTypeToFieldTypeMapInCondition)
            if (!realFiled) {
                return DEFAULT_RESOLVED_VALUE_VARIABLE
            }

            if (realFiled.type === 'date') {
                return {
                    type: VariableType.RESOLVED,
                    resolvedVariable: {
                        type: realFiled.type,
                        value: fieldValue,
                        format: realFiled.date.format || defaultFormat,
                        innerType: realFiled.innerType
                    } as ResolvedVariableConfig['resolvedVariable']
                }
            }
            return {
                type: VariableType.RESOLVED,
                resolvedVariable: {
                    type: realFiled.type,
                    value: fieldValue,
                    innerType: realFiled.innerType
                } as ResolvedVariableConfig['resolvedVariable']
            }


        }
        case VariableType.VALUE: {
            const { valueVariable } = variable

            if (!valueVariable) {
                return variable
            }

            const { type, value } = valueVariable

            const newValue = value

            if (type === 'checkbox') {
                // 过滤掉空值
                return {
                    type: VariableType.VALUE,
                    valueVariable: { type, value: !!newValue } as VariableFieldADTValue // 这里要过滤
                }
            }
            return variable
        }
        case VariableType.INPUT: {
            const pageStackFormState = extraParams?.pageStackFormState
            const { inputVariable } = variable
            const { blockId = '', scope } = inputVariable ?? {}
            if (!pageStackFormState) {
                return DEFAULT_RESOLVED_VALUE_VARIABLE
            }
            const formState = pageStackFormState[blockId]
            if (!formState || (formState?.scope && formState?.scope !== scope)) {
                return DEFAULT_RESOLVED_VALUE_VARIABLE
            }
            const fieldType: FieldType = formState.fieldType || InputTypeToFieldTypeMap[formState.type as FieldInputType]
            const innerType = InnerTypeMapByFieldType[fieldType]
            return {
                type: VariableType.RESOLVED,
                resolvedVariable: { type: fieldType, value: formState.value, innerType } as ResolvedVariableConfig['resolvedVariable'] // 这里要过滤
                // valueVariable: { type: fieldType, value: formState.value } as VariableFieldADTValue // 这里要过滤
            }
        }
        case VariableType.USER:
        case VariableType.SYSTEM: {
            return variable
        }
        case VariableType.PAGE: {

            if (disableResolvedPageData) {
                return variable
            }

            const { pageVariable } = variable

            if (!pageVariable || !pageVariable.fieldId) {
                return DEFAULT_RESOLVED_VALUE_VARIABLE
            }

            const { fieldId, type } = pageVariable

            const pageRecord = type === 'page' ? extraParams?.pageRecord : extraParams?.clickTriggerNodeParams?.prevRecord

            const fieldValue = pageRecord?.content?.[fieldId]?.value
            const dsId = pageRecord?.dsId
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = dataSourceList?.find(({ id }) => id === dsId)
            const field = dataSource?.schema[fieldId]
            return {
                type: VariableType.RESOLVED,
                resolvedVariable: { type: field?.type, value: fieldValue, innerType: field?.innerType } as ResolvedVariableConfig['resolvedVariable']
            }
        }
        case VariableType.VIEW: {
            const { viewVariable } = variable

            if (!viewVariable || !viewVariable.fieldId) {
                return DEFAULT_RESOLVED_VALUE_VARIABLE
            }

            const { fieldId } = viewVariable

            const viewRecord = extraParams?.viewRecord

            const fieldValue = viewRecord?.content?.[fieldId]?.value
            const dsId = viewRecord?.dsId
            const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
            const dataSource = dataSourceList?.find(({ id }) => id === dsId)
            const field = dataSource?.schema[fieldId]

            if (!field) {
                return DEFAULT_RESOLVED_VALUE_VARIABLE
            }

            return {
                type: VariableType.RESOLVED,
                resolvedVariable: { type: field?.type, value: fieldValue, innerType: field.innerType } as ResolvedVariableConfig['resolvedVariable']
            }
        }
        case VariableType.PAGE_LINK: {
            const { pageLinkVariable } = variable
            if (pageLinkVariable?.value === 'CURRENT_PAGE') {
                const link = extraParams?.getCurrentPageLink?.()
                return {
                    type: VariableType.RESOLVED,
                    resolvedVariable: { type: 'url', value: link, innerType: 'TEXT' } as ResolvedVariableConfig['resolvedVariable']
                }
            }
            return DEFAULT_RESOLVED_VALUE_VARIABLE
        }
        default: {
            return variable
        }
    }
}

/**
 * 解析过滤器相关变量
 * 主要使用在动作、动作流、筛选器中，将过滤器中的变量转换为真实数据
 * @param ResolveFilterParams
 * @returns
 */
export const resolveFilterItem = (payload: ResolveFilterItemParams) => {
    const { condition, extraParams, useInFilterRecordChecker, disableResolvedPageData } = payload

    const { paramList, idVariable, operator: conditionOperator } = condition

    if (!idVariable || !paramList) {
        return condition
    }
    const operator = conditionOperator
    const newParamList: VariableADTvalue[] = []

    const dataSourceList: DataSourceAbstract[] = extraParams?.dataSourceList
    const newIdVariable = transformToValueVariable({
        variable: idVariable,
        extraParams,
        disableResolvedPageData
    })
    for (const param of paramList) {
        const { type } = param

        /**
         * 如果是筛选器校验，直接返回
         */
        if (useInFilterRecordChecker && type !== VariableType.UPSTREAM) {
            newParamList.push(param)
            continue
        }
        if (type === VariableType.FILTER) {
            const { filterVariable } = param
            if (extraParams) {
                const { filterBlockParams } = extraParams
                const { filterValue, filterOptions } = filterBlockParams
                const filterBlocks: FilterBlockAbstract[] = filterBlockParams?.filterBlocks

                return convertFilterBlockConditions({
                    filterCondition: condition,
                    filterVariable,
                    filterBlocks,
                    filterValue,
                    filterOptions
                })
            }
            return condition
        }

        const newParam = transformToValueVariable({ variable: param, extraParams, disableResolvedPageData })
        newParamList.push(newParam)
    }

    if (operator !== FILTER_OPERATOR && newParamList?.length) {
        return {
            ...condition,
            idVariable: newIdVariable,
            operator,
            paramList: newParamList
        }
    }

    return condition

    // return {
    //     ...filter,
    //     expression: {
    //         ...expression,
    //         conditions: newConditions
    //     }
    // }
}

// where: 'AND' | 'OR'
// conditions: FilterConditions[]
export const resolveExpression = ({ where, conditions, extraParams, useInFilterRecordChecker, disableResolvedPageData }: ResolveFilterToValueParams): FilterGroupType => {
    const newConditions = conditions?.map(condition => {
        if (condition.where && condition.conditions) {
            return resolveExpression({ where: condition.where, conditions: condition.conditions, extraParams, useInFilterRecordChecker, disableResolvedPageData })
        }
        return resolveFilterItem({ condition, extraParams, useInFilterRecordChecker, disableResolvedPageData })
    })?.filter(Boolean) as Conditions

    return {
        where,
        conditions: newConditions
    }
}


export const resolveFilter = ({ filter, extraParams, disableResolvedPageData, useInFilterRecordChecker }: ResolveFilterParams): FilterFormType | undefined => {
    if (!filter) {
        return filter
    }
    const { expression } = filter

    if (!expression || !expression.conditions || !expression.where) {
        return filter
    }
    const { where, conditions } = expression
    const newExpression = resolveExpression({ where, conditions, extraParams, disableResolvedPageData, useInFilterRecordChecker })
    return {
        expression: newExpression
    }
}
