import { Flex, Switch, Text } from '@byecode/ui'
import { type CheckboxConfig } from '@lighthouse/core'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { checkBoxShapeMap } from '../../../constants'
import { applyFillPickerValue4CssColorValue, useFillPickerContext } from '../../FillPicker'
import { InputCheckBox } from '../../InputCheckBox'
import type { RichTextEditorProps } from '../../RichText'
import { isEmptyRichTextValue, RichTextEditor } from '../../RichText'
import type { FieldBaseProps } from '../types'

interface CheckboxFieldProps extends FieldBaseProps {
    value?: boolean
    checkBoxConfig?: CheckboxConfig
}
const SCxContainer = styled.div``

export const CheckboxField: React.FunctionComponent<CheckboxFieldProps> = props => {
    const { value, onCellChange, readOnly, checkBoxConfig, onRenderLabel } = props
    const { checkbox } = checkBoxConfig ?? {}
    const { type, gapType, gap = 0, describe, shape, describePosition = 'left', unCheckedIcon, checkedIcon } = checkbox ?? {}
    const { palettes } = useFillPickerContext()
    const justifyContent = gapType === 'auto' ? 'space-between' : 'flex-start'

    const domGap = gapType === 'auto' ? undefined : gap * 16

    const config: RichTextEditorProps['config'] = useMemo(
        () => ({
            heading: true,
            variable: {
                renderLabel: v => onRenderLabel?.(v.attrs.value) ?? ''
            }
        }),
        [onRenderLabel]
    )

    const content = useMemo(() => {
        if (type === 'checkbox') {
            const isCheckbox = !shape || shape === 'checkbox'
            const checkBoxShape = shape ? checkBoxShapeMap[shape] : checkBoxShapeMap.checkbox
            return (
                <InputCheckBox
                    checked={value}
                    defaultBgcolor={isCheckbox ? 'var(--color-white)' : 'transparent'}
                    isCheckbox={isCheckbox}
                    disabled={readOnly}
                    unCheckIcon={checkBoxShape?.[0]}
                    unCheckIconColor="var(--color-app-main)"
                    checkIcon={checkBoxShape?.[1]}
                    checkIconColor="var(--color-app-main)"
                    onChange={e => {
                        onCellChange?.({ type: 'checkbox', value: e.currentTarget.checked })
                    }}
                />
            )
        }
        if (type === 'switch') {
            return (
                <Switch
                    checked={value}
                    disabled={readOnly}
                    color="var(--color-app-main)"
                    onChange={e => onCellChange?.({ type: 'checkbox', value: e.currentTarget.checked })}
                />
            )
        }
        const checkIconColor = applyFillPickerValue4CssColorValue(checkedIcon?.color?.color, palettes) || 'var(--color-app-main)'
        const unCheckIconColor = applyFillPickerValue4CssColorValue(unCheckedIcon?.color?.color, palettes) || 'var(--color-gray-400)'
        return (
            <InputCheckBox
                defaultBgcolor="transparent"
                checked={value}
                disabled={readOnly}
                unCheckIcon={unCheckedIcon?.icon?.value || 'SmileCircleIconLibrary'}
                unCheckIconColor={unCheckIconColor}
                checkIcon={checkedIcon?.icon?.value || 'SmileCircleIconLibrary'}
                checkIconColor={checkIconColor}
                onChange={e => {
                    onCellChange?.({ type: 'checkbox', value: e.currentTarget.checked })
                }}
            />
        )
    }, [
        checkedIcon?.color?.color,
        checkedIcon?.icon?.value,
        onCellChange,
        palettes,
        readOnly,
        shape,
        type,
        unCheckedIcon?.color?.color,
        unCheckedIcon?.icon?.value,
        value
    ])

    return (
        <SCxContainer>
            <Flex alignItems="center" justifyContent={justifyContent} gap={domGap}>
                {content}
                {describe && !isEmptyRichTextValue(describe) && (
                    <Text style={{ order: describePosition === 'left' ? -1 : 0 }} whiteSpace="pre-wrap" size={14}>
                        <RichTextEditor config={config} readonly value={describe} />
                    </Text>
                )}
            </Flex>
        </SCxContainer>
    )
}
