import type { SegmentedControlProps } from '@byecode/ui'
import { Flex, SegmentedControl } from '@byecode/ui'
import React from 'react'
import styled from 'styled-components'

import type { BlockSettingsSegmentedEnum } from '../../constants'
import { BLOCK_SETTINGS_SEGMENTED_DATA, BLOCK_SETTINGS_SEGMENTED_DATA_EXCEPT_INTERACTION } from '../../constants'

const StyledSegmentedControl = styled(SegmentedControl)`
    label[data-active='true'] {
        iconpark-icon {
            color: var(--color-main);
        }
    }
`

interface Props extends Omit<SegmentedControlProps, 'data'> {
    isInterAction?: boolean
    value: BlockSettingsSegmentedEnum
    onChange: (v: BlockSettingsSegmentedEnum) => void
}

export const BlockConfigureSegmentedControl:React.FC<Props> = (props) => {
    const { isInterAction = true } = props
    const data = isInterAction ? BLOCK_SETTINGS_SEGMENTED_DATA : BLOCK_SETTINGS_SEGMENTED_DATA_EXCEPT_INTERACTION
    return (
        <Flex grow px={16} pt={12}>
            <StyledSegmentedControl data={data} fullWidth size="lg" {...props} />
        </Flex>
    )
}
