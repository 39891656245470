import { Divider, Flex, Group, Text } from '@byecode/ui'
import type { EVENT_VARIABLE_TYPE } from '@lighthouse/core';
import type { CurrPageDatasourceForVariable, PrevPageDatasourceForVariable } from '@lighthouse/shared';
import React from 'react';

import { SettingRecordContent } from '@/containers/FlowDetail/components/SettingRecordContent';

interface RecordConfigureProps {
    prefixName: string
    dataSourceId?: string
    eventType?: EVENT_VARIABLE_TYPE
    curr?: CurrPageDatasourceForVariable
    prev?: PrevPageDatasourceForVariable
}

export const RecordConfigure: React.FC<RecordConfigureProps> = ({ prefixName, dataSourceId, eventType, curr, prev }) => {
    if (!dataSourceId) {
        return null
    }
    return (
        <>
            <Divider />
            <Group
                label={
                    <Flex alignItems='center'>
                        <Text>设置记录内容</Text>
                        <Text  color="red" style={{ paddingLeft: 4 }}>
                            *
                        </Text>
                    </Flex>

                }
            >
                <SettingRecordContent dataSourceId={dataSourceId} prefixName={prefixName} curr={curr} prev={prev} eventType={eventType} />
            </Group>
        </>
    )
}
