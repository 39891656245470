export const LINK_TARGET_OPTIONS = [
    {
        value: '_blank',
        label: '新开页面'
    },
    {
        value: '_self',
        label: '当前页打开'
    }
]

export const SEND_MSG_WX_OPTIONS = [
    {
        value: 'text',
        label: '发送文本消息'
    }
    // {
    //     value: 'text',
    //     label: '发送富文本消息',
    //     disabled: true
    // },
    // {
    //     value: 'news',
    //     label: '发送群图文消息',
    //     disabled: true
    // },
    // {
    //     value: 'textNotices',
    //     label: '文本通知模板卡片',
    //     disabled: true
    // },
    // {
    //     value: 'newsNotices',
    //     label: '图文展示模板卡片',
    //     disabled: true
    // }
]

export const SEND_MSG_DINGTALK_OPTIONS = [
    {
        value: 'text',
        label: '发送文本消息'
    },
    {
        value: 'link',
        label: '发送链接'
    },
    {
        value: 'markdown',
        label: '发送富文本消息'
    }
    // {
    //     value: 'feedCard',
    //     label: '发送图文卡片消息',
    //     disabled: true
    // },
    // {
    //     value: 'actionCard',
    //     label: '图文展示模板卡片',
    //     disabled: true
    // },
    // {
    //     value: 'feedCard',
    //     label: '发送多图文卡片消息',
    //     disabled: true
    // }
]

export const SEND_WAY_OPTIONS = [
    {
        label: '腾讯企业邮',
        value: 'WE_COM_MAIL',
        icon: 'BrandLogoWeCom',
        iconColor: '#2883EC'
    },
    {
        label: '网易免费邮',
        value: 'NET_EASE_126_MAIL',
        icon: 'NetEasyFree',
        iconColor: '#1E7E3E'
    }
    // {
    //     label: '钉钉机器人',
    //     value: 'DING_TALK_ROBOT',
    //     icon: 'BrandLogoDingding',
    //     disabled: true
    // }
]

export const WECHAT_PAY_OPTIONS = [
    {
        label: '发起支付',
        value: 'PAY'
    }
    // {
    //     label: '申请退款',
    //     value: 'REFUND'
    // }
]

export const STRIPE_PAY_OPTIONS = [
    {
        label: '发起支付',
        value: 'PAY'
    }
]

export const WECHAT_TEMPLATE_OPTIONS = [
    {
        label: '模板消息1',
        value: '1'
    },
    {
        label: '模板消息2',
        value: '2'
    }
]
