
import { Flex, Input } from '@byecode/ui'
import type { TransitionTypeEnum } from '@lighthouse/core'
import { TagIcon } from '@lighthouse/shared'
import React from 'react'

import { TRANSITION_OPTIONS } from '../constants'

interface TransitionPreviewProps {
    type: TransitionTypeEnum
    onClick?: React.MouseEventHandler<HTMLDivElement>
}

const TransitionPreview = React.forwardRef<HTMLDivElement, TransitionPreviewProps>(({ type, onClick }, ref) => {
    const title = TRANSITION_OPTIONS.find(v => v.value === type)?.label

    return (
        <Flex gap={8} ref={ref} onClick={onClick}>
            <Input
                style={{ width: 180 }}
                styles={{
                    input: {
                        cursor: 'pointer'
                    }
                }}
                value={title}
                readOnly
                prefix={<TagIcon icon="Spring" size={24} iconColor="#fff" background="var(--color-theme-7)" radius={4} color="#fff" />}
            />
        </Flex>
    )
})

export default TransitionPreview
