import { Flex, Group, IconFont, Input, Slider } from '@byecode/ui'
import type { ContainerBlockConfig } from '@lighthouse/core'
import { ListItem4ByecodeUi } from '@lighthouse/shared'
import isDeepEqual from 'fast-deep-equal'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { InheritLabel } from '@/components/InheritLabel'

import { useSetExtendsKeys } from '../hooks/useMouseEventDistance'
import { BackgroundField } from './BackgroundField'
import { BorderField } from './BorderField'
import { FillColor } from './FillColor'
import { LineColor } from './LineColor'
import { RadiusSize } from './RadiusSize'
import { ShadowField } from './ShadowField'
import { TextBorder } from './TextBorder'
import { combinePath } from './utils'
import { VeinsField } from './VeinsField'

export interface StyleSettingSwitch {
    hideRadius?: boolean
    hideFill?: boolean
    hideBackground?: boolean
    hideTextBorder?: boolean
    hideVeins?: boolean
    hideBorder?: boolean
    hideShadow?: boolean
    hideBlur?: boolean
    hideLineColor?: boolean
}
interface StyleSettingProps extends StyleSettingSwitch {
    label?: React.ReactNode
    beforeSlot?: React.ReactNode
    afterSlot?: React.ReactNode
    prefixPath?: string
    customViewDsId?: string

    fillLabel?: string
}

export const StyleSetting: React.FC<StyleSettingProps> = ({
    label = '样式',
    customViewDsId,
    prefixPath,

    hideRadius,
    hideFill,
    hideBackground,
    hideTextBorder,
    hideVeins,
    hideBorder,
    hideShadow,
    hideBlur,
    hideLineColor,

    fillLabel,
    beforeSlot,
    afterSlot
}) => {
    const { control } = useFormContext<ContainerBlockConfig>()
    const { handleSetBreakKey } = useSetExtendsKeys()
    return (
        <Group label={label}>
            {beforeSlot}
            {!hideLineColor && <LineColor prefixPath={prefixPath} onSetBreakKey={handleSetBreakKey} />}
            {!hideFill && <FillColor label={fillLabel} prefixPath={prefixPath} onSetBreakKey={handleSetBreakKey} />}
            {!hideRadius && (
                <Controller
                    control={control}
                    name="breakPoint.design.radius"
                    render={({ field }) => (
                        <RadiusSize
                            title={<InheritLabel label="圆角" name="breakPoint.design.radius" />}
                            value={field.value}
                            onChange={val => {
                                field.onChange(val)
                                handleSetBreakKey('breakPoint.design.radius')
                            }}
                        />
                    )}
                />
            )}

            {!hideBackground && (
                <BackgroundField prefixPath={prefixPath} customViewDsId={customViewDsId} onSetBreakKey={handleSetBreakKey} />
            )}

            {!hideTextBorder && <TextBorder prefixPath={prefixPath} onSetBreakKey={handleSetBreakKey} />}

            {!hideVeins && <VeinsField prefixPath={prefixPath} onSetBreakKey={handleSetBreakKey} />}

            {!hideBorder && (
                <Controller
                    control={control}
                    name="breakPoint.design.border"
                    render={({ field }) => (
                        <BorderField
                            label={<InheritLabel label="描边" name="breakPoint.design.border" />}
                            value={field.value}
                            onChange={v => {
                                if (isDeepEqual(v, field.value)) {
                                    return
                                }
                                field.onChange(v)
                                handleSetBreakKey('breakPoint.design.border')
                            }}
                        />
                    )}
                />
            )}
            {!hideShadow && <ShadowField prefixPath={prefixPath} onSetBreakKey={handleSetBreakKey} />}

            {!hideBlur && (
                <ListItem4ByecodeUi justifyContent="space-between" alignItems="center">
                    <InheritLabel label="背景模糊" name="breakPoint.design.blur" />
                    <Controller
                        control={control}
                        name="breakPoint.design.blur"
                        render={({ field }) => (
                            <Slider
                                style={{ width: 180 }}
                                min={0}
                                max={100}
                                step={1}
                                value={field.value}
                                onChange={v => {
                                    if (isDeepEqual(v, field.value)) {
                                        return
                                    }
                                    field.onChange(v)
                                    handleSetBreakKey('breakPoint.design.blur')
                                }}
                            />
                        )}
                    />
                </ListItem4ByecodeUi>
            )}

            {afterSlot}
        </Group>
    )
}
