import type { Option } from '@byecode/ui'
import { Divider, Group, IconFont } from '@byecode/ui'
import type { BlockAbstract, ContainerBlockAbstract, TabsBlockConfig } from '@lighthouse/core'
import { BlockType, TABS_TYPE } from '@lighthouse/core'
import { filterBlock, isContainerBlock, ListItem4ByecodeUi, useAtomAction, useAtomData } from '@lighthouse/shared'
import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

import { blockHighlightAtom, lastPageOfStackAtom, pageBlocksAtom } from '@/atoms/page/state'
import { getBlockAndPageDesignLimit, PositionSetting, SizeSetting } from '@/components/DesignSetting'
import AnimationSetting from '@/components/DesignSetting/AnimationSetting'
import { FontSetting } from '@/components/DesignSetting/FontSetting'
import { StyleSetting } from '@/components/DesignSetting/StyleSetting'
import { VisibilitySetting } from '@/components/VisibilitySetting'
import { useColorSystemAction } from '@/hooks/useColorSystemAction'

import { BlockConfigureSegmentedControl } from '../Common/BlockConfigureSegmentedControl'
import FormSegmentedControl from '../Common/FormSegmentedControl'
import type { BlockSettingsSegmentedEnum } from '../constants'
import { BLOCK_SETTINGS_SEGMENTED_ENUM, POSITION_ALIGN_CONTROL } from '../constants'
import TabBarSettings from './TabBarSettings'
import { TabsTypeSelector } from './TabsTypeSelector'

export const TabsSettings: React.FC = () => {
    const designProps = getBlockAndPageDesignLimit('tabs')
    const { control, watch } = useFormContext<TabsBlockConfig>()
    const [variant] = watch(['variant'])
    const isLineType = variant === TABS_TYPE.LINE
    const isSegmentedType = variant === TABS_TYPE.SEGMENTED

    const [currentTab, setCurrentTab] = useState<BlockSettingsSegmentedEnum>(BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN)

    const { pageId } = useAtomData(
        lastPageOfStackAtom,
        useCallback(s => ({ pageId: s?.pageId || '' }), [])
    )

    const { run: setBlockHighlight } = useAtomAction(blockHighlightAtom)

    useEffect(() => {
        setBlockHighlight([])
    }, [setBlockHighlight])

    const containerBlockOptions = useAtomData(
        pageBlocksAtom(pageId),
        useCallback(
            dict => {
                const blocks = filterBlock(dict, isContainerBlock)
                return blocks.map<Option>(item => ({
                    label: item.title,
                    value: item.id,
                    icon: <IconFont type="LayerTagContainerBlock" size={16} />,
                    onMouseEnter: () => {
                        setBlockHighlight(draft => {
                            draft.push(item.id)
                        })
                        document.querySelector(`div[data-node-id="${item.id}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    },
                    onMouseLeave: () => {
                        setBlockHighlight(draft => draft.filter(id => id !== item.id))
                        document.querySelector(`div[data-node-id="${item.id}"]`)?.scrollIntoView({ behavior: 'smooth', block: 'center' })
                    }
                }))
            },
            [setBlockHighlight]
        )
    )

    return (
        <>
            <BlockConfigureSegmentedControl value={currentTab} onChange={setCurrentTab} />

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.DESIGN && (
                <>
                    <Group label="标签类型">
                        <ListItem4ByecodeUi grow>
                            <Controller name="variant" control={control} render={({ field }) => <TabsTypeSelector {...field} />} />
                        </ListItem4ByecodeUi>
                    </Group>

                    <Divider />

                    <Fragment key={variant}>
                        <PositionSetting {...designProps.position} />
                        <Divider />
                        <SizeSetting {...designProps.size} />
                        <Divider />
                        <Group label="布局">
                            <Controller
                                control={control}
                                name={`variantMap.${variant}.align`}
                                render={({ field }) => (
                                    <FormSegmentedControl
                                        {...field}
                                        style={{ flex: '0 0 180px' }}
                                        label="对齐"
                                        data={POSITION_ALIGN_CONTROL}
                                    />
                                )}
                            />
                        </Group>

                        <Divider />

                        <StyleSetting {...designProps.design} hideLineColor={variant !== TABS_TYPE.LINE} />
                    </Fragment>

                    <Divider />

                    <FontSetting {...designProps.font} />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.CONFIGURE && (
                <>
                    <Group label="标签">
                        <TabBarSettings variant={variant} isSegmentedType={isSegmentedType} />
                    </Group>
                    <Divider />
                    <VisibilitySetting />
                </>
            )}

            {currentTab === BLOCK_SETTINGS_SEGMENTED_ENUM.INTERACTION && <AnimationSetting />}
        </>
    )
}
