import type { AnyExtension, Editor as CoreEditor, FocusPosition } from '@tiptap/core'
import type { Transaction } from '@tiptap/pm/state'
import type { Editor, JSONContent } from '@tiptap/react'
import { useEditor } from '@tiptap/react'
import { clone, equals, mergeDeepRight, pick } from 'rambda'
import type { HtmlHTMLAttributes } from 'react'
import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react'
import { useLatest, useUpdateEffect } from 'react-use'

import { TipTapProvider } from './Context'
import { getExtensions } from './helper'
import * as SC from './styles'
import type { ToolbarProps } from './Toolbar'
import { Toolbar } from './Toolbar'
import type { ToolbarStyles } from './Toolbar/Toolbar'
import type { UploadImage } from './UploadImage'
import { ParseImagePlugin } from './UploadImage/plugin'
import type { VariableBlock, VariableExtensionOptions } from './VariableBlock'

type TipTapStyles = 'editorContainer' | 'editorContent' | ToolbarStyles

function isVariableExt(ext: AnyExtension): ext is typeof VariableBlock {
    return ext.name === 'variable'
}
function isImageExt(ext: AnyExtension): ext is typeof UploadImage {
    return ext.name === 'image'
}

export type TiptapEditorJSONContent = JSONContent

export interface TiptapEditorProps extends Pick<ToolbarProps, 'config' | 'shrink' | 'previewType'>, HtmlHTMLAttributes<HTMLDivElement> {
    instanceRef?: React.Ref<Editor | null>
    value?: string | null | JSONContent
    onChange?: (json: JSONContent, html?: string) => void
    onEditorBlur?: (props: { editor: CoreEditor; event: FocusEvent; transaction: Transaction }) => void
    autofocus?: FocusPosition
    readonly?: boolean
    minHeight?: number | string
    containerProps?: HtmlHTMLAttributes<HTMLDivElement>
    styles?: Partial<Record<TipTapStyles, React.CSSProperties>>
    disableToolbar?: boolean
    disabled?: boolean
}

export const TiptapEditor = forwardRef<HTMLDivElement, TiptapEditorProps>(
    (
        {
            instanceRef,
            value,
            onChange,
            onEditorBlur,
            autofocus = false,
            readonly,
            config,
            containerProps,
            styles,
            disableToolbar,
            disabled,
            minHeight,
            shrink,
            previewType = 'desktop',
            placeholder,
            ...props
        },
        ref
    ) => {
        const latest = useLatest({ onChange })

        const isCompositionInputRef = useRef(false)

        const variableConfig: Partial<VariableExtensionOptions> = useMemo(() => {
            if (typeof config?.variable === 'boolean' || !config?.variable) {
                return {}
            }
            return config.variable
        }, [config?.variable])

        const editor = useEditor(
            {
                extensions: getExtensions({ ...config, placeholder, variable: variableConfig }),
                autofocus,
                content: value,
                editable: !(disabled || readonly),
                editorProps: {
                    // transformPasted: (slice, view) => {
                    //     const newNodes: Node[] = []
                    //     const placeholders: { id: string; pos: number; src: string }[] = []

                    //     slice.content.forEach((node, offset) => {
                    //         let newNode = node

                    //         if (node.type.name === 'image') {
                    //             const id = nanoid()
                    //             newNode = view.state.schema.nodes.image.create({
                    //                 src: '',
                    //                 id
                    //             })
                    //             placeholders.push({
                    //                 id,
                    //                 pos: offset,
                    //                 src: node.attrs.src
                    //             })

                    //             newNodes.push(newNode)

                    //             return
                    //         }

                    //         node.descendants((node, pos) => {
                    //             if (node.type.name === 'image') {
                    //                 const id = nanoid()
                    //                 placeholders.push({
                    //                     id,
                    //                     pos,
                    //                     src: node.attrs.src
                    //                 })

                    //                 newNode = newNode.replace(
                    //                     pos,
                    //                     pos,
                    //                     new Slice(Fragment.from(view.state.schema.nodes.image.create({ src: '', id })), 0, 0)
                    //                 )
                    //                 return false
                    //             }
                    //         })

                    //         newNodes.push(newNode)
                    //     })

                    //     return new Slice(Fragment.fromArray(newNodes), slice.openStart, slice.openEnd)
                    // },
                    handleDOMEvents: {
                        compositionstart: () => {
                            isCompositionInputRef.current = true
                        },
                        compositionend: view => {
                            isCompositionInputRef.current = false
                            latest.current.onChange?.(view.state.doc.toJSON())
                        }
                    }
                },
                onBlur: (...args) => onEditorBlur?.(...args),
                onUpdate: ({ editor: e }) => {
                    if (isCompositionInputRef.current) {
                        return
                    }
                    // 需要clone，因为返回的attrs属性没有继承Object原型链，导致使用Object方法时报错
                    latest.current.onChange?.(clone(e.getJSON()), e.getHTML())
                }
            },
            [readonly, placeholder]
        )

        useImperativeHandle(instanceRef, () => editor, [editor])

        useUpdateEffect(() => {
            if (!editor) {
                return
            }

            editor.extensionManager.extensions.forEach(extension => {
                if (isVariableExt(extension)) {
                    if (variableConfig.renderLabel) {
                        extension.options.renderLabel = variableConfig.renderLabel
                    }
                    if (variableConfig.dataSources) {
                        extension.options.dataSources = variableConfig.dataSources
                    }
                    if (variableConfig.options) {
                        extension.options.options = variableConfig.options
                    }
                    if (variableConfig.userOption) {
                        extension.options.userOption = variableConfig.userOption
                    }
                    if (variableConfig.systemOption) {
                        extension.options.systemOption = variableConfig.systemOption
                    }
                    if (variableConfig.viewOption) {
                        extension.options.viewOption = variableConfig.viewOption
                    }
                }
            })

            // https://github.com/ueberdosis/tiptap/issues/3764
            requestAnimationFrame(() => {
                editor.view.setProps({
                    nodeViews: editor.extensionManager.nodeViews
                })
            })
        }, [variableConfig])

        // 图片配置更新时，更新上传插件
        useUpdateEffect(() => {
            if (!editor || typeof config?.image !== 'object') {
                return
            }
            const imageExtension = editor?.extensionManager.extensions.find(isImageExt)
            if (!imageExtension) {
                return
            }

            if (equals(imageExtension.options, config.image)) {
                return
            }

            imageExtension.options = mergeDeepRight(imageExtension.options, config.image)

            const newState = editor.state.reconfigure({
                plugins: [
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-expect-error
                    ...editor.state.plugins.filter(p => (p.spec.key ? !p.spec.key.key.includes('upload-image') : true)),
                    ParseImagePlugin({
                        view: editor.view,
                        appId: imageExtension.options.appId,
                        uploadOptions: imageExtension.options.uploadOptions
                    })
                ]
            })
            !editor.view.isDestroyed && editor.view.updateState(newState)
        }, [config?.image])

        // 恢复选中range
        useUpdateEffect(() => {
            if (editor && !editor.isDestroyed && !editor.isFocused) {
                editor
                    .chain()
                    .setContent(value || null, false)
                    .setTextSelection({ from: editor.state.selection.from, to: editor.state.selection.to })
                    .run()
            }
        }, [value])

        const contentStyle = useMemo(
            () => ({
                padding: readonly ? undefined : 8,
                ...styles?.editorContent
            }),
            [readonly, styles?.editorContent]
        )

        const toolbarStyle = useMemo(() => {
            return styles ? pick(['toolbarContainer', 'toolbarMenuList'], styles) : undefined
        }, [styles])

        return (
            <TipTapProvider value={editor}>
                <SC.Container ref={ref} {...props} style={styles?.editorContainer}>
                    {!!editor && !readonly && !disableToolbar && (
                        <Toolbar disabled={disabled} shrink={shrink} previewType={previewType} styles={toolbarStyle} config={config} />
                    )}
                    <SC.Content
                        editor={editor}
                        style={contentStyle}
                        isEditable={editor?.isEditable}
                        minHeight={minHeight}
                        {...containerProps}
                    />
                </SC.Container>
            </TipTapProvider>
        )
    }
)
