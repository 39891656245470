import { Box, Button, Flex, IconFont, Select, Text, Tooltip } from '@byecode/ui'
import { type DataSourceAbstract, type FieldBlockWithDsId, type SelectDataSourceVariable,VariableType } from '@lighthouse/core'
import React, { useCallback } from 'react'
import { Controller, useFieldArray, useFormContext } from 'react-hook-form'
import styled from 'styled-components'

import { DEFAULT_FILTER_VALUE_VARIABLE, ruleOptions } from '../../constant'
import type { VariableTree } from '../../types'
import { MatchCondition } from './MatchCondition'

const SCxContainer = styled.div`
    margin-top: 12px;
    display: flex;
    flex-direction: column;
`

const SCxTitle = styled(Flex)`
    height: 40px;
    align-items: center;
    font-size: 14px;
    justify-content: space-between;
`

const SCxIcon = styled(IconFont)`
    color: var(--color-gray-400);
    cursor: pointer;
`
interface MatchContentProps {
    dataSource: DataSourceAbstract
    dataSourceList: DataSourceAbstract[]
    userOption?: VariableTree
    systemOption?: VariableTree
    fieldBlocksWithDsId?: FieldBlockWithDsId[]
}

export const MatchContent: React.FC<MatchContentProps> = ({ dataSource, dataSourceList, fieldBlocksWithDsId }) => {
    const { control } = useFormContext<SelectDataSourceVariable>()
    const { fields, append, remove } = useFieldArray<SelectDataSourceVariable>({
        control,
        name: 'selectDataSourceVariable.filter.expression.conditions'
    })

    const handleAdd = useCallback(() => {
        append({
            idVariable: {
                type: VariableType.FIELD_ID,
                fieldIdVariable: {
                    fieldId: ''
                }
            },
            operator: '=',
            paramList: [DEFAULT_FILTER_VALUE_VARIABLE]
        })
    }, [append])

    const handleRemove = useCallback(
        (i: number) => {
            remove(i)
        },
        [remove]
    )
    return (
        <SCxContainer>
            <SCxTitle>
                <Flex alignItems="center" gap={4}>
                    <Text weight={600}>查询条件</Text>
                    <Tooltip
                        title={<Box style={{ width: 193 }}>查找满足条件的数据。如果未添加筛选条件则按照排序规则返回排序第一的记录</Box>}
                    >
                        <SCxIcon size={16} type="Question" />
                    </Tooltip>
                </Flex>
                <Flex alignItems="center" gap={8} style={{color: 'var(--color-gray-500)'}}>
                    满足下方
                    <Controller
                        control={control}
                        name="selectDataSourceVariable.filter.expression.where"
                        render={({ field }) => (
                            <Select
                                styles={{
                                    root: {
                                        width: 63
                                    }
                                }}
                                options={ruleOptions}
                                value={field.value}
                                onChange={field.onChange}
                            />
                        )}
                    />
                    条件
                </Flex>
            </SCxTitle>
            <Flex gap={8} style={{ padding: '8px 0' }} direction="column">
                {fields.map(({ id }, i) => (
                    <MatchCondition
                        // useDsIds={useDsIds}
                        key={id}
                        dataSource={dataSource}
                        dataSourceList={dataSourceList}
                        fieldBlocksWithDsId={fieldBlocksWithDsId}
                        prefixName={`selectDataSourceVariable.filter.expression.conditions.${i}`}
                        onRemove={() => handleRemove(i)}
                    />
                ))}

                <Button
                    radius={100}
                    style={{ width: 74 }}
                    onClick={handleAdd}
                    icon={<IconFont type="Add" fill="var(--color-gray-500)" size={16} />}
                >
                    添加
                </Button>
            </Flex>
        </SCxContainer>
    )
}
