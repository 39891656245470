import { FloatingFocusManager, FloatingPortal, useTransitionStyles } from '@floating-ui/react'
import { mergeRefs } from '@lighthouse/tools'
import React, { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'

import { PAGE_CONTAINER_HOST } from '../../../constants'
import { useFloatBoxContext } from '../../../contexts'
import { getSizeStyle } from '../../../utils/layout'
import { useAppContainerContext } from '../../ApplicationContainer'
import { useContainerLayoutContext, useFlowLayoutContext } from '../Context'
import type { FlowLayoutNode, NodeRenderProps } from '../types'

type BoxProps = {
    disabled?: boolean
}

const StyledBox = styled.div<BoxProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    flex-shrink: 0;

    &[data-highlight='true'] {
        background-color: #5551ff1f;
        outline: 2px solid var(--color-main);
        outline-offset: -1px;
    }
`

export interface FlowLayoutOverlayNodeProps extends React.ComponentPropsWithoutRef<'div'> {
    data: FlowLayoutNode
    disabled?: boolean
    sortable?: boolean
    onDataDrawerVisible?: (val: boolean) => void
    nodeRender?: (props: NodeRenderProps) => JSX.Element | null
}

export const OverlayNode = forwardRef<HTMLDivElement, FlowLayoutOverlayNodeProps>((props, ref) => {
    const { data, style, disabled: propDisabled, sortable, onDataDrawerVisible, nodeRender, children, ...rest } = props
    const { size, overlay } = data.data || {}

    const { highlight, disabled: globalDisabled, nodeRender: globalNodeRender = React.Fragment, rootElement } = useFlowLayoutContext()
    const NodeRender = nodeRender ?? globalNodeRender
    const frameID = useRef(0)

    const parentLayoutCtx = useContainerLayoutContext()
    const { scale = 1 } = useAppContainerContext()

    const [parentNodeWidth, setParentNodeWidth] = useState(0)
    const [parentEle, setParentEle] = useState<Element | null | undefined>(null)

    // 设置父容器初始宽度
    useLayoutEffect(() => {
        const parentEle = rootElement?.querySelector(`[data-node-id="${parentLayoutCtx.parentId}"]`)
        setParentEle(parentEle)
        setParentNodeWidth((parentEle?.getBoundingClientRect().width ?? 0) / scale)
    }, [data.id, parentLayoutCtx.parentId, parentNodeWidth, rootElement, scale])

    const observer = useMemo(
        () =>
            typeof window === 'undefined'
                ? null
                : new ResizeObserver((entries: ResizeObserverEntry[]) => {
                      const entry = entries[0]

                      if (entry) {
                          cancelAnimationFrame(frameID.current)
                          frameID.current = requestAnimationFrame(() => {
                              parentEle && setParentNodeWidth(entry.contentRect.width / scale)
                          })
                      }
                  }),
        [parentEle, scale]
    )

    useEffect(() => {
        if (parentEle) {
            observer?.observe(parentEle)
        }
        return () => {
            observer?.disconnect()
            if (frameID.current) {
                cancelAnimationFrame(frameID.current)
            }
        }
    }, [observer, parentEle, parentLayoutCtx.parentId, rootElement])

    const widthStyle = useMemo(() => {
        if (size?.width.size === 'fill') {
            return { width: parentNodeWidth }
        }

        if (size?.width.unit === '%' && size?.width.size !== 'auto') {
            return { width: (parentNodeWidth * (Number(size?.width.size) ?? 0)) / 100 }
        }
        if (size?.width.unit === 'vw') {
            const pageContainer = document.querySelector<HTMLDivElement>(PAGE_CONTAINER_HOST)
            if (!pageContainer) {
                return { width: `${size?.width?.size ?? 0}${size?.width?.unit || 'px'}` }
            }
            const currentSize = typeof size?.width.size  === 'number' ?  size?.width.size ?? 0 : 0
            const pageContainerRect = pageContainer.getBoundingClientRect()
            const width = Number(pageContainerRect.width * (currentSize / scale / 100)).toFixed(2)
            return { width: `${width}px` }
        }
    }, [parentNodeWidth, scale, size?.width.size, size?.width.unit])

    const heightStyle = useMemo(() => {
        const parentNodeHeight =
            rootElement?.querySelector(`[data-node-id="${parentLayoutCtx.parentId}"]`)?.getBoundingClientRect()?.height ?? 0
        if (size?.height.size === 'fill') {
            return { height: parentNodeHeight }
        }

        if (size?.height.unit === '%' && size?.height.size !== 'auto') {
            return { height: (parentNodeHeight * (Number(size?.height.size) ?? 0)) / 100 }
        }
    }, [parentLayoutCtx.parentId, rootElement, size?.height.size, size?.height.unit])

    // 定位的逻辑
    const { floating, context, interactionProps, opened } = useFloatBoxContext()

    const { isMounted, styles: transitionStyles } = useTransitionStyles(context)

    const floatStyle = useMemo(() => {
        return {
            ...transitionStyles,
            position: context.strategy,
            top: context.y,
            left: context.x,
            opacity: opened ? 1 : 0,
            ...widthStyle,
            ...heightStyle
        }
    }, [context.strategy, context.x, context.y, opened, transitionStyles, widthStyle, heightStyle])

    if (!isMounted) {
        return null
    }

    return (
        <FloatingFocusManager context={context} visuallyHiddenDismiss returnFocus>
            <FloatingPortal root={rootElement || document.body}>
                <StyledBox
                    ref={floating ? mergeRefs([floating, ref]) : ref}
                    data-type="overlay"
                    data-node-id={data.id}
                    data-highlight={!!highlight?.[data.id]?.self}
                    style={{
                        ...getSizeStyle(size, parentLayoutCtx.layout?.align?.direction, false, scale),
                        ...floatStyle,
                        ...style,
                        // 防止遮挡拖拽指线
                        zIndex: 2
                    }}
                    {...rest}
                    {...interactionProps.getFloatingProps()}
                >
                    <NodeRender node={data} disabled={propDisabled}>
                        {children}
                    </NodeRender>
                </StyledBox>
            </FloatingPortal>
        </FloatingFocusManager>
    )
})
