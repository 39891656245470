import type { FloatBlockAbstract } from '@lighthouse/core'
import type { FlowLayoutNode } from '@lighthouse/shared'
import { PAGE_SCROLL_PARENT_CONTENT, useFloatBoxContext } from '@lighthouse/shared'
import { mergeRefs } from '@lighthouse/tools'
import type { RefObject} from 'react';
import React, { useRef } from 'react'
import { useClickAway } from 'react-use'
import styled from 'styled-components'

import { ContainerLayout } from '../../components/ContainerLayout'

interface FloatBlockProps extends React.ComponentPropsWithoutRef<'div'> {
    disabled?: boolean
    shown?: boolean
    node: FlowLayoutNode
    blockData: FloatBlockAbstract
    children?: React.ReactNode
}

const FloatBlock = React.forwardRef<HTMLDivElement, FloatBlockProps>(({ blockData, disabled, children, ...rest }, ref) => {
    return (
        <ContainerLayout ref={ref} disabled={disabled} {...rest}>
            {children}
        </ContainerLayout>
    )
})

export default FloatBlock
