import type { TableColumnWidth, ViewIndependentData } from '@lighthouse/core'
import { type AiFieldStatus, type DataSourceAbstract, type RecordLikeProtocol, type TableColumn, DataSourceType } from '@lighthouse/core'
import { ScrollArea } from '@mantine/core'
import type { UploadyProps } from '@rpldy/shared-ui'
import type { atomWithImmer } from 'jotai-immer'
import { equals, find, findIndex, sortBy } from 'rambda'
import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import styled, { css } from 'styled-components'

import type { FindUseLocationConfig } from '../../types'
import { FindUseLocationType } from '../../types'
import { getPrimaryDataSourceEnableFieldIds, useTableSelection } from '../../utils'
import { RecordAdderIcon } from '../RecordAdderIcon'
import type { UseUploadFileSParameter } from '../UploadManage'
import { TableContent } from './TableContent'
import { TableHeader } from './TableHeader'
import { TablePlaceHolder } from './TablePlaceHolder'
import type { ColumnEvent, CreateFieldParams, RowEvent } from './types'

interface TableProps {
    appId: string
    data: DataSourceAbstract
    viewIndependentData: ViewIndependentData
    recordPoolAtom: ReturnType<typeof atomWithImmer<RecordLikeProtocol[]>>
    aiFieldStatusListAtom: ReturnType<typeof atomWithImmer<AiFieldStatus[]>>
    dataSourceList: DataSourceAbstract[]
    pageSize: number
    findUseLocation?: FindUseLocationConfig
    selectedRecords?: string[]
    disableFieldCreatable?: boolean
    disableFieldConfigurable?: boolean
    headerFixed?: boolean
    contentLoading?: boolean
    rowEvent: RowEvent
    columnEvent: ColumnEvent
    createFieldParams?: CreateFieldParams
    disableCreateRecord?: boolean
    disableEditRecord?: boolean
    disableSelect?: boolean
    disableSelectMode?: boolean
    disableEditField?: boolean
    disableAddRecord?: boolean
    scrollBarOutside?: boolean
    disableFindUse?: boolean
    tableColumnCache?: TableColumnWidth
    uploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
    richTextUploadOptions: UploadyProps
    videoUploadOptions: Pick<UseUploadFileSParameter, 'info' | 'options'>
}


const SCxTableWrapper = styled(ScrollArea)<{ scrollBarOutside?: boolean }>`
    height: 100%;
    width: 100%;
    overflow: visible;

    & .mantine-ScrollArea-viewport {
        max-height: 100%;
        height: auto;
        border: 1px solid var(--color-gray-200);
        border-radius: 8px;
        padding-bottom: 1px;
    }
    ${({ scrollBarOutside }) =>
        scrollBarOutside
            ? css`
                  & .mantine-ScrollArea-scrollbar[data-orientation='horizontal'] {
                      bottom: -26px !important;
                  }
                  & .mantine-ScrollArea-scrollbar[data-orientation='vertical'] {
                      right: -26px !important;
                  }
              `
            : css`
                  & .mantine-ScrollArea-scrollbar[data-orientation='horizontal'] {
                      bottom: -14px !important;
                  }
              `}
`

const SCxRecordAdder = styled.div`
    width: 100%;
    display: flex;
    background-color: var(--color-white);
`

const Table: React.FC<TableProps> = ({
    // id,
    appId,
    data,
    viewIndependentData,
    recordPoolAtom,
    aiFieldStatusListAtom,
    dataSourceList,
    pageSize,
    findUseLocation,
    disableFieldCreatable,
    disableFieldConfigurable,
    headerFixed,
    selectedRecords,
    contentLoading = true,
    rowEvent,
    columnEvent,
    createFieldParams,
    disableCreateRecord,
    disableEditRecord,
    disableSelect,
    disableSelectMode,
    disableEditField,
    disableAddRecord,
    scrollBarOutside = true,
    disableFindUse,
    tableColumnCache,
    uploadOptions,
    richTextUploadOptions,
    videoUploadOptions
}) => {
    const { recordIds } = viewIndependentData
    // const listRef = useRef<HTMLDivElement>(null)
    // const containerRef = useRef<HTMLDivElement>(null)
    const listContentRef = useRef<HTMLDivElement>(null)
    const { onSelectField } = useTableSelection({ scrollRef: listContentRef, isExcludeIgnore: true })
    // const [listContent, setListContent] = useState<HTMLDivElement | null>(null)
    const { onCellChange, onCreateRecord, onRecordSelect, onLoadMoreData, onCellUpdate, onAiGeneration } = rowEvent
    const { onCreateField, onUpdateField, onDeleteField, onFindUse, onClearPath, onSelectModeChange, onTableColumnWidthChange } =
        columnEvent
    const { id: dsId, schema, viewOptions, type } = data
    const { tableProps, pagination, joinConfig } = viewOptions
    // const joinConfigureChangeRef = useRef<{
    //     isJoinChange: boolean
    //     key: number
    //     config?: JoinConfig
    // }>({
    //     isJoinChange: false,
    //     key: 0,
    //     config: joinConfig
    // })

    const rowTotal = pagination?.rowTotal ?? 0
    // const isSystemDataSource = type === DataSourceType.systemDataSource
    // const isJoinDataSource = type === DataSourceType.joinDataSource
    const isAggregateDataSource = type === DataSourceType.aggregateDataSource
    const primaryDataSourceFieldIds = useMemo(() => getPrimaryDataSourceEnableFieldIds(data, dataSourceList), [data, dataSourceList])
    const isAddRecord = useMemo(() => {
        return !(isAggregateDataSource || disableAddRecord)
    }, [disableAddRecord, isAggregateDataSource])

    const columns = useMemo(() => {
        const verifyTableProps = tableProps.reduce<TableColumn[]>((prev, { id, visible }) => {
            const width = Number(tableColumnCache?.[id] || 160)
            if (schema?.[id]) {
                prev.push({
                    id,
                    visible,
                    width
                })
            }
            return prev
        }, [])

        return Object.values(schema).reduce<TableColumn[]>((prev, cur) => {
            const field = find(item => item.id === cur.id, prev || [])
            if (!field) {
                prev.push({
                    id: cur.id,
                    visible: false,
                    width: 160
                })
            }
            return prev
        }, verifyTableProps)
    }, [schema, tableColumnCache, tableProps])

    // const [state, setState] = useImmer<TableState>({
    //     columns: initTableColumns
    // })

    // const { columns } = state
    useEffect(() => {
        if (findUseLocation && findUseLocation.type === FindUseLocationType.FIELD) {
            const { fieldId } = findUseLocation
            const fieldDataKey = `${dsId}&${fieldId}`
            const fieldDom = document.querySelector(`[data-field='${fieldDataKey}']`)
            onSelectField(fieldDom as HTMLDivElement)
            onClearPath?.()

            const scrollElement = listContentRef.current
            if (scrollElement) {
                const index = findIndex(col => col.id === fieldId, tableProps)
                const column = tableProps[index]
                if (!column || !column.visible) {
                    return
                }
                const offsetLeft = tableProps.reduce((acc, cur, i) => {
                    if (i < index && cur.visible) {
                        acc += cur.width || 160
                    }
                    return acc
                }, 0)
                scrollElement.scrollTo({ left: offsetLeft })
            }
        }
    }, [findUseLocation, dsId, onClearPath, onSelectField, tableProps])

    const allRecordSelected = useMemo(() => {
        return (
            selectedRecords &&
            selectedRecords.length > 0 &&
            equals(
                sortBy(a => a, selectedRecords),
                sortBy(
                    a => a,
                    recordIds.map(id => id)
                )
            )
        )
    }, [recordIds, selectedRecords])

    const handleAllRecordSelect = useCallback(
        (selected: boolean) => {
            if (selected) {
                onRecordSelect?.(recordIds?.map(id => id))
            } else {
                onRecordSelect?.([])
            }
        },
        [onRecordSelect, recordIds]
    )

    const handleAdd = useCallback(() => {
        onCreateRecord?.(recordIds?.[0])
    }, [onCreateRecord, recordIds])

    return (
        <SCxTableWrapper viewportRef={listContentRef} scrollBarOutside={scrollBarOutside}>
            <TableHeader
                id={dsId}
                appId={appId}
                headerFixed={headerFixed}
                tableProps={columns}
                disableFieldConfigurable={disableFieldConfigurable}
                dataSource={data}
                primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                dataSourceList={dataSourceList}
                disableFieldCreatable={disableFieldCreatable}
                allRecordSelected={allRecordSelected}
                scrollRef={listContentRef}
                createFieldParams={createFieldParams}
                borderBottom={isAddRecord}
                disableSelect={disableSelect}
                disableSelectMode={disableSelectMode}
                disableEditField={disableEditField}
                disableFindUse={disableFindUse}
                onSelectModeChange={onSelectModeChange}
                onAllRecordSelect={handleAllRecordSelect}
                onCreateField={onCreateField}
                onUpdateField={onUpdateField}
                onDeleteField={onDeleteField}
                onFindUse={onFindUse}
                onTableColumnWidthChange={onTableColumnWidthChange}
            />
            {isAddRecord && !disableCreateRecord && (
                <SCxRecordAdder>
                    <RecordAdderIcon height={40} onAdd={handleAdd} />
                </SCxRecordAdder>
            )}
            {/* {isEmpty && <TableHolder>暂无数据</TableHolder>} */}
            <TableContent
                dataSourceInfo={data}
                viewIndependentData={viewIndependentData}
                dataSourceList={dataSourceList}
                selectedRecords={selectedRecords}
                onRecordSelect={onRecordSelect}
                tableProps={columns}
                recordPoolAtom={recordPoolAtom}
                aiFieldStatusListAtom={aiFieldStatusListAtom}
                scrollRef={listContentRef}
                pagination={pagination}
                primaryDataSourceFieldIds={primaryDataSourceFieldIds}
                disableEdit={disableEditRecord}
                disableSelect={disableSelect}
                disableCreateRecord={disableCreateRecord}
                uploadOptions={uploadOptions}
                richTextUploadOptions={richTextUploadOptions}
                videoUploadOptions={videoUploadOptions}
                onCellChange={onCellChange}
                onCellUpdate={onCellUpdate}
                onLoadMoreData={onLoadMoreData}
                onAiGeneration={onAiGeneration}
            />
            {rowTotal > recordIds?.length && recordIds?.length > 0 && <TablePlaceHolder tableProps={columns} schema={schema} />}
        </SCxTableWrapper>
    )
}

export default Table
