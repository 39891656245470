import type { BaseModalProps } from '@byecode/ui'
import {
    Box,
    Button,
    DesktopModal,
    Empty,
    Flex,
    IconFont,
    Input,
    Loading,
    Menu,
    Modal,
    singleTextEllipsis,
    Text,
    TextArea,
    tinyButtons,
    Toast,
    Tooltip,
    usePopoverContext
} from '@byecode/ui'
import type { ApplicationVersionBranch } from '@lighthouse/core'
import { APPLICATION_VERSION_PROD, getImageFullUrlInApplication, useAtomAsyncAction, UserAvatar } from '@lighthouse/shared'
import classNames from 'classnames'
import { formatDistanceToNow, lightFormat } from 'date-fns'
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import useSWR from 'swr'

import { createAppVersionAtom, deleteAppVersionAtom, switchAppVersionAtom, updateAppVersionAtom } from '@/atoms/application/action'
import { useCurrentAppID } from '@/hooks/useApplication'
import { useCurrentSpaceID } from '@/hooks/useWorkSpace'
import * as srv from '@/services'
import { useSpaceUserList } from '@/shared/reusable'

const StyledHeader = styled.div`
    padding: 8px 16px;
    border-bottom: 1px solid var(--color-gray-200);
`
const StyledButton = styled.button`
    all: unset;
    box-sizing: border-box;
    appearance: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 6px;
    border-radius: 50%;
    color: var(--color-gray-400);
    background-color: var(--color-gray-100);
    transition: backgroundColor 0.2s;
    &:hover {
        color: var(--color-gray-500);
        background-color: var(--color-gray-200);
    }
`
const StyledContent = styled.div`
    ${tinyButtons};
    max-height: 500px;
    overflow-y: auto;
    padding: 12px 0;
`
const StyledGroupedName = styled.div`
    height: 32px;
    padding: 0 16px;
    display: flex;
    align-items: center;
    font-size: 12px;
    color: var(--color-gray-500);
`
const StyledBuiButton = styled(Button)`
    width: 24px;
    height: 24px;
    opacity: 0;
    &.active {
        opacity: 1;
        color: var(--color-gray-900);
        background-color: #26415a0f;
    }
`
const StyledItem = styled.div`
    display: flex;
    gap: 4px;
    padding: 8px 16px;

    &:hover {
        background-color: var(--color-gray-100);
        ${StyledBuiButton} {
            opacity: 1;
        }
    }
`
const StyledTitle = styled.div`
    font-size: 14px;
    font-weight: 600;
    line-height: 22px;
`
const StyledDescription = styled.div`
    color: var(--color-gray-500);
    font-size: 12px;
    ${singleTextEllipsis};
`

interface VersionItemProps {
    data: ApplicationVersionBranch
    isProdVersion?: boolean

    rootRef?: React.MutableRefObject<HTMLDivElement | null>

    onRecover?: (id: string) => void
    onDelete?: (id: string) => void
    onUpdate?: (id: string) => void
}

const VersionItem = ({ data, isProdVersion, rootRef, onRecover, onDelete, onUpdate }: VersionItemProps) => {
    const spaceId = useCurrentSpaceID()
    const spaceUserRes = useSpaceUserList(spaceId)
    const appId = useCurrentAppID()
    const user = useMemo(() => spaceUserRes.data?.find(item => item.userId === data.createdBy), [data.createdBy, spaceUserRes.data])

    const [open, setOpen] = useState(false)

    return (
        <StyledItem>
            <Flex direction="column" gap={2} styles={{ root: { flex: 1, overflow: 'hidden' } }}>
                <StyledTitle>
                    {isProdVersion
                        ? `发布于 ${data.updatedTime ? lightFormat(data.updatedTime, 'yyyy/MM/dd HH:mm') : '未知时间'}`
                        : data.name}
                </StyledTitle>
                <StyledDescription>{data.remark}</StyledDescription>
                <Flex alignItems="center">
                    <UserAvatar avatar={getImageFullUrlInApplication(appId, user?.avatar)} name={user?.username ?? '未知用户'} size="small" />
                    <Text size={12} ml={4} lineHeight="20px">
                        {user?.username ?? '未知用户'}
                    </Text>
                    <Text size={12} ml={8} lineHeight="20px" color="var(--color-gray-500)">
                        {data.updatedTime && formatDistanceToNow(data.updatedTime, { addSuffix: true })}
                    </Text>
                </Flex>
            </Flex>

            {isProdVersion ? (
                <Tooltip title="恢复到该版本" placement="bottom">
                    <StyledBuiButton type="text" icon={<IconFont type="Undo" size={16} />} onClick={() => onRecover?.(data.id)} />
                </Tooltip>
            ) : (
                <Menu
                    width={150}
                    position="bottom-start"
                    closeOnItemClick
                    opened={open}
                    onChange={setOpen}
                    // shiftOptions={{ boundary: rootRef?.current || undefined, mainAxis: false, crossAxis: true }}
                >
                    <Menu.Target>
                        <StyledBuiButton
                            className={classNames({ active: open })}
                            type="text"
                            icon={<IconFont type="DotsThree" size={16} />}
                        />
                    </Menu.Target>
                    <Menu.Dropdown>
                        <Menu.Item onClick={() => onRecover?.(data.id)}>恢复到该版本</Menu.Item>
                        <Menu.Item onClick={() => onDelete?.(data.id)}>删除</Menu.Item>
                        <Menu.Item onClick={() => onUpdate?.(data.id)}>编辑</Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            )}
        </StyledItem>
    )
}

export const VersionList = () => {
    const ctx = usePopoverContext()
    const { data, mutate, isLoading } = useSWR('get-version-list', () => srv.getAppVersionList(), {
        revalidateOnFocus: false
    })

    const savedVersionList = useMemo(() => data?.filter(item => item.id !== APPLICATION_VERSION_PROD) ?? [], [data])
    const prodVersion = useMemo(() => data?.find(item => item.id === APPLICATION_VERSION_PROD), [data])

    const switchAppVersionAction = useAtomAsyncAction(switchAppVersionAtom)
    const handleRecover = useCallback(
        async (id: string) => {
            const isSuccess = await switchAppVersionAction.run(id)
            if (isSuccess) {
                Toast.success('恢复成功')
                setRecoverId('')
            }
        },
        [switchAppVersionAction]
    )

    const [recoverId, setRecoverId] = useState('')
    const [versionInfoModalState, setVersionInfoModalState] = useState({ open: false, id: '' })

    const updateData = useMemo(() => {
        const editVersion = data?.find(item => item.id === versionInfoModalState.id)
        if (!editVersion) {
            return
        }

        return { name: editVersion.name, remark: editVersion.remark }
    }, [data, versionInfoModalState.id])

    const deleteVersionAction = useAtomAsyncAction(deleteAppVersionAtom)
    const onDelete = useCallback(
        async (id: string) => {
            const isSuccess = await deleteVersionAction.run(id)
            if (isSuccess) {
                Toast.success('删除成功')
                mutate()
            }
        },
        [deleteVersionAction, mutate]
    )

    const createVersionAction = useAtomAsyncAction(createAppVersionAtom)
    const updateVersionAction = useAtomAsyncAction(updateAppVersionAtom)

    const onSubmitVersionInfo = useCallback(
        async (values: Pick<ApplicationVersionBranch, 'name' | 'remark'>) => {
            let isSuccess = false
            if (versionInfoModalState.id) {
                isSuccess = await updateVersionAction.run({ id: versionInfoModalState.id, ...values })
            } else {
                const newVersionId = await createVersionAction.run(values)
                isSuccess = !!newVersionId
            }

            if (isSuccess) {
                Toast.success(`保存成功`)

                mutate()
                setVersionInfoModalState({ open: false, id: '' })
            }
        },
        [createVersionAction, mutate, updateVersionAction, versionInfoModalState.id]
    )

    const rootRef = useRef<HTMLDivElement | null>(null)

    return (
        <Box ref={rootRef}>
            <StyledHeader>
                <Flex justifyContent="space-between" alignItems="center" styles={{ root: { height: 40 } }}>
                    <Text size={16} weight={500} lineHeight="24px">
                        历史版本
                    </Text>
                    <StyledButton onClick={() => ctx.onClose?.()}>
                        <IconFont type="Close" />
                    </StyledButton>
                </Flex>
            </StyledHeader>

            <StyledContent>
                {isLoading ? (
                    <Loading />
                ) : data && data.length > 0 ? (
                    <>
                        <Box mb={12}>
                            <StyledGroupedName>最近发布的版本</StyledGroupedName>
                            {prodVersion && <VersionItem data={prodVersion} isProdVersion onRecover={setRecoverId} />}
                        </Box>
                        <Box mb={12}>
                            <StyledGroupedName>保存的版本</StyledGroupedName>
                            {savedVersionList.map(item => (
                                <VersionItem
                                    key={item.id}
                                    rootRef={rootRef}
                                    data={item}
                                    onRecover={setRecoverId}
                                    onDelete={onDelete}
                                    onUpdate={id => {
                                        setVersionInfoModalState({ open: true, id })
                                    }}
                                />
                            ))}
                        </Box>
                    </>
                ) : (
                    <Empty description="没有历史版本" py={21} icon={<IconFont size={20} type="NoData" fill="var(--color-gray-400)" />} />
                )}
                <Box px={16} py={8}>
                    <Button
                        block
                        icon={<IconFont type="Add" fill="var(--color-gray-400)" />}
                        size="lg"
                        disabled={savedVersionList.length >= 9}
                        onClick={() => {
                            setVersionInfoModalState({ open: true, id: '' })
                        }}
                    >
                        保存版本
                        <Text size={14} color="var(--color-gray-500)">
                            （{data ? savedVersionList.length : 0}/9）
                        </Text>
                    </Button>
                </Box>
            </StyledContent>

            {/* 恢复版本 确认弹窗 */}
            {recoverId && (
                <RecoverConfirmModal
                    open={!!recoverId}
                    onClose={() => setRecoverId('')}
                    loading={switchAppVersionAction.loading}
                    onSaveVersion={() => {
                        setRecoverId('')
                        setVersionInfoModalState({ open: true, id: '' })
                    }}
                    onRecover={() => handleRecover(recoverId)}
                />
            )}

            {/* 更新/创建 版本 */}
            {versionInfoModalState.open && (
                <VersionInfoModal
                    open={versionInfoModalState.open}
                    onClose={() => {
                        setVersionInfoModalState({ open: false, id: '' })
                    }}
                    title={versionInfoModalState.id ? '编辑版本' : '保存版本'}
                    data={updateData}
                    loading={versionInfoModalState.id ? updateVersionAction.loading : createVersionAction.loading}
                    onSubmit={onSubmitVersionInfo}
                />
            )}
        </Box>
    )
}

interface RecoverConfirmModalProps extends BaseModalProps {
    loading?: boolean
    onSaveVersion?: () => void
    onRecover?: () => void
}

const RecoverConfirmModal = ({ loading, onSaveVersion, onRecover, ...rest }: RecoverConfirmModalProps) => {
    return (
        <DesktopModal width={438} withCloseIcon={false} closeOnClickOverlay={false} p={24} {...rest}>
            <Text weight={600} mb={12}>
                确定恢复到该版本
            </Text>
            <Text size={14} color="var(--color-gray-600)" mb={24} whiteSpace="initial" ellipsis={false}>
                检测到您有未发布的编辑内容，恢复历史版本将清空当前的编辑内容！
                <br />
                如果需要保留当前编辑内容，请点击「保存版本」。如果您确定要恢复历史版本，请点击「知道了，确定恢复」。
                <br />
                注意：恢复的内容是「设计」和「流程」，不包括「数据」。
            </Text>
            <Flex justifyContent="space-between">
                <Button type="text" size="lg" onClick={() => rest.onClose?.()} disabled={loading}>
                    再想想
                </Button>
                <Flex gap={8}>
                    <Button
                        size="lg"
                        onClick={() => {
                            onSaveVersion?.()
                        }}
                        disabled={loading}
                    >
                        保存版本
                    </Button>
                    <Button type="primary" size="lg" loading={loading} onClick={() => onRecover?.()}>
                        知道了，确定恢复
                    </Button>
                </Flex>
            </Flex>
        </DesktopModal>
    )
}

interface VersionInfoModalProps extends Omit<BaseModalProps, 'onSubmit'> {
    title: string
    loading?: boolean
    data?: Pick<ApplicationVersionBranch, 'name' | 'remark'>
    onSubmit?: (values: Pick<ApplicationVersionBranch, 'name' | 'remark'>) => void
}

const VersionInfoModal = ({ title, data, loading, onSubmit, onClose, ...rest }: VersionInfoModalProps) => {
    const [formValues, setFormValues] = useState(data)

    return (
        <DesktopModal
            width={492}
            {...rest}
            onClose={onClose}
            styles={{ header: { border: 'none', height: 48, padding: '10px 12px' }, body: { padding: 32, paddingTop: 0 } }}
        >
            <Text size={24} weight={600} lineHeight="32px">
                {title}
            </Text>

            <Box mt={20}>
                <Flex gap={2}>
                    <Text size={14} weight={600} mb={8}>
                        标题
                    </Text>
                    <Text color="var(--color-red-500)">*</Text>
                </Flex>
                <Input
                    size="lg"
                    placeholder="请输入标题"
                    maxLength={20}
                    value={formValues?.name}
                    onChange={e => {
                        const { value } = e.target
                        setFormValues(s => ({ ...s, name: value }))
                    }}
                />
            </Box>

            <Box mt={20}>
                <Text size={14} weight={600} mb={8}>
                    描述
                </Text>
                <TextArea
                    placeholder="请输入描述"
                    rows={4}
                    maxLength={200}
                    styles={{ root: { width: '100%' } }}
                    value={formValues?.remark}
                    onChange={e => {
                        const { value } = e.target
                        setFormValues(s => ({ ...s, remark: value }))
                    }}
                />
            </Box>

            <Flex justifyContent="flex-end" gap={12} mt={32}>
                <Button size="lg" disabled={loading} onClick={() => onClose?.()}>
                    取消
                </Button>
                <Button
                    type="primary"
                    size="lg"
                    loading={loading}
                    disabled={!formValues || !formValues.name}
                    onClick={() => {
                        if (!formValues || !formValues.name) {
                            return
                        }
                        onSubmit?.(formValues)
                    }}
                >
                    保存版本
                </Button>
            </Flex>
        </DesktopModal>
    )
}
