import { IconFont } from '@byecode/ui'
import type { TriggerNodeType, WorkflowType } from '@lighthouse/shared'
import React, { useMemo } from 'react'
import styled from 'styled-components'

import { useIsDisabledWithVersion } from '@/hooks/useIsDisabledWithVersion'

interface FlowEditorPlaceholderProps {
    type: WorkflowType
    onInit: (type: TriggerNodeType) => void
}

const SCxPlaceholderWrapper = styled.div`
    width: 322px;
    margin: 120px auto;
    position: relative;
    user-select: none;
    z-index: 5;
`

const SCxPlaceholderTitle = styled.h1`
    padding: 0 8px;
    margin-bottom: 32px;
    font-size: 20px;
`

const SCxPlaceholderItemWrapper = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    /* height: 48px; */
    padding: 8px;
    margin-bottom: 8px;
    pointer-events: all;
    cursor: pointer;
    border-radius: 8px;

    &:not(:disabled):hover {
        background-color: var(--color-gray-100);
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`

const SCxPlaceholderItemIconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 36px;
    height: 36px;
    margin-right: 12px;
    border-radius: 8px;
    font-size: 24px;
    background-color: var(--color-green-500);
    color: var(--color-white);
`

const SCxPlaceholderItemIcon = styled(IconFont)``

const SCxPlaceholderItemText = styled.p`
    font-size: var(--font-size-normal);
    color: var(--color-black);
`

const TRIGGER_PLACEHOLDER_ITEMS = [
    { type: 'TRIGGER', nodeType: 'UPDATE_RECORD_TRIGGER', workflowType: 'automation', icon: 'FlowArrow', label: '当记录被更新时' },
    { type: 'TRIGGER', nodeType: 'MATCH_CONDITION_TRIGGER', workflowType: 'automation', icon: 'Workflow', label: '当记录满足指定条件时' },
    { type: 'TRIGGER', nodeType: 'CREATE_RECORD_TRIGGER', workflowType: 'automation', icon: 'PencilSimple', label: '当记录被创建时' },
    { type: 'TRIGGER', nodeType: 'CLICK_TRIGGER', workflowType: 'automation', icon: 'Click', label: '当点击时' },
    { type: 'TRIGGER', nodeType: 'SCHEDULED_TRIGGER', workflowType: 'automation', icon: 'PropertyCreatTime', label: '定时触发' }
]

export const Placeholder: React.FC<FlowEditorPlaceholderProps> = ({ type, onInit }) => {
    const disabledWithVersion = useIsDisabledWithVersion()
    const items = useMemo(() => TRIGGER_PLACEHOLDER_ITEMS.filter(item => item.workflowType === type), [type])
    return (
        <SCxPlaceholderWrapper>
            <SCxPlaceholderTitle>请选择一个触发器</SCxPlaceholderTitle>
            {items.map(item => (
                <SCxPlaceholderItemWrapper
                    disabled={disabledWithVersion}
                    key={item.nodeType}
                    onClick={() => onInit(item.nodeType as TriggerNodeType)}
                >
                    <SCxPlaceholderItemIconWrapper>
                        <SCxPlaceholderItemIcon type={item.icon} />
                    </SCxPlaceholderItemIconWrapper>
                    <SCxPlaceholderItemText>{item.label}</SCxPlaceholderItemText>
                </SCxPlaceholderItemWrapper>
            ))}
        </SCxPlaceholderWrapper>
    )
}
