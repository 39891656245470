import { SegmentedControl, Select } from '@byecode/ui'
import { RecordOpenType } from '@lighthouse/core'
import type { NodeTypes } from '@lighthouse/shared';
import { CollapseBox, commonPageList , nodeTypeOptions, OpenTypeData } from '@lighthouse/shared'
import React from 'react'
import { Controller, useFormContext, useWatch } from 'react-hook-form'

import { useDefaultPageList } from '@/hooks/usePage'

import * as SC from '../styles'

export interface OpenPageActionConfigureProps {
    actionTypeSwitcher?: React.ReactNode
}

export const OpenPageActionConfigure: React.FC<OpenPageActionConfigureProps> = ({ actionTypeSwitcher }) => {
    const { control, watch } = useFormContext()
    const openPageId = useWatch({ control, name: 'config.openPageId' })
    const allPages = useDefaultPageList()

    const isSystemPage = commonPageList.some(v => v.value === openPageId)
    const pageOptions = React.useMemo(() => {
        return [...commonPageList, ...allPages.map(page => ({ label: page.name, value: page.id }))]
    }, [allPages])

    return (
        <SC.Container>
            <CollapseBox label="动作配置">
                <SC.Content>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel>类型</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            {actionTypeSwitcher || (
                                <Controller
                                    name="nodeType"
                                    control={control}
                                    render={({ field }) => (
                                        <Select
                                            disabled
                                            value={field.value}
                                            options={nodeTypeOptions}
                                            onChange={val => field.onChange?.(val as NodeTypes)}
                                        />
                                    )}
                                />
                            )}
                        </SC.FormItemContent>
                    </SC.FormItem>
                    <SC.FormItem>
                        <SC.FormItemLabelWrapper>
                            <SC.FormItemLabel required>页面</SC.FormItemLabel>
                        </SC.FormItemLabelWrapper>
                        <SC.FormItemContent>
                            <Controller
                                name="config.openPageId"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        disabledPortal
                                        dropdownProps={{
                                            style: {
                                                maxHeight: 300
                                            }
                                        }}
                                        value={field.value}
                                        options={pageOptions}
                                        onChange={val => field.onChange?.(val as NodeTypes)}
                                    />
                                )}
                            />
                        </SC.FormItemContent>
                    </SC.FormItem>
                    {openPageId && !isSystemPage && (
                        <SC.FormItem>
                            <SC.FormItemLabelWrapper>
                                <SC.FormItemLabel>打开方式</SC.FormItemLabel>
                            </SC.FormItemLabelWrapper>
                            <SC.FormItemContent>
                                <Controller
                                    name="config.pageOpenType"
                                    defaultValue={RecordOpenType.modal}
                                    control={control}
                                    render={({ field }) => <SegmentedControl fullWidth data={OpenTypeData} {...field} />}
                                />
                            </SC.FormItemContent>
                        </SC.FormItem>
                    )}
                </SC.Content>
            </CollapseBox>
        </SC.Container>
    )
}
